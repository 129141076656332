import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { Wallet } from "./ClientWalletPage";
import CustomBackButton from "../reusable/BackButton";
import AppRoutePath from "../../constants/AppRoutePath";
import { getIconWallet, WalletType } from "../../constants/WalletType";

export default function ReloadFlowerPage() {
  const wallet = WalletType.Flower;
  const [isDialogAddWalletShow, setIsDialogAddWalletShow] = useState(false);
  return (
    <div>
      <header>
        <CustomBackButton />
        <div className="title">Reload Flower </div>
        <a href="#" className="clk-btn">
          <i className="fa-solid fa-bars"></i>
        </a>
      </header>

      <div className="bg-dark">
        <div className="container">
          <div className="inner-box pt-4 pb-4">
            <ul className="nav-page">
              <li className={wallet === WalletType.OneCoin ? "active" : ""}>
                <Link to={AppRoutePath.clientWallet + "/one-coin"}>
                  {getIconWallet(WalletType.OneCoin)} OneCoin
                </Link>
              </li>
              <li className={wallet === WalletType.Flower ? "active" : ""}>
                <Link to={AppRoutePath.clientWallet + "/flower"}>
                  {getIconWallet(WalletType.Flower)} FLOWER
                </Link>
              </li>
              <li className={wallet === WalletType.Heart ? "active" : ""}>
                <Link to={AppRoutePath.clientWallet + "/heart"}>
                  {getIconWallet(WalletType.Heart)} Heart
                </Link>
              </li>
            </ul>

            <div className="flower-box ul li strong">
              <ul>
                <li>
                  <span>Current FLOWER Ranking</span> <strong>12</strong>
                </li>
              </ul>
            </div>
            <div className="flower-box2">
              <div className="flower-top">
                <h3>Add FLOWER Now</h3>
                <h4>
                  <span>BAL</span> <img src="img/flower_icon.png" alt="" />{" "}
                  2,500
                </h4>
              </div>
              <div className="flower-bottom">
                <div className="f-qty">
                  <button type="button" id="sub" className="minus">
                    <i className="fa-solid fa-plus"></i>
                  </button>
                  <input
                    type="text"
                    id="1"
                    value="1"
                    min="1"
                    className="quantity"
                    max="1000"
                  />
                  <button type="button" id="add" className="plus">
                    <i className="fa-solid fa-minus"></i>
                  </button>
                </div>
                <h5>NEW FLOWER RANKING</h5>
                <h6>06</h6>
              </div>
              <button
                className="btn primary-button rounded-button"
                data-bs-toggle="modal"
                data-bs-target="#myModal"
                onClick={(e) => setIsDialogAddWalletShow(true)}
              >
                <i className="fa fa-shopping-cart"></i> ADD
              </button>
            </div>
            <div className="flower-box2">
              <div className="flower-top">
                <h3>Add FLOWER Now</h3>
                <h4>
                  <span>BAL</span> <img src="img/flower_icon.png" alt="" />{" "}
                  2,500
                </h4>
              </div>
              <div className="flower-bottom">
                <div className="f-qty">
                  <button type="button" id="sub" className="minus">
                    <i className="fa-solid fa-plus"></i>
                  </button>
                  <input
                    type="text"
                    id="1"
                    value="1"
                    min="1"
                    className="quantity"
                    max="1000"
                  />
                  <button type="button" id="add" className="plus">
                    <i className="fa-solid fa-minus"></i>
                  </button>
                </div>
                <h5>NEW FLOWER RANKING</h5>
                <h6>06</h6>
              </div>
              <button
                className="btn primary-button rounded-button"
                data-bs-toggle="modal"
                data-bs-target="#myModal"
                onClick={(e) => setIsDialogAddWalletShow(true)}
              >
                <i className="fa fa-shopping-cart"></i> ADD
              </button>
            </div>

            <h2 className="rank-title">
              <img src="img/flower_icon.png" alt="" /> FLOWER RANKING
            </h2>

            <div className="row g-1">
              <div className="col-4">
                <div className="rank-box">
                  <span className="ranking">
                    <i className="fa-solid fa-crown"></i>
                    <a href="#">1</a>
                  </span>
                  <div className="clt-img">
                    <img src="/img/gallery/1.png" alt="" />
                  </div>
                  <div className="rank-top">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fa-brands fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa-regular fa-circle-play"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa-solid fa-qrcode"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa-solid fa-percent"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa-regular fa-thumbs-up"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa-solid fa-crown"></i>
                        </a>
                      </li>
                    </ul>
                    <strong>
                      <img src="img/flower_icon1.png" alt="" /> 699
                    </strong>
                  </div>
                  <hr />
                  <div className="rank-btm">
                    <span>
                      Client Name
                      <br /> Type
                    </span>
                    <strong>$700</strong>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="rank-box">
                  <span className="ranking">
                    <i className="fa-solid fa-crown"></i>
                    <a href="#">1</a>
                  </span>
                  <div className="clt-img">
                    <img src="/img/gallery/1.png" alt="" />
                  </div>
                  <div className="rank-top">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fa-brands fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa-regular fa-circle-play"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa-solid fa-qrcode"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa-solid fa-percent"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa-regular fa-thumbs-up"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa-solid fa-crown"></i>
                        </a>
                      </li>
                    </ul>
                    <strong>
                      <img src="img/flower_icon1.png" alt="" /> 699
                    </strong>
                  </div>
                  <hr />
                  <div className="rank-btm">
                    <span>
                      Client Name
                      <br /> Type
                    </span>
                    <strong>$700</strong>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="rank-box">
                  <span className="ranking">
                    <i className="fa-solid fa-crown"></i>
                    <a href="#">1</a>
                  </span>
                  <div className="clt-img">
                    <img src="/img/gallery/1.png" alt="" />
                  </div>
                  <div className="rank-top">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fa-brands fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa-regular fa-circle-play"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa-solid fa-qrcode"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa-solid fa-percent"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa-regular fa-thumbs-up"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa-solid fa-crown"></i>
                        </a>
                      </li>
                    </ul>
                    <strong>
                      <img src="img/flower_icon1.png" alt="" /> 699
                    </strong>
                  </div>
                  <hr />
                  <div className="rank-btm">
                    <span>
                      Client Name
                      <br /> Type
                    </span>
                    <strong>$700</strong>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="rank-box">
                  <span className="ranking">
                    <i className="fa-solid fa-crown"></i>
                    <a href="#">1</a>
                  </span>
                  <div className="clt-img">
                    <img src="img/gallery/1.png" alt="" />
                  </div>
                  <div className="rank-top">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fa-brands fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa-regular fa-circle-play"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa-solid fa-qrcode"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa-solid fa-percent"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa-regular fa-thumbs-up"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa-solid fa-crown"></i>
                        </a>
                      </li>
                    </ul>
                    <strong>
                      <img src="img/flower_icon1.png" alt="" /> 699
                    </strong>
                  </div>
                  <hr />
                  <div className="rank-btm">
                    <span>
                      Client Name
                      <br /> Type
                    </span>
                    <strong>$700</strong>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="rank-box">
                  <span className="ranking">
                    <i className="fa-solid fa-crown"></i>
                    <a href="#">1</a>
                  </span>
                  <div className="clt-img">
                    <img src="/img/gallery/1.png" alt="" />
                  </div>
                  <div className="rank-top">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fa-brands fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa-regular fa-circle-play"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa-solid fa-qrcode"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa-solid fa-percent"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa-regular fa-thumbs-up"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa-solid fa-crown"></i>
                        </a>
                      </li>
                    </ul>
                    <strong>
                      <img src="img/flower_icon1.png" alt="" /> 699
                    </strong>
                  </div>
                  <hr />
                  <div className="rank-btm">
                    <span>
                      Client Name
                      <br /> Type
                    </span>
                    <strong>$700</strong>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="rank-box">
                  <span className="ranking">
                    <i className="fa-solid fa-crown"></i>
                    <a href="#">1</a>
                  </span>
                  <div className="clt-img">
                    <img src="/img/gallery/1.png" alt="" />
                  </div>
                  <div className="rank-top">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fa-brands fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa-regular fa-circle-play"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa-solid fa-qrcode"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa-solid fa-percent"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa-regular fa-thumbs-up"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa-solid fa-crown"></i>
                        </a>
                      </li>
                    </ul>
                    <strong>
                      <img src="img/flower_icon1.png" alt="" /> 699
                    </strong>
                  </div>
                  <hr />
                  <div className="rank-btm">
                    <span>
                      Client Name
                      <br /> Type
                    </span>
                    <strong>$700</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal show={isDialogAddWalletShow}>
          <Modal.Header>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={(e) => setIsDialogAddWalletShow(false)}
            ></button>
          </Modal.Header>
          <Modal.Body>
            <div className="reload-flower-wrap">
              <strong>
                Bal <i className="fa-solid fa-dollar-sign"></i> 2,500
              </strong>

              <h5>
                <i className="fa-solid fa-heart icon"></i> 1,525
              </h5>

              <div className="qty-box">
                <div>
                  <button type="button" id="sub" className="plus">
                    <i className="fa-solid fa-plus"></i>
                  </button>
                  <input
                    type="text"
                    id="1"
                    value="60"
                    min="1"
                    className="quantity"
                    max="1000"
                  />
                  <button type="button" id="add" className="minus">
                    <i className="fa-solid fa-minus"></i>
                  </button>
                </div>
                <div>
                  <i className="fa-solid fa-dollar-sign"></i>
                  <input type="text" name="" value="6" />
                </div>
              </div>
              <button
                id="blog"
                className="btn primary-button rounded-button abs-btn coin-btn w-70"
              >
                <i className="fa fa-shopping-cart"></i> Reload Heart
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}
