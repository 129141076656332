import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import CustomHeader from "../reusable/Header";
import TopHearder from "../reusable/TopHearder";
import { getClientQRcode } from "../../services/client/client-profile";
import { Spinner } from "react-bootstrap";
import Loaders from "../reusable/Loaders";

const ClientQRCodePages = (props: any) => {
  const isMaster = window.location.pathname.includes("master");
  const [cleintQRCode, setCleintQRCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setIsLoading(true);
    getClientQRcode().then((res: any) => {
      if (res != null) {
        setCleintQRCode(res.qrcode);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  }, []);

  return (
    <>
      <CustomHeader />
      <TopHearder title={t("clientQRCode")} />

      <div className="bg-dark d-flex justify-content-center">
        <div className="pt-5">
          <p className="text-center primary-color ">{t("scanCleintQRCode")}</p>
          <p className="text-center"> {isLoading ? <Loaders /> : ""}</p>
          {cleintQRCode && (
            <img
              className="client-qrcode-img"
              src={cleintQRCode}
              alt="Client QR Code"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ClientQRCodePages;
