import { useTranslation } from "react-i18next";
import "../../css/style.css";

const NoDataFound = () => {
  const { t } = useTranslation();
  return (
    <div className="d-flex justify-content-center text-white">
      <span className="no-data-found">{t('noDataFound')}</span>
    </div>
  );
};

export default NoDataFound;
