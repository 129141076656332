import RegistrationSteps from "../navigation/RegistrationSteps";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import AppRoutePath from "../../../constants/AppRoutePath";
import LocalStorageKey from "../../../constants/LocalStorageKey";
import {
  findCoinBalance,
  findCoinBalanceISEmpty,
} from "../../../services/products/one-coin";
import { useTranslation, initReactI18next } from "react-i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: require("../../../locales/en.json") },
      zh: { translation: require("../../../locales/zh.json") },
    },
    fallbackLng: "en", // Default language fallback
    interpolation: {
      escapeValue: false, // Disable HTML escaping
    },
  });

function LoadCoin() {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const isMaster = window.location.pathname.includes("master");
  const isLogin =
    (localStorage.getItem(LocalStorageKey.isLogin) ?? "false") === "true";
  const [language, setLanguage] = useState("en");
  const { t } = useTranslation();
  const handleReloadCoin = async () => {
    const checkOneCoinIsReload = await findCoinBalanceISEmpty();

    isLogin && checkOneCoinIsReload.message == "Top Up Required"
      ? navigate(AppRoutePath.reloadCoin)
      : navigate(
          isMaster
            ? AppRoutePath.masterReloadCoin
            : AppRoutePath.clientReloadCoin
        );
  };

  const handleLanguageSwitch = () => {
    const newLanguage = language === "en" ? "zh" : "en";
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage); // Update the language setting
  };
  return (
    <>
      <header className="justify-content-center">
        <div className="title">{t("OneCoinReloads")}</div>
        <div className="d-flex justify-content-end pt-5">
          <p className="lang">
            <button onClick={handleLanguageSwitch} className="login-language">
              {language === "en" ? "中文" : "ENG"}{" "}
              {/* Display "ENG" or "中文" based on language */}
            </button>
          </p>
        </div>
      </header>

      <div className="bg-dark">
        <div className="container">
          <div className="inner-box">
            <div className="accordion pt-3" id="accordionExample">
              <RegistrationSteps step={1} />
            </div>

            <div className="tab-content">
              <div id="headingTwo"></div>
              <div
                id="collapseTwo"
                className="collapse show"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div>
                  <p className="text-center mb-1 w-80 m-auto balance-para">
                    {t("ReloadsEmptyQ1")}
                  </p>
                </div>
                <div className="account-box px-5 mt-5 align-items-center">
                  <div className="account active-account w-100">
                    <div className="card mb-5 mb-lg-0 py-3 text-center bg-white">
                      <div className="card-body">
                        <h5 className="balance-title mb-0">
                          {t("OneCoinBalances")}
                        </h5>
                        <h5 className="balance-value d-flex align-items-center justify-content-center gap-1">
                          <p>$</p>
                          <p>0</p>
                        </h5>
                        {/* <Link to="/reload-coin"> */}
                        <button
                          className="btn primary-button rounded-button abs-btn coin-btn w-70 m-auto"
                          onClick={handleReloadCoin}
                        >
                          <i className="fa fa-shopping-cart"></i>{" "}
                          {t("OneCoinReloads1")}
                        </button>
                        {/* </Link> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <p className="note mt-5">
                {t("contactCStg")}, <br /> {t("contactCStg2")}
              </p>
            </div>

            <div className="row">
              <div className="col-6 text-center">
                <Button
                  variant="primary"
                  onClick={() => setShowModal(true)}
                  className="primary-button button mt-3 w-100"
                  data-bs-toggle="modal"
                  data-bs-target="#myModal"
                >
                  {/* Cancel */}
                  {t("cancel")}
                </Button>
              </div>
              <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Body>
                  <div className="d-flex justify-content-center align-items-center">
                    <Modal.Title>
                      <h5 className="modal-title text-center">
                        {t("cancelRegister")}
                      </h5>
                      <Link to="/">
                        <Button className="secondary-button button mt-3 w-100">
                          {t("yes")}
                        </Button>
                      </Link>
                      <Button
                        variant="primary"
                        className="primary-button button mt-3 w-100"
                        onClick={() => setShowModal(false)}
                      >
                        {t("no")}
                      </Button>
                    </Modal.Title>
                  </div>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
              </Modal>
              <div className="col-6 text-center">
                <Button
                  variant="primary"
                  className="primary-button button light-button mt-3 w-100"
                >
                  {t("next")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
    </>
  );
}

export default LoadCoin;
