import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { OverlayTrigger, Pagination, Popover, Spinner } from "react-bootstrap";
import { findFlowerTransaction } from "../../../../services/products/flower";
import { sendInvitaion } from "../../../../services/client/client-invitation";
import { getFollowerList } from "../../../../services/client/client-follow";
import NoDataFound from "../../../reusable/NoDataFound";

const FlowerTableRowV2 = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [oneCoinTrans, setOneCoinTrans] = useState([]);
  const langType = localStorage.getItem("i18nextLng");
  const user_id = localStorage.getItem("user_id");
  const [userlistData, setUserListData] = useState<any[]>([]);

  const getData = () => {
    setIsLoading(true);
    findFlowerTransaction({ page: currentPage, per_page: perPage }).then((res) => {
      if (res) {
        setOneCoinTrans(res["item(s)"]);
        const totalPages = res.total_pages || 1;
        setTotalPages(totalPages);

        setIsLoading(false);
      }
      setIsLoading(false);
    });
  };
  useEffect(() => {
    getData()
    getFollowerList().then((res: any) => {
      if (res != null) {
        const fansList = res["item(s)"].map((follower: any) => follower.fans);
        setUserListData(fansList);
      }
    });
  }, [currentPage, totalPages]);

  const goToPage = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const goToNextPage = () => {
    goToPage(currentPage + 1);
  };

  const goToPrevPage = () => {
    goToPage(currentPage - 1);
  };

  return (
    isLoading ? <div className="justify-content-center d-flex text-white"><Spinner /></div> : (
      <>
        <div>
          <b><span className="p-2 text-14" style={{ color: "#ff3751" }}>{t('myFlowerTransaction')}</span></b>
        </div><br />
        {oneCoinTrans.length > 0 ? (
          <>
            <div className="table-responsive">
              <table style={{ color: "#b3c1ce" }} className="w-100 text-12">
                <colgroup>
                  <col style={{ width: "15%" }} />
                  <col style={{ width: "25%" }} />
                  <col style={{ width: "15%" }} />
                  <col style={{ width: "20%" }} />
                  <col style={{ width: "25%" }} />
                </colgroup>
                <thead style={{ borderBottom: "1px solid #ff3751" }}>
                  <tr>
                    <th className="text-start p-2">{t("dateTime")}</th>
                    <th className="text-center p-2">{t("senderName")}</th>
                    <th className="text-center">{t("receiverName")}</th>
                    <th className="text-center">{t("t_productName")}</th>
                    <th className="text-center">{t("followingRequest")}</th>
                  </tr>
                </thead>
                <tbody>
                  {oneCoinTrans.map((transaction: any, index) => (
                    <tr key={index} style={{ borderBottom: "1px solid #ff3751" }}>
                      <td className="p-2">
                        <div>{transaction.tx_time.split(' ')[0]}</div>
                        <div>{transaction.tx_time.split(' ')[1]}</div>
                      </td>
                      <td className="text-center">
                        <div>{transaction.sender}</div>
                        <div>{transaction.sender_en_name ? (langType === "en" ? transaction.sender_en_name : transaction.sender_zh_name) : '-'}</div>
                      </td>
                      <td className="text-center">
                        <div>{transaction.receiver}</div>
                        <div>{transaction.receiver_en_name ? (langType === "en" ? transaction.receiver_en_name : transaction.receiver_zh_name) : '-'}</div>
                      </td>
                      <td className="text-center">
                        {transaction.product === "AD" && langType === "zh" ? "廣告" :
                          transaction.product === "BLOG" && langType === "zh" ? "網誌" :
                            transaction.product.split('|')[0]}
                      </td>
                      <td className="text-center">
                        {transaction?.sender === Number(user_id) ? (
                          <>
                            <button className="request-btn f-btn ">{t('myAccount')}</button>
                          </>
                        ) : (
                          <div>
                            {userlistData.includes(transaction?.sender) ? (
                              <button className={"request-btn "}>{t('Following')}</button>
                            ) : (
                              <button
                                className={"request-btn"}
                                onClick={() =>
                                  sendInvitaion({
                                    target: transaction?.sender,
                                  })
                                }
                              >
                                {t('sendInvitation')}
                              </button>
                            )}
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div><br />
            <div className="d-flex justify-content-center my-3">
              <Pagination>
                <Pagination.Prev
                  onClick={goToPrevPage}
                  disabled={currentPage === 1}
                />

                {/* Show the first page */}
                <Pagination.Item onClick={() => goToPage(1)}>
                  {t('first')}
                </Pagination.Item>

                {/* Show ellipsis if there are more than 3 pages before the current page */}
                {currentPage > 3 && <Pagination.Ellipsis disabled />}

                {/* Show two pages before the current page */}
                {currentPage > 2 && (
                  <Pagination.Item onClick={() => goToPage(currentPage - 1)}>
                    {currentPage - 1}
                  </Pagination.Item>
                )}

                {/* Show the current page */}
                <Pagination.Item active>{currentPage}</Pagination.Item>

                {/* Show two pages after the current page */}
                {currentPage < totalPages - 1 && (
                  <Pagination.Item onClick={() => goToPage(currentPage + 1)}>
                    {currentPage + 1}
                  </Pagination.Item>
                )}

                {/* Show ellipsis if there are more than 3 pages after the current page */}
                {currentPage < totalPages - 2 && <Pagination.Ellipsis disabled />}

                {/* Show the last page */}
                <Pagination.Item onClick={() => goToPage(totalPages)}>
                  {t('last')}
                </Pagination.Item>

                <Pagination.Next
                  onClick={goToNextPage}
                  disabled={currentPage === totalPages}
                />
              </Pagination>
            </div>
          </>
        ) : (
          <>
            <NoDataFound />
          </>
        )}
      </>
    )
  );
};

export default FlowerTableRowV2;
