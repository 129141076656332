import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AppRoutePath from "../../constants/AppRoutePath";

import { InstantReviewListModel } from "../../domain/model/instant-review-model";
import {
  deleteInstantReviewPost,
  getInstantsReview,
} from "../../services/instant";
import CustomEmptyMessage from "../reusable/EmptyMessage";
import { Modal, Spinner } from "react-bootstrap";
import LocalStorageKey from "../../constants/LocalStorageKey";

import { useTranslation } from "react-i18next";

import StickerData from "./StickerList";
import CustomHeader from "../reusable/Header";
import TopHearder from "../reusable/TopHearder";
import Loaders from "../reusable/Loaders";
import {
  blockUnblockFollower,
  Follower,
  followUser,
  getFollowerList,
} from "../../services/client/client-follow";
import { IFollowUserParams } from "../../domain/model/follower-model";
import { sendInvitaion } from "../../services/client/client-invitation";
export interface IHistoryPageProps {}

function MyInstantReviews(): JSX.Element {
  const { id } = useParams();
  const isMaster = window.location.pathname.includes("master");

  // console.log("frientLists ", frientLists);

  const [instantReviews, setInstantReviews] = useState<
    InstantReviewListModel | null | undefined
  >(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isDeleteAdModalOpen, setIsDeleteAdModalOpen] = useState(false);
  const [selectedPostId, setSelectedPostId] = useState(-1);
  const [selectedPostTitle, setSelectedPostTitle] = useState("");
  const [isShowFollowSuccessfulModal, setIsShowFollowSuccessfulModal] =
    React.useState(false);

  const { t } = useTranslation();

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [descSort, setDescSort] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  //

  const [isShowBlockModal, setIsShowBlockModal] = React.useState(false);
  const [isShowUnBlockModal, setIsShowUnBlockModal] = React.useState(false);
  const [fanId, setFanId] = React.useState({
    target: 0,
    name: "",
    // BtnTitle: "",
  });
  const [bTitles, setBTitles] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [isUnfollowShow, setIsUnfollowShow] = React.useState(false);
  const [isShowBlocSuccessfulModal, setIsShowBlocSuccessfulModal] =
    React.useState(false);

  const frientListsJSON = localStorage.getItem("frientLists");
  let frientLists = frientListsJSON ? JSON.parse(frientListsJSON) : [];

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      handleLoadMore();
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    fetchData(page);
  }, []);

  const fetchData = async (page: number) => {
    let res: any;

    if (isMaster) {
      res = await getInstantsReview({
        inst_user: id,
        page: page,
        per_page: perPage,
      });
    } else {
      res = await getInstantsReview({ page: page, per_page: perPage });
    }

    if (res != null) {
      setIsLoadingData(false);

      setInstantReviews((prevPosts: any) => {
        if (res != null) {
          const existingPostIds = new Set(
            prevPosts?.["item(s)"]?.map((post: any) => post.rev_id)
          );

          const newPosts = res["item(s)"].filter(
            (post: any) => !existingPostIds.has(post.rev_id)
          );

          return {
            "item(s)": [...(prevPosts?.["item(s)"] || []), ...newPosts],
            total_pages: res?.total_pages || 0,
          };
        }
        return prevPosts || {};
      });

      setTotalPages(res?.total_pages || 0);
      setHasMore(page < (res.total_pages || 0));
    }
  };

  const handleLoadMore = () => {
    setPage((prevPage) => {
      const nextPage = prevPage + 1;
      fetchData(nextPage); // Pass the new page value to loadPosts
      return nextPage; // Return the new page value
    });
  };

  //  delete selected instant review post
  const deleteSelectedPost = () => {
    setIsLoading(true);
    deleteInstantReviewPost(String(selectedPostId)).then((isSuccess) => {
      if (isSuccess) {
        setIsDeleteAdModalOpen(false);
        setIsLoading(false);
        getInstantsReview().then((resAdList) => {
          if (resAdList != null) {
            setInstantReviews(resAdList);
          }
        });
      }
    });
  };

  const handleFrientList = () => {
    console.log("updating...");
    getFollowerList()
      .then((res: any) => {
        const friendData = res.data["item(s)"];
        // console.log(friendData);
        const simplifiedData: Follower[] = friendData.map(
          (follower: Follower) => ({
            fans: follower.fans,
            block: follower.block,
          })
        );
        frientLists = JSON.stringify(simplifiedData);
      })
      .catch((error) => {
        console.error("Error fetching friend list:", error);
      });
  };

  //  Block user
  async function doUnblockFollower(id: number, isNowBlocked: boolean) {
    setLoading(true);

    const isSuccess = await blockUnblockFollower(id, !isNowBlocked ? 1 : 0);
    if (isSuccess) {
      handleFrientList();

      setIsShowUnBlockModal(false);

      setLoading(false);
      setIsShowBlocSuccessfulModal(true);
    } else {
      // setTextModal("Block or unblock follower failed");
      // setIsShowModal(true);
    }
  }
  async function doBlockFollower(id: number, isNowBlocked: boolean) {
    setLoading(true);

    const isSuccess = await blockUnblockFollower(id, !isNowBlocked ? 1 : 0);
    if (isSuccess) {
      handleFrientList();

      setIsShowBlockModal(false);

      setLoading(false);

      setIsShowBlocSuccessfulModal(true);
    } else {
      // setTextModal("Block or unblock follower failed");
      // setIsShowModal(true);
    }
  }

  return (
    <>
      <CustomHeader />
      <TopHearder title={t("myInstantReview")} />
      <div className="bg-dark">
        <div className="container py-5">
          {/* <div className="inner-box pt-4 pb-4"> */}
          {isLoadingData ? (
            <div className="text-center pb-3">
              <Loaders />
            </div>
          ) : instantReviews?.["item(s)"].length === 0 ? (
            <div>
              <CustomEmptyMessage />
            </div>
          ) : (
            instantReviews?.["item(s)"]
              // .sort(
              //   (a, b) =>
              //     moment(b.created).valueOf() - moment(a.created).valueOf()
              // )
              .map((data, i) => {
                let mediaImages = [];

                if (
                  data.media &&
                  data.media.image &&
                  data.media.image.length > 0
                ) {
                  for (let i = 0; i < data.media.image.length; i++) {
                    mediaImages.push(data.media.image[i].cache_url);
                  }
                }

                mediaImages = mediaImages.slice(0, 4);

                return (
                  <React.Fragment key={i}>
                    <ReviewItem
                      id={data.rev_id}
                      profilePhoto={
                        data?.author_photo
                          ? data?.author_photo
                          : localStorage.getItem(
                              LocalStorageKey.userProfilePicture
                            )
                          ? localStorage.getItem(
                              LocalStorageKey.userProfilePicture
                            )
                          : "/img/blank-profile-picture.png"
                      }
                      date={data.created}
                      memberName={data?.author_name}
                      lookLevel={data?.stickers.looking ? "" : "1"}
                      bodyFigureLevel={data?.stickers.body_figure ? "" : "2"}
                      techniqueLevel={data?.stickers.technic ? "" : "3"}
                      serviceLevel={data?.stickers.service ? "" : "2"}
                      likes={data?.like}
                      title={data?.title}
                      description={data?.content}
                      setSelectedPostId={setSelectedPostId}
                      setSelectedPostTitle={setSelectedPostTitle}
                      setIsDeleteAdModalOpen={setIsDeleteAdModalOpen}
                      viewTitle="View"
                      mediaImages={mediaImages}
                      stickers={data.stickers}
                      isMaster={isMaster}
                      masterId={id}
                      rank={data.rank}
                      handleFrientList={handleFrientList}
                      frientLists={frientLists}
                      doUnblockFollower={doUnblockFollower}
                      authorId={data.author_id}
                      isShowFollowSuccessfulModal={isShowFollowSuccessfulModal}
                      doBlockFollower={doBlockFollower}
                      blockUnblockFollower={blockUnblockFollower}
                      setIsShowBlockModal={setIsShowBlockModal}
                      setIsShowUnBlockModal={setIsShowUnBlockModal}
                      t={t}
                      setFanId={setFanId}
                      setBTitles={setBTitles}
                      loading={loading}
                    />
                  </React.Fragment>
                );
              })
          )}
          {/* </div> */}
          <div className="col-12 text-center">
            {hasMore ? (
              <Loaders />
            ) : (
              <>
                {/* <p className="text-white">No More post</p> */}
                {instantReviews?.["item(s)"]?.length !== 0 ? (
                  <p className="text-white">No More post</p>
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>

      <Modal show={isDeleteAdModalOpen}>
        <Modal.Body>
          <Modal.Title>
            <center>
              <h5 className="modal-title">
                Are you sure want to delete this instant post?
                <p className="pt-3 primary-color"> {selectedPostTitle}</p>
              </h5>
            </center>
            <div style={{ height: 10 }}></div>
          </Modal.Title>
        </Modal.Body>

        <Modal.Footer>
          <div className="d-flex justify-content-center align-items-center">
            <button
              type="button"
              className="primary-button button mt-3 w-100"
              data-bs-dismiss="modal"
              onClick={deleteSelectedPost}
            >
              {/* Yes */}
              {isLoading ? <Spinner size="sm" /> : "Yes"}
            </button>
            <div style={{ width: 50 }}></div>
            <button
              type="button"
              className="primary-button button mt-3 w-100"
              data-bs-dismiss="modal"
              onClick={() => setIsDeleteAdModalOpen(false)}
            >
              No
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {/*  */}

      {/* Unblock user  */}
      <Modal show={isShowUnBlockModal}>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={(e) => setIsShowUnBlockModal(false)}
        ></button>

        <Modal.Body>
          {/* <p>{textModal}</p> */}
          <p className="text-center">
            Are you sure you want unblock{" "}
            <span className="primary-color">{fanId.name}</span> ?
          </p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <button
            className="btn-modal-close "
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={(e) => setIsShowUnBlockModal(false)}
          >
            No
          </button>
          <button
            className={`${loading ? "btn-modal-close" : "btn-modal "}`}
            onClick={() => {
              doUnblockFollower(fanId?.target, true);
              // setIsShowUnBlockModal(false);
            }}
            // disabled={true}
          >
            {/* Yes */}
            {loading ? <Spinner size="sm" /> : <span>{t("Yes")}</span>}
          </button>
        </Modal.Footer>
      </Modal>

      {/* Block user */}
      <Modal show={isShowBlockModal}>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={(e) => setIsShowBlockModal(false)}
        ></button>

        <Modal.Body>
          {/* <p>{textModal}</p> */}
          <p className="text-center">
            Are you sure you want block{" "}
            <span className="primary-color">{fanId.name}</span> ?
          </p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <button
            className="btn-modal-close "
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={(e) => setIsShowBlockModal(false)}
          >
            No
          </button>
          <button
            className={`${loading ? "btn-modal-close" : "btn-modal "}`}
            onClick={() => {
              doBlockFollower(fanId?.target, false);
            }}
            disabled={loading}
          >
            {/* Yes */}
            {loading ? <Spinner size="sm" /> : <span>{t("Yes")}</span>}
          </button>
        </Modal.Footer>
      </Modal>

      {/* isShowBlocSuccessfulModal  */}
      <Modal show={isShowBlocSuccessfulModal}>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          // onClick={(e) => handleBlockUser()}
        ></button>

        <Modal.Body>
          {/* <p>{textModal}</p> */}
          <p className="text-center">
            Done! <br />
            The selected user has been successfully {bTitles}.
          </p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <button
            className="btn-modal"
            onClick={() => setIsShowBlocSuccessfulModal(false)}
          >
            Done
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

// Instant Post Cards
function ReviewItem(props: any) {
  const stickerList = StickerData();

  interface Friend {
    fans: number;
    block: number;
  }
  const friend: Friend | undefined = props.frientLists.find(
    (friend: Friend) => friend.fans === props.authorId
  );

  // console.log("friend ", friend);

  const isFriendAndBlocked = friend && friend.block === 1;

  return (
    <React.Fragment key={props.id}>
      <div className="mir-wrap pb-lg-3 pb-md-3">
        <div className="row">
          <div className=" col-lg-4 col-md-4 col-3">
            <div className="mir-left">
              <ul>
                {/* <li>
                  <strong>{props.date}</strong>
                  <span className="ranking">
                    <i className="fa-solid fa-crown"></i>
                    <a>3</a>
                  </span>
                </li> */}
                <li>
                  <strong>Ranking</strong>
                  <span className="ranking">
                    <i className="fa-solid fa-crown"></i>
                    <a>{props.rank ? props.rank : "N/A"}</a>
                  </span>
                </li>
              </ul>
              {friend ? (
                <>
                  {isFriendAndBlocked ? (
                    <button
                      className="request-btn h-auto"
                      onClick={() => {
                        props.setIsShowUnBlockModal(true);
                        props.setFanId({
                          target: props.authorId,
                          name: props.memberName,
                        });
                        props.setBTitles("unblock");
                      }}
                    >
                      {props.t("unblock")}
                    </button>
                  ) : (
                    <button
                      className="request-btn"
                      onClick={() => {
                        props.setIsShowBlockModal(true);
                        props.setFanId({
                          target: props.authorId,
                          name: props.memberName,
                        });
                        props.setBTitles("blocked");
                      }}
                    >
                      {props.t("Block")}
                    </button>
                  )}
                </>
              ) : (
                <button
                  className="request-btn h-auto"
                  onClick={() =>
                    sendInvitaion({
                      target: props.authorId,
                    })
                  }
                >
                  {props.t("sendInvitation")}
                </button>
              )}

              <h6>{props.memberName}</h6>
              <div className="clt-img">
                <img src={props.profilePhoto} alt="" />
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-md-8 col-9">
            <div className="mir-right">
              <div className="row">
                <div className="col-9">
                  <ul>
                    {stickerList.map((stickerItem: any, index: any) => {
                      const enName = stickerItem.enName;
                      const emojis = stickerItem.emojis;
                      const name = stickerItem.name;
                      const rates = stickerItem.rate;
                      const a = enName.toLowerCase();
                      const matchedEmoji = emojis.find((emoji: any) => {
                        return (
                          emoji?.rate?.toLowerCase().trim() ===
                          props.stickers[a]?.rate?.toLowerCase().trim()
                        );
                      });

                      // Check if a matching emoji was found
                      if (matchedEmoji) {
                        return (
                          <li className="emojis" key={index}>
                            <li className="text-8 text-white">{name}</li>
                            <img
                              src={matchedEmoji.emojiIcon}
                              alt=""
                              style={{ height: 25 }}
                            />
                            <li className="text-8 primary-color mt-1 text-center">
                              {matchedEmoji.rate}
                            </li>
                          </li>
                        );
                      }

                      return null; // Return null if no matching emoji was found
                    })}
                  </ul>
                </div>
                <div className="col-3 text-end">
                  <div className="thumbs-box">
                    <span>{props.likes}</span>
                    <i className="fa-solid fa-thumbs-up"></i>
                  </div>
                </div>

                <div className="col-12">
                  <h2 className="text-16">
                    {props.title} <span className="text-12">{props.date}</span>
                  </h2>
                  <p>{props.description}</p>
                  <div className="gap-2 d-flex my-3">
                    {props.mediaImages && props.mediaImages.length > 0 && (
                      <>
                        {props.mediaImages
                          .slice(0, 4)
                          .map((url: any, index: any) => (
                            <>
                              <img
                                key={index}
                                src={url}
                                className="inst-r-four-img"
                              />
                            </>
                          ))}
                      </>
                    )}
                  </div>

                  <div className="pt-2  d-flex gap-3">
                    <Link
                      to={
                        props.isMaster
                          ? `${AppRoutePath.masterMyInstantReview}/${props.masterId}/${props.id}`
                          : `${AppRoutePath.clientMyInstantReview}/${props.id}`
                      }
                      className="primary-color no-decoration post-edit"
                    >
                      {props.viewTitle}
                    </Link>
                    {/* <Link
                      to={""}
                      className="primary-color no-decoration post-edit"
                    >
                      Edit{" "}
                    </Link> */}
                    {/* <span
                      className="primary-color no-decoration post-edit delete pointer"
                      onClick={() => {
                        props.setSelectedPostId(props.id);
                        props.setSelectedPostTitle(props.title);
                        props.setIsDeleteAdModalOpen(true);
                      }}
                    >
                      Delete
                    </span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default MyInstantReviews;
