import * as React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { WalletTableRow } from "../interface/WalletTableRow";
import { useTranslation, initReactI18next } from "react-i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: require("../../../../locales/en.json") },
      zh: { translation: require("../../../../locales/zh.json") },
    },
    fallbackLng: "en", // Default language fallback
    interpolation: {
      escapeValue: false, // Disable HTML escaping
    },
  });

const langType = localStorage.getItem("i18nextLng");

const oneCoinTableRow: any[] = [
  {
    label: "My OneCoin Transaction",
    div: false,
    colName: [langType === "en" ? "Date/Time" : "show Chinese", "Name", "Amount", "Product Name", "Remarks"],

    rowDataComp: (data: any, remarksData: any) => {
      return (
        <tr>
          <td className="text-start">
            <span className="d-block ">
              {data.tx_time && data.tx_time.split(" ")[0]}
            </span>
            <small className="d-block time-col ">
              {" "}
              {data.tx_time && data.tx_time.split(" ")[1]}
            </small>
          </td>
          <td>
            <span>{data?.receiver ? data?.receiver : "N/A"}</span>
            {langType === "zh" ? data.receiver_zh_name : data.receiver_en_name}
          </td>
          <td>
            <button
              className={
                "badge rounded-pill bg-1 p-2 border-0 text-white  fw-normal b-amount " +
                (data.coins >= 0 && " bg-2")
              }
            >
              {data.coins >= 0 && "+ "}
              {data.coins}
            </button>
          </td>
          <td>
            {" "}
            {data.product
              ? langType === "zh"
                ? data.product?.split("|")?.[0] || data.product
                : data.product?.split("|")?.[0] || data.product
              : "N/A"}
          </td>
          {/* <td>{data.product ? data.product : "N/A"}</td> */}

          <td>
            <OverlayTrigger
              trigger="click"
              placement="left"
              overlay={
                <Popover
                  id="popover-positioned-left"
                  title="Popover left"
                  className="cursor-pointer"
                >
                  <div className="popover-body">{remarksData[data.remark]}</div>
                </Popover>
              }
            >
              <div className="cursor-pointer">{data.remark}</div>
            </OverlayTrigger>
          </td>
        </tr>
      );
    },
  },
];

export default oneCoinTableRow;
