import Snackbar from "awesome-snackbar";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PasswordField from "../components/reusable/PasswordField";
import { forgetPassword, getOTP } from "../services/auth";
import { useTranslation } from "react-i18next";
import CustomHeader from "../components/reusable/Header";
import TopHearder from "../components/reusable/TopHearder";
import FormErrorMessage from "../components/reusable/FormErrorMessage";
import { OverlayTrigger, Popover, PopoverBody, Spinner } from "react-bootstrap";

function ForgetPassword(): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedPrefix, setSelectedPrefix] = useState("+60");
  const [mobileNumber, setMobileNumber] = useState("");
  const [prefixVerifCode, setPrefixVerifCode] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [password, setPassword] = useState("");
  const [retypePassword, setRetypePassword] = useState("");
  const [registerBy, setRegisterBy] = useState("registeredByMobile");
  const [isMobileNumberValid, setIsMobileNumberValid] = useState(false);

  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isEmailAddressValid, setIsEmailAddressValid] = useState(false);

  const [isOtpValid, setIsOtpValid] = useState(false);
  const [timer, setTimer] = useState(0);
  const [isTimerStart, setIsTimerStart] = useState(false);
  const [isCodeResent, setIsCodeResent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [intervalTimer, setIntervalTimer] = useState<NodeJS.Timer>();

  // HANDLE TIMER - START
  useEffect(() => {
    if (isTimerStart) {
      setTimer(60);
      const interval = setInterval(() => {
        setTimer((seconds) => {
          return seconds - 1;
        });
      }, 1000);
      setIntervalTimer(interval);
    }
  }, [isTimerStart]);
  useEffect(() => {
    if (timer === 0) {
      clearInterval(intervalTimer);
      setIsTimerStart(false);
    }
  }, [timer, intervalTimer]);
  const formatTimer = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secondsLeft = seconds % 60;
    const formatted = `${minutes.toString().padStart(2, "0")}:${secondsLeft
      .toString()
      .padStart(2, "0")}`;
    return formatted;
  };
  // HANDLE TIMER - END

  const handleMobileNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMobileNumber(e.target.value);
  };
  const handleVerify = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVerificationCode(e.target.value);
  };

  const handleRegisterBy: React.ChangeEventHandler<HTMLSelectElement> = (
    event
  ) => {
    const value = event.target.value;
    setRegisterBy(value);
  };

  const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };
  const handleRetypePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRetypePassword(e.target.value);
  };
  const handlePrefixChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedPrefix(event.target.value);
  };
  const handleGetOtp = async (event?: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    if (isMobileNumberValid && event != null) {
      return;
    }
    if (parseInt(mobileNumber.trim()) > 0) {
      setIsTimerStart(true);
      const prefix = await getOTP({
        country_phone_code: selectedPrefix,
        phone_number: mobileNumber,
      });
      if (prefix != null) {
        setPrefixVerifCode(prefix);
        setIsMobileNumberValid(true);
      }
    }
  };

  const handleEmailGetOtp = async (
    event?: React.FormEvent<HTMLFormElement>
  ) => {
    event?.preventDefault();
    if (isEmailValid) {
      setIsLoading(true);

      setIsTimerStart(true);
      const prefix = await getOTP({ email: email });
      if (prefix != null) {
        setPrefixVerifCode(prefix);
        // setIsMobileNumberValid(true);
        setIsEmailAddressValid(true);
      }
    }
    setIsLoading(false);
  };

  const handleResendCode: React.MouseEventHandler<HTMLParagraphElement> = (
    event
  ) => {
    if (!isTimerStart) {
      // setIsCodeResent(true);
      // handleGetOtp();
      if (registerBy === "registeredByMobile") {
        handleGetOtp();
      } else {
        handleEmailGetOtp();
      }
    }
  };
  const handleVerifyOtp: React.MouseEventHandler<HTMLFormElement> = async (
    event
  ) => {
    event.preventDefault();
    if (verificationCode.trim() && !isOtpValid) {
      setIsOtpValid(true);
    }
  };

  const handleForgetPass: React.MouseEventHandler<HTMLButtonElement> = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    // checks for password
    if (password !== retypePassword) {
      // display message passward match failed
      new Snackbar(t("passwordMismatch"), {
        position: "bottom-center",
      });
      return;
    }

    if (registerBy === "registeredByMobile") {
      forgetPassword({
        country_code: selectedPrefix,
        mobile_number: mobileNumber,
        vcode: `${prefixVerifCode}-${verificationCode}`,
        password: password,
      }).then((isSuccess) => {
        if (isSuccess.result_code === 200) {
          new Snackbar(t("changePwdInfo"), {
            position: "bottom-center",
          });
          navigate("/");
        } else {
          new Snackbar(t(`${isSuccess.reason}`), {
            position: "bottom-center",
          });
        }
      });
    } else {
      forgetPassword({
        email: email,
        vcode: `${prefixVerifCode}-${verificationCode}`,
        password: password,
      }).then((isSuccess) => {
        // console.log("isSuccess", isSuccess);
        if (isSuccess) {
          if (isSuccess.result_code === 200) {
            new Snackbar(t("changePwdInfo"), {
              position: "bottom-center",
            });
            navigate("/");
          } else {
            new Snackbar(t(`${isSuccess.reason}`), {
              position: "bottom-center",
            });
          }
        }
      });
    }
  };

  return (
    <>
      <div>
        <CustomHeader publics={true} title={t("login")} />
        <TopHearder title={t("forgetPassword")} isBackRoute={true} />
        <div className="bg-dark">
          <div className="container">
            <div className="inner-box">
              <div className="row my-4 align-items-center ">
                <div className="col-12 d-flex justify-content-between align-items-center">
                  <label className="label me-2">{t("registerBy")}</label>
                  <select
                    className="form-select w-auto px-5"
                    onChange={handleRegisterBy}
                    // onKeyDown={handleKeyDown}
                    disabled={isMobileNumberValid || isEmailAddressValid}
                  >
                    <option value="registeredByMobile">
                      {t("mobileNumber")}
                    </option>
                    <option value="registeredByEmail">
                      {t("emailAddress")}
                    </option>
                  </select>
                </div>
              </div>
              <div className="">
                {registerBy === "registeredByMobile" ? (
                  <form onSubmit={(e) => handleGetOtp(e)}>
                    <div className="row align-items-center g-2 mb-3">
                      <label className="label mb-1">
                        {" "}
                        {t("yourMobileNo")}{" "}
                      </label>
                      <div className="col-9">
                        <div className="input-group">
                          <div className="input-group-addon addon-grp">
                            <select
                              style={{ cursor: "pointer" }}
                              defaultValue={selectedPrefix}
                              value={selectedPrefix}
                              onChange={handlePrefixChange}
                              disabled={isMobileNumberValid}
                            >
                              <option value={"852"}>+852</option>
                              <option value={"853"}>+853</option>
                              <option value={"91"}>+91</option>
                              <option value={"60"}>+60</option>
                              {/* TODO: TES*/}
                              <option value={"62"}>+62</option>
                              <option value={"65"}>+65</option>
                            </select>
                          </div>
                          <input
                            className="form-control input addon-input-r"
                            type="tel"
                            pattern="[0-9]+"
                            style={{ color: "white" }}
                            value={mobileNumber}
                            disabled={isMobileNumberValid}
                            maxLength={12}
                            onChange={(e) =>
                              setMobileNumber(
                                e.target.value.replace(/[^\d]/g, "")
                              )
                            }
                          />
                          {isMobileNumberValid && (
                            <div className="input-group-addon-r">
                              <i className="fa fa-check"></i>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-3 text-center">
                        {!isMobileNumberValid && (
                          <button
                            className={
                              parseInt(mobileNumber.trim()) > 0 &&
                              !isMobileNumberValid
                                ? "primary-button button btn btn-primary px-3 py-1"
                                : "light-button button w-100 btn btn-primary"
                            }
                          >
                            {t("getCode")}
                          </button>
                        )}
                        {isMobileNumberValid && !isOtpValid && (
                          <div>
                            <p
                              className="light-text"
                              style={{
                                color: isTimerStart ? "#6D6E71" : "#F5405F",
                                cursor: "pointer",
                              }}
                              onClick={handleResendCode}
                            >
                              {t("resendCode")}
                            </p>
                            {isTimerStart && (
                              <p
                                className="light-text"
                                style={{ color: "#F5405F" }}
                              >
                                {formatTimer(timer)}
                              </p>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                ) : (
                  <form onSubmit={(e) => handleEmailGetOtp(e)}>
                    <div className="row align-items-center g-2">
                      <label className="label mb-1">
                        {" "}
                        {t("yourEmailAddress")}{" "}
                        {/* <OverlayTrigger
                        placement="top"
                        trigger="click"
                        overlay={
                          <Popover>
                            <PopoverBody>{t("emailReInfo")}</PopoverBody>
                          </Popover>
                        }
                      >
                        <button type="button" className="exc-btn mx-2">
                          <i className="fa-solid fa-exclamation"></i>
                        </button>
                      </OverlayTrigger> */}
                      </label>
                      <div className="col-9">
                        <div className="input-group fss-25">
                          <input
                            className="form-control input addon-input-r"
                            type="email"
                            // pattern="[0-9]+"
                            style={{ color: "white" }}
                            value={email}
                            disabled={isEmailAddressValid}
                            onChange={(e) => {
                              const inputEmail = e.target.value;
                              setEmail(inputEmail);
                              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                              const isValidEmail = emailRegex.test(inputEmail);
                              setIsEmailValid(isValidEmail);
                            }}
                            maxLength={50}
                          />
                          {(isMobileNumberValid || isEmailAddressValid) && (
                            <div className="input-group-addon-r">
                              <i className="fa fa-check"></i>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-3 text-center">
                        {!isCodeResent &&
                          !isOtpValid &&
                          !isEmailAddressValid && (
                            <button
                              className={
                                isEmailValid && !isEmailAddressValid
                                  ? isLoading
                                    ? "primary-button button btn btn-primary px-3 py-1 disabled"
                                    : "primary-button button btn btn-primary px-3 py-1"
                                  : "light-button button w-100 btn btn-primary"
                              }
                            >
                              {isLoading ? (
                                <Spinner
                                  animation="border"
                                  variant="light"
                                  size="sm"
                                />
                              ) : (
                                t("getCode")
                              )}
                            </button>
                          )}
                        {(isMobileNumberValid || isEmailAddressValid) &&
                          !isOtpValid && (
                            <div>
                              <p
                                className="light-text"
                                style={{
                                  color: isTimerStart ? "#6D6E71" : "#F5405F",
                                  cursor: "pointer",
                                }}
                                onClick={handleResendCode}
                              >
                                {t("resendCode")}
                              </p>
                              {isTimerStart && (
                                <p
                                  className="light-text"
                                  style={{ color: "#F5405F" }}
                                >
                                  {formatTimer(timer)}
                                </p>
                              )}
                            </div>
                          )}
                      </div>
                    </div>
                  </form>
                )}
                {(isMobileNumberValid || isEmailAddressValid) && (
                  <form onSubmit={handleVerifyOtp}>
                    <div className="row align-items-end g-2 mb-5">
                      <label className="label mb-1">
                        {" "}
                        {t("verificationCode")}{" "}
                      </label>
                      <div className="col-9">
                        <div className="input-group">
                          <input
                            className="form-control input addon-input-r"
                            type="text"
                            style={{ color: "white", flex: 0.15 }}
                            value={prefixVerifCode}
                            disabled={isOtpValid || prefixVerifCode !== ""}
                            onChange={(e) =>
                              setPrefixVerifCode(
                                e.target.value.replace(/[^a-zA-Z]/g, "")
                              )
                            }
                          />
                          <input
                            className="form-control input addon-input-r"
                            type="tel"
                            style={{ color: "white" }}
                            value={verificationCode}
                            disabled={isOtpValid}
                            maxLength={6}
                            onChange={(e) =>
                              setVerificationCode(
                                e.target.value.replace(/[^\d]/g, "")
                              )
                            }
                          />
                          {isOtpValid && (
                            <div className="input-group-addon-r">
                              <i className="fa fa-check"></i>
                            </div>
                          )}
                        </div>
                      </div>
                      {/* <div className='col-3 text-center'>
											<button
												className={
													prefixVerifCode.length > 0 &&
														verificationCode.trim().length > 0 &&
														!isOtpValid
														? "primary-button button btn btn-primary px-3 py-1"
														: "light-button button w-100 btn btn-primary"
												}
											>
												{t('submit')}
											</button>
										</div> */}
                    </div>
                  </form>
                )}

                {(isMobileNumberValid || isEmailAddressValid) && (
                  <>
                    {/* <div className="row align-items-center mb-3 g-1">
                        <PasswordField label="Old Password" passwordValue={oldPassword} passwordOnChange={handleOldPassword} />
                      </div> */}

                    <div className="row align-items-center mb-3 g-1">
                      <PasswordField
                        label={t("createNewPwd")}
                        passwordValue={password}
                        passwordOnChange={handlePassword}
                      />
                      {password.length < 6 && password != "" && (
                        <FormErrorMessage
                          message={t("registerPwdValidation")}
                        />
                      )}
                    </div>

                    <div className="row align-items-center mb-3 g-1">
                      <PasswordField
                        label={t("retypeNewPwd")}
                        passwordValue={retypePassword}
                        passwordOnChange={handleRetypePassword}
                      />
                      {password !== retypePassword && retypePassword !== "" && (
                        <>
                          <FormErrorMessage message={t("passwordMismatch")} />
                          <br />
                        </>
                      )}
                    </div>
                  </>
                )}

                <div className="text-center mt-5 d-flex justify-content-center gap-2">
                  {/* <button  className="w-100"> */}
                  <button
                    className="secondary-button button mt-3 btn btn-primary w-100 "
                    onClick={(e) => navigate("/")}
                  >
                    {t("back")}
                  </button>
                  {/* </button> */}
                  {/* <button className="w-100"> */}
                  <button
                    className="secondary-button button mt-3 btn btn-primary w-100"
                    onClick={handleForgetPass}
                    disabled={
                      (!isEmailAddressValid && !isMobileNumberValid) ||
                      password !== retypePassword ||
                      verificationCode.length !== 6
                    }
                  >
                    {t("submit")}
                  </button>
                  {/* </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modle */}
    </>
  );
}

export default ForgetPassword;
