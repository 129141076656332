import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import AppRoutePath from "../../../../constants/AppRoutePath";
import SizedBox from "../../../reusable/Sizedbox";
import WalletCardItemComp from "./WalletCardItemComp";
import { sumBalances } from "../../../../helper/format-helper";
import { useTranslation } from "react-i18next";

export interface IFlowerCardCompProps {
  coin?: any;
  ranking?: Ranking;
}

export default function FlowerCardComp({
  coin = [],
  ranking = { rank: "N/A", units: "N/A" },
}: IFlowerCardCompProps) {
  const navigate = useNavigate();
  const isMaster = window.location.pathname.includes("master");
  const flowerIcon = <img src="/img/flower_icon.png" alt="" />;
  const langType = localStorage.getItem("i18nextLng");
  const MasterAdsCount = localStorage.getItem("adsTotal");
  const userRoles = localStorage.getItem("role");
  const EXT = localStorage.getItem("EXT");

  console.log("EXTddf ", EXT);

  const { t } = useTranslation();
  return (
    <div className="card  d-flex flex-row bd-highlight text-center bg-white">
      <div className="card-body">
        <h5 className="balance-title mb-0">{t("currentFlowerRanking")}</h5>
        <h5 className="balance-value d-flex align-items-center justify-content-center gap-1">
          {ranking.rank ?? "N/A"}
        </h5>
        <hr style={{ borderTop: "1.5px solid #ff3751" }} />
        <WalletCardItemComp
          title={t("fWallwrBAL")}
          // icon={flowerIcon}
          // value={Number(sumBalances(coin))}
          langType={langType || "en"}
        />

        {coin &&
          coin?.map((data: any, index: any) => {
            const { prod_name, quantity, remark } = data;
            const [englishName, chineseName] = prod_name.split("|");
            return (
              <>
                <WalletCardItemComp
                  engTitle={englishName}
                  ChiTitle={chineseName}
                  icon={flowerIcon}
                  value={quantity}
                  langType={langType || "en"}
                />
              </>
            );
          })}

        <div className="abs-btn2 position-absolute d-flex justify-content-center gap-2">
          {(MasterAdsCount !== "0" && userRoles === "master") ||
          (userRoles === "client" && (EXT === "false" || EXT === null)) ? (
            <>
              <button
                className={`btn primary-button rounded-button coin-btn w-80 m-auto`}
                style={{ height: 50 }}
                onClick={() =>
                  navigate(
                    `${
                      isMaster
                        ? AppRoutePath.masterWallet
                        : AppRoutePath.clientWallet
                    }/flower/reload`
                  )
                }
              >
                <i className="fa fa-shopping-cart"></i> {t("ReloadFlower")}
              </button>
            </>
          ) : null}

          <SizedBox width={5} />
          {!isMaster && (
            <button
              className={`btn primary-button rounded-button coin-btn w-80 m-auto`}
              style={{ height: 50 }}
              onClick={() => navigate(AppRoutePath.clientAddFlower)}
            >
              {t("ReloadFlowerAD")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
