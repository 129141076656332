import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Overlay,
  OverlayTrigger,
  Popover,
  PopoverBody,
} from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import AppRoutePath from "../../constants/AppRoutePath";
import {
  controlAdClient,
  controlBlogClient,
  controlSpecialCatalogue,
  controlBlogDetails,
  controlSpecialCat,
} from "../../services/client";
import { Button, Spinner } from "react-bootstrap";
import { getClientPrice, getTypes } from "../../services/modules";
import CustomNavigation from "../reusable/Navigation";
import { getHeartBalance } from "../../services/products/heart";
import { findFlowerBalance } from "../../services/products/flower";
import { findCoinBalance } from "../../services/products/one-coin";
import AccountTypeConst from "../../domain/enum/account-type";
import { getAdsMaster } from "../../services/master/master-ad";
import { ClientProfileModel } from "../../domain/model/client-profile-model";
import { getClientProfile } from "../../services/client/client-profile";
import { getFollowerList } from "../../services/client/client-follow";
import { findAdMedia } from "../../services/ad-media";
import LocalStorageKey from "../../constants/LocalStorageKey";
import { WalletType } from "../../constants/WalletType";

import { getSpecialCatalogue } from "../../services/modules";
import {
  deleteSpecialPhoto,
  getSpecialPhoto,
  uploadSpecialPhoto,
  setMainSpecialPhoto,
  getMainSpecialPhoto,
} from "../../services/client/client-special-photo";
import CheckboxPhotoGallery from "../reusable/CheckboxPhotoGallery";
import { uploadFileToS3 } from "../../services/upload";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

import Snackbar from "awesome-snackbar";
import TopHearder from "../reusable/TopHearder";
import CustomHeader from "../reusable/Header";
import {
  getTChatLanguages,
  setTChatLanguages,
  getUserTChatLanguages,
  getTchatUrl,
} from "../../services/tChat/chats";
import { TChatModel1 } from "../../domain/model/t-chat-model";
import ScreenLoaders from "../reusable/ScreenLoader";

function ClientMainPage() {
  const langType = localStorage.getItem("i18nextLng");
  const isMaster = window.location.pathname.includes("master");
  const isRoleMaster = localStorage.getItem("role") == "master";
  const isEmailLogin = localStorage.getItem("email_login") == "true";
  const navigate = useNavigate();
  const { success } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [clientProfile, setClientProfile] =
    React.useState<ClientProfileModel | null>(null);
  const [followerCount, setFollowerCount] = React.useState(0);
  // const [ad, setAd] = useState(0);
  const [ad, setAd] = useState<number | null>(0);
  const [advertisement, setAdvertisement] = useState("");
  // const [advertisementTemp, setAdvertisementTemp] = useState("on");

  const [specialCatalogueStatus, setSpecialCatalogueStatus] = useState(false);
  // const [specialCatalogue, setSpecialCatalogue] = useState("1"); // ID or UUID of catalogue
  // const [specialCatalogue, setSpecialCatalogue] = useState<string>(); // ID or UUID of catalogue
  const [memberPromotionPlan, setMemberPromotionPlan] = useState("1"); // ID or UUID of  plan
  const [isAdvertisementModalOpen, setIsAdvertisementModalOpen] =
    useState(false);
  const [isAdsModalOpen, setIsAdsModalOpen] = useState(false);
  const [isAdsOff, setIsAdsOff] = useState(false);
  const [blog, setBlog] = useState(false);
  const [isBlogModalOpen, setIsBlogModalOpen] = useState(false);
  const [isBlogOff, setIsBlogOff] = useState(false);
  const [isBlogOn, setIsBlogOn] = React.useState(false);
  const params = useParams();
  const [isLoadingScreen, setIsLoadingScreen] = useState(false);

  const [heartBalance, setHeartBalance] = useState([]);
  const [coinBalance, setCoinBalance] = useState(0);
  const [flowerBalance, setFlowerBalance] = useState([]);
  const [profilePhoto, setProfilPhoto] = React.useState("");

  const [blogDetails, setBlogDetails] = useState("");
  const [blogDetailsCount, setBlogDetailsCount] = useState(0);
  const [notifCount, setNotifCount] = useState(0);
  // tchat

  const [tChatGenerateLink, setTChatGenerateLink] = React.useState("");
  const [tChatModelOpen, setTChatModelOpen] = React.useState(false);
  const [isTChatLoading, setIsTChatLoading] = React.useState(false);

  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const { t } = useTranslation();

  const [specialCatalogueList, setSpecialCatalogueList] = useState<string[]>(
    []
  );
  const [specialCatalogueLists, setSpecialCatalogueLists] = useState<any>();

  const [selectSpecialCat, setSelectSpecialCat] = useState<string>(
    clientProfile?.details?.basic_desc?.special_cat ?? ""
  );
  const [catalogNumber, setCatalogNumber] = useState<any>(null);

  const [isNavOpen, setIsNavOpen] = useState(true);
  const isEXT = localStorage.getItem("EXT") == "true";
  const blogToggleRef = useRef<HTMLInputElement>(null);
  const [blogTogglePopoverVisibility, setBlogTogglePopoverVisibility] =
    useState(false);
  const specialCatToggleRef = useRef<HTMLInputElement>(null);
  const [
    specialCatTogglePopoverVisibility,
    setSpecialCatTogglePopoverVisibility,
  ] = useState(false);
  const [types, setTypes] = useState<string[]>([]);
  // const [tChatLang, setTChatLang] =  React.useState<TChatModel | null>(null);
  const [tChatLang, setTChatLang] = useState<TChatModel1[]>([]);

  const [tChats, setTChats] = useState("");

  const typeCheck = useRef<HTMLSelectElement>(null);
  const [typeTogglePopoverVisibility, setTypeTogglePopoverVisibility] =
    useState(false);
  const [type, setType] = useState<string>(
    clientProfile?.details?.basic_desc?.type ?? ""
  );
  const [isSpecialCatalogueOn, setIsSpecialCatalogueOn] = React.useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const [isMasterClientsEXT, setIsMasterClientsEXT] = React.useState(false);

  const [sendFlowerModal, setSendFlowerModal] = useState(false);
  const [sendFlower, setSendFlower] = useState(false);
  const [activePopover, setActivePopover] = useState(null);

  const handlePopoverToggle = (popoverType: any) => {
    if (activePopover === popoverType) {
      setActivePopover(null);
    } else {
      setActivePopover(popoverType);
    }
  };

  const handleSendFlowerShowPopover = () => {
    setSendFlower(false);
  };
  const [TypeShowPopover, setTypeShowPopover] = useState(false);

  const [BlogAdsShowPopover, setBlogAdsShowPopover] = useState(false);

  const handleBlogAdsShowPopover = () => {
    setBlogAdsShowPopover(false);
  };

  const [adPrice, setAdPrice] = React.useState(0);
  const [blogPrice, setBlogPrice] = React.useState(0);

  useEffect(() => {
    getTypes().then((types) => {
      setTypes(types);
    });
    getTChatLanguages().then((res: any) => {
      setTChatLang(res);
    });
    getUserTChatLanguages().then((res: any) => {
      setTChats(res.lang);
    });
  }, []);

  useEffect(() => {
    setIsLoadingScreen(true);
    if (isMaster && (success ?? "success") !== "success") {
      getAdsMaster(success!).then((res) => {
        if (res != null) {
          setClientProfile(res);
          if (res?.details?.blog_details?.basic_desc) {
            setBlogDetails(res.details.blog_details.basic_desc);
          }
          if (res.details?.basic_desc?.special_cat) {
            setSelectSpecialCat(res.details?.basic_desc?.special_cat);
          }
          let masterClientsEXT1;
          if (res?.mobile !== null) {
            masterClientsEXT1 = res?.mobile?.includes("EXT");
          } else {
            masterClientsEXT1 = res?.email?.includes("EXT");
          }

          setIsMasterClientsEXT(masterClientsEXT1 || false);

          if (res.blog_status === 0) {
            setIsBlogOn(false);
            setBlog(false);
          } else {
            setIsBlogOn(true);
            setBlog(true);
          }

          if (res.ad_status === 1) {
            setAd(1);
          } else if (res.ad_status === 2) {
            setAd(2);
          } else {
            setAd(0);
            setIsBlogOn(true);
            setBlog(false);
          }

          if (res.ad_status === 1 && res.blog_status === 0) {
            setAd(1);
            setIsBlogOn(true);
          } else if (res.ad_status === 1 && res.blog_status === 1) {
            setAd(1);
            setIsBlogOn(false);
          }

          setIsLoadingScreen(false);
        }
      });
    } else {
      getClientProfile().then((res) => {
        setClientProfile(res);
        if (res !== null) {
          setIsLoadingScreen(false);
          if (res?.details?.blog_details?.basic_desc) {
            setBlogDetails(res.details.blog_details.basic_desc);
          }
          if (res.details?.basic_desc?.special_cat) {
            setSelectSpecialCat(res.details?.basic_desc?.special_cat);
          }

          // setIsBlogOn((res.blog ?? 0) === 1);

          if (res.blog_status === 0) {
            setIsBlogOn(false);
            setBlog(false);
          } else {
            setIsBlogOn(true);
            setBlog(true);
          }

          if (res.ad_status === 1) {
            setAd(1);
          } else if (res.ad_status === 2) {
            setAd(2);
          } else {
            setAd(0);
            setIsBlogOn(true);
            setBlog(false);
          }

          if (res.ad_status === 1 && res.blog_status === 0) {
            setAd(1);
            setIsBlogOn(true);
          } else if (res.ad_status === 1 && res.blog_status === 1) {
            setAd(1);
            setIsBlogOn(false);
          }

          const specialCatValue = res?.details?.basic_desc?.special_cat;
          if (specialCatValue != undefined) {
            setSelectSpecialCat(specialCatValue);
          }

          const userType = res?.details?.basic_desc?.type;
          const userId = res?.user_id;
          localStorage.setItem("user_id", userId);
          if (userType != "") {
            localStorage.setItem("userType", userType || "");
            getClientPrice(userType).then((response) => {
              if (response != null) {
                localStorage.setItem("priceList", JSON.stringify(response));

                for (const key in response) {
                  if (key.includes("AD")) {
                    setAdPrice(response[key]);
                  }
                  if (key.includes("BLOG")) {
                    setBlogPrice(response[key]);
                  }
                }
                // setClientPriceList(priceBackage);
              }
            });
          }
          // console.log("res?.mobile ", res?.mobile);

          let masterClientsEXT1;
          if (res?.mobile !== null) {
            masterClientsEXT1 = res?.mobile?.includes("EXT");
          } else {
            masterClientsEXT1 = res?.email?.includes("EXT");
          }
          setIsMasterClientsEXT(masterClientsEXT1 || false);
          async function fetchCoinBalance() {
            const result = await findCoinBalance();
            setCoinBalance(result);
          }

          // async function fetchHeartBalance() {
          //   const result = await getHeartBalance();
          //   setHeartBalance(result);
          // }

          // async function fetchFlowerBalance() {
          //   const result = await findFlowerBalance();
          //   setFlowerBalance(result);
          // }

          if (masterClientsEXT1 === false) {
            fetchCoinBalance();
            // fetchHeartBalance();
            // fetchFlowerBalance();
          }
        }
      });
    }
    const id = (success ?? "success") !== "success" ? success : undefined;
    findAdMedia(id).then((res) => {
      if (res != null) {
        for (let idx = 0; idx < res.length; idx++) {
          const item = res[idx];
          if (item.flag) {
            localStorage.setItem(
              LocalStorageKey.userProfilePicture,
              item.cache_url
            );
            setProfilPhoto(item.cache_url);
            break;
          }
        }
      }
    });
  }, []);

  const getMasterFlowerBalance = async () => {
    const result = await findFlowerBalance();
    // console.log("result ", result);
  };

  const handleTypeClick = () => {
    setTypeShowPopover(true); // setTimeout(() => setTypeTogglePopoverVisibility(false), 3000);
  };

  const handleBlogAdsClick = () => {
    if (ad === 0) {
      setIsBlogModalOpen(false);
      setBlogAdsShowPopover(true);
    } else if (ad === 1 && blog != true) {
      setIsBlogModalOpen(true);
      setIsBlogOff(false);
      setBlogAdsShowPopover(false);
    }
  };
  const handleBlogAdsClickOff = () => {
    if (blog) {
      setBlogAdsShowPopover(false);
      setIsBlogOff(true);
    }
  };

  const handleType: React.ChangeEventHandler<HTMLSelectElement> = async (
    event
  ) => {
    const value = event.target.value;
    setType(value);
  };
  const handleTChatLanguage: React.ChangeEventHandler<
    HTMLSelectElement
  > = async (event) => {
    const value = event.target.value;
    setTChats(value);
    setTChatLanguages(value).then((res) => {});
  };

  const handleSpecialCatalogue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (isBlogOn === false) {
      // setSpecialCatalogueStatus(true);
      setSpecialCatTogglePopoverVisibility(false);
    } else {
      setSpecialCatTogglePopoverVisibility(true);
    }

    if (isBlogOn === false || specialCatalogueStatus === true) {
      setSpecialCatalogueStatus(true);
    } else {
      setSpecialCatalogueStatus(false);
    }
  };

  useEffect(() => {
    if (ad === 0) {
      setAd(0);
      setIsBlogOn(true); //Blog ON OFF button = OFF
      setBlog(false); //Blog details
      setSpecialCatalogueStatus(false);
    }
  }, [ad, blog]);

  useEffect(() => {
    if (clientProfile?.special === true) {
      setSpecialCatalogueStatus(true);
    } else {
      setSpecialCatalogueStatus(false);
    }
  }, [clientProfile?.special]);

  // FOLLOWER COUNT
  useEffect(() => {
    getFollowerList().then((follower) => {
      if (follower != null) {
        setFollowerCount(follower.total ?? 0);
      }
    });
  }, []);

  useEffect(() => {
    getSpecialCatalogue().then((res) => {
      if (res) {
        setSpecialCatalogueList(res);
        setSpecialCatalogueLists(res);
      }
    });
  }, []);

  useEffect(() => {
    if (specialCatalogueLists && selectSpecialCat) {
      const key = Object.keys(specialCatalogueLists).find(
        (k) => specialCatalogueLists[k] === selectSpecialCat
      );
      setCatalogNumber(key);
    }
  }, [selectSpecialCat, specialCatalogueLists]);

  const handleTchats = async () => {
    setIsTChatLoading(true);
    getTchatUrl().then(async (res: any) => {
      if (res.result_code === 200) {
        // console.log("res.url", res.url);
        setTChatModelOpen(true);

        setTChatGenerateLink(res.url);
        setIsTChatLoading(false);
      }
    });
  };

  const handleSpecialCatalogChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = e.target.value;

    if (value === "") return;

    controlSpecialCatalogue({
      control: value,
      target: isMaster ? Number(success) : undefined,
    }).then((isSuccess) =>
      isSuccess
        ? (setClientProfile((state) => ({
            ...state!,
            details: {
              ...state?.details!,
              basic_desc: {
                ...state?.details?.basic_desc!,
                special_cat: value,
              },
            },
          })),
          setSelectSpecialCat(value)) // Wrap this line inside parentheses
        : // setSelectedKey(newSelectedKey))
          null
    );

    setSelectSpecialCat(value);
  };

  return (
    <>
      <Modal show={isAdvertisementModalOpen}>
        <Modal.Body>
          <div className="d-flex justify-content-center align-items-center">
            <Modal.Title>
              <h5 className="modal-title text-center">
                You need to pay
                <br />
                <center
                  style={{
                    margin: "10px 0",
                    fontSize: 30,
                    color: "#FF3751",
                  }}
                >
                  <div
                    style={{
                      display: "inline",
                      padding: "5px 10px",
                      fontSize: 20,
                      border: "1px solid #FF3751",
                      borderRadius: "100%",
                    }}
                  >
                    $
                  </div>{" "}
                  10
                </center>
                <br />
                to put your advertisement on REST
              </h5>
            </Modal.Title>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="col">
              <button
                className="button btn3 rounded-button w-100"
                onClick={() => {
                  setIsAdvertisementModalOpen(false);
                }}
              >
                No
              </button>
            </div>
            <div className="col">
              <button
                className="button primary-button rounded-button w-100"
                onClick={() => {
                  // NOTE: 2 => REST
                  controlAdClient(
                    2,
                    isMaster ? Number(success) : undefined
                  ).then((isSuccess) => {
                    if (isSuccess) {
                      setAd(2);
                      setIsAdvertisementModalOpen(false);
                    }
                  });
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal show={isAdsModalOpen}>
        <Modal.Body>
          <div className="d-flex justify-content-center align-items-center">
            <Modal.Title>
              {!clientProfile?.ad_payment ? (
                <>
                  <h5 className="modal-title text-center">
                    {t("payAdvertisement")}
                    <br />
                    <center
                      style={{
                        margin: "10px 0",
                        fontSize: 30,
                        color: "#FF3751",
                      }}
                    >
                      <div
                        style={{
                          display: "inline",
                          padding: "5px 10px",
                          fontSize: 20,
                          border: "1px solid #FF3751",
                          borderRadius: "100%",
                        }}
                      >
                        $
                      </div>{" "}
                      {adPrice}
                    </center>
                    <br />
                    {t("payAdvertisement2")}
                  </h5>
                </>
              ) : (
                <h5 className="modal-title text-center">
                  {t("paidAdvertisement")}

                  {t("payAdvertisement2")}
                </h5>
              )}
            </Modal.Title>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="col">
              <button
                className="button btn3 rounded-button w-100"
                onClick={() => {
                  setIsAdsModalOpen(false);
                }}
              >
                {t("no")}
              </button>
            </div>
            <div className="col">
              <button
                className="button primary-button rounded-button w-100"
                disabled={isLoading}
                onClick={() => {
                  // NOTE: 1 => ON
                  setIsLoading(true);
                  controlAdClient(
                    1,
                    isMaster ? Number(success) : undefined
                  ).then(async (isSuccess) => {
                    if (isSuccess) {
                      setAd(1);
                      setIsAdsModalOpen(false);
                      await findCoinBalance().then((res) => {
                        setCoinBalance(res);
                      });
                    }
                    setIsLoading(false);
                  });
                }}
              >
                {isLoading ? <Spinner size="sm" /> : <span>{t("yes")}</span>}
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal show={isAdsOff}>
        <Modal.Body>
          <div className="d-flex justify-content-center align-items-center">
            <Modal.Title>
              <h5 className="modal-title text-center">
                {t("offAdvertisement")}?
              </h5>
            </Modal.Title>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="col">
              <button
                className="button btn3 rounded-button w-100"
                onClick={() => {
                  setIsAdsOff(false);
                }}
              >
                {t("no")}
              </button>
            </div>
            <div className="col">
              <button
                className="button primary-button rounded-button w-100"
                disabled={isLoading}
                onClick={() => {
                  // NOTE: 0 => OFF
                  setIsLoading(true);
                  controlAdClient(
                    0,
                    isMaster ? Number(success) : undefined
                  ).then((isSuccess) => {
                    if (isSuccess) {
                      setAd(0);
                      setIsBlogOn(true);
                      setIsAdsOff(false);
                    }
                    setIsLoading(false);
                  });

                  if (isBlogOn === false) {
                    setIsLoading(true); // Start loading state
                    controlBlogClient(0)
                      .then((isSuccess) => {
                        if (isSuccess) {
                          setIsBlogOn(false);
                          // setSpecialCatalogueStatus(true);
                        }
                      })
                      .finally(() => {
                        setIsLoading(false); // End loading state, whether success or not
                      });
                  }
                }}
              >
                {isLoading ? <Spinner size="sm" /> : <span>{t("yes")}</span>}
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal show={isBlogModalOpen}>
        <Modal.Body>
          <div className="d-flex justify-content-center align-items-center">
            <Modal.Title>
              {!clientProfile?.blog_payment ? (
                <>
                  <h5 className="modal-title text-center">
                    {t("payAdvertisement")}
                    <br />
                    <center
                      style={{
                        margin: "10px 0",
                        fontSize: 30,
                        color: "#FF3751",
                      }}
                    >
                      <div
                        style={{
                          display: "inline",
                          padding: "5px 10px",
                          fontSize: 20,
                          border: "1px solid #FF3751",
                          borderRadius: "100%",
                        }}
                      >
                        $
                      </div>{" "}
                      {blogPrice}
                    </center>
                    <br />
                    {t("payblog")}
                  </h5>
                </>
              ) : (
                <h5 className="modal-title text-center">
                  {t("paidAdvertisement")}

                  {t("payblog")}
                </h5>
              )}
            </Modal.Title>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="col">
              <button
                className="button btn3 rounded-button w-100"
                onClick={() => {
                  setIsBlogModalOpen(false);
                }}
              >
                {t("no")}
              </button>
            </div>
            <div className="col">
              <button
                className="button primary-button rounded-button w-100"
                disabled={isLoading}
                onClick={() => {
                  setIsLoading(true);

                  controlBlogClient(
                    1,
                    isMaster ? Number(success) : undefined
                  ).then(async (res) => {
                    if (res) {
                      if (res.data.result_code !== 200) {
                        new Snackbar(
                          `${res.data.message} <br> <br> ${res.data.reason}`
                        );
                        setIsBlogOn(false);
                        setIsBlogOff(true);
                      }
                      setIsBlogOn(false);
                      setIsBlogModalOpen(false);
                      setBlog(true);
                    } else if (specialCatalogueStatus === true) {
                      setSpecialCatalogueStatus(true);
                    } else if (specialCatalogueStatus === false) {
                      setSpecialCatalogueStatus(false);
                    }
                    setIsLoading(false);
                    await findCoinBalance().then((res) => {
                      setCoinBalance(res);
                    });
                  });
                }}
              >
                {isLoading ? <Spinner size="sm" /> : <span>{t("yes")}</span>}
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal show={isBlogOff} style={{ padding: 30 }}>
        <button
          className="close-blog"
          type="button"
          aria-label="Close"
          onClick={() => {
            setIsBlogOff(false);
          }}
        >
          X
        </button>
        <Modal.Body>
          <div className="d-flex justify-content-center align-items-center">
            <Modal.Title>
              <h3 className="modal-title text-center">
                {t("reminder")} <br />
                <br />
              </h3>
              <h3 className="modal-important text-center">
                {t("important")}! <br />
                <br />
              </h3>
              <center className="modal-reminder text-center">
                {t("blogOffNote")}
                <br />
                <br />
                {t("contactCS")}
              </center>
              <br />
              <h5 className="modal-title text-center">{t("turnOffBlog")}</h5>
            </Modal.Title>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="col">
              <button
                className="button btn3 rounded-button w-100"
                onClick={() => {
                  setIsBlogOff(false);
                }}
              >
                {t("no")}
              </button>
            </div>
            <div className="col">
              <button
                className="button primary-button rounded-button w-100"
                disabled={isLoading}
                onClick={() => {
                  setIsLoading(true);
                  controlBlogClient(
                    0,
                    isMaster ? Number(success) : undefined
                  ).then((res) => {
                    if (res) {
                      if (res.data.result_code !== 200) {
                        new Snackbar(
                          `${res.data.message} <br> <br> ${res.data.reason}`
                        );
                        setIsBlogOff(false);
                        setBlog(false);
                      } else {
                        setBlog(false);
                        setIsBlogOn(true);
                        setIsBlogOff(false);
                        setSpecialCatalogueStatus(false);
                      }
                    }
                    setIsLoading(false);
                  });
                }}
              >
                {isLoading ? <Spinner size="sm" /> : <span>{t("yes")}</span>}
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal show={success === "success"}>
        <Modal.Body>
          <Modal.Title>
            <h5 className="modal-title">
              {t("individualAcctRegisterSuccessful")}
            </h5>
          </Modal.Title>
        </Modal.Body>

        <Modal.Footer>
          <Link to="/client/add-details">
            <button
              type="button"
              className="btn primary-button button w-50 m-auto mt-2"
              data-bs-dismiss="modal"
            >
              {t("done")}
            </button>
          </Link>
        </Modal.Footer>
      </Modal>

      <CustomNavigation
        isOpen={isNavOpen}
        onCallback={(value) => {
          setIsNavOpen(value);
        }}
        accountType={
          isMaster ? AccountTypeConst.Master : AccountTypeConst.Client
        }
      />

      <CustomHeader />

      <TopHearder title={t("clientMainPage")} />

      {isLoadingScreen ? (
        <ScreenLoaders />
      ) : (
        <>
          <div className="bg-dark">
            <div className="container">
              <div className="inner-box pt-4 pb-4">
                <div className="row g-0 mb-3 pb-3 rgd-mob">
                  <div className="col-6">
                    {!isEXT && (
                      <>
                        {!isMaster && (
                          <>
                            <span>
                              {" "}
                              {clientProfile?.mobile !== null
                                ? t("registeredMobile")
                                : t("registeredEmail")}
                            </span>
                            {/* <Link to="/change-register-number"> */}

                            {clientProfile?.mobile !== null ? (
                              <Link
                                to={
                                  isMaster
                                    ? AppRoutePath.masterChangeRegisterNumber
                                    : AppRoutePath.clientChangeRegisterNumber
                                }
                              >
                                {t("changeRegisteredMobile")}
                              </Link>
                            ) : (
                              <Link
                                to={
                                  isMaster
                                    ? AppRoutePath.masterChangeRegisterEmail
                                    : AppRoutePath.clientChangeRegisterEmail
                                }
                              >
                                {"changeRegisteredEmail"}
                              </Link>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                  <div className="col-6 text-end">
                    {/* registeredEmail */}
                    {clientProfile?.mobile !== null ? (
                      <span>+{clientProfile?.mobile}</span>
                    ) : (
                      <span className="text-break">{clientProfile?.email}</span>
                    )}

                    {/* {!isMaster && ( */}
                    <Link
                      to={
                        isMaster
                          ? `${AppRoutePath.masterAdsChangePassword}/${success}`
                          : AppRoutePath.clientChangePassword
                      }
                    >
                      {t("changePassword")}
                    </Link>
                    {/* )} */}
                  </div>
                </div>

                <div className="row">
                  <div className="col-4">
                    <div className="clt-img-profile">
                      <img
                        src={
                          clientProfile?.photo !== null
                            ? clientProfile?.photo
                            : "/img/blank-profile-picture.png"
                        }
                        alt=""
                        className="obj-cov"
                      />
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="pro-edt">
                      <div className="row text-end">
                        <div className="col-12 mb-3">
                          <h4 className="mb-0 qr-title">
                            {clientProfile?.en_name}{" "}
                            <span style={{ fontSize: 12 }}>
                              ({clientProfile?.zh_name})
                            </span>
                          </h4>
                          <div className="mb-2" />
                          <Link
                            to={
                              isMaster
                                ? `${AppRoutePath.masterEditDetails}/edit/${success}`
                                : AppRoutePath.clientEditProfile
                            }
                          >
                            <button className="btn primary-button rounded-button">
                              {t("editProfile")}
                            </button>
                          </Link>
                        </div>
                        {/* <div className="col-12 mb-3 ">
                          <OverlayTrigger
                            placement="top"
                            trigger="click"
                            show={activePopover === "clickScanner"}
                            onToggle={() => handlePopoverToggle("clickScanner")}
                            overlay={
                              <Popover>
                                <button
                                  type="button"
                                  className="close-popover"
                                  onClick={() =>
                                    handlePopoverToggle("clickScanner")
                                  }
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>

                                <PopoverBody>{t("clickScanner")}</PopoverBody>
                              </Popover>
                            }
                          >
                            <button type="button" className="exc-btn mx-2">
                              <i className="fa-solid fa-exclamation"></i>
                            </button>
                          </OverlayTrigger>
                          <Link to="/client/booking-scanner">
                            <button className="btn primary-button rounded-button">
                              <i className="fa-solid fa-qrcode"></i>{" "}
                              {t("bookingScanner")}
                            </button>
                          </Link>
                        </div> */}

                        {!isMaster && (
                          <>
                            <div className="col-12 mb-3">
                              <OverlayTrigger
                                placement="top"
                                trigger="click"
                                show={activePopover === "clientQRCodeClick"}
                                onToggle={() =>
                                  handlePopoverToggle("clientQRCodeClick")
                                }
                                overlay={
                                  <Popover>
                                    <button
                                      type="button"
                                      className="close-popover"
                                      onClick={() =>
                                        handlePopoverToggle("clientQRCodeClick")
                                      }
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>

                                    <PopoverBody>
                                      {t("clientQRCodeClick")}
                                    </PopoverBody>
                                  </Popover>
                                }
                              >
                                <button type="button" className="exc-btn mx-2">
                                  <i className="fa-solid fa-exclamation"></i>
                                </button>
                              </OverlayTrigger>
                              <Link
                                to={
                                  isMaster
                                    ? `${AppRoutePath.masterClientQRCodePage}/${success}`
                                    : AppRoutePath.clinetQRCodePage
                                }

                                // to="/client/qr-code"
                              >
                                <button className="btn primary-button rounded-button">
                                  <i className="fa-solid fa-qrcode"></i>{" "}
                                  {t("clientQRCode")}
                                </button>
                              </Link>
                            </div>
                          </>
                        )}

                        {!isMaster && !isRoleMaster && (
                          <>
                            <div className="col-12">
                              <OverlayTrigger
                                placement="top"
                                trigger="click"
                                show={activePopover === "clickChatLogo"}
                                onToggle={() =>
                                  handlePopoverToggle("clickChatLogo")
                                }
                                overlay={
                                  <Popover>
                                    <button
                                      type="button"
                                      className="close-popover"
                                      onClick={() =>
                                        handlePopoverToggle("clickChatLogo")
                                      }
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>

                                    <PopoverBody>
                                      {t("clickChatLogo")}
                                    </PopoverBody>
                                  </Popover>
                                }
                              >
                                <button type="button" className="exc-btn mx-2">
                                  <i className="fa-solid fa-exclamation"></i>
                                </button>
                              </OverlayTrigger>
                              {isTChatLoading ? (
                                <>
                                  <button className="btn primary-button rounded-button">
                                    {" "}
                                    <Spinner size="sm" />
                                  </button>
                                </>
                              ) : (
                                <button
                                  className="t-chat cursor-pointer color-pink-2 bg-transparent"
                                  onClick={() => handleTchats()}
                                >
                                  <img
                                    style={{ width: 20, marginRight: 5 }}
                                    src="/img/tchat_newlogo.png"
                                    className="default-logo"
                                  />
                                  {/* </i>  */}
                                  {t("tchat")}
                                  {/* <span>0</span> */}
                                </button>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-dark2">
              <div className="container">
                <div className="plan-wrap pt-4 pb-4">
                  {/* {isMasterClientsEXT && (
                <>
                  {" "}
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <label htmlFor="" className="label">
                      {t("sendFlower")}
                      <OverlayTrigger
                        placement="top"
                        trigger="click"
                        show={sendFlower}
                        onToggle={setSendFlower}
                        overlay={
                          <Popover>
                            {sendFlower && (
                              <button
                                type="button"
                                className="close-popover"
                                onClick={handleSendFlowerShowPopover}
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            )}
                            <PopoverBody>{t("sendFlowerInfo")}</PopoverBody>
                          </Popover>
                        }
                      >
                        <button type="button" className="exc-btn mx-2">
                          <i className="fa-solid fa-exclamation"></i>
                        </button>
                      </OverlayTrigger>
                    </label>{" "}
                    <button
                      className="btn primary-button rounded-button"
                      onClick={() => {
                        // setSendFlowerModal(true);
                        getMasterFlowerBalance();
                      }}
                    >
                      {t("sendFlower")}
                    </button>
                  </div>{" "}
                </>
              )} */}

                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <label htmlFor="" className="label">
                      {t("tchatLang")}
                    </label>

                    <div>
                      <select
                        // ref={typeCheck}
                        // disabled={true}

                        value={tChats}
                        className="form-select input-md w-100 "
                        id="typeSelectOption"
                        onChange={handleTChatLanguage}
                      >
                        <option value="" disabled selected>
                          Please select TChat Language
                        </option>

                        {Object.entries(tChatLang).map(([key, value]) => {
                          const abbr = value.abbr;
                          const name = value.name;
                          return (
                            <option value={abbr} key={key + 1}>
                              {name}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    {/* <Link
                  to={
                    isMaster
                      ? AppRoutePath.masterAddDetails
                      : AppRoutePath.clientAddDetails
                  }
                  className="his-btn"
                >
                  Change type
                </Link> */}
                  </div>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <label htmlFor="" className="label">
                      #{followerCount} {t("followers")}
                      <OverlayTrigger
                        placement="top"
                        trigger="click"
                        show={activePopover === "findFollower"}
                        onToggle={() => handlePopoverToggle("findFollower")}
                        overlay={
                          <Popover>
                            <button
                              type="button"
                              className="close-popover"
                              onClick={() =>
                                handlePopoverToggle("findFollower")
                              }
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>

                            <PopoverBody>{t("findFollower")}</PopoverBody>
                          </Popover>
                        }
                      >
                        <button type="button" className="exc-btn mx-2">
                          <i className="fa-solid fa-exclamation"></i>
                        </button>
                      </OverlayTrigger>
                    </label>
                    <Link
                      to={
                        isMaster
                          ? AppRoutePath.masterFollowerList
                          : AppRoutePath.clientFollowerList
                      }
                    >
                      <button className="btn primary-button rounded-button">
                        <i className="fa-solid fa-bars"></i> {t("followerList")}
                      </button>
                    </Link>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <label htmlFor="" className="label">
                      {t("Type")}
                    </label>
                    {/* <label htmlFor="" className="label">
                  {clientProfile?.details?.type ?? "N/A"}
                </label> */}
                    <OverlayTrigger
                      placement="bottom"
                      trigger="click"
                      show={activePopover === "typeInfo"}
                      onToggle={() => handlePopoverToggle("typeInfo")}
                      overlay={
                        <Popover>
                          <button
                            type="button"
                            className="close-popover"
                            onClick={() => handlePopoverToggle("typeInfo")}
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>

                          <PopoverBody>{t("typeInfo")}</PopoverBody>
                        </Popover>
                      }
                    >
                      <div
                        onClick={handleTypeClick}
                        style={{ width: 105, cursor: "pointer" }}
                      >
                        <select
                          // ref={typeCheck}
                          // disabled={true}

                          className="form-select input-md w-100 readonly"
                          id="typeSelectOption"
                          // value={type}
                          // onChange={handleType}
                          defaultValue={type}
                        >
                          <option
                            value={clientProfile?.details?.basic_desc?.type}
                          >
                            {langType === "zh"
                              ? clientProfile?.details?.basic_desc?.type?.split(
                                  "|"
                                )[1]
                              : clientProfile?.details?.basic_desc?.type?.split(
                                  "|"
                                )[0]}
                          </option>
                        </select>
                      </div>
                    </OverlayTrigger>
                    {/* <Link
                  to={
                    isMaster
                      ? AppRoutePath.masterAddDetails
                      : AppRoutePath.clientAddDetails
                  }
                  className="his-btn"
                >
                  Change type
                </Link> */}
                  </div>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <div hidden>
                      <div data-name="popover-content">
                        <ul className="popover-list">
                          <li>
                            <button className="popover-btn">ON</button> Turn off
                            AD
                          </li>
                          <li>
                            <button className="popover-btn">OFF</button> Turn
                            off AD
                          </li>
                          <li>
                            <button className="popover-btn">RESET</button> On
                            holiday but member can see your AD and chat with
                            you.
                          </li>
                        </ul>
                        <div className="row">
                          <div className="offset-md-2 col-10">
                            <p className="text-dim">
                              Rest will be muted for 4 types of service type.
                            </p>
                            <ul className="popover-list">
                              <li>
                                <i className="fa fa-times"></i>Type 1
                              </li>
                              <li>
                                <i className="fa fa-times"></i>Type 3
                              </li>
                              <li>
                                <i className="fa fa-times"></i>Type 4
                              </li>
                              <li>
                                <i className="fa fa-times"></i>Type 5
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div
                        data-name="blog-popover-content"
                        className="blog-popover text-center"
                      >
                        <h5 className="title">
                          Benefits <br /> of using Blog
                        </h5>
                        <h6 className="subtitle">
                          Lorem ipsum dolor sit amet, consectetuer adipiscing
                          elit, sed
                        </h6>

                        <p>
                          Lorem ipsum dolor sit amet, consectetuer adipiscing
                          elit, sed diam
                        </p>
                        <p>
                          Lorem ipsum dolor sit amet, consectetuer adipiscing
                          elit, sed diam
                        </p>
                        <p>
                          Lorem ipsum dolor sit amet, consectetuer adipiscing
                          elit, sed diam
                        </p>
                      </div>
                    </div>
                    <label htmlFor="" className="label">
                      {t("advertisement")}
                      <OverlayTrigger
                        placement="top"
                        trigger="click"
                        // show={AdvertisementShowPopover}
                        // onToggle={setAdvertisementShowPopover}

                        show={activePopover === "advertisementInfos"}
                        onToggle={() =>
                          handlePopoverToggle("advertisementInfos")
                        }
                        overlay={
                          <Popover>
                            <button
                              type="button"
                              className="close-popover"
                              // onClick={handleAdvertisementShowPopover}
                              onClick={() =>
                                handlePopoverToggle("advertisementInfos")
                              }
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>

                            <PopoverBody>{t("advertisementInfos")}</PopoverBody>
                          </Popover>
                        }
                      >
                        <button type="button" className="exc-btn mx-2">
                          <i className="fa-solid fa-exclamation"></i>
                        </button>
                      </OverlayTrigger>
                    </label>
                    <Link
                      to={
                        isMaster
                          ? `${AppRoutePath.masterHistory}/advertisement/${success}`
                          : `${AppRoutePath.clientHistory}/advertisement`
                      }
                      className="his-btn"
                    >
                      {t("history")}
                    </Link>
                    <div className="on-off-btn">
                      <button
                        className={
                          ad === 1
                            ? "btn primary-button-active rounded-button"
                            : "btn primary-button-2 rounded-button btn3"
                        }
                        onClick={() => {
                          if (ad === 1) {
                            setIsAdsModalOpen(false);
                          } else {
                            setIsAdsModalOpen(true);
                          }
                        }}
                      >
                        {t("ON")}
                      </button>
                      <button
                        className={
                          ad === 0
                            ? "btn primary-button rounded-button margin-left-10"
                            : "btn primary-button-2 rounded-button btn3 margin-left-10"
                        }
                        onClick={() => {
                          if (ad === 0) {
                            setIsAdsOff(false);
                          } else {
                            setIsAdsOff(true);
                          }
                        }}
                      >
                        {t("OFF")}
                      </button>
                      {/* <button
                    className={
                      ad === 2
                        ? "btn primary-button rounded-button"
                        : "btn primary-button-2 rounded-button btn3"
                    }
                    onClick={() => {
                      // setAd(2); 
                      if (ad === 2) {
                        setIsAdvertisementModalOpen(false);
                      } else {
                        setIsAdvertisementModalOpen(true);
                      }
                    }}
                  >
                    REST
                  </button> */}
                    </div>
                    {/* <div className="on-off-btn">
                    {["on", "off", "rest"].map((e, i) => (
                      <button
                        key={i}
                        className={
                          "btn rounded-button mx-1 " +
                          (ad === e
                            ? "primary-button"
                            : "btn3 primary-button rounded-button text-white")
                        }
                        onClick={() => {
                          changeAdvertisementStatus(e);
                        }}
                      >
                        {e.toUpperCase()}
                      </button>
                    ))}
                  </div> */}
                  </div>

                  <div>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <label htmlFor="" className="label">
                        {t("blog")}
                        <OverlayTrigger
                          placement="top"
                          trigger="click"
                          show={activePopover === "blogInfo"}
                          onToggle={() => handlePopoverToggle("blogInfo")}
                          overlay={
                            <Popover>
                              <button
                                type="button"
                                className="close-popover"
                                onClick={() => handlePopoverToggle("blogInfo")}
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>

                              <PopoverBody>{t("blogInfo")}</PopoverBody>
                            </Popover>
                          }
                        >
                          <button type="button" className="exc-btn mx-2">
                            <i className="fa-solid fa-exclamation"></i>
                          </button>
                        </OverlayTrigger>
                      </label>
                      <Link
                        to={
                          isMaster
                            ? `${AppRoutePath.masterHistory}/blog/${success}`
                            : `${AppRoutePath.clientHistory}/blog`
                        }
                        className="his-btn"
                      >
                        {t("history")}
                      </Link>
                      <OverlayTrigger
                        placement="top"
                        trigger="click"
                        show={BlogAdsShowPopover}
                        // onToggle={() => {

                        //   if (!isBlogOff && ad===0)  {
                        //     setBlogAdsShowPopover(!BlogAdsShowPopover);
                        //   }
                        // }}
                        overlay={
                          <Popover>
                            {BlogAdsShowPopover && (
                              <>
                                <button
                                  type="button"
                                  className="close-popover"
                                  onClick={handleBlogAdsShowPopover}
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                                {/* <PopoverBody>{t("turnAdsOn")}</PopoverBody> */}
                              </>
                            )}

                            {ad === 0 && !isBlogOff && (
                              <PopoverBody>{t("turnAdsOn")}</PopoverBody>
                            )}
                          </Popover>
                        }
                      >
                        <div className="on-off-btn">
                          <button
                            className={
                              blog === true
                                ? "btn primary-button-active rounded-button"
                                : "btn primary-button rounded-button btn3"
                            }
                            onClick={handleBlogAdsClick}
                          >
                            {t("ON")}
                          </button>
                          <button
                            className={
                              blog === false
                                ? "btn primary-button rounded-button margin-left-10"
                                : "btn primary-button rounded-button btn3 margin-left-10"
                            }
                            onClick={handleBlogAdsClickOff}
                          >
                            {t("OFF")}
                          </button>
                        </div>
                      </OverlayTrigger>
                    </div>

                    {/* <Overlay
                  target={blogToggleRef.current}
                  show={blogTogglePopoverVisibility}
                  onEntered={() =>
                    setTimeout(
                      () => setBlogTogglePopoverVisibility(false),
                      3000
                    )
                  }
                  placement="bottom-end"
                >
                  <Popover>
                    <PopoverBody>
                      You need to turn your Advertisement ON/REST before turning
                      your blog ON.
                    </PopoverBody>
                  </Popover>
                </Overlay> */}

                    {/* {blog ? (
                  <div className="mb-3 bd-dtl  bd-open">
                    <label htmlFor="" className="label mb-2">
                      {t("blogDetails")}
                    </label>
                    <div className="post-box ">
                      <p className="text-10 px-2 py-3 mb-0">{blogDetails}</p>
                      <textarea
                        readOnly={true}
                        name=""
                        id="text"
                        cols={30}
                        rows={7}
                        value={blogDetails}
                        // onChange={handleBlogDetails}
                        className="form-control"
                        // maxLength={2000}
                      />
                      <span
                        className="pull-right label label-default"
                        id="count_message"
                      >
                        {blogDetailsCount} / 2000
                      </span>
                    </div>
                    <button
                      className="btn primary-button rounded-button  px-3 mt-2 "
                      onClick={(e) => {
                        controlBlogDetails({
                          basic_desc: blogDetails,
                          target: isMaster ? Number(success) : undefined,
                        }).then((isSuccess) =>
                          isSuccess
                            ? setClientProfile((state) => ({
                                ...state,
                                details: {
                                  ...state?.details,
                                  blog_details: {
                                    ...state?.details?.blog_details,
                                    basic_desc: blogDetails,
                                  },
                                },
                              }))
                            : null
                        );
                      }}
                    >
                      Save
                    </button>
                  </div>
                ) : null} */}

                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <label htmlFor="" className="label">
                        {t("specialCategory")}
                        <OverlayTrigger
                          placement="top"
                          trigger="click"
                          show={activePopover === "specialCategoryPopUp"}
                          onToggle={() =>
                            handlePopoverToggle("specialCategoryPopUp")
                          }
                          overlay={
                            <Popover>
                              <button
                                type="button"
                                className="close-popover"
                                onClick={() =>
                                  handlePopoverToggle("specialCategoryPopUp")
                                }
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>

                              <PopoverBody>
                                {t("specialCategoryPopUp")}
                              </PopoverBody>
                            </Popover>
                          }
                        >
                          <button type="button" className="exc-btn mx-2">
                            <i className="fa-solid fa-exclamation"></i>
                          </button>
                        </OverlayTrigger>
                      </label>

                      <div className="on-off-btn">
                        <button
                          className={
                            specialCatalogueStatus === true
                              ? "btn primary-button-active rounded-button"
                              : "btn primary-button rounded-button btn3"
                          }
                          onClick={() => {
                            setIsLoading(true);
                            specialCatalogueStatus === false &&
                              controlSpecialCat(
                                true,
                                isMaster ? Number(success) : undefined
                              ).then((isSuccess) => {
                                if (isSuccess) {
                                  setSpecialCatalogueStatus(true);
                                }
                                setIsLoading(false);
                              });
                          }}
                        >
                          {t("ON")}
                        </button>
                        <button
                          className={
                            specialCatalogueStatus === false
                              ? "btn primary-button rounded-button margin-left-10"
                              : "btn primary-button rounded-button btn3 margin-left-10"
                          }
                          onClick={() => {
                            setIsLoading(true);
                            specialCatalogueStatus === true &&
                              controlSpecialCat(
                                false,
                                isMaster ? Number(success) : undefined
                              ).then((isSuccess) => {
                                if (isSuccess) {
                                  setSpecialCatalogueStatus(false);
                                }
                                setIsLoading(false);
                              });
                          }}
                        >
                          {t("OFF")}
                        </button>
                      </div>

                      {/* <OverlayTrigger
                    placement="bottom"
                    trigger="click"
                    show={SpecialCatAdsShowPopover}
                    onToggle={setSpecialCatAdsShowPopover}
                    overlay={
                      <Popover>
                        {SpecialCatAdsShowPopover && (
                          <button
                            type="button"
                            className="close-popover"
                            onClick={handleSpecialCatAdsShowPopover}
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        )}
                        <PopoverBody>{t("blogInfo")}</PopoverBody>
                      </Popover>
                    }
                   /> */}
                      {/* <div className="on-off-btn">
                      <button
                        className={
                          blog === true
                            ? "btn primary-button-active rounded-button"
                            : "btn primary-button rounded-button btn3"
                        }
                        onClick={handleBlogAdsClick}
                        // disabled={!blog}
                      >
                        {t("ON")}
                      </button>
                      <button
                        className={
                          blog === false || clientProfile?.blog_status
                            ? "btn primary-button rounded-button margin-left-10"
                            : "btn primary-button rounded-button btn3 margin-left-10"
                        }
                        onClick={handleBlogAdsClickOff}
                      >
                        {t("OFF")}
                      </button>
                    </div>
             */}
                      {/* </OverlayTrigger> */}
                    </div>

                    <div className="col-12">
                      <select
                        // disabled={!specialCatalogueStatus}

                        value={selectSpecialCat}
                        className="form-select input-md w-100"
                        style={{ width: "auto" }}
                        onChange={handleSpecialCatalogChange}
                      >
                        <option value={""}>{t("chooseOption")}</option>

                        {Object.entries(specialCatalogueList).map(
                          ([key, value]) => {
                            const [englishName, chineseName] = value.split("|");

                            return (
                              <option value={value} key={key + 1}>
                                {" "}
                                {langType === "zh" ? chineseName : englishName}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>
                  </div>

                  {/* {specialCatalogueStatus && ( */}
                  {/* <CheckboxPhotoGallery
                getPhoto={() =>
                  getSpecialPhoto({
                    target: isMaster ? Number(success) : undefined,
                    specialCat_id: catalogNumber,
                  })
                }
                getMainPhoto={() =>
                  getMainSpecialPhoto({
                    target: isMaster ? Number(success) : undefined,
                    media_cat: 10,
                    specialCat_id: catalogNumber,
                  })
                }
                uploadPhoto={(photo: File) =>
                  uploadFileToS3(photo).then((res) =>
                    res
                      ? uploadSpecialPhoto({
                          img_path: [res?.fullpath],
                          target: isMaster ? Number(success) : undefined,
                          specialCat_id: catalogNumber,
                        })
                      : false
                  )
                }
                deletePhoto={(photo) =>
                  deleteSpecialPhoto({
                    filename: photo.name,
                    target: isMaster ? Number(success) : undefined,
                  })
                }
                setMainPhoto={(photo) =>
                  setMainSpecialPhoto({
                    filename: photo.name,
                    target: isMaster ? Number(success) : undefined,
                    media_cat: 10,
                  })
                }
              /> */}

                  {specialCatalogueLists &&
                    selectSpecialCat &&
                    catalogNumber && (
                      <CheckboxPhotoGallery
                        currentSpecialCat={selectSpecialCat}
                        langType={langType ?? ""}
                        catalogNumber={catalogNumber}
                        specialCatalogueListData={specialCatalogueList}
                        specialCatalogueLists={specialCatalogueLists}
                        getPhoto={() =>
                          getSpecialPhoto({
                            target: isMaster ? Number(success) : undefined,
                            specialCat_id: Number(catalogNumber),
                          })
                        }
                        getMainPhoto={() =>
                          getMainSpecialPhoto({
                            target: isMaster ? Number(success) : undefined,
                            media_cat: 10,
                            specialCat_id: Number(catalogNumber),
                          })
                        }
                        uploadPhoto={(photo: File) =>
                          uploadFileToS3(photo).then((res) =>
                            res
                              ? uploadSpecialPhoto({
                                  img_path: [res?.fullpath],
                                  target: isMaster
                                    ? Number(success)
                                    : undefined,
                                  specialCat_id: Number(catalogNumber),
                                })
                              : false
                          )
                        }
                        deletePhoto={(photo) =>
                          deleteSpecialPhoto({
                            filename: photo.name,
                            target: isMaster ? Number(success) : undefined,
                          })
                        }
                        setMainPhoto={(photo) =>
                          setMainSpecialPhoto({
                            filename: photo.name,
                            target: isMaster ? Number(success) : undefined,
                            media_cat: 10,
                          })
                        }
                        handleSpecialCatalogChange={handleSpecialCatalogChange}
                      />
                    )}
                  {/* <div className="row">
                <div className="col-12">
                  <label htmlFor="" className="label">
                    {t("specialCatProfilePhoto")}
                    <OverlayTrigger
                      placement="top"
                      trigger="click"
                      show={SpecialCatProfileShowPopover}
                      onToggle={setSpecialCatProfileShowPopover}
                      overlay={
                        <Popover>
                          {SpecialCatProfileShowPopover && (
                            <button
                              type="button"
                              className="close-popover"
                              onClick={handleSpecialCatProfileShowPopover}
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          )}
                          <PopoverBody>{t("specialCategoryInfo")}</PopoverBody>
                        </Popover>
                      }
                    >
                      <button type="button" className="exc-btn mx-2">
                        <i className="fa-solid fa-exclamation"></i>
                      </button>
                    </OverlayTrigger>
                  </label>
                </div>
              </div> */}
                  {/* )} */}

                  {/* <div className="d-flex justify-content-between align-items-center mb-3">
                <label htmlFor="" className="label">
                  Member Promotion Plan
                  <OverlayTrigger
                    placement="top"
                    trigger="click"
                    overlay={(
                      <Popover>
                        <PopoverBody>
                          If you want an additional AD on promotion page and you want to have more clients, you should join a promotion plan, we will reward same amounts of tools back to you.
                        </PopoverBody>
                      </Popover>
                    )}>
                    <button
                      type="button"
                      className="exc-btn mx-2"
                    >
                      <i className="fa-solid fa-exclamation"></i>
                    </button>
                  </OverlayTrigger>
                </label>
                <select
                  name=""
                  id=""
                  className="form-select wt-80"
                  onChange={(e) => controlMemberPromotion({control: JSON.parse(e.target.value), target: isMaster ? Number(success) : undefined})}
                >
                  {memberPromotionPlanList.map((item, i ) => {
                    const itemVal = String(Object.values(item)[0].amount)
                    return (
                      <option key={i} value={JSON.stringify(item)}>{itemVal}</option>
                    )
                  })}
                </select>
              </div> */}
                  <hr />
                </div>
              </div>

              <div className="container">
                <div className="plan-wrap">
                  {!isMasterClientsEXT && (
                    <>
                      <div className="account-box mb-5 mt-1 align-items-center">
                        <div className="account animated">
                          <div className="card mb-5 mb-lg-0 py-3 text-center">
                            <div className="card-body">
                              <OverlayTrigger
                                placement="top"
                                trigger="click"
                                show={activePopover === "oneCoinInfo"}
                                onToggle={() =>
                                  handlePopoverToggle("oneCoinInfo")
                                }
                                overlay={
                                  <Popover>
                                    <button
                                      type="button"
                                      className="close-popover"
                                      onClick={() =>
                                        handlePopoverToggle("oneCoinInfo")
                                      }
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>

                                    <PopoverBody>
                                      {t("oneCoinInfo")}
                                    </PopoverBody>
                                  </Popover>
                                }
                              >
                                <button type="button" className="exc-btn mx-2">
                                  <i className="fa-solid fa-exclamation"></i>
                                </button>
                              </OverlayTrigger>
                              <img src="/img/onecoin_icon.png" alt="" />
                              <h5 className="card-title text-uppercase">
                                {t("oneCoinBal")}
                              </h5>
                              <div className="buy-btn-top">
                                {" "}
                                <h6 className="card-type">{t("Bal")}</h6>
                                <h4>{coinBalance ?? "0"}</h4>{" "}
                              </div>

                              {/* {!isEXT && params.id && ( */}
                              <>
                                <Link
                                  to={
                                    isMaster
                                      ? `${AppRoutePath.masterWallet}/${WalletType.OneCoin}?ad_id=${success}`
                                      : `${AppRoutePath.clientWallet}/${WalletType.OneCoin}`
                                  }
                                >
                                  <button className="text-10 primary-button rounded-button abs-btn3 text-uppercase">
                                    <i className="fa fa-shopping-cart"></i>
                                    {t("buy")}
                                  </button>
                                </Link>
                              </>
                              {/* )} */}
                            </div>
                          </div>
                        </div>
                        <div className="account animated">
                          <div className="card mb-5 mb-lg-0 py-3 text-center">
                            <div className="card-body">
                              <OverlayTrigger
                                placement="top"
                                trigger="click"
                                show={activePopover === "flowerInfo"}
                                onToggle={() =>
                                  handlePopoverToggle("flowerInfo")
                                }
                                overlay={
                                  <Popover>
                                    <button
                                      type="button"
                                      className="close-popover"
                                      onClick={() =>
                                        handlePopoverToggle("flowerInfo")
                                      }
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>

                                    <PopoverBody>{t("flowerInfo")}</PopoverBody>
                                  </Popover>
                                }
                              >
                                <button type="button" className="exc-btn mx-2">
                                  <i className="fa-solid fa-exclamation"></i>
                                </button>
                              </OverlayTrigger>
                              <img src="/img/flower_icon.png" alt="" />
                              <h5 className="card-title text-uppercase">
                                {t("flowerBal")}
                              </h5>
                              {/* <h6 className="card-type">{}</h6> */}
                              {/* <h4>{flowerBalance ?? "N/A"}</h4> */}
                              <div className="buy-btn-top"> </div>

                              {/* {!isEXT && params.id && ( */}
                              <>
                                <Link
                                  to={
                                    isMaster
                                      ? `${AppRoutePath.masterWallet}/${WalletType.Flower}?ad_id=${success}`
                                      : `${AppRoutePath.clientWallet}/${WalletType.Flower}`
                                  }
                                >
                                  <button className="text-10  primary-button rounded-button abs-btn3 text-uppercase select-account">
                                    <i className="fa fa-shopping-cart"></i>{" "}
                                    {t("buySend")}
                                  </button>
                                </Link>
                              </>
                              {/* )} */}
                            </div>
                          </div>
                        </div>
                        <div className="account animated">
                          <div className="card mb-5 mb-lg-0 py-3 text-center">
                            <div className="card-body">
                              <OverlayTrigger
                                placement="top"
                                trigger="click"
                                show={activePopover === "heartInfo"}
                                onToggle={() =>
                                  handlePopoverToggle("heartInfo")
                                }
                                overlay={
                                  <Popover>
                                    <button
                                      type="button"
                                      className="close-popover"
                                      onClick={() =>
                                        handlePopoverToggle("heartInfo")
                                      }
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>

                                    <PopoverBody>{t("heartInfo")}</PopoverBody>
                                  </Popover>
                                }
                              >
                                <button type="button" className="exc-btn mx-2">
                                  <i className="fa-solid fa-exclamation"></i>
                                </button>
                              </OverlayTrigger>
                              <img src="/img/Heart@2x.png" alt="" />
                              <h5 className="card-title text-uppercase">
                                {t("heartBal")}
                              </h5>
                              {/* <h6 className="card-type">{t("Bal")}</h6> */}
                              {/* <h4>{heartBalance ?? "N/A"}</h4> */}
                              <div className="buy-btn-top"> </div>

                              {/* {!isEXT && params.id && ( */}
                              <>
                                <Link
                                  to={
                                    isMaster
                                      ? `${AppRoutePath.masterWallet}/${WalletType.Heart}?ad_id=${success}`
                                      : `${AppRoutePath.clientWallet}/${WalletType.Heart}`
                                  }
                                >
                                  <button className="text-10 primary-button rounded-button abs-btn3 text-uppercase">
                                    <i className="fa fa-shopping-cart"></i>{" "}
                                    {t("buySend")}
                                  </button>
                                </Link>
                              </>
                              {/* )} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <label htmlFor="" className="label">
                      {t("instantPost")}
                      <OverlayTrigger
                        placement="top"
                        trigger="click"
                        show={activePopover === "instantPostInfo"}
                        onToggle={() => handlePopoverToggle("instantPostInfo")}
                        overlay={
                          <Popover>
                            <button
                              type="button"
                              className="close-popover"
                              onClick={() =>
                                handlePopoverToggle("instantPostInfo")
                              }
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>

                            <PopoverBody>{t("instantPostInfo")}</PopoverBody>
                          </Popover>
                        }
                      >
                        <button type="button" className="exc-btn mx-2">
                          <i className="fa-solid fa-exclamation"></i>
                        </button>
                      </OverlayTrigger>
                    </label>
                    <Link to="/client/add-instant-post">
                      <button className="btn primary-button rounded-button">
                        {t("addNewInstantPost")}
                      </button>
                    </Link>
                  </div>
                  <div className="d-flex justify-content-end mb-5">
                    <Link to="/client/my-instant-posts">
                      <button className="btn primary-button rounded-button">
                        {t("viewAllInstantPost")}
                      </button>
                    </Link>
                  </div>
                  <div className="d-flex justify-content-between align-items-center pb-5">
                    <label htmlFor="" className="label">
                      {t("InstantReview")}
                      <OverlayTrigger
                        placement="top"
                        trigger="click"
                        show={activePopover === "instantReviewInfo"}
                        onToggle={() =>
                          handlePopoverToggle("instantReviewInfo")
                        }
                        overlay={
                          <Popover>
                            <button
                              type="button"
                              className="close-popover"
                              onClick={() =>
                                handlePopoverToggle("instantReviewInfo")
                              }
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>

                            <PopoverBody>{t("instantReviewInfo")}</PopoverBody>
                          </Popover>
                        }
                      >
                        <button type="button" className="exc-btn mx-2">
                          <i className="fa-solid fa-exclamation"></i>
                        </button>
                      </OverlayTrigger>
                    </label>
                    <Link to={AppRoutePath.clientMyInstantReview}>
                      <button className="btn primary-button rounded-button">
                        {t("ViewMyInstantReview")}
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {/* sendFlowerModal POPUP */}
      <Modal show={sendFlowerModal}>
        <Modal.Body>
          <Modal.Title>
            <center>
              <h5 className="modal-title" style={{ fontSize: 20 }}>
                Your Current Balance
              </h5>
            </center>
            <div className="d-flex justify-content-center align-items-center">
              <img src="/img/flower_icon.png" alt="" style={{ width: 50 }} />{" "}
              <div style={{ width: 10 }}></div>
              <h5 style={{ fontSize: 50, color: "#ff3751" }}>10</h5>
            </div>
            <div>
              <label htmlFor="" className="text-white text-8 pb-2">
                Please select Flower package
              </label>
              <select name="" id="" className="form-select">
                <option value="" selected disabled>
                  Please select Flower package
                </option>
              </select>
            </div>
          </Modal.Title>
        </Modal.Body>

        <Modal.Footer>
          <div className="d-flex justify-content-center align-items-center">
            <button
              type="button"
              className="primary-button button mt-3 w-100"
              data-bs-dismiss="modal"
              onClick={() => {
                setSendFlowerModal(false);
              }}
            >
              Cancel
            </button>{" "}
            <div style={{ width: 50 }}></div>
            <button
              type="button"
              className="primary-button button mt-3 w-100"
              data-bs-dismiss="modal"
              // onClick={() => changeAdvertisementStatus(advertisementTemp)}
            >
              Send
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* <Modal show={isAdvertisementModalOpen}>
        <Modal.Body>
          <Modal.Title>
            <center>
              <h5 className="modal-title" style={{fontSize: 20}}>
                You need to pay
              </h5>
            </center>
            <div className="d-flex justify-content-center align-items-center">
              <img
                src='/img/onecoin_icon.png'
                alt=''
                style={{ width: 50 }}
              />{" "}
              <div style={{width:10}}></div>
              <h5 style={{fontSize: 50, color: '#ff3751'}}>10</h5>
            </div>
            <center>
              <h5 className="modal-title">
                To put your advertisement ON or REST
              </h5>
            </center>
          </Modal.Title>
        </Modal.Body>

        <Modal.Footer>
          <div className="d-flex justify-content-center align-items-center">
            <button
              type="button"
              className="primary-button button mt-3 w-100"
              data-bs-dismiss="modal"
              onClick={() => changeAdvertisementStatus(advertisementTemp)}
            >
              Yes
            </button>
            <div style={{width:50}}></div>
            <button
              type="button"
              className="primary-button button mt-3 w-100"
              data-bs-dismiss="modal"
              onClick={() => setIsAdvertisementModalOpen(false)}
            >
              No
            </button>
          </div>
        </Modal.Footer>
      </Modal> */}

      <Modal show={tChatModelOpen}>
        <Modal.Body>
          <div className="d-flex justify-content-center align-items-center">
            <Modal.Title>
              <div className="text-center pb-3">
                <img
                  style={{ width: 50, marginRight: 5 }}
                  src="/img/tchat_newlogo.png"
                  className="default-logo"
                />
              </div>
              <h5 className="modal-title text-center pb-3">
                {t("tchatModelMSG")}
              </h5>
            </Modal.Title>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-2 justify-content-center text-center">
            <button
              className=" button primary-button rounded-button px-5 "
              onClick={() => {
                setTChatModelOpen(false);
              }}
            >
              {t("no")}
            </button>

            <a
              href={tChatGenerateLink}
              target="_blank"
              rel="noreferrer"
              className="button primary-button rounded-button px-5"
              onClick={() => {
                setTChatModelOpen(false);
              }}
            >
              {t("ok")}
            </a>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ClientMainPage;
