import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  OverlayTrigger,
  Popover,
  PopoverBody,
  Spinner,
} from "react-bootstrap";

import { Link, useNavigate, useParams } from "react-router-dom";
import SizedBox from "../../reusable/Sizedbox";
import FilePicker from "../../reusable/FilePicker";
import { uploadFileToS3, uploadFileToS3Voice } from "../../../services/upload";
import { Modal } from "react-bootstrap";
import Snackbar from "awesome-snackbar";
import { AdMedia } from "../../../domain/model/ad-media";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import CreateUserParams from "../../../domain/model/create-user-params";
import { updateUser } from "../../../services/auth";
import {
  deleteAdMedia,
  deleteRealImages,
  findAdMedia,
  findRealImages,
  findStreetImages,
  findVoiceMemo,
  uploadAdMedia,
  uploadRealImage,
  uploadVoiceMemo,
  flagAdMedia,
  uploadStreetImage,
  deleteStreetPhotos,
  deleteVoiceMemo,
  photoRotate,
  uploadProfilePhotos,
  findProfilePhotos,
  deleteProfilePhotos,
} from "../../../services/ad-media";
import { ClientProfileModel } from "../../../domain/model/client-profile-model";
import { getClientProfile } from "../../../services/client/client-profile";
import { getAdsMaster } from "../../../services/master/master-ad";
import AppRoutePath from "../../../constants/AppRoutePath";
import LocalStorageKey from "../../../constants/LocalStorageKey";
import { useTranslation } from "react-i18next";
import CustomHeader from "../../reusable/Header";
import TopHearder from "../../reusable/TopHearder";
import ReactPlayer from "react-player";

import { isSafari } from "react-device-detect";

export interface IHistoryPageProps {}

function EditIndividualAddDetailPage(): JSX.Element {
  const isMaster = window.location.pathname.includes("master");
  const { id } = useParams();
  const navigate = useNavigate();
  const imgRef = useRef<HTMLImageElement>(null);
  const profileImgRef = useRef<HTMLImageElement>(null);

  const isLogin = !window.location.pathname.includes("register");

  const [isAutoRotate, setIsAutoRotate] = useState(false);
  const [isStreetPhotoChecked, setIsStreetPhotoChecked] = useState(true);

  // Street photo states
  const [listStreetPhoto, setListStreetPhoto] = useState<AdMedia[]>([]);
  const [selectedStreetPhotos, setSelectedStreetPhotos] = useState<any>({});

  // Real photo states
  const [listRealPhoto, setListRealPhoto] = useState<AdMedia[]>([]);
  const [selectedRealPhotos, setSelectedRealPhotos] = useState<any>({});

  // AD media states
  const [photos, setPhotos] = React.useState<File[]>([]);
  // const [video, setVideo] = React.useState<File | undefined>();
  const [video, setVideo] = useState<AdMedia>();
  const [listPhoto, setListPhoto] = useState<AdMedia[]>([]);
  const [selectedADMedia, setSelectedAdMedia] = useState<any>({});

  const params = useParams();
  // Voice memo states
  const recorderControls = useAudioRecorder();
  const [voiceMemo, setVoiceMemo] = useState<AdMedia>();
  const [hasVoice, setHasVoice] = useState(false);
  const [shouldAutoUpload, setShouldAutoUpload] = useState(true);
  const [microphonePermission, setMicrophonePermission] = useState(null);
  // Loading states
  const [isDeletingRealImage, setIsDeletingRealImage] = useState(false);
  const [isDeletingStreetImage, setIsDeletingStreetImage] = useState(false);
  const [isDeletingADPhoto, setIsDeletingADPhoto] = useState(false);
  const [isDeletingVideo, setIsDeletingVideo] = useState(false);
  const [isDeletingVoiceMemo, setIsDeletingVoiceMemo] = useState(false);
  const [isFlaggingAdMedia, setIsFlaggingAdMedia] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string | undefined>();
  const [isUploaded, setIsUploaded] = useState<boolean[]>([]);
  const [uploadedRealImages, setUploadedRealImages] = useState<string[]>([]);
  const [profilePhoto, setProfilPhoto] = React.useState("");

  const { success } = useParams();
  const [clientProfile, setClientProfile] =
    React.useState<ClientProfileModel | null>(null);
  const [AdPhotoMain, setAdPhotoMain] = React.useState("");
  const [profilePhotoMain, setProfilePhotoMain] = React.useState(null);
  const [profilePhotoMainName, setProfilePhotoMainName] = React.useState(null);
  const [isProfilePhotoUpload, setIsProfilePhotoUpload] = useState(false);
  const [isDeletingProfilePhoto, setIsDeletingProfilePhoto] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);
  const [selectedCount2, setSelectedCount2] = useState(0);
  const [selectedCount3, setSelectedCount3] = useState(0);
  const [isMainPhoto, setIsMainPhoto] = useState(false);
  const [notifCount, setNotifCount] = useState(0);
  const [currentTotalUploadedImages, setCurrentTotalUploadedImages] =
    useState(0);

  const { t } = useTranslation();

  const [microphoneStatus, setMicrophoneStatus] = useState("");

  const requestMicrophoneAccess = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setMicrophoneStatus("Microphone is on");
      recorderControls.startRecording();
      stream.getTracks().forEach((track) => track.stop());
    } catch (err) {
      setMicrophoneStatus(
        "Failed to access microphone. Please allow access in the browser settings."
      );
    }
  };

  const handleRecordingComplete = (blob: Blob) => {
    recorderControls.stopRecording();
    handleUploadVoiceMemo(blob);
  };

  const [activePopover, setActivePopover] = useState(null);

  const handlePopoverToggle = (popoverType: any) => {
    if (activePopover === popoverType) {
      setActivePopover(null);
    } else {
      setActivePopover(popoverType);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (isMaster) {
      // console.log("ismaster 1234", isMaster);

      if (params.id) {
        getAdsMaster(params.id).then((res) => {
          if (res != null) {
            // setAllProfileData(convertMastersAddToClientProfile(res!))
            setIsAutoRotate(res.rotate === 1 ? true : false);
            setAllProfileData(res);
          }
        });
      }
    } else {
      getClientProfile().then(setAllProfileData);
    }
  }, []);

  // GET CLIENT PROFILE
  useEffect(() => {
    if (isMaster || params.id) {
      findProfilePhotos(params.id).then((res: any) => {
        if (res != null) {
          const result = res["item(s)"][0];
          setProfilePhotoMain(result.cache_url);
          setProfilePhotoMainName(result.name);
        } else {
          setProfilePhotoMain(null);
        }
      });
    }
    if (isMaster && (success ?? "success") !== "success") {
      getAdsMaster(success!).then((res) => {
        if (res != null) {
          console.log("ggfgf ", res);

          setClientProfile(res);
          setIsAutoRotate(res.rotate === 1 ? true : false);
        }
      });
    } else {
      if (!params.id) {
        getClientProfile().then((res) => {
          if (res != null) {
            setIsAutoRotate(res.rotate === 1 ? true : false);
          }
          setClientProfile(res);
        });
        findProfilePhotos().then((res: any) => {
          if (res != null) {
            const result = res["item(s)"][0];
            // console.log("tt ", res["item(s)"][0].cache_url);
            setProfilePhotoMain(result.cache_url);
            setProfilePhotoMainName(result.name);
          } else {
            setProfilePhotoMain(null);
          }
          // setClientProfile(res);
        });
      }
    }
    const id = (success ?? "success") !== "success" ? success : undefined;
    findAdMedia(id).then((res) => {
      if (res != null) {
        for (let idx = 0; idx < res.length; idx++) {
          const item = res[idx];

          if (item.flag && item.media_cat === 2) {
            // if (item.flag) {
            //   localStorage.setItem(
            //     LocalStorageKey.userProfilePicture,
            //     item.cache_url
            //   );
            //   // setProfilPhoto(item.cache_url);
            //   break;
            // }

            setAdPhotoMain(item?.cache_url);
            break;
          }
        }
      }
    });
  }, []);

  const showLoadingSnackbar = () =>
    new Snackbar(
      `<div className="spinner-grow spinner-grow-sm mx-1"></div> Uploading...`,
      {
        timeout: 0,
      }
    );
  const showLoadingVoiceSnackbar = () =>
    new Snackbar(
      `<div className="spinner-grow spinner-grow-sm mx-1"></div> Uploading Voice...`,
      {
        timeout: 0,
      }
    );

  const getSelectedMedias = (
    sourceData: AdMedia[],
    selectedData: any
  ): AdMedia[] => {
    const keys = Object.keys(sourceData);
    const images = keys
      .map((e: string) => {
        if (selectedData[e]) return sourceData[parseInt(e)];

        return null;
      })
      .filter((e) => e != null)
      .map((e) => e!);

    return images;
  };

  const handleFlagAdMedia = async (profile: ClientProfileModel | null) => {
    const image = getSelectedMedias(listPhoto, selectedADMedia);

    if (image.length < 1) {
      new Snackbar("Please select an image");
      return;
    } else if (image.length > 1) {
      new Snackbar("Please select only one (1) image");
      return;
    }

    setSelectedCount3(0);

    setIsFlaggingAdMedia(true);
    await flagAdMedia(image[0].name, id ? Number(id) : undefined);
    // Check if the selected image is different from the current profile photo
    if (image[0].cache_url !== profilePhoto) {
      new Snackbar("Ad main photo has been updated");
      setProfilPhoto(image[0].cache_url);

      if (clientProfile) {
        console.log("Before updating clientProfile:", clientProfile);

        const updatedProfile: ClientProfileModel = {
          ...clientProfile,
          photo: image[0].cache_url,
        };
        setClientProfile(updatedProfile);

        console.log("After updating clientProfile:", updatedProfile);

        try {
          const fetchedProfile = await getClientProfile();
          if (fetchedProfile) {
            setClientProfile(fetchedProfile);

            console.log("After updating clientProfile3:", updatedProfile);
          }
        } catch (error) {
          console.error("Error fetching updated profile:", error);
        }
        console.log("After updating clientProfile4:", updatedProfile);
      }

      const createUserParams = CreateUserParams.getInstance();
      createUserParams.data = {
        ...createUserParams.data,
        photo: image[0].cache_url,
      };

      createUserParams.saveData();

      try {
        var isRegisterSuccessOrId: boolean | number | null = null;

        isRegisterSuccessOrId = await updateUser(createUserParams);
      } catch (e) {
        console.log(e);
        new Snackbar("Something went wrong");
      }
    }

    await handleGetAdMedia();
    setSelectedAdMedia([]);
    setIsFlaggingAdMedia(false);
  };

  const setAllProfileData = (profile: ClientProfileModel | null) => {
    if (profile) {
      console.log("profile", profile);
      const updatedProfile: ClientProfileModel = {
        ...clientProfile,
        photo: profile.photo,
      };

      setClientProfile(updatedProfile);
      // setProfilePhotoMain(profile.photo);
    }
  };

  const handleUploadADMedia = async (
    files: File[],
    type: "image" | "video"
  ) => {
    const sb = showLoadingSnackbar();

    try {
      for (const file of files) {
        let thumbnail: string | null = null;

        if (type === "video") {
          await new Promise<void>((resolve) => {
            const canvas = document.createElement("canvas");
            const canvasContext = canvas.getContext("2d");
            const playback = document.createElement("video");

            playback.addEventListener("loadedmetadata", () => {
              const [width, height] = [
                playback.videoWidth / 2,
                playback.videoHeight / 2,
              ];

              canvas.width = width;
              canvas.height = height;
              canvasContext!.drawImage(playback, 0, 0, width, height);

              canvas.toBlob(async (thumbTemp) => {
                const thumbRenamed = new File(
                  [thumbTemp!],
                  `${file.name}-thumbnail.png`,
                  {
                    type: "image/png",
                  }
                );
                thumbnail = await uploadFileToS3(thumbRenamed as File).then(
                  (res) => res?.fullpath ?? null
                );

                resolve();
              });
            });

            playback.src = URL.createObjectURL(file);
          });
        }

        const upload = await uploadFileToS3(file).then((res) => {
          if (res !== null) {
            return res.fullpath;
          }
          return null;
        });

        if (upload) {
          await uploadAdMedia(
            upload,
            type,
            thumbnail,
            id ? Number(id) : undefined
          );
        } else {
          throw new Error("Failed to upload file to S3");
        }
      }

      await handleGetAdMedia();
      sb.hide();
    } catch (e) {
      console.log(e);
      new Snackbar("Failed to upload files");
      sb.hide();
    }
  };

  // const handleFileInputChange: React.ChangeEventHandler<
  //   HTMLInputElement
  // > = async (e: React.ChangeEvent<HTMLInputElement>) => {
  //   if (e.target.files && e.target.files.length > 0) {
  //     const files = Array.from(e.target.files); // Convert FileList to Array
  //     const fileType = files[0].type.startsWith("image") ? "image" : "video";
  //     const videos = files.filter((file) => file.type.includes("video"));
  //     const images = files.filter((file) => file.type.includes("image"));

  //     const totalImages = images.length;
  //     const totalVideos = videos.length;

  //     if (totalVideos > 1) {
  //       setErrorMessages("You can only select one (1) video.");
  //       return;
  //     } else if (totalImages + currentTotalUploadedImages > 9) {
  //       setErrorMessages("You can only select up to nine (9) image(s).");
  //       return;
  //     }

  //     try {
  //       for (const file of files) {
  //         await handleUploadADMedia([file], fileType); // Pass the file as an array
  //       }

  //       // All files uploaded
  //       setCurrentTotalUploadedImages((prevTotal) => prevTotal + totalImages);
  //       setErrorMessages(undefined);
  //     } catch (error) {
  //       console.error("Error uploading files:", error);
  //       new Snackbar(
  //         "Failed to upload files. Please check the console for more information."
  //       );
  //       setErrorMessages(undefined);
  //     }
  //   }
  // };

  const handleFileInputChange: React.ChangeEventHandler<
    HTMLInputElement
  > = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const files = Array.from(e.target.files); // Convert FileList to Array
      const fileType = files[0].type.startsWith("image") ? "image" : "video";
      const videos = files.filter((file) => file.type.includes("video"));
      const images = files.filter((file) => file.type.includes("image"));

      const totalImages = images.length;
      const totalVideos = videos.length;

      if (totalVideos > 1) {
        setErrorMessages("You can only select one (1) video.");
        return;
      } else if (totalImages + currentTotalUploadedImages > 9) {
        setErrorMessages("You can only select up to nine (9) image(s).");
        return;
      }

      try {
        for (const file of files) {
          // Check file size not more than 60MB
          if (file.size > 60 * 1024 * 1024) {
            setErrorMessages("File size should not exceed 60MB.");
            return;
          }

          // Check file type for video
          if (fileType === "video" && !file.type.includes("video")) {
            setErrorMessages("Invalid file type. Please select a video file.");
            return;
          }

          await handleUploadADMedia([file], fileType); // Pass the file as an array
        }

        // All files uploaded
        setCurrentTotalUploadedImages((prevTotal) => prevTotal + totalImages);
        setErrorMessages(undefined);
      } catch (error) {
        console.error("Error uploading files:", error);
        new Snackbar(
          "Failed to upload files. Please check the console for more information."
        );
        setErrorMessages(undefined);
      }
    }
  };

  // const handleFileInputChange: React.ChangeEventHandler<
  //   HTMLInputElement
  // > = async (e: React.ChangeEvent<HTMLInputElement>) => {
  //   if (e.target.files && e.target.files.length > 0) {
  //     const files = Array.from(e.target.files); // Convert FileList to Array
  //     const fileType = files[0].type.startsWith("image") ? "image" : "video";
  //     const videos = files.filter((e) => e.type.includes("video"));
  //     const images = files.filter((e) => e.type.includes("image"));

  //     console.log("videos", videos);

  //     const totalImages = images.length
  //     const totalVideos = videos.length;

  //     console.log("Total Image ", totalImages);
  //     console.log("files.length ", files.length);

  //     if (totalVideos > 1) {
  //       setErrorMessages("You can only select one (1) video.");
  //       return;
  //     } else if (totalImages > 9) {
  //       setErrorMessages("You can only select up to nine (9) image(s).");
  //       return;
  //     }

  //     try {
  //       for (const file of files) {
  //         await handleUploadADMedia([file], fileType); // Pass the file as an array
  //       }

  //       // All files uploaded
  //       setIsUploaded((prevIsUploaded) => [
  //         ...prevIsUploaded,
  //         ...Array(files.length).fill(true),
  //       ]);
  //       setErrorMessages(undefined);
  //     } catch (error) {
  //       console.log(error);
  //       new Snackbar("Failed to upload files");
  //       setErrorMessages(undefined);
  //     }
  //   }
  // };

  // const handleUploadRealImage: React.ChangeEventHandler<HTMLInputElement> = async (
  //   e: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   if (e.target.files == null) {
  //     return;
  //   }

  //   const files = Array.from(e.target.files);
  //   console.log("files", files);
  //   const images = files.filter((e) => e.type.includes("image"));

  //   const totalImages = images.length + listRealPhoto.length; // Add the number of already uploaded images

  //   if (totalImages > 9) {
  //     setErrorMessages("You can only select up to nine (9) image(s).");
  //     return;
  //   }

  //   const sb = showLoadingSnackbar();

  //   try {
  //     const uploadPromises = files.map(async (file) => {
  //       const s3File = await uploadFileToS3(file);
  //       if (s3File) {
  //         return s3File.fullpath;
  //       }
  //       return null;
  //     });

  //     const s3Files = await Promise.all(uploadPromises);
  //     const validS3Files = s3Files.filter((file) => file !== null) as string[];

  //     // Append the new images to the existing list of real photos
  //     const newRealPhotos = validS3Files.map((path) => ({
  //       cache_url: path,
  //       created: "", // Set the appropriate value for the 'created' property
  //       file_size: 0, // Set the appropriate value for the 'file_size' property
  //       flag: false, // Set the appropriate value for the 'flag' property
  //       name: "", // Set the appropriate value for the 'name' property
  //       type: "" // Set the appropriate value for the 'type' property
  //     }));

  //     // Declare prevList variable before using it
  //     const prevList = listRealPhoto || [];

  //     setListRealPhoto((prevList) => [...prevList, ...newRealPhotos]);

  //     // Declare prevSelected variable before using it
  //     const prevSelected: Record<number, boolean> = selectedRealPhotos || {};

  //     // Update the selectedRealPhotos state by adding the new image indices
  //     setSelectedRealPhotos((prevSelected) => {
  //       const newSelected = { ...prevSelected };
  //       newRealPhotos.forEach((photo, index) => {
  //         const newIndex = prevList.length + index; // Calculate the index of the newly added photo
  //         newSelected[newIndex] = true; // Set the newly added photo as selected
  //       });
  //       return newSelected;
  //     });

  //     await uploadRealImage(validS3Files, id ? Number(id) : undefined);

  //     await handleGetRealImages();
  //   } catch (error) {
  //     new Snackbar("Failed to upload real image");
  //   }

  //   sb.hide();
  // };

  const handleUploadRealImage: React.ChangeEventHandler<
    HTMLInputElement
  > = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files == null) {
      return;
    }

    const files = Array.from(e.target.files);
    console.log("files", files);
    const images = files.filter((e) => e.type.includes("image"));

    const totalImages = images.length + listRealPhoto.length; // Add the number of already uploaded images

    if (totalImages > 9) {
      setErrorMessages("You can only select up to nine (9) image(s).");
      return;
    }

    const sb = showLoadingSnackbar();

    try {
      const uploadPromises = files.map(async (file) => {
        const s3File = await uploadFileToS3(file);
        if (s3File) {
          return s3File.fullpath;
        }
        return null;
      });

      const s3Files = await Promise.all(uploadPromises);
      const validS3Files = s3Files.filter((file) => file !== null) as string[];

      await uploadRealImage(validS3Files, id ? Number(id) : undefined);

      await handleGetRealImages();
    } catch (error) {
      new Snackbar("Failed to upload real image");
    }

    sb.hide();
  };

  // const handleUploadRealImage: React.ChangeEventHandler<HTMLInputElement> = async (
  //   e: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   if (e.target.files == null) {
  //     return;
  //   }

  //   const sb = showLoadingSnackbar();
  //   const files = Array.from(e.target.files);
  //   console.log("files",files);
  //   const images = files.filter((e) => e.type.includes("image"));

  //   const totalImages = images.length + listRealPhoto.length; // Add the number of already uploaded images

  //   if (totalImages > 9) {
  //     setErrorMessages("You can only select up to nine (9) image(s).")
  //     return;
  //   }
  //   // for (let i = 0; i < e.target.files.length; i++) {
  //   //   files.push(e.target.files.item(i)!);
  //   // }

  //   try {
  //     const s3Files = await Promise.all(
  //       files.map(async (e) => (await uploadFileToS3(e!))?.fullpath ?? null)
  //     );
  //     console.log("s3Files",s3Files);
  //     await uploadRealImage(
  //       s3Files.filter((e) => e != null).map((e) => e!),
  //       id ? Number(id) : undefined
  //     );

  //     await handleGetRealImages();

  //   } catch (e) {
  //     new Snackbar("Failed to upload real image");
  //   }

  //   sb.hide();
  // };

  // Call the function to check and request permission

  const handleUploadStreetImage: React.ChangeEventHandler<
    HTMLInputElement
  > = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const files = Array.from(e.target.files); // Convert FileList to Array

      const images = files.filter((e) => e.type.includes("image"));

      const totalImages = images.length + listStreetPhoto.length; // Add the number of already uploaded images

      if (totalImages > 3) {
        setErrorMessages("You can only select up to three (3) image(s).");
        return;
      }
      const sb = showLoadingSnackbar();
      try {
        for (const file of files) {
          const upload = await uploadFileToS3(file).then(
            (res) => res?.fullpath ?? null
          );
          await uploadStreetImage(upload!, id ? Number(id) : undefined);
        }
        await handleGetStreetImages();
        sb.hide();
      } catch (e) {
        new Snackbar("Failed to upload real image");
      }

      sb.hide();
    }
  };

  const handleDeleteRealImage = async () => {
    if (Object.keys(selectedRealPhotos).length === 0) {
      setErrorMessages("Please select at least one (1) image(s).");
      return;
    }
    setIsDeletingRealImage(true);

    try {
      await deleteRealImages(
        getSelectedMedias(listRealPhoto, selectedRealPhotos),
        id ? Number(id) : undefined
      );
      await handleGetRealImages();

      setSelectedRealPhotos([]);
      setSelectedCount2((prevCount) => {
        if (prevCount === 0) {
          setErrorMessages("Please select at least one (1) image(s).");
        }
        return prevCount - Object.keys(selectedRealPhotos).length;
      });
      setIsDeletingRealImage(false);
    } catch (error) {
      setErrorMessages("Failed to delete real image");
      setIsDeletingRealImage(false);
    }
  };

  const handleDeleteStreet = async () => {
    if (Object.keys(selectedStreetPhotos).length == 0) {
      setErrorMessages("Please select at least one (1) image(s).");
      return;
    }

    setIsDeletingStreetImage(true);

    const selectedPhotos = getSelectedMedias(
      listStreetPhoto,
      selectedStreetPhotos
    );

    try {
      await deleteStreetPhotos(selectedPhotos, id ? Number(id) : undefined);

      await handleGetStreetImages();
      setSelectedStreetPhotos({});
      setSelectedCount((prevCount) => {
        if (prevCount === 0) {
          setErrorMessages("Please select at least one (1) image(s).");
        }
        return prevCount - Object.keys(selectedPhotos).length;
      });
      setIsDeletingStreetImage(false);
    } catch (error) {
      new Snackbar("Failed to delete street photos");
      setIsDeletingStreetImage(false);
    }
  };

  const handleDeleteVoiceMemo = async () => {
    setIsDeletingVoiceMemo(true);

    try {
      await deleteVoiceMemo(voiceMemo!.name, params.id ? params.id : null).then(
        async (res: any) => {
          if (res) {
            new Snackbar("Voice Memo successfully deleted");
            await handleGetVoiceMemo();
          }
        }
      );
      // await handleGetVoiceMemo();
    } catch (error) {
      console.error("Failed to delete voice memo:", error);
      new Snackbar("Failed to delete voice memo");
    }

    setIsDeletingVoiceMemo(false);
  };

  const handleUploadVoiceMemoFile: React.ChangeEventHandler<
    HTMLInputElement
  > = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const files = Array.from(e.target.files);

      const sb = showLoadingSnackbar();
      try {
        for (const file of files) {
          const upload = await uploadFileToS3Voice(file).then(
            (res) => res?.fullpath ?? null
          );
          await uploadVoiceMemo(upload!, id ? Number(id) : undefined);
        }
        await handleGetVoiceMemo();
        sb.hide();
      } catch (e) {
        new Snackbar("Failed to upload Vioce Memo");
      }

      sb.hide();
    }
  };
  const handleUploadVoiceMemo = async (blob: Blob) => {
    const sb = showLoadingVoiceSnackbar();

    try {
      const file = new File([blob], `${blob.name}-voice-memo.MP3`, {
        type: blob.type,
      });
      const url = await uploadFileToS3Voice(file).then(
        (res) => res?.fullpath ?? null
      );

      await uploadVoiceMemo(url!, id ? Number(id) : undefined).then((res) => {
        console.log("Voice Uploaded");
      });
      await handleGetVoiceMemo();
    } catch (e) {
      new Snackbar("Failed to upload voice memo");
    }

    sb.hide();
  };
  // const handleUploadVoiceMemo = async (blob: Blob) => {
  //   const sb = showLoadingSnackbar();

  //   try {
  //     const file = new File([blob], `${blob.name}-voice-memo.wav`, {
  //       type: blob.type,
  //     });
  //     const url = await uploadFileToS3(file).then(
  //       (res) => res?.fullpath ?? null
  //     );

  //     await uploadVoiceMemo(url!, id ? Number(id) : undefined);
  //     await handleGetVoiceMemo();
  //   } catch (e) {
  //     new Snackbar("Failed to upload voice memo");
  //   }

  //   sb.hide();
  // };

  const handleDeleteAdMedia = async (type: "video" | "image") => {
    if (type === "video") {
      setIsDeletingVideo(true);
      await deleteAdMedia([video!], id ? Number(id) : undefined);
      await handleGetAdMedia();
      setVideo(undefined);
      setIsDeletingVideo(false);
    } else {
      if (Object.keys(selectedADMedia).length === 0) {
        setErrorMessages("Please select at least one (1) image.");
        return;
      }
      setIsDeletingADPhoto(true);
      const selectedMedia = getSelectedMedias(listPhoto, selectedADMedia);

      try {
        await deleteAdMedia(selectedMedia, id ? Number(id) : undefined);
        await handleGetAdMedia();
        setSelectedAdMedia([]);

        // Update selected count
        setSelectedCount3((prevCount) => {
          if (prevCount === 0) {
            setErrorMessages("Please select at least one (1) image.");
          }
          return prevCount - Object.keys(selectedMedia).length;
        });

        setIsDeletingADPhoto(false);

        // Check if the deleted media is the current profile photo
        if (selectedMedia.some((media) => media.cache_url === profilePhoto)) {
          new Snackbar("Profile photo has been deleted");
          setProfilPhoto(""); // Update the profile photo state to an empty value
        }
      } catch (error) {
        new Snackbar("Failed to delete ad media");
        setIsDeletingADPhoto(false);
      }
    }
  };

  const handleGetAdMedia = () =>
    findAdMedia(id).then((e) => {
      const photos = e.filter((e: any) => !e.hasOwnProperty("thumbnail"));
      const videos = e.filter((e: any) => e.hasOwnProperty("thumbnail"));

      if (videos.length > 0) {
        setVideo(videos[0]);
      }

      setListPhoto(photos);

      // Get the flagged profile photo and set it as the current profile photo
      const flaggedPhoto = photos.find((photo) => {
        return photo.flag === true && photo.media_cat === 2;
      });
      if (flaggedPhoto) {
        setAdPhotoMain(flaggedPhoto.cache_url);
      } else {
        setAdPhotoMain("");
      }
    });

  const handleGetVoiceMemo = async () => {
    // console.log("handleGetVoiceMemo");

    await findVoiceMemo(id).then((e) => {
      // console.log("Voice e ", e);
      if (e?.cache_url) {
        setHasVoice(true);
        setVoiceMemo(e);
      } else {
        setVoiceMemo(undefined);
      }
    });
  };

  const handleGetStreetImages = () =>
    findStreetImages(id).then((e) => setListStreetPhoto(e));

  const handleGetRealImages = () =>
    findRealImages(id).then((e) => setListRealPhoto(e));
  // const handleGetRealImages = async () => {
  //   const realImages = await findRealImages(id);
  //   setListRealPhoto(realImages);
  // };
  useEffect(() => {
    handleGetAdMedia();
    handleGetRealImages();
    handleGetStreetImages();
    handleGetVoiceMemo();
    console.log("param ", id);
  }, []);

  function playVideo(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.preventDefault();
    const video = document.querySelector<HTMLVideoElement>(".video");
    const playpause = document.querySelector<HTMLDivElement>(".playpause");

    if (video && playpause) {
      if (video.paused) {
        video.play();
        playpause.style.display = "none";
      } else {
        video.pause();
        playpause.style.display = "block";
      }
    }
  }

  const handleBack = () => {
    navigate(-1);
  };

  const [isNavOpen, setIsNavOpen] = useState(true);

  // const handlePhotoRotate = () => {
  //   photoRotate({contol:isAutoRotate}).then((res)=>{
  //     console.log();

  //   })

  // };

  const handleUploadProfileImage: React.ChangeEventHandler<
    HTMLInputElement
  > = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const files = e.target.files;
      setIsProfilePhotoUpload(true);

      try {
        for (const file of files) {
          const upload = await uploadFileToS3(file).then(
            (res) => res?.fullpath ?? null
          );

          const res = await uploadProfilePhotos(
            upload!,
            id ? Number(id) : undefined
          );

          // console.log("ress ", res);

          if (res.result_code === 200) {
            setProfilePhotoMain(res?.image);
            setIsProfilePhotoUpload(false);
          }
        }
      } catch (error) {
        new Snackbar("Failed to upload files");
      }
    }
  };

  const handleDeleteProfileImage = async () => {
    setIsDeletingProfilePhoto(true);
    const data = await deleteProfilePhotos(
      profilePhotoMainName,
      id ? Number(id) : undefined
    );

    if (data.result_code === 200) {
      setProfilePhotoMain(null);
      setIsDeletingProfilePhoto(false);
    }
  };

  return (
    <div>
      {/* <CustomNavigation
        isOpen={isNavOpen}
        onCallback={(value) => {
          setIsNavOpen(value);
        }}
        accountType={
          isMaster ? AccountTypeConst.Master : AccountTypeConst.Client
        }
      /> */}

      <CustomHeader publics={true} />
      <TopHearder title={t("editDetails")} />

      <div className="bg-dark">
        <div className="container pb-5">
          <div className="inner-box">
            <div className="accordion pt-3" id="accordionExample">
              {/* {isLogin ? <></> : <RegistrationSteps step={3} />} */}
              {errorMessages && (
                <Modal show={true} onHide={() => setErrorMessages(undefined)}>
                  <Modal.Header closeButton />
                  <Modal.Body>
                    {}
                    <div className="schedule-post text-center d-block">
                      <h3>{errorMessages}</h3>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <div className="row">
                      <div className="col-12 text-center">
                        <button
                          className="bk-btn primary-button button mt-3 w-100"
                          onClick={() => setErrorMessages(undefined)}
                        >
                          OK
                        </button>
                      </div>
                    </div>
                  </Modal.Footer>
                </Modal>
              )}
              <div className="tab-content">
                <div id="headingOne"></div>

                <div
                  id="collapseOne"
                  className="collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                ></div>
              </div>
              <div className="tab-content">
                <div id="headingTwo"></div>
                <div
                  id="collapseTwo"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                ></div>
              </div>
              <div className="tab-content">
                <div id="headingThree"></div>
                <div
                  id="collapseThree"
                  className="collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="card-body">your content goes here...</div>
                </div>
              </div>
              <div className="tab-content">
                <div
                  id="collapseFour"
                  className="collapse show"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <label htmlFor="" className="label mb-4">
                    {t("video")}
                  </label>

                  {/* <div className="d-none" id="thumbnail-generator">
                    <canvas id="thumbnail-generator-canvas" />
                    <video id="thumbnail-generator-playback" />
                  </div> */}

                  {video && (
                    <div className="my-2 video-div">
                      {/* <iframe
                        src={video.cache_url}
                        className="video-div"
                   
                      /> */}
                      {/* <video
                        src={video.cache_url}
                        style={{ width: "100%", borderRadius: 10 }}
                        className="delete-selected-video-position"
                        controls={true}
                      /> */}

                      {isSafari ? (
                        <ReactPlayer
                          className="video-player"
                          url={video.cache_url}
                          controls
                        />
                      ) : (
                        <video
                          width="320"
                          height="240"
                          controls
                          controlsList="nodownload"
                        >
                          <source src={video.cache_url} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      )}
                    </div>
                  )}

                  <div className="mb-5">
                    {!video && (
                      <FilePicker
                        onChange={handleFileInputChange}
                        // accept={["video/*"]}
                      >
                        <button className="button primary-button me-2">
                          {t("uploadVideo")}
                        </button>
                      </FilePicker>
                    )}
                    {video && (
                      <button
                        className="button primary-button"
                        data-bs-toggle="modal"
                        data-bs-target="#myModal"
                        onClick={() => handleDeleteAdMedia("video")}
                        disabled={isDeletingVideo}
                      >
                        {isDeletingVideo ? (
                          <Spinner size="sm" />
                        ) : (
                          t("deleteVideo")
                        )}
                      </button>
                    )}
                  </div>

                  <label htmlFor="" className="label mb-4">
                    {t("photo")}
                  </label>
                  <div className="row g-1 mb-3">
                    {listPhoto.map((photo, i) => (
                      <div className="col-4" key={i}>
                        <div className="custom-control custom-checkbox image-checkbox margin-top-0">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id={`photo-${i}`}
                            checked={selectedADMedia[i.toString()]}
                            onChange={(e) => {
                              selectedADMedia[i.toString()] = e.target.checked;

                              setSelectedAdMedia({ ...selectedADMedia });
                              setSelectedCount3(
                                Object.values(selectedADMedia).filter(Boolean)
                                  .length
                              ); // Update selected count
                            }}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={`photo-${i}`}
                          >
                            <img
                              src={photo.cache_url}
                              alt="#"
                              className="img-fluid add-details-img-height"
                            />
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="row g-0 mb-5">
                    <div className="col-12  ">
                      {listPhoto.length < 9 && (
                        <FilePicker
                          // onChanged={(file) =>
                          //   handleUploadADMedia(file!, "image")
                          // }
                          accept={["image/*"]}
                          multiple={true}
                          onChange={handleFileInputChange}
                        >
                          <button className="button primary-button me-2  mb-3 ">
                            {t("uploadPhoto")}
                          </button>
                        </FilePicker>
                      )}

                      {listPhoto.length > 0 && (
                        <button
                          className="button delete-photo me-2 mb-3"
                          data-bs-toggle="modal"
                          data-bs-target="#myModal"
                          onClick={() => handleDeleteAdMedia("image")}
                          disabled={selectedCount3 === 0}
                        >
                          {isDeletingADPhoto && selectedCount3 > 0 ? (
                            <Spinner size="sm" />
                          ) : (
                            `${t("deletePhoto")} ${
                              selectedCount3 > 0 ? ` (${selectedCount3})` : ""
                            }`
                          )}
                        </button>
                      )}

                      {/* <button
                        className="button  main-photo delete-photo ms-0 mb-3"
                        onClick={() => handleFlagAdMedia(clientProfile)}
                        disabled={
                          selectedCount3 === 0 ||
                          selectedADMedia.length === 0 ||
                          selectedCount3 > 1 ||
                          selectedADMedia.length > 1
                        }
                      >
                        {isFlaggingAdMedia ? (
                          <Spinner size="sm" />
                        ) : (
                          t("mainPhoto")
                        )}
                      </button> */}
                    </div>
                  </div>
                  {/* <div className="row">
                    <div className="col-5">
                      <label htmlFor="" className="label mb-4">
                        {t("adMainPhoto")}
                      </label>
                      <OverlayTrigger
                        placement="top"
                        trigger="click"
                        show={activePopover === "adMainPhotoInfo"}
                        onToggle={() => handlePopoverToggle("adMainPhotoInfo")}
                        overlay={
                          <Popover>
                            <button
                              type="button"
                              className="close-popover"
                              onClick={() =>
                                handlePopoverToggle("adMainPhotoInfo")
                              }
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>

                            <PopoverBody>{t("adMainPhotoInfo")}</PopoverBody>
                          </Popover>
                        }
                      >
                        <button type="button" className="exc-btn mx-2">
                          <i className="fa-solid fa-exclamation"></i>
                        </button>
                      </OverlayTrigger>
                    </div>
                  </div> */}
                  {/* <div className="row g-1 mb-3">
                    <div className="col-4">
                      <div className="margin-top-0">
                        <label>
                          <img
                            ref={imgRef}
                            src={
                              AdPhotoMain !== ""
                                ? AdPhotoMain
                                : "/img/blank-profile-picture.png"
                            }
                            className="img-fluid cus-cont-label"
                            alt=""
                            tabIndex={0}
                          />
                        </label>
                      </div>
                    </div>

                    <div className="col-12 pt-3"> */}
                  {/* {profilePhotoMain == null && (
                        <FilePicker
                          accept={["image/*"]}
                          onChange={handleUploadProfileImage}
                        >
                          <button className="button primary-button me-2">
                            {isProfilePhotoUpload ? (
                              <Spinner size="sm" />
                            ) : (
                              ` ${t("uploadPhoto")}
                            `
                            )}
                          </button>
                        </FilePicker>
                      )} */}
                  {/* {AdPhotoMain != "" && (
                        <button
                          className="button delete-photo"
                          data-bs-toggle="modal"
                          data-bs-target="#myModal"
                          onClick={handleDeleteProfileImage}
                          // disabled={selectedCount3 === 0}
                        >
                          {isDeletingProfilePhoto ? (
                            <Spinner size="sm" />
                          ) : (
                            `${t("deletePhoto")}
                            `
                          )}
                        </button>
                      )} */}
                  {/* </div>
                  </div> */}
                  <div className="row">
                    <div className="col-5 ">
                      <label htmlFor="" className="label mb-4">
                        {t("yourProfilePhoto")}
                      </label>
                      <OverlayTrigger
                        placement="top"
                        trigger="click"
                        show={activePopover === "profilePhotoInfo1"}
                        onToggle={() =>
                          handlePopoverToggle("profilePhotoInfo1")
                        }
                        overlay={
                          <Popover>
                            <button
                              type="button"
                              className="close-popover"
                              onClick={() =>
                                handlePopoverToggle("profilePhotoInfo1")
                              }
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>

                            <PopoverBody>{t("profilePhotoInfo1")}</PopoverBody>
                          </Popover>
                        }
                      >
                        <button type="button" className="exc-btn mx-2">
                          <i className="fa-solid fa-exclamation"></i>
                        </button>
                      </OverlayTrigger>
                    </div>
                    <div className="col-7 text-start ps-0 ">
                      <OverlayTrigger
                        placement="top"
                        trigger="click"
                        show={activePopover === "autoRotateInfo"}
                        onToggle={() => handlePopoverToggle("autoRotateInfo")}
                        overlay={
                          <Popover>
                            <button
                              type="button"
                              className="close-popover"
                              onClick={() =>
                                handlePopoverToggle("autoRotateInfo")
                              }
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>

                            <PopoverBody>{t("autoRotateInfo")}</PopoverBody>
                          </Popover>
                        }
                      >
                        <button type="button" className="exc-btn mx-2">
                          <i className="fa-solid fa-exclamation"></i>
                        </button>
                      </OverlayTrigger>
                      <label htmlFor="" className="label ">
                        {t("autoRotate")}{" "}
                      </label>
                      <label className="switch">
                        <div className="on-off-btn">
                          <button
                            className={
                              isAutoRotate === true
                                ? "btn primary-button-active rounded-button"
                                : "btn primary-button rounded-button btn3"
                            }
                            onClick={(e) => {
                              setIsAutoRotate(!isAutoRotate);

                              if (params.id) {
                                photoRotate({
                                  control: !isAutoRotate,
                                  userId: id,
                                }).then((res) => {
                                  // console.log("gfdgsdfg", res);
                                });
                              } else {
                                photoRotate({ control: !isAutoRotate }).then(
                                  (res) => {
                                    // console.log("gfdgsdfg", res);
                                  }
                                );
                              }
                            }}
                          >
                            {t("ON")}
                          </button>
                          <button
                            className={
                              isAutoRotate === false
                                ? "btn primary-button rounded-button margin-left-10"
                                : "btn primary-button rounded-button btn3 margin-left-10"
                            }
                            onClick={(e) => {
                              setIsAutoRotate(!isAutoRotate);

                              if (params.id) {
                                photoRotate({
                                  control: !isAutoRotate,
                                  userId: id,
                                }).then((res) => {
                                  // console.log("gfdgsdfg", res);
                                });
                              } else {
                                photoRotate({ control: !isAutoRotate }).then(
                                  (res) => {
                                    // console.log("gfdgsdfg", res);
                                  }
                                );
                              }
                            }}
                          >
                            {t("OFF")}
                          </button>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className="row g-1 mb-3">
                    <div className="col-4">
                      <div className="margin-top-0">
                        <label className="">
                          <img
                            ref={profileImgRef}
                            src={
                              profilePhotoMain !== null
                                ? profilePhotoMain
                                : "/img/blank-profile-picture.png"
                            }
                            className="img-fluid cus-cont-label"
                            alt="Profile Images"
                            tabIndex={0}
                          />
                        </label>
                      </div>
                    </div>

                    <div className="col-12 pt-3">
                      {profilePhotoMain == null && (
                        <FilePicker
                          accept={["image/*"]}
                          onChange={handleUploadProfileImage}
                        >
                          <button className="button primary-button me-2">
                            {isProfilePhotoUpload ? (
                              <Spinner size="sm" />
                            ) : (
                              ` ${t("uploadPhoto")}
                            `
                            )}
                          </button>
                        </FilePicker>
                      )}
                      {profilePhotoMain != null && (
                        <button
                          className="button delete-photo"
                          data-bs-toggle="modal"
                          data-bs-target="#myModal"
                          onClick={handleDeleteProfileImage}
                          // disabled={selectedCount3 === 0}
                        >
                          {isDeletingProfilePhoto ? (
                            <Spinner size="sm" />
                          ) : (
                            `${t("deletePhoto")}
                            `
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="row align-items-center g-0 mb-5">
                    <div className="col-5">
                      {/* <button
                        className="button primary-button"
                        onClick={handleFlagAdMedia}
                      >
                        {isFlaggingAdMedia ? (
                          <Spinner size="sm" />
                        ) : (
                          "Main Photo"
                        )}
                      </button> */}
                    </div>
                    <div className="col-7 text-end">
                      {/* <OverlayTrigger
                        placement="top"
                        trigger="click"
                        overlay={
                          <Popover>
                            <PopoverBody>
                              All your photos will swap sequentially display on
                              your main photo, if you want to turn it on, you
                              need to turn on your Blog first.
                            </PopoverBody>
                          </Popover>
                        }
                      >
                        <button type="button" className="exc-btn mx-2">
                          <i className="fa-solid fa-exclamation"></i>
                        </button>
                      </OverlayTrigger>
                      <label htmlFor="" className="label ">
                        Auto Rotate{" "}
                      </label>
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={!isAutoRotate}
                          onChange={(e) => setIsAutoRotate(!isAutoRotate)}
                        />
                        <span className="slider round">OFF ON</span>
                      </label> */}
                    </div>
                  </div>

                  <label htmlFor="" className="label mb-4">
                    {t("realPhoto")}
                  </label>
                  <OverlayTrigger
                    placement="top"
                    trigger="click"
                    show={activePopover === "realPhotoInfo"}
                    onToggle={() => handlePopoverToggle("realPhotoInfo")}
                    overlay={
                      <Popover>
                        <button
                          type="button"
                          className="close-popover"
                          onClick={() => handlePopoverToggle("realPhotoInfo")}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>

                        <PopoverBody>{t("realPhotoInfo")}</PopoverBody>
                      </Popover>
                    }
                  >
                    <button type="button" className="exc-btn mx-2">
                      <i className="fa-solid fa-exclamation"></i>
                    </button>
                  </OverlayTrigger>
                  <div className="row g-1 mb-3">
                    {listRealPhoto.map((photo, i) => (
                      <div className="col-4" key={i}>
                        <div className="custom-control custom-checkbox image-checkbox margin-top-0">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id={`real-photo-${i}`}
                            checked={selectedRealPhotos[i.toString()]}
                            onChange={(e) => {
                              selectedRealPhotos[i.toString()] =
                                e.target.checked;

                              setSelectedRealPhotos({ ...selectedRealPhotos });
                              setSelectedCount2(
                                Object.values(selectedRealPhotos).filter(
                                  Boolean
                                ).length
                              ); /// Update selected count
                            }}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={`real-photo-${i}`}
                          >
                            <img
                              src={photo.cache_url}
                              alt="#"
                              className="img-fluid add-details-img-height"
                            />
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="row align-items-center g-0 mb-5">
                    <div className="col-12">
                      {listRealPhoto.length < 9 && (
                        <FilePicker
                          onChange={handleUploadRealImage}
                          multiple={true}
                          accept={["image/*"]}
                        >
                          <button className="button primary-button me-2">
                            {t("uploadPhoto")}
                          </button>
                        </FilePicker>
                      )}

                      {listRealPhoto.length > 0 && (
                        <button
                          className="button delete-photo"
                          onClick={handleDeleteRealImage}
                          disabled={selectedCount2 === 0}
                        >
                          {isDeletingRealImage && selectedCount2 > 0 ? (
                            <Spinner size="sm" />
                          ) : (
                            `${t("deletePhoto")} ${
                              selectedCount2 > 0 ? ` (${selectedCount2})` : ""
                            }`
                          )}
                        </button>
                      )}
                    </div>
                  </div>

                  <label htmlFor="" className="label mb-4">
                    {t("streetPhoto")}
                  </label>

                  <div className="row g-1 mb-3">
                    {listStreetPhoto.map((photo, i) => (
                      <div className="col-4" key={i}>
                        <div className="custom-control custom-checkbox image-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id={`street-photo-${i}`}
                            onChange={(e) => {
                              selectedStreetPhotos[i.toString()] =
                                e.target.checked;

                              setSelectedStreetPhotos({
                                ...selectedStreetPhotos,
                              });
                              setSelectedCount(
                                Object.values(selectedStreetPhotos).filter(
                                  Boolean
                                ).length
                              ); // Update selected count
                            }}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={`street-photo-${i}`}
                          >
                            <img
                              src={photo.cache_url}
                              alt="#"
                              className="img-fluid add-details-img-height"
                            />
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="row align-items-center g-0 mb-5">
                    <div className="col-10">
                      {listStreetPhoto.length < 3 && (
                        <FilePicker
                          onChange={handleUploadStreetImage}
                          multiple={true}
                          accept={["image/*"]}
                        >
                          <button className="button primary-button me-2">
                            {t("uploadPhoto")}
                          </button>
                        </FilePicker>
                      )}
                      {listStreetPhoto.length > 0 && (
                        <button
                          className="button delete-photo"
                          data-bs-toggle="modal"
                          data-bs-target="#myModal"
                          onClick={handleDeleteStreet}
                          disabled={selectedCount === 0}
                        >
                          {isDeletingStreetImage && selectedCount > 0 ? (
                            <Spinner size="sm" />
                          ) : (
                            `${t("deletePhoto")} ${
                              selectedCount > 0 ? ` (${selectedCount})` : ""
                            }`
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                  <label htmlFor="" className="label mb-4">
                    {t("voiceMemo")}
                  </label>
                  <OverlayTrigger
                    placement="top"
                    trigger="click"
                    show={activePopover === "voiceMemoInfo"}
                    onToggle={() => handlePopoverToggle("voiceMemoInfo")}
                    overlay={
                      <Popover>
                        <button
                          type="button"
                          className="close-popover"
                          onClick={() => handlePopoverToggle("voiceMemoInfo")}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>

                        <PopoverBody>{t("voiceMemoInfo")}</PopoverBody>
                      </Popover>
                    }
                  >
                    <button type="button" className="exc-btn mx-2">
                      <i className="fa-solid fa-exclamation"></i>
                    </button>
                  </OverlayTrigger>

                  <div className="row align-items-center g-0 mb-5">
                    <div className="col-10">
                      {/* <AudioRecorder
                        recorderControls={recorderControls}
                        onRecordingComplete={handleUploadVoiceMemo}
                      /> */}

                      {/* <button
                        className="btn-audio-record mb-3"
                        onClick={requestMicrophoneAccess}
                      >
                        <i className="fa-solid fa-microphone"></i>
                      </button>
                      {microphoneStatus === "Microphone is on" ? (
                        <AudioRecorder
                          recorderControls={recorderControls}
                          onRecordingComplete={handleRecordingComplete}
                        />
                      ) : (
                        <>
                          <p>{microphoneStatus}</p>
                        </>
                      )} */}

                      <div>
                        {microphoneStatus === "Microphone is on" ? (
                          <AudioRecorder
                            recorderControls={recorderControls}
                            onRecordingComplete={handleRecordingComplete}
                          />
                        ) : (
                          <button
                            className="btn-audio-record-1"
                            onClick={requestMicrophoneAccess}
                          >
                            <i className="fa-solid fa-microphone"></i>
                          </button>
                        )}

                        <p className="py-3 mb-0">
                          {microphoneStatus !== "Microphone is on"
                            ? microphoneStatus
                            : ""}
                        </p>
                      </div>

                      {voiceMemo != undefined && (
                        <>
                          <SizedBox height={10} />
                          <audio src={voiceMemo.cache_url} controls />
                        </>
                      )}
                      <SizedBox height={20} />

                      {!voiceMemo?.cache_url && (
                        <FilePicker
                          onChange={handleUploadVoiceMemoFile}
                          accept={["audio/*"]}
                        >
                          <button className="button primary-button me-2">
                            {t("uploadAudio")}
                          </button>
                        </FilePicker>
                      )}

                      {voiceMemo?.cache_url ? (
                        <button
                          className="button primary-button"
                          data-bs-toggle="modal"
                          data-bs-target="#myModal"
                          onClick={handleDeleteVoiceMemo}
                        >
                          {isDeletingVoiceMemo ? (
                            <Spinner size="sm" />
                          ) : (
                            t("deleteVoiceMemo")
                          )}
                        </button>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>

                  <div className="row text-center">
                    <div className="col-12">
                      <Link
                        to={
                          isMaster
                            ? `${AppRoutePath.masterAddPage}/preview/${id}`
                            : `${AppRoutePath.clientEditPage}/preview`
                        }
                      >
                        <button className="gr-btn1">{t("preview")}</button>
                      </Link>
                      <OverlayTrigger
                        placement="top"
                        trigger="click"
                        show={activePopover === "previewInfo"}
                        onToggle={() => handlePopoverToggle("previewInfo")}
                        overlay={
                          <Popover>
                            <button
                              type="button"
                              className="close-popover"
                              onClick={() => handlePopoverToggle("previewInfo")}
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>

                            <PopoverBody>{t("previewInfo")}</PopoverBody>
                          </Popover>
                        }
                      >
                        <button type="button" className="exc-btn mx-2">
                          <i className="fa-solid fa-exclamation"></i>
                        </button>
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 text-center">
                    <button
                      className="next-button button mt-3 w-100"
                      onClick={handleBack}
                    >
                      {t("back")}
                    </button>
                  </div>
                  <div className="col-6 text-center">
                    <Link
                      to={
                        profilePhotoMain !== null
                          ? isMaster
                            ? AppRoutePath.masterAdList
                            : AppRoutePath.clientMainPage
                          : ""
                      }
                      onClick={(e) => {
                        // console.log("profilePhotoMain", profilePhotoMain);

                        if (profilePhotoMain !== null) {
                          localStorage.setItem(
                            LocalStorageKey.isRegister,
                            String(false)
                          );
                        } else {
                          new Snackbar(`${t("adMainProfilePhotoRequired")}`);

                          if (profileImgRef.current) {
                            profileImgRef.current.focus();
                          }

                          // e.preventDefault();
                        }
                      }}
                    >
                      <button className="next-button button mt-3 w-100">
                        {t("done")}
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditIndividualAddDetailPage;
