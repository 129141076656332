import Snackbar from "awesome-snackbar";
import {
	AdListModel,
	IAdListMasterSearchParams,
} from "../../domain/model/ad-list-model";
import { AdsMasterModel } from "../../domain/model/ads-master-model";
import axios from "../axios";
import { CreateUserParamsModel } from "../../domain/model/create-user-params-model";
import { ClientProfileModel } from "../../domain/model/client-profile-model";
import { convertMastersAddToClientProfile } from "../../helper/convert-helper";

export const createAdsMaster = async (params: CreateUserParamsModel) => {

	console.log("Master params: ", JSON.stringify(params));

	const res = await axios
		.post("/master/ad", params)
		.then((respons) => {
			console.log(respons);
			return respons.data.user_id as number;
		})
		.catch((error) => {
			try {
				console.log(JSON.stringify(error));
				new Snackbar("ERROR: " + (error.error.reason ?? error.error.message));
			} catch (error) {
				console.log(error);
				new Snackbar("Failed to create Ad");
			}
			return null;
		});

	return res;
};

export const getAdListMaster = async (params?: IAdListMasterSearchParams) => {
	const res = await axios
		.post(`/master/ads`, params)
		.then((respons) => {
			console.log("getAdListMaster", respons);
			return respons.data as AdListModel;
		})
		.catch((error) => {
			try {
				console.log(JSON.stringify(error));
				// new Snackbar(`ERROR: ${error.error.reason ?? error.error.message}`);
			} catch (error) {
				console.log(error);
				new Snackbar("Failed to get Ad list");
			}
			return null;
		});

	return res;
};

export const getAdsMaster = async (id: string) => {
	const res = await axios
		.get(`/master/ad/${id}`)
		.then((respons) => {
			console.log("master add details", respons.data);
			return respons.data.details
				? Object.keys(respons.data.details).includes("PD")
					? convertMastersAddToClientProfile(respons.data as AdsMasterModel)
					: (respons.data as ClientProfileModel)
				: (respons.data as ClientProfileModel);
		})
		.catch((error) => {
			try {
				console.log(JSON.stringify(error));
				new Snackbar(`ERROR: ${error.error.reason ?? error.error.message}`);
			} catch (error) {
				console.log(error);
				new Snackbar("Failed to get Ad");
			}
			return null;
		});

	return res;
};

export const editAdsMaster = async (id: string, params: any) => {
	const res = await axios
		.put(`/master/ad/${id}`, params)
		.then((respons) => {
			console.log(respons);
			return true;
		})
		.catch((error) => {
			try {
				console.log(JSON.stringify(error));
				new Snackbar(error.error.reason);
			} catch (error) {
				console.log(error);
				new Snackbar("Failed to get Ad");
			}
			return false;
		});

	return res;
};
export const editAdsMasterList = async (clientList: any) => {
	try {
		console.log("clientList ", clientList);
		const res = await axios
			.post(`master/client/editList`, { clientList: clientList })
			.then((respons) => {
				console.log(respons);
				return true;
			})
			.catch((error) => {

				return false;
			});

	} catch (e) {

	}
};

export const disableAdsMaster = async (id: string) => {
	const res = await axios
		.patch(`/master/ad/${id}/disable`)
		.then((respons) => {
			console.log(respons);
			return true;
		})
		.catch((error) => {
			try {
				console.log(JSON.stringify(error));
				new Snackbar(error.error.reason);
			} catch (error) {
				console.log(error);
				new Snackbar("Failed to disable Ad");
			}
			return false;
		});

	return res;
};

export const enableAdsMaster = async (id: string) => {
	const res = await axios
		.patch(`/master/ad/${id}/enable`)
		.then((respons) => {
			console.log(respons);
			return true;
		})
		.catch((error) => {
			try {
				console.log(JSON.stringify(error));
				new Snackbar(error.error.reason);
			} catch (error) {
				console.log(error);
				new Snackbar("Failed to enable Ad");
			}
			return false;
		});

	return res;
};

export const deleteAdsMaster = async (id: string) => {
	const res = await axios
		.delete(`/master/ad/${id}`)
		.then((respons) => {
			console.log(respons);
			return true;
		})
		.catch((error) => {
			try {
				console.log(JSON.stringify(error));
				new Snackbar(error.error.reason);
			} catch (error) {
				console.log(error);
				new Snackbar("Failed to delete Ad");
			}
			return false;
		});

	return res;
};


export const editAdsPasswordMaster = async (id: any, params: any) => {

	console.log("Change Ads Password ", params);

	const res = await axios
		.put(`/master/ad/changePassword/${id}`, params)
		.then((respons) => {
			console.log(respons);
			return true;
		})
		.catch((error) => {
			try {
				console.log(JSON.stringify(error));
				new Snackbar(error.error.reason);
			} catch (error) {
				console.log(error);
				new Snackbar("Failed to get Ad");
			}
			return false;
		});

	return res;
};
