export function formatMobileNumber(
	number: string,
	withCodeCountry: boolean = false
) {
	var formattedNumb = "";
	formattedNumb = number.substring(0, 3);
	const numbLength = number.length;

	for (let i = 3; i < numbLength; i = i + 4) {
		formattedNumb += " " + number.substring(i, Math.min(i + 4, numbLength));
	}

	return formattedNumb;
}



export function addCommas(number: number): string {
	return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


//  calculate the sume of Flower and heart
export const sumBalances = (balances: any) => {
	let sum = 0;
	// console.log("balances ", balances);

	if (balances && Array.isArray(balances) && balances.length > 0) {
		for (const balance of balances) {
			for (const key in balance) {
				sum += parseInt(balance[key]);
			}
		}
	}

	return sum;
};


export const shortenNumber = (numberValue: any) => {
	if (numberValue >= 1000 && numberValue < 1000000) {
		return (numberValue / 1000).toFixed(1) + " K";
	} else if (numberValue >= 1000000 && numberValue < 1000000000) {
		return (numberValue / 1000000).toFixed(1) + " M";
	} else {
		return numberValue;
	}
};

export function separateNumericAndNonNumeric(inputString: string): { numeric: string, nonNumeric: string } {
	const numericPart = inputString.match(/\d+/); // Match one or more digits
	const nonNumericPart = inputString.match(/\D+/); // Match one or more non-digits

	return {
		numeric: numericPart ? numericPart[0] : '',
		nonNumeric: nonNumericPart ? nonNumericPart[0] : '',
	};
}


export function extractTelegramUsernameFromLink(link: any) {
	const match = link.match(
		/(?:https?:\/\/)?t\.me\/([a-zA-Z][a-zA-Z0-9_]{4,31})/
	);

	if (match && match[1]) {
		return `Please enter a valid telegram username`;
	} else {
		return ` Invalid Telegram username`;
	}
}
//   console.log(extractTelegramUsernameFromLink("https://t.me/my_telegram123")); // Found Telegram username: my_telegram123


export const convertSecondsToMinutes = (seconds: any) => {
	const minutes = Math.floor(seconds / 60);
	const remainingSeconds = seconds % 60;
	const timeDisplay = minutes > 0 ? `${minutes} minute${minutes > 1 ? "s" : ""}` : "";
	return `${timeDisplay}${remainingSeconds > 0 ? (timeDisplay ? " " : "") : ""}${remainingSeconds} second${remainingSeconds > 1 ? "s" : ""}`;
};