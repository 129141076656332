import Snackbar from "awesome-snackbar";
import { createShop, editShop } from "../../services/master/master-shop";
import { uploadFileToS3, masterShopUpload } from "../../services/upload";
import IPageType from "../adapter/page-type-adapter";
import { ShopModel } from "../model/shop-model";


const registerOnNext = async (
	params: MasterAccountDetailsTypeOnNextParams,
	progresInt: number
) => {

	const shopModel: ShopModel = {
		en_name: params.englishShopName,
		zh_name: params.chineseShopName,
		details: {},
	};


	let bannerUrl
	if (params.shopBannerFile != null) {
		const res = await uploadFileToS3(params.shopBannerFile!);
		if (!res) {
			new Snackbar("Failed to upload banner");
			bannerUrl = null;
			return progresInt;
		} else {
			bannerUrl = res.fullpath;
		}
	}


	const isSuccess = await createShop(shopModel);

	if (isSuccess) {

		new Snackbar("Shop created successfully");
		if (bannerUrl != null) {
			await masterShopUpload(bannerUrl);
		}
		return progresInt + 1;
	}
	return progresInt;
};

const editOnNext = async (
	params: MasterAccountDetailsTypeOnNextParams,
	progresInt: number
) => {

	const shopModel: ShopModel = {
		en_name: params.englishShopName,
		zh_name: params.chineseShopName,
		details: {},
	};

	// let bannerUrl = null;

	if (params.shopBannerFile != null) {
		const res = await uploadFileToS3(params.shopBannerFile!);
		if (!res) {
			new Snackbar("Failed to upload banner");
			// bannerUrl = null;
			return progresInt;
		} else {
			// bannerUrl = res.fullpath;
			await masterShopUpload(res.fullpath);
		}
	}



	const isSuccess = await editShop(shopModel);
	if (isSuccess) {
		new Snackbar("UPDATE SUCCESS");
		return progresInt + 1;
	}
	return progresInt;
};

export default class MasterAccountDetailsType implements IPageType {
	constructor(
		path: string,
		onNext: (
			params: MasterAccountDetailsTypeOnNextParams,
			progresInt: number
		) => Promise<number>
	) {
		this.path = path;
		this.onNext = onNext;
	}
	path: string;
	onNext: (
		params: MasterAccountDetailsTypeOnNextParams,
		progresInt: number
	) => Promise<number>;

	static Register = new MasterAccountDetailsType("create", registerOnNext);
	static Edit = new MasterAccountDetailsType("edit", editOnNext);

	static getPageTypeByPath(path: string): MasterAccountDetailsType {
		switch (path) {
			case MasterAccountDetailsType.Register.path:
				return MasterAccountDetailsType.Register;
			case MasterAccountDetailsType.Edit.path:
				return MasterAccountDetailsType.Edit;
			default:
				return MasterAccountDetailsType.Register;
		}
	}
}

export interface MasterAccountDetailsTypeOnNextParams {
	chineseShopName: string;
	englishShopName: string;
	shopBannerFile: File | null;
	// type: string;
	// listService: string[];
	// listOperatingHour: OperatingHour[];
}
