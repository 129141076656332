import React, { useEffect, useState } from "react";
import CustomBackButton from "../reusable/BackButton";
import FilePicker from "../reusable/FilePicker";
import {
  editInstantPost,
  getInstantPostById,
  publishInstantPost,
  publishInstantReviewPost,
} from "../../services/instant";
import { uploadFileToS3 } from "../../services/upload";
import moment from "moment";
import { Modal, Spinner } from "react-bootstrap";
import Snackbar from "awesome-snackbar";
import AddNewPostPreview from "./AddNewPostPreview";
import CustomNavigation from "../reusable/Navigation";
import AccountTypeConst from "../../domain/enum/account-type";
import { useNavigate, useParams } from "react-router-dom";
import AppRoutePath from "../../constants/AppRoutePath";
import FormErrorMessage from "../reusable/FormErrorMessage";
import CheckboxWithImages from "../reusable/CheckboxWithImages";
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
export interface IHistoryPageProps {}
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: require('../../locales/en.json') },
      zh: { translation: require('../../locales/zh.json') }
    },
    fallbackLng: 'en', // Default language fallback
    interpolation: {
      escapeValue: false // Disable HTML escaping
    }
  });
function AddInstantReviewPost() {
  const isMaster = localStorage.getItem("role") == "master";
  const navigate = useNavigate();
  const { postId } = useParams();
  const { t } = useTranslation();
  const [language, setLanguage] = React.useState("en"); // Default language is English
  const handleLanguageSwitch = () => {
    const newLanguage = language === "en" ? "zh" : "en";
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage); // Update the language setting
  };
  const [pageTitle, setPageTitle] = useState("");
  const [photos, setPhotos] = React.useState<File[]>([]);
  const [video, setVideo] = React.useState<File>();
  const [type, setType] = React.useState<string>("post");
  const [title, setTitle] = React.useState<string>();
  const [content, setContent] = React.useState<string>();
  const [countContent, setCountContent] = useState(0);
  const [countTitle, setCountTitle] = useState(0);
  const [scheduledDate, setScheduledDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );
  const [scheduledTime, setScheduledTime] = React.useState("00:00:00");
  const [isPublishing, setIsPublishing] = useState(false);
  const [confirmationDialogVisibility, setConfirmationDialogVisibility] =
    useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  // Validation states
  const [titleValidated, setTitleValidated] = useState<string>();
  const [contentValidated, setContentValidated] = useState<string>();
  const [photoValidated, setPhotoValidated] = useState<string>();

  // check url and change title add to edit when click on edit page
  useEffect(() => {
    const path = window.location.pathname;
    if (path.endsWith("/add-instant-post")) {
      setPageTitle("New Instant Post");
    } else if (path.includes("/add-instant-post/")) {
      setPageTitle("Edit Instant Post");
    }
  }, []);

  const handleTitle: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const val = e.target.value;
    if (val.length > 0) {
      setTitleValidated("");
    } else {
      setTitleValidated("Title cannot be empty!");
    }
    if (val.length > 20) return;

    setCountTitle(val.length);
    setTitle(e.target.value);
  };

  const handleContent: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    const val = e.target.value;
    if (val.length > 0) {
      setContentValidated("");
    } else {
      setContentValidated("Content cannot be empty!");
    }
    if (val.length > 100) return;

    setCountContent(val.length);
    setContent(e.target.value);
  };

  const handleScheduledDate: React.ChangeEventHandler<HTMLDataElement> = (
    e
  ) => {
    setScheduledDate(e.target.value);
  };

  const handleScheduledTime: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    setScheduledTime(e.target.value);
  };

  const handleSubmit = async () => {
    if (isPublishing) return;

    setIsPublishing(true);

    const payload: any = {
      // user_id:507,
      title,
      content,
    };

    try {
      if (video) {
        const videoContainer = document.getElementById(
          "video-container"
        ) as HTMLVideoElement;
        const thumbnailContainer = document.getElementById(
          "thumbnail-container"
        ) as HTMLCanvasElement;
        const thumbnailContext = thumbnailContainer.getContext("2d");

        thumbnailContainer.style.width = `${videoContainer.videoWidth}px`;
        thumbnailContainer.style.height = `${videoContainer.videoHeight}px`;
        thumbnailContext!.drawImage(
          videoContainer,
          0,
          0,
          videoContainer.videoWidth,
          videoContainer.videoHeight
        );

        const videoS3 = await uploadFileToS3(video);
        let thumbnailS3 = "";

        await new Promise<void>((resolve, reject) => {
          thumbnailContainer!.toBlob(async (file) => {
            thumbnailS3 = (await uploadFileToS3(
              new File([file!], `thumbnail-${video.name}.png`)
            ))!.fullpath;

            resolve();
          });
        });

        payload.video = {
          fullpath: videoS3?.fullpath,
          thn_path: thumbnailS3,
        };
      }

      const photosS3 = await Promise.all(
        photos
          .filter((e) => e.type.includes("image"))
          .map(async (e) => await uploadFileToS3(e))
      );

      payload.img_path = photosS3.map((e) => e?.fullpath);

      if (type == "scheduled") {
        payload.scheduled = `${scheduledDate} ${scheduledTime}`;
      }

      if (postId) {
        await editInstantPost(payload, postId);
      } else {
        await publishInstantReviewPost(payload);
        
      }

      setIsPublishing(false);
      navigate(AppRoutePath.clientAddMyInstantReview);
    } catch (e) {
      new Snackbar("Failed to publish instant review post");
    }
  };

  const handleFilePicker: React.ChangeEventHandler<HTMLInputElement> = async (
    e
  ) => {
    const files: File[] = [];

    if (!e.target.files) return;

    for (let i = 0; i < e.target.files.length; i++) {
      files.push(e.target.files.item(i)!);
    }

    const videos = files.filter((e) => e.type.includes("video"));

    if (videos.length > 0) {
      setVideo(videos[0]);
    }

    setPhotos([...photos, ...files.filter((e) => e.type.includes("image"))]);
  };

  const handleGetData = async () => {
    const data = await getInstantPostById(postId!);

    if (!data) return;

    setTitle(data.title);
    setContent(data.content);
    setPhotos(
      await Promise.all(
        data.media.image?.map(async (e) => {
          return (await (await fetch(e.cache_url)).blob()) as File;
        }) ?? []
      )
    );
  };

  useEffect(() => {
    if (postId) handleGetData();
  }, []);

  const stickers = [
    {
      id: 1,
      name: "Body Figure",
      value: "body_figure",
      img: "/img/emoji/body_figure/2.png",
    },
    {
      id: 2,
      name: "Looking",
      value: "looking",
      img: "/img/emoji/looking/1.png",
    },
    {
      id: 3,
      name: "Service",
      value: "service",
      img: "/img/emoji/service/2.png",
    },
    {
      id: 4,
      name: "Technic",
      value: "technic",
      img: "/img/emoji/technic/3.png",
    },
  ];

  return (
    <>
      <Modal
        show={confirmationDialogVisibility}
        onHide={() => setConfirmationDialogVisibility(false)}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          {}
          <div className="schedule-post text-center d-block">
            <div className={type == "scheduled" ? "" : "d-none"}>
              <h3>Are you sure to schedule post on this date?</h3>
              <h4>
                {
                  [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December",
                  ][new Date(scheduledDate).getMonth()]
                }
              </h4>
              <strong>{new Date(scheduledDate).getDate()}</strong>
              <span>
                {new Date(scheduledDate).toString().split(" ")[0]}{" "}
                {scheduledTime}
              </span>
            </div>
            <div className={type != "scheduled" ? "" : "d-none"}>
              <h3>Are you sure to post now?</h3>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="col-6 text-center">
              <button
                className="primary-button button mt-3 w-100"
                onClick={handleSubmit}
              >
                {isPublishing ? <Spinner size="sm" /> : "Yes"}
              </button>
            </div>
            <div className="col-6 text-center">
              <button
                className="bk-btn primary-button button mt-3 w-100"
                onClick={() => setConfirmationDialogVisibility(false)}
              >
                No
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      <CustomNavigation
        isOpen={isNavOpen}
        onCallback={(value: boolean) => {
          setIsNavOpen(value);
        }}
        accountType={
          isMaster ? AccountTypeConst.Master : AccountTypeConst.Client
        }
      />

      <header>
        <CustomBackButton />
        <div className="title"> New Instant Review Post</div>
        <div className="d-flex justify-content-end pt-5">
          <p className="lang">
            <button onClick={handleLanguageSwitch} className="menu-language">{language === "en" ? "中文" : "ENG"} {/* Display "ENG" or "中文" based on language */}</button>
          </p>
        </div>
        <a className="clk-btn" onClick={() => setIsNavOpen(false)}>
          <i className="fa-solid fa-bars"></i>
        </a>
      </header>
      <div className={isPreview ? "" : "d-none"}>
        <AddNewPostPreview
          title={title!}
          content={content!}
          photos={photos}
          video={video!}
          onConfirm={() => setConfirmationDialogVisibility(true)}
          onCancel={() => setIsPreview(false)}
        />
      </div>
      <div className={(isPreview ? "d-none" : "") + " bg-dark"}>
        <div className="container">
          <div className="inner-box pt-5">
            {photos.length > 0 || video ? (
              <></>
            ) : (
              <div className="post-video mb-3 py-2 px-3">
                <p>Upload only 1 video or multiple photos</p>
              </div>
            )}

            {video && (
              <>
                <video
                  id="video-container"
                  src={URL.createObjectURL(video)}
                  className="w-100 mb-3"
                  controls
                />
                <canvas id="thumbnail-container" className="d-none" />
              </>
            )}

            <div className="row g-1 mb-3">
              {photos.map((e, i) => (
                <div className="col-6" key={i}>
                  <img src={URL.createObjectURL(e)} className="image-grid" />
                </div>
              ))}
            </div>

            <FilePicker
              accept={["image/png", "video/*"]}
              multiple={true}
              onChange={handleFilePicker}
            >
              <button className="button primary-button me-2">
                Upload Photo/Video
              </button>
            </FilePicker>
            <div>
              {photoValidated && (
                <>
                  <FormErrorMessage message={photoValidated} />
                  <br />
                </>
              )}
            </div>

            <div className="post-box mt-3">
              <input
                type="text"
                className="form-control"
                placeholder="Add Title"
                id="title"
                onChange={handleTitle}
                value={title}
                maxLength={20}
                required
              />
              <span
                className="pull-right label label-default"
                id="count_message_title"
              >
                {countTitle} / 20
              </span>
              <textarea
                name=""
                id="text"
                cols={30}
                rows={5}
                className="form-control"
                placeholder="Add Content"
                onChange={handleContent}
                value={content}
                maxLength={100}
                required
              />
              <span
                className="pull-right label label-default"
                id="count_message"
              >
                {" "}
                {countContent} / 1000
              </span>
            </div>

            {titleValidated && <FormErrorMessage message={titleValidated} />}

            {contentValidated && (
              <>
                <br />
                <FormErrorMessage message={contentValidated} />
              </>
            )}
            {/* <label className="text-white pt-3">Stickers</label>
            <div className="custom-checkbox gap-2">
              {stickers?.map((data, i) => {
                return (
                  <CheckboxWithImages
                    value={data.value}
                    name={data.name}
                    image={data.img}
                    key={i}
                  />
                );
              })}
            </div> */}

            <div className="d-flex justify-content-end my-3">
              <p className="text-end primary-color preview">
                <a href="#" onClick={() => setIsPreview(true)}>
                  Preview
                </a>
              </p>
              <div className="mx-3" />
              <button
                className="button primary-button"
                onClick={(e) => {
                  if (
                    postId &&
                    (title?.length ?? 0) > 0 &&
                    (content?.length ?? 0) > 0 &&
                    (photos?.length ?? 0) > 0
                  ) {
                    return handleSubmit();
                  }
                  if (
                    (title?.length ?? 0) > 0 &&
                    (content?.length ?? 0) > 0 &&
                    (photos?.length ?? 0) > 0
                  ) {
                    return setConfirmationDialogVisibility(true);
                  } else {
                    if ((title?.length ?? 0) <= 0) {
                      setTitleValidated("Title cannot be empty!");
                    }
                    if ((content?.length ?? 0) <= 0) {
                      setContentValidated("Content cannot be empty!");
                    }
                    if ((photos?.length ?? 0) <= 0) {
                      setPhotoValidated("Photo(s) or Video cannot be empty!");
                    }
                  }
                }}
              >
                {isPublishing ? (
                  <Spinner size="sm" />
                ) : postId ? (
                  "Update"
                ) : (
                  "Publish"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddInstantReviewPost;
