import { useEffect, useState } from "react";
import { findOneCoinTransaction } from "../../../../services/products/one-coin";
import { useTranslation } from "react-i18next";
import { OverlayTrigger, Pagination, Popover, Spinner } from "react-bootstrap";
import { getRemarksDefault } from "../../../../services/modules";
import NoDataFound from "../../../reusable/NoDataFound";

const OneCoinTableRowV2 = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [oneCoinTrans, setOneCoinTrans] = useState([]);
  const langType = localStorage.getItem("i18nextLng");
  const [remarksData, setRemarksData] = useState<any>({});
  const [activePopover, setActivePopover] = useState(null);

  const handlePopoverToggle = (tx_id: any) => {
    if (activePopover === tx_id) {
      setActivePopover(null);
    } else {
      setActivePopover(tx_id);
    }
  };

  const getData = () => {
    setIsLoading(true);
    findOneCoinTransaction({ page: currentPage, per_page: perPage }).then(
      (res) => {
        if (res) {
          setOneCoinTrans(res["item(s)"]);
          const totalPages = res.total_pages || 1;
          setTotalPages(totalPages);

          setIsLoading(false);
        }
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    getData();
    getRemarksDefault().then((res) => {
      if (res != null) {
        setRemarksData(res);
      }
    });
  }, [currentPage, totalPages]);

  const goToPage = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const goToNextPage = () => {
    goToPage(currentPage + 1);
  };

  const goToPrevPage = () => {
    goToPage(currentPage - 1);
  };

  return isLoading ? (
    <div className="justify-content-center d-flex text-white">
      <Spinner />
    </div>
  ) : (
    <>
      <div>
        <b>
          <span className="p-2 text-14" style={{ color: "#ff3751" }}>
            {t("myOneCoinTransaction")}
          </span>
        </b>
      </div>
      <br />
      {oneCoinTrans?.length > 0 ? (
        <>
          <div className="table-responsive">
            <table style={{ color: "#b3c1ce" }} className="w-100 text-12">
              <colgroup>
                <col style={{ width: "15%" }} />
                <col style={{ width: "25%" }} />
                <col style={{ width: "15%" }} />
                <col style={{ width: "20%" }} />
                <col style={{ width: "25%" }} />
              </colgroup>
              <thead style={{ borderBottom: "1px solid #ff3751" }}>
                <tr>
                  <th className="text-start p-2">{t("dateTime")}</th>
                  <th className="text-center p-2">{t("name")}</th>
                  <th className="text-center">{t("amount")}</th>
                  <th className="text-center">{t("t_productName")}</th>
                  <th className="text-center">{t("remarks")}</th>
                </tr>
              </thead>
              <tbody>
                {oneCoinTrans.map((transaction: any, index) => (
                  <tr key={index} style={{ borderBottom: "1px solid #ff3751" }}>
                    <td className="p-2">
                      <div>{transaction.tx_time.split(" ")[0]}</div>
                      <div>{transaction.tx_time.split(" ")[1]}</div>
                    </td>
                    <td className="text-center">
                      <div>
                        {" "}
                        {transaction.related_tx_info
                          ? transaction.related_tx_info?.sender
                          : transaction.receiver}
                      </div>
                      <div>
                        {transaction.related_tx_info ? (
                          <>
                            {transaction.related_tx_info?.sender_en_name
                              ? langType === "en"
                                ? transaction.related_tx_info?.sender_en_name
                                : transaction.related_tx_info?.sender_zh_name
                              : "-"}
                          </>
                        ) : (
                          <>
                            {transaction.receiver_en_name
                              ? langType === "en"
                                ? transaction.receiver_en_name
                                : transaction.receiver_zh_name
                              : "-"}
                          </>
                        )}
                      </div>
                    </td>
                    <td className="text-center">
                      {/* <span className="badge rounded-pill bg-1 p-2 border-0 text-white  fw-normal b-amount text-12">
                          {transaction.coins === "free" && langType === "zh" ? "免費" :
                            transaction.coins}
                        </span> */}
                      <span
                        className={`badge rounded-pill p-2 border-0 text-white fw-normal b-amount text-12 ${
                          transaction.coins > 0 ? "bg-success" : "bg-1"
                        }`}
                      >
                        {transaction.coins === "free" && langType === "zh"
                          ? "免費"
                          : transaction.coins}
                      </span>
                    </td>
                    <td className="text-center">
                      {transaction.product === "AD" && langType === "zh"
                        ? "廣告"
                        : transaction.product === "BLOG" && langType === "zh"
                        ? "網誌"
                        : transaction.product?.split("|")[0]}
                    </td>
                    <td className="text-center">
                      <OverlayTrigger
                        trigger="click"
                        placement="left"
                        show={activePopover === transaction.tx_id}
                        onToggle={() => handlePopoverToggle(transaction.tx_id)}
                        overlay={
                          <Popover
                            id="popover-positioned-left"
                            title="Popover left"
                            className="cursor-pointer"
                          >
                            <div className="popover-body">
                              {remarksData[transaction.remark]}
                            </div>
                          </Popover>
                        }
                      >
                        <div className="cursor-pointer">
                          {transaction.remark}
                        </div>
                      </OverlayTrigger>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <br />

          <div className="d-flex justify-content-center my-3">
            <Pagination>
              <Pagination.Prev
                onClick={goToPrevPage}
                disabled={currentPage === 1}
              />

              {/* Show the first page */}
              <Pagination.Item onClick={() => goToPage(1)}>
                {t("first")}
              </Pagination.Item>

              {/* Show ellipsis if there are more than 3 pages before the current page */}
              {currentPage > 3 && <Pagination.Ellipsis disabled />}

              {/* Show two pages before the current page */}
              {currentPage > 2 && (
                <Pagination.Item onClick={() => goToPage(currentPage - 1)}>
                  {currentPage - 1}
                </Pagination.Item>
              )}

              {/* Show the current page */}
              <Pagination.Item active>{currentPage}</Pagination.Item>

              {/* Show two pages after the current page */}
              {currentPage < totalPages - 1 && (
                <Pagination.Item onClick={() => goToPage(currentPage + 1)}>
                  {currentPage + 1}
                </Pagination.Item>
              )}

              {/* Show ellipsis if there are more than 3 pages after the current page */}
              {currentPage < totalPages - 2 && <Pagination.Ellipsis disabled />}

              {/* Show the last page */}
              <Pagination.Item onClick={() => goToPage(totalPages)}>
                {t("last")}
              </Pagination.Item>

              <Pagination.Next
                onClick={goToNextPage}
                disabled={currentPage === totalPages}
              />
            </Pagination>
          </div>
        </>
      ) : (
        <>
          <NoDataFound />
        </>
      )}
    </>
  );
};

export default OneCoinTableRowV2;
