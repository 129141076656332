import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import { Link } from "react-router-dom";
import SizedBox from "../../reusable/Sizedbox";
import Avatar from "../../reusable/Avatar";

function YourAdPage() {
  return (
    <div>
      <header>
        <div className="title">Your Ad Page</div>
      </header>

      <div className="bg-dark">
        <div className="container">
          <div className="inner-box">
            {/* <div className="accordion pt-3" id="accordionExample">
              <YourAdPage step={3} />
            </div>

            <div className="tab-content">
              <div id="headingOne"></div>

              <div
                id="collapseOne"
                className="collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              ></div>
            </div>
            <div className="tab-content">
              <div id="headingTwo"></div>
              <div
                id="collapseTwo"
                className="collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              ></div>
            </div>
            <div className="tab-content">
              <div id="headingThree"></div>
              <div
                id="collapseThree"
                className="collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="card-body">your content goes here...</div>
              </div>
            </div> 
            <div className="tab-content">
              <div id="headingFour"></div>
              <div
                id="collapseFour"
                className="collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample"
              ></div>
            </div>*/}

            <div className="d-flex justify-content-end pt-5"></div>
            <form>
              <div className="row mt-2 mb-5">
                <div className="d-flex align-items-center">
                  <div className="row-container">
                    <div className="column-container">
                      <Avatar
                        src="https://www.kindpng.com/picc/m/22-223863_no-avatar-png-circle-transparent-png.png"
                        alt="User's Avatar"
                        size={120}
                      />
                      <SizedBox height={10} />
                      <p
                        style={{ display: "flex", justifyContent: "center" }}
                        className="text-name "
                      >
                        中文名字
                      </p>

                      <SizedBox width={2} />
                      <p
                        style={{ display: "flex", justifyContent: "center" }}
                        className="text-name "
                      >
                        Client Name
                      </p>
                    </div>

                    <SizedBox width={25} />
                    <div className="column-container">
                      <p className="text-mid ">Type</p>

                      {/* <FontAwesomeIcon icon={location} /> */}
                      <p className="text-mid ">Region</p>

                      <p className="text-mid ">Building</p>
                    </div>

                    <SizedBox width={50} />

                    <div className="column-container">
                      <Button className="follow-button button">+ Follow</Button>
                      <SizedBox height={5} />

                      <div className="text-reg-white"> 5.2k followers</div>
                    </div>
                  </div>
                </div>
                <SizedBox height={5} />

                <div
                  style={{ display: "flex", justifyContent: "flex-end" }}
                  className="row-container"
                >
                  <h3>$700</h3>
                  <SizedBox width={10} />
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/1407/1407574.png"
                    style={{ width: "45px", height: "45px" }}
                  />
                  <h3>899</h3>
                  <SizedBox width={10} />

                  <SizedBox width={10} />
                  <Button className="flower-button w-20 button">
                    Send Flower
                  </Button>
                </div>
                <SizedBox height={20} />
                <div className="row-container">
                  <div className="column-container">
                    <p className="text-reg-red">Operating Hours</p>
                    <p className="text-reg-white">10:00-20:00</p>
                  </div>
                  <SizedBox width={10}></SizedBox>

                  <div className="column-container">
                    <p className="text-reg-red">AGE</p>
                    <p className="text-reg-white">28</p>
                  </div>
                  <SizedBox width={10}></SizedBox>
                  <div className="column-container">
                    <p className="text-reg-red">Nationality</p>
                    <p className="text-reg-white">Hong Kong</p>
                  </div>
                  <SizedBox width={10}></SizedBox>
                  <div className="column-container">
                    <p className="text-reg-white">English, Chinese</p>
                    <p className="text-reg-white">158cm</p>
                    <p className="text-reg-white">38C-25-36</p>
                  </div>
                </div>
                <SizedBox height={100}></SizedBox>
                <p className="text-reg-white">
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                  diam nonummy nibh euismod tincidunt ut laoreet dolore magna
                  aliquam erat volutpat. Ut wisi enim ad minim veniam, quis
                  nostrud exerci tation ullam.
                </p>
                <div className="row-container">
                  <p className="text-reg-red">Welcome</p>
                  <SizedBox width={10}></SizedBox>
                  <p className="text-reg-white">PD, Foreginer</p>
                </div>

                <div className="column-container">
                  <div className="row-container"></div>
                  <SizedBox height={10} />
                  <p style={{ textAlign: "right" }}> +85 212 3456 7788</p>
                  <SizedBox height={10} />
                  <div className="row-container">
                    <div className="column-container">
                      <p style={{ textAlign: "right" }}>Need Caller Display</p>
                      <p style={{ textAlign: "right" }}>
                        Please Booking in Advance
                      </p>
                      <p style={{ textAlign: "right" }}>Call to Open Door</p>
                    </div>
                    <a className="t-chat"><i className="fa-solid fa-phone"></i></a>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default YourAdPage;
