import * as React from "react";
import { addCommas, sumBalances } from "../../../../helper/format-helper";
import {
  getHeartBalance,
  purchaseHeart,
} from "../../../../services/products/heart";
import { findCoinBalance } from "../../../../services/products/one-coin";
import WalletPaymentCardItem from "./WalletPaymentCardItem";
import { getClientProfile } from "../../../../services/client/client-profile";
import { getClientPrice } from "../../../../services/modules";
import { useTranslation } from "react-i18next";
const langType = localStorage.getItem("i18nextLng");

export interface IReloadHeartCardCompProps {
  getBalance?: () => Promise<number>;
  updateTable?: () => void;
}

export default function ReloadHeartCardComp(props: IReloadHeartCardCompProps) {
  const [heartBalance, setHeartBalance] = React.useState(0);
  const [clientPriceList, setClientPriceList] = React.useState([]);
  const { t } = useTranslation();

  const heartIcon = (
    <i className="fa-solid fa-heart icon" style={{ fontSize: 22 }}></i>
  );
  const iconCoin = (
    <img
      src="/img/onecoin_icon.png"
      style={{ marginBottom: 3, height: 25, width: 25 }}
      alt=""
    />
  );

  //   React.useState(() => {
  //     getHeartBalance().then((res: any) => {
  //       const data = Number(sumBalances(res));
  //       setHeartBalance(data);
  //     });
  //   });

  const priceList: Record<string, any> = JSON.parse(
    localStorage.getItem("priceList") || "{}"
  );
  const userType = localStorage.getItem("userType") || "";

  React.useEffect(() => {
    getHeartBalanceFun();

    // // };

    if (userType != "") {
      const flowerBackage: any = [];
      for (const key in priceList) {
        if (key.includes("HEARTx")) {
          flowerBackage.push([key, priceList[key]]);
        }
      }
      setClientPriceList(flowerBackage);
    }
  }, []);

  const getHeartBalanceFun = () => {
    getHeartBalance().then((res: any) => {
      if (res) {
        setHeartBalance(Number(sumBalances(res || 0)));
      }
    });
  };

  return (
    <div>
      <div className="flower-box">
        <h5 className="balance-title mb-0 d-flex align-items-center justify-content-center">
          {t("heartbalance")}
        </h5>
        <h5 className="balance-value d-flex align-items-center justify-content-center gap-1">
          {/* {heartIcon} */}
          {/* <p> {addCommas(heartBalance)}</p> */}
          {/* <p> {addCommas(heartBalance)}</p> */}
        </h5>
      </div>

      {clientPriceList.map((data: any, index: Number) => {
        console.log(data);
        const key = data[0];
        const price = data[1];
        const [englishName, chineseName] = key.split("|");
        const productName = data[0];

        // console.log(englishName);

        return (
          <>
            <WalletPaymentCardItem
              englishName={englishName}
              chineseName={chineseName}
              packagePrice={price}
              productName={productName}
              walletName={t("heartBal")}
              paymentName={t("Reloads")}
              initQuantity={1}
              multiple={1}
              coinPerBalanceCount={1}
              getBalance={getHeartBalance}
              getCoinBalance={findCoinBalance}
              getNewRanking={async () => {
                return 9;
              }}
              getBalanceRanking={async () => {
                const res: Ranking = {
                  rank: "N/A",
                  units: "N/A",
                  user_id: "0",
                };
                return res;
              }}
              sendPayment={async (unit, target, prod_id) =>
                purchaseHeart(unit, prod_id)
              }
              icon={heartIcon}
              coinIcon={iconCoin}
              updateTable={props.updateTable}
            />
          </>
        );
      })}
    </div>
  );
}
