import { addCommas } from "../../../../helper/format-helper";
import SizedBox from "../../../reusable/Sizedbox";

export interface IWalletCardItemProps {
  title?: string;
  engTitle?: string;
  ChiTitle?: string;
  coin?: number;
  icon?: JSX.Element;
  balance?: JSX.Element;
  value?: number;
  langType: string;
}

export default function WalletCardItemComp(props: IWalletCardItemProps) {
  return (
    <div style={{ display: "flex", marginBottom: 5, alignItems: "center" }}>
      <div className="col-6">
        <p
          className="mb-0"
          style={{
            textAlign: "left",
            marginLeft: 10,
            color: "#ff3751",
            fontSize: 13.5,
            fontWeight: 500,
          }}
        >
          {props.title && props.title}
          {/* {props.title} */}
          {props.langType === "zh" ? props.ChiTitle : props.engTitle}
        </p>
      </div>
      <div
        className="col-6 d-flex justify-content-lg-start px-lg-5 align-items-center"
        style={{ marginLeft: 10 }}
      >
        <div>{props.icon}</div>
        <SizedBox width={10} />
        <h5 className="balance-value mt-0 align-items-center">
          {props.value && addCommas(props.value)}
        </h5>
      </div>
    </div>
  );
}
