import { useEffect, useState } from "react";
import { WalletType } from "../../../../constants/WalletType";
import {
  findCoinBalance,
  findOneCoinTransaction,
} from "../../../../services/products/one-coin";
import OneCoinCardComp from "../card/OneCoinCardComp";
import ClientWalletPageTemplate from "../interface/ClientWalletPageTemplate";
import oneCoinTableRow from "../table/OneCoinTableRow";
import WalletTableComp from "../table/WalletTableComp";

export default class OneCoinWalletTemplate implements ClientWalletPageTemplate {
  constructor(id?: number) {
    this.id = id;
  }
  id?: number;
  type = WalletType.OneCoin;
  title = "OneCoin";
  icon = (<img src="/img/onecoin_icon.png" alt="" />);

  async cardComp() {
    const coinVal = await findCoinBalance();
    return <OneCoinCardComp coin={coinVal} />;
  }

  async tableComp() {
    return (
      <WalletTableComp
        walletTableRow={oneCoinTableRow}
        getTableData={async (
          page?: Number,
          per_page?: Number,
          to_user?: any[],
          to_post?: any[],
          from?: any[],
          to?: any[],
          tx_id?: string,
          start_at?: string,
          end_at?: string,
          desc_sort?: boolean,
          is_free?: boolean,
          order_cs_id?: Number
        ) => {
          const result = await findOneCoinTransaction({
            page: page,
            per_page: per_page,
          });

          if (result) {
            return result;
          } else {
            return null;
          }
        }}
      />
    );
  }
}
