import React, { useEffect, useState } from "react";
import FilePicker from "../reusable/FilePicker";
import { publishInstantPost } from "../../services/instant";
import { uploadFileToS3 } from "../../services/upload";
import moment from "moment";
import { Modal, Spinner } from "react-bootstrap";
import Snackbar from "awesome-snackbar";
import AddNewPostPreview from "./AddNewPostPreview";
import CustomNavigation from "../reusable/Navigation";
import AccountTypeConst from "../../domain/enum/account-type";
import { useNavigate, useParams } from "react-router-dom";
import AppRoutePath from "../../constants/AppRoutePath";
import FormErrorMessage from "../reusable/FormErrorMessage";

import { useTranslation } from "react-i18next";

import { InstantPostMedia } from "../../domain/model/instant-post-model";
import CustomHeader from "../reusable/Header";
import TopHearder from "../reusable/TopHearder";
export interface IHistoryPageProps {}

function AddNewPost() {
  const isMaster = localStorage.getItem("role") == "master";
  const navigate = useNavigate();
  const { postId, userId } = useParams();

  const [pageTitle, setPageTitle] = useState("");
  const [photos, setPhotos] = React.useState<File[]>([]);
  const [video, setVideo] = React.useState<File | undefined>();
  const [type, setType] = React.useState<string>("post");
  const [title, setTitle] = React.useState<string>();
  const [content, setContent] = React.useState<string>();
  const [countContent, setCountContent] = useState(0);
  const [countVideos, setCountVideos] = useState(0);
  const [countTitle, setCountTitle] = useState(0);
  const [postMedia, setPostMedia] = React.useState<InstantPostMedia>();
  const [scheduledDate, setScheduledDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );

  const currentDate1 = new Date();
  const currentHour = String(currentDate1.getHours()).padStart(2, "0");
  const currentMinute = String(currentDate1.getMinutes() + 2).padStart(2, "0"); // Adding 2 minutes
  const currentSecond = String(currentDate1.getSeconds()).padStart(2, "0");
  const currentTime = `${currentHour}:${currentMinute}:${currentSecond}`;

  const [scheduledTime, setScheduledTime] = useState(currentTime);
  const [isPublishing, setIsPublishing] = useState(false);
  const [confirmationDialogVisibility, setConfirmationDialogVisibility] =
    useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(true);
  const [isUploaded, setIsUploaded] = useState<boolean[]>([]);
  const [uploadedPhotos, setUploadedPhotos] = useState<string[]>([]);
  const [titleValidated, setTitleValidated] = useState<string>();
  const [contentValidated, setContentValidated] = useState<string>();
  const [photoValidated, setPhotoValidated] = useState<string>();
  const [errorMessages, setErrorMessages] = useState<string | undefined>();
  const { t } = useTranslation();

  function deleteHandler(image: File) {
    setPhotos((prevPhotos) => prevPhotos.filter((photo) => photo !== image));
  }
  function deleteHandlerVideo() {
    setVideo(undefined);
  }

  const currentDate = new Date().toISOString().split("T")[0];
  // check url and change title add to edit when click on edit page
  useEffect(() => {
    const path = window.location.pathname;
    if (path.endsWith("/add-instant-post")) {
      setPageTitle(t("newInstantPost"));
    } else if (path.includes("/add-instant-post/")) {
      setPageTitle(t("editInstantPost"));
    }
  }, []);

  const handleTitle: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const val = e.target.value;
    if (val.length > 0) {
      setTitleValidated("");
    } else {
      setTitleValidated("Title cannot be empty!");
    }

    setCountTitle(val.length);
    setTitle(e.target.value);
  };

  const handleContent: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    const val = e.target.value;
    if (val.length > 0) {
      setContentValidated("");
    } else {
      setContentValidated("Content cannot be empty!");
    }

    setCountContent(val.length);
    setContent(e.target.value);
  };

  const handleScheduledDate: React.ChangeEventHandler<HTMLDataElement> = (
    e
  ) => {
    setScheduledDate(e.target.value);
  };

  const handleScheduledTime: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    setScheduledTime(e.target.value);
  };

  const handleSubmit = async () => {
    // console.log("Instant POst publish");

    if (isPublishing) return;

    setIsPublishing(true);

    const payload: any = {
      title,
      content,
    };

    try {
      if (video) {
        const videoContainer = document.getElementById(
          "video-container"
        ) as HTMLVideoElement;
        const thumbnailContainer = document.getElementById(
          "thumbnail-container"
        ) as HTMLCanvasElement;
        const thumbnailContext = thumbnailContainer.getContext("2d");

        thumbnailContainer.style.width = `${videoContainer.videoWidth}px`;
        thumbnailContainer.style.height = `${videoContainer.videoHeight}px`;
        thumbnailContext!.drawImage(
          videoContainer,
          0,
          0,
          videoContainer.videoWidth,
          videoContainer.videoHeight
        );

        const videoS3 = await uploadFileToS3(video);
        let thumbnailS3 = "";

        await new Promise<void>((resolve, reject) => {
          thumbnailContainer!.toBlob(async (file) => {
            thumbnailS3 = (await uploadFileToS3(
              new File([file!], `thumbnail-${video.name}.png`)
            ))!.fullpath;

            resolve();
          });
        });

        payload.video = {
          fullpath: videoS3?.fullpath,
          thn_path: thumbnailS3,
        };
      }

      const photosS3 = await Promise.all(
        photos
          .filter((e) => e.type.includes("image"))
          .map(async (e) => await uploadFileToS3(e))
      );

      payload.img_path = photosS3.map((e) => e?.fullpath);

      if (type == "scheduled") {
        payload.scheduled = `${scheduledDate} ${scheduledTime}`;
      }

      if (isMaster) {
        payload.target = userId;
      }

      await publishInstantPost(payload);

      setIsPublishing(false);

      isMaster
        ? navigate(`${AppRoutePath.masterMyInstantPosts}/${userId}`)
        : navigate(AppRoutePath.clientMyInstantPosts);

      // navigate(AppRoutePath.clientMyInstantPosts);
    } catch (e) {
      new Snackbar("Failed to publish instant post");
    }
  };

  const MAX_FILE_SIZE_MB: number = 0.5;

  const resizeImage = (file: File): Promise<File> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event: ProgressEvent<FileReader>) => {
        const image = new Image();
        image.src = event.target?.result as string;

        image.onload = () => {
          const canvas = document.createElement("canvas");
          const maxSize = MAX_FILE_SIZE_MB * 999 * 1024; // Convert MB to bytes

          let width = image.width;
          let height = image.height;

          if (file.size > maxSize) {
            const scale = Math.sqrt(maxSize / file.size);
            width *= scale;
            height *= scale;
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext("2d");
          ctx?.drawImage(image, 0, 0, width, height);

          canvas.toBlob((blob) => {
            if (blob) {
              const resizedFile = new File([blob], file.name, {
                type: file.type,
              });
              resolve(resizedFile);
            } else {
              reject(new Error("Error converting canvas to blob"));
            }
          }, file.type);
        };
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };

  const handleFilePicker: React.ChangeEventHandler<HTMLInputElement> = async (
    e
  ) => {
    const files: File[] = [];

    if (!e.target.files) return;

    for (let i = 0; i < e.target.files.length; i++) {
      files.push(e.target.files.item(i)!);
    }
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      // console.log(`File ${i + 1} size: ${file.size / (1024 * 1024)} MB`);
      if (file.size >= 60 * 1024 * 1024) {
        // Check if size is 60MB or more
        // console.log(`File ${i + 1} size exceeds 60MB.`);
        // You can handle the error or return from the function as needed

        setErrorMessages("File size should not exceed 60MB.");
        return;
      }
      if (file.type.includes("image")) {
        if (file.size >= 1024 * 1024) {
          // Check if size is 1MB or more
          const resizedFile = await resizeImage(file);
          // console.log(
          //   `Resized image ${i + 1} size: ${
          //     resizedFile.size / (1024 * 1024)
          //   } MB`
          // );
        }
      }
    }
    const videos = files.filter((e) => e.type.includes("video"));
    const images = files.filter((e) => e.type.includes("image"));
    setCountVideos(videos.length);
    const totalImages = images.length + photos.length;
    const totalVideos = videos.length;

    if (totalVideos > 1 && totalImages > 9) {
      setErrorMessages(
        "You can only select one (1) video and up to nine (9) image(s)."
      );
      return;
    } else if (totalVideos > 1) {
      setErrorMessages("You can only select one (1) video.");
      return;
    } else if (totalImages > 9) {
      setErrorMessages("You can only select up to nine (9) image(s).");
      return;
    }

    if (totalVideos > 0) {
      // setCountVideos(totalVideos);
      setVideo(videos[0]);
    }

    const resizedPhotos = [];

    for (let i = 0; i < images.length; i++) {
      const imageFile = images[i];
      const resizedImage = await resizeImage(imageFile);
      resizedPhotos.push(resizedImage);
    }

    setPhotos([...photos, ...resizedPhotos]);

    const newUploadedStatus = new Array(files.length).fill(false);

    setIsUploaded([...isUploaded, ...newUploadedStatus]);
  };

  return (
    <>
      <Modal
        show={confirmationDialogVisibility}
        onHide={() => setConfirmationDialogVisibility(false)}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          {}
          <div className="schedule-post text-center d-block">
            <div className={type == "scheduled" ? "" : "d-none"}>
              <h3>{t("addScheduledPostConfirm")}?</h3>
              <h4>
                {
                  [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December",
                  ][new Date(scheduledDate).getMonth()]
                }
              </h4>
              <strong>{new Date(scheduledDate).getDate()}</strong>
              <span>
                {new Date(scheduledDate).toString().split(" ")[0]}{" "}
                {scheduledTime}
              </span>
            </div>
            <div className={type != "scheduled" ? "" : "d-none"}>
              <h3>{t("addPostConfirm")}?</h3>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="col-6 text-center">
              <button
                className="btn-modal-close1 mt-3 "
                onClick={() => setConfirmationDialogVisibility(false)}
              >
                {t("no")}
              </button>
            </div>
            <div className="col-6 text-center">
              <button
                className="primary-button button mt-3 w-100"
                onClick={handleSubmit}
              >
                {isPublishing ? <Spinner size="sm" /> : t("yes")}
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      <CustomNavigation
        isOpen={isNavOpen}
        onCallback={(value: boolean) => {
          setIsNavOpen(value);
        }}
        accountType={
          isMaster ? AccountTypeConst.Master : AccountTypeConst.Client
        }
      />

      <CustomHeader />
      <TopHearder title={t("newInstantPost")} />
      <div className={isPreview ? "" : "d-none"}>
        <AddNewPostPreview
          title={title!}
          content={content!}
          photos={photos}
          video={video!}
          onConfirm={() => setConfirmationDialogVisibility(true)}
          onCancel={() => setIsPreview(false)}
        />
      </div>
      <div className={(isPreview ? "d-none" : "") + " bg-dark"}>
        <div className="container">
          <div className="inner-box pt-5">
            {!postId && (
              <>
                {photos.length > 0 || video ? (
                  <></>
                ) : (
                  <div className="post-video py-2 px-3">
                    <FilePicker
                      accept={[
                        "image/jpeg",
                        "image/jpg",
                        "image/png",
                        "image/bmp",
                        "video/*",
                      ]}
                      multiple={true}
                      onChange={handleFilePicker}
                    >
                      <>
                        <i className="fa-solid fa-cloud-arrow-up upload-img cursor-pointer"></i>
                        <p className="upload-img-text cursor-pointer">
                          {t("uploadPhotoIPL")}
                        </p>
                      </>
                    </FilePicker>
                  </div>
                )}

                {video && (
                  <>
                    <div className="col-12">
                      <button
                        className="delete-selected-video"
                        type="button"
                        aria-label="Close"
                        onClick={() => deleteHandlerVideo()}
                      >
                        x
                      </button>
                      <video
                        id="video-container"
                        src={URL.createObjectURL(video)}
                        className="post-videos delete-selected-video-position "
                        controls
                      />
                      <canvas id="thumbnail-container" className="d-none" />
                    </div>
                  </>
                )}
                <div className="row g-1 mb-3">
                  {photos &&
                    photos.map((photo, i) => {
                      return (
                        <>
                          <div className="col-6" key={i}>
                            <div>
                              <button
                                className="delete-selected-photos"
                                type="button"
                                aria-label="Close"
                                onClick={() => deleteHandler(photo)}
                              >
                                x
                              </button>
                              <img
                                src={URL.createObjectURL(photo)}
                                className="image-grid"
                              />
                              {isUploaded[i] && <span>Uploaded</span>}
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>
                <FilePicker
                  accept={["image/*", "video/*"]}
                  multiple={true}
                  onChange={handleFilePicker}
                >
                  <button className="button primary-button me-2 margin-top-5">
                    {t("uploadPhotoIPL")}
                  </button>
                </FilePicker>
              </>
            )}

            {errorMessages && (
              <Modal show={true} onHide={() => setErrorMessages(undefined)}>
                <Modal.Header closeButton />
                <Modal.Body>
                  {}
                  <div className="schedule-post text-center d-block">
                    <h3>{errorMessages}</h3>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div className="row">
                    <div className="col-12 text-center">
                      <button
                        className="bk-btn primary-button button mt-3 w-100"
                        onClick={() => setErrorMessages(undefined)}
                      >
                        OK
                      </button>
                    </div>
                  </div>
                </Modal.Footer>
              </Modal>
            )}
            {/* {errorMessages && <p className="">{errorMessages}</p>} */}
            <div>
              {photoValidated && (
                <>
                  <FormErrorMessage message={photoValidated} />
                  <br />
                </>
              )}
            </div>

            <div className="post-box mt-3">
              <input
                type="text"
                className="form-control"
                placeholder={t("addTitle")}
                id="title"
                onChange={handleTitle}
                value={title}
                maxLength={20}
                required
              />
              <span
                className="pull-right label label-default"
                id="count_message_title"
              >
                {countTitle} / 20
              </span>
              <textarea
                name=""
                id="text"
                cols={30}
                rows={5}
                className="form-control"
                placeholder={t("addText")}
                onChange={handleContent}
                value={content}
                maxLength={140}
                required
              />
              <span
                className="pull-right label label-default"
                id="count_message"
              >
                {" "}
                {countContent} / 140
              </span>
            </div>

            {titleValidated && <FormErrorMessage message={titleValidated} />}

            {contentValidated && (
              <>
                <br />
                <FormErrorMessage message={contentValidated} />
              </>
            )}

            <div className="d-flex justify-content-end my-3">
              <p className="text-end primary-color preview">
                <a href="#" onClick={() => setIsPreview(true)}>
                  {t("preview")}
                </a>
              </p>
              <div className="mx-3" />
              <button
                className="button primary-button"
                onClick={(e) => {
                  // console.log(countVideos);

                  if (
                    postId &&
                    (title?.length ?? 0) > 0 &&
                    (content?.length ?? 0) > 0
                    // ((photos?.length ?? 0) > 0 || countVideos > 0)
                  ) {
                    return handleSubmit();
                  }
                  if (
                    (title?.length ?? 0) > 0 &&
                    (content?.length ?? 0) > 0 &&
                    ((photos?.length ?? 0) > 0 || countVideos > 0)
                  ) {
                    return setConfirmationDialogVisibility(true);
                  } else {
                    if ((title?.length ?? 0) <= 0) {
                      setTitleValidated("Title cannot be empty!");
                    }
                    if ((content?.length ?? 0) <= 0) {
                      setContentValidated("Content cannot be empty!");
                    }
                    if ((photos?.length ?? 0) <= 0 && countVideos <= 0) {
                      // console.log("video length: " + (countVideos ?? 0));
                      // console.log("photos length: " + (photos?.length ?? 0));

                      setPhotoValidated("Photo(s) or Video cannot be empty!");
                    }
                  }
                }}
              >
                {isPublishing ? (
                  <Spinner size="sm" />
                ) : postId ? (
                  t("update")
                ) : (
                  t("publish")
                )}
              </button>
            </div>

            <div className="row">
              <div className="col-6">
                <select
                  value={type}
                  onChange={(e) => setType(e.target.value!)}
                  id="post-select"
                  className="form-select mb-3"
                >
                  <option value="post" hidden>
                    {t("post")}
                  </option>
                  <option value="now">{t("now")}</option>
                  <option value="scheduled">{t("selectDateTime")}</option>
                </select>
              </div>
            </div>
            {type == "scheduled" ? (
              <div className="my-2 row">
                <div className="col-6">
                  <input
                    type="date"
                    value={scheduledDate}
                    onChange={handleScheduledDate}
                    className="form-control bg-1"
                    min={currentDate}
                  />
                </div>
                <div className="col-6">
                  <input
                    type="time"
                    value={scheduledTime}
                    onChange={handleScheduledTime}
                    className="form-control bg-1"
                    pattern="[0-9]{2}:[0-9]{2}:[0-9]{2}"
                    step="1"
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AddNewPost;
