import * as React from "react";
import { useNavigate } from "react-router-dom";

export interface ICustomBackButtonProps {}

export default function CustomBackButton(props: ICustomBackButtonProps) {
	const navigate = useNavigate();
	return (
		<a className='lft-arw' onClick={() => navigate(-1)}>
			<i className='fa-solid fa-chevron-left'></i>
		</a>
	);
}
