import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  OverlayTrigger,
  Popover,
  PopoverBody,
  Spinner,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getOTP, verifyOTP } from "../services/auth";
import FormErrorMessage from "../components/reusable/FormErrorMessage";
import PasswordField from "../components/reusable/PasswordField";
import RegistrationSteps from "../components/registration/navigation/RegistrationSteps";
import CreateUserParams from "../domain/model/create-user-params";
import AppRoutePath from "../constants/AppRoutePath";
import { useTranslation } from "react-i18next";

import { getCheckVerificationMethod, getGuestToken } from "../services/modules";
import CustomHeader from "../components/reusable/Header";
import TopHearder from "../components/reusable/TopHearder";

function Register(): JSX.Element {
  const [selectedPrefix, setSelectedPrefix] = useState("60");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [registerBy, setRegisterBy] = useState("registeredByMobile");
  const [verificationCode, setVerificationCode] = useState("");
  const [telegram, setTelegram] = useState("");
  const [password, setPassword] = useState("");
  const [nickname, setNickname] = useState("");
  const [zhName, setZhName] = useState("");
  const [retypePassword, setRetypePassword] = useState("");
  const [prefixVerifCode, setPrefixVerifCode] = useState("");
  const [isMobileNumberValid, setIsMobileNumberValid] = useState(false);
  const [isEmailAddressValid, setIsEmailAddressValid] = useState(false);
  const [isOtpValid, setIsOtpValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [timer, setTimer] = useState(0);
  const [isTimerStart, setIsTimerStart] = useState(false);
  const [intervalTimer, setIntervalTimer] = useState<NodeJS.Timer>();

  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [activeAccount, setActiveAccount] = useState("master");
  const createUserParams = CreateUserParams.getInstance();
  const [isCodeResent, setIsCodeResent] = useState(false);
  const [isNumberExist, setIsNumberExist] = useState(false);
  const [isEmailExist, setIsEmailExist] = useState(false);
  const [isEnName, setIsEnName] = useState(false);
  const [isZhName, setIsZhName] = useState(false);
  const [isNameModel, setIsNameModel] = useState(false);
  const [isForceToRegister, setIsForceToRegister] = useState(false);

  useEffect(() => {
    getGuestToken().then((res) => {
      if (res != null) {
        localStorage.setItem("tokenGuest", res?.user_token);
      }
    });
  }, []);

  useEffect(() => {
    createUserParams.loadData();
  }, []);

  useEffect(() => {
    if (isTimerStart) {
      setTimer(60);
      const interval = setInterval(() => {
        setTimer((seconds) => {
          return seconds - 1;
        });
      }, 1000);
      setIntervalTimer(interval);
    }
  }, [isTimerStart]);

  useEffect(() => {
    if (timer === 0) {
      clearInterval(intervalTimer);
      setIsTimerStart(false);
    }
  }, [timer, intervalTimer]);

  const handleRegisterBy: React.ChangeEventHandler<HTMLSelectElement> = (
    event
  ) => {
    const value = event.target.value;
    setRegisterBy(value);
    if (value === "registeredByMobile") {
      setEmail("");
    } else {
      setMobileNumber("");
    }
  };

  const handleChangePassword: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const value = event.target.value;
    setPassword(value);
  };

  const handleRetypePasswordChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (event) => {
    setRetypePassword(event.target.value);
    // const isValid = retypePassword === password;
    // event.target.setAttribute("aria-invalid", !isValid);
  };

  const handleResendCode: React.MouseEventHandler<HTMLParagraphElement> = (
    event
  ) => {
    if (!isTimerStart) {
      setIsCodeResent(true);

      if (registerBy === "registeredByMobile") {
        handleGetOtp();
      } else {
        handleEmailGetOtp();
      }
    }
  };
  useEffect(() => {
    if (isCodeResent) {
      // Delay for a certain time (e.g., 2 seconds) before resetting the `isCodeResent` state
      const delay = setTimeout(() => {
        setIsCodeResent(false);
      }, 2000);

      // Clear the timeout if the component unmounts or `isCodeResent` changes before the delay completes
      return () => clearTimeout(delay);
    }
  }, [isCodeResent]);

  const handlePrefixChange: React.ChangeEventHandler<HTMLSelectElement> = (
    event
  ) => {
    setSelectedPrefix(event.target.value);
  };

  const handleGetOtp = async (event?: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    if (isMobileNumberValid && event != null) {
      return;
    }
    if (parseInt(mobileNumber.trim()) > 0) {
      setIsLoading(true);
      await getCheckVerificationMethod({
        mobile: selectedPrefix + mobileNumber,
      }).then(async (res) => {
        if (res.in_use.length === 0) {
          setIsTimerStart(true);
          const prefix = await getOTP({
            country_phone_code: selectedPrefix,
            phone_number: mobileNumber,
          });
          if (prefix != null) {
            setPrefixVerifCode(prefix);
            setIsMobileNumberValid(true);
          }
        } else {
          setIsNumberExist(true);
        }
      });
    }
    setIsLoading(false);
  };

  const handleEmailGetOtp = async (
    event?: React.FormEvent<HTMLFormElement>
  ) => {
    event?.preventDefault();

    if (isEmailValid) {
      setIsLoading(true);

      await getCheckVerificationMethod({
        email: email,
      }).then(async (res) => {
        if (res.in_use.length === 0) {
          setIsTimerStart(true);
          const prefix = await getOTP({ email: email });
          if (prefix != null) {
            setPrefixVerifCode(prefix);
            // setIsMobileNumberValid(true);
            setIsEmailAddressValid(true);
          }
        } else {
          setIsEmailExist(true);
        }
      });
    }
    setIsLoading(false);
  };

  const handleVerifyOtp: React.MouseEventHandler<HTMLFormElement> = async (
    event
  ) => {
    event.preventDefault();
    if (verificationCode.trim() && !isOtpValid) {
      setIsLoading(true);
      let verifKey = null;
      if (registerBy === "registeredByMobile") {
        verifKey = await verifyOTP({
          country_phone_code: selectedPrefix,
          phone_number: mobileNumber,
          verification_code: `${prefixVerifCode}-${verificationCode}`,
        });
      } else {
        verifKey = await verifyOTP({
          email: email,
          verification_code: `${prefixVerifCode}-${verificationCode}`,
        });
      }
      if (verifKey != null) {
        localStorage.setItem("vkey", verifKey);

        const createUserParams = CreateUserParams.getInstance();
        createUserParams.data.verification_key = verifKey;
        createUserParams.saveData();
        setIsOtpValid(true);
      }
    }
    setIsLoading(false);
  };

  const checkIsRegisterFormValid = () =>
    isMobileNumberValid ||
    (isEmailAddressValid &&
      isOtpValid &&
      password !== "" &&
      password === retypePassword &&
      password.length >= 6 &&
      // telegram !== "" &&
      nickname !== "" &&
      zhName !== "");

  // const handleNext: React.MouseEventHandler<HTMLButtonElement> = async (
  //   event
  // ) => {

  // const handleRegister: React.MouseEventHandler<HTMLButtonElement> = async (
  const handleRegister = async (valid?: any) => {
    // event.preventDefault();

    // navigate("/accountType");
    if (checkIsRegisterFormValid()) {
      const createUserParams = CreateUserParams.getInstance();

      // if (registerBy === "registeredByMobile") {
      createUserParams.data.country_phone_code = selectedPrefix;
      createUserParams.data.phone_number = mobileNumber;
      // } else {
      createUserParams.data.email = email;
      // }

      createUserParams.data.password = password;
      createUserParams.data.en_name = nickname;
      createUserParams.data.zh_name = zhName;
      createUserParams.data.telegram = telegram;
      createUserParams.saveData();

      await getCheckVerificationMethod({
        en_name: nickname,
        zh_name: zhName,
      }).then(async (res) => {
        if (res.in_use.length !== 0) {
          if (res.in_use.includes("en_name")) {
            setIsEnName(true);
          }
          if (res.in_use.includes("zh_name")) {
            setIsZhName(true);
          }
          setIsNameModel(true);
        }
        if (valid || res.in_use.length === 0) {
          navigate(AppRoutePath.accountType);
        }
      });

      // navigate(AppRoutePath.accountType);

      // const res = register({
      // 	mobile: selectedPrefix + mobileNumber,
      // 	password,
      // });
    }
  };

  const formatTimer = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secondsLeft = seconds % 60;
    const formatted = `${minutes.toString().padStart(2, "0")}:${secondsLeft
      .toString()
      .padStart(2, "0")}`;
    return formatted;
  };

  const { t } = useTranslation();

  const handleRedirect = async () => {
    navigate(AppRoutePath.login);
  };
  // const handleCheckIfExist = async () => {
  //   if (isMobileNumberValid) {
  //     if (activeAccount !== "master") {
  //       const createUserParams = CreateUserParams.getInstance();
  //       const isRegisterSeccess = await registerClient(
  //         createUserParams.data.verification_key,
  //         createUserParams.data.password,
  //         createUserParams.data.country_phone_code,
  //         createUserParams.data.phone_number,
  //         createUserParams.data.user_type
  //       );
  //       if (isRegisterSeccess) {
  //         navigate(AppRoutePath.loadCoinEmpty);
  //       }
  //     } else {
  //       const isRegisterSeccess = await registerMaster(
  //         createUserParams.data.verification_key,
  //         createUserParams.data.password,
  //         createUserParams.data.country_phone_code,
  //         createUserParams.data.phone_number,
  //         createUserParams.data.user_type
  //       );
  //       // if (isRegisterSeccess) {
  //       //   navigate(AppRoutePath.loadCoinEmpty);
  //       // }
  //     }
  //   }
  // };

  return (
    <>
      <div>
        <CustomHeader publics={true} />
        <TopHearder title={t("registration")} isBackRoute={true} />
        <div className="bg-dark">
          <div className="container">
            <div className="inner-box">
              {/* <div>
            <div className="progress">
                <div className="progress-bar" role="progressbar" style={{ width: `${activeStep * 100 / 2}%` }} aria-valuenow={activeStep} aria-valuemin="0" aria-valuemax="2"></div>
            </div>
            <div className="d-flex justify-content-between">
                <button type="button" className={`btn ${activeStep >= 0 ? 'btn-success' : 'btn-secondary'} step-button`} onClick={() => handleStepClick(0)}>Step 1</button>
                <button type="button" className={`btn ${activeStep >= 1 ? 'btn-success' : 'btn-secondary'} step-button`} onClick={() => handleStepClick(1)}>Step 2</button>
                <button type="button" className={`btn ${activeStep >= 2 ? 'btn-success' : 'btn-secondary'} step-button`} onClick={() => handleStepClick(2)}>Step 3</button>
            </div>
          </div> */}

              <div className="accordion pt-3" id="accordionExample">
                <RegistrationSteps step={0} />

                <div className="tab-content">
                  <div id="headingOne"></div>

                  <div
                    id="collapseOne"
                    className="collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="row my-4 align-items-center ">
                      <div className="col-12 d-flex justify-content-between align-items-center">
                        <label className="label me-2">{t("registerBy")}</label>
                        <select
                          className="form-select w-auto px-5"
                          onChange={handleRegisterBy}
                          // onKeyDown={handleKeyDown}
                          disabled={isMobileNumberValid || isEmailAddressValid}
                        >
                          <option value="registeredByMobile">
                            {t("mobileNumber")}
                          </option>
                          <option value="registeredByEmail">
                            {t("emailAddress")}
                          </option>
                        </select>
                      </div>
                    </div>
                    {registerBy === "registeredByMobile" ? (
                      <form onSubmit={(e) => handleGetOtp(e)}>
                        <div className="row align-items-center g-2">
                          <label className="label mb-1">
                            {" "}
                            {t("yourMobileNo")}{" "}
                            <OverlayTrigger
                              placement="top"
                              trigger="click"
                              overlay={
                                <Popover>
                                  <PopoverBody>
                                    Phone number use for Registration and AD
                                    cannot be the same.
                                  </PopoverBody>
                                </Popover>
                              }
                            >
                              <button type="button" className="exc-btn mx-2">
                                <i className="fa-solid fa-exclamation"></i>
                              </button>
                            </OverlayTrigger>
                          </label>
                          <div className="col-9">
                            <div className="input-group fss-25">
                              <select
                                className="form-select "
                                defaultValue={selectedPrefix}
                                value={selectedPrefix}
                                onChange={handlePrefixChange}
                                disabled={isMobileNumberValid}
                              >
                                <option value={"852"}>+852</option>
                                <option value={"853"}>+853</option>
                                <option value={"91"}>+91</option>
                                <option value={"60"}>+60</option>
                                {/* TODO: TES*/}
                                <option value={"62"}>+62</option>
                                <option value={"65"}>+65</option>
                              </select>

                              <input
                                className="form-control input addon-input-r"
                                type="tel"
                                pattern="[0-9]+"
                                style={{ color: "white" }}
                                value={mobileNumber}
                                disabled={isMobileNumberValid}
                                maxLength={12}
                                onChange={(e) =>
                                  setMobileNumber(
                                    e.target.value.replace(/[^\d]/g, "")
                                  )
                                }
                              />
                              {isMobileNumberValid && (
                                <div className="input-group-addon-r">
                                  <i className="fa fa-check"></i>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-3 text-center">
                            {!isCodeResent &&
                              !isOtpValid &&
                              !isMobileNumberValid && (
                                <button
                                  className={
                                    parseInt(mobileNumber.trim()) > 0 &&
                                    !isMobileNumberValid
                                      ? isLoading
                                        ? "primary-button button btn btn-primary px-3 py-1 disabled"
                                        : "primary-button button btn btn-primary px-3 py-1"
                                      : "light-button button w-100 btn btn-primary"
                                  }
                                >
                                  {isLoading ? (
                                    <Spinner
                                      animation="border"
                                      variant="light"
                                      size="sm"
                                    />
                                  ) : (
                                    t("getCode")
                                  )}
                                </button>
                              )}
                            {isMobileNumberValid && !isOtpValid && (
                              <div>
                                <p
                                  className="light-text"
                                  style={{
                                    color: isTimerStart ? "#6D6E71" : "#F5405F",
                                    cursor: "pointer",
                                  }}
                                  onClick={handleResendCode}
                                >
                                  {t("resendCode")}
                                </p>
                                {isTimerStart && (
                                  <p
                                    className="light-text"
                                    style={{ color: "#F5405F" }}
                                  >
                                    {formatTimer(timer)}
                                  </p>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </form>
                    ) : (
                      <form onSubmit={(e) => handleEmailGetOtp(e)}>
                        <div className="row align-items-center g-2">
                          <label className="label mb-1">
                            {" "}
                            {t("yourEmailAddress")}{" "}
                            <OverlayTrigger
                              placement="top"
                              trigger="click"
                              overlay={
                                <Popover>
                                  <PopoverBody>{t("emailReInfo")}</PopoverBody>
                                </Popover>
                              }
                            >
                              <button type="button" className="exc-btn mx-2">
                                <i className="fa-solid fa-exclamation"></i>
                              </button>
                            </OverlayTrigger>
                          </label>
                          <div className="col-9">
                            <div className="input-group fss-25">
                              <input
                                className="form-control input addon-input-r"
                                type="email"
                                // pattern="[0-9]+"
                                style={{ color: "white" }}
                                value={email}
                                disabled={isEmailAddressValid}
                                onChange={(e) => {
                                  const inputEmail = e.target.value;
                                  setEmail(inputEmail);

                                  // Regular expression for basic email validation
                                  const emailRegex =
                                    /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                  const isValidEmail =
                                    emailRegex.test(inputEmail);

                                  setIsEmailValid(isValidEmail);
                                  // onChange(isValidEmail);
                                }}
                                maxLength={50}
                              />
                              {(isMobileNumberValid || isEmailAddressValid) && (
                                <div className="input-group-addon-r">
                                  <i className="fa fa-check"></i>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-3 text-center">
                            {!isCodeResent &&
                              !isOtpValid &&
                              !isEmailAddressValid && (
                                <button
                                  className={
                                    isEmailValid && !isEmailAddressValid
                                      ? isLoading
                                        ? "primary-button button btn btn-primary px-3 py-1 disabled"
                                        : "primary-button button btn btn-primary px-3 py-1"
                                      : "light-button button w-100 btn btn-primary"
                                  }
                                >
                                  {isLoading ? (
                                    <Spinner
                                      animation="border"
                                      variant="light"
                                      size="sm"
                                    />
                                  ) : (
                                    t("getCode")
                                  )}
                                </button>
                              )}
                            {(isMobileNumberValid || isEmailAddressValid) &&
                              !isOtpValid && (
                                <div>
                                  <p
                                    className="light-text"
                                    style={{
                                      color: isTimerStart
                                        ? "#6D6E71"
                                        : "#F5405F",
                                      cursor: "pointer",
                                    }}
                                    onClick={handleResendCode}
                                  >
                                    {t("resendCode")}
                                  </p>
                                  {isTimerStart && (
                                    <p
                                      className="light-text"
                                      style={{ color: "#F5405F" }}
                                    >
                                      {formatTimer(timer)}
                                    </p>
                                  )}
                                </div>
                              )}
                          </div>
                        </div>
                      </form>
                    )}
                    <br />
                    <br />{" "}
                    {(isMobileNumberValid || isEmailAddressValid) && (
                      <form onSubmit={handleVerifyOtp}>
                        <div className="row align-items-end g-2 mb-5">
                          <label className="label mb-1">
                            {t("verificationCode")}{" "}
                          </label>
                          <div className="col-9">
                            <div className="input-group">
                              <input
                                className="form-control"
                                type="text"
                                style={{ color: "white", flex: 0.25 }}
                                value={prefixVerifCode}
                                disabled={isOtpValid || prefixVerifCode !== ""}
                                onChange={(e) =>
                                  setPrefixVerifCode(
                                    e.target.value.replace(/[^a-zA-Z]/g, "")
                                  )
                                }
                              />
                              <input
                                className="form-control input addon-input-r"
                                type="tel"
                                style={{ color: "white" }}
                                value={verificationCode}
                                disabled={isOtpValid}
                                onChange={(e) =>
                                  setVerificationCode(
                                    e.target.value.replace(/[^\d]/g, "")
                                  )
                                }
                              />
                              {isOtpValid && (
                                <div className="input-group-addon-r">
                                  <i className="fa fa-check"></i>
                                </div>
                              )}
                            </div>
                          </div>
                          {!isOtpValid && (
                            <div className="col-3 text-center">
                              <button
                                className={
                                  prefixVerifCode.length > 0 &&
                                  verificationCode.trim().length > 0 &&
                                  !isOtpValid
                                    ? "primary-button button btn-primary px-3 py-1"
                                    : "light-button3 button px-3 py-1"
                                }
                                disabled={isLoading}
                              >
                                {isLoading ? (
                                  <Spinner
                                    animation="border"
                                    variant="light"
                                    size="sm"
                                  />
                                ) : (
                                  t("submit")
                                )}
                              </button>
                            </div>
                          )}
                        </div>
                      </form>
                    )}
                    {isOtpValid && (
                      <div>
                        <div className="mb-2">
                          <label className="label mb-1">
                            {t("englishName")}
                          </label>

                          <input
                            className="form-control input"
                            type="text"
                            style={{ color: "white", width: 366 }}
                            value={nickname}
                            maxLength={15}
                            onChange={(e) => setNickname(e.target.value)}
                            required
                          />
                        </div>

                        <div className="mb-3">
                          <label className="label mb-1">
                            {t("chineseName")}
                          </label>

                          <input
                            className="form-control input"
                            type="text"
                            style={{ color: "white", width: 366 }}
                            value={zhName}
                            maxLength={15}
                            onChange={(e) => setZhName(e.target.value)}
                            required
                          />
                        </div>

                        <PasswordField
                          passwordValue={password}
                          passwordOnChange={handleChangePassword}
                        />
                        {password.length < 6 && password != "" && (
                          <FormErrorMessage
                            message={t("registerPwdValidation")}
                          />
                        )}
                        <PasswordField
                          label={t("retypePwd")}
                          passwordValue={retypePassword}
                          passwordOnChange={handleRetypePasswordChange}
                        />
                        {password !== retypePassword &&
                          retypePassword !== "" && (
                            <>
                              <FormErrorMessage
                                message={t("passwordMismatch")}
                              />
                              <br />
                            </>
                          )}
                        {/* <label className="label mb-1">{t("telegramIDno")}</label>
                      <br />
                      <input
                        className="form-control input"
                        type="text"
                        style={{ color: "white", width: 366 }}
                        value={telegram}
                        onChange={(e) => setTelegram(e.target.value)}
                        required
                      /> */}
                      </div>
                    )}
                    <div>
                      <p className="note mt-5">
                        {t("contactCStg")} <br /> {t("contactCStg2")}
                      </p>
                    </div>
                    <div className="row">
                      <div className="col-6 text-center">
                        <Button
                          variant="primary"
                          onClick={() => setShowModal(true)}
                          className="primary-button button mt-3 w-100"
                        >
                          {t("cancel")}
                        </Button>
                      </div>
                      <Modal
                        show={showModal}
                        onHide={() => setShowModal(false)}
                      >
                        <Modal.Body>
                          <div className="d-flex justify-content-center align-items-center">
                            <Modal.Title>
                              <h5 className="modal-title text-center">
                                {t("cancelRegisterAcc")}
                              </h5>
                              <div className="d-flex justify-content-between w-100 mt-3">
                                {/* <Link to="/"> */}
                                <Button
                                  className="secondary-button button mt-3 w-100 mx-2"
                                  onClick={handleRedirect}
                                >
                                  {t("yes")}
                                </Button>
                                {/* </Link> */}
                                <Button
                                  variant="primary"
                                  className="primary-button button mt-3 w-100 mx-2"
                                  onClick={() => setShowModal(false)}
                                >
                                  {t("no")}
                                </Button>
                              </div>
                            </Modal.Title>
                          </div>
                        </Modal.Body>
                        <Modal.Footer></Modal.Footer>
                      </Modal>

                      <Modal
                        show={isNumberExist}
                        onHide={() => setIsNumberExist(false)}
                      >
                        <Modal.Body>
                          <div className="d-flex justify-content-center align-items-center">
                            <Modal.Title>
                              <h5 className="modal-title text-center">
                                {t("registeredNumber")}
                              </h5>
                              <div className="d-flex justify-content-between w-100 mt-3">
                                {/* <Link to="/"> */}
                                <Button
                                  className="secondary-button button mt-3 w-100 mx-2"
                                  onClick={() => setIsNumberExist(false)}
                                >
                                  {t("ok")}
                                </Button>
                                {/* </Link> */}
                                <Button
                                  variant="primary"
                                  className="primary-button button mt-3 w-100 mx-2"
                                  onClick={handleRedirect}
                                >
                                  {t("login")}
                                </Button>
                              </div>
                            </Modal.Title>
                          </div>
                        </Modal.Body>
                        <Modal.Footer></Modal.Footer>
                      </Modal>
                      <Modal
                        show={isEmailExist}
                        onHide={() => setIsEmailExist(false)}
                      >
                        <Modal.Body>
                          <div className="d-flex justify-content-center align-items-center">
                            <Modal.Title>
                              <h5 className="modal-title text-center">
                                {t("alreadyRegEmail")}
                              </h5>
                              <div className="d-flex justify-content-between w-100 mt-3">
                                {/* <Link to="/"> */}
                                <Button
                                  className="secondary-button button mt-3 w-100 mx-2"
                                  onClick={() => setIsEmailExist(false)}
                                >
                                  {t("ok")}
                                </Button>
                                {/* </Link> */}
                                <Button
                                  variant="primary"
                                  className="primary-button button mt-3 w-100 mx-2"
                                  onClick={handleRedirect}
                                >
                                  {t("login")}
                                </Button>
                              </div>
                            </Modal.Title>
                          </div>
                        </Modal.Body>
                        <Modal.Footer></Modal.Footer>
                      </Modal>

                      <Modal
                        show={isNameModel}
                        onHide={() => setIsNameModel(false)}
                      >
                        <Modal.Body>
                          <div className="d-flex justify-content-center align-items-center">
                            <Modal.Title>
                              <h5 className="modal-title text-center">
                                {isEnName && !isZhName ? t("englishName") : " "}
                                {isZhName && !isEnName ? t("chineseName") : " "}
                                {isEnName && isZhName
                                  ? `${t("englishName")} and ${t(
                                      "chineseName"
                                    )} `
                                  : " "}
                                {/* {t("englishName")} {t("chineseName")} */}
                                {t("registeredTaken")}
                                {t("registeredConfirmed")}
                              </h5>
                              <div className="d-flex justify-content-between w-100 mt-3">
                                {/* <Link to="/"> */}
                                <Button
                                  className="secondary-button button mt-3 w-100 mx-2"
                                  onClick={() => setIsNameModel(false)}
                                >
                                  {t("no")}
                                </Button>
                                {/* </Link> */}
                                <Button
                                  variant="primary"
                                  className="primary-button button mt-3 w-100 mx-2"
                                  onClick={() => {
                                    // setIsForceToRegister(true);
                                    handleRegister(true);
                                  }}
                                >
                                  {t("yes")}
                                </Button>
                              </div>
                            </Modal.Title>
                          </div>
                        </Modal.Body>
                        <Modal.Footer></Modal.Footer>
                      </Modal>

                      <div className="col-6 text-center">
                        {/* <Link to="/accountType"> */}
                        <Button
                          variant="primary"
                          className={
                            checkIsRegisterFormValid()
                              ? "primary-button button mt-3 w-100"
                              : "primary-button button light-button mt-3 w-100"
                          }
                          onClick={() => handleRegister()}
                          disabled={!checkIsRegisterFormValid()}
                        >
                          {t("next")}
                        </Button>
                        {/* </Link> */}
                      </div>
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Register;
