export enum WalletTransactionType {
    Ad = 'ad',
    Reload = 'reload',
}

export const getWalletTransactionTypeByString = (str: string) => {
    switch (str) {
        case "ad":
            return WalletTransactionType.Ad;
        case "reload":
            return WalletTransactionType.Reload
        default:
            return WalletTransactionType.Ad;
    }
}