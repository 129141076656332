import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "../index.css";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../services/auth";
import {
  findCoinBalance,
  findCoinBalanceISEmpty,
} from "../services/products/one-coin";
import { getClientProfile } from "../services//client/client-profile";
import FormErrorMessage from "../components/reusable/FormErrorMessage";
import Snackbar from "awesome-snackbar";
import AppRoutePath from "../constants/AppRoutePath";
import { ClientProfileModel } from "../domain/model/client-profile-model";

import { useTranslation } from "react-i18next";

import TopHearder from "../components/reusable/TopHearder";
import CustomHeader from "../components/reusable/Header";
import { getAllTypeFeature } from "../services/modules";
import ScreenLoaders from "../components/reusable/ScreenLoader";
import { checkMasterHasShop, getShop } from "../services/master/master-shop";
import ActionLoader from "../components/reusable/ActionLoader";

function LoginPage(): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [selectedPrefix, setSelectedPrefix] = useState("852");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingScreen, setIsLoadingScreen] = useState(true);
  const [ext, setExt] = useState("");
  const [role, setRole] = useState(1);
  const [clientProfile, setClientProfile] =
    React.useState<ClientProfileModel | null>(null);
  // Validation states
  const [phoneValidated, setPhoneValidated] = useState<string>();
  const [passwordValidated, setPasswordValidated] = useState<string>();
  const [extValidated, setExtValidated] = useState<string>();
  const [showPassword, setShowPassword] = useState(false);
  const [loginBy, setLoginBy] = useState("loginByMobile");
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      setIsLoadingScreen(false);
    }, 500);
  }, []);
  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLoginBy: React.ChangeEventHandler<HTMLSelectElement> = (
    event
  ) => {
    const value = event.target.value;
    setLoginBy(value);

    if (value === "loginByMobile") {
      setEmail("");
    } else {
      setPhone("");
    }
  };

  const handleChangeRole: React.ChangeEventHandler<HTMLSelectElement> = (
    event
  ) => {
    setExt("");
    setRole(parseInt(event.target.value));
  };

  const handlePrefixChange: React.ChangeEventHandler<HTMLSelectElement> = (
    event
  ) => {
    setSelectedPrefix(event.target.value);
  };

  const handlePassword: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setPassword(event.target.value);
  };
  const handlePhone: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setPhone(event.target.value);
  };

  const handleExt: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setExt(event.target.value);
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (isLoading) return;

    // Validations
    setPhoneValidated(phone.length < 1 ? "Invalid phone number" : "");
    setPasswordValidated(password.length < 1 ? "Invalid password" : "");
    // End of validations

    setIsLoading(true);

    if (password && (phone || email)) {
      try {
        let fullMobileNumber = `${phone}`;
        let fullEmailAddress = `${email}`;
        if (ext) {
          if (phone) {
            fullMobileNumber += `EXT${ext}`;
          } else {
            fullEmailAddress += `EXT${ext}`;
          }

          localStorage.setItem("EXT", String(true));
        } else {
          fullMobileNumber = `${phone}`;
          fullEmailAddress = `${email}`;
          localStorage.setItem("EXT", String(false));
        }

        // console.log(
        //   "Testing ",
        //   selectedPrefix,
        //   fullMobileNumber,
        //   password,
        //   role,
        //   fullEmailAddress
        // );

        const isSuccess = await login(
          selectedPrefix,
          fullMobileNumber,
          password,
          role,
          fullEmailAddress
        );

        // console.log("isSuccess ", isSuccess);

        let masterRedirect: any;

        let checkOneCoinIsReload: any;
        let checkOneCoinIsReloadMessage;
        let checkAddDetailsIsCompleted;
        let checkMasterhasShops;
        if (role == 1) {
          // console.log("Gg ", fullEmailAddress);

          checkAddDetailsIsCompleted = await getClientProfile();
          if (fullEmailAddress === "") {
            checkOneCoinIsReload = await findCoinBalanceISEmpty();

            checkOneCoinIsReloadMessage = checkOneCoinIsReload.message;
          } else {
            checkOneCoinIsReloadMessage = "";
          }
        } else {
          checkMasterhasShops = await checkMasterHasShop();
          checkOneCoinIsReload = await findCoinBalanceISEmpty();
        }

        // console.log("checkMasterhasShops", checkMasterhasShops);

        if (
          isSuccess &&
          checkOneCoinIsReloadMessage !== "Top Up Required" &&
          checkAddDetailsIsCompleted?.details?.basic_desc?.phone
        ) {
          navigate(
            role == 1 ? AppRoutePath.clientMainPage : AppRoutePath.masterAccount
          );
        } else if (
          isSuccess &&
          checkOneCoinIsReloadMessage === "Top Up Required" &&
          !checkAddDetailsIsCompleted?.details?.basic_desc?.phone
        ) {
          if (
            checkMasterhasShops &&
            checkMasterhasShops === "Record Not Found"
          ) {
            navigate(AppRoutePath.masterCreateShop);
          } else {
            navigate(AppRoutePath.loadCoinEmpty);
          }
        } else if (
          isSuccess &&
          !checkAddDetailsIsCompleted?.details?.basic_desc?.phone
        ) {
          if (role == 2) {
            getAllTypeFeature().then((res) => {
              localStorage.setItem("typeFeaturesArray", JSON.stringify(res));
            });
          }
          navigate(
            role == 1
              ? AppRoutePath.clientAddDetails
              : AppRoutePath.masterAccount
          );
        } else if (isSuccess && ext) {
          navigate(AppRoutePath.clientMainPage);
        }
      } catch (e: any) {
        if (e.error.reason === "Account is not existed") {
          new Snackbar("Account is not existed", {
            position: "bottom-center",
          });
        }
        if (e.error.result_code === 913) {
          new Snackbar("This Account is disabled ", {
            position: "bottom-center",
          });
        }
        if (e.error.reason === "Wrong 'password'") {
          new Snackbar("Invalid phone number or password", {
            position: "bottom-center",
          });
        }
      }
    }

    setIsLoading(false);
  };

  return (
    <>
      {" "}
      {isLoadingScreen ? (
        <ScreenLoaders />
      ) : (
        <>
          <div>
            <CustomHeader publics={true} title={t("login")} />
            <TopHearder title={t("login")} isBackRoute={true} />

            <div className="bg-dark">
              <div className="container">
                <div className="inner-box">
                  <br />
                  {/* <br /> */}
                  <div className="row my-4 align-items-center ">
                    <div className="col-12 d-flex justify-content-between align-items-center">
                      <label className="label me-2">{t("loginBy")}</label>
                      <select
                        className="form-select w-auto px-5"
                        onChange={handleLoginBy}
                        // onKeyDown={handleKeyDown}
                        // disabled={isMobileNumberValid || isEmailAddressValid}
                      >
                        <option value="loginByMobile">
                          {t("mobileNumber")}
                        </option>
                        <option value="loginByEmail">
                          {t("emailAddress")}
                        </option>
                      </select>
                    </div>
                  </div>

                  <form onSubmit={handleSubmit}>
                    {loginBy === "loginByMobile" ? (
                      <>
                        <label className="label mb-1">{t("enterMobile")}</label>

                        <div className="input-group mb-3">
                          <select
                            className="form-select fs-25"
                            value={selectedPrefix}
                            onChange={handlePrefixChange}
                          >
                            <option value={"852"}>+852</option>
                            <option value={"853"}>+853</option>
                            <option value={"91"}>+91</option>
                            <option value={"60"}>+60</option>
                            {/* TODO: TES*/}
                            <option value={"62"}>+62</option>
                            <option value={"65"}>+65</option>
                          </select>

                          <input
                            className="form-control input addon-input-r street-no"
                            type="text"
                            style={{ color: "white" }}
                            value={phone}
                            onChange={handlePhone}
                            maxLength={12}
                            onInput={(e: any) => {
                              e.preventDefault();
                              const input = e.target.value;
                              const regex = /^[0-9]*$/;
                              if (!regex.test(input)) {
                                e.target.value = input.slice(0, -1);
                              }
                            }}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <label className="label mb-1">
                          {" "}
                          {t("yourEmailAddress")}{" "}
                        </label>
                        <div className="col-12">
                          <div className="input-group fss-25">
                            <input
                              className="form-control input addon-input-r"
                              type="email"
                              // pattern="[0-9]+"
                              style={{ color: "white" }}
                              value={email}
                              // disabled={isEmailAddressValid}
                              onChange={(e) => {
                                const inputEmail = e.target.value;
                                setEmail(inputEmail);
                                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                const isValidEmail =
                                  emailRegex.test(inputEmail);
                                setIsEmailValid(isValidEmail);
                                // onChange(isValidEmail);
                              }}
                              maxLength={50}
                            />
                          </div>
                        </div>
                      </>
                    )}

                    {loginBy === "loginByMobile" ? (
                      <FormErrorMessage message={phoneValidated} />
                    ) : (
                      <>
                        {isEmailValid ? (
                          ""
                        ) : (
                          <>
                            {email.length > 0 ? (
                              <FormErrorMessage message={t("invalidEmail")} />
                            ) : (
                              ""
                            )}
                          </>
                        )}
                      </>
                    )}

                    <div className="row my-4 align-items-center ">
                      <div className="col-7 d-flex justify-content-start align-items-center">
                        <label className="label me-2">{t("accountType")}</label>
                        <select
                          className="form-select w-auto"
                          onChange={handleChangeRole}
                          // onKeyDown={handleKeyDown}
                        >
                          <option value={1}>{t("client")}</option>
                          <option value={2}>{t("master")}</option>
                        </select>
                      </div>
                      <div className="col-5 text-align-end align-items-center">
                        {role == 1 ? (
                          <>
                            <div className="d-flex justify-content-end align-items-center">
                              <label className="label me-2">{t("ext")}</label>
                              <input
                                className="form-control street-no"
                                type="text"
                                placeholder="1"
                                onChange={handleExt}
                                // onKeyDown={handleKeyDown}
                                onInput={(e: any) => {
                                  e.preventDefault();
                                  const input = e.target.value;
                                  const regex = /^[0-9]*$/;
                                  if (!regex.test(input)) {
                                    e.target.value = input.slice(0, -1);
                                  }
                                }}
                              />
                            </div>
                            <FormErrorMessage message={extValidated} />
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>

                    <div className="input-group mb-3">
                      <div className="input-group-addon addon-grp">
                        <i className="fa fa-lock"></i>
                      </div>
                      <input
                        className="form-control form-control-bs input addon-input border-end-0"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        maxLength={30}
                        style={{ color: "white" }}
                        value={password}
                        onChange={handlePassword}
                      />
                      <div className="input-group-addon eye-1  border-start-0 d-flex align-items-center">
                        <i
                          className={`fa fa-eye  ${
                            showPassword ? "primary-color1 " : ""
                          }`}
                          style={{ cursor: "pointer" }}
                          onClick={handlePasswordVisibility}
                        ></i>
                      </div>
                    </div>

                    <FormErrorMessage message={passwordValidated} />

                    <div className="row mt-2 mb-5">
                      {/* <div className="col-6 ">
                    <div className="checkbox">
                      <label>
                        <input type="checkbox" value="" />
                        <p className="link-sm  mb-0">{t('remember')}</p>
                      </label>
                    </div>
                  </div> */}
                      <div className="col-12">
                        <div className="d-flex align-items-center">
                          <Link
                            to={AppRoutePath.forgetPassword}
                            className=" link-sm "
                          >
                            {t("forgetPassword")} ?
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        {/* <Link href="#"> */}
                        <Button
                          className="primary-button button w-100"
                          type="submit"
                          disabled={isLoading}
                        >
                          {isLoading ? (
                            <Spinner size="sm" />
                          ) : (
                            <span>{t("login")}</span>
                          )}
                        </Button>
                        {/* </Link> */}
                      </div>
                      <div className="col-12 text-center">
                        <Link to={AppRoutePath.register}>
                          <Button
                            className="secondary-button button mt-3"
                            disabled={isLoading}
                          >
                            {t("newRegistration")}
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </form>
                  {isLoading && <ActionLoader />}
                </div>
              </div>
            </div>
          </div>{" "}
        </>
      )}
    </>
  );
}

export default LoginPage;
