import * as React from "react";
import CustomHeader from "../reusable/Header";
import HistoryModel from "../../domain/model/history-model";
import {
  getAdsHistoryClient,
  getBlogHistoryClient,
} from "../../services/client/client-history";
import { useParams } from "react-router-dom";
import { CloseButton, Modal } from "react-bootstrap";
import Datepicker from "../reusable/DatePicker/DatePicker";
import Snackbar from "awesome-snackbar";
import { useTranslation, initReactI18next } from "react-i18next";

import TopHearder from "../reusable/TopHearder";
export interface IHistoryPageProps {}

export default function HistoryPage(props: IHistoryPageProps) {
  const isMaster = window.location.pathname.includes("master");
  const { t } = useTranslation();

  var type = useParams().type;
  const id = useParams().id;

  const [historyTemplate, setHistoryTemplate] =
    React.useState<IHistoryTemplate>();
  const [data, setData] = React.useState<HistoryModel[]>([]);
  const [startDateTemp, setStartDateTemp] = React.useState<Date>();
  const [endDateTemp, setEndDateTemp] = React.useState<Date>();
  const [startDate, setStartDate] = React.useState<Date>();
  const [endDate, setEndDate] = React.useState<Date>();
  const advertisementHistoryTemplate = new AdvertisementHistoryTemplate();
  advertisementHistoryTemplate.name = t("ads");
  const [isChecked, setIsChecked] = React.useState(false);
  const [isDatePickerModalOpen, setIsDatePickerModalOPen] =
    React.useState(false);

  React.useEffect(() => {}, []);

  React.useEffect(() => {
    const template = getHistoryTemplateFromString(type ?? "blog");
    if (isMaster) {
      template.id = Number(id!);
    }
    setHistoryTemplate(template);
  }, [type, id, isMaster]);

  React.useEffect(() => {
    historyTemplate
      ?.getHistory(
        startDate ? startDate?.toLocaleDateString("en-CA") : undefined,
        endDate ? endDate?.toLocaleDateString("en-CA") : undefined
      )
      .then((res) => {
        if (res) {
          setData(res);
        }
      });
  }, [historyTemplate, startDate, endDate]);
  const [sortOrder, setSortOrder] = React.useState<"asc" | "desc">("desc");
  const chevronIcon =
    sortOrder === "asc" ? "fa-solid fa-chevron-down" : "fa-solid fa-chevron-up";
  const sortButtonText = sortOrder === "asc" ? "Oldest" : "Latest";
  const handleSortToggle = () => {
    setSortOrder((prevSortOrder) => (prevSortOrder === "asc" ? "desc" : "asc"));
  };
  return (
    <>
      <CustomHeader />
      {/* <CustomHeader title={`${historyTemplate?.name} History`} /> */}
      <TopHearder title={`${historyTemplate?.name} History`} />
      <div className="bg-dark">
        <div className="container">
          <div className="inner-box pt-4 pb-4">
            <div>
              <label htmlFor="" className="label mb-4">
                {t("searchHistory")}
              </label>
            </div>
            <div style={{ alignItems: "center" }}>
              <div className="row">
                <div className="col-8">
                  <form
                    className="date-box mb-5"
                    onClick={() => {
                      setStartDateTemp(startDate ?? new Date());
                      setEndDateTemp(endDate ?? new Date());
                      setIsDatePickerModalOPen(true);
                    }}
                  >
                    <div className="input-group date" id="datepicker">
                      <span className="input-group-append">
                        <span className="input-group-text d-block">
                          <i className="fa fa-calendar" />
                        </span>
                      </span>
                      <input
                        readOnly={true}
                        type="text"
                        className="form-control link"
                        id="date"
                        value={
                          startDate && endDate
                            ? `${startDate?.toLocaleDateString(
                                "en-GB"
                              )}-${endDate?.toLocaleDateString("en-GB")}`
                            : ""
                        }
                        // style={{ width: 200 }}
                      />
                    </div>
                  </form>
                </div>
                <div className="col-4 text-end">
                  <button
                    className="btn primary-button rounded-button"
                    onClick={() => {
                      setStartDate(undefined);
                      setEndDate(undefined);
                    }}
                    style={{ color: "white" }}
                  >
                    Clear Filter
                  </button>
                </div>
              </div>
            </div>
            <div>
              {/* <label htmlFor="" className="label mb-4">
                {t('latest')}
              </label> */}
              <a
                href="#"
                className="label color-pink"
                onClick={handleSortToggle}
              >
                <span className="pad-right-5">{sortButtonText}</span>
                <i className={`${chevronIcon}`}></i>
              </a>
            </div>
            <div className="table-wrap">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">{t("dateTime")}</th>
                    {/* <th scope="col">To</th> */}
                    <th scope="col">{t("status")}</th>
                  </tr>
                </thead>
                <tbody>
                  {data
                    .slice()
                    .sort((a, b) => {
                      const dateComparison =
                        new Date(b.updated).getTime() -
                        new Date(a.updated).getTime();
                      return sortOrder === "asc"
                        ? -dateComparison
                        : dateComparison;
                    })
                    .map((d, i) => (
                      <tr key={i}>
                        <td>
                          {d.updated.split(" ")[0]}{" "}
                          <span>{d.updated.split(" ")[1]}</span>
                        </td>
                        <td>
                          <label className="switch">
                            {/* <input type="checkbox" checked={d.status === 1} onChange={() => { }} /> */}
                            {/* <span className="slider round" style={{ cursor: "default" }}>
                              {t('OFF')} {t('ON')}
                            </span> */}
                            {d.status === 1 ? (
                              <button className="btn primary-button-active rounded-button ">
                                {t("ON")}
                              </button>
                            ) : (
                              <button className="btn primary-button rounded-button ">
                                {t("OFF")}
                              </button>
                            )}
                          </label>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Modal show={isDatePickerModalOpen} className="date-models">
        <CloseButton onClick={() => setIsDatePickerModalOPen(false)} />
        <Modal.Body>
          <p style={{ color: "white" }}>From:</p>
          <Datepicker
            initDate={startDate}
            maxDate={new Date()}
            onCallback={(date) => setStartDateTemp(date)}
          />
          <div
            style={{
              height: 1,
              backgroundColor: "#FF374D",
              margin: "10px 0px",
            }}
          ></div>
          <p style={{ color: "#white" }}>To:</p>
          <Datepicker
            initDate={endDate}
            maxDate={new Date()}
            onCallback={(date) => setEndDateTemp(date)}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => {
              if (startDateTemp! < endDateTemp!) {
                setStartDate(startDateTemp);
                setEndDate(endDateTemp);
                setIsDatePickerModalOPen(false);
              } else {
                new Snackbar("Invalid date selected");
              }
            }}
            className="button primary-button rounded-button create-post-btn"
          >
            Search
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const getHistoryTemplateFromString = (str: string) => {
  switch (str) {
    case "advertisement":
      return new AdvertisementHistoryTemplate();
    case "blog":
      return new BlogHistoryTemplate();
    default:
      return new AdvertisementHistoryTemplate();
  }
};

export interface IHistoryTemplate {
  id?: number;
  name: string;
  getHistory: (
    startDate?: string,
    endDate?: string
  ) => Promise<HistoryModel[] | null>;
}

export class AdvertisementHistoryTemplate implements IHistoryTemplate {
  id?: number | undefined;
  name = "Advertisement";
  getHistory = async (startDate?: string, endDate?: string) => {
    return await getAdsHistoryClient({
      start_at: startDate,
      end_at: endDate,
      target: this.id,
    });
  };
}

export class BlogHistoryTemplate implements IHistoryTemplate {
  id?: number | undefined;
  name = "Blog";
  getHistory = async (startDate?: string, endDate?: string) => {
    return await getBlogHistoryClient({
      start_at: startDate,
      end_at: endDate,
      target: this.id,
    });
  };
}
