/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom";
import {
  blockUnblockFollower,
  followUser,
} from "../../../../services/client/client-follow";
import { WalletTableRow } from "../interface/WalletTableRow";
import AppRoutePath from "../../../../constants/AppRoutePath";
import { sendInvitaion } from "../../../../services/client/client-invitation";
const langType = localStorage.getItem("i18nextLng");
const user_id = localStorage.getItem("user_id");
const isMaster = window.location.pathname.includes("master");

const heartTableRow: WalletTableRow[] = [
  {
    label: "My HEARTs Receiving History",
    div: false,
    colName: [
      "Time",
      "Sender Name",

      "Instant Post ID",
      "Product Name",
      "Following Request",
    ],
    rowDataComp: (data: any, remarksData: any, friendList: any) => {
      return (
        <tr>
          <td className="text-start">
            <span className="d-block ">
              {" "}
              {data.sent_time && data.sent_time.split(" ")[0]}
            </span>
            <small className="d-block time-col ">
              {" "}
              {data.sent_time && data.sent_time.split(" ")[1]}
            </small>
          </td>
          <td>
            <span>{data?.sender ? data?.sender : "N/A"}</span>
            {langType === "zh" ? data.sender_zh_name : data.sender_en_name}
          </td>
          <td>
            {isMaster ? (
              <a className="ig-btn">
                <img
                  style={{ width: 22, marginBottom: 10 }}
                  src="/img/instant-post-logo-white.png"
                />
              </a>
            ) : (
              <Link
                to={`/client/my-instant-posts/${
                  data?.to_post ? data?.to_post : "N/A"
                }`}
              >
                <a className="ig-btn">
                  <img
                    style={{ width: 22, marginBottom: 10 }}
                    src="/img/instant-post-logo-white.png"
                  />
                </a>
              </Link>
            )}
          </td>
          <td>
            {langType === "zh"
              ? data.product?.split("|")[1]
              : data.product?.split("|")[0]}
          </td>
          <td>
            {data?.sender === Number(user_id) ? (
              <button className="request-btn f-btn">My Account</button>
            ) : (
              <div>
                {friendList.includes(data?.sender) ? (
                  <button className={"request-btn "}>Following</button>
                ) : (
                  <button
                    className={"request-btn"}
                    onClick={() =>
                      sendInvitaion({
                        target: data?.sender,
                      })
                    }
                  >
                    Send Invitation
                  </button>
                )}
              </div>
            )}
          </td>
          {/* <td><button className="request-btn f-btn">Following</button></td> */}
        </tr>
      );
    },
  },
];

export default heartTableRow;
