/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { Image } from "../../domain/model/instant-review-model";
import FilePicker from "./FilePicker";
import { OverlayTrigger, Popover, PopoverBody, Spinner } from "react-bootstrap";
import { useState } from "react";
import { Modal } from "react-bootstrap";

import Compressor from "compressorjs";
import { useTranslation } from "react-i18next";

import Loaders from "./Loaders";
export interface IHistoryPageProps {}

export interface ICheckboxPhotoGalleryProps {
  getPhoto: () => Promise<Image[] | null>;
  getSpecialCatList?: () => Promise<any>;
  currentSpecialCat?: any;
  specialCatalogueListData: any[];
  specialCatalogueLists?: any;
  catalogNumber?: any;
  langType?: string;
  uploadPhoto?: (photoLocal: File) => Promise<boolean>;
  deletePhoto?: (photo: Image) => Promise<boolean>;
  setMainPhoto?: (photo: Image) => Promise<boolean>;
  getMainPhoto?: () => Promise<any>;
  handleSpecialCatalogChange: any;
}

export default function CheckboxPhotoGallery(
  props: ICheckboxPhotoGalleryProps
) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [photos, setPhotos] = React.useState<CheckboxPhoto[]>([]);
  const [isUploadPhoto, setIsUploadPhoto] = React.useState(false);
  const [isDeletingPhoto, setIsDeletingPhoto] = React.useState(false);
  const [errorMessages, setErrorMessages] = useState<string | undefined>();
  const [selectedPhotos, setSelectedPhotos] = useState<CheckboxPhoto[]>([]);
  const [selectedCount, setSelectedCount] = useState(0);
  
  const [isSpecialCatMainPhoto, setIsSpecialCatMainPhoto] = useState(false);
  const [isSpecialCatMainP, setIsSpecialCatMainP] = useState(null);
  const [activePopover, setActivePopover] = useState(null);

  const handlePopoverToggle = (popoverType: any) => {
    if (activePopover === popoverType) {
      setActivePopover(null);
    } else {
      setActivePopover(popoverType);
    }
  };

  const getData = () => {
    setIsLoading(true);
    props.getPhoto().then((res) => {
      if (res) {
        setPhotos(res.map((r) => ({ ...r, isChecked: false })));
        setIsLoading(false);
      }
      setIsLoading(false);
    });
  };

  async function fetchData() {
    try {
      const result = await props.getMainPhoto?.();
      if (result) {
        // console.log("result", result.data);
        setIsSpecialCatMainP(result?.data?.cache_url);
      } else {
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  React.useEffect(getData, [props.catalogNumber]);

  React.useEffect(() => {
    fetchData();
  }, []);

  const handleFileSelection: React.ChangeEventHandler<
    HTMLInputElement
  > = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const count = Array.from(e.target.files || []).filter((file) =>
      file.type.includes("image")
    ).length;
    setSelectedCount(count);

    if (e.target.files && e.target.files.length > 0) {
      const files = Array.from(e.target.files);
      const images = files.filter((file) => file.type.includes("image"));

      const totalImages = images.length;
      const remainingSlots = 3 - photos.length;

      if (totalImages > remainingSlots) {
        setErrorMessages(`You can only select up to three (3) image(s).`);
        return;
      }
      setSelectedCount(0);
      setIsUploadPhoto(true);

      const uploadNextPhoto = async (index: number) => {
        if (index >= images.length) {
          setIsUploadPhoto(false);
          getData();
          return;
        }

        const file = images[index];

        const compressedFile = await new Promise<File>((resolve) => {
          new Compressor(file, {
            quality: 0.6, // Adjust the compression quality as needed
            success(result) {
              // Create a new File object from the compressed Blob
              const compressed = new File([result], file.name, {
                type: file.type,
                lastModified: Date.now(),
              });
              resolve(compressed);
            },
            error(error) {
              console.error(error);
              resolve(file);
            },
          });
        });

        const maxSizeBytes = 2.4 * 1024 * 1024; // 2.4MB
        if (compressedFile.size > maxSizeBytes) {
          // Handle the case when compression does not achieve the desired file size
          // console.log("File size still exceeds 2.4MB");
        } else if (compressedFile.size < maxSizeBytes) {
          // console.log("File size is less than 2.4MB");
        }

        const uploadResult = await props.uploadPhoto?.(compressedFile);

        if (uploadResult === true) {
          uploadNextPhoto(index + 1);
        } else {
          setErrorMessages("Error uploading photos.");
          setIsUploadPhoto(false);
        }
      };

      uploadNextPhoto(0);

      // Update the selected count
      const count = Array.from(e.target.files || []).filter((file) =>
        file.type.includes("image")
      ).length;
    }
  };

  const handleDeletePhoto = () => {
    if (selectedPhotos.length > 0 && props.deletePhoto) {
      setIsDeletingPhoto(true);
      const deletePromises = selectedPhotos.map(
        (photo) => props.deletePhoto && props.deletePhoto(photo)
      );
      Promise.all(deletePromises)
        .then((results) => {
          if (results.includes(false)) {
            setErrorMessages("Error deleting photos.");
          }
          setSelectedPhotos([]);
          setSelectedCount(0); // Reset the selected count

          setIsDeletingPhoto(false);
          getData();
          fetchData();
        })
        .catch((error) => {
          setErrorMessages("Error deleting photos.");
          setSelectedPhotos([]);
          setSelectedCount(0); // Reset the selected count

          setIsDeletingPhoto(false);
        });
    } else {
      setErrorMessages("Please select at least one (1) image(s).");
    }
  };

  const handleFlagAdMedia = () => {
    if (selectedPhotos.length > 0) {
      setErrorMessages("Please select only one (1) image(s).");
    }
  };

  const handleSpecialCatalogueMain = () => {
    if (selectedPhotos.length > 0 && props.setMainPhoto) {
      const setMainPromises = selectedPhotos.map(
        (photo) => props.setMainPhoto && props.setMainPhoto(photo)
      );

      Promise.all(setMainPromises)
        .then((results) => {
          if (results.includes(false)) {
            // setErrorMessages("Error Set Main photos.");
          }
          //   console.log("fff", setMainPromises);
          setSelectedPhotos([]);
          setSelectedCount(0); // Reset the selected count

          // setIsDeletingPhoto(false);
          getData();
          fetchData();
        })
        .catch((error) => {
          setErrorMessages("Error Set Main photos.");
          setSelectedPhotos([]);
          setSelectedCount(0); // Reset the selected count

          setIsDeletingPhoto(false);
        });
    } else {
      setErrorMessages("Please select at least one (1) image(s).");
    }
  };

  return (
    <div>
      {/* <div className="row">
        <div className="col-12 mb-3">
       
          <select
            // disabled={!specialCatalogueStatus}
            name=""
            id=""
            className="form-select input-md w-100"
            style={{ width: "auto" }}
            value={props.currentSpecialCat}
            onChange={props.handleSpecialCatalogChange}
          >
            <option value={""}>{t("chooseOption")}</option>

            {Object.entries(props.specialCatalogueListData).map(
              ([key, value]) => {
                const [englishName, chineseName] = value.split("|");

                return (
                  <option value={value}>
                    {" "}
                    {props.langType === "zh" ? chineseName : englishName}
                  </option>
                );
              }
            )}
          </select>
        </div>
      </div> */}
      <div className="row g-1 mb-3">
        {isLoading ? (
          <div className="d-flex justify-content-center my-3">
            <Loaders />
          </div>
        ) : (
          <>
            {photos.map((photo, i) => (
              <div className="col-4" key={i}>
                <div className="custom-control custom-checkbox image-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={`street-photo-${i}`}
                    checked={photo.isChecked}
                    accept="image/*"
                    capture="environment"
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      const updatedPhotos = photos.map((photo, index) =>
                        index === i ? { ...photo, isChecked } : photo
                      );
                      setSelectedPhotos(
                        updatedPhotos.filter((photo) => photo.isChecked)
                      );
                      setPhotos(updatedPhotos);

                      // Update the selected count
                      const count = updatedPhotos.filter(
                        (photo) => photo.isChecked
                      ).length;
                      setSelectedCount(count);
                    }}
                  />

                  <label
                    className="custom-control-label"
                    htmlFor={`street-photo-${i}`}
                  >
                    <img src={photo.cache_url} alt="#" className="img-fluid" />
                  </label>
                </div>
              </div>
            ))}
          </>
        )}
      </div>

      <div className="row align-items-center g-0 mb-5">
        <div className="col-12  ">
          {photos.length < 3 && (
            <FilePicker
              multiple={true}
              accept={["image/*"]}
              onChange={handleFileSelection}
            >
              <button className="button primary-button me-2 px-2 px-md-3">
                {isUploadPhoto ? <Spinner size="sm" /> : t("uploadPhoto")}
              </button>
            </FilePicker>
          )}
          {photos.length > 0 && (
            <button
              className="button delete-photo px-2 px-md-3"
              data-bs-toggle="modal"
              data-bs-target="#myModal"
              onClick={handleDeletePhoto}
              disabled={selectedCount === 0 && selectedPhotos.length === 0}
            >
              {isDeletingPhoto ? (
                <Spinner size="sm" />
              ) : (
                `${t("deletePhoto")}  ${
                  selectedCount > 0 ? ` (${selectedCount})` : ""
                }`
              )}
            </button>
          )}
          <button
            className="button main-photo delete-photo px-2 px-md-3"
            disabled={
              (selectedCount > 1 && selectedPhotos.length > 1) ||
              (selectedCount === 0 && selectedPhotos.length === 0)
            }
            onClick={handleSpecialCatalogueMain}
          >
            {isSpecialCatMainPhoto ? <Spinner size="sm" /> : t("mainPhoto")}
          </button>
        </div>
      </div>

      <div className="row align-items-center g-0 mb-5">
        <div className="col-12">
          <label htmlFor="" className="label">
            {t("specialCatProfilePhoto")}
            <OverlayTrigger
              placement="top"
              trigger="click"
              // show={SpecialCatProfileShowPopover}
              // onToggle={setSpecialCatProfileShowPopover}

              show={activePopover === "specialCategoryInfo"}
              onToggle={() => handlePopoverToggle("specialCategoryInfo")}
              overlay={
                <Popover>
                  {/* {SpecialCatProfileShowPopover && ( */}
                  <button
                    type="button"
                    className="close-popover"
                    onClick={() => handlePopoverToggle("specialCategoryInfo")}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  {/* )} */}
                  <PopoverBody>{t("specialCategoryInfo")}</PopoverBody>
                </Popover>
              }
            >
              <button type="button" className="exc-btn mx-2">
                <i className="fa-solid fa-exclamation"></i>
              </button>
            </OverlayTrigger>
          </label>
        </div>

        <div className="col-4">
          <div className="margin-top-0 mt-2">
            <label className="">
              <img
                src={
                  isSpecialCatMainP !== null
                    ? isSpecialCatMainP
                    : "/img/blank-profile-picture.png"
                }
                className="img-fluid cus-cont-label"
                alt=""
              />
            </label>
          </div>
        </div>

        {errorMessages && (
          <Modal show={true} onHide={() => setErrorMessages(undefined)}>
            <Modal.Header closeButton />
            <Modal.Body>
              {}
              <div className="schedule-post text-center d-block">
                <h3>{errorMessages}</h3>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="row">
                <div className="col-12 text-center">
                  <button
                    className="bk-btn primary-button button mt-3 w-100"
                    onClick={() => setErrorMessages(undefined)}
                  >
                    OK
                  </button>
                </div>
              </div>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </div>
  );
}

interface CheckboxPhoto extends Image {
  isChecked: boolean;
}
