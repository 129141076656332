import IPageType from "../adapter/page-type-adapter"

export default class ClientAddDetailsType implements IPageType {
    constructor(path: string) {
        this.path = path
    }

    path: string;

    static Preview = new ClientAddDetailsType('preview');
    static Show = new ClientAddDetailsType('show');

    static getPageTypeByPath(path: String) : ClientAddDetailsType | null {
        switch (path) {
			case ClientAddDetailsType.Preview.path:
				return ClientAddDetailsType.Preview;
			default:
				return null;
		}
    }
}