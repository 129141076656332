import React, { useEffect, useState, useRef } from "react";
import RegistrationSteps from "../navigation/RegistrationSteps";
import { useNavigate, useParams } from "react-router-dom";
import {
  Modal,
  OverlayTrigger,
  Popover,
  PopoverBody,
  Spinner,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import Snackbar from "awesome-snackbar";
import SizedBox from "../../reusable/Sizedbox";
import { updateUser } from "../../../services/auth";
import CreateUserParams from "../../../domain/model/create-user-params";

import {
  BodyDimension,
  OperatingHour,
} from "../../../domain/model/create-user-params-model";
import {
  editAdsMaster,
  editAdsMasterList,
  getAdListMaster,
  getAdsMaster,
} from "../../../services/master/master-ad";
import { getShop } from "../../../services/master/master-shop";
import { getProfileMaster } from "../../../services/master/master-account";
import {
  editAddtionalInfo,
  getClientProfile,
} from "../../../services/client/client-profile";
import {
  AdvanceDes,
  ClientProfileModel,
} from "../../../domain/model/client-profile-model";
import {
  getCountry,
  getCurrencies,
  getLanguages,
  getNationality,
  getRegion1s,
  getRegion2s,
  getServiceType,
  getService,
  getTypes,
  getTypeDisplayGroup,
} from "../../../services/modules";
import AppRoutePath from "../../../constants/AppRoutePath";

import CustomHeader from "../../reusable/Header";
import { useTranslation, initReactI18next } from "react-i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { separateNumericAndNonNumeric } from "../../../helper/format-helper";
import { GroupDisplayModel } from "../../../domain/model/display-group";
import TopHearder from "../../reusable/TopHearder";
import ScreenLoaders from "../../reusable/ScreenLoader";
import ScreenLoader2 from "../../reusable/ScreenLoader2";
import { AdsMasterModel } from "../../../domain/model/ads-master-model";
import { translateToChinese } from "../../../services/googleTransalate";

function EditProfile(): JSX.Element {
  const isMaster = window.location.pathname.includes("master");
  const [isMastersClientEmpty, setIsMastersClientEmpty] = useState(true);
  const isRegister = window.location.pathname.includes("register");
  const isEdit = window.location.pathname.includes("edit");
  const params = useParams();
  const [isLoadingScreen, setIsLoadingScreen] = useState(false);
  const [isLoadingScreenStep, setIsLoadingScreenStep] = useState(false);
  const [masterType, setMasterType] = useState("");
  //
  const [existingAdsData, setExistingAdsData] = useState([]) as any;
  const [clientAddressNotMatchList, setClientAddressNotMatchList] = useState(
    []
  ) as any;
  //

  const navigate = useNavigate();
  const [telegram, setTelegram] = useState("");
  const langType = localStorage.getItem("i18nextLng");

  const [clientProfile, setClientProfile] =
    React.useState<ClientProfileModel | null>(null);

  const initialOperatingHours: OperatingHours = {
    Monday: ["00:00", "00:00"],
    Tuesday: ["00:00", "00:00"],
    Wednesday: ["00:00", "00:00"],
    Thursday: ["00:00", "00:00"],
    Friday: ["00:00", "00:00"],
    Saturday: ["00:00", "00:00"],
    Sunday: ["00:00", "00:00"],
  };

  const [operatingHours, setOperatingHours] = useState<OperatingHours>(
    initialOperatingHours
  );

  useEffect(() => {
    const operData = clientProfile?.details?.basic_desc?.operating_hour;
    if (operData) {
      setOperatingHours(operData);
    }
  }, [clientProfile]);

  const [activePopover, setActivePopover] = useState(null);

  const handlePopoverToggle = (popoverType: any) => {
    if (activePopover === popoverType) {
      setActivePopover(null);
    } else {
      setActivePopover(popoverType);
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const [shopMasterName, setShopMasterName] = useState("");
  const [phoneMaster, setPhoneMaster] = useState(
    clientProfile?.details?.contact_info?.phone_number
  );
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessagesChiName, setErrorMessagesChiName] = useState<
    string | undefined
  >();
  const [errorMessagesEngName, setErrorMessagesEngName] = useState<
    string | undefined
  >();
  const [errorMessagesNation, setErrorMessagesNation] = useState<
    string | undefined
  >();
  const [errorMessagesLang, setErrorMessagesLang] = useState<
    string | undefined
  >();
  const [errorMessagesCountry, setErrorMessagesCountry] = useState<
    string | undefined
  >();
  const [errorMessagesRegion1, setErrorMessagesRegion1] = useState<
    string | undefined
  >();
  const [errorMessagesRegion2, setErrorMessagesRegion2] = useState<
    string | undefined
  >();
  const [errorMessagesPhone, setErrorMessagesPhone] = useState<
    string | undefined
  >();
  const [errorMessagesTelegram, setErrorMessagesTelegram] = useState<
    string | undefined
  >();
  const [isBlogAdvertisementModalOpen, setIsBlogAdvertisementModalOpen] =
    useState(false);
  const [isBlogModalOpen, setIsBlogModalOpen] = useState(false);
  const [isTypeDetailsOpen, setIsTypeDetailsOpen] = useState(false);
  const [chineseName, setChineseName] = useState(clientProfile?.zh_name ?? "");
  const [englishName, setEnglishName] = useState(clientProfile?.en_name ?? "");
  const [specialCats, setSpecialCats] = useState(
    clientProfile?.details?.basic_desc?.special_cat ?? ""
  );
  const [type, setType] = useState<string>(
    clientProfile?.details?.basic_desc?.type ?? ""
  );

  const [nationality, setNationality] = useState(
    clientProfile?.details?.basic_desc?.nationality ?? ""
  );

  const [age, setAge] = useState<number>(
    clientProfile?.details?.basic_desc?.age ?? 0
  );
  const [language, setLanguage] = useState([""]);
  const [price, setPrice] = useState<number>(
    clientProfile?.details?.basic_desc?.price ?? 0
  );
  const [currency, setCurrency] = useState(
    clientProfile?.details?.basic_desc?.currency ?? "HKD|港幣"
  );
  const [selectedService, setSelectedService] = useState<string[]>([]);
  const [selectedServiceType, setSelectedServiceType] = useState<string[]>([]);
  const [services, setServices] = useState<string[]>([]);
  const [serviceTypes, setServiceTypes] = useState<string[]>([]);
  // const [selectedGroup, setSelectedGroup] = useState<number | null>(null);
  // const [serviceType, setServiceType] = useState<string[]>([]);
  const [displayGroupType, setDisplayGroupType] = useState<GroupDisplayModel>();
  const [blogDetails, setBlogDetails] = useState("");
  const [blogDetailsCount, setBlogDetailsCount] = useState(0);

  const generateClosingHoursOptions = (day: any) => {
    const startHour = parseInt(day?.open_hours.split(":")[0], 10);
    const closingHours = [];

    for (let hour = startHour + 1; hour <= 24; hour++) {
      closingHours.push(`${hour.toString().padStart(2, "0")}:00`);
    }

    return closingHours.map((hour) => (
      <option key={hour} value={hour}>
        {hour}
      </option>
    ));
  };

  const handleCloseHoursChangeMonday = (event: any) => {
    const newCloseHours = event.target.value;
    setMondayHour((prevMondayHour) => ({
      ...prevMondayHour,
      close_hours: newCloseHours,
    }));
  };
  const handleCloseHoursChangeTuesday = (event: any) => {
    const newCloseHours = event.target.value;
    setTuesdayHour((prevMondayHour) => ({
      ...prevMondayHour,
      close_hours: newCloseHours,
    }));
  };
  const handleCloseHoursChangeWednesday = (event: any) => {
    const newCloseHours = event.target.value;
    setWednesdayHour((prevMondayHour) => ({
      ...prevMondayHour,
      close_hours: newCloseHours,
    }));
  };
  const handleCloseHoursChangeThursday = (event: any) => {
    const newCloseHours = event.target.value;
    setThursdayHour((prevMondayHour) => ({
      ...prevMondayHour,
      close_hours: newCloseHours,
    }));
  };
  const handleCloseHoursChangeFriday = (event: any) => {
    const newCloseHours = event.target.value;
    setFridayHour((prevMondayHour) => ({
      ...prevMondayHour,
      close_hours: newCloseHours,
    }));
  };
  const handleCloseHoursChangeSaturday = (event: any) => {
    const newCloseHours = event.target.value;
    setSaturdayHour((prevMondayHour) => ({
      ...prevMondayHour,
      close_hours: newCloseHours,
    }));
  };
  const handleCloseHoursChangeSunday = (event: any) => {
    const newCloseHours = event.target.value;
    setSundayHour((prevMondayHour) => ({
      ...prevMondayHour,
      close_hours: newCloseHours,
    }));
  };

  const handleDayHoursChange = (day: any, event: any) => {
    const newOpenHours = event.target.value;
    const selectedOpenHour = parseInt(newOpenHours.split(":")[0], 10);

    // Calculate closing hour based on the selected opening hour
    let selectedCloseHour =
      selectedOpenHour >= 23
        ? "00"
        : (selectedOpenHour + 1).toString().padStart(2, "0");

    if (selectedOpenHour > parseInt(selectedCloseHour, 10)) {
      selectedCloseHour = (selectedOpenHour + 1).toString().padStart(2, "0");
    }

    // Dynamically set the state based on the day
    switch (day) {
      case "Monday":
        setMondayHour((prevMondayHour) => ({
          ...prevMondayHour,
          open_hours: newOpenHours,
          close_hours: `${selectedCloseHour}:00`,
        }));
        break;
      case "Tuesday":
        setTuesdayHour((prevTuesdayHour) => ({
          ...prevTuesdayHour,
          open_hours: newOpenHours,
          close_hours: `${selectedCloseHour}:00`,
        }));
        break;
      case "Wednesday":
        setWednesdayHour((prevWednesdayHour) => ({
          ...prevWednesdayHour,
          open_hours: newOpenHours,
          close_hours: `${selectedCloseHour}:00`,
        }));
        break;
      case "Thursday":
        setThursdayHour((prevThursdayHour) => ({
          ...prevThursdayHour,
          open_hours: newOpenHours,
          close_hours: `${selectedCloseHour}:00`,
        }));
        break;
      case "Friday":
        setFridayHour((prevFridayHour) => ({
          ...prevFridayHour,
          open_hours: newOpenHours,
          close_hours: `${selectedCloseHour}:00`,
        }));
        break;
      case "Saturday":
        setSaturdayHour((prevSaturdayHour) => ({
          ...prevSaturdayHour,
          open_hours: newOpenHours,
          close_hours: `${selectedCloseHour}:00`,
        }));
        break;
      case "Sunday":
        setSundayHour((prevSundayHour) => ({
          ...prevSundayHour,
          open_hours: newOpenHours,
          close_hours: `${selectedCloseHour}:00`,
        }));
        break;

      default:
        break;
    }
  };

  const openHourSelect = (
    <>
      {[...Array(24).keys()].map((hour) => (
        <option key={hour} value={`${hour.toString().padStart(2, "0")}:00`}>
          {`${hour.toString().padStart(2, "0")}:00`}
        </option>
      ))}
    </>
  );

  const [mondayHour, setMondayHour] = useState({
    day: "Monday",
    open_hours: "00:00",
    close_hours: "00:00",
    rest: false,
  });

  const [tuesdayHour, setTuesdayHour] = useState({
    day: "Tuesday",
    open_hours: "00:00",
    close_hours: "00:00",
    rest: false,
  });
  const [wednesdayHour, setWednesdayHour] = useState({
    day: "Wednesday",
    open_hours: "00:00",
    close_hours: "00:00",
    rest: false,
  });
  const [thursdayHour, setThursdayHour] = useState({
    day: "Thursday",
    open_hours: "00:00",
    close_hours: "00:00",
    rest: false,
  });
  const [fridayHour, setFridayHour] = useState({
    day: "Friday",
    open_hours: "00:00",
    close_hours: "00:00",
    rest: false,
  });
  const [saturdayHour, setSaturdayHour] = useState({
    day: "Saturday",
    open_hours: "00:00",
    close_hours: "00:00",
    rest: false,
  });

  const [sundayHour, setSundayHour] = useState({
    day: "Sunrday",
    open_hours: "00:00" || operatingHours.Sunday[0],
    close_hours: "00:00" || operatingHours.Sunday[1],
    rest: false,
  });

  const [height, setHeight] = useState<BodyDimension>({
    type: "height",
    size: 0,
    conversion: "cm",
  });
  const [cup, setCup] = useState<BodyDimension>({
    type: "c_size",
    size: 0,
    conversion: "AA",
  });

  const [waist, setWaist] = useState<BodyDimension>({
    type: "w_size",
    size: 0,
    conversion: "inch",
  });
  const [hip, setHip] = useState<BodyDimension>({
    type: "h_size",
    size: 0,
    conversion: "inch",
  });
  const [advanceDesc, setAdvanceDesc] = useState<AdvanceDes>({
    c_size: cup.size + "" + cup.conversion,
    c_letter: "C",
    w_size: waist.size + "" + waist.conversion,
    h_size: hip.size + "" + hip.conversion,
  });
  useEffect(() => {
    setAdvanceDesc({
      c_size: cup.size + "" + cup.conversion,
      c_letter: "C",
      w_size: waist.size + "" + waist.conversion,
      h_size: hip.size + "" + hip.conversion,
    });
  }, [hip, waist, cup]);

  const [ext, setExt] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordFill, setIsPasswordFill] = useState(true);
  const [ad, setAd] = useState(clientProfile?.ad ?? 0);
  const [blog, setBlog] = useState(clientProfile?.blog ?? 0);

  const [country, setCountry] = useState(
    clientProfile?.details?.basic_desc?.country ?? ""
  );
  const [region1, setRegion1] = useState(
    clientProfile?.details?.basic_desc?.region1 ?? ""
  );
  const [region2, setRegion2] = useState(
    clientProfile?.details?.basic_desc?.region2 ?? ""
  );
  const [streetName, setStreetName] = useState(
    clientProfile?.details?.address?.address_street ?? ""
  );
  const [chineseStreetName, setChineseStreetName] = useState("");
  const [addressStreet, setAddressStreet] = useState(
    clientProfile?.details?.address?.address_street ?? ""
  );
  const [building, setBuilding] = useState(
    clientProfile?.details?.address?.building ?? ""
  );
  const [room, setRoom] = useState(clientProfile?.details?.address?.room ?? "");
  const [floorC, setFloorC] = useState(
    clientProfile?.details?.address?.floorC ?? ""
  );
  const [block, setBlock] = useState(
    clientProfile?.details?.address?.block ?? ""
  );

  const [floorE, setFloorE] = useState(
    clientProfile?.details?.address?.floorE ?? ""
  );
  const [description, setDescription] = useState(
    clientProfile?.details?.address?.description ?? ""
  );

  const [needCaller, setNeedCaller] = useState(
    clientProfile?.details?.basic_desc?.need_caller_display ?? false
  );
  const [pleaseBooking, setPleaseBooking] = useState(
    clientProfile?.details?.basic_desc?.book_in_advance ?? false
  );
  const [callToOpen, setCallToOpen] = useState(
    clientProfile?.details?.basic_desc?.call_to_open_door ?? false
  );

  const [selfDescription, setSelfDescription] = useState(
    clientProfile?.details?.basic_desc?.self_desc ?? ""
  );
  const [foreginer, setForeginer] = useState(
    clientProfile?.details?.basic_desc?.foreigner ?? false
  );

  const [PD, setPD] = useState(
    clientProfile?.details?.basic_desc?.welcome_pd ?? false
  );

  const [selectedPrefix, setSelectedPrefix] = useState(
    clientProfile?.details?.basic_desc?.country_phone_code ?? "60"
    // "60"
  );
  const [mobileNumber, setMobileNumber] = useState(
    clientProfile?.details?.basic_desc?.phone ?? ""
  );

  const [isMobileNumberValid, setIsMobileNumberValid] = useState(false);
  const [mapAddress, setMapAddress] = useState<string>();

  // Master data
  const [countries, setCountries] = useState<string[]>([]);
  const [region1s, setRegion1s] = useState<string[]>([]);
  const [region2s, setRegion2s] = useState<string[]>([]);

  const [currencies, setCurrencies] = useState<string[]>([]);
  const [languages, setLanguages] = useState<string[]>([]);
  const [nationalities, setNationalities] = useState<string[]>([]);

  // const [service, setService] = useState<string[]>([]);
  const [types, setTypes] = useState<string[]>([]);
  const typeCheck = useRef<HTMLSelectElement>(null);

  const streetNumberField = useRef<HTMLInputElement>(null);
  const buildingField = useRef<HTMLInputElement>(null);

  const mapRef = useRef(null);
  const { t } = useTranslation();

  const [TypeShowPopover2, setTypeShowPopover2] = useState(false);

  const handleTypeShowPopover2 = () => {
    setTypeShowPopover2(false);
  };

  useEffect(() => {
    const fetchTransalateData = async () => {
      try {
        const englishText = streetName;
        const translatedText = await translateToChinese(englishText);
        setChineseStreetName(translatedText);
        console.log("Translated text in Chinese:", translatedText);
      } catch (error) {
        console.error("Error translating text:", error);
      }
    };

    fetchTransalateData();
  }, [streetName]);

  useEffect(() => {
    setIsLoadingScreen(true);
    getLanguages().then((e) => {
      setLanguages(e);
    });

    getTypes().then((types) => {
      setTypes(types);
    });

    if (isMaster) {
      getShop().then((shop) => {
        if (shop != null) {
          setShopMasterName(shop.en_name ?? "");
        }
      });
      getProfileMaster().then((profile) => {
        if (profile != null) {
          setMasterType(profile.master_type);
          setPhoneMaster(profile.mobile ?? "");
        }
      });
      getAdListMaster().then((res) => {
        if (res) {
          const existingAds: AdsMasterModel[] = res?.["item(s)"] || [];
          const filteredAds = existingAds.filter(
            (ad) => ad.user_id !== Number(params.id)
          );
          // console.log("All  ads: ", res);
          // console.log("All  existingAds: ", filteredAds);
          handleSuperMasterAddress();
          setExistingAdsData(filteredAds);
          setIsMastersClientEmpty(res.total <= 0);
        }
      });

      // GET DATA MASTER'S AD IF DATA ALREADY EXIST
      if (isMaster && params.id) {
        // console.log("Master Edit Param, id ", params.id);

        getAdsMaster(params.id).then((res) => {
          if (res != null) {
            // setAllProfileData(convertMastersAddToClientProfile(res!))
            setIsLoadingScreen(false);
            setAllProfileData(res);
            setExt(res!.mobile?.split("EXT")[1] ?? "");
            // setPassword(res.password ?? "")
            setAd(res.ad ?? 0);
            setBlog(res.blog ?? 0);
          } else {
            setIsLoadingScreen(false);
          }
        });
      }
      // getAddtionalInfo(params.id).then((res) => {
      //   setTelegram(res?.telegram);
      // });
    } else {
      getClientProfile().then((res) => {
        if (res) {
          setAllProfileData(res);
          setIsLoadingScreen(false);
        }
      });
    }
  }, []);

  useEffect(() => {
    const script = document.createElement("script");

    if (script) {
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places&v=weekly`;
      script.async = true;
      script.defer = true;
      script.onload = () => {
        setTimeout(() => {
          initMap();
        }, 1000);
      };

      document.body.appendChild(script);
      setTimeout(() => {
        document.body.removeChild(script);
      }, 2000);
    }
  }, [addressStreet, streetName]);

  function initMap() {
    const streetValue: string[] = addressStreet.includes(",")
      ? addressStreet.split(",")
      : [];

    const lat: string =
      streetValue.length > 0 ? Number(streetValue[0]).toFixed(4) : "22.3193";
    const lng: string =
      streetValue.length > 0 ? Number(streetValue[1]).toFixed(4) : "114.1694";

    let centerCoordinates = {
      lat: Number(lat),
      lng: Number(lng),
    };

    const [englishText] = country.split("|");

    if (englishText === "Macau") {
      centerCoordinates = {
        lat: 22.1987,
        lng: 113.5439,
      };
    }

    const mapDiv = document.getElementById("map") as HTMLElement;
    const inputDiv = document.getElementById("streetno") as HTMLInputElement;
    // console.log("mapDiv ", mapDiv);
    // console.log("inputDiv ", inputDiv);

    if (mapDiv) {
      const map = new google.maps.Map(mapDiv, {
        zoom: 17,
        center: centerCoordinates,
        mapTypeControl: false,
      });

      const marker = new google.maps.Marker({
        map,
        anchorPoint: new google.maps.Point(0, -29),
        position: new google.maps.LatLng(centerCoordinates),
      });

      if (inputDiv) {
        let countryRestriction = "hk";

        if (country === "Macau") {
          countryRestriction = "mo";
        } else if (country === "Hong KongChina" || country === "Hong Kong") {
          countryRestriction = "hk";
        }

        const autocomplete = new google.maps.places.Autocomplete(inputDiv, {
          componentRestrictions: {
            country: countryRestriction,
          },
          fields: ["geometry", "address_components"],
          types: ["address"],
        });

        autocomplete.setComponentRestrictions({
          country: countryRestriction,
        });
        autocomplete.bindTo("bounds", map);

        const infowindow = new google.maps.InfoWindow();
        const infowindowContent = document.getElementById(
          "infowindow-content"
        ) as HTMLElement;

        infowindow.setContent(infowindowContent);

        autocomplete.addListener("place_changed", () => {
          infowindow.close();
          marker.setVisible(false);

          const place = autocomplete.getPlace();

          let streetNumber = "";
          let building = "";
          let streetName = "";

          console.log("Map streetName", streetName);

          if (place && place.geometry && place.address_components) {
            for (const component of place.address_components) {
              const componentType = component.types[0];

              switch (componentType) {
                case "street_number": {
                  streetNumber = `${component.long_name}`;
                  break;
                }

                case "premise": {
                  building = `${component.long_name}${building}`;
                  break;
                }

                case "route": {
                  streetName = `${streetName}${component.long_name}`;
                  break;
                }
              }
            }
          }

          if (!place.geometry || !place.geometry.location) {
            window.alert(
              "No details available for input: '" + place.name + "'"
            );
            return;
          }

          // If the place has a geometry, then present it on a map.
          if (place.geometry.viewport) {
            map.fitBounds(place.geometry.viewport);
          } else {
            map.setCenter(place.geometry.location);
            map.setZoom(17);
          }

          marker.setPosition(place.geometry.location);
          marker.setVisible(true);

          const fullAddress = `${streetNumber} ${streetName}`;
          setStreetName(fullAddress);
          setAddressStreet(
            `${place.geometry.location.lat()},${place.geometry.location.lng()}`
          );
          setBuilding(building);
        });
      } else {
        console.error("Input element is not found!");
      }
    } else {
      console.error("Map element is not found!");
    }
  }

  const setAllProfileData = (profile: ClientProfileModel | null) => {
    if (profile && profile.details) {
      const cupData = separateNumericAndNonNumeric(
        profile?.details?.advance_desc?.c_size || ""
      );
      const waistData = separateNumericAndNonNumeric(
        profile?.details?.advance_desc?.w_size || ""
      );
      const hipData = separateNumericAndNonNumeric(
        profile?.details?.advance_desc?.h_size || ""
      );
      const heightData = separateNumericAndNonNumeric(
        profile?.details?.basic_desc?.height || ""
      );

      const openHour = profile?.details?.basic_desc?.operating_hour;
      // console.log("openHour ", openHour);

      // console.log("profile dffghfhfgh", profile);
      setType(profile.details?.basic_desc?.type ?? "");
      setChineseName(profile.zh_name ?? "");
      setEnglishName(profile.en_name ?? "");
      setNationality(profile.details?.basic_desc?.nationality ?? "");
      setAge(profile.details?.basic_desc?.age ?? 0);

      setLanguage(Object.values(profile?.details?.basic_desc?.language ?? {}));
      setPrice(profile.details?.basic_desc?.price ?? 0);
      setCurrency(profile.details?.basic_desc?.currency ?? "");
      setSelectedServiceType(profile.details?.advance_desc?.service_type ?? []);
      setSelectedService(profile.details?.advance_desc?.services ?? []);
      setSpecialCats(profile.details.basic_desc?.special_cat ?? "");
      setHeight({
        type: "height",
        size: Number(heightData.numeric),
        conversion: heightData.nonNumeric,
      });
      setCup({
        type: "c_size",
        size: Number(cupData.numeric),
        conversion: cupData.nonNumeric,
      });
      setWaist({
        type: "w_size",
        size: Number(waistData.numeric),
        conversion: waistData.nonNumeric,
      });
      setHip({
        type: "h_size",
        size: Number(hipData.numeric),
        conversion: hipData.nonNumeric,
      });

      // setHip((state) =>
      //   (profile.details?.body_dimension ?? []).length > 3
      //     ? profile.details?.body_dimension![3] ?? state
      //     : state
      // );
      setAd(profile.ad ?? 0);
      setBlog(profile.blog ?? 0);
      setAddressStreet(
        (state) => profile.details?.address?.address_street ?? state
      );
      setTelegram(profile.telegram ?? "");
      // setBlogDetails(profile.telegram ?? "");
      setBlogDetails(
        (state) => profile.details?.blog_details?.basic_desc ?? state
      );
      setStreetName((state) => profile.details?.address?.street_name ?? state);
      setRegion2((state) => profile.details?.basic_desc?.region2 ?? state);
      setRegion1((state) => profile.details?.basic_desc?.region1 ?? state);
      setCountry((state) => profile.details?.basic_desc?.country ?? state);
      setBuilding((state) => profile.details?.address?.building ?? state);
      setRoom((state) => profile.details?.address?.room ?? state);
      setFloorC((state) => profile.details?.address?.floorC ?? state);
      setFloorE((state) => profile.details?.address?.floorE ?? state);
      setBlock((state) => profile.details?.address?.block ?? state);
      setDescription((state) => profile.details?.address?.description ?? state);
      setNeedCaller(
        (state) => profile.details?.basic_desc?.need_caller_display ?? state
      );
      setPleaseBooking(
        (state) => profile.details?.basic_desc?.book_in_advance ?? state
      );
      setCallToOpen(
        (state) => profile.details?.basic_desc?.call_to_open_door ?? state
      );
      setSelfDescription(
        (state) => profile.details?.basic_desc?.self_desc ?? state
      );
      setForeginer(
        (state) => profile.details?.basic_desc?.welcome_foreigner ?? state
      );
      setPD((state) => profile.details?.basic_desc?.welcome_pd ?? state);
      setSelectedPrefix(
        (state) => profile.details?.basic_desc?.country_phone_code ?? state
      );
      setMobileNumber((state) => profile.details?.basic_desc?.phone ?? state);

      // setMobileNumber(
      //   (state) => profile.details?.contact_info?.phone_number ?? state
      // );

      setMondayHour({
        day: "Monday",
        open_hours:
          openHour.Monday[0] && openHour.Monday[0].length > 0
            ? openHour.Monday[0]
            : "",
        close_hours:
          openHour.Monday[1] && openHour.Monday[1].length > 0
            ? openHour.Monday[1]
            : "",
        rest: openHour.Monday[0] == "" && openHour.Monday[1] == "",
      });

      setTuesdayHour({
        day: "Tuesday",
        open_hours:
          openHour.Tuesday[0] && openHour.Tuesday[0].length > 0
            ? openHour.Tuesday[0]
            : "",
        close_hours:
          openHour.Tuesday[1] && openHour.Tuesday[1].length > 0
            ? openHour.Tuesday[1]
            : "",
        rest: openHour.Tuesday[0] == "" && openHour.Tuesday[1] == "",
      });
      setWednesdayHour({
        day: "Wednesday",
        open_hours:
          openHour.Wednesday[0] && openHour.Wednesday[0].length > 0
            ? openHour.Wednesday[0]
            : "",
        close_hours:
          openHour.Wednesday[1] && openHour.Wednesday[1].length > 0
            ? openHour.Wednesday[1]
            : "",
        rest: openHour.Wednesday[0] == "" && openHour.Wednesday[1] == "",
      });
      setThursdayHour({
        day: "Thursday",
        open_hours:
          openHour.Thursday[0] && openHour.Thursday[0].length > 0
            ? openHour.Thursday[0]
            : "",
        close_hours:
          openHour.Thursday[1] && openHour.Thursday[1].length > 0
            ? openHour.Thursday[1]
            : "",
        rest: openHour.Thursday[0] == "" && openHour.Thursday[1] == "",
      });
      setFridayHour({
        day: "Friday",
        open_hours:
          openHour.Friday[0] && openHour.Friday[0].length > 0
            ? openHour.Friday[0]
            : "",
        close_hours:
          openHour.Friday[1] && openHour.Friday[1].length > 0
            ? openHour.Friday[1]
            : "",
        rest: openHour.Friday[0] == "" && openHour.Friday[1] == "",
      });
      setSaturdayHour({
        day: "Saturday",
        open_hours:
          openHour.Saturday[0] && openHour.Saturday[0].length > 0
            ? openHour.Saturday[0]
            : "",
        close_hours:
          openHour.Saturday[1] && openHour.Saturday[1].length > 0
            ? openHour.Saturday[1]
            : "",
        rest: openHour.Saturday[0] == "" && openHour.Saturday[1] == "",
      });
      setSundayHour({
        day: "Sunday",
        open_hours:
          openHour.Sunday[0] && openHour.Sunday[0].length > 0
            ? openHour.Sunday[0]
            : "",
        close_hours:
          openHour.Sunday[1] && openHour.Sunday[1].length > 0
            ? openHour.Sunday[1]
            : "",
        rest: openHour.Sunday[0] == "" && openHour.Sunday[1] == "",
      });

      setClientProfile({ ...profile });
    }
  };

  useEffect(() => {
    try {
      const addresses = [streetName, region2, region1, country].filter(
        (e) => e && e.length > 0
      );

      setMapAddress(addresses.join(","));
    } catch (e) {
      return;
    }
  }, [country, region1, region2, streetName]);

  useEffect(() => {
    /**
     * Depends on type
     * nationality
     * service type
     * country
     */
    (async () => {
      if (!type) return;

      setNationalities(await getNationality(type));
      setServiceTypes(await getServiceType(type));
      setCountries(await getCountry(type));
      setCurrencies(await getCurrencies(type));

      setDisplayGroupType(await getTypeDisplayGroup(type));
    })();
  }, [type]);

  const handleExt: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setExt(event.target.value.replace(/[^\d]/g, ""));
  };
  const handlePassword: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setPassword(event.target.value);
  };
  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleChineseName: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const val = event.target.value;
    const formattedInput = val.replace(/\s/g, "");
    if (val.length > 0) {
      setErrorMessagesChiName("");
    } else {
      setErrorMessagesChiName(t("chineseNameEmpty"));
    }

    setChineseName(formattedInput);
  };
  // useEffect(() => {
  //   let timerId = setTimeout(() => {
  //     if (chineseName.length === 0) {
  //       setErrorMessagesChiName("Chinese Name cannot be empty!");
  //     } else {
  //       setErrorMessagesChiName(undefined);
  //     }
  //   }, 1000); // 1000 milliseconds = 1 second

  //   return () => {
  //     clearTimeout(timerId); // Clear the timer if the effect is cleaned up before 1 second
  //   };
  // }, [chineseName]);
  const handleEnglishName: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const val = event.target.value;
    const formattedInput = val.replace(/\s/g, "");
    if (val.length > 0) {
      setErrorMessagesEngName("");
    } else {
      setErrorMessagesEngName(t("englishNameEmpty"));
    }
    setEnglishName(formattedInput);
  };

  const handleTypeClick = () => {
    setTypeShowPopover2(true);
  };

  const handleNationality: React.ChangeEventHandler<HTMLSelectElement> = (
    event
  ) => {
    const val = event.target.value;
    if (val.length > 0) {
      setErrorMessagesNation("");
    } else {
      setErrorMessagesNation(t("nationalityEmpty"));
    }
    setNationality(event.target.value);
  };

  const handleAge: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const value: string = event.target.value;
    const age: number = parseInt(value, 10);

    if (!isNaN(age) && age >= 18 && age <= 65) {
      setAge(age);
    } else {
      setAge(isNaN(age) ? 0 : age);
    }
  };

  const handleLanguage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;

    if (checked) {
      if (!language.includes(value)) {
        setLanguage((prevLanguages) => [...prevLanguages, value]);
      }
    } else {
      setLanguage((prevLanguages) =>
        prevLanguages.filter((item) => item !== value)
      );
    }
  };
  useEffect(() => {
    if (language.length === 0) {
      setErrorMessagesLang(t("languageEmpty"));
    } else {
      setErrorMessagesLang(undefined);
    }
  }, [language]);

  const handlePrice: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const price = parseInt(event.target.value);

    setPrice(isNaN(price) ? 0 : price);
  };
  const handleCurrency: React.ChangeEventHandler<HTMLSelectElement> = (
    event
  ) => {
    setCurrency(event.target.value);
  };

  // services && services types

  useEffect(() => {
    const updatedSelectedServiceType = selectedServiceType.filter((selected) =>
      serviceTypes.includes(selected)
    );

    setSelectedServiceType(updatedSelectedServiceType);
  }, []);

  useEffect(() => {
    if (selectedServiceType.length > 0) {
      getService(selectedServiceType)
        .then((res) => {
          if (res != null) {
            setServices(res);
          } else {
            console.error("Error: Service request returned null.");
          }
        })
        .catch((error) => {
          console.error("Error while fetching service:", error);
        });
    }
  }, [selectedServiceType]);

  const handleServiceType = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedServiceType([...selectedServiceType, event.target.value]);
    } else {
      setSelectedServiceType(
        selectedServiceType.filter((item: any) => item !== event.target.value)
      );
      setSelectedService(
        selectedServiceType.filter((item: any) => item !== event.target.value)
      );
    }
  };

  //  check the All master add address same as current Ad edit
  const handleSuperMasterAddress = async () => {
    const filteredAdsData = existingAdsData
      ?.map((ad: any) => {
        if (!ad.details) return null;

        const oldAdsAddress = ad.details.address;

        const addressMatch =
          oldAdsAddress.address_street === addressStreet &&
          oldAdsAddress.block === block &&
          oldAdsAddress.building === building &&
          oldAdsAddress.floorC === floorC &&
          oldAdsAddress.floorE === floorE &&
          oldAdsAddress.room === room &&
          oldAdsAddress.street_name === streetName;

        const regionMatch =
          ad.details.basic_desc.country === country &&
          ad.details.basic_desc.region1 === region1 &&
          ad.details.basic_desc.region2 === region2;

        let kg = null;
        if (!addressMatch || !regionMatch) {
          kg = {
            target: ad.user_id,
            details: {
              address: {
                street_name: streetName,
                building: building,
                address_street: addressStreet,
                room: room,
                floorC: floorC,
                floorE: floorE,
                description: description,
                block: block,
              },
              basic_desc: {
                ...ad.details.basic_desc, // Copy existing basic_desc properties
                country: country,
                region1: region1,
                region2: region2,
              },
              advance_desc: ad.details.advance_desc,
              blog_details: ad.details.blog_details,
            },
          };
        }

        return kg;
      })
      .filter((ad: any) => ad !== null); // Filter out null values

    setClientAddressNotMatchList(filteredAdsData);

    // editAdsMasterList(filteredAdsData).then((res) => {
    //   console.log("Check Address Response ", res);
    // });
    console.log("filteredAdsData", filteredAdsData.length);
    // return filteredAdsData.length
  };

  const handleService = (
    event: React.ChangeEvent<HTMLInputElement>,
    groupKey: string,
    serviceValue: string
  ) => {
    setSelectedService((prevService: any) => {
      const updatedService = { ...prevService };
      if (event.target.checked) {
        if (!updatedService[groupKey]) {
          updatedService[groupKey] = [serviceValue];
        } else if (!updatedService[groupKey].includes(serviceValue)) {
          updatedService[groupKey].push(serviceValue);
        }
      } else {
        if (updatedService[groupKey]) {
          updatedService[groupKey] = updatedService[groupKey].filter(
            (item: any) => item !== serviceValue
          );

          if (updatedService[groupKey].length === 0) {
            delete updatedService[groupKey];
          }
        }
      }
      return updatedService;
    });
  };

  const handleBlogDetails: React.ChangeEventHandler<HTMLTextAreaElement> = (
    e
  ) => {
    const val = e.target.value;

    if (val.length > 2000) return;

    setBlogDetailsCount(val.length);
    setBlogDetails(val);
  };

  const handleMondayHourClick = (params: OperatingHour) => {
    const updatedHours = { ...mondayHour, ...params };

    if (params.rest) {
      updatedHours.open_hours = "";
      updatedHours.close_hours = "";
    } else {
      updatedHours.open_hours = "00:00";
      updatedHours.close_hours = "01:00";
    }

    setMondayHour(updatedHours);
  };
  const handleTuesdayHourClick = (params: OperatingHour) => {
    const updatedHours = { ...tuesdayHour, ...params };

    if (params.rest) {
      updatedHours.open_hours = "";
      updatedHours.close_hours = "";
    } else {
      updatedHours.open_hours = "00:00";
      updatedHours.close_hours = "01:00";
    }

    setTuesdayHour(updatedHours);
  };

  const handleWednesdayHourClick = (params: OperatingHour) => {
    const updatedHours = { ...wednesdayHour, ...params };

    if (params.rest) {
      updatedHours.open_hours = "";
      updatedHours.close_hours = "";
    } else {
      updatedHours.open_hours = "00:00";
      updatedHours.close_hours = "01:00";
    }

    setWednesdayHour(updatedHours);
  };

  const handleThursdayHourClick = (params: OperatingHour) => {
    const updatedHours = { ...thursdayHour, ...params };

    if (params.rest) {
      updatedHours.open_hours = "";
      updatedHours.close_hours = "";
    } else {
      updatedHours.open_hours = "00:00";
      updatedHours.close_hours = "01:00";
    }

    setThursdayHour(updatedHours);
  };

  const handleFridayHourClick = (params: OperatingHour) => {
    const updatedHours = { ...fridayHour, ...params };

    if (params.rest) {
      updatedHours.open_hours = "";
      updatedHours.close_hours = "";
    } else {
      updatedHours.open_hours = "00:00";
      updatedHours.close_hours = "01:00";
    }

    setFridayHour(updatedHours);
  };
  const handleSaturdayHourClick = (params: OperatingHour) => {
    const updatedHours = { ...saturdayHour, ...params };

    if (params.rest) {
      updatedHours.open_hours = "";
      updatedHours.close_hours = "";
    } else {
      updatedHours.open_hours = "00:00";
      updatedHours.close_hours = "01:00";
    }

    setSaturdayHour(updatedHours);
  };
  const handleSundayHourClick = (params: OperatingHour) => {
    const updatedHours = { ...sundayHour, ...params };

    if (params.rest) {
      updatedHours.open_hours = "";
      updatedHours.close_hours = "";
    } else {
      updatedHours.open_hours = "00:00";
      updatedHours.close_hours = "01:00";
    }

    setSundayHour(updatedHours);
  };

  const handleHeight = (params: BodyDimension) => {
    const { size, conversion } = height;

    let updatedSize: number;

    if (params.size !== undefined && !isNaN(params.size)) {
      updatedSize = params.size;
    } else {
      updatedSize = size || 0;
    }

    if (conversion === "inch" && params.conversion === "cm") {
      updatedSize = parseFloat((updatedSize * 2.54).toFixed(2));
    } else if (conversion === "cm" && params.conversion === "inch") {
      updatedSize = parseFloat((updatedSize / 2.54).toFixed(2));
    }

    setHeight({
      ...height,
      ...params,
      size: updatedSize,
    });
  };

  const handleHip = (params: BodyDimension) => {
    const { size, conversion } = hip;

    let updatedSize: number;

    if (params.size !== undefined && !isNaN(params.size)) {
      updatedSize = params.size;
    } else {
      updatedSize = size || 0;
    }

    if (conversion === "inch" && params.conversion === "cm") {
      updatedSize = parseFloat((updatedSize * 2.54).toFixed(2));
    } else if (conversion === "cm" && params.conversion === "inch") {
      updatedSize = parseFloat((updatedSize / 2.54).toFixed(2));
    }

    setHip({
      ...hip,
      ...params,
      size: updatedSize,
    });
  };

  const handleCupSize = (params: BodyDimension) => {
    const { size, conversion } = cup;
    let updatedSize: number;

    if (params.size !== undefined && !isNaN(params.size)) {
      updatedSize = params.size;
    } else {
      updatedSize = size || 0;
    }

    setCup({
      ...cup,
      ...params,
      size: updatedSize,
    });
  };

  const handleWaist = (params: BodyDimension) => {
    const { size, conversion } = waist;

    let updatedSize: number;

    if (params.size !== undefined && !isNaN(params.size)) {
      updatedSize = params.size;
    } else {
      updatedSize = size || 0;
    }

    if (conversion === "inch" && params.conversion === "cm") {
      updatedSize = parseFloat((updatedSize * 2.54).toFixed(2));
    } else if (conversion === "cm" && params.conversion === "inch") {
      updatedSize = parseFloat((updatedSize / 2.54).toFixed(2));
    }

    setWaist({
      ...waist,
      ...params,
      size: updatedSize,
    });
  };

  const handlePasswordFill = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsPasswordFill(event.target.checked);
    if (!event.target.checked) {
      setPassword("");
    }
  };
  const handleBlog = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      if (ad !== 1) {
        setIsBlogAdvertisementModalOpen(true);
        return;
      }
      setIsBlogModalOpen(true);
      return;
    }
    setBlog(event.target.checked ? 1 : 0);
  };

  useEffect(() => {
    if (ad === 0) {
      setBlog(0);
    }
  }, [ad]);

  useEffect(() => {
    // if (country) console.log("Gfdg country ", country);

    getRegion1s(country).then((e) => {
      // console.log("Region 1 ", e);

      // if (Array.isArray(e) && typeof e[0] === "string") {
      //   setRegion1s(["", ...e]);
      // }
      setRegion1s(e);
    });
  }, [country]);

  useEffect(() => {
    // if (region1) console.log("region1  f", region1);

    getRegion2s(region1).then((e) => {
      // console.log("region 1 ", e);

      setRegion2s(e);
    });
  }, [region1]);

  // Addresses
  const handleCountry: React.ChangeEventHandler<HTMLSelectElement> = (
    event
  ) => {
    const val = event.target.value;
    if (val.length > 0) {
      setErrorMessagesCountry("");
    } else {
      setErrorMessagesCountry(t("countryEmpty"));
    }
    setCountry(event.target.value);
  };
  useEffect(() => {
    if (
      country.length === 0 ||
      country == "" ||
      country === "Please select country"
    ) {
      setErrorMessagesCountry(t("countryEmpty"));
    } else {
      setErrorMessagesCountry(undefined);
    }
  }, [country]);
  const handleRegion1: React.ChangeEventHandler<HTMLSelectElement> = (
    event
  ) => {
    const val = event.target.value;
    if (val.length > 0) {
      setErrorMessagesRegion1("");
    } else {
      setErrorMessagesRegion1(t("region1Empty"));
    }
    setRegion1(event.target.value);
  };
  useEffect(() => {
    if (region1.length === 0 || region1 == "") {
      setErrorMessagesRegion1(t("region1Empty"));
    } else {
      setErrorMessagesRegion1(undefined);
    }
  }, [region1]);

  const handleRegion2: React.ChangeEventHandler<HTMLSelectElement> = (
    event
  ) => {
    const val = event.target.value;
    // if (val.length > 0) {
    //   setErrorMessagesRegion2("");
    // } else {
    //   setErrorMessagesRegion2("Please select Region 2!");
    // }
    // setRegion2(event.target.value);
    setRegion2(val);
  };
  useEffect(() => {
    if (region2.length === 0) {
      setErrorMessagesRegion2(t("region2Empty"));
    } else {
      setErrorMessagesRegion2(undefined);
    }
  }, [region2]);

  const handleStreetName: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const inputValue = event.target.value;
    // const numericValue = inputValue.replace(/[^0-9]/g, "");
    setStreetName(inputValue);
  };
  const handleAddressStreet: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setAddressStreet(event.target.value);
  };
  const handleBuilding: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setBuilding(event.target.value);
  };
  const handleRoom: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setRoom(event.target.value);
  };
  const handleFloorC: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setFloorC(event.target.value);
  };
  const handleBlock: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setBlock(event.target.value);
  };
  const handleFloorE: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setFloorE(event.target.value);
  };
  const handleDescription: React.ChangeEventHandler<HTMLTextAreaElement> = (
    event
  ) => {
    setDescription(event.target.value);
  };
  const handleMobile: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const val = event.target.value;
    if (val.length > 5) {
      setErrorMessagesPhone("");
    } else if (val.length < 6 && val.length > 0) {
      setErrorMessagesPhone(t("phoneValidEmpty"));
    } else {
      setErrorMessagesPhone(t("phoneEmpty"));
    }
    setMobileNumber(event.target.value.replace(/[^\d]/g, ""));
  };

  useEffect(() => {
    if (mobileNumber.length === 0 || mobileNumber == "") {
      setErrorMessagesPhone(t("phoneEmpty"));
    } else {
      setErrorMessagesPhone(undefined);
    }
    if (telegram.length === 0 || telegram == "") {
      setErrorMessagesTelegram(t("telegramErrorMsg1"));
    } else {
      setErrorMessagesTelegram("");
    }
  }, [telegram, mobileNumber]);

  const handleTelegram: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const val = event.target.value;
    if (val.length > 3) {
      setErrorMessagesTelegram("");
    } else if (val.length < 4 && val.length > 0) {
      setErrorMessagesTelegram(t("telegramErrorMsg"));
    } else {
      setErrorMessagesTelegram(t("telegramErrorMsg1"));
    }
    setTelegram(event.target.value);
  };

  const handleSelfDescription: React.ChangeEventHandler<HTMLTextAreaElement> = (
    event
  ) => {
    setSelfDescription(event.target.value);
  };

  const handlePrefixChange: React.ChangeEventHandler<HTMLSelectElement> = (
    event
  ) => {
    setSelectedPrefix(event.target.value);
  };

  const handleInput = (event: React.FormEvent<HTMLTextAreaElement>) => {
    const inputText = event.currentTarget.value;
    if (inputText.length <= 139) {
      setSelfDescription(inputText);
    }
  };
  const handleInputDescription = (
    event: React.FormEvent<HTMLTextAreaElement>
  ) => {
    const inputText = event.currentTarget.value;
    if (inputText.length <= 49) {
      setDescription(inputText);
    }
  };

  type OperatingHours = {
    [day: string]: string[];
  };

  const checkRestMonday = () => mondayHour.rest;

  const checkRestTuesday = () => tuesdayHour.rest;

  const checkRestWednesday = () => wednesdayHour.rest;

  const checkRestThursday = () => thursdayHour.rest;

  const checkRestFriday = () => fridayHour.rest;

  const checkRestSaturday = () => saturdayHour.rest;

  const checkRestSunday = () => sundayHour.rest;

  const [mondayHour1, setMondayHour1] = useState([
    mondayHour.open_hours,
    mondayHour.close_hours,
  ]);
  const [tuesdayHour1, setTuesdayHour1] = useState([
    tuesdayHour.open_hours,
    tuesdayHour.close_hours,
  ]);
  const [wednesdayHour1, setWednesdayHour1] = useState([
    wednesdayHour.open_hours,
    wednesdayHour.close_hours,
  ]);
  const [thursdayHour1, setThursdayHour1] = useState([
    thursdayHour.open_hours,
    thursdayHour.close_hours,
  ]);
  const [fridayHour1, setFridayHour1] = useState([
    fridayHour.open_hours,
    fridayHour.close_hours,
  ]);
  const [saturdayHour1, setSaturdayHour1] = useState([
    saturdayHour.open_hours,
    saturdayHour.close_hours,
  ]);
  const [sundayHour1, setSundayHour1] = useState([
    sundayHour.open_hours,
    sundayHour.close_hours,
  ]);
  useEffect(() => {
    setMondayHour1([mondayHour.open_hours, mondayHour.close_hours]);
    setTuesdayHour1([tuesdayHour.open_hours, tuesdayHour.close_hours]);
    setWednesdayHour1([wednesdayHour.open_hours, wednesdayHour.close_hours]);
    setThursdayHour1([thursdayHour.open_hours, thursdayHour.close_hours]);
    setFridayHour1([fridayHour.open_hours, fridayHour.close_hours]);
    setSaturdayHour1([saturdayHour.open_hours, saturdayHour.close_hours]);
    setSundayHour1([sundayHour.open_hours, sundayHour.close_hours]);
  }, [
    mondayHour,
    tuesdayHour,
    wednesdayHour,
    thursdayHour,
    fridayHour,
    saturdayHour,
    sundayHour,
  ]);

  useEffect(() => {
    if (masterType && masterType.includes("SuperMaster")) {
      handleSuperMasterAddress();
    }
  }, [
    country,
    region1,
    region2,
    addressStreet,
    streetName,
    building,
    block,
    floorC,
    floorE,
  ]);
  const handleNext: React.MouseEventHandler<HTMLButtonElement> = async (
    event
  ) => {
    event.preventDefault();

    const createUserParams = CreateUserParams.getInstance();
    createUserParams.data = {
      ...createUserParams.data,
      country_phone_code: selectedPrefix,
      phone_number: mobileNumber,
      en_name: englishName,
      zh_name: chineseName,
      language: Object.assign({}, [undefined, ...language]),
      details: {
        basic_desc: {
          age: age,
          nationality: nationality,
          price: price,
          currency: currency,
          type: type,
          operating_hour: {
            Monday: mondayHour1,
            Tuesday: tuesdayHour1,
            Wednesday: wednesdayHour1,
            Thursday: thursdayHour1,
            Friday: fridayHour1,
            Saturday: saturdayHour1,
            Sunday: sundayHour1,
          },
          country: country,
          region1: region1,
          region2: region2,
          need_caller_display: needCaller,
          book_in_advance: pleaseBooking,
          call_to_open_door: callToOpen,
          self_desc: selfDescription,
          welcome_foreigner: foreginer,
          welcome_pd: PD,
          language: language,
          height: String(height.size) + String(height.conversion),
          phone: mobileNumber,
          country_phone_code: selectedPrefix,
          special_cat: specialCats,
        },
        advance_desc: {
          c_size: advanceDesc.c_size,
          c_letter: "C",
          w_size: advanceDesc.w_size,
          h_size: advanceDesc.h_size,
          service_type: selectedServiceType,
          services: selectedService,
        },
        address: {
          street_name: streetName,
          building: building,
          address_street: addressStreet,
          room: room,
          floorC: floorC,
          floorE: floorE,
          description: description,
          block: block,
        },
        blog_details: {
          basic_desc: blogDetails,
        },
        // contact_info: {
        //   phone_number_country_code: selectedPrefix,
        //   phone_number: mobileNumber,
        // },

        body_dimension: [height, cup, waist, hip],
      },
    };

    if (isMaster && params.id) {
      await editAddtionalInfo({
        telegram: telegram,
        target: Number(params.id),
      });
    } else {
      await editAddtionalInfo({ telegram: telegram });
    }

    if (isMaster) {
      // New Added

      const getCountryCode = phoneMaster?.substring(0, 2);
      const getPhoneNumbers = phoneMaster?.substring(2);
      createUserParams.data = {
        ...createUserParams.data,
        password: password,
        ad: ad,
        blog: blog,
        ext: ext,
        country_phone_code: getCountryCode,
        phone_number: getPhoneNumbers,
      };
    }

    createUserParams.saveData();

    setIsLoading(true);
    setIsLoadingScreenStep(true);

    try {
      var isRegisterSuccessOrId: boolean | number | null = null;
      if (isMaster) {
        if (params.id) {
          isRegisterSuccessOrId = await editAdsMaster(
            params.id!,
            // convertCreateUserParamsToMastersAd(
            createUserParams.data
            // ext,
            // phoneMaster ?? ""
            // )
          );
          // setTimeout(() => {
          //   controlAdClient(ad, Number(params.id));
          //   setTimeout(() => {
          //     controlBlogClient(blog, Number(params.id));
          //   }, 500);
          // }, 500);
        }
        console.log("Edit Master Ad");
        if (masterType && masterType.includes("SuperMaster")) {
          let result = masterType?.includes("SuperMaster");
          console.log("masterType result Edit ", result);
          if (clientAddressNotMatchList.length > 0) {
            editAdsMasterList(clientAddressNotMatchList).then((res) => {
              console.log("Check Address Response On  SuperMaster Edit", res);
            });
          }
        }
      } else {
        isRegisterSuccessOrId = await updateUser(createUserParams);
      }
      // const isRegisterSuccessOrId = isMaster ?
      //   params.id ?
      //     await editAdsMaster(params.id!, convertCreateUserParamsToMastersAd(createUserParams.data, ext, phoneMaster ?? "")) :
      //       await createAdsMaster(createUserParams.data) :
      //         await updateUser(createUserParams);

      // if (isRegisterSuccess != null || isRegisterSuccess !== false) {
      if (isRegisterSuccessOrId) {
        isMaster
          ? navigate(
              `${AppRoutePath.masterEditIndividualAdDetails.replace(
                ":id",
                typeof isRegisterSuccessOrId === "number"
                  ? String(isRegisterSuccessOrId)
                  : params.id ?? ""
              )}`
            )
          : navigate(AppRoutePath.clientEditIndividualAdDetails);
      }
    } catch (e) {
      console.log(e);
      new Snackbar("Something went wrong");
    }

    setIsLoading(false);
    setIsLoadingScreenStep(false);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleTypeDetails = () => {
    setIsTypeDetailsOpen(true);
    // alert("testing")
  };
  //  Service type data

  // const optionData = serviceTypes.map((option) => ({
  //   value: option,
  //   label: option,
  // }));
  // select option type
  // Get the select element
  // const selectElement = document.getElementById(
  //   "typeSelectOPtion"
  // ) as HTMLSelectElement;

  // // Add an event listener to detect changes
  // selectElement.addEventListener("change", function () {
  //   // Check if it's the first time the option is changed
  //   if (!this.dataset.changed) {
  //     // Show the message
  //     const messageElement = document.getElementById(
  //       "message"
  //     ) as HTMLDivElement;
  //     messageElement.textContent = "Option changed for the first time!";

  //     // Set the 'changed' attribute to mark that the option has been changed
  //     this.dataset.changed = "true";
  //   }
  // });

  return (
    <>
      <CustomHeader
      // backRoute={clientProfile || isEdit ? -1 : null}
      // publics={true}
      />

      <TopHearder
        title={
          isMaster
            ? params.id
              ? t("editClientDetails")
              : isMastersClientEmpty
              ? t("editClientDetails")
              : t("editFirstClientDetails")
            : t("editDetails")
        }
      />
      {isLoadingScreenStep && <ScreenLoader2 />}

      {isLoadingScreen ? (
        <ScreenLoaders />
      ) : (
        <>
          <div className="bg-dark">
            <div className="container pb-5">
              {/* <div className="inner-box"> */}
              <div className="accordion pt-3" id="accordionExample">
                {isRegister && <RegistrationSteps step={3} />}
              </div>

              <div className="tab-content">
                <div id="headingOne"></div>

                <div
                  id="collapseOne"
                  className="collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                ></div>
              </div>
              <div className="tab-content">
                <div id="headingTwo"></div>
                <div
                  id="collapseTwo"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                ></div>
              </div>
              <div className="tab-content">
                <div id="headingThree"></div>
                <div
                  id="collapseThree"
                  className="collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="card-body">your content goes here...</div>
                </div>
              </div>
              <div className="tab-content">
                <div id="headingFour"></div>
                <div
                  id="collapseFour"
                  className="colla pse show"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  {isMaster && (
                    <>
                      <label htmlFor="" className="label mb-4">
                        {shopMasterName}
                      </label>
                      <div className="row justify-content-between mb-3">
                        <div className="col-5">
                          <label htmlFor="" className="label">
                            +{phoneMaster}
                            {/* +85 212 3456 7788 */}
                          </label>
                        </div>
                        <div className="col-7 text-end">
                          <OverlayTrigger
                            placement="top"
                            trigger="click"
                            show={activePopover === "extInfo"}
                            onToggle={() => handlePopoverToggle("extInfo")}
                            overlay={
                              <Popover>
                                <button
                                  type="button"
                                  className="close-popover"
                                  onClick={() => handlePopoverToggle("extInfo")}
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                                <PopoverBody>{t("extInfo")}</PopoverBody>
                              </Popover>
                            }
                          >
                            <button type="button" className="exc-btn">
                              <i className="fa-solid fa-exclamation" />
                            </button>
                          </OverlayTrigger>
                          <label htmlFor="" className="label pe-2">
                            EXT
                          </label>
                          <input
                            readOnly={true}
                            type="text"
                            value={ext}
                            onChange={handleExt}
                            placeholder={"1"}
                            className="ext-input d-input "
                          />
                        </div>
                      </div>
                      <div className="row justify-content-between mb-3 d-none">
                        <div className="col-7">
                          <label htmlFor="" className="label">
                            Create New Password
                          </label>
                          <OverlayTrigger
                            placement="top"
                            trigger="click"
                            show={activePopover === "passwordInfo"}
                            onToggle={() => handlePopoverToggle("passwordInfo")}
                            overlay={
                              <Popover>
                                <button
                                  type="button"
                                  className="close-popover"
                                  onClick={() =>
                                    handlePopoverToggle("passwordInfo")
                                  }
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                                <PopoverBody>{t("passwordInfo")}</PopoverBody>
                              </Popover>
                            }
                          >
                            <button type="button" className="exc-btn">
                              <i className="fa-solid fa-exclamation" />
                            </button>
                          </OverlayTrigger>
                          <input
                            type="checkbox"
                            checked={isPasswordFill}
                            onChange={handlePasswordFill}
                          />
                        </div>
                        <div className="col-lg-2 col-4 mb-3">
                          <input
                            readOnly={!isPasswordFill}
                            type={showPassword ? "text" : "password"}
                            value={password}
                            onChange={handlePassword}
                            className="d-input input-md w-100"
                          />
                        </div>
                        <div className="col-1 pad-left-0">
                          <i
                            className={`fa fa-eye primary-color eye-icon color-pink${
                              showPassword ? "" : " icon fa-eye-slash"
                            }`}
                            onClick={handlePasswordVisibility}
                            id="togglePassword"
                          ></i>
                        </div>
                      </div>
                      {/* <div className="ac-details">
                    <div className="row mb-3">
                      <div className="col-12">
                        <label htmlFor="" className="label">
                          Advertisement
                        </label>
                        <div className="col-6 text-end">
                          <div className="on-off-btn">
                            <button
                              className={
                                ad === 1
                                  ? "btn primary-button rounded-button"
                                  : "btn primary-button rounded-button btn3"
                              }
                              onClick={() => setAd(1)}
                            >
                              On
                            </button>
                            <button
                              className={
                                ad === 0
                                  ? "btn primary-button rounded-button"
                                  : "btn primary-button rounded-button btn3"
                              }
                              onClick={() => setAd(0)}
                            >
                              Off
                            </button>
                            <button
                              className={
                                ad === 2
                                  ? "btn primary-button rounded-button"
                                  : "btn primary-button rounded-button btn3"
                              }
                              onClick={() => setAd(2)}
                            >
                              Rest
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <label htmlFor="" className="label">
                          Blog
                        </label>
                      </div>
                      <div className="col-6 text-end">
                        <label className="switch bd">
                          <input
                            type="checkbox"
                            checked={blog === 1}
                            onChange={handleBlog}
                          />
                          <span className="slider round">OFF ON</span>
                        </label>
                      </div>
                      <div className="col-12">
                        <h6>
                          If you want to have your Brand Name &amp; Banner with
                          Following List for you to chat with your followers,
                          please turn on your Master account blog, But once you
                          turn it on all your client blog will turn on.
                        </h6>
                        <div className="mb-3 bd-dtl">
                          <label htmlFor="" className="label mb-2">
                            Blog Details
                          </label>
                          <div className="post-box mb-3">
                            <textarea
                              name=""
                              id="text"
                              cols={30}
                              rows={7}
                              className=" ht-100 "
                              defaultValue={""}
                            />
                            <span
                              className="pull-right label label-default"
                              id="count_message"
                            >
                              0 / 200
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                    </>
                  )}
                  <label htmlFor="" className="form-title1 mb-4">
                    {t("personalInfo")}
                  </label>

                  <div className="row mb-3">
                    <div className="col-lg-2 col-4 mb-3">
                      <label htmlFor="" className="label">
                        {t("chineseName")} <span className="re-star">*</span>
                      </label>{" "}
                    </div>
                    <div className="col-lg-4 col-8 mb-3">
                      {" "}
                      <input
                        type="text"
                        value={chineseName}
                        onChange={handleChineseName}
                        className="form-control form-control-cus"
                        required
                        maxLength={15}
                      />
                      <div className="text-red-add-details">
                        {errorMessagesChiName}
                      </div>
                    </div>

                    <div className="col-lg-2 col-4 mb-3">
                      {" "}
                      <label htmlFor="" className="label">
                        {t("englishName")} <span className="re-star">*</span>
                      </label>
                    </div>
                    <div className="col-lg-4 col-8 mb-3">
                      <input
                        type="text"
                        value={englishName}
                        onChange={handleEnglishName}
                        className="form-control form-control-cus"
                        required
                        maxLength={15}
                      />
                      <div className="text-red-add-details">
                        {errorMessagesEngName}
                      </div>
                    </div>

                    <div className="col-lg-2 col-4 mb-3">
                      <label htmlFor="" className="label">
                        {t("Type")} <span className="re-star">*</span>
                      </label>

                      {/* <OverlayTrigger
                    placement="top"
                    trigger="click"
                    show={TypeShowPopover}
                    onToggle={setTypeShowPopover}
                    overlay={
                      <Popover>
                        {TypeShowPopover && (
                          <button type="button" className="close-popover" onClick={handleTypeShowPopover}>
                            <span aria-hidden="true">&times;</span>
                          </button>
                        )}
                        <PopoverBody>
                          H - only for HK/China F - other than HK & China
                        </PopoverBody>
                      </Popover>
                    }
                  >
                    <button type="button" className="exc-btn mx-2">
                      <i className="fa-solid fa-exclamation"></i>
                    </button>
                  </OverlayTrigger> */}
                    </div>
                    <div className="col-lg-4 col-8 mb-3">
                      <OverlayTrigger
                        placement="top"
                        trigger="click"
                        show={TypeShowPopover2}
                        onToggle={setTypeShowPopover2}
                        overlay={
                          <Popover>
                            {TypeShowPopover2 && (
                              <button
                                type="button"
                                className="close-popover"
                                onClick={handleTypeShowPopover2}
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            )}
                            <PopoverBody>{t("typeInfo")}</PopoverBody>
                          </Popover>
                        }
                      >
                        <div
                          onClick={handleTypeClick}
                          style={{ cursor: "pointer" }}
                        >
                          <select
                            ref={typeCheck}
                            // readOnly
                            disabled={true}
                            className="form-select input-md w-100 readonly"
                            id="typeSelectOption"
                            value={type}
                            // onChange={handleType}
                          >
                            {/* {types.map((val, i) => {
                          return (
                            <>
                              <option value={val} key={i}>
                             
                                {val ? val : "Please select type"}
                              </option>
                            </>
                          );
                        })} */}
                            {/* <option value="" disabled selected>
                     {type}
                        </option> */}

                            <option
                              value={clientProfile?.details?.basic_desc?.type}
                            >
                              {langType === "zh"
                                ? clientProfile?.details?.basic_desc?.type?.split(
                                    "|"
                                  )[1]
                                : clientProfile?.details?.basic_desc?.type?.split(
                                    "|"
                                  )[0]}
                            </option>
                            {/* {Object.entries(types).map(([key, value]) => {
                          const [englishName, chineseName] = value.split("|");
                          return (
                            <option value={value}>
                              {" "}
                              {langType === "zh" ? chineseName : englishName}
                            </option>
                          );
                        })} */}
                          </select>
                        </div>
                        {/* <span style={{ fontSize: 12, color: "#42f593" }}>{t('typePopUp')}</span> */}
                        {/* <a className="learn-more" onClick={handleTypeDetails}>
                    Click here to learn more{" "}
                  </a> */}
                      </OverlayTrigger>
                      <span style={{ fontSize: 12, color: "#42f593" }}>
                        {t("typePopUp")}
                      </span>
                    </div>
                    {/* <div className="col-lg-6 col-4  mb-3">
                  <a className="learn-more" onClick={handleTypeDetails}>
                    Click here to learn more{" "}
                  </a>
                </div> */}

                    <div className="col-lg-2 col-4 mb-3">
                      <label htmlFor="" className="label">
                        {t("nationality")} <span className="re-star">*</span>
                      </label>
                      <OverlayTrigger
                        placement="top"
                        trigger="click"
                        show={activePopover === "countryPopUp"}
                        onToggle={() => handlePopoverToggle("countryPopUp")}
                        overlay={
                          <Popover>
                            <button
                              type="button"
                              className="close-popover"
                              onClick={() =>
                                handlePopoverToggle("countryPopUp")
                              }
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>

                            <PopoverBody>{t("countryPopUp")}</PopoverBody>
                          </Popover>
                        }
                      >
                        <button type="button" className="exc-btn mx-2">
                          <i className="fa-solid fa-exclamation"></i>
                        </button>
                      </OverlayTrigger>
                    </div>
                    <div className="col-lg-4 col-4 mb-3">
                      <select
                        className="form-select input-md"
                        value={nationality}
                        onChange={handleNationality}
                        required
                      >
                        {/* {nationalities.length < 1 ? (
                      <option value="">Please select type...</option>
                    ) : (
                      nationalities.map((e, i) => (
                        <option key={i + 1} value={e}>
                          {e ? e : "Please select nationality"}
                        </option>
                      ))
                    )} */}

                        <option>
                          {langType === "zh"
                            ? clientProfile?.details?.basic_desc?.nationality?.split(
                                "|"
                              )[1]
                            : clientProfile?.details?.basic_desc?.nationality?.split(
                                "|"
                              )[0]}
                        </option>
                        {Object.entries(nationalities)
                          .filter(
                            ([key, value]) =>
                              value !==
                              clientProfile?.details?.basic_desc?.nationality
                          )
                          .map(([key, value]) => {
                            const [englishName, chineseName] = value.split("|");
                            return (
                              <option key={key} value={value}>
                                {langType === "zh" ? chineseName : englishName}
                              </option>
                            );
                          })}
                      </select>
                      <div className="text-red-add-details">
                        {errorMessagesNation}
                      </div>
                    </div>
                    <div className=" col-lg-2 col-1 ">
                      <label htmlFor="" className="label">
                        {t("age")}
                      </label>
                    </div>
                    <div className=" col-lg-4   col-3">
                      <input
                        type="text"
                        value={age}
                        onChange={handleAge}
                        className="form-control form-control-cus"
                        maxLength={2}
                      />
                      <div className="text-red-add-details">
                        {age > 0 && age < 18 && <>{t("ageRange")}</>}
                      </div>
                    </div>

                    <div className="col-lg-2 col-4 mb-3">
                      <label htmlFor="" className="label">
                        {t("price")}
                      </label>
                    </div>
                    <div className=" col-lg-2 col-5">
                      <input
                        type="text"
                        value={price}
                        onChange={handlePrice}
                        className="form-control form-control-cus "
                      />
                    </div>
                    <div className="col-lg-2 col-3">
                      <select
                        className=" form-select "
                        value={currency}
                        onChange={handleCurrency}
                      >
                        {/* {currencies.map((e, i) => (
                      <option key={i + 1} value={e}>
                        {e}
                      </option>
                    ))} */}

                        {Object.entries(currencies).map(([key, value]) => {
                          const [englishName, chineseName] = value.split("|");
                          return (
                            <option key={key} value={value}>
                              {langType === "zh" ? chineseName : englishName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  <label htmlFor="" className="label mb-3">
                    {t("language")} <span className="re-star">*</span>
                  </label>
                  <div className="row gx-3 gy-1 ">
                    {/* {languages.map((e, i) => (
                     */}

                    {Object.entries(languages).map(([key, value]) => {
                      const [englishName, chineseName] = value.split("|");

                      return (
                        <>
                          <div className="col-lg-auto col-4  " key={key}>
                            <div className="bg_p1 mb-3 text-white pe-lg-3  ">
                              <div className="form-check  form-check-custom ">
                                <input
                                  className="form-check-input mt-md-2 mt-lg-2"
                                  type="checkbox"
                                  value={value}
                                  id={`service-${value}`}
                                  onChange={handleLanguage}
                                  // checked={language.indexOf(e) > -1}
                                  checked={language.includes(value)}
                                />
                                <label
                                  htmlFor={`service-${value}`}
                                  className="form-check-label"
                                >
                                  {langType === "zh"
                                    ? chineseName
                                    : englishName}
                                </label>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>

                  <div className="text-red-add-details pad-bot-10">
                    {errorMessagesLang}
                  </div>
                  <div className="row gx-3 gy-1 mb-3">
                    <label htmlFor="" className="label mb-4">
                      {t("serviceType")}
                    </label>
                    {Object.entries(serviceTypes).map(([key, value]) => {
                      const [englishName, chineseName] = value.split("|");
                      return (
                        <>
                          <div className="col-lg-auto col-4 " key={key}>
                            <div className="bg_p1 mb-3 text-white pe-lg-3 ">
                              <div className="form-check  form-check-custom">
                                <input
                                  className="form-check-input  mt-md-2 mt-lg-2"
                                  type="checkbox"
                                  value={value}
                                  id={`service-${value}`}
                                  onChange={handleServiceType}
                                  checked={selectedServiceType.includes(value)}
                                />
                                <label
                                  htmlFor={`service-${key}`}
                                  className="form-check-label"
                                >
                                  {langType === "zh"
                                    ? chineseName
                                    : englishName}
                                </label>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>

                  {services &&
                    selectedServiceType.length > 0 &&
                    Object.entries(services).map(([groupKey, servicedata]) => {
                      const numericGroupKey = Object.keys(servicedata);
                      return (
                        <div className="row" key={groupKey}>
                          <div className="col-12">
                            {selectedServiceType.length > 0 && (
                              <>
                                <label htmlFor="" className="label mb-4">
                                  {t("Service")} {numericGroupKey}
                                </label>
                              </>
                            )}
                          </div>
                          {Object.entries(servicedata).map(
                            ([key, serviceArray]) => {
                              // Check if the serviceArray is an array
                              if (Array.isArray(serviceArray)) {
                                return serviceArray.map(
                                  (serviceTypess, index) => {
                                    const [englishName, chineseName] =
                                      serviceTypess.split("|");
                                    const isChecked =
                                      selectedService[key as any] &&
                                      selectedService[key as any].includes(
                                        serviceTypess
                                      );

                                    return (
                                      <div
                                        className="col-lg-auto col-auto"
                                        key={index}
                                      >
                                        <div className="bg_p1 w-100 mb-3 text-white pe-3">
                                          <div className="form-check form-check-custom">
                                            <input
                                              className="form-check-input  mt-md-2 mt-lg-2"
                                              type="checkbox"
                                              id={`services-${key}-${index}`}
                                              value={serviceTypess}
                                              checked={isChecked as any}
                                              onChange={(event) =>
                                                handleService(
                                                  event,
                                                  key,
                                                  serviceTypess
                                                )
                                              }
                                            />
                                            <label
                                              htmlFor={`services-${key}-${index}`}
                                              className="form-check-label"
                                            >
                                              {langType === "zh"
                                                ? chineseName
                                                : englishName}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                );
                              } else {
                                // Handle the case where serviceArray is not an array
                                return null;
                              }
                            }
                          )}
                        </div>
                      );
                    })}

                  <label htmlFor="" className="form-title1 mb-4">
                    {t("operatingHour")}
                  </label>

                  <div className="row  oh-date">
                    <div className=" col-lg-2 col-2 rest-div">
                      <input
                        type="reset"
                        name="reset"
                        className={
                          !checkRestMonday()
                            ? "d-input input-md light-button w-100"
                            : "d-input input-md w-100"
                        }
                        value={t("rest")}
                        checked={checkRestMonday()}
                        // onClick={() => handleMondayHourClick}

                        onClick={() =>
                          handleMondayHourClick({
                            rest: !(mondayHour.rest ?? false),
                          })
                        }
                      />
                    </div>
                    <div className="col-lg-2 col-4 mb-3">
                      <input
                        type="text"
                        className={
                          !checkRestMonday()
                            ? "d-input2 input-md w-100"
                            : "d-input2 input-md light-button w-100"
                        }
                        value={t("monday")}
                        disabled={mondayHour.rest}
                        readOnly
                        style={{
                          lineHeight: 0,
                          backgroundColor: "#ff3751",
                          color: "white",
                        }}
                      />
                    </div>
                    <div className="col-6">
                      <div className="row g-0">
                        <div className="col-5">
                          <select
                            className={
                              !checkRestMonday()
                                ? "form-select "
                                : "form-select "
                            }
                            disabled={checkRestMonday()}
                            value={mondayHour?.open_hours}
                            // onChange={(event) => {
                            //     handleMondayHourClick({
                            //       open_hours: event.target.value,
                            //     });
                            // }}
                            onChange={(event) =>
                              handleDayHoursChange("Monday", event)
                            }
                          >
                            {openHourSelect}
                          </select>
                        </div>
                        <div className="col-2 text-center">
                          <label htmlFor="" className="label">
                            {t("to")}
                          </label>
                        </div>
                        <div className="col-5">
                          <select
                            className={
                              !checkRestMonday()
                                ? "form-select "
                                : "form-select "
                            }
                            disabled={checkRestMonday()}
                            value={mondayHour.close_hours}
                            // onChange={(event) => {
                            //   // if (event.target.value < mondayHour.open_hours) {
                            //   //   new Snackbar(
                            //   //     `Closing Hour cannot be less than Open Hour`
                            //   //   );
                            //   // } else {
                            //   // handleMondayHourClick({
                            //   //   close_hours: event.target.value,
                            //   // });
                            //   // }
                            // }}

                            onChange={handleCloseHoursChangeMonday}
                          >
                            {generateClosingHoursOptions(mondayHour)}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row  oh-date">
                    <div className="col-2 rest-div">
                      <input
                        type="reset"
                        name="reset"
                        className={
                          !checkRestTuesday()
                            ? "d-input input-md light-button w-100"
                            : "d-input input-md w-100"
                        }
                        value={t("rest")}
                        checked={checkRestTuesday()}
                        onClick={() =>
                          handleTuesdayHourClick({
                            rest: !(tuesdayHour.rest ?? false),
                          })
                        }
                      />
                    </div>
                    <div className="col-lg-2 col-4 mb-3">
                      <input
                        type="text"
                        className={
                          !checkRestTuesday()
                            ? "d-input2 input-md w-100"
                            : "d-input2 input-md light-button w-100"
                        }
                        value={t("Tuesday")}
                        disabled={tuesdayHour.rest}
                        readOnly
                        style={{
                          lineHeight: 0,
                          backgroundColor: "#ff3751",
                          color: "white",
                        }}
                      />
                    </div>
                    <div className="col-6">
                      <div className="row g-0">
                        <div className="col-5">
                          <select
                            className={
                              !checkRestTuesday()
                                ? "form-select "
                                : "form-select "
                            }
                            disabled={checkRestTuesday()}
                            value={tuesdayHour.open_hours}
                            onChange={(event) =>
                              handleDayHoursChange("Tuesday", event)
                            }
                          >
                            {openHourSelect}
                          </select>
                        </div>
                        <div className="col-2 text-center">
                          <label htmlFor="" className="label">
                            {t("to")}
                          </label>
                        </div>
                        <div className="col-5">
                          <select
                            className={
                              !checkRestTuesday()
                                ? "form-select "
                                : "form-select "
                            }
                            disabled={checkRestTuesday()}
                            value={tuesdayHour?.close_hours}
                            onChange={handleCloseHoursChangeTuesday}
                          >
                            {" "}
                            {generateClosingHoursOptions(tuesdayHour)}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row oh-date">
                    <div className="col-2 rest-div">
                      <input
                        type="reset"
                        name="reset"
                        className={
                          !checkRestWednesday()
                            ? "d-input input-md light-button w-100"
                            : "d-input input-md w-100"
                        }
                        value={t("rest")}
                        checked={checkRestWednesday()}
                        onClick={() =>
                          handleWednesdayHourClick({
                            rest: !(wednesdayHour.rest ?? false),
                          })
                        }
                      />
                    </div>
                    <div className="col-lg-2 col-4 mb-3">
                      <input
                        type="text"
                        className={
                          !checkRestWednesday()
                            ? "d-input2 input-md w-100"
                            : "d-input2 input-md light-button w-100"
                        }
                        value={t("Wednesday")}
                        disabled={wednesdayHour.rest}
                        readOnly
                        style={{
                          lineHeight: 0,
                          backgroundColor: "#ff3751",
                          color: "white",
                        }}
                      />
                    </div>
                    <div className="col-6">
                      <div className="row g-0">
                        <div className="col-5">
                          <select
                            className={
                              !checkRestWednesday()
                                ? "form-select "
                                : "form-select "
                            }
                            disabled={checkRestWednesday()}
                            value={wednesdayHour?.open_hours}
                            onChange={(event) =>
                              handleDayHoursChange("Wednesday", event)
                            }
                          >
                            {" "}
                            {openHourSelect}
                          </select>
                        </div>
                        <div className="col-2 text-center">
                          <label htmlFor="" className="label">
                            {t("to")}
                          </label>
                        </div>
                        <div className="col-5">
                          <select
                            className={
                              !checkRestWednesday()
                                ? "form-select "
                                : "form-select "
                            }
                            disabled={checkRestWednesday()}
                            value={wednesdayHour?.close_hours}
                            onChange={handleCloseHoursChangeWednesday}
                          >
                            {" "}
                            {generateClosingHoursOptions(wednesdayHour)}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row  oh-date">
                    <div className="col-2 rest-div">
                      <input
                        type="reset"
                        name="reset"
                        className={
                          !checkRestThursday()
                            ? "d-input input-md light-button w-100"
                            : "d-input input-md w-100"
                        }
                        value={t("rest")}
                        checked={checkRestThursday()}
                        onClick={() =>
                          handleThursdayHourClick({
                            rest: !(thursdayHour.rest ?? false),
                          })
                        }
                      />
                    </div>
                    <div className="col-lg-2 col-4 mb-3">
                      <input
                        type="text"
                        className={
                          !checkRestThursday()
                            ? "d-input2 input-md w-100"
                            : "d-input2 input-md light-button w-100"
                        }
                        value={t("Thursday")}
                        disabled={thursdayHour.rest}
                        readOnly
                        style={{
                          lineHeight: 0,
                          backgroundColor: "#ff3751",
                          color: "white",
                        }}
                      />
                    </div>
                    <div className="col-6">
                      <div className="row g-0">
                        <div className="col-5">
                          <select
                            className={
                              !checkRestThursday()
                                ? "form-select "
                                : "form-select "
                            }
                            disabled={checkRestThursday()}
                            value={thursdayHour?.open_hours}
                            onChange={(event) =>
                              handleDayHoursChange("Thursday", event)
                            }
                          >
                            {openHourSelect}
                          </select>
                        </div>
                        <div className="col-2 text-center">
                          <label htmlFor="" className="label">
                            {t("to")}
                          </label>
                        </div>
                        <div className="col-5">
                          <select
                            className={
                              !checkRestThursday()
                                ? "form-select "
                                : "form-select "
                            }
                            disabled={checkRestThursday()}
                            value={thursdayHour?.close_hours}
                            onChange={handleCloseHoursChangeThursday}
                          >
                            {" "}
                            {generateClosingHoursOptions(thursdayHour)}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row oh-date">
                    <div className="col-2 rest-div">
                      <input
                        type="reset"
                        name="reset"
                        className={
                          !checkRestFriday()
                            ? "d-input input-md light-button w-100"
                            : "d-input input-md w-100"
                        }
                        value={t("rest")}
                        checked={checkRestFriday()}
                        onClick={() =>
                          handleFridayHourClick({
                            rest: !(fridayHour.rest ?? false),
                          })
                        }
                      />
                    </div>
                    <div className="col-lg-2 col-4 mb-3">
                      <input
                        type="text"
                        className={
                          !checkRestFriday()
                            ? "d-input2 input-md w-100"
                            : "d-input2 input-md light-button2 w-100"
                        }
                        value={t("Friday")}
                        disabled={fridayHour.rest}
                        readOnly
                        style={{
                          lineHeight: 0,
                          backgroundColor: "#ff3751",
                          color: "white",
                        }}
                      />
                    </div>
                    <div className="col-6">
                      <div className="row g-0">
                        <div className="col-5">
                          <select
                            className={
                              !checkRestFriday()
                                ? "form-select "
                                : "form-select "
                            }
                            disabled={checkRestFriday()}
                            value={fridayHour?.open_hours}
                            onChange={(event) =>
                              handleDayHoursChange("Friday", event)
                            }
                          >
                            {openHourSelect}
                          </select>
                        </div>
                        <div className="col-2 text-center">
                          <label htmlFor="" className="label">
                            {t("to")}
                          </label>
                        </div>
                        <div className="col-5">
                          <select
                            className={
                              !checkRestFriday()
                                ? "form-select "
                                : "form-select "
                            }
                            disabled={checkRestFriday()}
                            value={fridayHour?.close_hours}
                            onChange={handleCloseHoursChangeFriday}
                          >
                            {" "}
                            {generateClosingHoursOptions(fridayHour)}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row  oh-date">
                    <div className="col-2 rest-div">
                      <input
                        type="reset"
                        name="reset"
                        className={
                          !checkRestSaturday()
                            ? "d-input input-md light-button w-100"
                            : "d-input input-md w-100"
                        }
                        value={t("rest")}
                        checked={checkRestSaturday()}
                        onClick={() =>
                          handleSaturdayHourClick({
                            rest: !(saturdayHour.rest ?? false),
                          })
                        }
                      />
                    </div>
                    <div className="col-lg-2 col-4 mb-3">
                      <input
                        type="text"
                        className={
                          !checkRestSaturday()
                            ? "d-input2 input-md w-100"
                            : "d-input2 input-md light-button w-100"
                        }
                        value={t("Saturday")}
                        disabled={saturdayHour.rest}
                        readOnly
                        style={{
                          lineHeight: 0,
                          backgroundColor: "#ff3751",
                          color: "white",
                        }}
                      />
                    </div>
                    <div className="col-6">
                      <div className="row g-0">
                        <div className="col-5">
                          <select
                            className={
                              !checkRestSaturday()
                                ? "form-select "
                                : "form-select "
                            }
                            disabled={checkRestSaturday()}
                            value={saturdayHour?.open_hours}
                            onChange={(event) =>
                              handleDayHoursChange("Saturday", event)
                            }
                          >
                            {openHourSelect}
                          </select>
                        </div>
                        <div className="col-2 text-center">
                          <label htmlFor="" className="label">
                            {t("to")}
                          </label>
                        </div>
                        <div className="col-5">
                          <select
                            className={
                              !checkRestSaturday()
                                ? "form-select "
                                : "form-select "
                            }
                            disabled={checkRestSaturday()}
                            value={saturdayHour?.close_hours}
                            onChange={handleCloseHoursChangeSaturday}
                          >
                            {" "}
                            {generateClosingHoursOptions(saturdayHour)}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3 oh-date">
                    <div className="col-2 rest-div">
                      <input
                        type="reset"
                        name="reset"
                        className={
                          !checkRestSunday()
                            ? "d-input input-md light-button w-100"
                            : "d-input input-md w-100"
                        }
                        value={t("rest")}
                        checked={checkRestSunday()}
                        onClick={() =>
                          handleSundayHourClick({
                            rest: !(sundayHour.rest ?? false),
                          })
                        }
                      />
                    </div>
                    <div className="col-lg-2 col-4 mb-3">
                      <input
                        type="text"
                        className={
                          !checkRestSunday()
                            ? "d-input2 input-md w-100"
                            : "d-input2 input-md light-button w-100"
                        }
                        value={t("Sunday")}
                        disabled={sundayHour.rest}
                        readOnly
                        style={{
                          lineHeight: 0,
                          backgroundColor: "#ff3751",
                          color: "white",
                        }}
                      />
                    </div>
                    <div className="col-6">
                      <div className="row g-0">
                        <div className="col-5">
                          <select
                            className={
                              !checkRestSunday()
                                ? "form-select "
                                : "form-select "
                            }
                            disabled={checkRestSunday()}
                            value={sundayHour?.open_hours}
                            onChange={(event) =>
                              handleDayHoursChange("Sunday", event)
                            }
                          >
                            {openHourSelect}
                          </select>
                        </div>
                        <div className="col-2 text-center">
                          <label htmlFor="" className="label">
                            {t("to")}
                          </label>
                        </div>
                        <div className="col-5">
                          <select
                            className={
                              !checkRestSunday()
                                ? "form-select "
                                : "form-select "
                            }
                            disabled={checkRestSunday()}
                            value={sundayHour?.close_hours}
                            onChange={handleCloseHoursChangeSunday}
                          >
                            {generateClosingHoursOptions(sundayHour)}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <SizedBox height={20} />

                  {displayGroupType?.advance_desc && (
                    <>
                      <label htmlFor="" className="form-title1 mb-4">
                        {/* {t("bodyDimension")} */}B
                      </label>

                      <div className="row mb-4 oh-date">
                        <div className="col-lg-2 col-2">
                          <label htmlFor="" className="label">
                            {/* {t("height")} */}
                            {/* He */}
                            {langType === "zh"
                              ? displayGroupType?.advance_desc[3]?.split("|")[1]
                              : displayGroupType?.advance_desc[3]?.split(
                                  "|"
                                )[0]}
                          </label>
                        </div>
                        <div className=" col-lg-4 col-4 ">
                          <div className="input-group mb-3">
                            <input
                              type="text"
                              className="form-control form-control-cus border-wr line-height-body-dimension"
                              value={height.size}
                              onChange={(event) => {
                                // const newSize = parseInt(event.target.value);
                                handleHeight({
                                  size:
                                    event.target.value !== ""
                                      ? parseInt(event.target.value)
                                      : 0,
                                });
                                // if (!isNaN(newSize)) {
                                //   handleHeight({ size: newSize });
                                // }
                              }}
                              maxLength={5}
                            />
                            <select
                              className="form-select select-cc"
                              value={height.conversion}
                              onChange={(event) =>
                                handleHeight({ conversion: event.target.value })
                              }
                            >
                              <option value={"cm"}>{t("cm")}</option>
                              <option value={"inch"}>{t("inch")}</option>
                            </select>
                          </div>
                        </div>

                        <div className=" col-lg-2 col-2 text-center">
                          <label htmlFor="" className="label">
                            {/* {t("cupSize")} */}
                            {/* C */}
                            {langType === "zh"
                              ? displayGroupType?.advance_desc[0]?.split("|")[1]
                              : displayGroupType?.advance_desc[0]?.split(
                                  "|"
                                )[0]}
                          </label>
                        </div>
                        <div className=" col-lg-4 col-4 ">
                          <div className="input-group mb-3">
                            <input
                              type="text"
                              className="form-control form-control-cus  border-wr line-height-body-dimension"
                              value={cup.size}
                              onChange={(event) =>
                                handleCupSize({
                                  size:
                                    event.target.value !== ""
                                      ? parseInt(event.target.value)
                                      : 0,
                                })
                              }
                            />{" "}
                            <select
                              className="form-select select-cc"
                              value={cup.conversion}
                              onChange={(event) =>
                                handleCupSize({
                                  conversion: event.target.value,
                                })
                              }
                            >
                              <option value={"AA"}>AA</option>
                              <option value={"A"}>A</option>
                              <option value={"B"}>B</option>
                              <option value={"C"}>C</option>
                              <option value={"D"}>D</option>
                              <option value={"E"}>E</option>
                              <option value={"F"}>F</option>
                              <option value={"G"}>G</option>
                              <option value={"H"}>H</option>
                              <option value={">H"}>&gt;H</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row  mb-5 oh-date">
                        <div className=" col-lg-2 col-2">
                          <label htmlFor="" className="label">
                            {/* {t("waist")} */}
                            {/* W */}
                            {langType === "zh"
                              ? displayGroupType?.advance_desc[1]?.split("|")[1]
                              : displayGroupType?.advance_desc[1]?.split(
                                  "|"
                                )[0]}
                          </label>
                        </div>
                        <div className=" col-lg-4 col-4">
                          <div className="input-group mb-3">
                            <input
                              type="text"
                              className="form-control form-control-cus border-wr line-height-body-dimension"
                              value={waist.size}
                              onChange={(event) => {
                                // const newSize = parseInt(event.target.value);
                                handleWaist({
                                  size:
                                    event.target.value !== ""
                                      ? parseInt(event.target.value)
                                      : 0,
                                });
                                // if (!isNaN(newSize)) {
                                //   handleWaist({ size: newSize });
                                // }
                              }}
                            />
                            <select
                              className="form-select select-cc"
                              value={waist.conversion}
                              onChange={(event) =>
                                handleWaist({ conversion: event.target.value })
                              }
                            >
                              <option value={"inch"}>{t("inch")}</option>
                              <option value={"cm"}>{t("cm")}</option>
                            </select>
                          </div>
                        </div>

                        <div className=" col-lg-2 col-2 text-center">
                          <label htmlFor="" className="label">
                            {/* {t("hip")} */}
                            {/* Hi */}
                            {langType === "zh"
                              ? displayGroupType?.advance_desc[2]?.split("|")[1]
                              : displayGroupType?.advance_desc[2]?.split(
                                  "|"
                                )[0]}
                          </label>
                        </div>
                        <div className=" col-lg-4 col-4">
                          <div className="input-group mb-3">
                            <input
                              type="text"
                              className="form-control form-control-cus border-wr line-height-body-dimension"
                              value={hip.size}
                              onChange={(event) => {
                                // const newSize = parseInt(event.target.value);
                                handleHip({
                                  size:
                                    event.target.value !== ""
                                      ? parseInt(event.target.value)
                                      : 0,
                                });
                                // if (!isNaN(newSize)) {
                                //   handleHip({ size: newSize });
                                // }
                              }}
                            />
                            <select
                              className="form-select select-cc"
                              value={hip.conversion}
                              onChange={(event) =>
                                handleHip({ conversion: event.target.value })
                              }
                            >
                              <option value={"inch"}>{t("inch")}</option>
                              <option value={"cm"}>{t("cm")}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  <label htmlFor="" className="form-title1 mb-4">
                    {t("address")}
                  </label>

                  <div className="row  mb-3">
                    <div className="col-lg-2 col-4 mb-3">
                      <label htmlFor="" className="label">
                        {t("country")} <span className="re-star">*</span>
                      </label>
                    </div>
                    <div className="col-lg-4 col-8 mb-3">
                      <select
                        className="form-select input-md w-100"
                        value={country}
                        onChange={handleCountry}
                      >
                        {/* {!type ? (
                      <option value="">Please select type first</option>
                    ) : (
                      countries.map((e, i) => (
                        <option value={e} key={i + 1}>
                          {e ? e : "Please select country"}
                        </option>
                      ))
                    )} */}

                        <option>
                          {langType === "zh"
                            ? country?.split("|")[1]
                            : country?.split("|")[0]}
                        </option>
                        {Object.entries(countries)
                          .filter(([key, value]) => value !== country)
                          .map(([key, value]) => {
                            const [englishName, chineseName] = value.split("|");
                            return (
                              <option key={key} value={value}>
                                {langType === "zh" ? chineseName : englishName}
                              </option>
                            );
                          })}
                      </select>
                      <div className="text-red-add-details-2">
                        {errorMessagesCountry}
                      </div>
                    </div>

                    <div className="col-lg-2 col-4 mb-3">
                      <label htmlFor="" className="label">
                        {t("region1")} <span className="re-star">*</span>
                      </label>
                    </div>
                    <div className="col-lg-4 col-8 mb-3">
                      <select
                        className="form-select input-md w-100"
                        value={region1}
                        onChange={handleRegion1}
                      >
                        {/* {region1s.map((e, i) => (
                      <option value={e} key={i + 1}>
                        {e ? e : "Please select region 1"}
                      </option>
                    ))} */}

                        <option>
                          {langType === "zh"
                            ? region1?.split("|")[1]
                            : region1?.split("|")[0]}
                        </option>
                        {Object.entries(region1s)
                          .filter(([key, value]) => value !== region1)
                          .map(([key, value]) => {
                            const [englishName, chineseName] = value.split("|");
                            return (
                              <option key={key} value={value}>
                                {langType === "zh" ? chineseName : englishName}
                              </option>
                            );
                          })}
                      </select>
                      <div className="text-red-add-details-2">
                        {errorMessagesRegion1}
                      </div>
                    </div>

                    <div className="col-lg-2 col-4 mb-3">
                      <label htmlFor="" className="label">
                        {t("region2")} <span className="re-star">*</span>
                      </label>
                    </div>
                    <div className="col-lg-4 col-8 mb-3">
                      <select
                        className="form-select input-md w-100"
                        value={region2}
                        onChange={handleRegion2}
                      >
                        {/* {region2s.map((e, i) => (
                      <option value={e} key={i + 1}>
                        {e ? e : "Please select region 2"}
                      </option>
                    ))} */}

                        <option>
                          {langType === "zh"
                            ? region2?.split("|")[1]
                            : region2?.split("|")[0]}
                        </option>
                        {Object.entries(region2s)
                          .filter(([key, value]) => value !== region2)
                          .map(([key, value]) => {
                            const [englishName, chineseName] = value.split("|");
                            return (
                              <option key={key} value={value}>
                                {langType === "zh" ? chineseName : englishName}
                              </option>
                            );
                          })}
                      </select>
                      <div className="text-red-add-details-2">
                        {errorMessagesRegion2}
                      </div>
                    </div>

                    <div className="col-lg-2 col-4 mb-3">
                      <label htmlFor="" className="label">
                        {t("streetNoName")}
                      </label>
                    </div>
                    <div className="col-lg-4 col-8 mb-3">
                      <input
                        type="text"
                        className="street-no form-control form-control-cus"
                        placeholder={t("streetNoPlaceholder")}
                        value={streetName}
                        onChange={handleStreetName}
                        ref={streetNumberField}
                        id="streetno"
                      />
                    </div>

                    {langType === "zh" && (
                      <>
                        <div className="col-lg-2 col-4 mb-3">
                          <label htmlFor="" className="label">
                            {t("streetNoNameCh")}
                          </label>
                        </div>
                        <div className="col-lg-4 col-8 mb-3">
                          <input
                            type="text"
                            className="street-no form-control form-control-cus"
                            placeholder={t("streetNoPlaceholder")}
                            value={chineseStreetName}
                            disabled={true}

                            // id="streetno"
                          />
                        </div>
                      </>
                    )}
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <div ref={mapRef} id="map" className="map" />
                      <div id="infowindow-content">
                        <span id="place-name" className="title"></span>

                        <span id="place-address"></span>
                      </div>
                    </div>
                  </div>

                  {displayGroupType?.address && (
                    <>
                      <div className="row gx-2 gy-1 mb-3">
                        <div className="col-lg-2 col-3">
                          <label htmlFor="" className="label">
                            {/* {t("building")} */}

                            {langType === "zh"
                              ? displayGroupType?.address[1]?.split("|")[1]
                              : displayGroupType?.address[1]?.split("|")[0]}
                          </label>
                        </div>
                        <div className="col-lg-10 col-9 mb-3">
                          <input
                            type="text"
                            className="d-input input-md w-100"
                            value={building}
                            onChange={handleBuilding}
                            id="building"
                            ref={buildingField}
                          />
                        </div>
                        <div className=" col-lg-2 col-3 mb-3">
                          <label htmlFor="" className="label">
                            {/* {t("block")} */}
                            {langType === "zh"
                              ? displayGroupType?.address[2]?.split("|")[1]
                              : displayGroupType?.address[2]?.split("|")[0]}
                          </label>
                        </div>
                        <div className="col-lg-4 col-3 mb-3">
                          <input
                            type="text"
                            className="d-input input-md w-100"
                            value={block}
                            onChange={handleBlock}
                          />
                        </div>
                        <div className="col-lg-2 col-3 mb-3 text-center ">
                          <label htmlFor="" className="label">
                            {/* {t("FloorE")} */}
                            {langType === "zh"
                              ? displayGroupType?.address[3]?.split("|")[1]
                              : displayGroupType?.address[3]?.split("|")[0]}
                          </label>
                        </div>
                        <div className="col-lg-4 col-3">
                          <input
                            type="text"
                            className="d-input input-md w-100"
                            value={floorE}
                            onChange={handleFloorE}
                            maxLength={2}
                            onInput={(e: any) => {
                              e.preventDefault();
                              const input = e.target.value;
                              const regex = /^[0-9]*$/;
                              if (!regex.test(input)) {
                                e.target.value = input.slice(0, -1);
                              }
                            }}
                          />
                        </div>

                        <div className="col-lg-2  col-3 mb-3">
                          <label htmlFor="" className="label">
                            {/* {t("FlatRoom")} */}
                            {langType === "zh"
                              ? displayGroupType?.address[4]?.split("|")[1]
                              : displayGroupType?.address[4]?.split("|")[0]}
                          </label>
                        </div>
                        <div className="col-lg-4  col-3 mb-3">
                          <input
                            type="text"
                            className="d-input input-md w-100"
                            value={room}
                            onChange={handleRoom}
                          />
                        </div>
                        <div className="col-lg-2  col-3 mb-3 text-center">
                          <label htmlFor="" className="label">
                            {/* {t("tenementFloor")} */}
                            {langType === "zh"
                              ? displayGroupType?.address[5]?.split("|")[1]
                              : displayGroupType?.address[5]?.split("|")[0]}
                          </label>
                        </div>
                        <div className="col-lg-4  col-3 mb-3">
                          <input
                            type="text"
                            className="d-input input-md w-100"
                            value={floorC}
                            onChange={handleFloorC}
                            maxLength={1}
                            onInput={(e: any) => {
                              e.preventDefault();
                              const input = e.target.value;
                              const regex = /^[0-9]*$/;
                              if (!regex.test(input)) {
                                e.target.value = input.slice(0, -1);
                              }
                            }}
                          />
                        </div>

                        <div className="col-lg-2 col-3 mb-3">
                          <label htmlFor="" className="label">
                            {langType === "zh"
                              ? displayGroupType?.address[6]?.split("|")[1]
                              : displayGroupType?.address[6]?.split("|")[0]}
                          </label>
                        </div>
                        <div className="col-lg-10 col-9 post-box-2 mb-3">
                          {/* <input
                    type="text"
                    className="d-input input-md w-100"
                    value={description}
                    onChange={handleDescription}
                  /> */}
                          <SizedBox height={5} />
                          <textarea
                            name=""
                            id="text"
                            rows={5}
                            className="form-control form-control-cus"
                            value={description.slice(0, 49)}
                            onChange={handleDescription}
                            onInput={handleInputDescription}
                          ></textarea>
                          <span
                            className="pull-right label label-default"
                            id="count_message"
                          >
                            {description.length} / 50
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                  {/* <div className="py-3">
                    <button
                      className="btn btn-primary"
                      onClick={handleSuperMasterAddress}
                    >
                      Check Address
                    </button>
                  </div> */}

                  <div className="ac-details">
                    <div className="row">
                      <div className="col-12">
                        {/* <h6>
                              {t('followerListInfo')}
                            </h6> */}
                        <div className="mb-3 bd-dtl bd-open">
                          <label htmlFor="" className="label mb-2">
                            {t("blogDetails")}
                          </label>
                          <div className="post-box mb-3">
                            <textarea
                              name=""
                              id="text"
                              cols={30}
                              rows={7}
                              className="form-control"
                              value={blogDetails}
                              onChange={handleBlogDetails}
                            />
                            <span
                              className="pull-right label label-default"
                              id="count_message"
                            >
                              {blogDetailsCount} / 2000
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <label htmlFor="" className="form-title1 mb-4">
                    {t("contactInfoAndOther")}
                  </label>

                  <div className="row  mb-3">
                    <div className="col-lg-3 col-3">
                      <label htmlFor="" className="label">
                        {t("phone")} <span className="re-star">*</span>
                      </label>
                    </div>
                    <div className=" col-lg-3 col-9 w-300">
                      <div className="input-group">
                        <div className="input-group-addon addon-grp">
                          <select
                            className="form-select custom-s2"
                            defaultValue={selectedPrefix}
                            value={selectedPrefix}
                            onChange={handlePrefixChange}
                            disabled={isMobileNumberValid}
                          >
                            <option value={"852"}>+852</option>
                            <option value={"853"}>+853</option>
                            <option value={"91"}>+91</option>
                            <option value={"60"}>+60</option>
                            {/* TODO: TES*/}
                            <option value={"62"}>+62</option>
                            <option value={"65"}>+65</option>
                          </select>
                        </div>
                        <input
                          className="form-control bg-color"
                          type="tel"
                          pattern="[0-9]+"
                          style={{ color: "white" }}
                          value={mobileNumber}
                          onChange={handleMobile}
                          maxLength={12}
                        />
                      </div>
                      <div className="text-red-add-details-3">
                        {errorMessagesPhone}
                      </div>
                    </div>
                  </div>

                  {/* {ext && ( */}
                  <div className="row  mb-3">
                    <div className="col-lg-3 col-3">
                      <label htmlFor="" className="label">
                        {t("telegramIDno")} <span className="re-star">*</span>
                      </label>
                    </div>
                    <div className=" col-lg-3 col-9 w-300">
                      <div className="input-group">
                        <input
                          className="form-control bg-color"
                          type="text"
                          style={{ color: "white" }}
                          value={telegram}
                          onChange={handleTelegram}
                        />
                      </div>
                      <div className="text-red-add-details-3">
                        {errorMessagesTelegram}
                      </div>
                    </div>
                  </div>
                  {/* )} */}

                  {/* <div className="row  mb-3">
                <div className="col-lg-4 col-8 ">
                  <label htmlFor="" className="label">
                    Need Caller Display
                  </label>
                </div>
                <div className="col-lg-2 col-4  text-end">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={needCaller}
                      onChange={handleNeedCaller}
                    />
                    <span className="slider round">OFF ON</span>
                  </label>
                </div>
              </div> */}
                  <div className="row  mb-3">
                    <div className="col-lg-4 col-8 ">
                      <label htmlFor="" className="label">
                        {t("pleaseBookingInAdv")}
                      </label>
                    </div>
                    <div className="col-lg-2 col-4 text-end ">
                      <div className="on-off-btn justify-content-end">
                        <button
                          className={
                            pleaseBooking === true
                              ? "btn primary-button-active rounded-button"
                              : "btn primary-button rounded-button btn3"
                          }
                          onClick={() => setPleaseBooking(true)}
                        >
                          {t("ON")}
                        </button>
                        <button
                          className={
                            pleaseBooking === false
                              ? "btn primary-button rounded-button margin-left-10"
                              : "btn primary-button rounded-button btn3 margin-left-10"
                          }
                          onClick={() => setPleaseBooking(false)}
                        >
                          {t("OFF")}
                        </button>
                      </div>
                      {/* </label> */}
                    </div>
                  </div>
                  <div className="row  mb-5">
                    <div className="col-lg-4 col-8 ">
                      <label htmlFor="" className="label">
                        {t("callOpenToCallDoor")}
                      </label>
                    </div>
                    <div className="col-lg-2 col-4  text-end">
                      <div className="on-off-btn justify-content-end">
                        <button
                          className={
                            callToOpen === true
                              ? "btn primary-button-active rounded-button"
                              : "btn primary-button rounded-button btn3"
                          }
                          onClick={() => setCallToOpen(true)}
                        >
                          {t("ON")}
                        </button>
                        <button
                          className={
                            callToOpen === false
                              ? "btn primary-button rounded-button margin-left-10"
                              : "btn primary-button rounded-button btn3 margin-left-10"
                          }
                          onClick={() => setCallToOpen(false)}
                        >
                          {t("OFF")}
                        </button>
                      </div>
                    </div>
                  </div>

                  <label htmlFor="" className="label mb-3">
                    {t("selfDescription")}
                  </label>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="post-box mb-5">
                        <SizedBox height={5} />
                        <textarea
                          name=""
                          id="text"
                          rows={7}
                          className="form-control form-control-cus"
                          value={selfDescription.slice(0, 139)}
                          onChange={handleSelfDescription}
                          onInput={handleInput}
                        ></textarea>
                        <span
                          className="pull-right label label-default"
                          id="count_message"
                        >
                          {selfDescription.length} / 140
                        </span>
                      </div>
                    </div>
                  </div>

                  <label htmlFor="" className="form-title1 mb-4">
                    {t("welcome")}
                  </label>

                  <div className="row  mb-3">
                    <div className="col-lg-4 col-8 ">
                      <label htmlFor="" className="label">
                        {t("foreigner")} {foreginer}
                      </label>
                    </div>
                    <div className="col-lg-2 col-4  text-end">
                      <div className="on-off-btn justify-content-end">
                        <button
                          className={
                            foreginer === true
                              ? "btn primary-button-active rounded-button"
                              : "btn primary-button rounded-button btn3"
                          }
                          onClick={() => setForeginer(true)}
                        >
                          {t("ON")}
                        </button>
                        <button
                          className={
                            foreginer === false
                              ? "btn primary-button rounded-button margin-left-10"
                              : "btn primary-button rounded-button btn3 margin-left-10"
                          }
                          onClick={() => setForeginer(false)}
                        >
                          {t("OFF")}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="row  mb-5">
                    <div className="col-lg-4 col-8 ">
                      <label htmlFor="" className="label">
                        {t("pd")}
                      </label>
                    </div>
                    <div className="col-lg-2 col-4 text-end">
                      <div className="on-off-btn justify-content-end">
                        <button
                          className={
                            PD === true
                              ? "btn primary-button-active rounded-button"
                              : "btn primary-button rounded-button btn3"
                          }
                          onClick={() => setPD(true)}
                        >
                          {t("ON")}
                        </button>
                        <button
                          className={
                            PD === false
                              ? "btn primary-button rounded-button margin-left-10"
                              : "btn primary-button rounded-button btn3 margin-left-10"
                          }
                          onClick={() => setPD(false)}
                        >
                          {t("OFF")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-6 text-center">
                    <button
                      className="button mt-3 w-100 next-button"
                      onClick={handleBack}
                    >
                      {t("back")}
                    </button>
                  </div>
                  <div className="col-6 text-center">
                    {/* <Link to="/individual-ad-details"> */}
                    <button
                      className="next-button button mt-3 w-100"
                      onClick={(e) => {
                        let isFirstSetValid = false;
                        let isSecondSetValid = false;
                        let isThirdSetValid = false;

                        if (
                          (chineseName?.length ?? 0) > 0 &&
                          (englishName?.length ?? 0) > 0 &&
                          (type?.length ?? 0) > 0 &&
                          // (nationality?.length ?? 0) > 0 &&
                          // (language?.length ?? 0) > 0
                          (errorMessagesLang?.length ?? 0) == 0 &&
                          (age === 0 || (age >= 18 && age <= 65))
                        ) {
                          isFirstSetValid = true;
                        }

                        if (
                          (country?.length ?? 0) > 0 &&
                          (region1?.length ?? 0) > 0 &&
                          (region2?.length ?? 0) > 0
                        ) {
                          isSecondSetValid = true;
                        }

                        if (
                          (mobileNumber?.length ?? 0) > 0 &&
                          (telegram?.length ?? 0) > 0
                        ) {
                          isThirdSetValid = true;
                        }

                        if (
                          isFirstSetValid &&
                          isSecondSetValid &&
                          isThirdSetValid
                        ) {
                          return handleNext(e);
                        } else if (!isFirstSetValid) {
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        } else if (!isSecondSetValid) {
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        } else if (!isThirdSetValid) {
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        }
                      }}
                      disabled={isLoading}
                    >
                      {isLoading ? <Spinner size="sm" /> : t("next")}
                    </button>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
        </>
      )}

      {/* Type details modal */}

      <Modal show={isBlogAdvertisementModalOpen}>
        <Modal.Body>
          <Modal.Title>
            <center>
              {/* <h5 className="modal-title" style={{fontSize: 20}}> */}
              <h5 className="modal-title">
                Please turn on Advertisement before turn Blog on
              </h5>
            </center>
            <div style={{ height: 50 }}></div>
          </Modal.Title>
        </Modal.Body>

        <Modal.Footer>
          <div className="d-flex justify-content-center align-items-center">
            <button
              type="button"
              className="primary-button button mt-3"
              style={{ width: 100 }}
              data-bs-dismiss="modal"
              onClick={() => setIsBlogAdvertisementModalOpen(false)}
            >
              Ok
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal show={isBlogModalOpen}>
        {/* <Modal show={isBlogAdvertisementModalOpen}> */}
        <Modal.Body>
          <Modal.Title>
            <center>
              <h5 className="modal-title">Are you sure to turn Blog On?</h5>
            </center>
            <div style={{ height: 100 }}></div>
          </Modal.Title>
        </Modal.Body>

        <Modal.Footer>
          <div className="d-flex justify-content-center align-items-center">
            <button
              type="button"
              className="primary-button button mt-3 w-100"
              data-bs-dismiss="modal"
              onClick={() => {
                setBlog(1);
                setIsBlogModalOpen(false);
              }}
            >
              Yes
            </button>
            <div style={{ width: 50 }}></div>
            <button
              type="button"
              className="primary-button button mt-3 w-100"
              data-bs-dismiss="modal"
              onClick={() => setIsBlogModalOpen(false)}
            >
              No
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditProfile;
