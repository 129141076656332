import * as React from "react";
import { Link } from "react-router-dom";
import { Transition, TransitionStatus } from "react-transition-group";
import AppRoutePath from "../../constants/AppRoutePath";
import AccountTypeConst from "../../domain/enum/account-type";
import { useTranslation, initReactI18next } from "react-i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { getClientProfile } from "../../services/client/client-profile";
import { getTchatUrl } from "../../services/tChat/chats";
import { Modal, Spinner } from "react-bootstrap";
export interface IHistoryPageProps {}
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: require("../../locales/en.json") },
      zh: { translation: require("../../locales/zh.json") },
    },
    fallbackLng: "en", // Default language fallback
    interpolation: {
      escapeValue: false, // Disable HTML escaping
    },
  });

export interface ICustomNavigationProps {
  isOpen: boolean;
  onCallback: (value: boolean) => void;
  accountType?: AccountTypeConst;
}

type TransitionStyles = {
  [key in TransitionStatus]: React.CSSProperties;
};

const openStyle: TransitionStyles = {
  entering: { right: -340, transition: "right 1s" },
  entered: { right: -340, transition: "right 1s" },
  exiting: { right: 0, transition: "right 1s" },
  exited: { right: 0, transition: "right 1s" },
  unmounted: {},
};

// const handleTchats = async () => {
//   getTchatUrl().then(async (res) => {
//     if (res.result_code === 200) {
//       const newWindow = window.open(res.url, "_blank");
//       if (
//         !newWindow ||
//         newWindow.closed ||
//         typeof newWindow.closed === "undefined"
//       ) {
//         // Opening in new tab/window failed, fallback to window.location
//         window.location.href = res.url;
//       }
//     }
//   });
// };

export default function CustomNavigation({
  isOpen,
  onCallback,
  accountType = AccountTypeConst.Client,
}: ICustomNavigationProps) {
  const { t } = useTranslation();
  const isEXT = localStorage.getItem("EXT") == "true";

  const isClient = window.location.pathname.includes("clinet");

  const userId = localStorage.getItem("user_id");

  const [tChatGenerateLink, setTChatGenerateLink] = React.useState("");
  const [tChatModelOpen, setTChatModelOpen] = React.useState(false);
  const [isTChatLoading, setIsTChatLoading] = React.useState(false);

  const handleTchats = async () => {
    setIsTChatLoading(true);
    getTchatUrl().then(async (res: any) => {
      if (res.result_code === 200) {
        console.log("res.url", res.url);
        setTChatModelOpen(true);

        setTChatGenerateLink(res.url);
        setIsTChatLoading(false);
      }
    });
  };

  return (
    <>
      <Transition in={isOpen} timeout={300}>
        {(state) => {
          const isMenuOpen = state === "entered" || state === "entering";
          const closeMenuClass = isMenuOpen ? "fixed-menu" : "";

          return (
            <nav
              className={`navigation ${closeMenuClass}`}
              style={openStyle[state]}
            >
              {!isMenuOpen && (
                <a className="close-menu">
                  <i
                    className="fa-solid fa-xmark"
                    onClick={() => {
                      onCallback(!isOpen);
                    }}
                  ></i>
                </a>
              )}
              <ul>
                <li>
                  {accountType === AccountTypeConst.Client ? (
                    <Link to={AppRoutePath.clientMainPage}>
                      <img
                        src="/img/home.png"
                        alt=""
                        style={{ width: 20, height: 20 }}
                      />{" "}
                      <span>{t("clientMainPage")}</span>
                    </Link>
                  ) : (
                    <Link to="/master/account">
                      <img
                        src="/img/home.png"
                        alt=""
                        style={{ width: 20, height: 20 }}
                      />{" "}
                      <span>{t("masterAcctPage")}</span>
                    </Link>
                  )}
                </li>

                <li>
                  {accountType === AccountTypeConst.Client ? (
                    <Link to="/client/edit-profile">
                      <img
                        src="/img/edit.png"
                        alt=""
                        style={{ width: 20, height: 20 }}
                      />{" "}
                      <span>{t("editDetails")}</span>
                    </Link>
                  ) : (
                    <>
                      <Link to="/master/ad-list">
                        <img
                          src="/img/edit.png"
                          alt=""
                          style={{ width: 20, height: 20 }}
                        />{" "}
                        <span>{t("adList")}</span>
                      </Link>
                    </>
                  )}
                </li>

                {isEXT != true ? (
                  accountType == AccountTypeConst.Master ? null : (
                    <>
                      <li>
                        <Link
                          to={
                            (accountType === AccountTypeConst.Client
                              ? AppRoutePath.clientWallet
                              : AppRoutePath.masterWallet) + "/one-coin"
                          }
                        >
                          <img src="/img/onecoin_icon.png" alt="" />{" "}
                          <span>{t("oneCoin")}</span>
                        </Link>
                      </li>

                      <li>
                        <Link
                          to={
                            (accountType === AccountTypeConst.Client
                              ? AppRoutePath.clientWallet
                              : AppRoutePath.masterWallet) + "/flower"
                          }
                        >
                          <img src="/img/flower_icon.png" alt="" />{" "}
                          <span>{t("addFlower")}</span>
                        </Link>
                      </li>

                      <li>
                        <Link
                          to={
                            accountType === AccountTypeConst.Client
                              ? AppRoutePath.clientMyInstantPosts
                              : AppRoutePath.masterMyInstantPosts
                          }
                        >
                          <img
                            style={{ width: 22 }}
                            src="/img/instant-post-logo-red.png"
                            alt="Instant Post Logo"
                          />
                          {/* <i className="fa-brands fa-instagram"></i>{" "} */}
                          <span>{t("instantPostList")}</span>
                        </Link>
                      </li>
                    </>
                  )
                ) : (
                  ""
                )}

                {accountType === AccountTypeConst.Client && (
                  <li>
                    <Link to={AppRoutePath.clientMyInstantReview}>
                      <img
                        style={{ width: 22 }}
                        src="/img/instant-review-logo-red.png"
                        alt="Instant Review Logo"
                      />
                      {/* <i className="fa-brands fa-instagram"></i> */}{" "}
                      <span>{t("instantReviewList")}</span>
                    </Link>
                  </li>
                )}
                {accountType === AccountTypeConst.Client && (
                  <li>
                    <Link to={AppRoutePath.clientInvitationList}>
                      <img
                        style={{ width: 22 }}
                        src="/img/invitationlist.svg"
                        alt="Invite List Icon"
                      />
                      {/* <i className="fa-brands fa-instagram"></i> */}{" "}
                      <span>{t("invitationList")}</span>
                    </Link>
                  </li>
                )}

                <li>
                  {accountType === AccountTypeConst.Client && isEXT != true ? (
                    <Link to={AppRoutePath.clientSendOneCoin}>
                      <img src="/img/sendCoin.svg" alt="" /> <span />
                      {t("sendOneCoin")}
                    </Link>
                  ) : (
                    ""
                  )}
                </li>
                <li>
                  {accountType === AccountTypeConst.Master && isEXT != true ? (
                    <Link to={AppRoutePath.masterSendOneCoin}>
                      <img src="/img/sendCoin.svg" alt="" /> <span />
                      {t("sendOneCoin")}
                    </Link>
                  ) : (
                    ""
                  )}
                </li>

                {isTChatLoading ? (
                  <>
                    <li>
                      <a className="text-white cur-pointer">
                        <i className="fa-solid fa-phone"></i>{" "}
                        <span className="color-pink pe-2">
                          {t("contactUs")}
                        </span>{" "}
                        <Spinner size="sm" />
                      </a>
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <a
                        className="text-white cur-pointer"
                        onClick={() => handleTchats()}
                      >
                        <i className="fa-solid fa-phone"></i>{" "}
                        <span className="color-pink">{t("contactUs")}</span>
                      </a>
                    </li>
                  </>
                )}

                {accountType === AccountTypeConst.Client && (
                  <>
                    {/* <li>
                
                    <Link
                      to={
                        accountType === AccountTypeConst.Client
                          ? AppRoutePath.clientTchatList
                          : AppRoutePath.masterTchatList
                      }
                    >
                      <img style={{ width: 26 }} src="/img/tchat_newlogo.png" />
                   
                      {t("TCHAT")}
                    </Link>
                  </li> */}
                    {/* userId */}
                    <li>
                      {/* <Link
                      to={`https://141-member.new-app.site/ad-blog/${userId}`}
                      target="_blank"
                    > */}
                      <Link to={AppRoutePath.clientAddPage}>
                        {/* <Link to={`http://localhost:3001/ad-blog/${userId}`} target="_blank"> */}
                        <img
                          src="/img/ad.png"
                          alt=""
                          // style={{ visibility: "hidden" }}
                        />{" "}
                        <span />
                        {t("yourAdPage")}
                      </Link>
                    </li>

                    {/* {isEXT != true ? (
                      <li>
                        <Link to={AppRoutePath.clientSendOneCoin}>
                          <img src="/img/ad.png" alt="" /> <span />
                          {t("sendOneCoin")}
                        </Link>
                      </li>
                    ) : (
                      ""
                    )} */}
                  </>
                )}
              </ul>
              <ol>
                <li>
                  <Link
                    to={
                      accountType === AccountTypeConst.Client
                        ? AppRoutePath.clientTrainingPage
                        : AppRoutePath.masterTrainingPage
                    }
                  >
                    <img
                      src="/img/training.png"
                      alt=""
                      // style={{ visibility: "hidden" }}
                    />{" "}
                    <span className="color-pink">{t("training")} </span>
                  </Link>
                </li>

                <li>
                  <a
                    className="cursor-pointer"
                    onClick={() => {
                      localStorage.clear();
                      document.location.href = "/";
                    }}
                  >
                    <img src="/img/logout.png" alt="" />{" "}
                    <span className="cur-pointer">{t("logout")}</span>
                  </a>
                </li>
              </ol>
            </nav>
          );
        }}
      </Transition>

      <Modal show={tChatModelOpen}>
        <Modal.Body>
          <div className="d-flex justify-content-center align-items-center">
            <Modal.Title>
              <div className="text-center pb-3">
                <img
                  style={{ width: 50, marginRight: 5 }}
                  src="/img/tchat_newlogo.png"
                  className="default-logo"
                  alt="Tchat Logo"
                />
              </div>
              <h5 className="modal-title text-center pb-3">
                {t("tchatModelContact")}
              </h5>
            </Modal.Title>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-2 justify-content-center text-center">
            <button
              className=" button primary-button rounded-button px-5 "
              onClick={() => {
                setTChatModelOpen(false);
              }}
            >
              {t("no")}
            </button>

            <a
              href={tChatGenerateLink}
              target="_blank"
              rel="noreferrer"
              className="button primary-button rounded-button px-5"
              onClick={() => {
                setTChatModelOpen(false);
              }}
            >
              {t("ok")}
            </a>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
