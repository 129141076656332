import { WalletTransactionType } from "../../../../constants/WalletTransactionType";
import ReloadHeartCardComp from "../card/ReloadHeartCardComp";
import HeartWalletTemplate from "./HeartWalletPageTemplate";

export default class ReloadHeartWalletPageTemplate extends HeartWalletTemplate {
	constructor(id?: number, updateTable?: () => void) {
		super(id);
		this.updateTable = updateTable;
	}

	title = "Reload Heart";
	transactionType = WalletTransactionType.Reload;
	updateTable;

	async cardComp() {
		return <ReloadHeartCardComp updateTable={this.updateTable} />;
	}
}
