import * as React from "react";
import { Dropdown, Modal, Pagination } from "react-bootstrap";
import moment from "moment";
import { WalletTableRow } from "../interface/WalletTableRow";
import { getFollowerList } from "../../../../services/client/client-follow";
import { getRemarksDefault } from "../../../../services/modules";
import Loaders from "../../../reusable/Loaders";

interface WalletData {
  tx_time: string;
}

export interface TableDataResponse {
  data: any[][];
  "item(s)": any;
  total_pages?: number;
  current_page?: number;
  total_items?: number;
}

export interface IWalletTableCompProps {
  walletTableRow: WalletTableRow[];
  getTableData: (
    page?: Number,
    per_page?: Number,
    to_user?: any[],
    to_post?: any[],
    from?: any[],
    to?: any[],
    tx_id?: string,
    start_at?: string,
    end_at?: string,
    desc_sort?: boolean,
    is_free?: boolean,
    order_cs_id?: Number
  ) => Promise<TableDataResponse | null>;

  idxTableSelectedVal?: number;
  // userList?: number[];
  userList?: () => Promise<any | null>;
  remarks?: () => Promise<any | null>;
}

export default function WalletTableComp({
  walletTableRow,
  getTableData,
  // getTableData1,
  idxTableSelectedVal = 0,
  userList,
  remarks,
}: IWalletTableCompProps & { userList?: number[] }) {
  const [idxTableSelected, setIdxTableSelected] =
    React.useState(idxTableSelectedVal);
  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState<[][]>([[]]);
  const [remarksData, setRemarksData] = React.useState({});
  //   check follower list
  const [userlistData, setUserListData] = React.useState<any[]>([]);
  const [sortOrder, setSortOrder] = React.useState<"asc" | "desc">("desc");
  const chevronIcon =
    sortOrder === "asc" ? "fa-solid fa-chevron-down" : "fa-solid fa-chevron-up";

  //  Select All
  const [selectAll, setSelectAll] = React.useState(false);
  const [isSendDisabled, setIsSendDisabled] = React.useState(true);
  const [isShowBlockModal, setIsShowBlockModal] = React.useState(false);
  const [isShowBlocSuccessfulModal, setIsShowBlocSuccessfulModal] =
    React.useState(false);
  const [isMessageOpen, setIsMessageOpen] = React.useState(false);
  const [messageContent, setMessageContent] = React.useState(
    "Long time no see ...!"
  );

  const itemsPerPage = 10; // Number of items to display per page
  const itemsPerPage1 = 10; // Number of items to display per page
  const [currentPage, setCurrentPage] = React.useState(1);
  const [currentPage1, setCurrentPage1] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(0);
  const [totalPages1, setTotalPages1] = React.useState(0);
  const [toUsers, setToUsers] = React.useState<any>();

  const [activePopover, setActivePopover] = React.useState(null);

  const handlePopoverToggle = (popoverType: any) => {
    if (activePopover === popoverType) {
      setActivePopover(null);
    } else {
      setActivePopover(popoverType);
    }
  };

  const getExactIdx = () => Math.min(idxTableSelected, data.length);

  const fetchDataBasedOnIndex = async (index: any) => {
    try {
      setIsLoading(true);
      const res = await getTableData(currentPage, itemsPerPage);
      if (res != null) {
        setData(Array.isArray(res["item(s)"]) ? res["item(s)"] : []);
        const totalPages = res.total_pages || 1;
        setTotalPages(totalPages);
        // setIsLoading(true);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchDataBasedOnIndex(getExactIdx());
  }, [getTableData, currentPage, itemsPerPage, itemsPerPage1, totalPages]);

  React.useEffect(() => {
    getFollowerList().then((res) => {
      if (res != null) {
        const fansList = res["item(s)"].map((follower) => follower.fans);
        setUserListData(fansList);
      }
    });
  }, [userList]);

  React.useEffect(() => {
    getRemarksDefault().then((res) => {
      if (res != null) {
        // console.log("Remarks data 123: ", res);
        setRemarksData(res);
      }
    });
  }, [remarks]);

  // const handleSortToggle = () => {
  //   setSortOrder((prevSortOrder) => (prevSortOrder === "asc" ? "desc" : "asc"));
  // };

  // const sortButtonText = sortOrder === "asc" ? "Oldest" : "Latest";

  const handleMessageContent: React.ChangeEventHandler<HTMLTextAreaElement> = (
    event
  ) => {
    setMessageContent(event.target.value);
  };

  const handleInput = (event: React.FormEvent<HTMLTextAreaElement>) => {
    const inputText = event.currentTarget.value;
    if (inputText.length <= 139) {
      setMessageContent(inputText);
    }
  };

  const handleStarClick = (follower: any) => {
    // Access properties of the clicked follower object
    const followerFans = follower.fans;
    const followerStar = follower.star;

    // Perform desired logic
    // console.log("Star clicked:", followerFans);
    // console.log("Star status:", followerStar);

    // Update follower's star status or perform any other actions
  };

  // Add functions to handle pagination events
  const goToPage = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const goToNextPage = () => {
    goToPage(currentPage + 1);
  };

  const goToPrevPage = () => {
    goToPage(currentPage - 1);
  };
  const goToPage1 = (page: number) => {
    if (page >= 1 && page <= totalPages1) {
      setCurrentPage1(page);
    }
  };

  return (
    <>
      <div className="table-wrap">
        <ul className="shorting-top">
          <li>
            <Dropdown
              style={{
                backgroundColor: "transparent",
                border: "none",
              }}
              onSelect={(eKey, evt) => {
                const selectedIndex = parseInt(eKey ?? "0");
                setIdxTableSelected(selectedIndex);
                fetchDataBasedOnIndex(selectedIndex); // Fetch data when selected index changes
              }}
              // onSelect={(eKey, evt) =>
              //   setIdxTableSelected(parseInt(eKey ?? "0"))
              // }
            >
              <Dropdown.Toggle
                id="dropdown-basic"
                variant="secondary"
                style={dropdownStyle}
              >
                {walletTableRow[getExactIdx()].label}{" "}
                <i className="fa-solid fa-chevron-down"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {walletTableRow.map((table, i) => (
                  <Dropdown.Item eventKey={i} style={dropdownStyle} key={i}>
                    {table.label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </li>
          {/* <li>
            <a href="#" className="color-pink" onClick={handleSortToggle}>
              <span className="pad-right-5">{sortButtonText}</span>
              <i className={`${chevronIcon}`}></i>
            </a>
          </li> */}
        </ul>

        {/* 28 November  */}

        {isLoading ? (
          <div className="d-flex justify-content-center">
            <Loaders />
          </div>
        ) : (
          <>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    {walletTableRow[getExactIdx()].colName.map((name, i) => (
                      <th scope="col" key={i}>
                        {name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data.map((d) =>
                    walletTableRow[getExactIdx()].rowDataComp(
                      d,
                      remarksData,
                      userlistData
                    )
                  )}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>

      <div className="d-flex justify-content-center">
        <Pagination>
          <Pagination.Prev
            onClick={goToPrevPage}
            disabled={currentPage === 1}
          />

          {/* Show the first page */}
          <Pagination.Item onClick={() => goToPage(1)}>First</Pagination.Item>

          {/* Show ellipsis if there are more than 3 pages before the current page */}
          {currentPage > 3 && <Pagination.Ellipsis disabled />}

          {/* Show two pages before the current page */}
          {currentPage > 2 && (
            <Pagination.Item onClick={() => goToPage(currentPage - 1)}>
              {currentPage - 1}
            </Pagination.Item>
          )}

          {/* Show the current page */}
          <Pagination.Item active>{currentPage}</Pagination.Item>

          {/* Show two pages after the current page */}
          {currentPage < totalPages - 1 && (
            <Pagination.Item onClick={() => goToPage(currentPage + 1)}>
              {currentPage + 1}
            </Pagination.Item>
          )}

          {/* Show ellipsis if there are more than 3 pages after the current page */}
          {currentPage < totalPages - 2 && <Pagination.Ellipsis disabled />}

          {/* Show the last page */}
          <Pagination.Item onClick={() => goToPage(totalPages)}>
            Last
          </Pagination.Item>

          <Pagination.Next
            onClick={goToNextPage}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      </div>

      <Modal show={isMessageOpen}>
        {/* <Modal.Header> */}
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={(e) => setIsMessageOpen(false)}
        ></button>
        {/* </Modal.Header> */}
        <Modal.Body className="px-0">
          <label className="label pb-2">Type your message here </label>
          <div className="post-box mb-3">
            <textarea
              name=""
              id="text"
              rows={7}
              className="form-control form-control-cus"
              value={messageContent.slice(0, 139)}
              onChange={handleMessageContent}
              onInput={handleInput}
            ></textarea>
            <span className="pull-right label label-default" id="count_message">
              {messageContent.length} / 140
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <button
            className="  btn-modal-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={(e) => setIsMessageOpen(false)}
          >
            Cancel
          </button>
          {/* <button className="btn-modal" onClick={handleSendMessages}>
            Send
          </button> */}
          <button className="btn-modal">Send</button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const dropdownStyle = {
  background: "transparent",
  border: "none",
  boxShadow: "none",
  fontSize: 12,
  fontWeight: 400,
};
