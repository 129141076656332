import _axios from "axios";
import LocalStorageKey from "../constants/LocalStorageKey";

const baseURL =
  process.env.REACT_APP_BASE_URL;
const axiosGuest = _axios.create({ baseURL });

axiosGuest.interceptors.request.use((config) => {
  var X_AUTH_TOKEN =
    localStorage.getItem(LocalStorageKey.tokenGuest);
  const X_VERIFY_TOKEN = localStorage.getItem("vkey") ?? "";


  if (X_AUTH_TOKEN !== '') {
    config.headers.set("Authorization", `Bearer ${X_AUTH_TOKEN}`);
  }
  config.headers.set("X-Verify-Key", X_VERIFY_TOKEN);

  return config;
});



export default axiosGuest;
