import React, { useState, useEffect, useRef } from "react";

import { Link } from "react-router-dom";
import AppRoutePath from "../../constants/AppRoutePath";
import {
  Html5Qrcode,
  Html5QrcodeSupportedFormats,
  Html5QrcodeScanner,
} from "html5-qrcode";


import { useTranslation, initReactI18next } from "react-i18next";

import CustomNavigation from "../reusable/Navigation";
import AccountTypeConst from "../../domain/enum/account-type";
import CustomHeader from "../reusable/Header";
import TopHearder from "../reusable/TopHearder";



 const BookingScanner = (props: any) => {
  

  const isMaster = window.location.pathname.includes("master");

  const [scanResult, setScanResult] = useState<string | null>(null);

  const { t } = useTranslation();

  const [isNavOpen, setIsNavOpen] = useState<boolean>(true);

  const formatsToSupport = [Html5QrcodeSupportedFormats.QR_CODE];

  let scanner:any;
  let isScanning = true;

  useEffect(() => {
    scanner = new Html5QrcodeScanner(
      "reader",
      {
        qrbox: {
          width: 250,
          height: 250,
        },
        formatsToSupport: formatsToSupport,
        fps: 5,
        rememberLastUsedCamera: true,
      },
      true
    );

    scanner.render(success, error);

    function success(result: any) {
      if (result) {
        scanner.clear();
        setScanResult(result);
        isScanning = false;

        // console.log("Scan Result", result);
        
      }
    }

    function error(err: any) {
      console.warn(err);
    }

    return () => {
      if(scanner) {
        scanner.clear();
      }  
    }
  }, []);


  


  return (
    <>
      <CustomHeader />
      <TopHearder title={t("bookingScanner")} />

      <CustomNavigation
        isOpen={isNavOpen}
        onCallback={(value) => {
          setIsNavOpen(value);
        }}
        accountType={
          isMaster ? AccountTypeConst.Master : AccountTypeConst.Client
        }
      />
      {/* <CustomHeader />
      <TopHearder title={t("bookingScanner")} /> */}

      <div className="bg-dark">
        <div className="container">
          <div className="inner-box">
            <div className="pt-5">
              <p className="text-center primary-color preview">
                {t("scanBarcode")}
              </p>

              {scanResult ? (
                <div>
                  <p>
                    Success: <a href={scanResult}>{scanResult}</a>
                  </p>
                  <p>Serial Number: {scanResult.slice(-16)}</p>
                </div>
              ) : (
                <div>
                  <div id="reader"></div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookingScanner;
