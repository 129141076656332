import { useParams } from "react-router-dom";

import React, { useEffect, useState } from "react";
// decode the JWT
import jwtDecode from "jwt-decode";
import {
  commentSelectedInstantReviewPost,

  deleteSelectedCommentInstantPost,
  getInstantsReviewDetails,
  getInstantsReviewcomments,
  updateSelectedCommentInstantPost,
} from "../../services/instant";
import { InstantReviewModel } from "../../domain/model/instant-review-model";
import InstantReviewPostCommentsModel from "../../domain/model/instant-review-comment-model";
import moment from "moment";
import FormErrorMessage from "../reusable/FormErrorMessage";
import CustomNavigation from "../reusable/Navigation";
import AccountTypeConst from "../../domain/enum/account-type";
import LocalStorageKey from "../../constants/LocalStorageKey";
import ChatCard from "../subcomponents/mixed/ChatCard";
import { Carousel, Modal, Spinner } from "react-bootstrap";

import nophoto from "../../assets/imgs/nophoto.jpeg";
import StickerData from "./StickerList";
import CustomHeader from "../reusable/Header";
import TopHearder from "../reusable/TopHearder";
import { useTranslation } from "react-i18next";

const InstantReviewDetail = () => {
  const isMaster = window.location.pathname.includes("master");
  const { id } = useParams(); // Use this id to get data from backend
  const [isNavOpen, setIsNavOpen] = React.useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [instantReviews, setInstantReviews] =
    useState<InstantReviewModel | null>();
  const { t } = useTranslation();

  const [comments, setComments] =
    React.useState<InstantReviewPostCommentsModel | null>();
  const [addComment, setAddComment] = useState("");
  const [totalComments, setTotalComments] = useState(0);
  const [str, setStr] = useState(instantReviews?.stickers);

  // console.log("instantReviews ", JSON.stringify(instantReviews));
  // console.log("instantReviews stickers ", instantReviews?.stickers);

  const [contentValidated, setContentValidated] = useState<string>();


  const [isDeleteAdModalOpen, setIsDeleteAdModalOpen] = useState(false);
  const [selectedCommentId, setSelectedCommentId] = useState(-1);
  const [selectedPostTitle, setSelectedPostTitle] = useState("");

  // decode the Token
  const token = localStorage.getItem(LocalStorageKey.token);
  const loggedInAuthorId = localStorage.getItem(
    LocalStorageKey.isLogin
  ) as string;
  const userProfilePicture = localStorage.getItem(
    LocalStorageKey.userProfilePicture
  );
  const decodedToken = jwtDecode(token as string) as { user_id: number };
  const currentUserId = decodedToken?.user_id;

  const handleAddComment: React.ChangeEventHandler<HTMLTextAreaElement> = (
    event
  ) => {
    const val = event.target.value;

    if (val.length > 0) {
      setContentValidated("");
    } else {
      setContentValidated("Content cannot be empty!");
    }
    setAddComment(val);
  };

  const handleInput = (event: React.FormEvent<HTMLTextAreaElement>) => {
    const inputText = event.currentTarget.value;
    if (inputText.length <= 100) {
      setAddComment(inputText);
    }
  };

  useEffect(() => {
    // console.log("sdfjgdfsadf" + id);
    getInstantsReviewDetails(id).then((res) => {
      if (res != null) {
        setInstantReviews(res!);
      }
    });

    getInstantsReviewcomments(Number(id)).then((res) => {
      if (res != null) {
        setTotalComments(res["item(s)"].length);
        setComments(res!);
      }
    });
  }, []);

  //  add comment on post
  const OnCommentPost = async () => {
    const payload = {
      comment: addComment,
      author_photo: localStorage.getItem(LocalStorageKey.userProfilePicture),
      rev_id: Number(id),
    };

    if (addComment?.length > 0) {
      commentSelectedInstantReviewPost(payload).then((isSuccess) => {
        if (isSuccess) {
          getInstantsReviewcomments(Number(id)).then((res) => {
            if (res != null) {
              setComments(res!);
              setTotalComments(res["item(s)"].length);
              setAddComment("");
            }
          });
        }
      });
    } else {
      if ((addComment?.length ?? 0) > 0) {
        setContentValidated("");
      } else {
        if ((addComment?.length ?? 0) <= 0) {
          setContentValidated("Content cannot be empty!");
        }
      }
    }
  };

  const deleteModal = (id: number) => {
    setIsDeleteAdModalOpen(true);
    setSelectedCommentId(id);

    // console.log(id);
  };

  //  delete selected post
  const deleteComment = () => {
    setIsLoading(true);
    // console.log(selectedCommentId);

    deleteSelectedCommentInstantPost(selectedCommentId).then((isSuccess) => {
      if (isSuccess) {
        setIsDeleteAdModalOpen(false);
        setIsLoading(false);
        getInstantsReviewcomments(Number(id)).then((res) => {
          if (res != null) {
            setComments(res!);
            setTotalComments(res["item(s)"].length);
            setAddComment("");
          }
        });
      }
    });
  };

  //  update selected post
  const updateSelectedComment = (inst_cmt_id: any, editedContent: any) => {
    // console.log(inst_cmt_id);

    const payload = {
      inst_cmt_id: inst_cmt_id,
      comment: editedContent,
    };
    updateSelectedCommentInstantPost(payload, payload.inst_cmt_id).then(
      (isSuccess) => {
        if (isSuccess) {
          setIsDeleteAdModalOpen(false);
          setIsLoading(false);
          getInstantsReviewcomments(Number(id)).then((res) => {
            if (res != null) {
              setComments(res!);
              setTotalComments(res["item(s)"].length);
              setAddComment("");
              setEditedContent("");
            }
          });
        }
      }
    );
  };

  // commnet list

  const commentList = () => {
    return (
      comments &&
      comments["item(s)"] &&
      comments["item(s)"]
        .sort(
          (a, b) => moment(b.created).valueOf() - moment(a.created).valueOf()
        )
        .map((data, i) => (
          <ChatCard
            key={i}
            fans={data.author_id}
            inst_cmt_id={data.inst_cmt_id}
            photo={
              data.author_photo === null
                ? "/img/blank-profile-picture.png"
                : loggedInAuthorId === "true" && userProfilePicture !== null
                ? userProfilePicture
                : data.author_photo
            }
            nickname={data.author_name}
            content={data.comment}
            date={data.created}
            currentUser={
              data.author_id === currentUserId
                ? (data.author_id as number)
                : undefined
            }
            deleteComments={() => deleteModal(data.inst_cmt_id)}
            editedContent={editedContent}
            handleContentChange={handleContentChange}
            updateComments={() =>
              updateSelectedComment(
                data.inst_cmt_id,
                editedContent || data.comment
              )
            }
            onClick={() => {}} //
          />
        ))
    );
  };

  const [editedContent, setEditedContent] = useState<string>(
    (comments && comments["item(s)"] && comments["item(s)"][0]?.comment) || ""
  );

  const handleContentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newContent = e.target.value;
    setEditedContent(newContent);
    // console.log(newContent);
  };

  const allMedia = () => {
    const imagesAndVideos = [
      ...(instantReviews?.media?.image || []),
      ...(instantReviews?.media?.video || []),
    ];
    const mediaData = imagesAndVideos.map((item) => ({
      url: item.cache_url,
      type: item.type,
    }));
    return mediaData;
  };

  // const stickerList = StickerData();
  const stickerList: any[] = StickerData();
  return (
    <>
      <CustomNavigation
        isOpen={isNavOpen}
        onCallback={(value) => {
          setIsNavOpen(value);
        }}
        accountType={
          isMaster ? AccountTypeConst.Master : AccountTypeConst.Client
        }
      />

      <CustomHeader />
      <TopHearder title={t("ViewMyInstantReview")} />

      <div className="bg-dark">
        <div className="container py-5">
          {/* <div className="inner-box pt-4 pb-4"> */}
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="row mir-box">
                <div className="col-2">
                  <span className="ranking">
                    <i className="fa-solid fa-crown"></i>
                    <a>7</a>
                  </span>
                </div>

                <div className="col-10">
                  <ul className="justify-content-start">
                    <li>
                      <span>{instantReviews?.author_name}</span>
                      <div className="clt-img">
                        <img
                          src={
                            instantReviews?.author_photo
                              ? instantReviews?.author_photo
                              : "/img/blank-profile-picture.png"
                          }
                          alt="Author photo"
                        />
                      </div>
                    </li>
                    <li>
                      <ul className="border-0">
                        {stickerList.map((stickerItem: any, index: any) => {
                          const enName = stickerItem.enName;
                          const emojis = stickerItem.emojis;
                          const name = stickerItem.name;
                          const a = enName.toLowerCase();
                          const matchedEmoji = emojis.find((emoji: any) => {
                            return (emoji.rate = instantReviews?.stickers[a]);
                          });

                          // Check if a matching emoji was found
                          if (matchedEmoji) {
                            return (
                              <li className="emojis pe-3 px-lg-3" key={index}>
                                <li className="text-8 text-white">{name}</li>
                                <img
                                  src={matchedEmoji.emojiIcon}
                                  alt=""
                                  style={{ height: 25 }}
                                />
                                <li className="text-8 primary-color mt-1 text-center">
                              {/* {matchedEmoji.rate} */}
                            </li>
                              </li>
                            );
                          }

                          return null; // Return null if no matching emoji was found
                        })}
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="instant-banner">
                <Carousel>
                  {allMedia().length === 0 ? (
                    <Carousel.Item>
                      <img
                        src={nophoto}
                        alt="author_photo h"
                        className="w-100"
                        style={{ maxHeight: 200 }}
                      />
                    </Carousel.Item>
                  ) : (
                    allMedia().map((mediaItem, index) => (
                      <Carousel.Item key={index}>
                        {mediaItem.type === "MP4" ||
                        mediaItem.type === "MOV" ||
                        mediaItem.type === "WMV" ||
                        mediaItem.type === "WEBM" ? (
                          // If the media is a video (MP4 format), display a video element.
                          <video controls className="w-100 h-50">
                            <source src={mediaItem.url} type="video/mp4" />
                          </video>
                        ) : (
                          // If the media is an image, display an image element.
                          <img
                            src={mediaItem.url ? mediaItem.url : nophoto}
                            alt="author_photo hhh"
                            className="w-100 h-50"
                          />
                        )}
                      </Carousel.Item>
                    ))
                  )}
                </Carousel>
              </div>

              <div className="row c-voice">
                <div className="col-12">
                  <h4>{instantReviews?.author_name}</h4>
                </div>
                <div className="col-8">
                  <div className="clt-img-profile-sm">
                    <img
                      src={
                        instantReviews?.author_photo
                          ? instantReviews?.author_photo
                          : "/img/blank-profile-picture.png"
                      }
                      alt="Author photo"
                    />
                  </div>
                  <audio>
                    <source src="/img/audio.wav" type="audio/ogg" />
                    <source src="/img/audio.wav" type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
                </div>
                <div className="col-4 text-end">
                  <div className="like-box">
                    <i className="fa-regular fa-thumbs-up"></i>
                    <span>{instantReviews?.like}</span>
                  </div>
                </div>
              </div>

              <div className="instant-title pt-3">
                <h2>
                  {instantReviews?.title
                    ? instantReviews?.title
                    : " Instant Title"}{" "}
                  <span>{instantReviews?.created} </span>
                </h2>
                <p>{instantReviews?.content}</p>
                <div className="row">
                  <div className="col-12 ">
                    <span> Comments ({totalComments})</span>
                  </div>
                </div>
              </div>

              <div className="row align-items-center">
                <div className=" col-lg-10 col-9 ">
                  <div className="post-box ">
                    <textarea
                      rows={5}
                      className="form-control form-control-cus ACD"
                      value={addComment.slice(0, 99)}
                      onChange={handleAddComment}
                      onInput={handleInput}
                    ></textarea>
                    <span
                      className="pull-right label label-default"
                      id="count_message"
                    >
                      {addComment.length} / 100
                    </span>
                  </div>
                </div>
                <div className=" col-lg-2 col-3 text-end">
                  <button
                    className="btn primary-button rounded-button"
                    onClick={OnCommentPost}
                  >
                    Comment
                  </button>
                </div>
                {/* <p>{JSON.stringify(comments)}</p> */}

                <div className="col-12">
                  {contentValidated && (
                    <>
                      <FormErrorMessage message={contentValidated} />
                    </>
                  )}
                </div>
                <div className="row  pe-0 pt-4">{commentList()}</div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>

      <Modal show={isDeleteAdModalOpen}>
        <Modal.Body>
          <Modal.Title>
            <center>
              <h5 className="modal-title">
                Are you sure want to delete this instant review comment?
                {/* <p className="pt-3 primary-color"> {selectedPostTitle}</p> */}
              </h5>
            </center>
            <div style={{ height: 10 }}></div>
          </Modal.Title>
        </Modal.Body>

        <Modal.Footer>
          <div className="d-flex justify-content-center align-items-center">
            <button
              type="button"
              className="btn-modal-close1 mt-3 "
              data-bs-dismiss="modal"
              onClick={() => setIsDeleteAdModalOpen(false)}
            >
              No
            </button>
            <div style={{ width: 50 }}></div>
            <button
              type="button"
              className="primary-button button mt-3 w-100"
              data-bs-dismiss="modal"
              onClick={deleteComment}
            >
              {/* Yes */}
              {isLoading ? <Spinner size="sm" /> : "Yes"}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

// "/img/blank-profile-picture.png"

export default InstantReviewDetail;
