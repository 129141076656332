import Snackbar from "awesome-snackbar";
import axios from "../axios"
import { HeartReceivingModel } from "../../domain/model/heart-receiving-model";
import { walletTranParams } from "./one-coin";

export const findHeartTransactions = async (params: walletTranParams, signal?: AbortSignal) => {

    console.log("Heart Tran data ", params);
    try {
        const data = await axios.get(`product/heart/transactions`, { params, signal });
        return data.data
        // return data.data['item(s)'] as HeartTransactionModel[];
    } catch (e) {
        new Snackbar("An error occured");
        return [];
    }
}

export const getHeartBalance = async (signal?: AbortSignal) => {
    try {
        const data = await axios.get("/product/heart/balance", {signal});

        return data.data['balance'];
    } catch (e) {
        return 0;
    }
}

export const getHeartRanking = async (signal?: AbortSignal): Promise<Ranking> => {
    try {
        const data = await axios.get("/product/heart/rank",{signal});

        return data.data;
    } catch (e) {
        new Snackbar("An error occured");
        return {};
    }
}

export const purchaseHeart = async (units: number, prod_id: number) => {
    try {
        const response = await axios.post("/product/heart/purchase", {
            units: units,
            prod_id: prod_id
        });

        // return true;
        if (response.data.result_code !== 200) {
            return response.data
        } else {
            return response.data
        }
    } catch (e: any) {
        new Snackbar(e.error.reason);
        return false;
    }
}

export const getHeartHistories = async () => {
    try {
        const data = await axios.get("/product/heart/histories");

        return data.data['item(s)'] as HeartReceivingModel[];
    } catch (e) {
        // new Snackbar("An error occured");

        return [];
    }
}

export const sendHeart = async (data: any) => {
    try {
        const response = await axios.post("/product/heart/send", {
            prod_id: data.prod_id,
            post_id: data.post_id,

        });
        return response.data;
    } catch (e) {
        new Snackbar("An error occured");

        return [];
    }
}