// To parse this data:
//
//   import { Convert, CreateUserParamsModel } from "./file";
//
//   const createUserParamsModel = Convert.toCreateUserParamsModel(json);

import { AdImages } from "./client-profile-model";

export interface CreateUserParamsModel {
    verification_key?: string;
    user_type?: number;
    password?: string;
    email?: string;

    phone_number?: string;
    nickname?: string;
    zh_name?: string;
    en_name?: string;
    details?: Details;
    userType?: string;
    user_role?: string;
    telegram?: string;
    role?: string;
    // language?: any;
    language?: object;
    shop_details?: ShopDetails;
    ad?: number;
    blog?: number;
    rotate?: number;
    ad_status?: number;
    blog_status?: number;
    blog_payment?: boolean;
    ad_payment?: boolean;
    master_type?: any;

    // NEW
    photo?: string;
    ext?: any;
    user_id?: string;
    // PHONE FOR MASTER
    country_phone_code?: string

    //   New 26 November
    adm_status?: number;
    ipm_status?: number;
    what_app?: string;
    //   telegram?: string;
    free_text?: number;
    master_id?: number;
    master_en_name?: string;
    cs_en_name?: string;
    cs_id?: number;

}

export interface Details {
    type?: string;
    nationality?: string;
    age?: number;
    price?: number;
    currency?: string;
    // services?: string[];
    operating_hours?: OperatingHour[];
    body_dimension?: BodyDimension[];
    address?: Address;
    contact_info?: ContactInfo;
    self_description?: string;
    self_desc?: string;
    welcome_foreigner?: boolean;
    welcome_pd?: boolean
    payment_registration?: string[];
    basic_desc?: BasicDesc;
    advance_desc?: AdvanceDes;
    blog_details?: BlogDetails;
    // NEW
    ad_images?: AdImages
    voice_memo?: string[]
    real_images?: string[]
    street_image?: string[]
}

export interface ContactInfo {
    country_phone_code: string;
    phone_number?: string;
    need_caller_display?: boolean;
    book_in_advance?: boolean;
    call_to_open_door?: boolean;
}

// export interface Address {
//     country?: string;
//     street?: string;
//     region1?: string;
//     region2?: string;
//     address_street?: string;
//     building?: string;
//     room?: string;
//     block?: string;
//     description?: string;
//     floorC?: string;
//     floorE: string;
// }
export interface Address {
    street_name?: string;
    address_street?: string;
    building?: string;
    room?: string;
    floorC?: string;
    block?: string;
    floorE?: string;
    description?: string;
}

export interface BodyDimension {
    type?: string;
    size?: number;
    conversion?: string;
}
export interface BodyDimension1 {
    // type?: string;
    size?: any;

}

export interface OperatingHour {
    day?: string;
    open_hours?: string;
    close_hours?: string;
    timezone?: string;
    rest?: boolean;
}
export interface OperatingHours1 {
    Monday?: [string, string];
    Tuesday?: [string, string];
    Wednesday?: [string, string];
    Thursday?: [string, string];
    Friday?: [string, string];
    Saturday?: [string, string];
    Sunday?: [string, string];
}


export interface ShopDetails {
    shop_name?: string;
    shop_zh_name?: string;
    shop_banner?: string;
}

export interface BasicDesc {
    promotion?: object;
    promotion_plan?: string;
    special_cat?: string;
    blog_details?: string;
    type?: string;
    nationality?: string;
    age?: number;
    // language?: object;
    language?: string[];
    price?: number;
    operating_hour?: any;
    height?: string;
    country?: string;
    currency?: string;
    region1?: string;
    region2?: string;
    phone?: string;

    country_phone_code?: string;
    book_in_advance?: boolean;
    call_to_open_door?: boolean;
    need_caller_display?: boolean;
    self_desc?: string;
    // foreigner?: boolean;
    // pd?: boolean;
    welcome_foreigner?: boolean;
    welcome_pd?: boolean;
}
export interface BasicDesc {
    promotion?: object;
    promotion_plan?: string;
    special_cat?: string;
    type?: string;
    nationality?: string;
    age?: number;
    // language?: object;
    language?: string[];
    price?: number;
    operating_hour?: any;
    height?: string;
    country?: string;
    currency?: string;
    region1?: string;
    region2?: string;
    phone?: string;
    country_phone_code?: string;
    book_in_advance?: boolean;
    call_to_open_door?: boolean;
    need_caller_display?: boolean;
    self_desc?: string;
    foreigner?: boolean;
    pd?: boolean;
}

export interface AdvanceDes {
    c_size?: string;
    c_letter?: string;
    w_size?: string;
    h_size?: string;
    service_type?: string[];
    services?: string[];
}
export interface BlogDetails {
    basic_desc?: string;

}

// Converts JSON strings to/from your types
export class Convert {
    public static toCreateUserParamsModel(json: string): CreateUserParamsModel {
        return JSON.parse(json);
    }

    public static createUserParamsModelToJson(value: CreateUserParamsModel): string {
        return JSON.stringify(value);
    }
}
