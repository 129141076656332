import RegistrationSteps from "../navigation/RegistrationSteps";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import CreateUserParams from "../../../domain/model/create-user-params";
import MasterAccountDetailsType from "../../../domain/enum/master-account-details-type";
import { findCoinBalance } from "../../../services/products/one-coin";
import AppRoutePath from "../../../constants/AppRoutePath";
import LocalStorageKey from "../../../constants/LocalStorageKey";

function LoadCoin() {
	const [showModal, setShowModal] = useState(false);
	const [coinBalance, setCoinBalance] = useState(0);
	const [isLoadingBalance, setIsLoadingBalance] = useState(true);
	const navigate = useNavigate();
	const createUserParams = CreateUserParams.getInstance();
	const isLogin =
		(localStorage.getItem(LocalStorageKey.isLogin) ?? "false") === "true";


	useEffect(() => {
		async function fetchCoinBalance() {

			console.log("Lofff ");
			
			const result = await findCoinBalance();
			setCoinBalance(result);
			setIsLoadingBalance(false);
		}
		fetchCoinBalance();
	}, []);


	const handleDone = () => {
		switch (createUserParams.data.user_type) {
			case 1:
				navigate(`${AppRoutePath.clientMainPage}/success`);
				break;
			case 2:
				// navigate(
				// 	`/master/account/details/${MasterAccountDetailsType.Register.path}`
				// );
				navigate(`${AppRoutePath.masterAccount}`);
				break;
			default:
				break;
		}
	};

	return (
		<>
			<header>
				<div className='title'>OneCoin Reload</div>
			</header>

			<div className='bg-dark'>
				<div className='container'>
					<div className='inner-box'>
						<div className='accordion pt-3' id='accordionExample'>
							<RegistrationSteps step={1} />
						</div>

						<div className='tab-content'>
							<div id='headingTwo'></div>
							<div
								id='collapseTwo'
								className='collapse show'
								aria-labelledby='headingTwo'
								data-bs-parent='#accordionExample'
							>
								<div className='account-box px-5 mt-5 align-items-center'>
									<div className='account active-account w-100'>
										<div className='card mb-5 mb-lg-0 py-3 text-center bg-white'>
											<div className='card-body'>
												<h5 className='balance-title mb-0'>OneCoin Balance</h5>
												{isLoadingBalance ?
													<Spinner size="sm" /> :
													<>
														<h5 className='balance-value d-flex align-items-center justify-content-center gap-1'>
															<p>$</p>
															<p>{coinBalance ?? "N/A"} </p>
														</h5>
														<Link to='/reload-coin'>
															<button className='btn primary-button rounded-button abs-btn coin-btn w-70 m-auto'>
																<i className='fa fa-shopping-cart'></i> Reload
																OneCoin
															</button>
														</Link>
													</>
												}
											</div>

										</div>
									</div>
								</div>
							</div>
						</div>

						<div>
							<p className='note mt-5'>
								if you have any questions, <br /> please contact our customer
								service TG : XXXXXXXX
							</p>
						</div>

						<div className='row'>
							<div className='col-6 text-center'>
								<Button
									variant='primary'
									onClick={() => setShowModal(true)}
									className='primary-button button mt-3 w-100'
									data-bs-toggle='modal'
									data-bs-target='#myModal'
								>
									Cancel
								</Button>
							</div>
							<Modal show={showModal} onHide={() => setShowModal(false)}>
								<Modal.Body>
									<div className='d-flex justify-content-center align-items-center'>
										<Modal.Title>
											<h5 className='modal-title text-center'>
												Confirmed to cancel new account registration?
											</h5>
											<Link to='/'>
												<Button className='secondary-button button mt-3 w-100'>
													Yes
												</Button>
											</Link>
											<Button
												variant='primary'
												className='primary-button button mt-3 w-100'
												onClick={() => setShowModal(false)}
											>
												No
											</Button>
										</Modal.Title>
									</div>
								</Modal.Body>
								<Modal.Footer></Modal.Footer>
							</Modal>
							<div className='col-6 text-center'>
								<Button
									variant='primary'
									className='primary-button button mt-3 w-100'
									onClick={handleDone}
								>
									Next
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default LoadCoin;
