import * as React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ClientAddDetailsType from "../../domain/enum/client-add-details-type";
import "../../index.css";

import { getClientProfile } from "../../services/client/client-profile";
import { ClientProfileModel } from "../../domain/model/client-profile-model";

import "../../../node_modules/@fortawesome/fontawesome-free/css/all.min.css";
import "../../css/style.css";
import { getFollowerList } from "../../services/client/client-follow";
import AdRealPhotoGallery from "../reusable/AdRealPhotoGallery";
import { getAdsMaster } from "../../services/master/master-ad";
import { AdMedia } from "../../domain/model/ad-media";
import { findAdMedia, findVoiceMemo } from "../../services/ad-media";
import AppRoutePath from "../../constants/AppRoutePath";

import LocalStorageKey from "../../constants/LocalStorageKey";
import SizedBox from "../reusable/Sizedbox";
import CustomHeader from "../reusable/Header";

//  Lang
import { useTranslation } from "react-i18next";
import Snackbar from "awesome-snackbar";
import TopHearder from "../reusable/TopHearder";

import noPhotos from "../../assets/imgs/nophoto.jpeg";
import { formatMobileNumber } from "../../helper/format-helper";
import ScreenLoaders from "../reusable/ScreenLoader";

export interface IClientAddDetailsPageProps {}
interface AdPhoto {
  cache_url: string;
}

export default function ClientEditDetailsPage(
  props: IClientAddDetailsPageProps
) {
  const isMaster = window.location.pathname.includes("master");
  const type = ClientAddDetailsType.getPageTypeByPath(useParams().type ?? "");
  const id = useParams().id;
  const [flower, setFlower] = React.useState(0);
  const [followerCount, setFollowerCount] = React.useState(0);
  const [clientProfile, setClientProfile] =
    React.useState<ClientProfileModel | null>(null);
  const [voiceMemo, setVoiceMemo] = React.useState<AdMedia>();
  const [profilePhoto, setProfilPhoto] = React.useState("");
  const [adPhotos, setAdPhotos] = React.useState<AdPhoto[]>([]);
  const [AdPhotoMain, setAdPhotoMain] = React.useState("");
  const [AdsClientProfiles, setAdsClientProfiles] = React.useState<any[]>([]);
  const [isNavOpen, setIsNavOpen] = React.useState(true);
  const navigate = useNavigate();
  const [notifCount, setNotifCount] = React.useState(0);
  const langType = localStorage.getItem("i18nextLng");
  const { t } = useTranslation();

  const [currentImageIndex, setCurrentImageIndex] = React.useState(0);

  const [showOpera, setShowOpera] = React.useState(false);
  const [showOperaMore, setShowOperaMore] = React.useState(t("more"));
  const [backgroundClass, setBackgroundClass] = React.useState("");
  const [profilePhotoMain, setProfilePhotoMain] = React.useState(null);
  const [combinedArray, setCombinedArray] = React.useState<string[]>([]);
  const [isLoadingScreen, setIsLoadingScreen] = React.useState(false);
  const [visibleItems, setVisibleItems] = React.useState(false);

  React.useEffect(() => {
    const data = clientProfile?.details?.advance_desc?.services || [];
    const newArray = Object.values(data).flat();

    const sortedData = newArray.sort((a, b) => {
      const numberA = parseInt(a.substring(1, a.indexOf("|")), 10);
      const numberB = parseInt(b.substring(1, b.indexOf("|")), 10);
      return numberA - numberB;
    });

    setCombinedArray(sortedData);
  }, [clientProfile]);

  const recordsToShow = visibleItems
    ? combinedArray
    : combinedArray.slice(0, 6);

  const handleShowMore = () => {
    setVisibleItems(!visibleItems);
  };

  React.useEffect(() => {
    setIsLoadingScreen(true);
    let adProfiles: any = [];
    // findFlowerBalance().then((balance) => {
    //   try {
    //     const balanceNum = Number(sumBalances(balance)) as number;
    //     setFlower(balanceNum ?? 0);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // });

    findVoiceMemo(id).then((e) => setVoiceMemo(e ?? undefined));

    if (isMaster && id != null) {
      getAdsMaster(id).then((ad) => {
        if (ad != null) {
          setClientProfile(ad);
          if (ad?.photo != null) {
            setProfilePhotoMain(ad?.photo);
            adProfiles = adProfiles.concat({ cache_url: ad?.photo });
          } else {
            setProfilePhotoMain(null);
          }
          setIsLoadingScreen(false);
        }
        setIsLoadingScreen(false);
      });
    } else {
      getClientProfile().then((profile) => {
        if (profile != null) {
          setClientProfile({ ...profile });
          if (profile?.photo) {
            setProfilePhotoMain(profile?.photo);
            adProfiles = adProfiles.concat({ cache_url: profile?.photo });
          } else {
            adProfiles = adProfiles.concat({ cache_url: noPhotos });
            setProfilePhotoMain(null);
          }
          setIsLoadingScreen(false);
        } else {
          setIsLoadingScreen(false);
        }
      });
    }
    findAdMedia(id).then((res: AdMedia[] | null) => {
      if (res != null) {
        const imageRes = res.filter(
          (item) =>
            item.type &&
            (item.type.includes("JPG") ||
              item.type.includes("JPEG") ||
              item.type.includes("PNG"))
        );

        for (let idx = 0; idx < res.length; idx++) {
          const item = res[idx];
          if (item.flag && item.media_cat === 2) {
            setAdPhotoMain(item?.cache_url);
            break;
          }
        }

        adProfiles = adProfiles.concat(imageRes);
        setAdPhotos(adProfiles);
      }
    });
    getFollowerList().then((follower) => {
      if (follower != null) {
        setFollowerCount(follower.total ?? 0);
      }
    });
  }, []);

  React.useEffect(() => {
    let intervalId: any;

    if (clientProfile?.ad_status === 1 && clientProfile?.rotate === 1) {
      intervalId = setInterval(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % adPhotos.length);
      }, 1000);

      return () => clearInterval(intervalId);
    }

    clearInterval(intervalId);
  }, [adPhotos.length, clientProfile?.ad_status, clientProfile?.rotate]);

  // Function to randomly select a background class
  const getRandomBackgroundClass = () => {
    const classes = ["ads_bg1", "ads_bg2", "ads_bg3", "ads_bg4"];
    const randomIndex = Math.floor(Math.random() * classes.length);
    return classes[randomIndex];
  };

  React.useEffect(() => {
    const randomBackgroundClass = getRandomBackgroundClass();
    setBackgroundClass(randomBackgroundClass);
  }, []);
  const [isFollowing, setIsFollowing] = React.useState(false);
  const handleFollowToggle = () => {
    setIsFollowing(!isFollowing);
    setFollowerCount(followerCount + (isFollowing ? -1 : 1));
  };
  // localStorage.setItem("isFollowing", JSON.stringify(isFollowing));

  // const serviceData = clientProfile?.details?.advance_desc?.services || [];

  const toggleOperators = () => {
    setShowOpera(!showOpera);
    setShowOperaMore(showOpera ? t("more") : t("less"));
  };

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  type OperatingHours = [string, string];

  type CompleteOperatingHourData = {
    [day: string]: OperatingHours;
  };

  const operatingHourData: { [day: string]: OperatingHours } =
    clientProfile?.details?.basic_desc?.operating_hour ?? {};

  // Ensure "Rest" for any missing days
  const completeOperatingHourData: CompleteOperatingHourData = {};
  for (const day of daysOfWeek) {
    completeOperatingHourData[day] = operatingHourData[day] || ["Rest", "Rest"];
  }

  const currentDay = new Date().toLocaleString("en-US", { weekday: "long" });
  const dayIndex = daysOfWeek.findIndex(
    (day) => day.toLowerCase() === currentDay.toLowerCase()
  );

  const translatedCurrentDay = daysOfWeek[dayIndex];

  const todayOperatingHour: OperatingHours = operatingHourData[
    translatedCurrentDay
  ] || ["Rest", "Rest"];

  return (
    <>
      {/* <CustomHeader title="Preview" /> */}
      <CustomHeader />
      <TopHearder title={t("preview")} />
      {isLoadingScreen && <ScreenLoaders />}
      <div className="bg-dark">
        <div className="container">
          <div className="">
            <div id="accordionExample">
              {/* {type === ClientAddDetailsType.Preview && (
                // <FixedRegisStep />
                <RegistrationSteps step={3} />
              )} */}
              <div className="tab-content">
                <div id="headingOne"></div>
                <div
                  id="collapseOne"
                  className="collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                ></div>
              </div>
              <div className="tab-content">
                <div id="headingTwo"></div>
                <div
                  id="collapseTwo"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                ></div>
              </div>
              <div className="tab-content">
                <div id="headingThree"></div>
                <div
                  id="collapseThree"
                  className="collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="card-body">your content goes here...</div>
                </div>
              </div>
              <div className="tab-content">
                <div id="headingFour"></div>
                <div
                  id="collapseFour"
                  className="collapse show"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div
                    className={`${
                      clientProfile?.blog_status === 0
                        ? "preview-container-1 py-3"
                        : `preview-container ${backgroundClass}`
                    }  `}
                  >
                    <div className="row pt-3">
                      <div className="col-1 col-md-2 col-lg-3"></div>
                      <div className="col-5 col-md-5 col-lg-4 ">
                        {clientProfile?.ad_status === 1 &&
                        clientProfile?.rotate === 1 ? (
                          <>
                            <div
                              className={`${
                                clientProfile?.blog_status === 0
                                  ? "d-none"
                                  : "preview-card-container"
                              } `}
                            ></div>
                            <img
                              className={`preview-card-img mb-3 ${
                                clientProfile?.blog_status === 0 ? "" : "br-5"
                              }`}
                              src={adPhotos[currentImageIndex]?.cache_url}
                              alt={`Ad Image ${currentImageIndex + 1}`}
                            />
                          </>
                        ) : (
                          <>
                            <div className="preview-card-container"></div>
                            <img
                              className={`preview-card-img mb-3 ${
                                clientProfile?.blog_status === 0 ? "" : "br-5"
                              }`}
                              src={
                                clientProfile?.photo !== null
                                  ? clientProfile?.photo
                                  : noPhotos
                              }
                              alt=""
                            />
                          </>
                        )}
                      </div>
                      <div className="col-6 col-md-5 col-lg-3 ">
                        <div className="text-white d-lg-flex d-md-flex align-items-center  mb-3">
                          <button className="btn primary-button rounded-1 ">
                            {t("follow")}
                          </button>
                          <p
                            className={` ${
                              backgroundClass === "ads_bg1"
                                ? "text-white "
                                : "primary-color"
                            }  ps-lg-3 ps-md-3 mb-0 text-12`}
                          >
                            {followerCount} {t("followers")}
                          </p>
                        </div>
                        <div className="text-white d-flex align-items-center  mb-3 prview-tele-div">
                          <i
                            className="fa-solid fa-square-phone text-pink-3 cursor-pointer"
                            style={{ fontSize: 50 }}
                          />
                          <p className="ps-lg-3 ps-3 mb-0 text-12 text-break">
                            <a
                              className={` ${
                                backgroundClass === "ads_bg1"
                                  ? "text-white "
                                  : " primary-color"
                              }  text-12 mb-0 pb-0`}
                              href={`tel:${clientProfile?.details?.basic_desc?.country_phone_code}${clientProfile?.details?.basic_desc?.phone}`}
                            >
                              +
                              {
                                clientProfile?.details?.basic_desc
                                  ?.country_phone_code
                              }
                              <br></br>
                              {formatMobileNumber(
                                clientProfile?.details?.basic_desc?.phone ?? ""
                              )}
                              {/* {clientProfile?.details?.basic_desc?.phone} */}
                            </a>
                          </p>
                        </div>

                        <div className="d-flex d-md-block d-lg-block">
                          <a
                            className="t-chat color-pink-2 cursor-pointer p-1 mb-3 prview-tchat-logo"
                            // onClick={() => handleTchats()}
                          >
                            <img
                              style={{ width: 25 }}
                              src="/img/tchat_newlogo.png"
                              className=""
                            />
                            <br />
                            {t("tchat")}
                          </a>

                          <div
                            className="card border-0 bg-transparent preview-flowers ms-3 ms-md-0 ms-lg-0"
                            style={{ width: 45, height: 45 }}
                          >
                            <img
                              src="/img/flower_icon.png"
                              className="card-img"
                              alt="..."
                            />
                            <div className="card-img-overlay text-12 text-white p-0 text-center d-flex align-items-center justify-content-center">
                              <span className="bk-grobs">
                                {clientProfile?.total_flower ?? 0}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="col-12 pb-0">
                        {clientProfile?.blog_status === 0 ? (
                          ""
                        ) : (
                          <img
                            src={bottomFlowerDesktop}
                            className={`bottom-flower d-lg-block d-md-block d-none ${
                              clientProfile?.blog_status === 0 ? "" : "br-15"
                            }`}
                            alt=""
                          />
                        )}
                        {clientProfile?.blog_status === 0 ? (
                          ""
                        ) : (
                          <img
                            src={bottomFlowerMobile}
                            className={`bottom-flower d-block d-md-none d-lg-none ${
                              clientProfile?.blog_status === 0 ? "" : "br-10"
                            }`}
                            alt=""
                          />
                        )}
                      </div> */}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12" style={{ textAlign: "center" }}>
                      {/* <span style={{color:"white"}}>{clientProfile?.zh_name}</span> */}

                      <div className="square3">
                        <span style={{ color: "white", fontSize: 16 }}>
                          {langType === "zh"
                            ? clientProfile?.zh_name
                            : clientProfile?.en_name}
                        </span>
                      </div>
                    </div>
                  </div>
                  <br />
                  <AdRealPhotoGallery userId={id} />
                  {clientProfile?.blog_status === 1 && (
                    <div className="service-div my-3">
                      <div className="service-content text-white">
                        <div className="row mb-2">
                          <div
                            className="col-12 text-center "
                            style={{
                              paddingLeft: 20,
                              paddingTop: 5,
                              color: "#E63EF8",
                            }}
                          >
                            {t("Service")}
                            <span className="text-white pe-3 text-12 ps-3">
                              {clientProfile?.details?.basic_desc?.currency && (
                                <>
                                  {langType === "zh"
                                    ? clientProfile?.details?.basic_desc?.currency.split(
                                        "|"
                                      )[1] + " "
                                    : clientProfile?.details?.basic_desc?.currency.split(
                                        "|"
                                      )[0] + " "}
                                </>
                              )}
                              {clientProfile?.details?.basic_desc?.price}
                            </span>
                          </div>

                          <div
                            className=" col-12 text-end pe-4"
                            style={{
                              paddingTop: 5,
                              marginTop: -30,
                            }}
                          >
                            {combinedArray.length > 6 && (
                              <small
                                className="text-white"
                                onClick={() => handleShowMore()}
                              >
                                {visibleItems ? t("less") : t("more")}
                              </small>
                            )}
                            {/* <small
                              className="text-white"
                              onClick={() => handleShowMore()}
                            >
                              {visibleItems ? t("less") : t("more")}
                            </small> */}
                          </div>
                        </div>

                        <div className="row px-3 pb-3 ">
                          {combinedArray.length > 0 ? (
                            combinedArray
                              .slice(0, recordsToShow.length)
                              .map((item: string) => (
                                <div className="col-2 p-1" key={item}>
                                  <div className="service-b py-2 tex-12 py-2 text-decoration-none  text-center">
                                    {langType === "zh"
                                      ? item.split("|")[1]
                                      : item.split("|")[0]}
                                  </div>
                                </div>
                              ))
                          ) : (
                            <div className="col-12 text-center">
                              {t("noDataInfo")}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {/* Location  */}
                  <div className="container text-center mb-4">
                    <div className="row text-white align-items-stretch d-flex  ">
                      <div className="col d-flex  align-items-center justify-content-center  text-decoration-underline rounded table-preview dec1 py-1 py-md-2">
                        {langType === "zh"
                          ? clientProfile?.details?.basic_desc?.type?.split(
                              "|"
                            )[1]
                          : clientProfile?.details?.basic_desc?.type?.split(
                              "|"
                            )[0]}
                      </div>
                      <div className="col d-flex  align-items-center justify-content-center  text-decoration-underline rounded table-preview">
                        <i className="fa-solid fa-location-dot" />
                      </div>
                      <div className="col d-flex  align-items-center justify-content-center  text-decoration-underline rounded table-preview dec1 py-1 py-md-2">
                        {langType === "zh"
                          ? clientProfile?.details?.basic_desc?.region1?.split(
                              "|"
                            )[1]
                          : clientProfile?.details?.basic_desc?.region1?.split(
                              "|"
                            )[0]}
                        ,
                        {langType === "zh"
                          ? clientProfile?.details?.basic_desc?.region2?.split(
                              "|"
                            )[1]
                          : clientProfile?.details?.basic_desc?.region2?.split(
                              "|"
                            )[0]}
                      </div>
                      <div className="col d-flex  align-items-center justify-content-center  text-decoration-underline rounded table-preview dec1 py-1 py-md-2">
                        {clientProfile?.details?.address?.building
                          ? clientProfile?.details?.address?.building
                          : "N/A"}
                        {/* {langType === "zh"
                          ? clientProfile?.details?.address?.building?.split(
                              "|"
                            )[1]
                          : clientProfile?.details?.address?.building?.split(
                              "|"
                            )[0]} */}
                      </div>
                    </div>
                  </div>
                  {/* Measurements */}
                  <div className="row px-3 my-3  ">
                    <div className="col-12 text-start text-14 text-white border-red-1 py-2 fst-italic">
                      <div className="row px-lg-3">
                        <div className="col ">
                          <p className="t-color-red ">{t("age")}</p>
                          <p className=" text-12">
                            {clientProfile?.details?.basic_desc?.age === 0
                              ? "N/A"
                              : clientProfile?.details?.basic_desc?.age}
                          </p>
                        </div>
                        <div className="col ">
                          <p className="t-color-red ">{t("nationality")}</p>
                          <p className=" text-12">
                            {langType === "zh"
                              ? clientProfile?.details?.basic_desc?.nationality?.split(
                                  "|"
                                )[1]
                              : clientProfile?.details?.basic_desc?.nationality?.split(
                                  "|"
                                )[0]}
                          </p>
                        </div>
                        <div className="col ">
                          <p className="t-color-red ">{t("height")}</p>
                          <p className=" text-12">
                            {clientProfile?.details?.basic_desc?.height}
                          </p>
                        </div>
                        <div className="col ">
                          <p className="t-color-red ">{t("Measurements")}</p>
                          <p className=" text-12">
                            {clientProfile?.details?.advance_desc?.c_size
                              ? clientProfile?.details?.advance_desc?.c_size +
                                "-"
                              : ""}

                            {clientProfile?.details?.advance_desc?.c_size
                              ? clientProfile?.details?.advance_desc?.h_size +
                                "-"
                              : ""}

                            {clientProfile?.details?.advance_desc?.c_size
                              ? clientProfile?.details?.advance_desc?.w_size
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Address */}
                  <div className="row px-3 mt-3">
                    <div className="col-12 text-start text-14 fw-bold text-white">
                      <p className="text-start text-14 fw-bold t-color-red mb-2">
                        {t("Address")}

                        <span
                          className={`ms-2 p-2 py-1 rounded-pill ${
                            clientProfile?.details?.basic_desc
                              ?.book_in_advance ||
                            clientProfile?.details?.basic_desc
                              ?.call_to_open_door
                              ? "primary-border-1"
                              : ""
                          }  text-10`}
                        >
                          {clientProfile?.details?.basic_desc
                            ?.book_in_advance ||
                          clientProfile?.details?.basic_desc?.call_to_open_door
                            ? "* "
                            : ""}
                          {clientProfile?.details?.basic_desc?.book_in_advance
                            ? t("pleaseBookingInAdv") + ". "
                            : " "}

                          {clientProfile?.details?.basic_desc?.call_to_open_door
                            ? t("callOpenToCallDoor") + ". "
                            : " "}
                        </span>
                      </p>
                      <p>
                        {langType === "zh"
                          ? clientProfile?.details?.basic_desc?.region2?.split(
                              "|"
                            )[1] + ", "
                          : clientProfile?.details?.basic_desc?.region2?.split(
                              "|"
                            )[0] + ", "}{" "}
                        {clientProfile?.details?.address?.street_name + ", "}
                        {clientProfile?.details?.address?.building + ", "}
                        {clientProfile?.details?.address?.block}{" "}
                        {clientProfile?.details?.address?.floorE}
                        {clientProfile?.details?.address?.room}{" "}
                        {langType === "zh"
                          ? clientProfile?.details?.basic_desc?.country?.split(
                              "|"
                            )[1]
                          : clientProfile?.details?.basic_desc?.country?.split(
                              "|"
                            )[0]}{" "}
                        {/* {t("Room")}{" "} */}
                        {clientProfile?.details?.address?.floorC
                          ? ", 唐1樓"
                          : ""}
                      </p>
                      <p>
                        {" "}
                        {(clientProfile?.details?.address?.description ??
                          "") !== ""
                          ? "*" + clientProfile?.details?.address?.description
                          : ""}{" "}
                      </p>
                      {/* <p className="text-start text-12 fw-bold t-color-red text-decoration-underline">
                        {clientProfile?.details?.basic_desc?.book_in_advance ||
                        clientProfile?.details?.basic_desc?.call_to_open_door
                          ? "* "
                          : ""}
                        {clientProfile?.details?.basic_desc?.book_in_advance
                          ? t("pleaseBookingInAdv") + ". "
                          : " "}
                        {clientProfile?.details?.basic_desc?.call_to_open_door
                          ? t("callOpenToCallDoor")
                          : " "}
                      </p> */}
                    </div>
                  </div>

                  {/* Operating Hours */}
                  <div className="row px-3 mt-3">
                    <div className="col-12  text-start">
                      <p className="t-color-red fw-bold text-14 text-start">
                        {t("operatingHours")}
                      </p>
                    </div>
                    <div className="col-12  text-end less-more">
                      <p className="mb-0 text-14" onClick={toggleOperators}>
                        {" "}
                        {showOperaMore}
                      </p>
                    </div>

                    <div className="col-12 px-0">
                      {!showOpera && (
                        <>
                          <div className="text-12 fw-bold bg-dark3 text-white text-center rounded-2 p-3 text-capitalize">
                            <span> {currentDay} </span>
                            <span className="ps-2">
                              {" "}
                              {todayOperatingHour[0] == "" &&
                              todayOperatingHour[1] == ""
                                ? `- ${t("reset")}`
                                : todayOperatingHour[0] +
                                  "-" +
                                  todayOperatingHour[1]}{" "}
                            </span>
                          </div>
                        </>
                      )}
                      {showOpera && (
                        <>
                          <div className="text-10 fw-bold text-white border-red-2 p-1">
                            {Object.entries(completeOperatingHourData).map(
                              ([day, hours]: [string, [string, string]]) => (
                                <div
                                  key={day}
                                  className={
                                    day === currentDay ? "current-day" : ""
                                  }
                                >
                                  <div className="row">
                                    <div className="col">
                                      <p className="text-capitalize mb-0 text-start ps-2">
                                        {day}
                                      </p>
                                    </div>
                                    <div className="col">
                                      <p className="text-capitalize mb-0">
                                        {hours[0] === "" && hours[1] === "" ? (
                                          " Rest "
                                        ) : (
                                          <>
                                            {" "}
                                            {hours[0]} - {hours[1]}
                                          </>
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  {/* Languages Div */}
                  <div className="row px-3 my-3 ">
                    <div className="col-12 text-start text-14 text-white bg-dark3 rounded-1 py-2 fst-italic">
                      <div className="row ">
                        <div className="col-5 primary-color ">
                          {" "}
                          {t("language")}
                        </div>
                        <div className="col-6 ">
                          <div className="row">
                            {clientProfile?.details?.basic_desc?.language &&
                              Object.entries(
                                clientProfile.details.basic_desc.language
                              ).map(([key, data]) => (
                                <>
                                  <div className="text-white text-14 p-1 col">
                                    {langType === "zh"
                                      ? data.split("|")[1]
                                      : data.split("|")[0]}
                                  </div>
                                </>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Blog Details */}
                  {clientProfile?.blog_status === 1 && (
                    <div className="row px-3 mt-3">
                      <div className="col-12">
                        <p className="text-start text-14 fw-bold t-color-red mb-2">
                          {t("blogDetail")}
                        </p>
                      </div>
                      <div className="col-12 text-start text-14 text-white bg-dark3 rounded-1 py-2 fst-italic">
                        <p className="mb-1">
                          {(clientProfile?.details?.blog_details?.basic_desc ??
                            "") !== ""
                            ? clientProfile?.details?.blog_details?.basic_desc
                            : ""}
                        </p>
                      </div>
                    </div>
                  )}

                  {/* Self Description */}
                  <div className="row px-3 mt-3">
                    <div className="col-12">
                      <p className="text-start text-14 fw-bold t-color-red mb-2">
                        {t("selfDescription")}
                      </p>
                    </div>
                    <div className="col-12  border-red-1 py-2 ">
                      <p className="mb-1 fst-italic text-start text-14 text-white">
                        {(clientProfile?.details?.basic_desc?.self_desc ??
                          "") !== ""
                          ? clientProfile?.details?.basic_desc?.self_desc
                          : ""}{" "}
                      </p>

                      {clientProfile?.blog_status === 0 && (
                        <div className="row px-2">
                          <div className="service-div my-3">
                            <div className="service-content text-white p-2">
                              <div className="row">
                                <div
                                  className="col-12 text-center"
                                  style={{
                                    paddingLeft: 20,
                                    paddingTop: 5,
                                    color: "#E63EF8",
                                  }}
                                >
                                  {t("Service")}
                                  <span className="text-white pe-3 text-12 ps-3">
                                    {clientProfile?.details?.basic_desc
                                      ?.currency && (
                                      <>
                                        {langType === "zh"
                                          ? clientProfile?.details?.basic_desc?.currency.split(
                                              "|"
                                            )[1] + " "
                                          : clientProfile?.details?.basic_desc?.currency.split(
                                              "|"
                                            )[0] + " "}
                                      </>
                                    )}
                                    {/* $ */}
                                    {clientProfile?.details?.basic_desc?.price}
                                  </span>
                                </div>
                                <div
                                  className="col-12 text-end pe-4"
                                  style={{ paddingLeft: 20, paddingTop: 5 }}
                                >
                                  {combinedArray.length > 6 && (
                                    <small
                                      className="text-white"
                                      onClick={() => handleShowMore()}
                                    >
                                      {visibleItems ? t("less") : t("more")}
                                    </small>
                                  )}
                                </div>

                                <div className="col-12 mt-3 pb-3">
                                  <div className="row">
                                    {combinedArray.length > 0 ? (
                                      combinedArray
                                        .slice(0, recordsToShow.length)
                                        .map((item: string) => (
                                          <div className="col-2 p-1" key={item}>
                                            <div className="service-b py-2 tex-12 py-2 text-decoration-none  text-center">
                                              {langType === "zh"
                                                ? item.split("|")[1]
                                                : item.split("|")[0]}
                                            </div>
                                          </div>
                                        ))
                                    ) : (
                                      <div className="col-12 text-center">
                                        {t("noDataInfo")}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="col-12 mt-1 text-center">
                      <span className="bg-white t-color-red fw-bold  text-14 welcome-btn">
                        {t("WELCOME1")}
                      </span>

                      {clientProfile?.details?.basic_desc?.welcome_pd ||
                      clientProfile?.details?.basic_desc?.welcome_foreigner ? (
                        <span className="bg-red text-white fw-bold  text-14 welcome-btn-2">
                          {" "}
                          {clientProfile?.details?.basic_desc?.welcome_pd
                            ? t("pd") + ", "
                            : " "}
                          {clientProfile?.details?.basic_desc?.welcome_foreigner
                            ? t("foreigner")
                            : ""}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  {voiceMemo && (
                    <center>
                      <div className="my-5">
                        <audio controls={true}>
                          <source src={voiceMemo.cache_url} type="audio/ogg" />
                          Your browser does not support the audio element.
                        </audio>
                      </div>
                    </center>
                  )}

                  {type === ClientAddDetailsType.Preview && (
                    <div className="row mt-3">
                      <div className="col-6 text-center">
                        <button
                          className="primary-button button mt-3 w-100"
                          onClick={() => navigate(-1)}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="col-6 text-center">
                        <button
                          className="primary-button button mt-3 w-100"
                          onClick={() => {
                            if (profilePhotoMain !== null) {
                              isMaster
                                ? navigate(AppRoutePath.masterAccount)
                                : navigate(AppRoutePath.clientMainPage);

                              localStorage.setItem(
                                LocalStorageKey.isRegister,
                                String(false)
                              );
                            } else {
                              new Snackbar(
                                `${t("adMainProfilePhotoRequired")}`
                              );
                            }
                          }}
                        >
                          Confirm
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <SizedBox height={30} />
      </div>
    </>
  );
}
