import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import CreateUserParams from "../domain/model/create-user-params";
import { registerClient, registerMaster } from "../services/auth";
import RegistrationSteps from "./registration/navigation/RegistrationSteps";
import { formatMobileNumber } from "../helper/format-helper";
import {
  getAllRoles,
  getAllRolesDefault,
  getGuestToken,
  getTypesDefault,
} from "../services/modules";
import AppRoutePath from "../constants/AppRoutePath";
import { useTranslation, initReactI18next } from "react-i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: require("../locales/en.json") },
      zh: { translation: require("../locales/zh.json") },
    },
    fallbackLng: "en", // Default language fallback
    interpolation: {
      escapeValue: false, // Disable HTML escaping
    },
  });
function AccountType() {
  const langType = localStorage.getItem("i18nextLng");
  const isLoginWithEmail = localStorage.getItem("email_login");
  const [activeAccount, setActiveAccount] = useState("master");
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState("");
  const [showNextModal, setShowNextModal] = useState(false);
  const [mobileNumberFormated, setMobileNumberFormated] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [isHasEmail, setIsHasEmail] = useState("");
  const [isHasPhoneNumber, setIsHasPhoneNumber] = useState("");

  const createUserParams = CreateUserParams.getInstance();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [language, setLanguage] = useState("en"); // Default language is English
  const handleLanguageSwitch = () => {
    const newLanguage = language === "en" ? "zh" : "en";
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage); // Update the language setting
  };
  const [isLoading, setIsLoading] = useState(false);
  const [types, setTypes] = useState([]);
  const [masterRoles, setMasterRoles] = useState([]);
  useEffect(() => {
    getGuestToken().then((res) => {
      if (res != null) {
        localStorage.setItem("tokenGuest", res?.user_token);
      }
    });

    const delay = setTimeout(() => {
      // getTypesDefault().then((res) => {
      //   if (res != null) {
      //     setTypes(res);
      //   }
      // });
      getAllRolesDefault("Individual_Client|Individual_Client中文").then(
        (res) => {
          if (res != null) {
            setTypes(res);
          }
        }
      );
      getAllRoles().then((res) => {
        if (res != null) {
          const grpIdToFind = 5;
          const filteredRoles = Object.values(res)
            .filter((role) => role.grpId === grpIdToFind)
            .map((role) => role.roleName);
          setMasterRoles(filteredRoles);
        }
      });
    }, 500);

    // Clear the timeout when the component unmounts to prevent any potential memory leaks
    return () => clearTimeout(delay);
  }, []);

  const handleType = (e) => {
    const selectedValue = e.target.value;
    setError("");
    setSelectedType(selectedValue);
  };

  useEffect(() => {
    createUserParams.data.userType = selectedType;
    createUserParams.saveData();
  }, [selectedType]);

  const handleRole = (e) => {
    const selectedValue = e.target.value;
    setError("");
    setSelectedRole(selectedValue);
  };

  useEffect(() => {
    createUserParams.data.role = selectedRole;
    createUserParams.saveData();
  }, [selectedRole]);

  useEffect(() => {
    createUserParams.loadData();

    const getRegisterData = createUserParams.getData();
    setIsHasEmail(getRegisterData.email);
    setIsHasPhoneNumber(getRegisterData.phone_number);
    setMobileNumberFormated(
      `${createUserParams.data.country_phone_code} ${formatMobileNumber(
        createUserParams.data.phone_number || "888888888"
      )}`
    );
    handleChangeAccount("master");
  }, []);

  const ACCOUNT_TYPES = {
    superMaster: 3,
    master: 2,
    individual: 1,
  };

  const handleChangeAccount = (accountType) => {
    setActiveAccount(accountType);
    createUserParams.data.user_type = ACCOUNT_TYPES[accountType];
    createUserParams.data.userType = selectedType;
    createUserParams.data.role = selectedRole;

    createUserParams.saveData();
  };

  const handleNextBTN = async (event) => {
    if (activeAccount === "individual") {
      if (!selectedType) {
        setError(t("TypeSelectedError"));
        setIsLoading(false);
        return; // Prevent further execution
      } else {
        setShowNextModal(true);
      }
    }
    if (activeAccount === "master") {
      // setShowNextModal(true);
      if (!selectedRole) {
        setError(t("RoleSelectedError"));
        setIsLoading(false);
        return; // Prevent further execution
      } else {
        setShowNextModal(true);
      }
    }
  };
  const handleNext = async (event) => {
    setIsLoading(true);
    if (activeAccount === "individual") {
      if (!selectedType) {
        setError(t("TypeSelectedError"));
        setIsLoading(false);
        return; // Prevent further execution
      } else {
        setShowNextModal(true);
      }
      const isRegisterSeccess = await registerClient(
        createUserParams.data.verification_key,
        createUserParams.data.password,
        createUserParams.data.country_phone_code,
        createUserParams.data.phone_number,
        createUserParams.data.en_name,
        createUserParams.data.zh_name,
        createUserParams.data.userType,
        createUserParams.data.email,
        createUserParams.data.accountType
      );

      if (isRegisterSeccess) {
        if (isHasEmail !== "") {
          navigate(AppRoutePath.clientAddDetails);
        } else {
          navigate(AppRoutePath.loadCoinEmpty);
        }
      } else {
        navigate(AppRoutePath.login);
      }
    }

    if (activeAccount === "master") {
      if (!selectedRole) {
        setError(t("RoleSelectedError"));
        setIsLoading(false);
        return;
      } else {
        setShowNextModal(true);
      }
      const isRegisterSeccess = await registerMaster(
        createUserParams.data.verification_key,
        createUserParams.data.password,
        createUserParams.data.country_phone_code,
        createUserParams.data.phone_number,
        createUserParams.data.en_name,
        createUserParams.data.zh_name,
        createUserParams.data.role,
        createUserParams.data.email,
        createUserParams.data.accountType
      );
      if (isRegisterSeccess) {
        // navigate(AppRoutePath.loadCoinEmpty);
        navigate(AppRoutePath.masterCreateShop);
      } else {
        navigate(AppRoutePath.login);
      }
    }
    setIsLoading(false);
  };

  const handleRedirect = async () => {
    navigate(AppRoutePath.login);
  };

  return (
    <>
      <header className="justify-content-center">
        <div className="title">{t("acctType")}</div>
        <div className="d-flex justify-content-end pt-5">
          <p className="lang">
            <button onClick={handleLanguageSwitch} className="login-language">
              {language === "en" ? "中文" : "ENG"}{" "}
            </button>
          </p>
        </div>
      </header>

      <div className="bg-dark">
        <div className="container">
          <div className="inner-box">
            <div className="accordion pt-3" id="accordionExample">
              <RegistrationSteps step={1} />

              <div className="tab-content">
                <div id="headingOne"></div>

                <div
                  id="collapseOne"
                  className="collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <form>
                    {/* <p className='number'>+85 212 3456 7788</p> */}
                    <p className="number">{mobileNumberFormated}</p>
                    <div className="row align-items-center g-2">
                      <p className=" mb-1">{t("acctTypeInfo")}</p>
                    </div>
                    <div className="row align-items-center g-2">
                      <p className=" mb-1">{t("acctTypeInfo2")}</p>
                    </div>
                    <div className="account-box  mt-5 ">
                      {/* <div className="row"> */}
                      <div
                        className={`account ${
                          activeAccount === "master" ? "active-account" : ""
                        } `}
                      >
                        <div className="card mb-4 mb-lg-0 py-3 text-center">
                          <div className="card-body">
                            <i className="fa fa-users icon"></i>
                            <h5 className="card-title text-uppercase">
                              {t("masterAcct")}
                            </h5>
                            <h6 className="card-type">{t("masterAcct2")}</h6>
                            <Link to="#">
                              <button
                                type="button"
                                className="btn primary-button rounded-button abs-btn text-uppercase select-account"
                                style={{ color: "white" }}
                                onClick={() => handleChangeAccount("master")}
                              >
                                {t("select")}
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`account ${
                          activeAccount === "individual" ? "active-account" : ""
                        } `}
                      >
                        <div className="card mb-4 mb-lg-0 py-3 text-center">
                          <div className="card-body">
                            <i className="fa fa-user icon"></i>
                            <h5 className="card-title text-uppercase ">
                              {t("indAcct")}
                            </h5>
                            <h6 className="card-type">{t("indAcct2")}</h6>
                            <Link to="#">
                              <button
                                type="button"
                                className="btn primary-button rounded-button abs-btn text-uppercase select-account"
                                onClick={() =>
                                  handleChangeAccount("individual")
                                }
                                style={{ color: "white" }}
                              >
                                {t("select")}
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                      {/* <div
                        className={`account ${
                          activeAccount === "superMaster"
                            ? "active-account"
                            : ""
                        } `}
                      >
                        <div className="card mb-4 mb-lg-0 py-3 text-center">
                          <div className="card-body">
                            <i className="fa fa-users icon"></i>
                            <h5 className="card-title text-uppercase">
                              {t("supermasterAcct")}
                            </h5>
                            <h6 className="card-type">
                              {t("supermasterAcct2")}
                            </h6>
                            <Link to="#">
                              <button
                                type="button"
                                className="btn primary-button rounded-button abs-btn text-uppercase select-account"
                                style={{ color: "white" }}
                                onClick={() =>
                                  handleChangeAccount("superMaster")
                                }
                              >
                                {t("select")}
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div> */}
                    </div>
                    {activeAccount === "individual" && (
                      <>
                        <div className="mt-3 mt-lg-5">
                          <select
                            className="form-select input-md w-100 "
                            id="typeSelectOption"
                            value={selectedType} // Assuming you have a selectedType state to control the selected option
                            onChange={handleType} // Assuming you have a handleType function to handle changes
                          >
                            <option value="" selected disabled>
                              {t("TypeSelected")}
                            </option>
                            {Object.entries(types).map(([id, typeName]) => (
                              <option key={id} value={typeName}>
                                {langType === "zh"
                                  ? typeName.split("|")[1]
                                  : typeName.split("|")[0]}
                              </option>
                            ))}
                          </select>
                          <small className="text-white">{error}</small>
                        </div>
                      </>
                    )}
                    {activeAccount === "master" && (
                      <>
                        <div className="mt-3 mt-lg-5">
                          <select
                            className="form-select input-md w-100 "
                            id="typeSelectOption"
                            value={selectedRole} // Assuming you have a selectedType state to control the selected option
                            onChange={handleRole} // Assuming you have a handleType function to handle changes
                          >
                            <option value="" selected disabled>
                              {t("rolesSelected")}
                            </option>
                            {Object.entries(masterRoles).map(
                              ([id, typeName]) => (
                                <option key={id} value={typeName}>
                                  {langType === "zh"
                                    ? typeName.split("|")[1]
                                    : typeName.split("|")[0]}
                                </option>
                              )
                            )}
                          </select>
                          <small className="text-white">{error}</small>
                        </div>
                      </>
                    )}

                    {/* </div> */}
                  </form>
                </div>
              </div>
              <div className="tab-content">
                <div id="headingTwo"></div>
                <div
                  id="collapseTwo"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <p className="text-center mb-1 w-80 m-auto balance-para">
                    Please reload OneCoin for the first time before put in AD
                    details. <br /> We suggest you reload at least 200 USDT to
                    your account. <br /> You can reload the amount you need, no
                    need to reload a lot
                  </p>
                  <div className="account-box px-5 mt-5 align-items-center">
                    <div className="account active-account w-100">
                      <div className="card mb-5 mb-lg-0 py-3 text-center bg-white">
                        <div className="card-body">
                          <h5 className="balance-title mb-0">
                            OneCoin Balance
                          </h5>
                          <h5 className="balance-value d-flex align-items-center justify-content-center gap-1">
                            <p>$</p>
                            <p>0</p>
                          </h5>
                          <a href="reload-coin.html">
                            <button className="btn primary-button rounded-button abs-btn coin-btn w-70 m-auto">
                              <i className="fa fa-shopping-cart"></i> Reload
                              OneCoin
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-content">
                <div id="headingThree"></div>
                <div
                  id="collapseThree"
                  className="collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="card-body">your content goes here...</div>
                </div>
              </div>
              <div className="tab-content">
                <div id="headingFour"></div>
                <div
                  id="collapseFour"
                  className="collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div className="card-body">your content goes here...</div>
                </div>
              </div>

              <div>
                <p className="note mt-5">
                  {t("contactCStg")} <br /> {t("contactCStg2")}
                </p>
              </div>

              <div className="row">
                <div className="col-6 text-center">
                  <Button
                    variant="primary"
                    onClick={() => setShowModal(true)}
                    className="primary-button button mt-3 w-100"
                  >
                    {t("cancel")}
                  </Button>
                </div>

                <Modal
                  show={showNextModal}
                  onHide={() => setShowNextModal(false)}
                >
                  <Modal.Body>
                    <div className="d-flex justify-content-center align-items-center">
                      <Modal.Title>
                        <h5 className="modal-title text-center">
                          {t("confirmIndAcct")}{" "}
                          {activeAccount === "master" ? (
                            <>
                              <br />
                              {langType === "zh"
                                ? selectedRole.split("|")[1]
                                : selectedRole.split("|")[0]}
                              ?<br />
                              <br />
                              <span style={{ color: "yellow", fontSize: 14 }}>
                                {t("actionCannotBeUndone")}
                              </span>
                            </>
                          ) : (
                            // t("indAcct3")
                            <>
                              <br />
                              {t("indAcct3")} {" - "}
                              {langType === "zh"
                                ? selectedType.split("|")[1]
                                : selectedType.split("|")[0]}
                              ?<br />
                              <br />
                              <span style={{ color: "yellow", fontSize: 14 }}>
                                {t("actionCannotBeUndone")}
                              </span>
                            </>
                          )}
                        </h5>
                        <div className="d-flex justify-content-between w-100 mt-3">
                          {/* <Link to="/"> */}
                          <Button
                            variant="primary"
                            className="primary-button button w-100 mx-2"
                            onClick={handleNext}
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <Spinner size="sm" />
                            ) : (
                              <span>{t("yes")}</span>
                            )}
                          </Button>
                          {/* </Link> */}
                          <Button
                            variant="primary"
                            className="primary-button button mx-2 w-100"
                            onClick={() => setShowNextModal(false)}
                            disabled={isLoading}
                          >
                            {t("no")}
                          </Button>
                        </div>
                      </Modal.Title>
                    </div>
                  </Modal.Body>
                </Modal>

                <Modal show={showModal} onHide={() => setShowModal(false)}>
                  <Modal.Body>
                    <div className="d-flex justify-content-center align-items-center">
                      <Modal.Title>
                        <h5 className="modal-title text-center">
                          {t("cancelRegisterAcc")}
                        </h5>
                        <div className="d-flex justify-content-between w-100 mt-3">
                          {/* <Link to="/"> */}
                          <Button
                            className="secondary-button button mt-3 w-100 mx-2"
                            onClick={handleRedirect}
                          >
                            {t("yes")}
                          </Button>
                          {/* </Link> */}
                          <Button
                            variant="primary"
                            className="primary-button button mt-3 w-100 mx-2"
                            onClick={() => setShowModal(false)}
                          >
                            {t("no")}
                          </Button>
                        </div>
                      </Modal.Title>
                    </div>
                  </Modal.Body>
                  <Modal.Footer></Modal.Footer>
                </Modal>

                <div className="col-6 text-center">
                  {/* <Link to="/load-coin-empty"> */}
                  <Button
                    variant="primary"
                    className="primary-button button mt-3 w-100"
                    onClick={handleNextBTN}
                    // onClick={() => setShowNextModal(true)}
                  >
                    {t("next")}
                  </Button>
                  {/* </Link> */}
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AccountType;
