import * as React from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import AppRoutePath from "../../constants/AppRoutePath";
import LocalStorageKey from "../../constants/LocalStorageKey";
import AccountTypeConst from "../../domain/enum/account-type";

export interface IMasterValidatorRouteProps {}

export default function MasterValidatorRoute(
	props: IMasterValidatorRouteProps
) {
	const navigate = useNavigate();
	const isLogin =
		(localStorage.getItem(LocalStorageKey.isLogin) ?? "false") === "true";
	const isMaster =
		localStorage.getItem(LocalStorageKey.role) === AccountTypeConst.Master.path;

	React.useEffect(() => {
		if (isLogin && !isMaster) {
			navigate(-1);
		}
	});

	if (isLogin) {
		if (isMaster) {
			return <Outlet />;
		}
		return <div className='bg-dark'></div>;
	}
	return <Navigate to={AppRoutePath.login} />;
}
