// Loader.js

import React from "react";


import companyLogo from '../../assets/imgs/whiteLogo.png'

const ScreenLoader2 = () => {
  return (
    <div>
      <div className="loader-backdrop-2"></div>
      <div className="loader-container-2">
        <img
          src={companyLogo} // Replace with the path to your company logo
          alt="Company Logo"
          className="company-logo"
        />
        <div className="loader-5"></div>
      </div>
    </div>
  );
};

export default ScreenLoader2;
