import * as React from "react";
import { AdMedia, AdMedia1 } from "../../domain/model/ad-media";
import { findAdMedia, findRealImages, findStreetImages } from "../../services/ad-media";
import PhotoGallery from "./PhotoGallery";
import {
  getMainSpecialPhoto,
  getSpecialPhoto,
  getSpecialPhotoMedia,
} from "../../services/client/client-special-photo";
import { Image } from "../../domain/model/instant-review-model";
import { useTranslation } from "react-i18next";

export interface IAdRealPhotoGalleryProps {
  userId?: string;
}

export default function AdRealPhotoGallery(props: IAdRealPhotoGalleryProps) {
  const { t } = useTranslation();
  const [isSelectAdPhotos, setIsSelectAdPhotos] = React.useState(true);
  const [isSelectRealPhotos, setIsSelectRealPhotos] = React.useState(false);
  const [isSelectSpecialCat, setIsSelectSpecialCat] = React.useState(false);
  const [isSelectStreetPhoto, setIsSelectStreetPhoto] = React.useState(false);

  const [adPhotos, setAdPhotos] = React.useState<AdMedia[]>();
  const [realPhotos, setRealPhotos] = React.useState<AdMedia[]>();
  const [streetPhotos, setStreetPhotos] = React.useState<AdMedia[]>();
  const [specialCatPhotos, setSpecialCatPhotos] = React.useState<AdMedia[]>();

  React.useEffect(() => {
    findAdMedia(props.userId).then(setAdPhotos);
    findRealImages(props.userId).then(setRealPhotos);
    findStreetImages(props.userId).then(setStreetPhotos);
    getSpecialPhotoMedia({ target: Number(props.userId) }).then(
      setSpecialCatPhotos
    );
  }, []);

  return (
    <div>
      <div id="tab-wrap ">
        <ul className="tab-group">
          <li
            className={isSelectAdPhotos ? "active" : ""}
            onClick={() => {
              setIsSelectAdPhotos(true);
              setIsSelectRealPhotos(false);
              setIsSelectSpecialCat(false);
              setIsSelectStreetPhoto(false);
            }}
          >
            <a className="color-pink-2" data-href="#tab-1">
              {t("adsPhoto")}
            </a>
          </li>
          <li
            className={isSelectRealPhotos ? "active" : ""}
            onClick={() => {
              setIsSelectAdPhotos(false);
              setIsSelectRealPhotos(true);
              setIsSelectSpecialCat(false);
              setIsSelectStreetPhoto(false);
            }}
          >
            <a className="color-pink-2" data-href="#tab-2">
              {t("realPhoto")}
            </a>
          </li>
          <li
            className={isSelectSpecialCat ? "active" : ""}
            onClick={() => {
              setIsSelectAdPhotos(false);
              setIsSelectRealPhotos(false);
              setIsSelectStreetPhoto(false);
              setIsSelectSpecialCat(true);
            }}
          >
            <a className="color-pink-2" data-href="#tab-3">
              {t("SpecialCatPhoto")}
            </a>
          </li>
          <li
            className={isSelectStreetPhoto ? "active" : ""}
            onClick={() => {
              setIsSelectAdPhotos(false);
              setIsSelectRealPhotos(false);
              setIsSelectSpecialCat(false);
              setIsSelectStreetPhoto(true);
            }}
          >
            <a className="color-pink-2" data-href="#tab-3">
              {t("streetPhoto")}
            </a>
          </li>
        </ul>
        <PhotoGallery
          listPhoto={adPhotos ?? []}
          id="tab-1"
          style={{ display: isSelectAdPhotos ? "block" : "none" }}
        />
        <PhotoGallery
          listPhoto={realPhotos ?? []}
          id="tab-2"
          style={{ display: isSelectRealPhotos ? "block" : "none" }}
        />
        <PhotoGallery
          listPhoto={specialCatPhotos ?? []}
          id="tab-3"
          style={{ display: isSelectSpecialCat ? "block" : "none" }}
        />
        <PhotoGallery
          listPhoto={streetPhotos ?? []}
          id="tab-4"
          style={{ display: isSelectStreetPhoto ? "block" : "none" }}
        />
      </div>
    </div>
  );
}
