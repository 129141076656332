import React from "react";

function FormErrorMessage(props: FormErrorMessageProps): JSX.Element {
    if (!props.message) return <></>;

    return (
        <span className="form-error">{props.message}</span>
    );
}

type FormErrorMessageProps = {
    message?: string
}

export default FormErrorMessage;