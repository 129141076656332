import * as React from "react";

import {
  blockUnblockFollower,
  followUser,
  getFollowerList,
  getFollowerStar,
} from "../../services/client/client-follow";
import { IFollowUserParams } from "../../domain/model/follower-model";
import { Dropdown, Modal, Spinner, Table } from "react-bootstrap";
import { FollowerModel } from "../../domain/model/follower-model";
import CustomEmptyMessage from "../reusable/EmptyMessage";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import Snackbar from "awesome-snackbar";
import { AdMedia } from "../../domain/model/ad-media";
import { uploadFileToS3 } from "../../services/upload";
import { findVoiceMemo, uploadVoiceMemo } from "../../services/ad-media";
import SizedBox from "../reusable/Sizedbox";
import { addSystemMessage } from "../../services/system-notification";
import moment from "moment";
import { getSystemMessage } from "../../services/system-notification";
import { useTranslation, initReactI18next } from "react-i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import CustomHeader from "../reusable/Header";
import TopHearder from "../reusable/TopHearder";
import { getTchatUrl } from "../../services/tChat/chats";
import Loaders from "../reusable/Loaders";
export interface IHistoryPageProps {}
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: require("../../locales/en.json") },
      zh: { translation: require("../../locales/zh.json") },
    },
    fallbackLng: "en", // Default language fallback
    interpolation: {
      escapeValue: false, // Disable HTML escaping
    },
  });

export default function FollowerListPage() {
  const langType = localStorage.getItem("i18nextLng");
  const { id } = useParams();
  const isMaster = localStorage.getItem("role") == "master";
  const navigate = useNavigate();
  const [isNavOpen, setIsNavOpen] = React.useState(true);
  const [listFollower, setListFollower] = React.useState<FollowerModel[]>([]);
  const [isUnfollowShow, setIsUnfollowShow] = React.useState(false);
  const [tchatModel, setTchatModel] = React.useState(false);
  const [isShowFollowSuccessfulModal, setIsShowFollowSuccessfulModal] =
    React.useState(false);
  const [fanId, setFanId] = React.useState({ target: 0, name: "" });
  const [loading, setLoading] = React.useState(false);

  const [isShowBlockModal, setIsShowBlockModal] = React.useState(false);
  const [isShowUnBlockModal, setIsShowUnBlockModal] = React.useState(false);
  const [isShowBlocSuccessfulModal, setIsShowBlocSuccessfulModal] =
    React.useState(false);
  const [isShowUnBlocSuccessfulModal, setIsShowUnBlocSuccessfulModal] =
    React.useState(false);
  const [bTitles, setBTitles] = React.useState("");
  const [isMessageOpen, setIsMessageOpen] = React.useState(false);
  const [messageContent, setMessageContent] = React.useState(
    "Long time no see ...!"
  );

  const [textModal, setTextModal] = React.useState("");

  // Tchat

  const [isTChatLoading, setIsTChatLoading] = React.useState(false);
  const [tChatGenerateLink, setTChatGenerateLink] = React.useState("");
  const [tChatModelOpen, setTChatModelOpen] = React.useState(false);

  const [selectedFollower, setSelectedFollower] = React.useState<number | 0>(0);

  const { t } = useTranslation();

  // Voice memo states
  const [voiceMemo, setVoiceMemo] = React.useState<AdMedia>();
  const recorderControls = useAudioRecorder();

  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    setIsLoading(true);
    getFollowerList().then((res) => {
      if (res != null) {
        setListFollower(res["item(s)"]);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  }, []);

  // React.useEffect(() => {
  //   getSystemMessage({ status: 0 }).then((res) => {
  //     if (res) {
  //       setNotifCount(res.length);
  //     }
  //   });
  // }, []);

  //  sort the follower based on star

  //  Block user
  async function doBlockOrUnblockFollower(id: number, isNowBlocked: boolean) {
    setLoading(true);

    // console.log("isNowBlocked ", isNowBlocked);

    const isSuccess = await blockUnblockFollower(id, !isNowBlocked ? 1 : 0);
    if (isSuccess) {
      getFollowerList().then((res) => {
        if (res != null) {
          setListFollower(res["item(s)"]);
        }
      });

      if (!isNowBlocked) {
        setIsShowBlockModal(false);
      } else {
        setIsShowUnBlockModal(false);
        setBTitles("blocked");
      }
      setLoading(false);

      setIsShowBlocSuccessfulModal(true);
    } else {
      setTextModal("Block or unblock follower failed");
      // setIsShowModal(true);
    }
  }

  // after block the user
  const handleBlockUser = () => {
    setIsShowBlocSuccessfulModal(false);
    getFollowerList().then((res) => {
      if (res != null) {
        setListFollower(res["item(s)"]);
      }
    });
  };

  async function handleFollowerAndUnfollow(params: IFollowUserParams) {
    // console.log(params);

    const isSuccess = await followUser(params);
    if (isSuccess) {
      setIsShowFollowSuccessfulModal(true);
    } else {
      // setTextModal("Block or unblock follower failed");
      // setIsShowModal(true);
    }
  }

  const successFollowerAndUnfollow = () => {
    setIsShowFollowSuccessfulModal(false);
    getFollowerList().then((res) => {
      if (res != null) {
        setListFollower(res["item(s)"]);
      }
    });
  };

  const handleMessageContent: React.ChangeEventHandler<HTMLTextAreaElement> = (
    event
  ) => {
    setMessageContent(event.target.value);
  };

  const handleInput = (event: React.FormEvent<HTMLTextAreaElement>) => {
    const inputText = event.currentTarget.value;
    if (inputText.length <= 139) {
      setMessageContent(inputText);
    }
  };

  const handleStarClick = (follower: any) => {
    // Access properties of the clicked follower object
    const followerFans = follower.fans;
    const followerStar = follower.star;

    getFollowerStar({
      starControl: followerStar ? 0 : 1,
      target: follower.fans,
    }).then((res) => {
      getFollowerList().then((res) => {
        if (res != null) {
          setListFollower(res["item(s)"]);
        }
      });
    });

    // Update follower's star status or perform any other actions
  };

  // Voice

  const handleGetVoiceMemo = () =>
    findVoiceMemo(id).then((e) => setVoiceMemo(e ?? undefined));

  const handleUploadVoiceMemo = async (blob: Blob) => {
    const sb = showLoadingSnackbar();

    try {
      const file = new File([blob], `${blob.name}-voice-memo.wav`, {
        type: blob.type,
      });
      const url = await uploadFileToS3(file).then(
        (res) => res?.fullpath ?? null
      );

      await uploadVoiceMemo(url!, id ? Number(id) : undefined);
      await handleGetVoiceMemo();
    } catch (e) {
      new Snackbar("Failed to upload voice memo");
    }

    sb.hide();
  };

  const showLoadingSnackbar = () =>
    new Snackbar(
      `<div className="spinner-grow spinner-grow-sm mx-1"></div> Uploading...`,
      {
        timeout: 0,
      }
    );

  // send messages
  const handleSendMessages = () => {
    addSystemMessage().then((res) => {
      if (res != null) {
        // console.log(res);
        // setListFollower(res["item(s)"]);
      }
    });
  };

  const handleTchats = async (following_type: Number, to: Number) => {
    setSelectedFollower(Number(to));
    setIsTChatLoading(true);
    getTchatUrl({ following_type: following_type, to: to }).then((res) => {
      if (res.result_code === 200) {
        // console.log("res.url", res.url);
        setTChatModelOpen(true);

        setTChatGenerateLink(res.url);
        setIsTChatLoading(false);
      }
    });
  };
  return (
    <div>
      <CustomHeader />
      <TopHearder title={t("followerList")} />

      <div className="bg-dark">
        <div className="container py-5">
          {isLoading ? (
            <>
              <div className="col-12 text-center">
                <Loaders />
              </div>
            </>
          ) : (
            <Table className="cutome-tables" responsive>
              {listFollower.length < 1 ? (
                <CustomEmptyMessage />
              ) : (
                <>
                  <thead>
                    <tr className="primary-color">
                      <th className="nowrap">{t("myFav")}</th>
                      <th className="nowrap">{t("memberProfile")}</th>
                      <th className="nowrap">{t("chatMsg")}</th>
                      <th className="nowrap">{t("following")}</th>
                      <th className="nowrap">{t("action")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listFollower
                      .sort(
                        (a, b) =>
                          moment(b.star).valueOf() - moment(a.star).valueOf()
                      )
                      .map((follower, i) => (
                        <tr
                          className="align-items-center mb-3 border-0"
                          key={i}
                        >
                          <td>
                            <div className="d-flex align-items-center ">
                              <div className="ps-2 pt-0  ">
                                {follower.star ? (
                                  <i
                                    className={`fa-solid fa-star ${
                                      follower.block
                                        ? "gray-color-1 "
                                        : "yellow-c"
                                    }`}
                                    onClick={() => {
                                      if (follower.block === 1) {
                                        return;
                                      }
                                      handleStarClick(follower);
                                    }}
                                  ></i>
                                ) : (
                                  <i
                                    className={`fa-regular fa-star ${
                                      follower.block
                                        ? "gray-color-1 "
                                        : "primary-color "
                                    }`}
                                    onClick={() => {
                                      if (follower.block === 1) {
                                        return;
                                      }
                                      handleStarClick(follower);
                                    }}
                                  ></i>
                                )}
                              </div>
                            </div>
                          </td>

                          <td className="  ">
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0">
                                <img
                                  className="clt-img1 "
                                  src={
                                    follower.photo
                                      ? follower.photo
                                      : "/img/blank-profile-picture.png"
                                  }
                                  alt="User profile"
                                />
                              </div>
                              <div className="flex-grow-1 ms-lg-2 ms-1 ">
                                <p
                                  className={`mb-0 ${
                                    follower.block
                                      ? "gray-color-1 "
                                      : "primary-color "
                                  }   profile-title`}
                                >
                                  {langType === "zh"
                                    ? follower.zh_name
                                    : follower.en_name}
                                </p>

                                <small
                                  className={`mb-0  ${
                                    follower.block
                                      ? "gray-color-1 "
                                      : "text-white "
                                  }  profile-n`}
                                >
                                  {follower.mobile}
                                </small>
                              </div>
                            </div>
                          </td>

                          <td>
                            {" "}
                            <div className="d-flex gap-2">
                              {follower.block ? (
                                <button
                                  className={`t-chat bg-transparent ${
                                    follower.block ? "disable-link " : ""
                                  }`}
                                  id={`chat-${follower.fans}`}
                                >
                                  {/* <i className="fa-regular fa-comment"></i>  */}
                                  <img
                                    src="/img/tchat_newlogo_gray.png"
                                    className="default-logo"
                                    style={{ width: 15, marginRight: 5 }}
                                  />
                                  {t("tchat")}{" "}
                                  <span>
                                    {follower?.unreadChatCount
                                      ? follower?.unreadChatCount
                                      : 0}
                                  </span>
                                </button>
                              ) : (
                                <>
                                  {isTChatLoading &&
                                  selectedFollower === follower.fans ? (
                                    <>
                                      <button className="btn primary-button rounded-button">
                                        {" "}
                                        <Spinner size="sm" />
                                      </button>
                                    </>
                                  ) : (
                                    <button
                                      className={`t-chat bg-transparent ${
                                        follower.block ? "d-none " : ""
                                      }`}
                                      id={`chat-${follower.fans}`}
                                      onClick={() =>
                                        handleTchats(1, follower.fans)
                                      }
                                    >
                                      <img
                                        src="/img/tchat_newlogo.png"
                                        className="default-logo"
                                        style={{ width: 15 }}
                                      />{" "}
                                      {t("tchat")}{" "}
                                      {/* <span>
                                        {follower?.unreadChatCount
                                          ? follower?.unreadChatCount
                                          : 0}
                                      </span> */}
                                    </button>
                                  )}
                                </>
                              )}
                            </div>
                          </td>
                          <td className="primary-color profile-title">
                            {follower?.date.split(" ")[0]}{" "}
                          </td>

                          <td>
                            <Dropdown>
                              <Dropdown.Toggle
                                id=" "
                                className="custom-dropdown-1"
                                // disabled={follower.block ? true : false}
                              >
                                <i className="fa-solid fa-ellipsis-vertical"></i>
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                {follower.block ? (
                                  <Dropdown.Item
                                    onClick={() => {
                                      setIsShowUnBlockModal(true);
                                      setFanId({
                                        target: follower?.fans,
                                        name:
                                          langType === "zh"
                                            ? follower.zh_name
                                            : follower.en_name,
                                      });
                                      setBTitles("unblocked");
                                    }}
                                  >
                                    {t("unblock")}
                                  </Dropdown.Item>
                                ) : (
                                  <Dropdown.Item
                                    onClick={() => {
                                      setIsShowBlockModal(true);
                                      setFanId({
                                        target: follower?.fans,
                                        name:
                                          langType === "zh"
                                            ? follower.zh_name
                                            : follower.en_name,
                                      });
                                      setBTitles("blocked");
                                    }}
                                  >
                                    {t("Block")}
                                  </Dropdown.Item>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </>
              )}
            </Table>
          )}
        </div>
      </div>

      <Modal show={isShowBlockModal}>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={(e) => setIsShowBlockModal(false)}
        ></button>

        <Modal.Body>
          {/* <p>{textModal}</p> */}
          <p className="text-center">
            Are you sure you want block{" "}
            <span className="primary-color">{fanId.name}</span> ?
          </p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <button
            className="btn-modal-close "
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={(e) => setIsShowBlockModal(false)}
          >
            No
          </button>
          <button
            className="btn-modal"
            onClick={() => {
              doBlockOrUnblockFollower(fanId?.target, false);
              // if(!loading){
              //    setIsShowBlockModal(false);
              // }
            }}
            disabled={loading}
          >
            {/* Yes */}
            {loading ? <Spinner size="sm" /> : <span>{t("Yes")}</span>}
          </button>
        </Modal.Footer>
      </Modal>

      {/* Unblock user  */}
      <Modal show={isShowUnBlockModal}>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={(e) => setIsShowUnBlockModal(false)}
        ></button>

        <Modal.Body>
          {/* <p>{textModal}</p> */}
          <p className="text-center">
            Are you sure you want unblock{" "}
            <span className="primary-color">{fanId.name}</span> ?
          </p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <button
            className="btn-modal-close "
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={(e) => setIsShowUnBlockModal(false)}
          >
            No
          </button>
          <button
            className="btn-modal"
            onClick={() => {
              doBlockOrUnblockFollower(fanId?.target, true);
              // setIsShowUnBlockModal(false);
            }}
            disabled={loading}
          >
            {/* Yes */}
            {loading ? <Spinner size="sm" /> : <span>{t("Yes")}</span>}
          </button>
        </Modal.Footer>
      </Modal>

      {/* isShowBlocSuccessfulModal  */}
      <Modal show={isShowBlocSuccessfulModal}>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={(e) => handleBlockUser()}
        ></button>

        <Modal.Body>
          {/* <p>{textModal}</p> */}
          <p className="text-center">
            Done! <br />
            The selected follower has been successfully {bTitles}.
          </p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <button className="btn-modal" onClick={() => handleBlockUser()}>
            Done
          </button>
        </Modal.Footer>
      </Modal>

      {/* Unfollow Modal */}
      <Modal show={isUnfollowShow}>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={(e) => setIsUnfollowShow(false)}
        ></button>

        <Modal.Body>
          <p className="text-center">
            Are you sure you want to unfollow{" "}
            <span className="primary-color">{fanId.name} </span> ?
          </p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <button
            className=" btn-modal-close  "
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={(e) => setIsUnfollowShow(false)}
          >
            No
          </button>
          <button
            className="btn-modal"
            onClick={() => {
              handleFollowerAndUnfollow({
                target: fanId.target,
                type: 1,
                followControl: 2,
              });
              setIsUnfollowShow(false);
            }}
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>

      {/* isShowBlocSuccessfulModal  */}
      <Modal show={isShowFollowSuccessfulModal}>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={(e) => successFollowerAndUnfollow()}
        ></button>

        <Modal.Body>
          {/* <p>{textModal}</p> */}
          <p className="text-center">
            Done! <br />
            The selected follower has been successfully unfollowed.
          </p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <button
            className="btn-modal"
            onClick={() => successFollowerAndUnfollow()}
          >
            Done
          </button>
        </Modal.Footer>
      </Modal>
      {/*  Send message modal */}
      <Modal show={isMessageOpen}>
        {/* <Modal.Header> */}
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={(e) => setIsMessageOpen(false)}
        ></button>
        {/* </Modal.Header> */}
        <Modal.Body className="px-0">
          <label className="label pb-2">Type your message here </label>
          <div className="post-box mb-3">
            <textarea
              name=""
              id="text"
              rows={7}
              className="form-control form-control-cus"
              value={messageContent.slice(0, 139)}
              onChange={handleMessageContent}
              onInput={handleInput}
            ></textarea>
            <span className="pull-right label label-default" id="count_message">
              {messageContent.length} / 140
            </span>
          </div>
          <AudioRecorder
            onRecordingComplete={handleUploadVoiceMemo}
            recorderControls={recorderControls}
          />
          {voiceMemo && (
            <>
              <SizedBox height={10} />
              <audio src={voiceMemo.cache_url} controls />
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <button
            className="  btn-modal-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={(e) => setIsMessageOpen(false)}
          >
            Cancel
          </button>
          <button className="btn-modal" onClick={handleSendMessages}>
            Send
          </button>
        </Modal.Footer>
      </Modal>

      {/* Tchat  */}
      <Modal show={tChatModelOpen}>
        <Modal.Body>
          <div className="d-flex justify-content-center align-items-center">
            <Modal.Title>
              <div className="text-center pb-3">
                <img
                  style={{ width: 50, marginRight: 5 }}
                  src="/img/tchat_newlogo.png"
                  className="default-logo"
                />
              </div>
              <h5 className="modal-title text-center pb-3">
                {t("tchatModelMSG")}
              </h5>
            </Modal.Title>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-2 justify-content-center text-center">
            <button
              className=" button primary-button rounded-button px-5 "
              onClick={() => {
                setTChatModelOpen(false);
              }}
            >
              {t("no")}
            </button>

            <a
              href={tChatGenerateLink}
              target="_blank"
              rel="noreferrer"
              className="button primary-button rounded-button px-5"
              onClick={() => {
                setTChatModelOpen(false);
              }}
            >
              {t("ok")}
            </a>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
