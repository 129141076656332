import Snackbar from "awesome-snackbar";
import { ShopModel } from "../../domain/model/shop-model";
import axios from "../axios";

export const createShop = async (params: ShopModel) => {
	const res = await axios
		.post("/master/shop", params)
		.then((respons) => {
			console.log(respons);
			return true;
		})
		.catch((error) => {
			try {
				console.log(JSON.stringify(error));
				// new Snackbar('ERROR: ' + (error.error.reason ?? error.error.message));
			} catch (error) {
				console.log(error);
				new Snackbar("Failed to create shop");
			}
			return false;
		});

	return res;
};

export const editShop = async (params: ShopModel) => {

	// console.log("Edit SHpe Param ", params);

	const res = await axios
		.put("/master/shop", params)
		.then((respons) => {
			console.log(respons);
			return true;
		})
		.catch((error) => {
			try {
				console.log(JSON.stringify(error));
				// new Snackbar('ERROR: ' + (error.error.reason ?? error.error.message));
			} catch (error) {
				console.log(error);
				new Snackbar("Failed to edit shop");
			}
			return false;
		});

	return res;
};

export const getShop = async () => {
	const res = await axios
		.get("/master/shop")
		.then((respons) => {
			console.log("Master Shop respons", respons);
			return respons.data as ShopModel;
		})
		.catch((error) => {
			try {
				console.log(JSON.stringify(error));
				// new Snackbar('ERROR: ' + (error.error.reason ?? error.error.message));
				// if ((error.error.reason || error.error.message) === "Corresponding data can't be found - 'shop'") {
				// 	new Snackbar("No Shop");
				// }
			} catch (error) {
				console.log(error);
				new Snackbar("Failed to get shop data");
			}
			return null;
		});

	return res;
};
export const checkMasterHasShop = async () => {
	const res = await axios
		.get("/master/shop")
		.then((respons) => {
			console.log("checkMasterHasShop respons", respons);
			return respons.data.message;
		})
		.catch((error) => {
			try {
				console.log(JSON.stringify(error));
				// new Snackbar('ERROR: ' + (error.error.reason ?? error.error.message));
				// if ((error.error.reason || error.error.message) === "Corresponding data can't be found - 'shop'") {
				// 	new Snackbar("No Shop");
				// }
			} catch (error) {
				console.log(error);
				new Snackbar("Failed to get shop data");
			}
			return null;
		});

	return res;
};

export const controlShop = async (blogControl: number) => {

	console.log("controlShop ", blogControl);


	const res = await axios
		.patch("/master/shop/control", { blog_control: blogControl })
		.then((respons) => {
			console.log(respons);

			if (respons.data.result_code !== 200) {
				new Snackbar(`${respons.data.reason}`);
			} else {
				return true;
			}

		})
		.catch((error) => {
			try {
				console.log(JSON.stringify(error));
				// new Snackbar('ERROR: ' + (error.error.reason ?? error.error.message));
			} catch (error) {
				console.log(error);
				new Snackbar("Failed to change shop control");
			}
			return false;
		});

	return res;
};
