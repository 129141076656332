import Snackbar from "awesome-snackbar";
import axios from "../axios";

import { TChatModel1 } from "../../domain/model/t-chat-model";

export const getTChatLanguages = async () => {
  const res = await axios
    .get("/chat/lang/list")
    .then((respons) => {
      return respons.data["item(s)"] as TChatModel1[];
    })
    .catch((error) => {
      try {
        console.log(JSON.stringify(error));

      } catch (error) {
        console.log(error);
        new Snackbar("Failed to get Tchat Language");
      }
      return 0;
    });

  return res;
};
export const getUserTChatLanguages = async () => {
  const res = await axios
    .get("/chat/user/lang/get")
    .then((respons) => {

      // return respons.data
      return respons.data
      // return respons.data["item(s)"] as TChatModel[];
    })
    .catch((error) => {
      try {
        console.log(JSON.stringify(error));
        // new Snackbar(`ERROR: ${error.error.reason ?? error.error.message}`);
      } catch (error) {
        console.log(error);
        new Snackbar("Failed to get User Tchat Language");
      }
      return 0;
    });

  return res;
};
export const setTChatLanguages = async (data?: any) => {

  console.log(data);

  const res = await axios
    .post(`/chat/lang/edit`, { lang_abbr: data })
    .then((respons) => {
      if (respons.status === 200) {
        new Snackbar("TChat Language Updated");
      }
      return respons;
    })
    .catch((error) => {
      try {
        console.log(JSON.stringify(error));

      } catch (error) {
        console.log(error);
        new Snackbar("Failed to set Tchat Language");
      }
      return 0;
    });

  return res;
};
export const getTchatUrl = async (data?: any) => {
  let params
  if (data) {
    params = { to: data.to, following_type: data.following_type }
  } else {
    params = {}
  }

  const res = await axios
    .post(`/chat/url/get`, params)
    .then((respons) => {
      // if (respons.status === 200) {
      //   new Snackbar("TChat Language Updated");
      // }
      return respons.data;
    })
    .catch((error) => {
      try {
        console.log(JSON.stringify(error));

      } catch (error) {
        console.log(error);
        new Snackbar("Failed to set Tchat Language");
      }
      return 0;
    });

  return res;
};


