import * as React from "react";
import { Modal } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import AppRoutePath from "../../constants/AppRoutePath";
import { AdListModel } from "../../domain/model/ad-list-model";
import { controlAdClient, controlBlogClient } from "../../services/client";
import { getProfileMaster } from "../../services/master/master-account";
import {
  deleteAdsMaster,
  getAdListMaster,
} from "../../services/master/master-ad";
import { findFlowerBalance, sendFlower } from "../../services/products/flower";
import CustomEmptyMessage from "../reusable/EmptyMessage";
import { useTranslation } from "react-i18next";

import SizedBox from "../reusable/Sizedbox";
import Snackbar from "awesome-snackbar";
import CustomHeader from "../reusable/Header";
import TopHearder from "../reusable/TopHearder";
import { ProfileMasterModel } from "../../domain/model/profile-master-model";
import Loaders from "../reusable/Loaders";

export interface IMasterAdListProps {}
export default function MasterAdList(props: IMasterAdListProps) {
  const langType = localStorage.getItem("i18nextLng");
  const abortController = new AbortController();

  const navigate = useNavigate();

  const { t } = useTranslation();
  const [notifCount, setNotifCount] = React.useState(0);
  const [isNavOpen, setIsNavOpen] = React.useState(true);
  const [adList, setAdList] = React.useState<AdListModel | null>(null);
  const [searchKeyword, setSearchKeyword] = React.useState("");
  const [searchEngName, setSearchEngName] = React.useState("");
  const [searchChiName, setSearchChiName] = React.useState("");

  const [isDeleteAdModalOpen, setIsDeleteAdModalOpen] = React.useState(false);
  const [isDeleteMultipleAdModalOpen, setIsDeleteMultipleAdModalOpen] =
    React.useState(false);

  const [isSendFlower, setIsSendFlower] = React.useState(false);
  const [sendFlowerSuccess, setSendFlowerSuccessl] = React.useState(false);
  const [productNames, setProductNames] = React.useState("");
  const [isDialogInsufficientCoin, setIsDialogInsufficientCoin] =
    React.useState(false);
  const [adsId, setAdsId] = React.useState(0);
  const [selectedAdsId, setSelectedAdsId] = React.useState<
    number | undefined
  >();
  const [selecedBlogPrice, setSelecedBlogPrice] = React.useState<
    number | undefined
  >();
  const [selecedAdvertAdsPrice, setSelecedAdvertAdsPrice] = React.useState<
    number | undefined
  >();
  const [selecedAdsTypes, setSelecedAdsTypes] = React.useState("");
  const [selecedAdsBlogStatus, setSelecedAdsBlogStatus] = React.useState<
    number | undefined
  >();
  const [selecedAdsStatus, setSelecedAdsStatus] = React.useState<
    number | undefined
  >();
  const [selectedAdsPaymentsStatus, setSelectedAdsPaymentsStatus] =
    React.useState<boolean | undefined>();
  const [selectedBlogPaymentsStatus, setSelectedBlogPaymentsStatus] =
    React.useState<boolean | undefined>();
  const [selectedBlogStatus, setSelectedBlogStatus] = React.useState<
    number | undefined
  >();
  const [seletedProductId, setSeletedProductId] = React.useState("");
  const [adBlogId, setAdBlogId] = React.useState(0);
  const [adBlogCurrentStatus, setAdBlogCurrentStatus] = React.useState(0);
  const [flowerBalance, setFlowerBalance] = React.useState(0);
  const [flowerBalanceArray, setFlowerBalanceArray] = React.useState([]);
  const [mobileMaster, setMobileMaster] = React.useState("");
  const [selectedItems, setSelectedItems] = React.useState<number[]>([]);
  const [showCheckboxes, setShowCheckboxes] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSendFlowerLoading, setIsSendFlowerLoading] = React.useState(false);
  const [isLoadingAds, setIsLoadingAds] = React.useState(false);
  const [isBlogAdvertisementModalOpen, setIsBlogAdvertisementModalOpen] =
    React.useState(false);
  const [isADModalOpen, setIsADModalOpen] = React.useState(false);

  const [isAdsModalOpen, setIsAdsModalOpen] = React.useState(false);
  const [isAdsOff, setIsAdsOff] = React.useState(false);
  const [isBlogModalOpen, setIsBlogModalOpen] = React.useState(false);
  const [isBlogPaidModalOpen, setIsBlogPaidModalOpen] = React.useState(false);
  const [isBlogOff, setIsBlogOff] = React.useState(false);
  const [isBlogOn, setIsBlogOn] = React.useState(false);
  const [idxAdListSelected, setIdxAdListSelected] = React.useState(-1);
  const [masterType, setMasterType] = React.useState("");
  const [type, setType] = React.useState<Array<{ [key: string]: boolean }>>([]);
  const [clientType, setClientType] = React.useState("");
  const [masterProfile, setMasterProfile] =
    React.useState<ProfileMasterModel | null>();
  const [masterBlogStatus, setMasterBlogStatus] = React.useState(
    masterProfile?.master_blog
  );

  let masterTypeValue;

  const fetchAdListMasterBlogAndAds = async () => {
    try {
      const resAdList = await getAdListMaster({});
      if (resAdList != null) {
        setAdList(resAdList);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchAdListMaster = async () => {
    try {
      setIsLoadingAds(true);
      const resAdList = await getAdListMaster({});
      if (resAdList != null) {
        setAdList(resAdList);
      }

      setIsLoadingAds(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error if needed
      setIsLoadingAds(false);
    }
  };

  const fetchFlowerBalance = async () => {
    const result = await findFlowerBalance();
    setFlowerBalance(result);
    setFlowerBalanceArray(result);
  };
  React.useEffect(() => {
    // const fetchAllAPI = async()=>{
    //   await Promise.all([
    fetchFlowerBalance();
    fetchAdListMaster();
    //   ]);
    // }

    // fetchAllAPI();
  }, []);

  React.useEffect(() => {
    getProfileMaster().then((res) => {
      if (res != null) {
        setMobileMaster(res.mobile);
        setMasterType(res?.master_type);
        setMasterBlogStatus(res?.master_blog);

        masterTypeValue = res.master_type;
      }
    });
  }, [masterTypeValue]);

  // React.useEffect(() => {
  //   const setAdTypes = async () => {
  //     if (adList?.["item(s)"] && adList["item(s)"].length > 0) {
  //       const userTypesMap = new Map();

  //       for (const client of adList["item(s)"]) {
  //         if (client.details?.basic_desc?.type) {
  //           const clientType = client.details.basic_desc.type;

  //           const typeFeature = await getTypeFeature(clientType);

  //           setClientType(clientType);
  //           if (typeFeature) {
  //             userTypesMap.set(client.user_id, typeFeature);
  //             setType((prevType) => [...prevType, typeFeature]);

  //             // localStorage.setItem(
  //             //   clientType,
  //             //   JSON.stringify(typeFeature.BLOG)
  //             // );
  //           }
  //         }
  //       }

  //       // Now, you can use the map to set the type for each client
  //       const updatedAdList = adList["item(s)"].map((client) => {
  //         const typeForClient = userTypesMap.get(client.user_id);

  //         return { ...client, type: typeForClient };
  //       });

  //       // Update the state or use the updatedAdList as needed
  //       // setAdList(updatedAdList);

  //       // For example, you can log the updated adList
  //     }
  //   };

  //   setAdTypes();
  // }, [adList]);

  React.useEffect(() => {
    const setAdTypes = () => {
      const allFeatureBlogs = localStorage.getItem("typeFeaturesArray");

      if (!adList?.["item(s)"] || !allFeatureBlogs) {
        return;
      }

      try {
        const typeFeaturesArray = JSON.parse(allFeatureBlogs);
        const userTypesMap = new Map();

        for (const client of adList["item(s)"]) {
          const clientType = client.details?.basic_desc?.type;

          if (clientType) {
            const typeFeatureResponse = typeFeaturesArray[clientType];
            setClientType(clientType);

            if (typeFeatureResponse) {
              if (typeFeatureResponse) {
                userTypesMap.set(client.user_id, typeFeatureResponse);
                setType((prevType) => [...prevType, typeFeatureResponse]);
              }
            }
          }
        }

        // Update adList with types
        const updatedAdList = adList["item(s)"].map((client) => ({
          ...client,
          type: userTypesMap.get(client.user_id),
        }));
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    };

    setAdTypes();
  }, [adList]);

  const handleSearchKeyword: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    event.preventDefault();

    const inputValue = event.target.value.replace(/[^0-9]/g, "");

    setSearchKeyword(inputValue);
  };
  const handleSearchEngName: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    event.preventDefault();

    const inputValue = event.target.value.replace(/\s/g, "");

    setSearchEngName(inputValue);
  };
  const handleSearchChiName: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    event.preventDefault();

    const inputValue = event.target.value.replace(/\s/g, "");

    setSearchChiName(inputValue);
  };

  const handleSubmitSearch = () => {
    getAdListMaster({
      mobile:
        searchKeyword !== "" ? `${mobileMaster}EXT${searchKeyword}` : undefined,
      en_name: searchEngName !== "" ? `${searchEngName}` : undefined,
      zh_name: searchChiName !== "" ? `${searchChiName}` : undefined,
    }).then((resAdList) => {
      if (resAdList != null) {
        setAdList(resAdList);
      }
    });
  };

  const handleToggleSelect = (idx: number) => {
    if (selectedItems.includes(idx)) {
      setSelectedItems(selectedItems.filter((item) => item !== idx));
    } else {
      setSelectedItems([...selectedItems, idx]);
    }
  };

  const openDeleteMultipleModal = () => {
    setIsDeleteMultipleAdModalOpen(true);
  };

  const handleDeleteSelected = () => {
    // Iterate over the selected items and delete them
    const selectedAdIds = selectedItems
      .map((idx) => adList?.["item(s)"][idx]?.user_id)
      .filter((id) => typeof id === "number"); // Filter out undefined values

    setIsLoading(true);

    if (selectedAdIds.length > 0) {
      Promise.all(selectedAdIds.map((id) => deleteAdsMaster(String(id))))
        .then((results) => {
          // Refresh ad list after deletion
          getAdListMaster({}).then((resAdList) => {
            if (resAdList != null) {
              setAdList(resAdList);
              setSelectedItems([]); // Clear selected items after deletion
              setShowCheckboxes(false);
              setIsDeleteMultipleAdModalOpen(false);
            }
          });
          setIsLoading(false);
        })
        .catch((error) => {
          // Handle error
        });
    }
  };

  const handleAdsBlog = (data: any) => {
    searchType(data.type);
    setSelectedBlogPaymentsStatus(data.adBlogPayment);
    setSelectedBlogStatus(data.blogStatus);

    setAdBlogId(data.user_id);
    if (data.adStatus == 0 && data.currentBlogStatus != data.blogStatus) {
      setIsBlogAdvertisementModalOpen(true);
    } else if (
      data.adStatus == 1 &&
      data.blogON &&
      data.currentBlogStatus != 1 &&
      data.adBlogPayment === false
    ) {
      // setIsBlogPaidModalOpen(true);
      setIsBlogModalOpen(true);
      setAdBlogCurrentStatus(1);
    } else if (
      data.adStatus == 1 &&
      data.blogON &&
      data.currentBlogStatus != 1 &&
      data.adBlogPayment === true
    ) {
      setIsBlogPaidModalOpen(true);

      setAdBlogCurrentStatus(1);
    } else if (
      data.adStatus == 1 &&
      data.currentBlogStatus != 0 &&
      !data.blogON
    ) {
      setAdBlogCurrentStatus(0);
      setIsBlogOff(true);
    }
  };

  const handleMasterAds = (data: any) => {
    setSelecedAdsStatus(data.adStatus);
    setSelectedAdsId(data.adsId);
    searchType(data.type);
    setSelectedAdsPaymentsStatus(data.adPayment);
    setSelectedBlogPaymentsStatus(data.adBlogPayment);
    setSelectedBlogStatus(data.adBlogStatus);
    setIsAdsModalOpen(true);
  };

  const handleSendFlowers = () => {
    if (seletedProductId != "") {
      setIsSendFlowerLoading(true);
      sendFlower({ prod_id: Number(seletedProductId), target: adsId }).then(
        (res) => {
          if (res?.result_code === 200) {
            fetchFlowerBalance();
            fetchAdListMaster();
            setIsSendFlower(false);
            setSendFlowerSuccessl(true);
            setIsSendFlowerLoading(false);
            setSeletedProductId("");
          } else {
            setIsSendFlower(false);
            setIsSendFlowerLoading(false);
          }
        }
      );
    }
  };

  const handleProductName: React.ChangeEventHandler<HTMLSelectElement> = (
    event
  ) => {
    // setProductNames(event.target.value);
    setSeletedProductId(event.target.value);
  };

  // Search type and Get AD and BLOG price

  const searchType = (typeToSearch: any) => {
    const PriceDataString = localStorage.getItem("AllTypePrice");
    if (!PriceDataString) {
      return;
    }

    try {
      const PriceData = JSON.parse(PriceDataString);
      const result = PriceData[typeToSearch];

      if (result) {
        const { AD, BLOG } = result;

        setSelecedBlogPrice(BLOG);
        setSelecedAdvertAdsPrice(AD);
      } else {
      }
    } catch (error) {
      console.error("Error parsing JSON data from local storage:", error);
    }
  };

  const handleMasterBlog = (adStatus: any, adsId: any, type: any) => {
    setSelecedAdsStatus(adStatus);
    setSelectedAdsId(adsId);
    searchType(type);
    setIsAdsModalOpen(true);
  };

  return (
    <>
      {/* <CustomNavigation
        isOpen={isNavOpen}
        onCallback={(value: boolean) => {
          setIsNavOpen(value);
        }}
        accountType={AccountTypeConst.Master}
      />
      <header>
        <a
          className="lft-arw"
          onClick={() => navigate(AppRoutePath.masterAccount)}
        >
          <i className="fa-solid fa-chevron-left"></i>
        </a>
        <div className="title">{t("ADList")}</div>
        <div>
          <button onClick={handleLanguageSwitch} className="menu-language">
            {language === "en" ? "中文" : "ENG"}{" "}
          </button>
        </div>
        <a href={AppRoutePath.masterSystemMessages} className="stn-btn">
          <i className="fa-regular fa-comment" />
          <i className="fa-solid fa-gear" />{" "}
          {notifCount > 0 && <span>{notifCount}</span>}
        </a>
        <a className="clk-btn" onClick={() => setIsNavOpen(!isNavOpen)}>
          <i className="fa-solid fa-bars"></i>
        </a>
      </header> */}
      <CustomHeader />
      <TopHearder title={t("ADList")} />
      <div className="bg-dark">
        <div className="container">
          <div className="inner-box pt-5">
            {!showCheckboxes && (
              <Link to="/master/add-details">
                <button className="btn primary-button rounded-button mb-3">
                  + {t("addClient")}
                </button>
              </Link>
            )}
            <button
              className="btn primary-button rounded-button margin-left-10 mb-3 me-2"
              onClick={() => setShowCheckboxes(!showCheckboxes)}
            >
              {showCheckboxes ? t("close") : t("selectToDelete")}
            </button>
            {selectedItems.length > 0 && (
              <button
                className="btn primary-button rounded-button margin-left-10 mb-3 ms-0"
                onClick={openDeleteMultipleModal}
                disabled={selectedItems.length == 0}
              >
                {t("deleteSelected")}
              </button>
            )}
            {!showCheckboxes ? (
              <>
                <div className="row my-3">
                  <div className="col-6 py-2 text-white text-12 d-flex align-items-center">
                    {t("searchClientExt")}
                  </div>
                  <div className="col-6 py-2 text-white ">
                    <div className="search-client my-0">
                      <input
                        className="w-100"
                        type="search"
                        name=""
                        placeholder="1"
                        value={searchKeyword}
                        onChange={handleSearchKeyword}
                      />
                    </div>
                  </div>
                  <div className="col-6 py-2 text-white text-12 d-flex align-items-center">
                    {t("englishName")}
                  </div>
                  <div className="col-6 py-2 text-white">
                    <div className="search-client my-0">
                      <input
                        className="w-100"
                        type="search"
                        name=""
                        placeholder={"John"}
                        value={searchEngName}
                        onChange={handleSearchEngName}
                      />
                    </div>
                  </div>
                  <div className="col-6 py-2 text-white text-12  d-flex align-items-center">
                    {t("chineseName")}
                  </div>
                  <div className="col-6 py-2 text-white">
                    <div className="search-client my-0">
                      <input
                        className="w-100"
                        type="search"
                        name=""
                        placeholder="约翰"
                        value={searchChiName}
                        onChange={handleSearchChiName}
                      />
                    </div>
                  </div>
                  <div className="col-12 mt-3">
                    <button
                      className="primary-button button mb-3 w-100"
                      onClick={handleSubmitSearch}
                    >
                      {t("search")}
                    </button>
                  </div>
                </div>

                {/* <div className="search-client">
                  <input
                    className="w-100"
                    type="search"
                    name=""
                    placeholder={t("searchClientExt")}
                    value={searchKeyword}
                    onChange={handleSearchKeyword}
                  />
                  <input
                    type="submit"
                    name="submit"
                    defaultValue="Search"
                    onClick={handleSubmitSearch}
                  />
                </div> */}
              </>
            ) : (
              <>
                <br />
                <br />
              </>
            )}
            <ul className="client-list">
              {/* {(adList?.["item(s)"] ?? []).length === 0 ? (
                <>
                
                 <CustomEmptyMessage />
                <Loaders />
                </>
               
              ) : ( */}

              {isLoadingAds ? (
                <>
                  <div className="text-center pb-3">
                    <Loaders />
                  </div>
                </>
              ) : adList?.["item(s)"]?.length === 0 ? (
                <>
                  {/* Show empty message if no items */}
                  <CustomEmptyMessage />
                </>
              ) : (
                adList?.["item(s)"].map((ad, idx) => (
                  <>
                    {ad.status != 3 && (
                      <li key={idx + 1}>
                        <div className="row mb-3 d-flex align-items-center">
                          {showCheckboxes && (
                            <div className="col-1">
                              <input
                                type="checkbox"
                                checked={selectedItems.includes(idx)}
                                onChange={() => handleToggleSelect(idx)}
                                className="form-check-input"
                              />
                            </div>
                          )}

                          <div style={{ width: "50%" }}>
                            {ad.details?.country_phone_code &&
                            ad.details?.phone_number ? (
                              <h2>
                                +{ad.details?.country_phone_code!}
                                {ad.details?.phone_number ?? ""} (
                                {ad.mobile?.split("EXT")[1]})
                              </h2>
                            ) : (
                              <>
                                {ad.mobile !== null ? (
                                  <h2>
                                    +{ad.mobile?.split("EXT")[0]} (
                                    {ad.mobile?.split("EXT")[1]})
                                  </h2>
                                ) : (
                                  <h2>
                                    {ad.email?.split("EXT")[0]} (
                                    {ad.email?.split("EXT")[1]})
                                  </h2>
                                )}
                              </>
                            )}
                          </div>

                          {!showCheckboxes && (
                            // <Link
                            //   to={`https://141-member.new-app.site/ad-blog/${ad.user_id}`}
                            //   target="_blank"
                            //   style={{ width: "50%" }}
                            // >
                            <Link
                              to={`${AppRoutePath.masterAddPage}/view/${ad.user_id}`}
                            >
                              <div className="text-end">
                                <button className="btn primary-button rounded-button">
                                  {t("showHerAD")}
                                </button>
                              </div>
                            </Link>
                          )}
                        </div>
                        {!showCheckboxes && (
                          <div className="row mb-3">
                            <div className="col-6">
                              {" "}
                              <p>
                                {" "}
                                {langType === "zh" ? ad.zh_name : ad.en_name}
                              </p>
                            </div>
                            <div className="col-6 text-end">
                              <button
                                className="btn primary-button rounded-button"
                                onClick={() => {
                                  setIdxAdListSelected(idx);
                                  setIsDeleteAdModalOpen(true);
                                }}
                              >
                                {t("delete")}
                              </button>
                            </div>
                          </div>
                        )}
                        <div className="row mb-3">
                          <div className="col-6">
                            <p>
                              {langType === "zh"
                                ? ad.details?.basic_desc?.type?.split("|")[1]
                                : ad.details?.basic_desc?.type?.split("|")[0]}
                            </p>
                          </div>
                          {!showCheckboxes && (
                            <div className="col-6 text-end">
                              <Link
                                to={`${AppRoutePath.masterClientMainPage}/${ad.user_id}`}
                              >
                                <button className="btn primary-button rounded-button">
                                  {t("edit")}
                                </button>
                              </Link>
                            </div>
                          )}
                        </div>
                        {!showCheckboxes && <hr />}
                        {!showCheckboxes && (
                          <div className="row mb-3">
                            <div className="col-6">
                              <h4>{t("advertisement")}</h4>
                            </div>
                            {type?.[idx]?.BLOG === true ? (
                              <div className="col-6 text-end">
                                <div className="">
                                  <button
                                    className={
                                      (ad.ad_status ?? 1) === 1
                                        ? "btn primary-button-active rounded-button"
                                        : "btn primary-button rounded-button btn3"
                                    }
                                    // onClick={() => {
                                    //   if (ad.ad_status !== 1) {
                                    //     handleMasterAds({
                                    //       adStatus: ad.ad_status,
                                    //       adsId: ad.user_id,
                                    //       type: ad.details?.basic_desc?.type,
                                    //       adPayment: ad.ad_payment,
                                    //       masterBlogStatus: masterBlogStatus,
                                    //       adBlogStatus: ad.blog_status,
                                    //       adBlogPayment: ad.blog_payment,
                                    //     });
                                    //   }
                                    // }}

                                    onClick={() => {
                                      if ((ad.ad_status ?? 0) !== 1) {
                                        controlAdClient(1, ad.user_id).then(
                                          (isSuccess) => {
                                            if (isSuccess) {
                                              const resAdList = adList;
                                              resAdList["item(s)"][
                                                idx
                                              ].ad_status = 1;
                                              setAdList({ ...resAdList });

                                              controlBlogClient(
                                                1,
                                                ad.user_id
                                              ).then((isSuccess) => {
                                                if (isSuccess) {
                                                  const resAdList = adList;
                                                  resAdList["item(s)"][
                                                    idx
                                                  ].blog_status = 1;
                                                  setAdList({ ...resAdList });
                                                }
                                              });
                                            }
                                          }
                                        );
                                      }
                                      setIsADModalOpen(true);
                                    }}
                                  >
                                    {t("ON")}
                                  </button>
                                  <button
                                    className={
                                      (ad.ad_status ?? 0) === 0
                                        ? "btn primary-button rounded-button margin-left-10"
                                        : "btn primary-button rounded-button btn3 margin-left-10"
                                    }
                                    onClick={() => {
                                      if ((ad.ad_status ?? 0) !== 0) {
                                        controlAdClient(0, ad.user_id).then(
                                          (isSuccess: any) => {
                                            if (isSuccess) {
                                              const resAdList = adList;
                                              resAdList["item(s)"][
                                                idx
                                              ].ad_status = 0;
                                              setAdList({ ...resAdList });
                                            }
                                          }
                                        );
                                        controlBlogClient(0, ad.user_id).then(
                                          (isSuccess) => {
                                            if (isSuccess) {
                                              const resAdList = adList;
                                              resAdList["item(s)"][
                                                idx
                                              ].blog_status = 0;
                                              setAdList({ ...resAdList });
                                            }
                                          }
                                        );
                                      }
                                      setIsADModalOpen(false);
                                    }}
                                  >
                                    {t("OFF")}
                                  </button>
                                </div>
                              </div>
                            ) : (
                              <div className="col-6 text-end">
                                <div className="">
                                  <button
                                    className={
                                      (ad.ad_status ?? 0) === 1
                                        ? "btn primary-button-active rounded-button"
                                        : "btn primary-button rounded-button btn3"
                                    }
                                    onClick={() => {
                                      if (ad.ad_status !== 1) {
                                        handleMasterAds({
                                          adStatus: ad.ad_status,
                                          adsId: ad.user_id,
                                          type: ad.details?.basic_desc?.type,
                                          adPayment: ad.ad_payment,
                                          masterBlogStatus: masterBlogStatus,
                                          adBlogStatus: ad.blog_status,
                                          adBlogPayment: ad.blog_payment,
                                        });
                                      }
                                    }}
                                    // onClick={() => {
                                    //   setIsAdsModalOpen(true)
                                    //   if ((ad.ad_status ?? 0) !== 1) {
                                    //     controlAdClient(1, ad.user_id).then(
                                    //       (isSuccess: any) => {
                                    //         if (isSuccess) {
                                    //           const updatedAdList = adList[
                                    //             "item(s)"
                                    //           ].map((item, index) => {
                                    //             if (index === idx) {
                                    //               // Update the ad_status and blog_status for the clicked item
                                    //               return {
                                    //                 ...item,
                                    //                 ad_status: 1,
                                    //                 blog_status:
                                    //                   masterBlogStatus === 1
                                    //                     ? 1
                                    //                     : 0,
                                    //               };
                                    //             }
                                    //             return item;
                                    //           });
                                    //           setAdList({
                                    //             ...adList,
                                    //             "item(s)": updatedAdList,
                                    //           });
                                    //         }
                                    //       }
                                    //     );

                                    //     controlBlogClient(
                                    //       masterBlogStatus === 1 ? 1 : 0,
                                    //       ad.user_id
                                    //     ).then((isSuccess) => {
                                    //       if (isSuccess) {
                                    //         const resAdList = adList;
                                    //         resAdList["item(s)"][
                                    //           idx
                                    //         ].blog_status =
                                    //           masterBlogStatus === 1 ? 1 : 0;
                                    //         setAdList({ ...resAdList });
                                    //       }
                                    //     });
                                    //   }
                                    // }}
                                  >
                                    {t("ON")}
                                  </button>
                                  <button
                                    className={
                                      (ad.ad_status ?? 0) === 0
                                        ? "btn primary-button rounded-button margin-left-10"
                                        : "btn primary-button rounded-button btn3 margin-left-10"
                                    }
                                    onClick={() => {
                                      if ((ad.ad_status ?? 0) !== 0) {
                                        controlAdClient(0, ad.user_id).then(
                                          (isSuccess: any) => {
                                            if (isSuccess) {
                                              const updatedAdList = adList[
                                                "item(s)"
                                              ].map((item, index) => {
                                                if (index === idx) {
                                                  // Update the ad_status and blog_status for the clicked item
                                                  return {
                                                    ...item,
                                                    ad_status: 0,
                                                    blog_status: 0,
                                                  };
                                                }
                                                return item;
                                              });
                                              setAdList({
                                                ...adList,
                                                "item(s)": updatedAdList,
                                              });
                                            }
                                          }
                                        );
                                        // controlBlogClient(0, ad.user_id).then(
                                        //   (isSuccess) => {
                                        //     if (isSuccess) {
                                        //       const resAdList = adList;
                                        //       resAdList["item(s)"][
                                        //         idx
                                        //       ].blog_status = 0;
                                        //       setAdList({ ...resAdList });
                                        //     }
                                        //   }
                                        // );
                                      }
                                    }}
                                    // disabled={masterBlogStatus === 1}
                                  >
                                    {t("OFF")}
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        )}

                        {!showCheckboxes && (
                          <div className="row mb-3">
                            <div className="col-6">
                              <h4>{t("blog")}</h4>
                            </div>
                            <>
                              {ad.details?.basic_desc?.type &&
                              type?.[idx]?.BLOG === true ? (
                                <div
                                  className="col-6 text-end"
                                  style={{ marginTop: -3, marginLeft: -10 }}
                                >
                                  <span
                                    style={{
                                      color:
                                        ad.blog_status === 1
                                          ? "#35FD0D"
                                          : "grey",
                                      fontWeight: 700,
                                    }}
                                  >
                                    {ad.blog_status === 1 ? t("ON") : t("OFF")}
                                  </span>
                                </div>
                              ) : (
                                <div className="col-6 text-end">
                                  <button
                                    className={
                                      ad.blog_status === 1
                                        ? "btn primary-button-active rounded-button"
                                        : "btn primary-button rounded-button btn3"
                                    }
                                    onClick={(e) => {
                                      handleAdsBlog({
                                        user_id: ad.user_id,
                                        adStatus: ad.ad_status,
                                        blogStatus: 1,
                                        currentBlogStatus: ad.blog_status,
                                        blogON: true,
                                        type: ad.details?.basic_desc?.type,
                                        adPayment: ad.ad_payment,
                                        adBlogStatus: ad.blog_status,
                                        adBlogPayment: ad.blog_payment,
                                      });
                                    }}
                                  >
                                    {t("ON")}
                                  </button>
                                  <button
                                    className={
                                      ad.blog_status === 0
                                        ? "btn primary-button rounded-button margin-left-10"
                                        : "btn primary-button rounded-button btn3 margin-left-10"
                                    }
                                    onClick={(e) => {
                                      handleAdsBlog({
                                        user_id: ad.user_id,
                                        adStatus: ad.ad_status,
                                        blogStatus: 0,
                                        currentBlogStatus: ad.blog_status,
                                        blogON: false,
                                      });
                                    }}
                                    disabled={
                                      masterBlogStatus === 1 &&
                                      ad.ad_status === 1
                                    }
                                  >
                                    {t("OFF")}
                                  </button>
                                </div>
                              )}
                            </>
                          </div>
                        )}

                        {!showCheckboxes && (
                          <div className="row">
                            <div className="col-6">
                              <h4 className="mb-0">
                                <img src="/img/flower_icon.png" alt="" />{" "}
                                {ad?.total_flower}
                              </h4>
                              <br />
                              <button
                                className="btn primary-button rounded-button "
                                disabled={ad.ad_status !== 1}
                                onClick={(e) => {
                                  setAdsId(Number(ad.user_id));
                                  setIsSendFlower(true);
                                  // setProductNames(prod_name);
                                }}
                              >
                                {t("sendFlower")}
                              </button>
                            </div>
                            <div className="col-6 text-end">
                              <Link
                                to={`${AppRoutePath.masterMyInstantReview}/${ad.user_id}`}
                              >
                                <button className="btn primary-button rounded-button">
                                  {t("InstantReview")}
                                </button>
                              </Link>
                              <br />
                              <Link
                                to={`${AppRoutePath.masterMyInstantPosts}/${ad.user_id}`}
                              >
                                <button className="btn primary-button rounded-button mt-3">
                                  {t("instantPost")}
                                </button>
                              </Link>
                            </div>

                            <div className="col-12 d-flex justify-content-end">
                              <Link
                                to={`${AppRoutePath.masterAddInstantPosts}/${ad.user_id}`}
                              >
                                <button className="btn primary-button rounded-button mt-3">
                                  {t("addNewInstantPost")}
                                </button>
                              </Link>
                            </div>

                            {/* <div className="col-12 mt-3">
                              <button
                                className="btn primary-button rounded-button"
                                onClick={(e) => {
                                    "Number(ad.user_id) ",
                                    Number(ad.user_id)
                                  );

                                  setAdsId(Number(ad.user_id));
                                  setIsSendFlower(true);
                                }}
                              >
                                {t("sendFlower")}
                              </button>
                            </div> */}
                          </div>
                        )}
                      </li>
                    )}
                  </>
                ))
              )}
              {!showCheckboxes && (
                <Link to={AppRoutePath.masterAccount}>
                  <button className="primary-button button mb-3 w-100">
                    {t("done")}
                  </button>
                </Link>
              )}
            </ul>
          </div>
        </div>
      </div>
      <Modal show={isDeleteMultipleAdModalOpen}>
        <Modal.Body>
          <Modal.Title>
            <center>
              <h5 className="modal-title">{t("deleteAdPrompt")}</h5>
            </center>
            <h6
              className="modal-title"
              style={{ marginTop: 8, color: "yellow" }}
            >
              {t("actionCannotUndo")}
            </h6>
          </Modal.Title>
        </Modal.Body>

        <Modal.Footer>
          <div className="d-flex justify-content-center align-items-center">
            <button
              type="button"
              className="button mt-3 w-100 no-button"
              data-bs-dismiss="modal"
              onClick={() => setIsDeleteMultipleAdModalOpen(false)}
            >
              {t("no")}
            </button>
            <div style={{ width: 50 }}></div>
            <button
              type="button"
              className="primary-button button mt-3 w-100"
              data-bs-dismiss="modal"
              onClick={handleDeleteSelected}
              disabled={isLoading}
            >
              {isLoading ? <Spinner size="sm" /> : <span>{t("yes")}</span>}
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal show={isDeleteAdModalOpen}>
        <Modal.Body>
          <Modal.Title>
            <center>
              <h5 className="modal-title">{t("deleteAdPrompt")}</h5>
            </center>
            <h6
              className="modal-title"
              style={{ marginTop: 8, color: "yellow" }}
            >
              {t("actionCannotUndo")}
            </h6>
          </Modal.Title>
        </Modal.Body>

        <Modal.Footer>
          <div className="d-flex justify-content-center align-items-center">
            <button
              type="button"
              className="button mt-3 w-100 no-button"
              data-bs-dismiss="modal"
              onClick={() => setIsDeleteAdModalOpen(false)}
            >
              {t("no")}
            </button>
            <div style={{ width: 50 }}></div>
            <button
              type="button"
              className="primary-button button mt-3 w-100"
              data-bs-dismiss="modal"
              disabled={isLoading}
              onClick={() => {
                setIsLoading(true);
                deleteAdsMaster(
                  String(adList?.["item(s)"][idxAdListSelected]?.user_id)
                ).then((isSuccess) => {
                  if (isSuccess) {
                    setIsDeleteAdModalOpen(false);
                    getAdListMaster({}).then((resAdList) => {
                      if (resAdList != null) {
                        setAdList(resAdList);
                      }
                    });
                  }
                  setIsLoading(false);
                });
              }}
            >
              {isLoading ? <Spinner size="sm" /> : <span>{t("yes")}</span>}
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal show={isBlogAdvertisementModalOpen}>
        <Modal.Body>
          <Modal.Title>
            <center>
              {/* <h5 className="modal-title" style={{fontSize: 20}}> */}
              <h5 className="modal-title">{t("turnOnAdPrompt")}</h5>
            </center>
          </Modal.Title>
        </Modal.Body>

        <Modal.Footer>
          <div className="d-flex justify-content-center align-items-center">
            <button
              type="button"
              className="primary-button button mt-3"
              style={{ width: 100 }}
              data-bs-dismiss="modal"
              onClick={() => setIsBlogAdvertisementModalOpen(false)}
            >
              {t("ok")}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* {adList?.["item(s)"].map((ad, idx) => (
        <Modal show={isADModalOpen}>
          <Modal.Body>
            <Modal.Title>
              <center>
                <h5 className="modal-title">{t("turnOnAdPrompt2")}</h5>
              </center>
            </Modal.Title>
          </Modal.Body>

          <Modal.Footer>
            <div className="d-flex justify-content-center align-items-center">
              <button
                type="button"
                className="primary-button button mt-3 w-100"
                data-bs-dismiss="modal"
                onClick={() => {
                  controlBlogClient(1, adBlogId).then((isSuccess) => {
                    if (isSuccess) {
                      setIsBlogModalOpen(false);
                      fetchAdListMaster();
                    }
                  });
                }}
              >
                {t("yes")}
              </button>
              <div style={{ width: 50 }}></div>
              <button
                type="button"
                className="primary-button button mt-3 w-100"
                data-bs-dismiss="modal"
                onClick={() => setIsADModalOpen(false)}
              >
                {t("no")}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      ))} */}
      <Modal show={isBlogModalOpen}>
        <Modal.Body>
          <Modal.Title>
            <center>
              <h5 className="modal-title">
                {t("payAdvertisement")}

                <br />
                <center
                  style={{
                    margin: "10px 0",
                    fontSize: 30,
                    color: "#FF3751",
                  }}
                >
                  <div
                    style={{
                      display: "inline",
                      padding: "5px 10px",
                      fontSize: 20,
                      border: "1px solid #FF3751",
                      borderRadius: "100%",
                    }}
                  >
                    $
                  </div>{" "}
                  {selecedBlogPrice}
                </center>
                <br />
                {t("payblog")}
              </h5>
            </center>
          </Modal.Title>
        </Modal.Body>

        <Modal.Footer>
          <div className="d-flex justify-content-center align-items-center">
            <button
              type="button"
              className="primary-button button mt-3 w-100"
              data-bs-dismiss="modal"
              onClick={() => setIsBlogModalOpen(false)}
            >
              {t("no")}
            </button>
            <div style={{ width: 50 }}></div>
            <button
              type="button"
              className="primary-button button mt-3 w-100"
              data-bs-dismiss="modal"
              onClick={() => {
                setIsLoading(true);
                controlBlogClient(1, adBlogId).then((isSuccess) => {
                  if (isSuccess) {
                    setIsBlogModalOpen(false);
                    setIsLoading(false);
                    fetchAdListMaster();
                  }
                  setIsLoading(false);
                });
              }}
              disabled={isLoading}
            >
              {isLoading ? <Spinner size="sm" /> : <span>{t("yes")}</span>}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal show={isBlogPaidModalOpen}>
        {/* <Modal show={isBlogAdvertisementModalOpen}> */}
        <Modal.Body>
          <Modal.Title>
            <center>
              <h5 className="modal-title">
                {t("paidAdvertisement")}
                {t("payblog")}
              </h5>
            </center>
          </Modal.Title>
        </Modal.Body>

        <Modal.Footer>
          <div className="d-flex justify-content-center align-items-center">
            <button
              type="button"
              className="primary-button button mt-3 w-100"
              data-bs-dismiss="modal"
              onClick={() => setIsBlogPaidModalOpen(false)}
            >
              {t("no")}
            </button>
            <div style={{ width: 50 }}></div>
            <button
              type="button"
              className="primary-button button mt-3 w-100"
              data-bs-dismiss="modal"
              onClick={() => {
                setIsLoading(true);
                controlBlogClient(1, adBlogId).then((isSuccess) => {
                  if (isSuccess) {
                    setIsBlogPaidModalOpen(false);
                    setIsLoading(false);
                    fetchAdListMaster();
                  }
                  setIsLoading(false);
                });
              }}
              disabled={isLoading}
            >
              {isLoading ? <Spinner size="sm" /> : <span>{t("yes")}</span>}
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal show={isBlogOff} style={{ padding: 30 }}>
        <button
          className="close-blog"
          type="button"
          aria-label="Close"
          onClick={() => {
            setIsBlogOff(false);
          }}
        >
          X
        </button>
        <Modal.Body>
          <div className="d-flex justify-content-center align-items-center">
            <Modal.Title>
              <h3 className="modal-title text-center">
                {t("reminder")} <br />
                <br />
              </h3>
              <h3 className="modal-important text-center">
                {t("important")}! <br />
                <br />
              </h3>
              <center className="modal-reminder text-center">
                {t("blogOffNote")}
                <br />
                <br />
                {t("contactCS")}
              </center>
              <br />
              <h5 className="modal-title text-center">{t("turnOffBlog")}</h5>
            </Modal.Title>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="col">
              <button
                className="button btn3 rounded-button w-100"
                onClick={() => {
                  setIsBlogOff(false);
                }}
              >
                {t("no")}
              </button>
            </div>
            <div className="col">
              <button
                className="button primary-button rounded-button w-100"
                disabled={isLoading}
                onClick={() => {
                  setIsLoading(true);
                  controlBlogClient(0, adBlogId).then((res) => {
                    if (res) {
                      if (res.data.result_code !== 200) {
                        new Snackbar(
                          `${res.data.message} <br> <br> ${res.data.reason}`
                        );
                        setIsBlogOff(false);
                      } else {
                        setIsBlogOff(false);
                        fetchAdListMaster();
                      }
                    }
                    setIsLoading(false);
                  });
                }}
              >
                {isLoading ? <Spinner size="sm" /> : <span>{t("yes")}</span>}
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal show={isSendFlower}>
        <Modal.Body>
          <div className="d-flex justify-content-center align-items-center">
            <Modal.Title>
              <h5 className="modal-title text-center">
                {/* Reload <span className="color-pink-3" style={{fontWeight:"bold" , fontSize:17 }}>{quantity1}</span> {walletName} Successful */}
              </h5>
            </Modal.Title>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="col-12">
              <label htmlFor="" className="text-white pb-2">
                {t("packageNames")}
              </label>
              <select
                className="form-select input-md w-100 "
                id="typeSelectOption"
                onChange={handleProductName}
              >
                <option value="" selected disabled>
                  {t("flowerBalPack")}
                </option>
                {flowerBalanceArray?.map((item, index) => {
                  // const keys = Object.keys(item);
                  // const englishLabel = keys[0].split("|")[0];
                  // const chinesLabel = keys[0].split("|")[1];

                  const { prod_name, quantity, prod_id } = item as {
                    prod_name: string;
                    quantity: number;
                    prod_id: any;
                  };

                  const [englishName, chineseName] = (
                    prod_name as string
                  ).split("|");
                  return (
                    <option key={index} value={prod_id}>
                      {/* {langType === "zh" ? chinesLabel : englishLabel} */}
                      {langType === "zh" ? chineseName : englishName}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-12 d-flex gap-2 mt-3">
              <button
                className="button primary-button rounded-button w-100"
                onClick={() => {
                  setIsSendFlower(false);
                  setSeletedProductId("");
                }}
                disabled={isSendFlowerLoading}
              >
                {t("cancel")}
              </button>
              <button
                className="button primary-button rounded-button w-100"
                disabled={
                  flowerBalanceArray.length < 1 || seletedProductId == ""
                }
                onClick={() => {
                  handleSendFlowers();
                }}
              >
                {isSendFlowerLoading ? (
                  <Spinner size="sm" />
                ) : (
                  <span>{t("sendFlower")} </span>
                )}
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal show={sendFlowerSuccess}>
        <Modal.Header>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={(e) => setSendFlowerSuccessl(false)}
          ></button>
        </Modal.Header>
        <Modal.Body>
          <div className="reload-flower-wrap">
            <div className="flower-top">
              <SizedBox width={50} />
            </div>

            <p className="text-center"> {t("sendFlowerAdsInfo")}</p>

            <div className="text-center">
              <button
                id="blog"
                className="btn primary-button rounded-button abs-btn coin-btn w-70"
                onClick={() => setSendFlowerSuccessl(false)}
              >
                {t("close")}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Master Ad Model */}

      <Modal show={isAdsModalOpen}>
        <Modal.Body>
          <div className="d-flex justify-content-center align-items-center">
            <Modal.Title>
              {!selectedAdsPaymentsStatus ? (
                <>
                  <h5 className="modal-title text-center">
                    {t("payAdvertisement")}
                    <br />
                    <center
                      style={{
                        margin: "10px 0",
                        fontSize: 30,
                        color: "#FF3751",
                      }}
                    >
                      <div
                        style={{
                          display: "inline",
                          padding: "5px 10px",
                          fontSize: 20,
                          border: "1px solid #FF3751",
                          borderRadius: "100%",
                        }}
                      >
                        $
                      </div>{" "}
                      {selecedAdvertAdsPrice}
                    </center>
                    <br />
                    {t("payAdvertisement2")}
                  </h5>

                  {masterBlogStatus === 1 && (
                    <h5 className="modal-title text-center">
                      & <br />
                      {t("payAdvertisement")}
                      <br />
                      <center
                        style={{
                          margin: "10px 0",
                          fontSize: 30,
                          color: "#FF3751",
                        }}
                      >
                        <div
                          style={{
                            display: "inline",
                            padding: "5px 10px",
                            fontSize: 20,
                            border: "1px solid #FF3751",
                            borderRadius: "100%",
                          }}
                        >
                          $
                        </div>{" "}
                        {selecedBlogPrice}
                      </center>
                      <br />
                      {t("payblog")}
                    </h5>
                  )}
                </>
              ) : (
                <h5 className="modal-title text-center">
                  {t("paidAdvertisement")}

                  {t("payAdvertisement2")}
                </h5>
              )}
            </Modal.Title>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="col">
              <button
                className="button btn3 rounded-button w-100"
                onClick={() => {
                  setIsAdsModalOpen(false);
                }}
              >
                {t("no")}
              </button>
            </div>
            <div className="col">
              <button
                className="button primary-button rounded-button w-100"
                disabled={isLoading}
                onClick={() => {
                  setIsAdsModalOpen(true);
                  setIsLoading(true);
                  if ((selecedAdsBlogStatus ?? 0) !== 1) {
                    controlAdClient(1, selectedAdsId).then(
                      async (isSuccess: any) => {
                        if (isSuccess) {
                          setIsAdsModalOpen(false);
                          setIsLoading(false);

                          if (masterBlogStatus === 1) {
                            controlBlogClient(1, selectedAdsId).then(
                              (isSuccess) => {
                                if (isSuccess) {
                                  fetchAdListMasterBlogAndAds();
                                }
                              }
                            );
                          }
                          fetchAdListMasterBlogAndAds();
                        }
                        setIsLoading(false);
                      }
                    );
                  }
                }}
              >
                {isLoading ? <Spinner size="sm" /> : <span>{t("yes")}</span>}
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
