import Snackbar from "awesome-snackbar";
import _axios from "axios";
import LocalStorageKey from "../constants/LocalStorageKey";
const baseURL =
  process.env.REACT_APP_BASE_URL;
const axios = _axios.create({ baseURL });

axios.interceptors.request.use((config) => {
  var X_AUTH_TOKEN =
    localStorage.getItem(LocalStorageKey.token);
  const X_VERIFY_TOKEN = localStorage.getItem("vkey") ?? "";

  if (X_AUTH_TOKEN === 'undefined') {
    X_AUTH_TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImY2ZTBmNjkyLWM5MjktMTFlZC05ZGQ4LTAyNTE1NDFjNmFhOCIsImNvdW50cnlfcGhvbmVfY29kZSI6Ijg1MiIsInBob25lX251bWJlciI6IjEyMzQ1Njc4IiwiaWF0IjoxNjc5NTQxNzU5LCJleHAiOjE2Nzk1NDI2MjN9.-2iDqqTjuDtQif4Shz0M54wLt7WYA1VehiHIcUrWNWI'
  }

  if (X_AUTH_TOKEN !== '') {
    config.headers.set("Authorization", `Bearer ${X_AUTH_TOKEN}`);
  }
  config.headers.set("X-Verify-Key", X_VERIFY_TOKEN);

  return config;
},

  error => {
    return Promise.reject(error)
  }

);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error);
    const { response } = error;
    if (response) {
      const errorObject = response.data;
      const code = errorObject.result_code;

      if (code === 401) {
        new Snackbar("Your login session has expired. Please login again")
      }

      throw errorObject;
    }

    throw error;
  }
);

export default axios;
