import { WalletTransactionType } from "../../../../constants/WalletTransactionType";
import { WalletType } from "../../../../constants/WalletType";
import FlowerWalletTemplate from "../template/FlowerWalletPageTemplate";
import HeartWalletTemplate from "../template/HeartWalletPageTemplate";
import OneCoinWalletTemplate from "../template/OneCoinWalletPageTemplate";
import ReloadFlowerWalletPageTemplate from "../template/ReloadFlowerWalletPageTemplate";
import ReloadHeartWalletPageTemplate from "../template/ReloadHeartWalletPageTemplate";


export default interface IClientWalletPageTemplate {
  type: WalletType;
  transactionType?: WalletTransactionType;
  title: string;
  id?: number;
  cardComp: () => Promise<JSX.Element>;
  tableComp: (page: number, per_page: number) => Promise<JSX.Element>;
  updateCard?: () => void;
  updateTable?: (page: number, per_page: number) => void;
}

export const getWalletTempateByType: (
  type: WalletType,
  transactionType?: WalletTransactionType
) => IClientWalletPageTemplate = (
  type: WalletType,
  transactionType?: WalletTransactionType
) => {
  switch (type) {
    case WalletType.OneCoin:
      return new OneCoinWalletTemplate();
    case WalletType.Flower: {
      switch (transactionType) {
        case WalletTransactionType.Reload:
          return new ReloadFlowerWalletPageTemplate();
        default:
          return new FlowerWalletTemplate();
      }
    }
    case WalletType.Heart:
      switch (transactionType) {
        case WalletTransactionType.Reload:
          return new ReloadHeartWalletPageTemplate();
        default:
          return new HeartWalletTemplate();
      }
    default:
      return new OneCoinWalletTemplate();
  }
};
