import React, { useState } from "react";
import PropTypes from "prop-types";
import { TChatModel } from "../../../domain/model/t-chat-model";

export default function ChatCard(
  props: TChatModel & {
    contentClass?: string;
    onClick?: () => undefined | void;
  }
): JSX.Element {
  const [editMode, setEditMode] = useState(false);
  const [editedContent, setEditedContent] = useState(props.content);
  const [currentCommentId, setCurrentCommentId] = useState(props.inst_cmt_id);

  const handleEditClick = (id: any) => {
    if (isAnotherEditModeInUse()) {
      // Another comment is already in edit mode, close the previous one
      alert(
        "Another comment is already in edit mode. Please close the current edit mode before editing another comment."
      );
    } else {
      // Set the comment to edit mode
      setEditMode(true);
      setCurrentCommentId(id);
      
    }
  };

  const allComments: any = [];

  const isAnotherEditModeInUse = () => {
    // Get all the comments that are currently in edit mode
    const commentsInEditMode = [];
    for (const comment of allComments) {
      if (comment.isEditing) {
        commentsInEditMode.push(comment);
      }
    }

    // Check if there is more than one comment in edit mode
    return commentsInEditMode.length > 1;
  };

  const handleCancelClick = () => {
    setEditMode(false);
    setEditedContent("");
  };

  return (
    <div className="row  chat-DIV">
      <div className=" col-lg-2 col-2 ">
        <div className="clt-img-profile-sm-2">
          <img
            src={props?.photo ? props?.photo : "/img/blank-profile-picture.png"}
            alt=""
          />
        </div>
      </div>

      <div className="col-lg-10  col-10   border-0 comment-div ">
        <div className="d-flex justify-content-between ">
          <h6 className="primary-color">{props?.nickname} </h6>
          <small className="text-muted">{props?.date}</small>
        </div>
        <div className="d-flex justify-content-between ">
          {editMode && currentCommentId === props.inst_cmt_id ? (
            <textarea
              rows={5}
              className="form-control form-control-cus ACD"
              value={props.editedContent || props.content}
              onChange={props.handleContentChange}
              style={{ height: 60, borderRadius: 5 }}
              maxLength={100}
            ></textarea>
          ) : (
            <p className={`mb-0 ${props.contentClass}`}>{props.content}</p>
          )}

          {/* <p className={`mb-0 ${props?.contentClass} `}>{props?.content}</p> */}

          {props.newMsgCount && (
            <small className="chat-count rounded-circle bg-1">
              {props.newMsgCount}
            </small>
          )}
        </div>
        <div></div>
        {props.currentUser && (
          <div className="d-flex gap-2">
            {editMode && currentCommentId === props.inst_cmt_id ? (
              <>
                <button
                  className="btn primary-button rounded-button my-3"
                  onClick={() => {
                    props.updateComments && props.updateComments(0);
                    setEditMode(false);
                  }}
                >
                  Save
                </button>
                <button
                  className=" my-3 btn-cancel "
                  onClick={handleCancelClick}
                >
                  Cancel
                </button>
              </>
            ) : (
              <>
                <button
                  className="btn primary-button rounded-button my-3 "
                  onClick={() => {
                    props.deleteComments &&
                      props.deleteComments(props.inst_cmt_id || 0);
                  }}
                >
                  <i className="fa-solid fa-trash pe-1"></i> Delete
                </button>

                <button
                  className="btn primary-button rounded-button my-3  "
                  onClick={() => handleEditClick(props.inst_cmt_id)}
                >
                  <i className="fa-solid fa-edit pe-1"></i> Edit
                </button>
              </>
            )}
            ;
          </div>
        )}

        {/* {props.currentUser && (
          <>
         
            <div className="row  mt-3">
              <div className="col-12 d-flex ">
                <div className=" d-inline">
                  <img
                    className="clt-img2 "
                    src={
                      "https://d2zzhkl4ews4a1.cloudfront.net/viewer/517/media/ad/ad_517_1686854996_1.PNG"
                      // props?.photo ? props?.photo : "/img/blank-profile-picture.png"
                    }
                    alt=""
                  />
                </div>
                <div className="ps-3 ">
                  <small className=" pb-3 text-white">
                    This is reply test 2, This is reply test 2, This is reply
                    test 2,This is reply test 2, This is reply test 2
                  </small>
                 
                </div>
              </div>
              <div className="col-2"></div>
            </div>
          </>
        )} */}
        {/* {props.currentUser && (
          <div className="row  mt-3">
            <div className="col-lg-12 col-12   border-0 comment-div d-flex gap-2">
              <input className="form-control" />
              <button className="btn primary-button rounded-button ">
                Reply
              </button>
            
            </div>
          </div>
        )} */}

        <hr className="primary-color" />
      </div>
    </div>
  );
}
