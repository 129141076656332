import Snackbar from "awesome-snackbar";
import * as React from "react";
import AppRoutePath from "../../constants/AppRoutePath";
import {
  Button,
  Modal,
  OverlayTrigger,
  Popover,
  Spinner,
} from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import CreateUserParams from "../../domain/model/create-user-params";
import { OperatingHour } from "../../domain/model/create-user-params-model";
import MasterAccountDetailsType from "../../domain/enum/master-account-details-type";
import { getShop } from "../../services/master/master-shop";
import { getServiceType, getTypes } from "../../services/modules";
import { useTranslation } from "react-i18next";

import CustomNavigation from "../reusable/Navigation";
import AccountTypeConst from "../../domain/enum/account-type";

import {
  getMasterShopUpload,
  deleteMasterShopBanner,
} from "../../services/upload";
import CustomHeader from "../reusable/Header";
import TopHearder from "../reusable/TopHearder";

export interface IMasterAccountDetailsProps {}

export default function MasterAccountDetails(
  props: IMasterAccountDetailsProps
) {
  const [accountDetailType, setAccountDetailType] = React.useState(
    MasterAccountDetailsType.Register
  );

  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const createUserParams = CreateUserParams.getInstance();
  const imgRef = React.useRef<HTMLInputElement>(null);
  const [stepProgress, setStepProgress] = React.useState(0);
  // const [isFormValid, setIsFormValid] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);

  const [isNavOpen, setIsNavOpen] = React.useState(true);

  const [chineseShopName, setChineseShopName] = React.useState("");
  const [englishShopName, setEnglishShopName] = React.useState("");
  const [shopBannerFile, setShopBannerFile] = React.useState<File | null>(null);
  const [shopBannerUrl, setShopBannerUrl] = React.useState("");
  const [shopBannerName, setShopBannerName] = React.useState("");
  const [showDeletePhotoButton, setShowDeletePhotoButton] =
    React.useState(false);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDeletingImage, setIsDeletingImage] = React.useState(false);
  const [isDeletingImageBefore, setIsDeletingImageBefore] =
    React.useState(false);

  const [serviceTypes, setServiceTypes] = React.useState<string[]>([]);

  const [type, setType] = React.useState<string>();
  const [listTypes, setListTypes] = React.useState<string[]>([]);
  const [listService, setListService] = React.useState<string[]>([]);
  const [listOperatingHour, setListOperatingHour] = React.useState<
    OperatingHour[]
  >([]);

  const [activePopover, setActivePopover] = React.useState(null);

  const handlePopoverToggle = (popoverType: any) => {
    if (activePopover === popoverType) {
      setActivePopover(null);
    } else {
      setActivePopover(popoverType);
    }
  };
  React.useEffect(() => {
    setListOperatingHour(
      [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ].map((d) => {
        return {
          day: d,
          open_hours: "01:00",
          close_hours: "01:00",
          rest: false,
        };
      })
    );
  }, []);

  React.useEffect(() => {
    getTypes().then((types) => {
      if (types.length > 0) setType(types[0]);

      setListTypes(types);
    });

    getMasterShopUpload().then((apiResponse: any) => {
      if (apiResponse["item(s)"][0]) {
        setShopBannerUrl(apiResponse["item(s)"][0].cache_url);
        setShowDeletePhotoButton(true);
        setShopBannerName(apiResponse["item(s)"][0].name ?? "");
      }
    });
  }, []);

  React.useEffect(() => {
    if (type) getServiceType(type).then((value) => setServiceTypes(value));
  }, [type]);

  React.useEffect(() => {
    const masterAccountTypeSelected =
      MasterAccountDetailsType.getPageTypeByPath(params.type ?? "");
    setAccountDetailType(masterAccountTypeSelected);

    if (masterAccountTypeSelected === MasterAccountDetailsType.Edit) {
      getShop().then((shop) => {
        if (shop != null) {
          setEnglishShopName(shop.en_name ?? "");
          setChineseShopName(shop.zh_name ?? "");
          // setShopBannerUrl(shop.photo ?? "");
          // console.log("sh ", shop);
        }
      });
    }
  }, [params.type]);

  const handleChineseShopName: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setChineseShopName(event.target.value);
  };
  const handleEnglishShopName: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setEnglishShopName(event.target.value);
  };
  const handleChangeType: React.ChangeEventHandler<HTMLSelectElement> = async (
    event
  ) => {
    setType(event.target.value);
  };
  const handleUploadShopBannerFile: React.ChangeEventHandler<
    HTMLInputElement
  > = (event) => {
    const target = event.target as HTMLInputElement;
    const fileSelected = (target.files as FileList)[0];
    const maxAllowedSize = 20 * 1024 * 1024;
    if (fileSelected.size > maxAllowedSize) {
      new Snackbar("Image larger than 20 Mb!");
    } else {
      setShopBannerFile(fileSelected);
    }
  };
  const showOpenFileDlg = () => {
    imgRef.current?.click();
  };
  const handleDeleteShopBannerFile = () => {
    setIsDeletingImageBefore(true);
    if (shopBannerFile != null) {
      setShopBannerFile(null);
      setShowDeletePhotoButton(false);
      setIsSaveButtonDisabled(shopBannerFile === null);
      setIsDeletingImageBefore(false);
    }
  };

  const handleDeleteExistingShopBanner = async () => {
    setIsDeletingImage(true);
    deleteMasterShopBanner(shopBannerName)
      .then((response) => {
        if (response) {
          setIsDeletingImage(false);
          setShopBannerUrl("");
          setShowDeletePhotoButton(false);
        } else {
          // Handle API request failure here
          console.error("Failed to delete banner");
          setIsDeletingImage(false);
        }
      })
      .catch((error) => {
        // Handle API request error here
        console.error("An error occurred while deleting banner:", error);
      });
  };

  const handleSelectService: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    if (event.target.checked) {
      setListService([...listService, event.target.value]);
    } else {
      setListService(
        listService.filter((service) => service !== event.target.value)
      );
    }
  };

  const getIsFormValid = () => {
    switch (stepProgress) {
      case 0:
        return (
          chineseShopName !== "" && englishShopName !== ""
          // (shopBannerFile != null || shopBannerUrl !== "")
        );
      case 1:
        return (
          chineseShopName !== "" && englishShopName !== ""
          // &&
          // (shopBannerFile != null || shopBannerUrl !== "")
          // shopBannerFile != null &&
          // type !== "" &&
          // listService.length > 0 &&
          // listOperatingHour.length > 0
        );
      default:
        return false;
    }
  };

  const handleNext = async () => {
    setIsLoading(true);
    if (getIsFormValid()) {
      const progressNow = await accountDetailType.onNext(
        {
          chineseShopName: chineseShopName,
          englishShopName: englishShopName,

          shopBannerFile: shopBannerFile,
          // type: type!,
          // listService: listService,
          // listOperatingHour: listOperatingHour,
        },
        stepProgress
      );
      setStepProgress(progressNow);
      // if (progressNow === 1) {
      // 	setServiceTypes(await getServiceType(type!));
      // }

      switch (accountDetailType) {
        case MasterAccountDetailsType.Register: {
          if (progressNow === 2) {
            navigate(AppRoutePath.masterAccount);
          } else {
            navigate(AppRoutePath.masterAccount);
          }
          break;
        }
        case MasterAccountDetailsType.Edit: {
          if (progressNow === 1) {
            navigate(-1);
          }
          break;
        }
        default:
          break;
      }
    }
    setIsLoading(false);
  };

  const handleCancel = () => {
    switch (stepProgress) {
      case 0:
        accountDetailType === MasterAccountDetailsType.Register
          ? navigate(-1)
          : navigate(-1);
        break;
      case 1:
        setStepProgress(0);
        setType("");
        setListOperatingHour([]);
        setListService([]);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <CustomNavigation
        isOpen={isNavOpen}
        onCallback={(value: boolean) => {
          setIsNavOpen(value);
        }}
        accountType={AccountTypeConst.Master}
      />

      <CustomHeader />
      <TopHearder
        title={
          accountDetailType === MasterAccountDetailsType.Register
            ? t("createShop")
            : t("editShop")
        }
      />
      <div className="bg-dark">
        <div className="container">
          <div className="inner-box">
            <div className="accordion pt-3" id="accordionExample">
              <div className="tab-content">
                {accountDetailType === MasterAccountDetailsType.Register && (
                  <div>
                    {/* <RegistrationSteps step={3} /> */}
                    <div id="headingOne"></div>
                  </div>
                )}
                <div
                  id="collapseOne"
                  className="collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                ></div>
              </div>
              <div className="tab-content">
                <div id="headingTwo"></div>
                <div
                  id="collapseTwo"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                ></div>
              </div>
              <div className="tab-content">
                <div id="headingThree"></div>
                <div
                  id="collapseThree"
                  className="collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="card-body">your content goes here...</div>
                </div>
              </div>
              <div className="tab-content">
                <div id="headingFour"></div>
                <div
                  id="collapseFour"
                  className="collapse show"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  {/* {accountDetailType === MasterAccountDetailsType.Register && (
										<p className='number'>
											{`+${createUserParams.data.country_phone_code} ${createUserParams.data.phone_number}`}
										</p>
									)} */}

                  <div className="row align-items-center mb-3 g-1">
                    <label className="label mb-1">{t("chineseShopName")}</label>
                    <div className="col-11">
                      <input
                        className="form-control input"
                        type="text"
                        value={chineseShopName}
                        onChange={handleChineseShopName}
                        maxLength={15}
                      />
                    </div>
                    <div className="col-1 text-end">
                      <OverlayTrigger
                        trigger="click"
                        placement="left"
                        show={activePopover === "ShopNameInfo"}
                        onToggle={() => handlePopoverToggle("ShopNameInfo")}
                        overlay={
                          <Popover
                            id="popover-positioned-left"
                            title="Popover left"
                          >
                            <button
                              type="button"
                              className="close-popover"
                              onClick={() =>
                                handlePopoverToggle("ShopNameInfo")
                              }
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>

                            <div className="popover-body">
                              {t("ShopNameInfo")}
                            </div>
                          </Popover>
                        }
                      >
                        <button type="button" className="exc-btn">
                          <i className="fa-solid fa-exclamation" />
                        </button>
                      </OverlayTrigger>
                    </div>
                  </div>
                  <div className="row align-items-center mb-3 g-1">
                    <label className="label mb-1">{t("englishShopName")}</label>
                    <div className="col-11">
                      <input
                        className="form-control input"
                        type="text"
                        value={englishShopName}
                        onChange={handleEnglishShopName}
                        maxLength={15}
                      />
                    </div>
                    <div className="col-1 text-end">
                      <OverlayTrigger
                        trigger="click"
                        placement="left"
                        show={activePopover === "ShopNameInfo1"}
                        onToggle={() => handlePopoverToggle("ShopNameInfo1")}
                        overlay={
                          <Popover
                            id="popover-positioned-left"
                            title="Popover left"
                          >
                            <button
                              type="button"
                              className="close-popover"
                              onClick={() =>
                                handlePopoverToggle("ShopNameInfo1")
                              }
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>

                            <div className="popover-body">
                              {t("ShopNameInfo1")}
                            </div>
                          </Popover>
                        }
                      >
                        <button type="button" className="exc-btn">
                          <i className="fa-solid fa-exclamation" />
                        </button>
                      </OverlayTrigger>
                    </div>
                  </div>

                  <label htmlFor="" className="label mb-4">
                    {t("uploadShopBanner")}
                  </label>
                  {shopBannerFile !== null ? (
                    <div className="master-banner mb-3">
                      <img
                        src={URL.createObjectURL(shopBannerFile)}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  ) : shopBannerUrl !== "" ? (
                    <div className="master-banner mb-3">
                      <img src={shopBannerUrl} className="img-fluid" alt="" />
                    </div>
                  ) : null}

                  <div className="d-flex align-items-center justify-content-between u-box mb-5">
                    {/* <p>Image upload must not be larger than 20MB</p> */}
                    <input
                      ref={imgRef}
                      type="file"
                      onChange={handleUploadShopBannerFile}
                      hidden={true}
                      accept="image/*"
                    />
                    {shopBannerFile != null || shopBannerUrl !== "" ? (
                      <></>
                    ) : (
                      <button
                        className="button primary-button mr-5"
                        onClick={showOpenFileDlg}
                      >
                        {t("uploadPhoto")}
                      </button>
                    )}
                    {showDeletePhotoButton && (
                      <button
                        className="button primary-button d-flex gap-2 align-items-center"
                        onClick={handleDeleteExistingShopBanner}
                        disabled={isDeletingImage}
                      >
                        {/* {t("deletePhoto")} */}
                        {isDeletingImage ? (
                          <>
                            {t("deleting")} <Spinner size="sm" />
                          </>
                        ) : (
                          t("deletePhoto")
                        )}
                      </button>
                    )}
                    {shopBannerFile != null && (
                      <button
                        className="button primary-button d-flex gap-2 align-items-center"
                        onClick={handleDeleteShopBannerFile}
                        disabled={isDeletingImageBefore}
                      >
                        {isDeletingImageBefore ? (
                          <>
                            {t("deleting")} <Spinner size="sm" />
                          </>
                        ) : (
                          t("deletePhoto")
                        )}
                      </button>
                    )}
                  </div>

                  {/* {stepProgress > 0 && (
										<div>
											<label htmlFor='' className='label mb-4'>
												{t('type')}
											</label>
											<div className='col-12 mb-4'>
												<select
													value={type}
													onChange={handleChangeType}
													className='d-select input-md w-100'>
													{listTypes.map((val, i) => {
														return (
															<option value={val} key={i}>{val}</option>
														);
													})}
												</select>
											</div>
											<label htmlFor='' className='label mb-4'>
												{t('service')}
											</label>
											<div className='row gx-2 gy-1 mb-5'>
												{serviceTypes.map((service, idx) => {
													return (
														<div key={idx} className='col-4'>
															<div className='check-box input-sm'>
																<input
																	key={idx}
																	type='checkbox'
																	value={service}
																	onChange={handleSelectService}
																/>
																<label htmlFor='' className='label'>
																	{service}
																</label>
															</div>
														</div>
													);
												})}
											</div>
											<label htmlFor='' className='label mb-4'>
												{t('operatingHour')}
											</label>
											{listOperatingHour.map((oh, idx) => {
												return (
													<div>
														<div className={"row gx-2 gy-1 mb-3 oh-date"}>
															<div className='col-2'>
																<input
																	type='reset'
																	name='reset'
																	className='d-input input-md w-100'
																	style={{
																		backgroundColor: oh.rest
																			? "#ff3751"
																			: "#6d6e71",
																	}}
																	value='Rest'
																	readOnly={true}
																	onClick={() => {
																		const listOHTemp = listOperatingHour;
																		listOHTemp[idx].rest =
																			!listOHTemp[idx].rest;
																		setListOperatingHour([...listOHTemp]);
																	}}
																/>
															</div>
															<div className='col-4'>
																<input
																	type='text'
																	className='d-input input-md w-100'
																	style={{
																		backgroundColor: !oh.rest
																			? "#ff3751"
																			: "#6d6e71",
																	}}
																	value={oh.day}
																	readOnly={true}
																	onClick={() => {
																		const listOHTemp = listOperatingHour;
																		listOHTemp[idx].rest =
																			!listOHTemp[idx].rest;
																		setListOperatingHour([...listOHTemp]);
																	}}
																/>
															</div>
															<div className='col-6'>
																<div className='row g-0'>
																	<div className='col-5'>
																		<select
																			className='d-select input-md w-100'
																			value={oh.open_hours}
																			onChange={(e) => {
																				const listOHTemp = listOperatingHour;
																				listOHTemp[idx].open_hours =
																					e.target.value;
																				setListOperatingHour([...listOHTemp]);
																			}}
																		>
																			<option>01:00</option>
																			<option>02:00</option>
																			<option>03:00</option>
																			<option>04:00</option>
																			<option>05:00</option>
																			<option>06:00</option>
																			<option>07:00</option>
																			<option>08:00</option>
																			<option>09:00</option>
																			<option>10:00</option>
																			<option>11:00</option>
																			<option>12:00</option>
																			<option>13:00</option>
																			<option>14:00</option>
																			<option>15:00</option>
																			<option>16:00</option>
																			<option>17:00</option>
																			<option>18:00</option>
																			<option>19:00</option>
																			<option>20:00</option>
																			<option>21:00</option>
																			<option>22:00</option>
																			<option>23:00</option>
																			<option>24:00</option>
																		</select>
																	</div>
																	<div className='col-2 text-center'>
																		<label htmlFor='' className='label'>
																			To
																		</label>
																	</div>
																	<div className='col-5'>
																		<select
																			className='d-select input-md w-100'
																			value={oh.close_hours}
																			onChange={(e) => {
																				const listOHTemp = listOperatingHour;
																				listOHTemp[idx].close_hours =
																					e.target.value;
																				setListOperatingHour([...listOHTemp]);
																			}}
																		>
																			<option>01:00</option>
																			<option>02:00</option>
																			<option>03:00</option>
																			<option>04:00</option>
																			<option>05:00</option>
																			<option>06:00</option>
																			<option>07:00</option>
																			<option>08:00</option>
																			<option>09:00</option>
																			<option>10:00</option>
																			<option>11:00</option>
																			<option>12:00</option>
																			<option>13:00</option>
																			<option>14:00</option>
																			<option>15:00</option>
																			<option>16:00</option>
																			<option>17:00</option>
																			<option>18:00</option>
																			<option>19:00</option>
																			<option>20:00</option>
																			<option>21:00</option>
																			<option>22:00</option>
																			<option>23:00</option>
																			<option>24:00</option>
																		</select>
																	</div>
																</div>
															</div>
														</div>
													</div>
												);
											})}
										</div>
									)} */}

                  <div className="row">
                    <div className="col-6 text-center">
                      <button
                        className="primary-button button mt-3 w-100"
                        data-bs-toggle="modal"
                        data-bs-target="#myModal"
                        onClick={handleCancel}
                      >
                        {t("cancel")}
                      </button>
                    </div>
                    <div className="col-6 text-center">
                      <button
                        className={
                          getIsFormValid()
                            ? "primary-button button mt-3 w-100"
                            : "button light-button3 mt-3 w-100"
                        }
                        disabled={isLoading}
                        onClick={handleNext}
                      >
                        {accountDetailType ===
                        MasterAccountDetailsType.Register ? (
                          isLoading ? (
                            <Spinner size="sm" />
                          ) : (
                            <span>{t("edit")}</span>
                          )
                        ) : isLoading ? (
                          <Spinner size="sm" />
                        ) : (
                          <span>{t("edit")}</span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* The Modal */}
          <div className="modal  m-auto" id="myModal">
            <div className="modal-dialog modal-md">
              <div className="modal-content text-center">
                {/* Modal body */}
                <div className="modal-body">
                  <h5 className="modal-title">
                    Confirmed to cancel new account registration?
                  </h5>
                </div>
                {/* Modal footer */}
                <div className="modal-footer">
                  <a href="login.html">
                    <button
                      type="button"
                      className="btn secondary-button button w-50 m-auto mt-4"
                      data-bs-dismiss="modal"
                    >
                      Yes
                    </button>
                  </a>
                  <button
                    type="button"
                    className="btn primary-button button w-50 m-auto mt-2"
                    data-bs-dismiss="modal"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Body>
          <div className="d-flex justify-content-center align-items-center">
            <Modal.Title>
              <h5 className="modal-title text-center">
                Confirmed to cancel new account registration?
              </h5>
              <Link to="/">
                <Button className="secondary-button button mt-3 w-100">
                  Yes
                </Button>
              </Link>
              <Button
                variant="primary"
                className="primary-button button mt-3 w-100"
                onClick={() => setShowModal(false)}
              >
                No
              </Button>
            </Modal.Title>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}
