import axios from "./axios";
import { AdMedia } from "../domain/model/ad-media";
import Snackbar from "awesome-snackbar";

export const findAdMedia = async (userId?: string, signal?: AbortSignal): Promise<AdMedia[]> => {
    try {
        const req = await axios.get("/client/ad-media", {
            params: {
                target: userId,
            },
            signal,
        });

        return req.data["item(s)"];
    } catch (e) {
        return [];
    }
};

export const findRealImages = async (
    userId: string | undefined, signal?: AbortSignal
): Promise<AdMedia[]> => {
    try {
        const req = await axios.get("/client/real/find", {
            params: {
                target: userId,
            },
            signal,
        });

        return req.data["item(s)"];
    } catch (e) {
        return [];
    }
};

export const findStreetImages = async (
    userId: string | undefined, signal?: AbortSignal
): Promise<AdMedia[]> => {
    try {
        const req = await axios.get("/client/street/find", {
            params: {
                target: userId,
            },
            signal,
        });

        return req.data["item(s)"];
    } catch (e) {
        return [];
    }
};

export const findVoiceMemo = async (
    userId?: string | undefined, signal?: AbortSignal
): Promise<AdMedia | null> => {
    try {
        const req = await axios.get("/client/voice/describe", {
            params: {
                target: userId,
            },
            signal,
        });

        console.log("findVoiceMemo ", req.data?.["item(s)"][0]);

        return req.data["item(s)"][0];
    } catch (e) {
        return null;
    }
};

export const uploadRealImage = async (
    files: string[],
    id?: number
): Promise<boolean> => {
    try {
        await axios.post("/client/real/upload", {
            img_path: files,
            target: id,
        });

        return true;
    } catch (e) {
        return false;
    }
};

export const uploadStreetImage = async (
    file: string,
    id?: number
): Promise<boolean> => {
    try {
        await axios.post("/client/street/upload", {
            img_path: [file],
            target: id,
        });

        return true;
    } catch (e) {
        return false;
    }
};

export const uploadVoiceMemo = async (
    file: string,
    id?: number
): Promise<boolean> => {
    try {
        await axios.post("/client/voice", {
            fullpath: file,
            target: id,
        });

        return true;
    } catch (e) {
        return false;
    }
};

export const uploadAdMedia = async (
    file: string,
    type: "image" | "video",
    thn_path: string | null,
    id?: number
): Promise<boolean> => {
    try {
        const body: any = {};

        switch (type) {
            case "image":
                body["img_path"] = [file];
                break;
            case "video":
                body["video"] = {
                    fullpath: file,
                    thn_path: thn_path,
                };
                break;
        }

        if (id) {
            body["target"] = id;
        }

        await axios.post("/client/ad-media", body);

        return true;
    } catch (e) {
        return false;
    }
};

export const deleteRealImages = async (
    images: AdMedia[],
    target?: any
): Promise<boolean> => {
    try {
        for (const i in images) {
            const image = images[i];

            try {
                await axios.delete("/client/real/delete", {
                    data: {
                        filename: image.name,
                        target: target,
                    },
                });
            } catch (e) {
                continue;
            }
        }

        return true;
    } catch (e) {
        return false;
    }
};

export const deleteStreetPhotos = async (
    images: AdMedia[],
    target?: any
): Promise<boolean> => {
    console.log("fdgfg ", target);
    try {
        for (const i in images) {
            const image = images[i];

            try {
                await axios.delete("/client/street", {
                    data: {
                        filename: image.name,
                        target: target,
                    },
                });
            } catch (e) {
                continue;
            }
        }

        return true;
    } catch (e) {
        return false;
    }
};

export const deleteAdMedia = async (
    images: AdMedia[],
    target?: any
): Promise<boolean> => {
    try {
        for (const i in images) {
            const image = images[i];

            try {
                await axios.delete("/client/ad-media", {
                    data: {
                        filename: image.name,
                        target: target,
                    },
                });
            } catch (e) {
                continue;
            }
        }

        return true;
    } catch (e) {
        return false;
    }
};

export const deleteVoiceMemo = async (file: string, id?: any): Promise<boolean> => {
    try {
        await axios.delete("/client/voice", {
            data: {
                filename: file,
                target: parseInt(id),
            },
        });

        return true;
    } catch (e) {
        return false;
    }
};

export const flagAdMedia = async (
    filename: string,
    userId?: number
): Promise<void> => {
    try {
        await axios.patch("/client/ad-media/flag", {
            filename,
            target: userId,
        });
    } catch (e: any) {
        new Snackbar("This photo has already been set as Main Photo.");
    }
};
export const photoRotate = async (data: any) => {
    console.log("Rotate Data ", data);

    try {
        await axios
            .post("/client/control/rotate", {
                control: data.control === true ? 1 : 0,
                target: parseInt(data.userId),
            })
            .then((res) => {
                if (res) {
                    if (res.data.result_code !== 200) {
                        new Snackbar(`${res.data.message} <br> <br> ${res.data.reason}`);
                    } else {
                        new Snackbar(`Auto Rotate is ${data.control ? "ON " : "OFF"}`);
                    }
                } else {

                    return res;
                }
            });
    } catch (e: any) {
        new Snackbar("Failed to upadte Photo Rotate");
    }
};

export const uploadProfilePhotos = async (fullpath: string, id?: any) => {
    try {
        const response = await axios.post("/client/profile/img/upload", {
            fullpath: fullpath,
            target: parseInt(id),
        });

        if (response.status === 200) {
            new Snackbar("Profile Image uploaded ");
            return response.data;
        } else {
            console.error("API request failed with status:", response.status);
            return null;
        }
    } catch (error) {
        console.error("An error occurred during the API request:", error);
        return null;
    }
};

export const findProfilePhotos = async (target?: any) => {
    try {
        const response = await axios.get("/client/profile/img/describe", {
            params: {
                target: parseInt(target),
            },
        });

        if (response.status === 200) {
            // new Snackbar("Profile Image uploaded ");
            return response.data;
        } else {
            console.error("API request failed with status:", response.status);
            return null;
        }
    } catch (error) {
        console.error("An error occurred during the API request:", error);
        return null;
    }
};

export const deleteProfilePhotos = async (filename: any, id?: any) => {

    console.log("ptt ", filename);


    try {
        const response = await axios.delete("/client/profile/img/delete", {
            data: {
                target: parseInt(id),
                filename: filename
            },
        });

        if (response.status === 200) {
            new Snackbar("Profile Image Deleted ");
            return response.data;
        } else {
            console.error("API request failed with status:", response.status);
            return null;
        }
    } catch (error) {
        console.error("An error occurred during the API request:", error);
        return null;
    }
};
