// Loader.js

import React from "react";

import companyLogo from "../../assets/imgs/whiteLogo.png";

const ScreenLoaders = () => {
  return (
    <div>
      <div className="loader-backdrop"></div>
      <div className="loader-container">
        <img src={companyLogo} alt="Company Logo" className="company-logo" />
        <div className="loader-13 "></div>
      </div>
    </div>
  );
};

export default ScreenLoaders;
