import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Modal, Spinner } from "react-bootstrap";
import {
  findCoinBalance,
  // findOneCoinTransaction,
} from "../../services/products/one-coin";
import {
  purchaseFlower,
  addFlower,
  sendFlower,
  findFlowerRanking,
  findFlowerBalance,
  findFlowerRangkingList,
} from "../../services/products/flower";
import Snackbar from "awesome-snackbar";
import SizedBox from "../reusable/Sizedbox";
import AppRoutePath from "../../constants/AppRoutePath";
import CustomNavigation from "../reusable/Navigation";
import AccountTypeConst from "../../domain/enum/account-type";
import { getIconWallet, WalletType } from "../../constants/WalletType";
import { FlowerRanking } from "../../domain/model/flower-ranking-model";
import CustomEmptyMessage from "../reusable/EmptyMessage";

import { useTranslation } from "react-i18next";

import { sumBalances } from "../../helper/format-helper";
import { getAllProductsDefault } from "../../services/modules";
import TopHearder from "../reusable/TopHearder";
import CustomHeader from "../reusable/Header";
const langType = localStorage.getItem("i18nextLng");
function AddFlowerPage(): JSX.Element {
  const user_id = localStorage.getItem("user_id");
  const isMaster = window.location.pathname.includes("master");
  const wallet = WalletType.Flower;
  const navigate = useNavigate();
  const [isDialogAddFlowerShow1, setIsDialogAddFlowerShow1] = useState(false);
  const [isDialogAddFlowerShow2, setIsDialogAddFlowerShow2] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const [isDialogInsufficientCoin, setIsDialogInsufficientCoin] =
    useState(false);
  const [flowerBalance, setFlowerBalance] = useState(0);
  const [productNames, setProductNames] = useState("");

  const [seletedProductId, setSeletedProductId] = useState(null);
  const [totlaBal, setTotlaBal] = useState(0);
  const [sendFlowerSuccess, setSendFlowerSuccessl] = useState(false);
  const [isSendFlowerLoading, setIsSendFlowerLoading] = useState(false);
  const [isCheckRankingLoading, setIsCheckRankingLoading] = useState(false);
  const [flowerBalance1, setFlowerBalance1] = useState([]);
  const [coinBalance, setCoinBalance] = useState(0);
  const [flowerRanking, setFlowerRanking] = useState({
    rank: "N/A",
    units: "N/A",
    user_id: 0,
  });
  // const [allflowerRanking, setAllFlowerRanking] = React.useState([]);
  const [allflowerRanking, setAllFlowerRanking] = useState<[]>([]);

  const [nextFlowerRanking, setnextFlowerRanking] = useState("N/A");
  const [updatedFlowerRanking, setupdatedFlowerRanking] = useState({
    rank: "N/A",
    units: "N/A",
  });

  const { t } = useTranslation();
  const [quantity1, setQuantity1] = useState(1);
  const [quantity2, setQuantity2] = useState(1);
  const [quantity3, setQuantity3] = useState(1);
  const flowerCoin1 = quantity1 * 10;
  const flowerCoin2 = quantity2 * 10;
  const validCoin = quantity3;
  const [isNavOpen, setIsNavOpen] = useState(true);

  const [flowerRankingList, setFlowerRankingList] = useState<FlowerRanking[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isReloadPopUpSuccessful, setIsReloadPopUpSuccessful] = useState(false);
  // const newFlowerRanking = () => {
  //   // Calculate the rank for the new flower entry based on nextFlowerRanking
  //   const newFlower = { units: nextFlowerRanking };

  //   // Sort the flowerRanking array by units in descending order
  //   const sortedFlowerRanking = [...flowerRanking].sort((a, b) => b.units - a.units);

  //   // Find the appropriate rank for the newFlower based on its units
  //   let newRank = 1;
  //   for (const flower of sortedFlowerRanking) {
  //     if (newFlower.units <= flower.units) {
  //       newRank = flower.rank + 1;
  //     } else {
  //       break;
  //     }
  //   }

  //   // Update the rank and units in the state
  //   setupdatedFlowerRanking({ rank: newRank, units: nextFlowerRanking });
  // };

  // Call the function to calculate the new rank and units for nextFlowerRanking

  const iconFlower = (
    <img
      src="/img/flower_icon.png"
      style={{ marginBottom: 3, height: "75%" }}
      alt=""
    />
  );
  const iconCoin = (
    <img
      src="/img/onecoin_icon.png"
      style={{ marginBottom: 3, height: 25, width: 25 }}
      alt=""
    />
  );
  const handleAddFlower1 = () => {
    if (flowerBalance < quantity1) {
      setIsDialogInsufficientCoin(true);
    } else if (flowerBalance < quantity1 && coinBalance < flowerBalance) {
      navigate(AppRoutePath.loadCoin);
    } else {
      setIsDialogAddFlowerShow1(true);
    }
  };

  const handleAddFlowerClick1 = async () => {
    const units = quantity1;
    const targets = flowerRanking.user_id;
    setIsLoading(true);
    if (coinBalance < quantity1) {
      setShowPopover(true);
    } else {
      addFlower(units, targets);
    }

    const newFlowerBalance = await findFlowerBalance();
    setFlowerBalance(Number(sumBalances(newFlowerBalance)));

    const newCoinBalance = await findCoinBalance();
    setCoinBalance(newCoinBalance);
    setIsDialogAddFlowerShow1(false);
  };

  const handleAddFlower2 = () => {
    if (flowerBalance < quantity2) {
      setIsDialogInsufficientCoin(true);
    } else if (flowerBalance < quantity2 && coinBalance < flowerBalance) {
      navigate(AppRoutePath.loadCoin);
    } else {
      setIsDialogAddFlowerShow2(true);
    }
  };

  const handleAddFlowerClick2 = async () => {
    const units = quantity2;
    const targets = flowerRanking.user_id;
    if (coinBalance < quantity2) {
      setShowPopover(true);
    } else {
      addFlower(units, targets);
    }

    const newFlowerBalance = await findFlowerBalance();

    // console.log("newFlowerBalance ", newFlowerBalance);

    setFlowerBalance(Number(sumBalances(newFlowerBalance)));

    const newCoinBalance = await findCoinBalance();
    setCoinBalance(newCoinBalance);
  };

  const handleInsufficientFlower = () => {
    setIsDialogInsufficientCoin(true);
  };

  const handleClosePopover = () => {
    setShowPopover(false);
  };

  useEffect(() => {
    async function fetchCoinBalance() {
      const result = await findCoinBalance();
      setCoinBalance(result ?? "N/A");
    }
    fetchCoinBalance();

    if (!isMaster) {
      findFlowerRangkingList({ f_more: 1 }).then((res) => {
        if (res) {
          setFlowerRankingList(res);
        }
      });
    }
  }, []);
  async function fetchFlowerBalance() {
    const result = await findFlowerBalance();

    setFlowerBalance1(result);
  }
  useEffect(() => {
    fetchFlowerBalance();
  }, []);

  // console.log("allflowerRanking ", JSON.stringify(allflowerRanking));

  useEffect(() => {
    async function fetchFlowerRanking() {
      const result = await findFlowerRanking();
      setAllFlowerRanking(result["item(s)"]);

      if (
        typeof result === "object" &&
        result.hasOwnProperty("rank") &&
        result.hasOwnProperty("units") &&
        result.hasOwnProperty("user_id")
      ) {
        setFlowerRanking({
          rank: result.rank || "N/A",
          units: result.units || "N/A",
          user_id: Number(result.user_id) || 0,
        });
      } else {
        // console.log("Error: invalid response from server.");
        new Snackbar("An error occurred.");
      }
    }

    if (!isMaster) {
      fetchFlowerRanking();
    }
  }, []);

  function handleMinusClick1() {
    if (quantity1 > 1) {
      setQuantity1(quantity1 - 1);
    }
  }

  function handlePlusClick1() {
    if (quantity1 < 10000) {
      setQuantity1(quantity1 + 1);
    }
  }

  function handleMinusClick2() {
    if (quantity2 > 1) {
      setQuantity2(quantity2 - 1);
    }
  }

  function handlePlusClick2() {
    if (quantity2 < 10000) {
      setQuantity2(quantity2 + 1);
    }
  }

  function handleMinusClick3() {
    if (quantity3 > 1) {
      setQuantity3(quantity3 - 1);
    }
  }

  function handlePlusClick3() {
    if (quantity3 < 10000) {
      setQuantity3(quantity3 + 1);
    }
  }

  const handleSendFlowers = () => {
    if (seletedProductId != null) {
      setIsSendFlowerLoading(true);
      sendFlower({ prod_id: seletedProductId, target: user_id }).then((res) => {
        if (res?.result_code === 200) {
          fetchFlowerBalance();
          setIsDialogInsufficientCoin(false);
          setSendFlowerSuccessl(true);
          setIsSendFlowerLoading(false);
        } else {
          setIsDialogInsufficientCoin(false);
          setIsSendFlowerLoading(false);
        }
      });
    }
  };

  const handleCheckRanking: (
    units: number
  ) => { rank: number; units: number } | null = (units: number) => {
    const result = allflowerRanking.find((item: any) => units >= item.units);

    if (result) {
      // If a matching ranking is found, return the result
      return result;
    } else {
      // If no matching ranking found, determine the rank for the new entry
      const newRank =
        allflowerRanking.filter((item: any) => units < item.units).length + 1;
      return { rank: newRank, units };
    }
  };

  return (
    <div>
      <CustomNavigation
        isOpen={isNavOpen}
        onCallback={(value: boolean) => {
          setIsNavOpen(value);
        }}
        accountType={
          isMaster ? AccountTypeConst.Master : AccountTypeConst.Client
        }
      />
      {/* <header>
        <a
          className="lft-arw"
          onClick={() =>
            navigate(
              isMaster
                ? AppRoutePath.masterAccount
                : AppRoutePath.clientMainPage
            )
          }
        >
          <i className="fa-solid fa-chevron-left"></i>
        </a>
        <div className="title">Add Flower</div>
        <a className="clk-btn" onClick={() => setIsNavOpen(!isNavOpen)}>
          <i className="fa-solid fa-bars"></i>
        </a>
      </header> */}
      <CustomHeader />
      <TopHearder title={t("addFlower1")} />
      <div className="bg-dark">
        <div className="container py-5 ">
          {/* <div className="inner-box pt-4 pb-4"> */}
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <ul className="nav-page">
                <li>
                  <Link
                    to={
                      (isMaster
                        ? AppRoutePath.masterWallet
                        : AppRoutePath.clientWallet) + "/one-coin"
                    }
                  >
                    {getIconWallet({ type: WalletType.OneCoin })} OneCoin
                  </Link>
                </li>
                <li className={wallet === WalletType.Flower ? "active" : ""}>
                  <Link
                    to={
                      (isMaster
                        ? AppRoutePath.masterWallet
                        : AppRoutePath.clientWallet) + "/flower"
                    }
                  >
                    {getIconWallet({ type: WalletType.Flower })} FLOWER
                  </Link>
                </li>
                <li className="">
                  <Link
                    to={
                      (isMaster
                        ? AppRoutePath.masterWallet
                        : AppRoutePath.clientWallet) + "/heart"
                    }
                  >
                    {getIconWallet({ type: WalletType.Heart })} Heart
                  </Link>
                </li>
              </ul>

              <div className="flower-box px-lg-5 py-lg-3">
                <ul>
                  <li>
                    <span> {t("currentFlowerRanking")}</span>{" "}
                    <strong>
                      {flowerRanking.rank === null ? "N/A" : flowerRanking.rank}
                    </strong>
                  </li>
                  <li>
                    <span>{t("ActiveAdsFlower")}</span>{" "}
                    <strong>
                      <img src="/img/flower_icon.png" alt="" />
                      {!flowerRanking.units ? "N/A" : flowerRanking.units}
                    </strong>
                  </li>
                </ul>
              </div>

              {flowerBalance1 &&
                flowerBalance1?.map((item, i) => {
                  const { prod_name, quantity, prod_id } = item as {
                    prod_name: string;
                    quantity: number;
                    prod_id: any;
                  };

                  const [englishName, chineseName] = (
                    prod_name as string
                  ).split("|");

                  return (
                    <>
                      <div className="flower-box2 p-lg-3">
                        <div className="flower-top d-block">
                          <div className="row">
                            <div className="col">
                              <h3>
                                {/* Package Name */}
                                {t("packageNames")}
                                {/* {langType === "zh" ? chineseName : englishName} */}
                                <p className="fw-bold primary-color d-flex align-items-center pt-1">
                                  {langType === "zh"
                                    ? chineseName
                                    : englishName}
                                  <span className="flower-top-img ms-1">
                                    {" "}
                                    {iconFlower}
                                  </span>
                                </p>
                                {/* {paymentName} {walletName.toUpperCase()}{" "} */}
                              </h3>
                            </div>
                            <div className="col text-end">
                              {" "}
                              <div className="">
                                <h3 style={{ width: "100%", fontSize: 13 }}>
                                  <span>{t("currentBAL")}</span>
                                </h3>
                                <h4
                                  className="flower-top-balacnce"
                                  // style={{
                                  //   width: "60%",
                                  // }}
                                >
                                  {/* {props.icon}  */}
                                  {quantity}
                                </h4>
                              </div>
                            </div>
                          </div>

                          {/*  */}
                        </div>
                        {/* <div className='flower-bottom'> */}

                        <div className="row pb-3">
                          {/* <div className="col">
                          
                            <button
                              className="btn primary-button rounded-button"
                              data-bs-toggle="modal"
                              data-bs-target="#myModal"
                              
                              onClick={(e) => handleAddFlower1()}
                            >
                            
                              Check Ranking
                         
                            </button>
                          </div> */}
                          {/* <div className="col">
                          
                           <input type="text" className="form-control" />
                          </div> */}
                          <div className="col">
                            <button
                              className="btn primary-button rounded-button"
                              data-bs-toggle="modal"
                              data-bs-target="#myModal"
                              // onClick={handlePayment}
                              // onClick={(e) => handleAddFlower1()}
                              onClick={(e) => {
                                setProductNames(prod_name);
                                setTotlaBal(quantity);
                                setIsDialogInsufficientCoin(true);
                                setSeletedProductId(prod_id);
                              }}
                            >
                              {/* <i className="fa fa-shopping-cart"></i>{" "}  */}
                              {t("sendFlower")}
                              {/* {props.paymentName} */}
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}

              <h2 className="rank-title">
                <img src="/img/flower_icon.png" alt="" /> FLOWER RANKING
              </h2>

              <div className="row g-1">
                {flowerRankingList.length === 0 ? (
                  <CustomEmptyMessage />
                ) : (
                  flowerRankingList.map((data, i) => (
                    <div className="col-lg-3  col-md-4 col-6 p-1" key={i}>
                      <FlowerRankingItemCard data={data} key={i} />
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>

          {/* </div> */}
        </div>

        <Modal show={isReloadPopUpSuccessful}>
          <Modal.Body>
            <div className="d-flex justify-content-center align-items-center">
              <Modal.Title>
                <h5 className="modal-title text-center">
                  {/* Reload <span className="color-pink-3" style={{fontWeight:"bold" , fontSize:17 }}>{quantity1}</span> {walletName} Successful */}
                </h5>
              </Modal.Title>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="row">
              <div className="col">
                <button
                  className="button primary-button rounded-button w-100"
                  onClick={() => {
                    setIsReloadPopUpSuccessful(false);
                  }}
                >
                  OK
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal show={isDialogAddFlowerShow1}>
          <Modal.Header>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={(e) => setIsDialogAddFlowerShow1(false)}
            ></button>
          </Modal.Header>
          <Modal.Body>
            <div className="reload-flower-wrap">
              <div className="flower-top">
                <SizedBox width={50} />
                <h4>
                  <strong>
                    Bal
                    {/* <SizedBox width={5}/>  */}
                    <i className="fa-solid fa-dollar-sign"></i>
                    {/* <SizedBox width={5}/>  */}
                    {coinBalance ?? "N/A"}
                  </strong>
                </h4>
              </div>
              <h5>
                {getIconWallet({ type: wallet })} {flowerRanking.units ?? "N/A"}
              </h5>

              <div className="qty-box">
                <div>
                  <button
                    type="button"
                    className="minus"
                    onClick={handleMinusClick1}
                  >
                    <i className="fa-solid fa-minus"></i>
                  </button>
                  <input
                    type="text"
                    value={quantity1}
                    className="quantity"
                    min="1"
                    max="1000"
                  />
                  <button
                    type="button"
                    className="plus"
                    onClick={handlePlusClick1}
                  >
                    <i className="fa-solid fa-plus"></i>
                  </button>
                </div>

                <div className="text-price text-white">
                  BAL
                  <input type="text" name="" value={flowerCoin1} />
                </div>
              </div>

              {showPopover && (
                <div className="popover">
                  <div
                    className="popover-arrow"
                    style={{
                      position: "absolute",
                      transform: "translate3d(0px, 78px, 0px)",
                      top: "0px",
                    }}
                  ></div>
                  <div className="popover-body">
                    <div
                      data-name="blog-popover-content"
                      className="blog-popover text-center"
                    >
                      <p>Not enough OneCoin</p>
                      <Link
                        to={
                          (isMaster
                            ? AppRoutePath.masterWallet
                            : AppRoutePath.clientWallet) + "/one-coin"
                        }
                      >
                        <a
                          className="btn primary-button rounded-button w-100"
                          onClick={(e) => {
                            setIsDialogAddFlowerShow1(false);
                            handleClosePopover();
                          }}
                        >
                          Reload OneCoin
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
              )}

              <div className="text-center">
                <button
                  id="blog"
                  className="btn primary-button rounded-button abs-btn coin-btn w-70"
                  onClick={handleAddFlowerClick1}
                >
                  {isLoading ? (
                    <Spinner size="sm" />
                  ) : (
                    <i className="fa fa-shopping-cart" />
                  )}
                  {isLoading ? "" : "Add Flower"}
                  {/* <i className="fa fa-shopping-cart"></i> Add Flower */}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={isDialogAddFlowerShow2}>
          <Modal.Header>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={(e) => setIsDialogAddFlowerShow2(false)}
            ></button>
          </Modal.Header>
          <Modal.Body>
            <div className="reload-flower-wrap">
              <div className="flower-top">
                <SizedBox width={50} />
                <h4>
                  <strong>
                    Bal
                    <SizedBox width={5} />
                    {getIconWallet({ type: wallet })} <SizedBox width={5} />
                    {flowerBalance ?? "N/A"}
                  </strong>
                </h4>
              </div>
              <h5>
                {getIconWallet({ type: wallet })} {flowerRanking.units ?? "N/A"}
              </h5>

              <div className="qty-box">
                <div>
                  <button
                    type="button"
                    className="minus"
                    onClick={handleMinusClick2}
                  >
                    <i className="fa-solid fa-minus"></i>
                  </button>
                  <input
                    type="text"
                    value={quantity2}
                    className="quantity"
                    min="1"
                    max="1000"
                  />
                  <button
                    type="button"
                    className="plus"
                    onClick={handlePlusClick2}
                  >
                    <i className="fa-solid fa-plus"></i>
                  </button>
                </div>

                <div className="text-price">
                  BAL
                  <input type="text" name="" value={flowerCoin2} />
                </div>
              </div>
              {showPopover && (
                <div className="popover">
                  <div
                    className="popover-arrow"
                    style={{
                      position: "absolute",
                      transform: "translate3d(0px, 78px, 0px)",
                      top: "0px",
                    }}
                  ></div>
                  <div className="popover-body">
                    <div
                      data-name="blog-popover-content"
                      className="blog-popover text-center"
                    >
                      <p>Not enough OneCoin</p>
                      <Link
                        to={
                          (isMaster
                            ? AppRoutePath.masterWallet
                            : AppRoutePath.clientWallet) + "/one-coin"
                        }
                      >
                        <a
                          className="btn primary-button rounded-button w-100"
                          onClick={(e) => {
                            setIsDialogAddFlowerShow2(false);
                            handleClosePopover();
                          }}
                        >
                          Reload OneCoin
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
              )}
              <div className="text-center">
                <button
                  id="blog"
                  className="btn primary-button rounded-button abs-btn coin-btn w-70"
                  onClick={handleAddFlowerClick2}
                >
                  <i className="fa fa-shopping-cart"></i> Add Flower
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={isDialogInsufficientCoin}>
          <Modal.Header>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={(e) => setIsDialogInsufficientCoin(false)}
            ></button>
          </Modal.Header>
          <Modal.Body>
            <div className="reload-flower-wrap">
              <h6 className="text-center">
                {langType === "zh"
                  ? productNames?.split("|")[1]
                  : productNames?.split("|")[0]}
              </h6>

              <div className="primary-color">{t("sendFlowerDetails1")}</div>
              <div className="d-flex justify-content-between align-items-center pb-2">
                <p className="mb-0"> {t("currentRanking")}</p>{" "}
                <h5 className="mb-0 flower-top-balacnce">
                  {flowerRanking.rank ?? "N/A"}
                </h5>
              </div>
              <div className="d-flex justify-content-between align-items-center pb-2">
                <p className="mb-0"> {t("nextRanking")}</p>{" "}
                <h5 className="mb-0 flower-top-balacnce">
                  {nextFlowerRanking}
                </h5>
              </div>

              <div className="qty-box">
                <div>
                  <button
                    type="button"
                    className="minus"
                    onClick={handleMinusClick3}
                  >
                    <i className="fa-solid fa-minus"></i>
                  </button>
                  <input
                    type="text"
                    value={quantity3}
                    className="quantity"
                    min="1"
                    max="1000"
                  />
                  <button
                    type="button"
                    className="plus"
                    onClick={handlePlusClick3}
                  >
                    <i className="fa-solid fa-plus"></i>
                  </button>
                </div>
              </div>

              <div className="text-center d-flex gap-2 justify-content-center">
                <button
                  id="blog"
                  className="btn primary-button rounded-button abs-btn coin-btn w-70"
                  disabled={isSendFlowerLoading || isCheckRankingLoading}
                  onClick={async () => {
                    setIsCheckRankingLoading(true);

                    await getAllProductsDefault(productNames).then(
                      (res: any) => {
                        if (res != null) {
                          const calculateTotal = res.unit * quantity3;
                          let newUnits = flowerRanking.units + calculateTotal;

                          const result = handleCheckRanking(Number(newUnits));
                          if (result) {
                            console.log(result.rank);
                            setnextFlowerRanking(String(result?.rank));
                            setIsCheckRankingLoading(false);
                          } else {
                            // console.log("No matching ranking found");
                            setIsCheckRankingLoading(false);
                          }
                          setIsCheckRankingLoading(false);
                        }
                      }
                    );
                  }}
                >
                  {isCheckRankingLoading ? <Spinner size="sm" /> : t("nextRanking")}
                  {/* {isCheckRankingLoading ? "" : t("nextRanking")} */}
                </button>
              </div>

              <div>
                <button
                  id="blog"
                  className="btn primary-button rounded-button abs-btn coin-btn w-100"
                  onClick={handleSendFlowers}
                  disabled={isSendFlowerLoading}
                >
                  {/* {t("sendFlower")} (
                  {langType === "zh"
                    ? productNames?.split("|")[1]
                    : productNames?.split("|")[0]}
                  ) */}

                  {isSendFlowerLoading ? (
                    <Spinner size="sm" />
                  ) : (
                    <span>
                      {t("sendFlower")}{" "}
                      {langType === "zh"
                        ? productNames?.split("|")[1]
                        : productNames?.split("|")[0]}{" "}
                    </span>
                  )}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={sendFlowerSuccess}>
          <Modal.Header>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={(e) => setSendFlowerSuccessl(false)}
            ></button>
          </Modal.Header>
          <Modal.Body>
            <div className="reload-flower-wrap">
              <div className="flower-top">
                <SizedBox width={50} />
              </div>
              <p className="text-center primary-color text-20">
                {langType === "zh"
                  ? productNames?.split("|")[1]
                  : productNames?.split("|")[0]}
              </p>

              <p className="text-center">{t("sendFlowerYourselfInfo")}</p>

              <div className="text-center">
                <button
                  id="blog"
                  className="btn primary-button rounded-button abs-btn coin-btn w-70"
                  onClick={() => setSendFlowerSuccessl(false)}
                >
                  {t("close")}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

interface IFlowerRankingItemCardProps {
  data: FlowerRanking;
}

function FlowerRankingItemCard({ data }: IFlowerRankingItemCardProps) {
  return (
    <>
      <Link
        to={`https://141-member.new-app.site/ad-blog/${data.user_id}`}
        target="_blank"
        style={{ width: "50%" }}
      >
        <div className="rank-box h-100 ">
          <span className="ranking">
            <i className="fa-solid fa-crown"></i>
            <a href="#">{data.rank}</a>
          </span>
          <div className="d-flex justify-content-center">
            <img
              src={data.photo ? data.photo : "/img/blank-profile-picture.png"}
              alt=""
              className="clt-img"
            />
          </div>
          <div className="rank-top">
            {/* <ul>
          <li>
            <a href="#">
              <i className="fa-brands fa-instagram"></i>
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fa-regular fa-circle-play"></i>
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fa-solid fa-qrcode"></i>
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fa-solid fa-percent"></i>
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fa-regular fa-thumbs-up"></i>
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fa-solid fa-crown"></i>
            </a>
          </li>
        </ul> */}
            <strong style={{ fontSize: 10 }}>
              {langType === "zh"
                ? data.details?.basic_desc?.region1?.split("|")[1]
                : data.details?.basic_desc?.region1?.split("|")[0]}
            </strong>
            <strong>
              <img src="/img/flower_icon1.png" alt="" /> {data.units}
            </strong>
          </div>
          <hr />
          <div className="rank-btm">
            <span>
              {data.en_name ? data.en_name : "N/A"}
              <br />
              {/* {data?.details?.basic_desc?.type} */}
            </span>
            <strong>$ {data.details.basic_desc?.price}</strong>
          </div>
        </div>
      </Link>
    </>
  );
}

export default AddFlowerPage;
