import React from "react";

const Avatar = (props: AvatarProps): JSX.Element => {
  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  const avatarStyle = {
    width: props.size ?? 20,
    height: props.size ?? 20,
  };

  return (
    <div className="avatar" onClick={handleClick} style={avatarStyle}>
      {(
        <img
          src={
            props.src ?? "https://www.kindpng.com/picc/m/22-223863_no-avatar-png-circle-transparent-png.png"
          }
          alt={props.alt}
        />
        // <div className="initials">{initials}</div>
      )}
    </div>
  );
};

type AvatarProps = {
  src?: string,
  alt?: string,
  size?: number,
  onClick?: () => void,
  initials?: string
}

export default Avatar;
