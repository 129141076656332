import React, { useState } from "react";

import CustomHeader from "../reusable/Header";
import TopHearder from "../reusable/TopHearder";
import { useTranslation } from "react-i18next";
import noPhotos from "../../assets/imgs/nophoto.jpeg";

import {
  ReceiverDetailsModel,
  oneCoinRequestModel,
  requestOneCoinParamsSearch,
} from "../../domain/model/user-getwallet";
import {
  answerOneCoinRequest,
  findCoinBalance,
  getAllOneCoinRequestList,
  sendOneCoinRequest,
  sendOneCoinRequestMobleOREmail,
  transferOneCoinRequest,
} from "../../services/products/one-coin";
import { Modal, Pagination, Spinner } from "react-bootstrap";
import SizedBox from "../reusable/Sizedbox";
import CustomEmptyMessage from "../reusable/EmptyMessage";
import Loaders from "../reusable/Loaders";

const SearchAndSendOneCoins = () => {
  const { t } = useTranslation();
  const langType = localStorage.getItem("i18nextLng");
  const userId = localStorage.getItem("user_id");
  const isEmailLogin = localStorage.getItem("email_login") == "true";
  const [requestData, setRequestData] = React.useState<oneCoinRequestModel[]>(
    []
  );
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(10);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSearchLoading, setIsSearchLoading] = React.useState(false);
  const [isAnswerLoading, setIsAnswerLoading] = useState(false);
  const [isRejectLoading, setIsRejectLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSuccessModel, setIsSuccessModel] = useState(false);
  const [isTransferSuccessModel, setIsTransferSuccessModel] = useState(false);
  const [isTransferErrorModel, setIsTransferErrorModel] = useState(false);
  const [isTransferErrorMsg, setIsTransferErrorMsg] = useState("");
  const [isErrorModel, setIsErrorModel] = useState(false);
  const [isAlreadyAccept, setIsAlreadyAccept] = useState(false);
  const [isAlreadyAcceptMsg, setIsAlreadyAcceptMsg] = useState("");
  const [isErrorMsg, setIsErrorMsg] = useState("");
  const [notNnoughBal, setNotNnoughBal] = useState("");
  const [inputErrorMsg, setInputErrorMsg] = useState("");
  const [selectedReceiverId, setSelectedReceiverId] = useState(0);
  const [totalOneCoin, setTotalOneCoin] = useState(0);
  const [oneCoinAmount, setOneCoinAmount] = useState(0);
  const [senduserID, setSenduserID] = useState("");
  const [email, setEmail] = useState("");
  const [isMobileValid, setIsMobileValid] = useState(false);
  const [phone, setPhone] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isAllInputValid, setIsAllInputValid] = useState(false);
  const [requestBy, setRequestBy] = useState("requestByMobile");
  const [inputType, setInputType] = useState("text");
  const [selectedReceiverDetails, setSelectedReceiverDetails] =
    useState<ReceiverDetailsModel>({});
  const [isTransferOneCoinLoading, setIsTransferOneCoinLoading] =
    useState(false);
  const [isTransferModel, setIsTransferModel] = useState(false);

  const handleRequestBy: React.ChangeEventHandler<HTMLSelectElement> = (
    event
  ) => {
    const value = event.target.value;
    setRequestBy(value);
    setSearchTerm("");

    if (value === "requestByMobile") {
      setInputType("text");
    }
    if (value === "requestByEmail") {
      setInputType("email");
    }
    if (value === "requestByuserId") {
      setInputType("text");
    }
  };

  const getData = () => {
    setIsLoading(true);

    let params: { page: number; per_page: number; sender?: string } = {
      page: currentPage,
      per_page: perPage,
    };

    getAllOneCoinRequestList(params).then((res) => {
      if (res) {
        setRequestData(res["item(s)"]);
        const totalPages = res.total_pages || 1;
        setTotalPages(totalPages);

        setIsLoading(false);
      }
      setIsLoading(false);
    });
  };
  React.useEffect(getData, [currentPage, totalPages]);

  const getTotalOneCoinBalance = async () => {
    const totalOneCoin = await findCoinBalance();
    setTotalOneCoin(totalOneCoin);
  };
  React.useEffect(() => {
    getTotalOneCoinBalance();
  }, []);
  React.useEffect(() => {
    if (searchTerm.length === 0) {
      setInputErrorMsg(t(""));
    }
  }, [searchTerm]);

  const isValidMobile = (input: string): boolean => {
    const mobileRegex = /^[0-9]{8,}$/;
    return mobileRegex.test(input);
  };

  const isValidEmail = (input: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(input);
  };
  const isValidUserId = (input: string): boolean => {
    const userIdRegex = /^[0-9]{5,}$/;

    return userIdRegex.test(input);
  };

  const handleRequestOneCoin = () => {
    let requestParams: requestOneCoinParamsSearch = {};
    let isValidInput: boolean = false; // Rename the variable to indicate validation status

    // Validate input based on the selected requestBy type
    if (requestBy === "requestByMobile") {
      // Check if searchTerm is a valid mobile number
      if (isValidMobile(searchTerm)) {
        isValidInput = true;
        requestParams.mobile = searchTerm;
      }
    }
    if (requestBy === "requestByEmail") {
      // Check if searchTerm is a valid email address
      if (isValidEmail(searchTerm)) {
        isValidInput = true;
        requestParams.email = searchTerm;
      }
    }
    if (requestBy === "requestByuserId") {
      // Check if searchTerm is a valid user ID (numeric)
      if (isValidUserId(searchTerm)) {
        isValidInput = true;
        requestParams.user_id = Number(searchTerm);
      }
    }
    if (isValidInput) {
      setIsSearchLoading(true);
      setInputErrorMsg(t(""));

      // sendOneCoinRequest({ user_id: Number(searchTerm) }).then((res: any) => {
      sendOneCoinRequestMobleOREmail(requestParams).then((res: any) => {
        if (res) {
          if (res.result_code === 200) {
            setSenduserID(searchTerm);
            setIsSuccessModel(true);
            getData();
          }
          if (res.result_code === 955) {
            setIsAlreadyAccept(true);
            setIsAlreadyAcceptMsg(res.reason);
          }
          if (res.result_code === 930) {
            setIsAlreadyAccept(true);
            setIsAlreadyAcceptMsg(res.message);
          }
          if (res.result_code !== 200) {
            setIsErrorModel(true);
            setIsErrorMsg(res.reason);
          }
          setIsSearchLoading(false);
          setSearchTerm("");
        }
      });
    } else {
      if (requestBy === "requestByMobile") {
        setInputErrorMsg(t("phoneValidEmpty"));
      }
      if (requestBy === "requestByEmail") {
        setInputErrorMsg(t("emptyEmail"));
      }
      if (requestBy === "requestByuserId") {
        setInputErrorMsg(t("validUserId"));
      }
      // setInputErrorMsg(t("validUserId"));
      setIsSearchLoading(false);
    }
  };

  const handleAcceptAndRejectRequest = (requestId: Number, status: boolean) => {
    if (status) {
      setIsAnswerLoading(true);
    } else {
      setIsRejectLoading(true);
    }

    console.table({ status: status, request_id: requestId });

    answerOneCoinRequest({ status: status, request_id: requestId }).then(
      (res: any) => {
        if (res) {
          // console.log("answered", res);
          getData();
          // if (res.result_code === 200) {
          //   setIsSuccessModel(true);
          // }
          // if (res.result_code !== 200) {
          //   setIsErrorModel(true);
          //   setIsErrorMsg(res.reason);
          // }
          // setIsLoading(false);
        }
        // setIsLoading(false);
      }
    );
  };

  // Tranfer One Coin
  const handleTransferOneCoinRequest = () => {
    setIsTransferOneCoinLoading(true);

    console.table({ receiver: selectedReceiverId, coins: oneCoinAmount });

    transferOneCoinRequest({
      receiver: selectedReceiverId,
      coins: oneCoinAmount,
    }).then((res: any) => {
      if (res) {
        // console.log("Transfer One Coin ", res);

        if (res.result_code === 200) {
          getData();
          getTotalOneCoinBalance();
          setIsTransferSuccessModel(true);
          setIsTransferModel(false);
        }
        if (res.result_code !== 200) {
          setIsTransferErrorModel(true);
          setIsTransferErrorMsg(res.message);
        }
        setIsTransferOneCoinLoading(false);
        // setIsTransferModel(false);
      }
      // setIsLoading(false);
    });
  };

  const handleOneCoinAmount: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setOneCoinAmount(Number(event.target.value));

    if (Number(event.target.value) > totalOneCoin) {
      setNotNnoughBal(t("sendTransferOnecoinBalInfo"));
    } else {
      setNotNnoughBal("");
    }
  };

  const goToPage = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const goToNextPage = () => {
    goToPage(currentPage + 1);
  };

  const goToPrevPage = () => {
    goToPage(currentPage - 1);
  };

  return (
    <>
      <CustomHeader />
      <TopHearder title={t("requestOneCoinList")} />
      <div className="bg-dark">
        <div className="container py-5">
          <div className="row mb-4 align-items-center  justify-content-center">
            <h3 className="text-center pb-5"> {t("requestOneCoinList")}</h3>
            <div className="col-lg-8 d-flex justify-content-between align-items-center">
              <label className="label me-2">{t("requestBy")}</label>
              <select
                className="form-select w-auto px-5"
                onChange={handleRequestBy}
                // onKeyDown={handleKeyDown}
                // disabled={isMobileNumberValid || isEmailAddressValid}
              >
                <option value="requestByMobile">{t("mobileNumber")}</option>
                <option value="requestByEmail">{t("emailAddress")}</option>
                <option value="requestByuserId">{t("userId")}</option>
              </select>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <>
                <div>
                  <div className="input-group ">
                    <input
                      className="form-control py-3"
                      placeholder={
                        requestBy === "requestByuserId"
                          ? t("loginIds1")
                          : requestBy === "requestByEmail"
                          ? t("emptyEmail")
                          : t("phoneValidEmpty")
                      }
                      type="text"
                      value={searchTerm}
                      required={true}
                      onChange={(e) => {
                        setSearchTerm(e.target.value);
                        if (searchTerm.length === 0) {
                          setInputErrorMsg(t(""));
                        }
                      }}
                    />
                    <button
                      className="btn btn-primary text-white"
                      type="button"
                      disabled={isSearchLoading}
                      onClick={() => handleRequestOneCoin()}
                    >
                      {isSearchLoading ? (
                        <Spinner size="sm" />
                      ) : (
                        t("requestOneCoin")
                      )}
                    </button>
                  </div>
                  <div className="mb-3">
                    <small className="primary-color">{inputErrorMsg}</small>
                  </div>
                </div>
              </>

              {isLoading ? (
                <div className="text-center pb-3">
                  <Loaders />
                </div>
              ) : requestData.length === 0 ? (
                <CustomEmptyMessage />
              ) : (
                requestData.map((user, id) => {
                  const statusString =
                    user.status === null
                      ? "Pending"
                      : user.status === true
                      ? t("btnAccepted")
                      : t("btnReject");

                  const myAccount = user.sender === Number(userId);

                  // console.log("myAccount ", myAccount);

                  return (
                    <>
                      <div className="card bg-black-3 border-0 rounded-0  mb-3 py-2">
                        <div className="row g-0 align-items-center">
                          <div className=" col-3  col-md-2 col-lg-2 ps-2 ps-lg-3 ">
                            <img
                              src={
                                myAccount
                                  ? user.receiver_photo || noPhotos
                                  : user.sender_photo || noPhotos
                              }
                              className="invitation-profile-img rounded-pill border"
                              alt="..."
                            />
                          </div>
                          <div className="col-9 col-md-10 col-lg-10">
                            <div className="card-body ">
                              <div className="row align-items-center invitation-body-row">
                                <div className="col-7 col-md-6  col-lg-6">
                                  <h5 className="card-title t-color-red mb-0">
                                    {myAccount
                                      ? langType === "zh"
                                        ? user.receiver_zh_name
                                        : user.receiver_en_name
                                      : langType === "zh"
                                      ? user.sender_zh_name
                                      : user.sender_en_name}
                                  </h5>

                                  <p className="card-text last_invited mb-0">
                                    <small className=" text-white text-12">
                                      {myAccount ? user.receiver : user.sender}
                                    </small>
                                  </p>

                                  <p className="card-text last_invited">
                                    <small className=" text-white text-12">
                                      {myAccount
                                        ? t("typeRequested")
                                        : t("typeRequestReceived")}
                                    </small>
                                  </p>
                                </div>
                                <div className="col-5 col-md-6   col-lg-6  text-end text-lg-end pt-2 pt-md-0 pt-lg-0 ">
                                  {myAccount ? (
                                    <>
                                      <button
                                        className={`btn py-1 ${
                                          user.status === null
                                            ? "btn-warning"
                                            : user.status === true
                                            ? "btn-success"
                                            : "btn-primary"
                                        } rounded-1 fw-bold`}

                                        // onClick={onClickFollow}
                                      >
                                        {/* {t("follow")} */}
                                        {statusString}
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      {user.status === true ? (
                                        <>
                                          <div className="text-end d-md-flex gap-md-2 justify-content-end align-items-center">
                                            {!myAccount && (
                                              <button
                                                className={`btn py-1 border-0 ${"btn-primary"} rounded-1 mb-2 mb-md-0 fw-bold`}
                                                onClick={() => {
                                                  setSelectedReceiverId(
                                                    Number(user.sender)
                                                  );
                                                  setIsTransferModel(true);
                                                  setSelectedReceiverDetails({
                                                    sender: user.sender,
                                                    sender_zh_name:
                                                      user.sender_zh_name,
                                                    sender_en_name:
                                                      user.sender_en_name,

                                                    sender_photo:
                                                      user.sender_photo,
                                                  });
                                                }}
                                              >
                                                {t("transfer")}
                                              </button>
                                            )}

                                            <span
                                              className={`btn py-1  ${"btn-success"} rounded-1 fw-bold`}
                                            >
                                              {t("btnAccepted")}
                                            </span>
                                          </div>
                                        </>
                                      ) : user.status === false ? (
                                        <>
                                          <span
                                            className={`btn py-1 ${"btn-primary"} rounded-1 fw-bold`}
                                          >
                                            {t("btnRejected")}
                                          </span>
                                        </>
                                      ) : (
                                        <>
                                          <div className="text-end d-md-flex gap-md-2 justify-content-end align-items-center">
                                            <button
                                              className={`btn py-1 ${"btn-danger"} rounded-1 fw-bold mb-2 mb-md-0`}
                                              onClick={() => {
                                                handleAcceptAndRejectRequest(
                                                  Number(user.request_id),
                                                  false
                                                );
                                              }}
                                              disabled={isRejectLoading}
                                            >
                                              {/* {t("follow")} */}

                                              {isRejectLoading ? (
                                                <Spinner size="sm" />
                                              ) : (
                                                t("btnReject")
                                              )}
                                            </button>
                                            <button
                                              className={`btn py-1 ${"btn-success"} rounded-1 fw-bold`}
                                              onClick={() => {
                                                handleAcceptAndRejectRequest(
                                                  Number(user.request_id),
                                                  true
                                                );
                                              }}
                                              disabled={isAnswerLoading}
                                            >
                                              {isAnswerLoading ? (
                                                <Spinner size="sm" />
                                              ) : (
                                                t("btnAccept")
                                              )}
                                              {/* {t("btnAccept")} */}
                                            </button>
                                          </div>
                                        </>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr className="border-red opacity-100 mx-3 my-2 my-md-3" />
                    </>
                  );
                })
              )}
            </div>

            {requestData?.length > 0 && (
              <div className="d-flex justify-content-center my-3">
                <Pagination>
                  <Pagination.Prev
                    onClick={goToPrevPage}
                    disabled={currentPage === 1}
                  />

                  {/* Show the first page */}
                  <Pagination.Item onClick={() => goToPage(1)}>
                    First
                  </Pagination.Item>

                  {/* Show ellipsis if there are more than 3 pages before the current page */}
                  {currentPage > 3 && <Pagination.Ellipsis disabled />}

                  {/* Show two pages before the current page */}
                  {currentPage > 2 && (
                    <Pagination.Item onClick={() => goToPage(currentPage - 1)}>
                      {currentPage - 1}
                    </Pagination.Item>
                  )}

                  {/* Show the current page */}
                  <Pagination.Item active>{currentPage}</Pagination.Item>

                  {/* Show two pages after the current page */}
                  {currentPage < totalPages - 1 && (
                    <Pagination.Item onClick={() => goToPage(currentPage + 1)}>
                      {currentPage + 1}
                    </Pagination.Item>
                  )}

                  {/* Show ellipsis if there are more than 3 pages after the current page */}
                  {currentPage < totalPages - 2 && (
                    <Pagination.Ellipsis disabled />
                  )}

                  {/* Show the last page */}
                  <Pagination.Item onClick={() => goToPage(totalPages)}>
                    Last
                  </Pagination.Item>

                  <Pagination.Next
                    onClick={goToNextPage}
                    disabled={currentPage === totalPages}
                  />
                </Pagination>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal show={isSuccessModel}>
        <Modal.Body>
          <Modal.Title>
            <center>
              <h5 className="modal-title">
                {t("requestOneCoinSuccess")} ({senduserID}){" "}
              </h5>
              <SizedBox height={25} />
            </center>
          </Modal.Title>
        </Modal.Body>

        <Modal.Footer>
          <div className=" justify-content-center align-items-center">
            <button
              type="button"
              className="primary-button button mt-3 w-100"
              data-bs-dismiss="modal"
              // onClick={deleteItem}
              onClick={() => setIsSuccessModel(false)}
            >
              {t("ok")}
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Transfer Modal */}

      <Modal show={isTransferModel}>
        <Modal.Body className="p-0">
          <div className="text-end mb-3">
            <SizedBox width={100} />
            <small className="text-white text-12">
              <strong>
                {t("Bal")}
                <i className="fa-solid fa-dollar-sign"></i>
                {totalOneCoin ?? "N/A"}
              </strong>
            </small>
          </div>
          <Modal.Title>
            <center>
              <h5 className="modal-title primary-color text-20">
                {t("transferOneCoin")}
              </h5>
              <SizedBox height={25} />
            </center>
          </Modal.Title>

          <div className="d-flex align-items-center mb-3">
            <img
              src={selectedReceiverDetails.sender_photo || noPhotos}
              className="invitation-profile-img rounded-pill border"
              alt="..."
            />
            <div className="ps-3">
              <p className="mb-0">
                {langType === "zh"
                  ? selectedReceiverDetails.sender_zh_name
                  : selectedReceiverDetails.sender_en_name}
              </p>
              <p>{selectedReceiverDetails.sender}</p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="col-12 d-flex justify-content-center align-content-center gap-2">
              <label htmlFor="" className="text-white pt-1 ">
                {t("amount")}:
              </label>
              <input
                type="text"
                className="form-control"
                maxLength={8}
                onChange={handleOneCoinAmount}
                onInput={(e: any) => {
                  e.preventDefault();
                  const input = e.target.value;
                  const regex = /^[0-9]*$/;
                  if (!regex.test(input)) {
                    e.target.value = input.slice(0, -1);
                  }
                }}
              />
            </div>
            <div>
              <small className="primary-color">
                {notNnoughBal ? (
                  <>
                    {notNnoughBal}{" "}
                    <span className="text-white"> {totalOneCoin}</span>{" "}
                  </>
                ) : (
                  ""
                )}
              </small>
            </div>
            <div className="col-12 d-flex gap-2 mt-3">
              <button
                className="button primary-button rounded-button w-100"
                onClick={() => {
                  setIsTransferModel(false);
                  setSelectedReceiverId(0);
                }}
                disabled={isTransferOneCoinLoading}
              >
                {t("cancel")}
              </button>
              <button
                className="button primary-button rounded-button w-100"
                disabled={
                  oneCoinAmount < 1 ||
                  selectedReceiverId === 0 ||
                  isTransferOneCoinLoading ||
                  totalOneCoin < oneCoinAmount
                }
                onClick={() => {
                  handleTransferOneCoinRequest();
                }}
              >
                {isTransferOneCoinLoading ? (
                  <Spinner size="sm" />
                ) : (
                  <span>{t("transfer")} </span>
                )}
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal show={isTransferSuccessModel}>
        <Modal.Header>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={(e) => setIsTransferSuccessModel(false)}
          ></button>
        </Modal.Header>
        <Modal.Body>
          <div className="reload-flower-wrap">
            <div className="flower-top">
              <SizedBox width={50} />
            </div>

            <p className="text-center">
              {" "}
              {t("sendTransferOnecoinInfo")} <br />
              <span className="primary-color fw-bold">
                {" "}
                {langType === "zh"
                  ? selectedReceiverDetails.sender_zh_name
                  : selectedReceiverDetails.sender_en_name}
              </span>
            </p>
            <div className="text-center">
              <button
                id="blog"
                className="btn primary-button rounded-button abs-btn coin-btn w-70"
                onClick={() => setIsTransferSuccessModel(false)}
              >
                {t("close")}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Transfer Errror  */}
      <Modal show={isTransferErrorModel}>
        <Modal.Body>
          <Modal.Title>
            <center>
              <h5 className="modal-title">{isTransferErrorMsg}</h5>
              <SizedBox height={25} />
            </center>
          </Modal.Title>
        </Modal.Body>

        <Modal.Footer>
          <div className=" justify-content-center align-items-center">
            <button
              type="button"
              className="primary-button button mt-3 w-100"
              data-bs-dismiss="modal"
              // onClick={deleteItem}
              onClick={() => setIsTransferErrorModel(false)}
            >
              {t("ok")}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* Transfer Errror  */}
      <Modal show={isAlreadyAccept}>
        <Modal.Body>
          <Modal.Title>
            <center>
              <h5 className="modal-title">{isAlreadyAcceptMsg}</h5>
              <SizedBox height={25} />
            </center>
          </Modal.Title>
        </Modal.Body>

        <Modal.Footer>
          <div className=" justify-content-center align-items-center">
            <button
              type="button"
              className="primary-button button mt-3 w-100"
              data-bs-dismiss="modal"
              // onClick={deleteItem}
              onClick={() => setIsAlreadyAccept(false)}
            >
              {t("ok")}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SearchAndSendOneCoins;
