import React, { useEffect, useState } from "react";
import CustomHeader from "../reusable/Header";
import { getSystemMessageDetail } from "../../services/system-notification";
import { SystemMessageModel } from "../../domain/model/system-message";
import { useNavigate, useParams } from "react-router-dom";
import CustomEmptyMessage from "../reusable/EmptyMessage";
import TopHearder from "../reusable/TopHearder";
import { useTranslation } from "react-i18next";
import Loaders from "../reusable/Loaders";

const SystemMessages = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [systemMsgDesc, setSystemMsgDesc] = useState<SystemMessageModel>();
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    getData();
  }, [id]);

  const getData = () => {
    setIsLoading(true);
    getSystemMessageDetail({ notif_id: Number(id) }).then((res) => {
      if (res) {
        setSystemMsgDesc(res);
        setIsLoading(false);
      }
      setIsLoading(false);
    });
  };

  return (
    <>
      <CustomHeader />
      <TopHearder title={t("systemMessage")} />
      <div className="bg-dark">
        <div className="container">
          <div className="inner-box">
            <ul className="pt-3">
              {isLoading ? (
                <div className="text-center pb-3">
                  <Loaders />
                </div>
              ) : !systemMsgDesc ? (
                <CustomEmptyMessage />
              ) : (
                <li className="msg-box pt-2">
                  <div className="msg-datetime-box">
                    <h6 className="msg-datetime">
                      {systemMsgDesc?.created.split(" ")[0]}{" "}
                      <span className="ms-2">
                        {systemMsgDesc?.created.split(" ")[1]}
                      </span>
                    </h6>
                  </div>
                  <p className="msg">{systemMsgDesc?.content}</p>
                </li>
              )}
            </ul>
            {/* <div className="text-center">
              <button
                type="button"
                className="secondary-button button mt-3 btn btn-primary"
                onClick={()=>  navigate(-1)}
              >
               {t("back")}
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SystemMessages;
