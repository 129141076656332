import * as React from "react";
import { useNavigate } from "react-router-dom";

export interface ICustomHeaderProps {
  title: string;
  backRoute: any;
  icons?: string;
  className?: string;
  isLang?: string;
  isBackRoute?: boolean;
}

export default function TopHearder({
  title,
  icons,
  backRoute,
  isBackRoute,
  className,
  isLang,
}: Partial<ICustomHeaderProps>) {
  const navigate = useNavigate();

  return (
    <>
      <div className={` top-headers  py-2 ${className ? className : ""}`}>
        <div className="row px-2 align-items-center ">
          <div className="col-lg-auto col-1 text-end">
            {/* <Link to={backLink} className="ps-2 text-white">
              <FaChevronLeft
                style={{ fontSize: 20 }}
                onClick={() => navigate(-1)}
              />
            </Link> */}
            {/* {backRoute && ( */}
            <div
              // className="lft-arw"
              className=" text-white link p-0"
              onClick={() => {
                navigate(-1);
              }}
            >
              {!isBackRoute && <i className="fa-solid fa-chevron-left"></i>}
            </div>
            {/* )} */}
          </div>
          <div className="col-11 ">
            <div className="d-flex align-items-center justify-content-center ">
              {icons && (
                <>
                  <img src={icons} style={{ width: 25, height: 25 }} />
                </>
              )}
              <p className="mb-0 top-title">{title ? title : "Page Title"}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
