// stickerData.js
import React from "react";
import { useTranslation } from "react-i18next";

// import look1 from '../../../public/img/emoji/look1.gif";
// import look1 from "../../assets/imgs/emoji/look1.gif";
// import look2 from "../../assets/imgs/emoji/look2.gif";
// import look3 from "../../assets/imgs/emoji/look3.gif";
// import look4 from "../../assets/imgs/emoji/look4.gif";
// import body1 from "../../assets/imgs/emoji/body1.gif";
// import body2 from "../../assets/imgs/emoji/body2.gif";
// import body3 from "../../assets/imgs/emoji/body1.gif";
// import body4 from "../../assets/imgs/emoji/body2.gif";
// import services1 from "../../assets/imgs/emoji/services1.gif";
// import services2 from "../../assets/imgs/emoji/services2.gif";
// import services3 from "../../assets/imgs/emoji/services3.gif";
// import services4 from "../../assets/imgs/emoji/services4.gif";
// import tech1 from "../../assets/imgs/emoji/tech1.gif";
// import tech2 from "../../assets/imgs/emoji/tech2.gif";
// import tech3 from "../../assets/imgs/emoji/tech3.gif";
// import tech4 from "../../assets/imgs/emoji/tech4.gif";

// import lookExcellent from "../../assets/imgs/emoji/looking/excellent.gif";
// import lookGood from "../../assets/imgs/emoji/looking/good.gif";
// import lookAverage from "../../assets/imgs/emoji/looking/average.gif";
// import lookOk from "../../assets/imgs/emoji/looking/ok.gif";
// import lookNotGood from "../../assets/imgs/emoji/looking/notgood.gif";
// import bodyFigureExcellent from "../../assets/imgs/emoji/body_figure/excellent.gif";
// import bodyFigureGood from "../../assets/imgs/emoji/body_figure/good.gif";
// import bodyFigureAverage from "../../assets/imgs/emoji/body_figure/average.gif";
// import bodyFigureOk from "../../assets/imgs/emoji/body_figure/ok.gif";
// import bodyFigureNotGood from "../../assets/imgs/emoji/body_figure/notgood.gif";
// import serviceExcellent from "../../assets/imgs/emoji/service/excellent.gif";
// import serviceGood from "../../assets/imgs/emoji/service/good.gif";
// import serviceAverage from "../../assets/imgs/emoji/service/average.gif";
// import serviceOk from "../../assets/imgs/emoji/service/ok.gif";
// import serviceNotGood from "../../assets/imgs/emoji/service/notgood.gif";
// import technicExcellent from "../../assets/imgs/emoji/technic/excellent.gif";
// import technicGood from "../../assets/imgs/emoji/technic/good.gif";
// import technicAverage from "../../assets/imgs/emoji/technic/average.gif";
// import technicOk from "../../assets/imgs/emoji/technic/ok.gif";
// import technicNotGood from "../../assets/imgs/emoji/technic/notgood.gif";

// New Emogi

import look1 from "../../assets/imgs/emoji_new/H2.gif";
import look2 from "../../assets/imgs/emoji_new/H3.gif";
import look3 from "../../assets/imgs/emoji_new/H1.gif";
import look4 from "../../assets/imgs/emoji_new/H7.gif";
import look5 from "../../assets/imgs/emoji_new/H6.gif";

import body1 from "../../assets/imgs/emoji_new/B5.gif";
import body2 from "../../assets/imgs/emoji_new/B2.gif";
import body3 from "../../assets/imgs/emoji_new/B4.gif";
import body4 from "../../assets/imgs/emoji_new/B7.gif";
import body5 from "../../assets/imgs/emoji_new/B6.gif";

import services1 from "../../assets/imgs/emoji_new/G5.gif";
import services2 from "../../assets/imgs/emoji_new/G2.gif";
import services3 from "../../assets/imgs/emoji_new/G6.gif";
import services4 from "../../assets/imgs/emoji_new/G7.gif";
import services5 from "../../assets/imgs/emoji_new/G3.gif";

import tech1 from "../../assets/imgs/emoji_new/i5.gif";
import tech2 from "../../assets/imgs/emoji_new/i2.gif";
import tech3 from "../../assets/imgs/emoji_new/i3.gif";
import tech4 from "../../assets/imgs/emoji_new/i1.gif";
import tech5 from "../../assets/imgs/emoji_new/i7.gif";
const StickerData = () => {
  const { t } = useTranslation();

  const allStickerList = [
    {
      // name: t("looking"),
      name: t("looking"),
      icon: look1,
      enName: "looking",
      emojis: [
        {
          emojiIcon: look1,
          rate: t("excellent"),
        },
        {
          emojiIcon: look2,
          rate: t("good"),
        },

        {
          emojiIcon: look3,
          rate: t("average"),
        },
        {
          emojiIcon: look4,
          rate: t("oks"),
        },
        {
          emojiIcon: look5,
          rate: t("notGood"),
        },
      ],
    },
    {
      // name: t("bodyFigure"),
      name: t("bodyFigure"),
      icon: body1,
      enName: "body_figure",
      emojis: [
        {
          emojiIcon: body1,
          rate: t("excellent"),
        },
        {
          emojiIcon: body2,
          rate: t("good"),
        },

        {
          emojiIcon: body3,
          rate: t("average"),
        },
        {
          emojiIcon: body4,
          rate: t("oks"),
        },
        {
          emojiIcon: body5,
          rate: t("notGood"),
        },
      ],
    },
    {
      name: t("technic"),
      icon: tech4,
      enName: "technic",
      emojis: [
        {
          emojiIcon: tech1,
          rate: t("excellent"),
        },
        {
          emojiIcon: tech2,
          rate: t("good"),
        },

        {
          emojiIcon: tech3,
          rate: t("average"),
        },
        {
          emojiIcon: tech4,
          rate: t("oks"),
        },
        {
          emojiIcon: tech5,
          rate: t("notGood"),
        },
      ],
    },
    {
      name: t("service"),
      icon: services1,
      enName: "service",
      emojis: [
        {
          emojiIcon: services1,
          rate: t("excellent"),
        },
        {
          emojiIcon: services2,
          rate: t("good"),
        },

        {
          emojiIcon: services3,
          rate: t("average"),
        },
        {
          emojiIcon: services4,
          rate: t("oks"),
        },
        {
          emojiIcon: services5,
          rate: t("notGood"),
        },
      ],
    },
  ];

  return allStickerList;
};

export default StickerData;
