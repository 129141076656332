import { WalletType } from "../../../../constants/WalletType";
import {
  findHeartTransactions,
  getHeartBalance,
} from "../../../../services/products/heart";
import HeartCardComp from "../card/HeartCardComp";
import ClientWalletPageTemplate from "../interface/ClientWalletPageTemplate";
import heartTableRow from "../table/HeartTableRow";
import WalletTableComp from "../table/WalletTableComp";
const user_id = localStorage.getItem("user_id");
export default class HeartWalletTemplate implements ClientWalletPageTemplate {
  constructor(id?: number) {
    this.id = id;
  }
  id?: number;
  type = WalletType.Heart;
  title = "Heart";

  async cardComp() {
    const heartVal = await getHeartBalance();
    return <HeartCardComp coin={heartVal} />;
  }

  async tableComp() {
    // const items = await findHeartTransactions();
    return (
      <WalletTableComp
        walletTableRow={heartTableRow}
   

        getTableData={async (
          page?: Number,
          per_page?: Number
        ) => {
          const result = await findHeartTransactions({
            page: page,
            per_page: per_page,
            desc_sort: true,
          });

          if (result) {
            return result;
          } else {
            return null;
          }
        }}
      />
    );
  }
}
