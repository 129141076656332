import { useState } from "react";
import {
	useDatepicker,
	START_DATE,
	FocusedInput,
	OnDatesChangeProps,
} from "@datepicker-react/hooks";
import Month from "./subcomponents/Month";
import DatepickerContext from "./context/datePickerContext";

export interface IDatepickerProps {
	initDate?: Date,
	onCallback?: (date: Date) => void,
	maxDate?: Date;
}

function Datepicker(props: IDatepickerProps) {
	interface IState {
		startDate: Date | null;
		endDate: Date | null;
		focusedInput: FocusedInput;
	}
	const [state, setState] = useState<IState>({
		startDate: props.initDate ?? null,
		endDate: null,
		focusedInput: START_DATE,
	});
	const {
		firstDayOfWeek,
		activeMonths,
		isDateSelected,
		isDateHovered,
		isFirstOrLastSelectedDate,
		// isDateBlocked,
		isDateFocused,
		focusedDate,
		onDateHover,
		onDateSelect,
		onDateFocus,
		goToPreviousMonths,
		goToNextMonths,
	} = useDatepicker({
		startDate: state.startDate,
		endDate: state.endDate,
		focusedInput: state.focusedInput,
		onDatesChange: handleDateChange,

		numberOfMonths: 1,
		minBookingDays: 1,
		exactMinBookingDays: true,
	});

	const isDateBlocked = (date: Date) => {
		if (props.maxDate && date > props.maxDate) {
		  return true;
		}
		return false;
	  };

	function handleDateChange(data: OnDatesChangeProps) {
		if (!data.focusedInput) {
			setState({ ...data, focusedInput: START_DATE });
		} else {
			setState(data);
		}
		if (props.onCallback && data.startDate) {
			props.onCallback(data.startDate);
		}
	}

	return (
		<DatepickerContext.Provider
			value={{
				focusedDate,
				isDateFocused,
				isDateSelected,
				isDateHovered,
				isDateBlocked,
				isFirstOrLastSelectedDate,
				onDateSelect,
				onDateFocus,
				onDateHover,
			}}
		>
			<div className="date-contents"
				style={{
					display: "grid",
					gridTemplateColumns: `repeat(${activeMonths.length}, auto)`,
					gridGap: "0 64px",
					position: "relative",
				}}
			>
				<div
					onClick={goToPreviousMonths}
					style={{
						position: "absolute",
						left: 20,
						top: 0,
						width: 10,
						cursor: "pointer",
					}}
				>
					<p>{"<"}</p>
				</div>
				<div
					onClick={goToNextMonths}
					style={{
						position: "absolute",
						right: -10,
						top: 0,
						width: 10,
						cursor: "pointer",
					}}
				>
					<p>{">"}</p>
				</div>
				<Month
					key={`${activeMonths[0].year}-${activeMonths[0].month}`}
					year={activeMonths[0].year}
					month={activeMonths[0].month}
					firstDayOfWeek={firstDayOfWeek}
				/>
			</div>
		</DatepickerContext.Provider>
	);
}

export default Datepicker;
