import * as React from "react";
import { useState } from "react";
import { AdMedia } from "../../domain/model/ad-media";

import { Button, Carousel, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { isSafari } from "react-device-detect";
import ReactPlayer from "react-player";

export interface IPhotoGalleryProps {
  id?: string;
  style?: React.CSSProperties;
  listPhoto: AdMedia[];
}

export default function PhotoGallery(props: IPhotoGalleryProps) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { t } = useTranslation();
  const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(
    null
  );

  const imagesOnly = props.listPhoto.filter((e) => e.thumbnail == null);

  let video: AdMedia | null = null;

  for (let i = 0; i < props.listPhoto.length; i++) {
    if (props.listPhoto[i].thumbnail != null) {
      video = props.listPhoto[i];
      break;
    }
  }

  const openModal = (index: number) => {
    setSelectedImageIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedImageIndex(null);
    setModalIsOpen(false);
  };

  return (
    <div className="tab-block" id={props.id} style={props.style}>
      <div className="row g-2">
        {imagesOnly.length === 0 ? (
          <center>
            <div
              style={{
                height: 250,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                <img src="/img/icon_photo.png" width={50} height={50} alt="" />
                <p>There is no photo</p>
              </div>
            </div>
          </center>
        ) : (
          <>
            {video && (
              <>
                <div className="col-12 text-center">
                  {isSafari ? (
                    <ReactPlayer
                      className="video-player"
                      url={video!.cache_url}
                      controls
                    />
                  ) : (
                    <video
                      src={video!.cache_url}
                      controls
                      className="w-100 mb-3 preview-video"
                    />
                  )}
                </div>
              </>
            )}
            {imagesOnly.map((mediaSplit, idx) => {
              let colClass = "col-6 col-md-6 col-lg-6";
              let imgCLass = "custom_photo1 ";

              if (idx % 5 === 2 || idx % 5 === 3 || idx % 5 === 4) {
                colClass = "col-4 col-md-4 col-lg-4";
                imgCLass = "custom_photo ";
              }

              return (
                <div
                  key={idx}
                  className={colClass}
                  onClick={() => openModal(idx)}
                >
                  <img
                    src={mediaSplit.cache_url}
                    alt=""
                    className={imgCLass}
                    // className="custom_photo"
                  />
                </div>
              );
            })}

            <Modal
              show={modalIsOpen}
              onHide={closeModal}
              contentLabel="Image Modal"
              className="customModels border-0"
            >
              {selectedImageIndex !== null && (
                <>
                  <i
                    className="fa-solid fa-xmark  x-custom"
                    onClick={closeModal}
                  ></i>

                  <img
                    src={imagesOnly[selectedImageIndex].cache_url}
                    alt={`Image ${selectedImageIndex + 1}`}
                    className="w-100  imgs"
                  />
                  <div className="modal-navigation">
                    <span
                      className="btn-control-prev"
                      onClick={() =>
                        setSelectedImageIndex((prevIndex) =>
                          prevIndex !== null
                            ? (prevIndex - 1 + imagesOnly.length) %
                              imagesOnly.length
                            : null
                        )
                      }
                    >
                      <i className="fa-solid fa-arrow-left"></i>
                    </span>
                    {/* <span onClick={closeModal}>Close</span> */}
                    <span
                      className="btn-control-next"
                      onClick={() =>
                        setSelectedImageIndex((prevIndex) =>
                          prevIndex !== null
                            ? (prevIndex + 1) % imagesOnly.length
                            : null
                        )
                      }
                    >
                      <i className="fa-solid fa-arrow-right"></i>
                    </span>
                  </div>
                </>
              )}
            </Modal>
          </>
        )}
      </div>
    </div>
  );
}
