import React, { useState, useEffect } from "react";

interface LoadingIndicatorProps {
	startPercent?: number;
	endPercent?: number;
	duration?: number;
	width?: number;
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
	startPercent = 0,
	endPercent = 0,
	duration = 1000,
	width = 25,
}) => {
	const startAngle = (startPercent * 360) / 100 + 90;
	const endAngle = (endPercent * 360) / 100 + 90;
	const [currentAngle, setCurrentAngle] = useState(startAngle);
	const radius = 20;
	const strokeWidth = 10;
	const viewBox = `0 0 ${radius * 2} ${radius * 2}`;

	useEffect(() => {
		const start = Date.now();

		const intervalId = setInterval(() => {
			const now = Date.now();
			const timeElapsed = now - start;
			const progress = timeElapsed / duration;

			if (progress >= 1) {
				setCurrentAngle(endAngle);
				clearInterval(intervalId);
			} else {
				const angle = startAngle + (endAngle - startAngle) * progress;
				setCurrentAngle(angle);
			}
		}, 20);

		return () => clearInterval(intervalId);
	}, [startAngle, endAngle, duration]);

	// const startRadians = (startAngle / 180) * Math.PI - Math.PI / 2;
	const startRadians = 0;
	const endRadians = (currentAngle / 180) * Math.PI - Math.PI / 2;
	const largeArcFlag = endRadians - startRadians <= Math.PI ? 0 : 1;
	const startX = radius + (radius - strokeWidth / 2) * Math.cos(startRadians);
	const startY = radius + (radius - strokeWidth / 2) * Math.sin(startRadians);
	const endX = radius + (radius - strokeWidth / 2) * Math.cos(endRadians);
	const endY = radius + (radius - strokeWidth / 2) * Math.sin(endRadians);

	return (
		<svg viewBox={viewBox} width='100%' height='100%' style={{ width: width }}>
			<defs>
				<linearGradient id='gradient' x1='0' y1='0' x2='100%' y2='0'>
					<stop offset='0%' stopColor='#ff3751' />
					<stop offset='50%' stopColor='#ff3751' />
					<stop offset='100%' stopColor='#ff3751' />
				</linearGradient>
			</defs>
			<circle
				cx={radius}
				cy={radius}
				r={radius - strokeWidth / 2}
				fill='#07070C'
				strokeWidth={strokeWidth}
				stroke='#07070C'
			/>
			<path
				d={`M ${startX},${startY} A ${radius - strokeWidth / 2},${
					radius - strokeWidth / 2
				} 0 ${largeArcFlag} 1 ${endX},${endY}`}
				fill='none'
				strokeWidth={strokeWidth}
				stroke='url(#gradient)'
				transform={`rotate(-90 ${radius} ${radius})`}
			/>
		</svg>
	);
};

export default LoadingIndicator;
