import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import CustomNavigation from "../reusable/Navigation";
import AppRoutePath from "../../constants/AppRoutePath";
import AccountTypeConst from "../../domain/enum/account-type";
import { deleteInstantPost, getInstantsPosts } from "../../services/instant";
import CustomEmptyMessage from "../reusable/EmptyMessage";
import InstantPostsModel, {
  InstantPostModel,
} from "../../domain/model/instant-post-model";
import { Modal, Spinner, Table } from "react-bootstrap";
import { useTranslation, initReactI18next } from "react-i18next";
import TopHearder from "../reusable/TopHearder";
import CustomHeader from "../reusable/Header";
import Loaders from "../reusable/Loaders";
import { shortenNumber } from "../../helper/format-helper";
import iconAccHeart from "../../assets/imgs/acc_heart.svg";

export interface IHistoryPageProps {}

function MyInstantPostLists(): JSX.Element {
  const isMaster = window.location.pathname.includes("master");
  const { id } = useParams();

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isScheduled, setIsScheduled] = useState(false);

  const [isNavOpen, setIsNavOpen] = useState(true);
  const [isDeleteAdModalOpen, setIsDeleteAdModalOpen] = useState(false);
  const [selectedPostId, setSelectedPostId] = useState(-1);
  const [selectedPostTitle, setSelectedPostTitle] = useState("");

  // const [posts, setPosts] = useState<InstantPostsModel>(defaultPosts);
  const [posts, setPosts] = useState<InstantPostsModel | null>();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [descSort, setDescSort] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const isScheduledRef = useRef(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      handleLoadMore();
    }
  };

  useEffect(() => {
    loadPosts(page, isScheduledRef.current);
  }, [page]);
  const handleLoadMore = () => {
    setPage((prevPage) => {
      const nextPage = prevPage + 1;
      loadPosts(nextPage, isScheduledRef.current);
      return nextPage;
    });
  };

  const loadPosts = async (page: number, isScheduled?: boolean) => {
    // console.log("isScheduled ", isScheduled);

    setIsLoading(true);
    try {
      const res = await (isMaster
        ? getInstantsPosts(isScheduled, page, perPage, descSort, Number(id))
        : getInstantsPosts(isScheduled, page, perPage, descSort));

      if (res != null) {
        // console.log(res);

        setPosts((prevPosts) => {
          const existingPostIds = new Set(
            prevPosts?.["item(s)"]?.map((post) => post.post_id) || []
          );
          const newPosts = res["item(s)"].filter(
            (post: InstantPostModel) => !existingPostIds.has(post.post_id)
          );

          return {
            "item(s)": [...(prevPosts?.["item(s)"] || []), ...newPosts],
            total_pages: res.total_pages || 0,
          };
        });
        setIsLoading(false);

        setTotalPages(res.total_pages || 0);
        setHasMore(page < (res.total_pages || 0));
      }
    } catch (error) {
      console.error("Error loading posts:", error);
    }
  };

  //  delete selected post
  const deleteSelectedPost = () => {
    setIsLoading(true);
    deleteInstantPost(String(selectedPostId)).then((isSuccess) => {
      if (isSuccess) {
        setIsDeleteAdModalOpen(false);
        setIsLoading(false);
        getInstantsPosts(isScheduled).then((resAdList) => {
          if (resAdList != null) {
            setPosts(resAdList);
          }
        });
      } else {
        setIsLoading(false);
      }
    });
  };



  return (
    <>
      <CustomNavigation
        isOpen={isNavOpen}
        onCallback={(value: boolean) => {
          setIsNavOpen(value);
        }}
        accountType={
          isMaster ? AccountTypeConst.Master : AccountTypeConst.Client
        }
      />

      <CustomHeader />
      <TopHearder title={t("instantPostList")} />

      <div className="bg-dark">
        <div className="container py-5">
          {/* <div className="inner-box pt-5"> */}
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="d-flex align-items-center justify-content-between mb-4 g-1">
                <Link
                  to={
                    isMaster
                      ? `${AppRoutePath.masterAddInstantPosts}/${id}`
                      : "/client/add-instant-post"
                  }
                  // onClick={() => handlePostAndScheduled(false)}
                >
                  <button className="button primary-button rounded-button create-post-btn">
                    <i className="fa fa-plus me-2" />
                    {t("createNew")}
                  </button>
                </Link>

                <Link
                  to={
                    isMaster
                      ? `/master/my-instant-posts/${id}`
                      : "/client/my-instant-posts"
                  }
                  className="schedule-list primary-color ms-lg-0 ms-md-0 ms-2 "
                  style={{ marginLeft: 100 }}
                >
                  {t("postedIPL")} <i className="fa fa-angle-right"></i>
                </Link>

                <Link
                  to={
                    isMaster
                      ? `/master/my-instant-posts/${id}/scheduled`
                      : "/client/my-instant-posts/scheduled"
                  }
                  className="schedule-list primary-color"
                >
                  {t("scheduledIPL")} <i className="fa fa-angle-right"></i>
                </Link>
              </div>
              <h3 className="postlist-main-title pb-1">
                {isScheduled ? t("scheduled") : t("posted")}{" "}
                {t("instantPostList")}
              </h3>

              <div className="table-responsive">
                <table className="table text-white d-table">
                  <tbody>
                    {posts !== null && posts?.["item(s)"]?.length === 0 ? (
                      <tr className="border-0">
                        <CustomEmptyMessage />
                      </tr>
                    ) : (
                      posts?.["item(s)"]?.map((e, i) => {
                        const date = moment(e.created);
                        const scheduledDate = moment(e.scheduled);
                        return (
                          <React.Fragment key={e.post_id}>
                            <tr className="border-0 mb-0 mt-0">
                              {e.scheduled ? (
                                <>
                                  <td className="border-0 ps-0" colSpan={1}>
                                    <div className="text-start">
                                      {e.title || "Post Title"}
                                    </div>
                                  </td>

                                  <td
                                    className="border-0 text-end text-scheduled"
                                    colSpan={2}
                                  >
                                    {/* {" "}
                                      <span className="primary-color">
                                        {" "}
                                        scheduled :{" "}
                                      </span>{" "}
                                      <small>{e.scheduled} </small> */}
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td className="border-0 ps-0" colSpan={2}>
                                    <div className="text-start">
                                      {e.title || "Post Title"}
                                    </div>
                                  </td>

                                  <td className="border-0 d-flex justify-content-end align-items-center">
                                    {e.current_rank && (
                                      <div className="post-likes post-likes-1 me-2">
                                        <i className="fa fa-crown me-1 "></i>
                                        {/* {parseThousands(e.current_rank)} */}
                                        {shortenNumber(e.current_rank)}
                                      </div>
                                    )}
                                    {/* <div className="post-likes post-likes-1 me-2">
                                        <i className="fa fa-crown me-1 "></i>
                                        {shortenNumber(e?.rank)}
                                      </div> */}

                                    <div className="post-likes-1 me-2 d-flex ">
                                      <img src={iconAccHeart} alt="" />
                                      {shortenNumber(e.current_hearts)}
                                      {/* current_hearts */}
                                    </div>
                                    <div className="post-likes me-2">
                                      <i className="fa fa-heart me-1"></i>
                                      {shortenNumber(e.accum_hearts)}
                                    </div>
                                    <div className="post-saved">
                                      <i className="fa fa-file me-1"></i>
                                      {shortenNumber(e.comments)}
                                    </div>
                                  </td>
                                </>
                              )}
                            </tr>

                            <tr>
                              <td className="">
                                <div className="d-flex align-items-center">
                                  <div className="flex-shrink-0">
                                    {e.media.image &&
                                    e.media.image[0].cache_url ? (
                                      <img
                                        src={e.media.image[0].cache_url}
                                        alt="..."
                                        style={{
                                          width: 50,
                                          height: 50,
                                          borderRadius: 2,
                                        }}
                                      />
                                    ) : e.media.video &&
                                      e.media.video[0].cache_url ? (
                                      <video
                                        src={e.media.video[0].cache_url}
                                        controls
                                        style={{
                                          width: 50,
                                          height: 50,
                                          borderRadius: 2,
                                        }}
                                      />
                                    ) : (
                                      <div>No media available</div>
                                    )}
                                  </div>
                                  <div
                                    className="flex-grow-1 ms-2 post-content1 "
                              //  style={{ maxWidth: 150 }}     
                                  >
                                    <p className="mb-0 dec1 text-break">
                                      {e.content}
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td className="postlist-datetime ps-0">
                                {e.scheduled ? (
                                  <>
                                    <h6 className="d-lg-inline d-md-inline pe-1">
                                      {scheduledDate.format("YYYY-MM-DD")}
                                    </h6>
                                    <h6 className="d-lg-inline d-md-inline ">
                                      {scheduledDate.format("HH:mm:ss")}
                                    </h6>{" "}
                                  </>
                                ) : (
                                  <>
                                    <h6 className="d-lg-inline d-md-inline pe-1">
                                      {date.format("YYYY-MM-DD")}
                                    </h6>
                                    <h6 className="d-lg-inline d-md-inline ">
                                      {date.format("HH:mm:ss")}
                                    </h6>
                                  </>
                                )}
                              </td>
                              {/* <td>
                                  <div className="d-flex padding-top-4">
                                    <div className="post-likes me-2">
                                      <i className="fa fa-heart me-1"></i>
                                      {parseThousands(e.heart)}
                                    </div>
                                    <div className="post-saved">
                                      <i className="fa fa-file me-1"></i>
                                      {parseThousands(e.comments)}
                                    </div>
                                  </div>
                                </td> */}
                              <td className="text-end">
                                <span className="d-flex align-content-center justify-content-end">
                                  <Link
                                    // to={`/client/my-instant-posts/${e.post_id}`}

                                    to={
                                      isMaster
                                        ? `${AppRoutePath.masterMyInstantPostView}/${id}/${e.post_id}`
                                        : `/client/my-instant-posts/${e.post_id}`
                                    }
                                    className="primary-color no-decoration post-edit pe-2 d-inline"
                                  >
                                    {t("view")}
                                  </Link>
                                  <Link
                                    // to={`/client/add-instant-post/${e.post_id}`}
                                    to={``}
                                    className="primary-color no-decoration post-edit pe-2 d-inline"
                                    onClick={() => {
                                      setSelectedPostId(e.post_id);
                                      setSelectedPostTitle(e.title);
                                      setIsDeleteAdModalOpen(true);
                                    }}
                                  >
                                    {/* {t("edit")} */}
                                    {t("delete")}
                                  </Link>
                                  {/* <Dropdown className="d-inline">
                                      <Dropdown.Toggle
                                        id=" "
                                        className="custom-dropdown-1 btn-12 "
                                        // disabled={follower.checked ? true : false}
                                      >
                                        <i className="fa-solid fa-ellipsis-vertical"></i>
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          onClick={() => {
                                            setSelectedPostId(e.post_id);
                                            setSelectedPostTitle(e.title);
                                            setIsDeleteAdModalOpen(true);
                                          }}
                                        >
                                          {t("delete")}
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown> */}
                                </span>
                              </td>
                            </tr>
                          </React.Fragment>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>

              {/* {hasMore && (
                <div className="d-flex justify-content-center">
                  <button
                    className="button primary-button rounded-button create-post-btn"
                    onClick={handleLoadMore}
                    disabled={!hasMore}
                  >
                    Load More
                  </button>
                </div>
              )} */}
              <br />

              <div className="col-12 text-center">
                {hasMore ? (
                  <Loaders />
                ) : (
                  <>
                    {posts?.["item(s)"]?.length !== 0 ? (
                      <p className="text-white">No More post</p>
                    ) : null}
                  </>
                )}
              </div>
              <br />
              <br />
              <br />
              <br />
            </div>
          </div>

          {/* </div> */}
        </div>
      </div>

      <Modal show={isDeleteAdModalOpen}>
        <Modal.Body>
          <Modal.Title>
            <center>
              <h5 className="modal-title">
                Are you sure want to delete this instant post?
                <p className="pt-3 primary-color"> {selectedPostTitle}</p>
              </h5>
            </center>
            <div style={{ height: 10 }}></div>
          </Modal.Title>
        </Modal.Body>

        <Modal.Footer>
          <div className="d-flex justify-content-center align-items-center">
            <button
              type="button"
              className="btn-modal-close1 mt-3 "
              data-bs-dismiss="modal"
              onClick={() => setIsDeleteAdModalOpen(false)}
            >
              No
            </button>
            <div style={{ width: 50 }}></div>
            <button
              type="button"
              className="primary-button button mt-3 w-100"
              data-bs-dismiss="modal"
              onClick={deleteSelectedPost}
            >
              {/* Yes */}
              {isLoading ? <Spinner size="sm" /> : "Yes"}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

// function parseThousands(number: number) {
//   const thousandth = number / 1000;

//   return number >= 1000
//     ? `${Math.round((thousandth + Number.EPSILON) * 10) / 10}k`
//     : number.toString();
// }
// function parseThousands(number: number) {
//   const thousandth = number / 1000;

//   return number >= 1000
//     ? `${Math.round(thousandth * 10) / 10}k`
//     : number.toString();
// }

export default MyInstantPostLists;
