import Snackbar from "awesome-snackbar";
import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router";

import AppRoutePath from "../../constants/AppRoutePath";
import {
  changeMSISDN,
  changeRegisteredEmails,
  getOTP,
  getOTPUserLogin,
  verifyOTP,
} from "../../services/auth";
import FormErrorMessage from "../reusable/FormErrorMessage";
import PasswordField from "../reusable/PasswordField";
import CustomHeader from "../reusable/Header";
import useIsMaster from "../../helper/custom_hooks/use-is-master";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

import TopHearder from "../reusable/TopHearder";
import CreateUserParams from "../../domain/model/create-user-params";
import { getCheckVerificationMethod } from "../../services/modules";

export interface IHistoryPageProps {}

function ChangeRegisterEmail(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMaster = useIsMaster();

  const [selectedPrefix, setSelectedPrefix] = useState("60");
  const [newSelectedPrefix, setNewSelectedPrefix] = useState("60");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isEmailStepValid, setIsEmailStepValid] = useState(false);
  const [isMobileNumberValid, setIsMobileNumberValid] = useState(false);
  const [isOtpValid, setIsOtpValid] = useState(false);
  const [prefixVerifCode, setPrefixVerifCode] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [newMobileNumber, setNewMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const [retypePassword, setRetypePassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showErrorModalMsg, setShowErrorModalMsg] = useState("");

  const [timer, setTimer] = useState(0);
  const [isTimerStart, setIsTimerStart] = useState(false);
  const [intervalTimer, setIntervalTimer] = useState<NodeJS.Timer>();
  const [isEmailExist, setIsEmailExist] = useState(false);

  useEffect(() => {
    if (isTimerStart) {
      setTimer(60);
      const interval = setInterval(() => {
        setTimer((seconds) => {
          return seconds - 1;
        });
      }, 1000);
      setIntervalTimer(interval);
    }
  }, [isTimerStart]);

  useEffect(() => {
    if (timer === 0) {
      clearInterval(intervalTimer);
      setIsTimerStart(false);
    }
  }, [timer, intervalTimer]);

  const handleResendCode: React.MouseEventHandler<HTMLParagraphElement> = (
    event
  ) => {
    if (!isTimerStart) {
      handleGetOtp();
    }
  };

  const handleEmail: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    const inputEmail = event.target.value;
    setEmail(inputEmail);

    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(inputEmail);
    setIsEmailValid(isValidEmail);
  };

  const handlePrefixChange: React.ChangeEventHandler<HTMLSelectElement> = (
    event
  ) => {
    setSelectedPrefix(event.target.value);
  };

  const handleNewPrefixChange: React.ChangeEventHandler<HTMLSelectElement> = (
    event
  ) => {
    setNewSelectedPrefix(event.target.value);
  };

  const handleChangePassword: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setPassword(event.target.value);
  };

  const handleRetypePasswordChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (event) => {
    setRetypePassword(event.target.value);
    // const isValid = retypePassword === password;
    // event.target.setAttribute("aria-invalid", !isValid);
  };

  const handleGetOtp = async (event?: React.FormEvent<HTMLFormElement>) => {
    console.log("test");

    event?.preventDefault();

    await getCheckVerificationMethod({
      email: email,
    }).then(async (res) => {
      // console.log("check res ", res);
      // console.log("check length ", res.in_use.length);
      // console.log("check email ", email);

      if (res.in_use.length === 0) {
        setIsTimerStart(true);
        const prefix = await getOTPUserLogin({
          email: email,
        });
        if (prefix != null) {
          setPrefixVerifCode(prefix);
          setIsEmailStepValid(true);
        }
      } else {
        setIsEmailExist(true);
      }
    });

    // }
  };

  const formatTimer = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secondsLeft = seconds % 60;
    const formatted = `${minutes.toString().padStart(2, "0")}:${secondsLeft
      .toString()
      .padStart(2, "0")}`;
    return formatted;
  };

  const checkIsRegisterFormValid = () => isEmailStepValid;

  const handleChangeEmails: React.MouseEventHandler<HTMLButtonElement> = async (
    event
  ) => {
    event.preventDefault();

    if (checkIsRegisterFormValid()) {
      try {
        setIsLoadingSubmit(true);
        await changeRegisteredEmails(
          `${email}`,
          `${prefixVerifCode}-${verificationCode}`
          // `${newPassword}`
        ).then((res: any) => {
          // console.log(res);

          if (res.data.result_code === 200) {
            new Snackbar("Email has been successfully changed");
            navigate(
              isMaster
                ? AppRoutePath.masterAccount
                : AppRoutePath.clientMainPage
            );
          } else {
            setShowErrorModal(true);
            setShowErrorModalMsg(res.data.reason);
            setVerificationCode("");
            // alert()
          }
        });
      } catch (e) {
        // console.log(e);
        new Snackbar("The verification doesn't match", {
          position: "bottom-center",
        });
      }
      setIsLoadingSubmit(false);
    }
  };

  const handleRedirect = async () => {
    if (isMaster) {
      navigate(AppRoutePath.masterAccount);
    } else {
      navigate(AppRoutePath.clientMainPage);
    }
  };

  return (
    <>
      <div>
        <CustomHeader />
        <TopHearder title={t("ChangeRegisterEmail")} />

        <div className="bg-dark">
          <div className="container mt-4">
            <div className="inner-box">
              <form onSubmit={(e) => handleGetOtp(e)}>
                <div className="row align-items-center g-2 mb-3">
                  <label className="label mb-1">
                    {" "}
                    {t("yourNewEmailAddress")}{" "}
                  </label>
                  <div className="col-9">
                    <div className="input-group">
                      <input
                        className="form-control input addon-input-r"
                        type="email"
                        // pattern="[0-9]+"
                        style={{ color: "white" }}
                        value={email}
                        // disabled={isMobileNumberValid}
                        onChange={(e: any) => handleEmail(e)}
                      />
                      {isEmailStepValid && (
                        <div className="input-group-addon-r">
                          <i className="fa fa-check"></i>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-3 text-center">
                    {!isOtpValid && !isEmailStepValid && (
                      <button
                        className={
                          !isEmailValid
                            ? isLoading
                              ? "primary-button button btn btn-primary px-3 py-1 disabled"
                              : "primary-button button btn btn-primary px-3 py-1"
                            : "light-button button w-100 btn btn-primary"
                        }
                        disabled={!isEmailValid}
                      >
                        {isLoading ? (
                          <Spinner
                            animation="border"
                            variant="light"
                            size="sm"
                          />
                        ) : (
                          t("getCode")
                        )}
                      </button>
                    )}
                    {isEmailStepValid && !isOtpValid && (
                      <div>
                        <p
                          className="light-text"
                          style={{
                            color: isTimerStart ? "#6D6E71" : "#F5405F",
                            cursor: "pointer",
                          }}
                          onClick={handleResendCode}
                        >
                          {t("resendCode")}
                        </p>
                        {isTimerStart && (
                          <p
                            className="light-text"
                            style={{ color: "#F5405F" }}
                          >
                            {formatTimer(timer)}
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </form>
              {isEmailStepValid && (
                <>
                  {/* // <form onSubmit={handleVerifyOtp}> */}
                  <div className="row align-items-end g-2 mb-3">
                    <label className="label mb-1">
                      {" "}
                      {t("verificationCode")}{" "}
                    </label>
                    <div className="col-9">
                      <div className="input-group">
                        <input
                          className="form-control input addon-input-r"
                          type="text"
                          style={{ color: "white", flex: 0.15 }}
                          value={prefixVerifCode}
                          disabled={isOtpValid || prefixVerifCode !== ""}
                          onChange={(e) =>
                            setPrefixVerifCode(
                              e.target.value.replace(/[^a-zA-Z]/g, "")
                            )
                          }
                        />
                        <input
                          className="form-control input addon-input-r"
                          type="tel"
                          style={{ color: "white" }}
                          value={verificationCode}
                          disabled={isOtpValid}
                          onChange={(e) =>
                            setVerificationCode(
                              e.target.value.replace(/[^\d]/g, "")
                            )
                          }
                        />
                        {isOtpValid && (
                          <div className="input-group-addon-r">
                            <i className="fa fa-check"></i>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* // </form> */}
                </>
              )}
              <form>
                <div className="row align-items-center mb-3 g-1">
                  {isEmailStepValid && (
                    <div className="col-12 text-center">
                      {/* <Link to="/accountType"> */}
                      <Button
                        variant="primary"
                        className={
                          checkIsRegisterFormValid()
                            ? "primary-button button w-50"
                            : "primary-button button w-50"
                        }
                        onClick={handleChangeEmails}
                        disabled={
                          isLoadingSubmit || verificationCode.length < 6
                        }
                      >
                        {isLoadingSubmit ? (
                          <Spinner
                            animation="border"
                            variant="light"
                            size="sm"
                          />
                        ) : (
                          t("submit")
                        )}
                      </Button>
                      {/* </Link> */}
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Error  Modal */}
      <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)}>
        <Modal.Body>
          <div className="d-flex justify-content-center align-items-center">
            <Modal.Title>
              <h5 className="modal-title text-center">{showErrorModalMsg}</h5>
              <div className="d-flex justify-content-between w-100 mt-3">
                <Button
                  variant="primary"
                  className="primary-button button mt-3 w-100 mx-2"
                  onClick={() => setShowErrorModal(false)}
                >
                  {t("ok")}
                </Button>
              </div>
            </Modal.Title>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      {/* If Email is already taken */}
      <Modal show={isEmailExist} onHide={() => setIsEmailExist(false)}>
        <Modal.Body>
          <div className="d-flex justify-content-center align-items-center">
            <Modal.Title>
              <h5 className="modal-title text-center">
                {t("alreadyRegEmail1")}
              </h5>
              <div className="d-flex justify-content-between w-100 mt-3">
                {/* <Link to="/"> */}
                <Button
                  className="secondary-button button mt-3 w-100 mx-2"
                  onClick={() => setIsEmailExist(false)}
                >
                  {t("ok")}
                </Button>
                <Button
                  // variant="primary"
                  className="primary-button button mt-3 w-100 mx-2"
                  onClick={handleRedirect}
                >
                  {t("backToMain1")}
                </Button>
              </div>
            </Modal.Title>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}

export default ChangeRegisterEmail;
