import Snackbar from "awesome-snackbar";
import axios from "../axios";
import HistoryModel from "../../domain/model/history-model";

export interface GetBlogHistoryClientParams {
    target?: number;
    sort?: boolean;
    start_at?: string;
    end_at?: string;
}
export const getAdsHistoryClient = async (params: GetBlogHistoryClientParams) => {
	const res = await axios.get("/client/ad/status/find", {params})
		.then((respons) => {
			console.log(respons);
			return respons.data["item(s)"] as HistoryModel[];
		})
		.catch((error) => {
			try {
				console.log(JSON.stringify(error));
				// new Snackbar(`ERROR: ${error.error.reason ?? error.error.message}`);
				if ((error.error.reason || error.error.message) === "Token Expired") {
					// new Snackbar("Please relogin again.");
				}
			} catch (error) {
				console.log(error);
				new Snackbar(`Failed to get advertisement history`);
			}
			return null;
		});

	return res;
};
export const getBlogHistoryClient = async (params: GetBlogHistoryClientParams) => {
	const res = await axios.get("/client/blog/status/find", {params})
		.then((respons) => {
			console.log(respons);
			return respons.data["item(s)"] as HistoryModel[];
		})
		.catch((error) => {
			try {
				console.log(JSON.stringify(error));
				// new Snackbar(`ERROR: ${error.error.reason ?? error.error.message}`);
				if ((error.error.reason || error.error.message) === "Token Expired") {
					// new Snackbar("Please relogin again.");
				}
			} catch (error) {
				console.log(error);
				new Snackbar(`Failed to get advertisement history`);
			}
			return null;
		});

	return res;
};