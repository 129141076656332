import { WalletType } from "../../../../constants/WalletType";
import {
  findFlowerBalance,
  findFlowerRanking,
  findFlowerTransaction,
} from "../../../../services/products/flower";
import FlowerCardComp from "../card/FlowerCardComp";
import ClientWalletPageTemplate from "../interface/ClientWalletPageTemplate";
import flowerTableRow from "../table/FlowerTableRow";
import WalletTableComp from "../table/WalletTableComp";

export default class FlowerWalletTemplate implements ClientWalletPageTemplate {
  constructor(id?: number) {
    this.id = id;
  }
  id?: number;
  type = WalletType.Flower;
  title = "FLOWER";

  async cardComp() {
    const coinVal = await findFlowerBalance();
    const flowerRank = await findFlowerRanking();
    return <FlowerCardComp coin={coinVal} ranking={flowerRank} />;
  }

  async tableComp() {
    return (
      <>
        <WalletTableComp
          walletTableRow={flowerTableRow}
          getTableData={async (page?: Number, per_page?: Number) => {
            const result = await findFlowerTransaction({
              page: page,
              per_page: per_page,
              // from: [Number(user_id)],
              desc_sort: true,
            });

            if (result) {
              return result;
            } else {
              return null;
            }
          }}
        />{" "}
      </>
    );
  }
}
