import { ClientProfileModel } from "../../domain/model/client-profile-model";
import axios from "../axios";

export const getClientProfile = async () => {
	const res = await axios
		.get("/client/profile")
		.then((respons) => {
			// console.log(" Client Profile respons ", respons.data);
			return respons.data as ClientProfileModel;
		})
		.catch((error) => {
			return null;
		});

	return res;
};
export const getAddtionalInfo = async (target?: any) => {

	const res = await axios
		.get(`/client/personalDetail?target=${target}`)
		.then((respons) => {
			console.log("/client/personalDetail", respons);
			return respons.data
		})
		.catch((error) => {
			return null;
		});

	return res;
};

export const editAddtionalInfo = async (data: any) => {

	console.log("editAddtionalInfo ", data);


	const res = await axios
		.put("/client/int/detail/edit", { details: { telegram: data.telegram }, target: data.target })
		.then((respons) => {
			// console.log("/client/int/detail/edit", respons);
			return respons.data
		})
		.catch((error) => {
			return null;
		});

	return res;
};
export const getClientQRcode = async () => {

	const res = await axios
		.get("/client/showQRcode")
		.then((respons) => {
			console.log("/client/showQRcod", respons.data);
			return respons.data
		})
		.catch((error) => {
			return error;
		});

	return res;
};

