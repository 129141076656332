import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import AppRoutePath from "../../../constants/AppRoutePath";
import LocalStorageKey from "../../../constants/LocalStorageKey";
import CreateUserParams from "../../../domain/model/create-user-params";
import { uploadFileToS3 } from "../../../services/upload";
import CustomBackButton from "../../reusable/BackButton";
import FilePicker from "../../reusable/FilePicker";
import LoadingIndicator from "../../reusable/Loader";
import RegistrationSteps from "../navigation/RegistrationSteps";
import { useTranslation } from "react-i18next";

import CustomHeader from "../../reusable/Header";
import TopHearder from "../../reusable/TopHearder";
import { rechargeOneCoinReal } from "../../../services/products/one-coin";
import ActionLoader from "../../../components/reusable/ActionLoader";
function ReloadCOinUploadProgress() {
  const [reloadCoinUploadInputState, setReloadCoinUploadInputState] = useState(
    useLocation().state as ReloadCoinUploadInputState
  );
  // const reloadCoinUploadInputState = useLocation().state as ReloadCoinUploadInputState;
  const [isAllUploadedToS3, setIsAllUploadedToS3] = useState(false);
  const [isUploadSuccess, setIsUploadSuccess] = useState(false);
  const [isWalletEmpty, setIsWalletEmpty] = useState(false);
  const [walletEmptyMsg, setWalletEmptyMsg] = useState("");
  const navigate = useNavigate();
  const createUserParams = CreateUserParams.getInstance();
  const isLogin =
    (localStorage.getItem(LocalStorageKey.isLogin) ?? "false") === "true";
  // const isLogin = localStorage.getItem("account_type") != null;
  // const isMaster = localStorage.getItem(LocalStorageKey.role) === "master";
  const isMaster = window.location.pathname.includes("master");
  const isClient = window.location.pathname.includes("client");
  const { t } = useTranslation();
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [isTimerStarted, seIsTimerStarted] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [startTimer, setStartTimer] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (startTimer) {
      timer = setInterval(() => {
        if (seconds > 0) {
          setSeconds((prevSeconds) => prevSeconds - 1);
        }
      }, 1000);
    }

    console.log("gg ", seconds);

    if (seconds === 0 && startTimer) {
      handleAPICall();
      setStartTimer(false); // Stop the timer
      seIsTimerStarted(false);
    }

    return () => clearInterval(timer);
  }, [startTimer, seconds]);

  const processPhotos = () => {
    for (let i = 0; i < reloadCoinUploadInputState.listImage.length; i++) {
      setReloadCoinUploadInputState((state) => {
        state.listImage[i].uploadProgress0 = state.listImage[i].uploadProgress;
        state.listImage[i].uploadProgress = 50;
        return state;
      });
      uploadFileToS3(reloadCoinUploadInputState.listImage[i].image)
        .then((res) => {
          setReloadCoinUploadInputState((state) => {
            state.listImage[i].path = res?.fullpath ?? "";
            state.listImage[i].uploadProgress0 =
              state.listImage[i].uploadProgress;
            state.listImage[i].uploadProgress = 100;
            return state;
          });
        })
        .finally(() => {
          const allProgress =
            reloadCoinUploadInputState.listImage
              .map((img) => img.uploadProgress)
              .reduce((prev, curr) => prev + curr) /
            (reloadCoinUploadInputState.listImage.length * 100);
          if (allProgress === 1) {
            setIsAllUploadedToS3(true);
          }
        });
    }
  };

  const handleChangeUploadPhoto = (listFileSelected?: FileList) => {
    if (listFileSelected) {
      setIsAllUploadedToS3(false);
      const listImageUploaded: ImageUploaded[] = [];
      setReloadCoinUploadInputState({ listImage: [] });
      for (let i = 0; i < listFileSelected.length; i++) {
        uploadFileToS3(listFileSelected[i]).then((res) => {
          const imageTemp: ImageUploaded = {
            image: listFileSelected[i],
            path: res?.fullpath ?? "",
            uploadProgress0: 0,
            uploadProgress: 100,
          };
          listImageUploaded.push(imageTemp);
          setReloadCoinUploadInputState({ listImage: [...listImageUploaded] });
          const allProgress =
            reloadCoinUploadInputState.listImage
              .map((img) => img.uploadProgress)
              .reduce((prev, curr) => prev + curr) /
            (reloadCoinUploadInputState.listImage.length * 100);
          if (allProgress === 1) {
            setIsAllUploadedToS3(true);
          }
        });
      }
    }
  };

  useEffect(processPhotos, []);
  const convert = () => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const displayText =
      minutes > 0 ? `${minutes} minute${minutes > 1 ? "s" : ""}` : "";
    return (
      displayText +
      (remainingSeconds > 0
        ? (displayText ? " " : "") +
          `${remainingSeconds} second${remainingSeconds > 1 ? "s" : ""}`
        : "")
    );
  };

  // const totalTime
  useEffect(() => {
    if (isAllUploadedToS3) {
      if (createUserParams.data.details == null) {
        console.log(typeof createUserParams.data);
        createUserParams.data.details = {};
      }
      createUserParams.data.details!.payment_registration =
        reloadCoinUploadInputState.listImage.map((img) => img.path);
      createUserParams.saveData();

      setIsUploadSuccess(true);
    }
  }, [isAllUploadedToS3]);

  const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = async (
    event
  ) => {
    seIsTimerStarted(true);
    setStartTimer(true);
    setSeconds(120);
    if (isAllUploadedToS3) {
      if (createUserParams.data.details == null) {
        console.log(typeof createUserParams.data);
        createUserParams.data.details = {};
      }
      createUserParams.data.details!.payment_registration =
        reloadCoinUploadInputState.listImage.map((img) => img.path);
      createUserParams.saveData();

      setIsUploadSuccess(true);
    }
  };

  const handleAPICall = async () => {
    // console.log("gdfgf");

    if (isLogin) {
      setIsLoadingSubmit(true);
      await rechargeOneCoinReal().then((res: any) => {
        const resultCode = res.result_code;
        console.log('Reload Real One Coin"', res);
        console.log('Reload Real One Coin"', res.result_code);
        if (resultCode === 200) {
          setIsLoadingSubmit(false);
          setWalletEmptyMsg("");
          navigate(AppRoutePath.reloadCoinSuccess);
        } else {
          // alert(res.reason);
          setWalletEmptyMsg(res.reason);
          setIsLoadingSubmit(false);
          setIsWalletEmpty(true);
        }
      });
    }
  };

  return (
    <>
      <CustomHeader publics={true} />
      <TopHearder title={t("OneCoinReloads1")} />
      <div className="bg-dark">
        <div className="container">
          <div className="inner-box">
            <div className="accordion pt-3" id="accordionExample">
              {isLogin ? <></> : <RegistrationSteps step={1} />}

              <div className="tab-content">
                <div id="headingTwo"></div>
                <div
                  id="collapseTwo"
                  className="collapse show text-center"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <h4 className="mt-3 mb-1 qr-title">{t("transferUSD")} </h4>
                  <p className="qr-subtitle">{t("transferUSD1")} </p>
                  <label htmlFor="" className="text-dim mb-2">
                    {t("uploadFile")}
                  </label>
                  <FilePicker
                    onChangedList={handleChangeUploadPhoto}
                    multiple={true}
                  >
                    <button className="btn primary-button w-100 text-end rounded-button px-3">
                      <i className="fa fa-angle-down"></i> {t("Browse")}
                    </button>
                  </FilePicker>

                  <div className="progress-container mt-5">
                    {reloadCoinUploadInputState.listImage.map((img) => {
                      // return <div>
                      // 	<LoadingIndicator startPercent={img.uploadProgress0} endPercent={img.uploadProgress}/>
                      // 	<h6 className='mb-0'>{img.image.name}</h6>
                      // 	<i className='fa fa-check progress-complete'></i>
                      // </div>
                      return (
                        <li className="progress-step">
                          <div className="inner">
                            <LoadingIndicator
                              startPercent={img.uploadProgress0}
                              endPercent={img.uploadProgress - 0.001}
                              duration={1000}
                            />
                            <h6 className="mb-0">{img.image.name}</h6>
                          </div>
                          <i className="fa fa-check progress-complete"></i>
                        </li>
                      );
                    })}
                  </div>

                  <Button
                    variant="primary"
                    className={
                      isUploadSuccess
                        ? "primary-button button mt-3 w-100 l-1"
                        : "primary-button button light-button mt-3 w-100 l-1"
                    }
                    onClick={handleSubmit}
                    disabled={isLoadingSubmit}
                  >
                    {isLoadingSubmit ? (
                      <Spinner size="sm" />
                    ) : (
                      <span>{t("submitTranBtn")}</span>
                    )}
                    {/* {t("submitTranBtn")} */}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {seconds > 0 && (
        <ActionLoader
          isHasTimer={isTimerStarted}
          second={convert()}
          ishasInfoText={true}
        />
      )}
      {/* Error Modal */}

      <Modal show={isWalletEmpty} onHide={() => setIsWalletEmpty(false)}>
        <Modal.Body>
          <div className="d-flex justify-content-center align-items-center">
            <Modal.Title>
              <h5 className="modal-title text-center">{walletEmptyMsg}</h5>
              <div className="d-flex justify-content-between w-100 mt-3">
                {/* <Link to="/"> */}
                <Button
                  className="secondary-button button mt-3 w-100 mx-2"
                  onClick={() => setIsWalletEmpty(false)}
                >
                  {t("ok")}
                </Button>
                {/* </Link> */}
              </div>
            </Modal.Title>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}

export interface ReloadCoinUploadInputState {
  listImage: ImageUploaded[];
}

export interface ImageUploaded {
  image: File;
  path: string;
  uploadProgress0: number;
  uploadProgress: number;
}

export default ReloadCOinUploadProgress;
