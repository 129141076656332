import React, { useState, useEffect } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";

import RegistrationSteps from "../navigation/RegistrationSteps";
import { getClientProfile } from "../../../services/client/client-profile";
import {
  rechargeOneCoin,
  rechargeOneCoinReal,
} from "../../../services/products/one-coin";
import CreateUserParams from "../../../domain/model/create-user-params";
import { getProfileMaster } from "../../../services/master/master-account";
import AppRoutePath from "../../../constants/AppRoutePath";
import CustomNavigation from "../../reusable/Navigation";
import AccountTypeConst from "../../../domain/enum/account-type";
import LocalStorageKey from "../../../constants/LocalStorageKey";
import { findCoinBalance } from "../../../services/products/one-coin";
import { useTranslation } from "react-i18next";
import CustomHeader from "../../reusable/Header";
import TopHearder from "../../reusable/TopHearder";

function ReloadCoinSuccessPage() {
  const navigate = useNavigate();
  const isMaster = window.location.pathname.includes("master");
  const [isLoading, setIsLoading] = useState(false);
  const isLogin =
    (localStorage.getItem(LocalStorageKey.isLogin) ?? "false") === "true";
  const [isNavOpen, setIsNavOpen] = useState(true);
  const { t } = useTranslation();

  const handleDone = async () => {
    let nickname: string | undefined;
    const userType = CreateUserParams.getInstance().data.user_type!;
    const role = localStorage.getItem("role");
    let checkAddDetailsIsCompleted: any;
    let checkMasterDetails: any;

    if (isMaster && role === "master") {
      try {
        checkMasterDetails = await getProfileMaster();
      } catch (error) {
        console.error("Error fetching profile for master:", error);
      }
    } else {
      try {
        checkAddDetailsIsCompleted = await getClientProfile();
      } catch (error) {
        console.error("Error fetching client profile:", error);
      }
    }

    setIsLoading(true);
    try {
      // await rechargeOneCoinReal().then((res) => {
      //   console.log('Reload Real One Coin"', res);
      // });
      // await rechargeOneCoin();
    } catch (error) {
      console.error("Error recharging one coin:", error);
    }

    if (isLogin) {
      if (
        !checkAddDetailsIsCompleted?.details?.basic_desc?.phone &&
        role === "client"
      ) {
        navigate(AppRoutePath.clientAddDetails);
      } else if (
        checkAddDetailsIsCompleted?.details?.basic_desc?.phone &&
        role === "client"
      ) {
        navigate(AppRoutePath.clientWallet + "/one-coin");
      } else if (role === "master") {
        // navigate(AppRoutePath.masterAccount);
        navigate(AppRoutePath.masterWallet + "/one-coin");
      }
    }
  };

  return (
    <>
      <CustomNavigation
        isOpen={isNavOpen}
        onCallback={(value: boolean) => {
          setIsNavOpen(value);
        }}
        accountType={
          isMaster ? AccountTypeConst.Master : AccountTypeConst.Client
        }
      />

      <CustomHeader publics={true} />
      <TopHearder title={t("OneCoinReloads1")} />
      <div className="bg-dark">
        <div className="container">
          <div className="inner-box">
            <div className="accordion pt-3" id="accordionExample">
              {isLogin ? <></> : <RegistrationSteps step={1} />}

              <div className="tab-content">
                <div id="headingTwo"></div>
                <div
                  id="collapseTwo"
                  className="collapse show text-center"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <h4 className="mt-3 mb-1 qr-title">
                    {t("SubmitSuccessful")}{" "}
                  </h4>
                  <p className="qr-subtitle">
                    {t("SubmitSuccessful1")} <br /> {t("SubmitSuccessful2")}
                  </p>

                  {/* <Link to="/load-coin"> */}
                  <Button
                    variant="primary"
                    className="primary-button button mt-3 w-100 l-1"
                    onClick={handleDone}
                    disabled={isLoading}
                  >
                    {isLoading ? <Spinner size="sm" /> : t("done")}
                  </Button>
                  {/* </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReloadCoinSuccessPage;
