import * as React from "react";
import CustomHeader from "../reusable/Header";
import SizedBox from "../reusable/Sizedbox";
import { SystemMessageModel } from "../../domain/model/system-message";
import {
  deleteAllSystemMessages,
  deleteSystemMessage,
  getSystemMessage,
} from "../../services/system-notification";
import { Modal, Pagination } from "react-bootstrap";
import { Link } from "react-router-dom";
import AppRoutePath from "../../constants/AppRoutePath";
import CustomEmptyMessage from "../reusable/EmptyMessage";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import TopHearder from "../reusable/TopHearder";
import Loaders from "../reusable/Loaders";

export interface ISystemMessageListPageProps {}

export default function SystemMessageListPage(
  props: ISystemMessageListPageProps
) {
  const [systemMessageList, setSystemMessageList] = React.useState<
    SystemMessageModel[]
  >([]);
  const [isModalDeleteAllOpen, setIsModalDeleteAllOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(10);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(0);
  const [currentPage1, setCurrentPage1] = React.useState(1);
  const [totalPages1, setTotalPages1] = React.useState(0);
  const getData = () => {
    setIsLoading(true);
    getSystemMessage({ page: currentPage, per_page: perPage }).then((res) => {
      if (res) {
        setSystemMessageList(res["item(s)"]);
        const totalPages = res.total_pages || 1;
        setTotalPages(totalPages);

        setIsLoading(false);
      }
      setIsLoading(false);
    });
  };

  React.useEffect(getData, [currentPage, totalPages]);

  const { t } = useTranslation();

  const deleteAllMessages = () => {
    deleteAllSystemMessages().then((isSuccess) => {
      if (isSuccess) {
        setIsModalDeleteAllOpen(false);
        getData();
      }
    });
  };

  const goToPage = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const goToNextPage = () => {
    goToPage(currentPage + 1);
  };

  const goToPrevPage = () => {
    goToPage(currentPage - 1);
  };

  return (
    <div>
      <CustomHeader />
      <TopHearder title={t("systemMessage")} />

      <div className="bg-dark">
        <div className="inner-box sys-msg" style={{ paddingTop: 20 }}>
          <button
            className="btn primary-button rounded-button mb-4"
            onClick={() => {
              setIsModalDeleteAllOpen(true);
            }}
          >
            {t("deleteAllMessages")}
          </button>
          {isLoading ? (
            <div className="text-center pb-3">
              <Loaders />
            </div>
          ) : systemMessageList.length === 0 ? (
            <CustomEmptyMessage />
          ) : (
            systemMessageList.map((item, i) => (
              <SystemMessageItem
                data={item}
                onDeleteCallback={getData}
                getData={getData}
                key={i}
              />
            ))
          )}
        </div>
        {systemMessageList.length > 0 && (
          <div className="d-flex justify-content-center my-3">
            <Pagination>
              <Pagination.Prev
                onClick={goToPrevPage}
                disabled={currentPage === 1}
              />

              {/* Show the first page */}
              <Pagination.Item onClick={() => goToPage(1)}>
                First
              </Pagination.Item>

              {/* Show ellipsis if there are more than 3 pages before the current page */}
              {currentPage > 3 && <Pagination.Ellipsis disabled />}

              {/* Show two pages before the current page */}
              {currentPage > 2 && (
                <Pagination.Item onClick={() => goToPage(currentPage - 1)}>
                  {currentPage - 1}
                </Pagination.Item>
              )}

              {/* Show the current page */}
              <Pagination.Item active>{currentPage}</Pagination.Item>

              {/* Show two pages after the current page */}
              {currentPage < totalPages - 1 && (
                <Pagination.Item onClick={() => goToPage(currentPage + 1)}>
                  {currentPage + 1}
                </Pagination.Item>
              )}

              {/* Show ellipsis if there are more than 3 pages after the current page */}
              {currentPage < totalPages - 2 && <Pagination.Ellipsis disabled />}

              {/* Show the last page */}
              <Pagination.Item onClick={() => goToPage(totalPages)}>
                Last
              </Pagination.Item>

              <Pagination.Next
                onClick={goToNextPage}
                disabled={currentPage === totalPages}
              />
            </Pagination>
          </div>
        )}
      </div>

      <Modal show={isModalDeleteAllOpen}>
        <Modal.Body>
          <Modal.Title>
            <center>
              <h5 className="modal-title">
                {t("systemMessageDeleteAllConfirm")}
              </h5>
              <SizedBox height={25} />
            </center>
          </Modal.Title>
        </Modal.Body>

        <Modal.Footer>
          <div className="d-flex justify-content-center align-items-center">
            <button
              type="button"
              className="primary-button button mt-3 w-100"
              data-bs-dismiss="modal"
              onClick={deleteAllMessages}
            >
              {t("yes")}
            </button>
            <div style={{ width: 50 }}></div>
            <button
              type="button"
              className="primary-button button mt-3 w-100"
              data-bs-dismiss="modal"
              onClick={() => setIsModalDeleteAllOpen(false)}
            >
              {t("no")}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export interface ISystemMessageItemProps {
  data: SystemMessageModel;
  onDeleteCallback: () => void;
  getData: () => void;
}

export function SystemMessageItem(props: ISystemMessageItemProps) {
  const isMaster = window.location.pathname.includes("master");
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const deleteItem = () => {
    deleteSystemMessage({ notif_id: props.data.notif_id }).then((isSuccess) => {
      if (isSuccess) {
        setIsModalOpen(false);
        props.getData();
      }
    });
  };

  const { t } = useTranslation();
  const [language, setLanguage] = React.useState("en"); // Default language is English

  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-start",
        height: 100,
        borderBottom: "solid #f5405f 2px",
        marginBottom: 10,
        marginRight: 10,
      }}
    >
      <i
        className="fa-solid fa-xmark"
        style={{
          color: "#ff3751",
          fontSize: 15,
          cursor: "pointer",
        }}
        onClick={() => setIsModalOpen(true)}
      ></i>
      <SizedBox width={10} />
      <Link
        to={`${
          isMaster
            ? AppRoutePath.masterSystemMessages
            : AppRoutePath.clientSystemMessages
        }/${props.data.notif_id}`}
        style={{
          display: "flex",
          alignItems: "flex-start",
          height: "100%",
          width: "100%",
        }}
      >
        <div
          style={{ width: "80%", display: "flex", flex: 1, cursor: "pointer" }}
        >
          <p
            className="sys-msg-2"
            style={{
              display: "-webkit-box",
              WebkitLineClamp: 4,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
            }}
          >
            <p
              className="msg-datetime-box2"
              style={{ color: "#55565b", fontSize: 11 }}
            >
              {props.data.created.split(" ")[0]}
              <span className="ms-2">{props.data.created.split(" ")[1]}</span>
            </p>
            {props.data.content}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-end",
            height: "100%",
          }}
        >
          <div
            style={{
              backgroundColor: props.data.status === 0 ? "#ff3751" : "#212529",
              width: 10,
              height: 10,
              borderRadius: 12,
            }}
          ></div>
        </div>
      </Link>

      <Modal show={isModalOpen}>
        <Modal.Body>
          <Modal.Title>
            <center>
              <h5 className="modal-title">{t("systemMessageDelConfirm")}</h5>
              <SizedBox height={25} />
            </center>
          </Modal.Title>
        </Modal.Body>

        <Modal.Footer>
          <div className="d-flex justify-content-center align-items-center">
            <button
              type="button"
              className="primary-button button mt-3 w-100"
              data-bs-dismiss="modal"
              onClick={deleteItem}
            >
              {t("yes")}
            </button>
            <div style={{ width: 50 }}></div>
            <button
              type="button"
              className="primary-button button mt-3 w-100"
              data-bs-dismiss="modal"
              onClick={() => setIsModalOpen(false)}
            >
              {t("no")}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
