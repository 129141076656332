import * as React from "react";
import { Modal, Popover, PopoverBody, Spinner } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";
import { Link, useNavigate } from "react-router-dom";
import AppRoutePath from "../../constants/AppRoutePath";
import AccountTypeConst from "../../domain/enum/account-type";
import MasterAccountDetailsType from "../../domain/enum/master-account-details-type";
import { ProfileMasterModel } from "../../domain/model/profile-master-model";
import { ShopModel } from "../../domain/model/shop-model";
import { getFollowerList } from "../../services/client/client-follow";
import { getProfileMaster } from "../../services/master/master-account";
import { controlShop, getShop } from "../../services/master/master-shop";
import { getMasterShopUpload } from "../../services/upload";
// import { findFlowerBalance } from "../../services/products/flower";
// import { getHeartBalance } from "../../services/products/heart";
import { findCoinBalance } from "../../services/products/one-coin";
import CustomNavigation from "../reusable/Navigation";

import SizedBox from "../reusable/Sizedbox";
import { useTranslation } from "react-i18next";

import { getAllTypeFeature, getClientPrice } from "../../services/modules";
import { getAdListMaster } from "../../services/master/master-ad";
import CustomHeader from "../reusable/Header";
import TopHearder from "../reusable/TopHearder";
import { AdListModel } from "../../domain/model/ad-list-model";
import { controlBlogClient } from "../../services/client";
import ScreenLoaders from "../reusable/ScreenLoader";

import {
  getTChatLanguages,
  getTchatUrl,
  getUserTChatLanguages,
  setTChatLanguages,
} from "../../services/tChat/chats";

import { TChatModel1 } from "../../domain/model/t-chat-model";

export interface IMasterAccountProps {}

export default function MasterAccount(props: IMasterAccountProps) {
  const langType = localStorage.getItem("i18nextLng");
  const isMaster = window.location.pathname.includes("master");
  const [isNavOpen, setIsNavOpen] = React.useState(true);
  const [shop, setShop] = React.useState<ShopModel | null>(null);
  const [masterProfile, setMasterProfile] =
    React.useState<ProfileMasterModel | null>();
  const [oneCoinBalance, setOneCoinBalance] = React.useState(0);
  const [flowerBalance, setFlowerBalance] = React.useState(0);
  const [heartBalance, setHeartBalance] = React.useState(0);
  const [followerCount, setFollowerCount] = React.useState(0);
  const [masterTotalAds, setMasterTotalAds] = React.useState(0);
  const [isMasterTotalAds, setIsMasterTotalAds] = React.useState(false);
  const [isBlogOn, setIsBlogOn] = React.useState(false);
  const [blogStatus, setBlogStatus] = React.useState(
    masterProfile?.master_blog
  );
  const [clientBlogStatus, setClientBlogStatus] = React.useState<number | null>(
    null
  );

  const [blogPayment, setBlogPayment] = React.useState(
    masterProfile?.master_blog_payment
  );
  const [adStatus, setAdStatus] = React.useState(masterProfile?.ad_status);
  const [adPayment, setAdPayment] = React.useState(masterProfile?.ad_payment);
  const [notifCount, setNotifCount] = React.useState(0);
  const [shopBannerUrl, setShopBannerUrl] = React.useState("");
  const [isShopDataLoaded, setIsShopDataLoaded] = React.useState(false);
  const navigate = useNavigate();
  const [language, setLanguage] = React.useState("en"); // Default language is English

  const { t } = useTranslation();
  const [masterType, setMasterType] = React.useState("");
  const [adBlogId, setAdBlogId] = React.useState(0);
  const [adBlogCurrentStatus, setAdBlogCurrentStatus] = React.useState(0);
  const [adList, setAdList] = React.useState<AdListModel | null>(null);
  const [type, setType] = React.useState<Array<{ [key: string]: boolean }>>([]);
  const [allBlogValues, setAllBlogValues] = React.useState<any[]>([]);
  const userRoles = localStorage.getItem("account_type");
  const userLoginRole = localStorage.getItem("role");
  const typeFeaturesArray = localStorage.getItem("typeFeaturesArray");
  const AllTypePrice = localStorage.getItem("AllTypePrice");
  const [isTChatLoading, setIsTChatLoading] = React.useState(false);
  const [tChatGenerateLink, setTChatGenerateLink] = React.useState("");
  const [tChatModelOpen, setTChatModelOpen] = React.useState(false);
  const [isLoadingScreen, setIsLoadingScreen] = React.useState(false);
  const [activePopover, setActivePopover] = React.useState(null);

  const [tChatLang, setTChatLang] = React.useState<TChatModel1[]>([]);

  const [tChats, setTChats] = React.useState("");
  const abortController = new AbortController();

  const handlePopoverToggle = (popoverType: any) => {
    if (activePopover === popoverType) {
      setActivePopover(null);
    } else {
      setActivePopover(popoverType);
    }
  };

  React.useEffect(() => {
    setIsLoadingScreen(true);

    getShop()
      .then((res) => {
      })
      .catch((error) => {
        console.error("API error:", error);
      });
    getProfileMaster().then((res) => {
      if (res != null) {
        setMasterProfile(res);
        setIsLoadingScreen(false);
        //  setIsBlogOn(res.ad_status)
        setBlogStatus(res?.master_blog);
        setMasterType(res?.master_type);
        setMasterTotalAds(Number(res?.total_clients));
        localStorage.setItem("adsTotal", `${res?.total_clients}`);
      }
      setIsLoadingScreen(false);

      // console.log("master Account res", res);
    });

    if (!typeFeaturesArray) {
      getAllTypeFeature().then((res) => {
        localStorage.setItem("typeFeaturesArray", JSON.stringify(res));
      });
    }
    if (!AllTypePrice) {
      getClientPrice().then((response) => {
        if (response != null) {
          localStorage.setItem("AllTypePrice", JSON.stringify(response));
        }
      });
    }

    getTChatLanguages().then((res: any) => {
      setTChatLang(res);
    });
    getUserTChatLanguages().then((res: any) => {
      setTChats(res.lang);
    });
    findCoinBalance().then((res) => setOneCoinBalance(res));
    // findFlowerBalance().then((res) => setFlowerBalance(res));
    // getHeartBalance().then((res) => setHeartBalance(res));
    getFollowerList().then((res) => {
      if (res != null) {
        setFollowerCount(res.total);
      }
    });

    getMasterShopUpload().then((apiResponse) => {
      const res = apiResponse["item(s)"][0];

      if (res) {
        setShopBannerUrl(res.cache_url);
      }
    });
    getAdListMaster().then((apiResponse) => {
      const ads = apiResponse?.["item(s)"] || [];
      const count = ads.filter((ad) => ad.status !== 3).length;
      // setMasterTotalAds(Number(count));
      setAdList(apiResponse);

      // Find the first ad with status not equal to 3
      const firstAdWithStatusNot3 = ads.find((ad) => ad.status !== 3);
      localStorage.setItem("adsTotal", `${count}`);

      // Check if such an ad was found
      if (firstAdWithStatusNot3) {
        const adType = firstAdWithStatusNot3.details?.basic_desc?.type;
        // console.log("Type of the first ad with status not equal to 3:", adType);
        localStorage.setItem("userType", adType || "");
        getClientPrice(adType).then((response) => {
          if (response != null) {
            localStorage.setItem("priceList", JSON.stringify(response));
          }
        });
      } else {
        // console.log("No ad found with status not equal to 3.");
      }
    });

    return () => {
      // Cancel all pending requests
      abortController.abort();
    };
  }, []);

  const handleBlog = (status: number) => {
    controlShop(status).then((isSuccess) => {
      // console.log("bvb", status);

      if (isSuccess) {
        setBlogStatus(status);
      }
    });
  };

  const handleProfileDetailsClick = () => {
    // Call the getShop API
    getShop()
      .then((res) => {
        if (res) {
          // Check if the response is not null
          const profileDetailsPath =
            res.zh_name === "" || res.en_name === ""
              ? `${AppRoutePath.masterAccountDetails}/${MasterAccountDetailsType.Register.path}`
              : `${AppRoutePath.masterAccountDetails}/${MasterAccountDetailsType.Edit.path}`;
          navigate(profileDetailsPath);
        } else {
          // Handle the case when the API response is null (status 400)
          const errorProfileDetailsPath = `${AppRoutePath.masterAccountDetails}/${MasterAccountDetailsType.Register.path}`;
          navigate(errorProfileDetailsPath);
        }
      })
      .catch((error) => {
        console.error("API error:", error);
        // Handle the error, e.g., display an error message
      });
  };

  const handleTotalAds = () => {
    setIsMasterTotalAds(true);
  };

  const handleTchats = async () => {
    setIsTChatLoading(true);
    getTchatUrl().then(async (res: any) => {
      if (res.result_code === 200) {
        // console.log("res.url", res.url);
        setTChatModelOpen(true);

        setTChatGenerateLink(res.url);
        setIsTChatLoading(false);
      }
    });
  };

  const handleTChatLanguage: React.ChangeEventHandler<
    HTMLSelectElement
  > = async (event) => {
    const value = event.target.value;
    setTChats(value);
    setTChatLanguages(value).then((res) => {});
  };
  return (
    <>
      <CustomNavigation
        isOpen={isNavOpen}
        onCallback={(value: boolean) => {
          setIsNavOpen(value);
        }}
        accountType={AccountTypeConst.Master}
      />
      {/* <header>
        <div className="title">{t("masterAcct3")}</div>
        <div>
          <button onClick={handleLanguageSwitch} className="menu-language">
            {language === "en" ? "中文" : "ENG"}{" "}

          </button>
        </div>
        <a href={AppRoutePath.masterSystemMessages} className="stn-btn">
          <i className="fa-regular fa-comment" />
          <i className="fa-solid fa-gear" />{" "}
          {notifCount > 0 && <span>{notifCount}</span>}
        </a>
        <a className="clk-btn" onClick={() => setIsNavOpen(!isNavOpen)}>
          <i className="fa-solid fa-bars"></i>
        </a>
      </header> */}

      <CustomHeader />
      <TopHearder title={t("masterAcct3")} />

      {isLoadingScreen ? (
        <ScreenLoaders />
      ) : (
        <>
          <div className="bg-dark">
            <div className="container">
              <div className="inner-box pt-4 pb-4">
                <div className="row g-0 mb-3 pb-3 rgd-mob">
                  <div className="col-6">
                    {/* <span>{t("registeredMobile")}</span> */}
                    <span>
                      {" "}
                      {masterProfile?.mobile !== null
                        ? t("registeredMobile")
                        : t("registeredEmail")}
                    </span>

                    {masterProfile?.mobile !== null ? (
                      <Link
                        to={
                          isMaster
                            ? AppRoutePath.masterChangeRegisterNumber
                            : AppRoutePath.clientChangeRegisterNumber
                        }
                      >
                        {t("changeRegisteredMobile")}
                      </Link>
                    ) : (
                      <Link
                        to={
                          isMaster
                            ? AppRoutePath.masterChangeRegisterEmail
                            : AppRoutePath.clientChangeRegisterEmail
                        }
                      >
                        {"changeRegisteredEmail"}
                      </Link>
                    )}
                  </div>
                  <div className="col-6 text-end">
                    {/* <span>+85 212 3456 7788</span> */}
                    {/* <span>+{masterProfile?.mobile}</span> */}
                    {masterProfile?.mobile !== null ? (
                      <span>+{masterProfile?.mobile}</span>
                    ) : (
                      <span>{masterProfile?.email}</span>
                    )}
                    <Link to={AppRoutePath.masterChangePassword}>
                      {t("changePassword")}
                    </Link>
                  </div>
                </div>
                <div className="master-account">
                  <div className="row align-items-end">
                    <div className="col-12">
                      <p>{shop?.en_name}</p>
                      <h6>{shop?.details.shop_zh_name}</h6>
                    </div>
                    <div className="col-8">
                      {shopBannerUrl ? (
                        <div className="master-img">
                          <img
                            src={shopBannerUrl}
                            className="img-fluid"
                            alt=""
                            width={257}
                            height={160}
                          />
                        </div>
                      ) : (
                        <div className="master-img">
                          <center>
                            <div
                              style={{
                                height: 100,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div>
                                <img
                                  src="/img/icon_photo.png"
                                  width={50}
                                  height={50}
                                  alt=""
                                />
                                <p>{t("noPhoto")}</p>
                              </div>
                            </div>
                          </center>
                        </div>
                      )}
                    </div>
                    <div className="col-4 text-end">
                      {/* <Link
										to={
											shop?.details.shop_zh_name === ""
												? `${AppRoutePath.masterAccountDetails}/${MasterAccountDetailsType.Register.path}`
												: `${AppRoutePath.masterAccountDetails}/${MasterAccountDetailsType.Edit.path}`
										}
									> */}
                      <button
                        className="btn primary-button rounded-button"
                        style={{ fontSize: 12 }}
                        onClick={handleProfileDetailsClick}
                      >
                        {/* {t("profileDetails")} */}
                        {t("editShop")}
                      </button>
                      {/* </Link> */}
                    </div>
                    <div className="col-12">
                      <h6>
                        #{followerCount} <span>{t("followers")}</span>
                      </h6>
                    </div>
                    <div className="col-12 d-flex justify-content-between mb-3">
                      <a href={AppRoutePath.masterFollowerList}>
                        <button className="btn primary-button rounded-button">
                          <i className="fa-solid fa-bars" /> {t("followerList")}
                        </button>
                      </a>
                      {isTChatLoading ? (
                        <>
                          <button className="btn primary-button rounded-button">
                            {" "}
                            <Spinner size="sm" />
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className="t-chat cursor-pointer py-2 bg-transparent"
                            onClick={() => handleTchats()}
                          >
                            <img
                              style={{ width: 20, marginRight: 5 }}
                              src="/img/tchat_newlogo.png"
                              className="default-logo"
                            />
                            {t("tchat")}
                            {/* <span>0</span> */}
                          </button>{" "}
                        </>
                      )}
                    </div>
                    {/* <div className="col-12 mt-3">
                      <a
                        className="t-chat cursor-pointer color-pink-2 py-2"
                        onClick={() => handleTchats()}
                      >
                        <img
                          style={{ width: 20, marginRight: 5 }}
                          src="/img/tchat_newlogo.png"
                          className="default-logo"
                        />
                 
                        {t("tchat")} <span>0</span>
                      </a>
                    </div> */}
                  </div>

                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <label htmlFor="" className="label">
                      {t("tchatLang")}
                    </label>

                    <div>
                      <select
                        // ref={typeCheck}
                        // disabled={true}

                        value={tChats}
                        className="form-select input-md w-100 "
                        id="typeSelectOption"
                        onChange={handleTChatLanguage}
                      >
                        <option value="" disabled selected>
                          Please select TChat Language
                        </option>

                        {Object.entries(tChatLang).map(([key, value]) => {
                          const abbr = value.abbr;
                          const name = value.name;
                          return (
                            <option value={abbr} key={key + 1}>
                              {name}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    {/* <Link
                  to={
                    isMaster
                      ? AppRoutePath.masterAddDetails
                      : AppRoutePath.clientAddDetails
                  }
                  className="his-btn"
                >
                  Change type
                </Link> */}
                  </div>

                  <div className="row">
                    <div className="col-8 mt-3">
                      <h6>{t("masterAcctBlog")}</h6>
                      <p className="mb-3">{t("masterAcctBlogInfo")}</p>
                    </div>

                    <div className="col-4 ">
                      {userLoginRole === "master" &&
                      masterType === "Master|Master中文" ? (
                        <>
                          <div
                            className="on-off-btn justify-content-end "
                            style={{ marginTop: 10 }}
                          >
                            <button
                              className={
                                blogStatus === 1
                                  ? "btn primary-button-active rounded-button"
                                  : "btn primary-button rounded-button btn3"
                              }
                              onClick={(e) => {
                                handleBlog(1);
                                // {
                                //   adList?.["item(s)"].map((ad, idx) => {
                                //     if (
                                //       ad.ad_status === 1 &&
                                //       ad.blog_status === 0
                                //     ) {
                                //       controlBlogClient(1, ad.user_id).then(
                                //         (isSuccess) => {
                                //           if (isSuccess) {
                                //             const resAdList = adList;
                                //             resAdList["item(s)"][
                                //               idx
                                //             ].blog_status = 1;
                                //             setAdList({ ...resAdList });
                                //           }
                                //         }
                                //       );
                                //     }
                                //   });
                                // }
                              }}
                            >
                              {t("ON")}
                            </button>
                            <button
                              className={
                                blogStatus === 0
                                  ? "btn primary-button rounded-button margin-left-10"
                                  : "btn primary-button rounded-button btn3 margin-left-10"
                              }
                              // onClick={(e) => handleBlog(0)}

                              onClick={(e) => {
                                handleBlog(0);
                              }}
                            >
                              {t("OFF")}
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          <div style={{ marginTop: 15, marginLeft: 25 }}>
                            <span
                              style={{
                                color: blogStatus === 1 ? "#35FD0D" : "grey",
                                fontWeight: 700,
                              }}
                            >
                              {blogStatus === 1 ? t("ON") : t("OFF")}
                            </span>
                          </div>
                        </>
                      )}
                    </div>

                    <div className="col-12">
                      <strong>{t("followerListInfo")}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="plan-wrap">
                <div className="account-box mb-5 mt-5 align-items-center">
                  <div className="account animated">
                    <div className="card mb-5 mb-lg-0 py-3 text-center">
                      <div className="card-body">
                        <OverlayTrigger
                          placement="top"
                          trigger="click"
                          // show={OneCoinShowPopover}
                          // onToggle={setOneCoinShowPopover}
                          show={activePopover === "oneCoinInfo"}
                          onToggle={() => handlePopoverToggle("oneCoinInfo")}
                          overlay={
                            <Popover>
                              <button
                                type="button"
                                className="close-popover"
                                onClick={() =>
                                  handlePopoverToggle("oneCoinInfo")
                                }
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>

                              <PopoverBody>{t("oneCoinInfo")}</PopoverBody>
                            </Popover>
                          }
                        >
                          <button type="button" className="exc-btn mx-2">
                            <i className="fa-solid fa-exclamation"></i>
                          </button>
                        </OverlayTrigger>
                        <img src="/img/onecoin_icon.png" alt="" />
                        <h5 className="card-title text-uppercase">
                          {t("oneCoinBal")}
                        </h5>
                        <div className="buy-btn-top">
                          <h6 className="card-type">{t("Bal")}</h6>
                          <h4>{oneCoinBalance}</h4>
                        </div>

                        {/* {masterTotalAds == 0 ? (
                      <>
                        <button
                          className="btn primary-button rounded-button abs-btn3 text-uppercase"
                          onClick={handleTotalAds}
                        >
                          <i className="fa fa-shopping-cart" /> {t("buy")}
                        </button>
                      </>
                    ) : ( */}
                        <>
                          {" "}
                          <Link to={AppRoutePath.masterWallet + "/one-coin"}>
                            <button className="text-10 primary-button rounded-button abs-btn3 text-uppercase">
                              <i className="fa fa-shopping-cart" /> {t("buy")}
                            </button>
                          </Link>
                        </>
                        {/* )} */}
                      </div>
                    </div>
                  </div>
                  <div className="account animated">
                    <div className="card mb-5 mb-lg-0 py-3 text-center">
                      <div className="card-body">
                        <OverlayTrigger
                          placement="top"
                          trigger="click"
                          show={activePopover === "flowerInfo"}
                          onToggle={() => handlePopoverToggle("flowerInfo")}
                          overlay={
                            <Popover>
                              <button
                                type="button"
                                className="close-popover"
                                onClick={() =>
                                  handlePopoverToggle("flowerInfo")
                                }
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>

                              <PopoverBody>{t("flowerInfo")}</PopoverBody>
                            </Popover>
                          }
                        >
                          <button type="button" className="exc-btn mx-2">
                            <i className="fa-solid fa-exclamation"></i>
                          </button>
                        </OverlayTrigger>
                        <img src="/img/flower_icon.png" alt="" />
                        <h5 className="card-title text-uppercase">
                          {t("flowerBal")}
                        </h5>
                        {/* <h6 className="card-type">{t("Bal")}</h6> */}
                        {/* <h4>{flowerBalance}</h4> */}
                        <div className="buy-btn-top"> </div>
                        {masterTotalAds == 0 ? (
                          <>
                            <Link to={AppRoutePath.masterWallet + "/flower"}>
                              <button className="text-10 primary-button rounded-button abs-btn3 text-uppercase select-account">
                                <i className="fa fa-eye" /> {t("view")}
                              </button>
                            </Link>
                          </>
                        ) : (
                          <Link
                            to={AppRoutePath.masterWallet + "/flower/reload"}
                          >
                            <button className="text-10 primary-button rounded-button abs-btn3 text-uppercase select-account">
                              <i className="fa fa-shopping-cart" /> {t("buySend")}
                            </button>
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="account animated">
                    <div className="card mb-5 mb-lg-0 py-3 text-center">
                      <div className="card-body">
                        <OverlayTrigger
                          placement="top"
                          trigger="click"
                          show={activePopover === "heartInfo"}
                          onToggle={() => handlePopoverToggle("heartInfo")}
                          overlay={
                            <Popover>
                              <button
                                type="button"
                                className="close-popover"
                                onClick={() => handlePopoverToggle("heartInfo")}
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>

                              <PopoverBody>{t("heartInfo")}</PopoverBody>
                            </Popover>
                          }
                        >
                          <button type="button" className="exc-btn mx-2">
                            <i className="fa-solid fa-exclamation"></i>
                          </button>
                        </OverlayTrigger>
                        <img src="/img/Heart@2x.png" alt="" />
                        <h5 className="card-title text-uppercase">
                          {t("heartBal")}
                        </h5>
                        {/* <h6 className="card-type">{t("Bal")}</h6> */}
                        <div className="buy-btn-top"> </div>
                        {masterTotalAds == 0 ? (
                          <>
                            <Link to={AppRoutePath.masterWallet + "/heart"}>
                              <button className="text-10 primary-button rounded-button abs-btn3 text-uppercase select-account">
                                <i className="fa fa-eye" /> {t("view")}
                              </button>
                            </Link>
                          </>
                        ) : (
                          <Link
                            to={AppRoutePath.masterWallet + "/heart/reload"}
                          >
                            <button className="text-10 primary-button rounded-button abs-btn3 text-uppercase select-account">
                              <i className="fa fa-shopping-cart" /> {t("buySend")}
                            </button>
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="plan-wrap">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <label htmlFor="" className="label">
                    {t("advertisement")}
                  </label>
                  <Link to="/master/add-details">
                    <button className="btn primary-button rounded-button">
                      {t("addNewClient")}
                    </button>
                  </Link>
                </div>
                <div className="d-flex justify-content-end">
                  <Link to={AppRoutePath.masterAdList}>
                    <button className="btn primary-button rounded-button">
                      {t("editClient")}
                    </button>
                  </Link>
                </div>
                <SizedBox height={20} />
              </div>
            </div>
          </div>
        </>
      )}

      {/* Ads total  */}

      <Modal show={isMasterTotalAds}>
        <Modal.Body>
          <div className="d-flex justify-content-center align-items-center">
            <Modal.Title>
              <h5 className="modal-title text-center">
                Please add at least One Ad for this Account
              </h5>
            </Modal.Title>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="col">
              <button
                className="button primary-button rounded-button w-100"
                onClick={() => {
                  setIsMasterTotalAds(false);
                }}
              >
                {t("ok")}
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Tchat  */}
      <Modal show={tChatModelOpen}>
        <Modal.Body>
          <div className="d-flex justify-content-center align-items-center">
            <Modal.Title>
              <div className="text-center pb-3">
                <img
                  style={{ width: 50, marginRight: 5 }}
                  src="/img/tchat_newlogo.png"
                  className="default-logo"
                />
              </div>
              <h5 className="modal-title text-center pb-3">
                {t("tchatModelMSG")}
              </h5>
            </Modal.Title>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-2 justify-content-center text-center">
            <button
              className=" button primary-button rounded-button px-5 "
              onClick={() => {
                setTChatModelOpen(false);
              }}
            >
              {t("no")}
            </button>

            <a
              href={tChatGenerateLink}
              target="_blank"
              rel="noreferrer"
              className="button primary-button rounded-button px-5"
              onClick={() => {
                setTChatModelOpen(false);
              }}
            >
              {t("ok")}
            </a>
          </div>
        </Modal.Footer>
      </Modal>

      {/* <TChatCustomModel  langType ={langType} link={tChatGenerateLink} IsTChatOpen={tChatModelOpen} /> */}
    </>
  );
}
