import React from "react";
import LoginPage from "./auth/Login";
import Register from "./auth/Register";
import YourAdPage from "./components/registration/steps/YourAd";
import IndividualAddDetailPage from "./components/registration/steps/IndividualAddDetail";
import EditIndividualAddDetailPage from "./components/registration/steps/EditIndividualAddDetail";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AccountType from "./components/AccountType";
import ForgetPassword from "./auth/ForgetPassword";
import LoadCoinEmpty from "./components/registration/steps/LoadCoinEmpty";
import LoadCoin from "./components/registration/steps/LoadCoin";
import AddDetails from "./components/registration/steps/AddDetails";
import EditProfile from "./components/registration/steps/EditProfile";
import PreviewNavPage from "./components/registration/steps/PreviewNav";
import ClientMainPage from "./components/client/ClientMainPage";
import ReloadCoin from "./components/registration/steps/ReloadCoin";
import ReloadCoinImageSelector from "./components/registration/steps/ReloadCoinImageSelector";
import ReloadCoinGallery from "./components/registration/steps/ReloadCoinGallery";
import ReloadCoinUploadProgress from "./components/registration/steps/ReloadCoinUploadProgress";
import ReloadCoinSuccessPage from "./components/registration/steps/ReloadCoinSuccessPage";
import ClientWalletPage from "./components/client/ClientWalletPage";
import BookingScanner from "./components/client/BookingScanner";
import AddNewPost from "./components/client/AddNewPost";
import MyInstantReviews from "./components/client/MyInstantReviews";
import InstantReviewDetail from "./components/client/InstantReviewDetail";
import AddInstantReviewPost from "./components/client/AddInstantReviewPost";
import ChangeRegisterNumber from "./components/client/ChangeNumber";
import ChangeRegisterEmail from "./components/client/ChangeRegisterEmail";
import ChangePassword from "./components/client/ChangePassword";
import ChangeAdsPassword from "./components/client/ChangeAdsPassword";
import MyInstantPostLists from "./components/client/MyInstantPostLists";
import MyInstantPostListsScheduled from "./components/client/MyInstantPostListsScheduled";
import MyInstantScheduledPostLists from "./components/client/MyInstantScheduledPostLists";
import AddFlowerPage from "./components/client/AddFlowerPage";
import ReloadFlowerPage from "./components/client/ReloadFlowerPage";
import FollowerListPage from "./components/client/FollowerListPage";
import InvitationListPage from "./components/client/InvitationListPage";
import ClientAddDetailsPage from "./components/client/ClientAddDetailsPage";
import MasterAdList from "./components/master/MasterAdList";
// import TestRoute from "./components/TestRoute";
import MasterAccount from "./components/master/MasterAccount";
import MasterAccountDetails from "./components/master/MasterAccountDetails";
import MasterAccountCreateShop from "./components/master/CreateMasterShop";
import SystemMessages from "./components/client/SystemMessages";
import AppRoutePath from "./constants/AppRoutePath";
import ClientValidatorRoute from "./components/validator/ClientValidatorRoute";
import MasterValidatorRoute from "./components/validator/MasterValidatorRoute";

import HistoryPage from "./components/client/HistoryPage";
import SystemMessageListPage from "./components/client/SystemMessageListPage";
import AddNewPostPreview from "./components/client/AddNewPostPreview";
import MemberProfilePage from "./components/client/MemberProfilePage";
import TChatList from "./components/client/TChatList";
import ClientTchats from "./components/client/TChats";
import TrainingPage from "./components/client/TrainingPage";
import ContactPage from "./components/client/ContactPage";
import ClientEditDetailsPage from "./components/client/ClientEditDetailsPage";
import ClientQRCodePages from "./components/client/ClientQRCodePages";
import SendOneCoin from "./components/client/SearchAndSendOneCoins";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path={AppRoutePath.login} element={<LoginPage />} />
          {/* <Route path={AppRoutePath.loginPage} element={<LoginPage />} /> */}
          <Route path={AppRoutePath.register} element={<Register />} />
          <Route path={AppRoutePath.accountType} element={<AccountType />} />
          <Route
            path={AppRoutePath.forgetPassword}
            element={<ForgetPassword />}
          />
          <Route
            path={AppRoutePath.loadCoinEmpty}
            element={<LoadCoinEmpty />}
          />

          <Route
            path={AppRoutePath.reloadFlower}
            element={<ReloadFlowerPage />}
          />
          <Route path={AppRoutePath.loadCoin} element={<LoadCoin />} />
          <Route path={AppRoutePath.reloadCoin} element={<ReloadCoin />} />
          <Route
            path={AppRoutePath.reloadCoinImageSelecter}
            element={<ReloadCoinImageSelector />}
          />
          <Route
            path={AppRoutePath.reloadCoinGallery}
            element={<ReloadCoinGallery />}
          />
          <Route
            path={AppRoutePath.reloadCoinUpload}
            element={<ReloadCoinUploadProgress />}
          />
          <Route
            path={AppRoutePath.reloadCoinSuccess}
            element={<ReloadCoinSuccessPage />}
          />
          <Route path={AppRoutePath.yourAd} element={<YourAdPage />} />

          <Route path="/" element={<ClientValidatorRoute />}>
            <Route
              path={AppRoutePath.clientIndividualAdDetails + "/:type?"}
              element={<IndividualAddDetailPage />}
            />
            <Route
              path={`${AppRoutePath.clientAddDetails}/:type?`}
              element={<AddDetails />}
            />
            <Route
              path={AppRoutePath.clientEditIndividualAdDetails + "/:type?"}
              element={<EditIndividualAddDetailPage />}
            />
            <Route
              path={`${AppRoutePath.clientEditProfile}/:type?`}
              element={<EditProfile />}
            />
            <Route
              path={`${AppRoutePath.clientMainPage}/:success?`}
              element={<ClientMainPage />}
            />
            <Route
              path={`${AppRoutePath.clientWallet}/:walletType?/:walletTransactionType?`}
              element={<ClientWalletPage />}
            />
            <Route
              path={AppRoutePath.clientReloadCoin}
              element={<ReloadCoin />}
            />
            <Route
              path={AppRoutePath.clientReloadCoinImageSelecter}
              element={<ReloadCoinImageSelector />}
            />
            <Route
              path={AppRoutePath.clientReloadCoinGallery}
              element={<ReloadCoinGallery />}
            />
            <Route
              path={AppRoutePath.clientReloadCoinUpload}
              element={<ReloadCoinUploadProgress />}
            />
            <Route
              path={AppRoutePath.clientReloadCoinSuccess}
              element={<ReloadCoinSuccessPage />}
            />
            <Route
              path={AppRoutePath.clientAddFlower}
              element={<AddFlowerPage />}
            />
            <Route
              path={AppRoutePath.clientFollowerList}
              element={<FollowerListPage />}
            />
            <Route
              path={AppRoutePath.clientInvitationList}
              element={<InvitationListPage />}
            />
            <Route
              path={AppRoutePath.clientChangeRegisterNumber}
              element={<ChangeRegisterNumber />}
            />
            <Route
              path={AppRoutePath.clientChangeRegisterEmail}
              element={<ChangeRegisterEmail />}
            />
            <Route
              path={AppRoutePath.clientChangePassword}
              element={<ChangePassword />}
            />
            <Route
              path="/client/booking-scanner"
              element={<BookingScanner />}
            />
            <Route
              path="/client/add-instant-post/:postId?"
              element={<AddNewPost />}
            />

            <Route
              path="/client/my-instant-reviews"
              element={<MyInstantReviews />}
            />
            {/* <Route
              path="/master/my-instant-reviews"
              element={<MyInstantReviews />}
            /> */}
            <Route
              path="/client/my-instant-reviews/:id"
              element={<InstantReviewDetail />}
            />
            <Route
              path="/client/booking-scanner"
              element={<BookingScanner />}
            />
            <Route path="/client/add-instant-post" element={<AddNewPost />} />

            <Route
              path={AppRoutePath.clientAddMyInstantReview}
              element={<AddInstantReviewPost />}
            />
            {/* <Route
              path={AppRoutePath.masterAddMyInstantReview}
              element={<AddInstantReviewPost />}
            /> */}

            <Route
              path={AppRoutePath.clientMyInstantReview}
              element={<MyInstantReviews />}
            />
            {/* <Route path='/client/add-instant-review' element={<AddNewInstantReview />} /> */}
            <Route
              path="/client/my-instant-reviews/:id"
              element={<InstantReviewDetail />}
            />
            <Route
              path={AppRoutePath.clientMyInstantPosts}
              element={<MyInstantPostLists />}
            />
            <Route
              path={AppRoutePath.clientMyIPScheduled}
              element={<MyInstantPostListsScheduled />}
            />
            <Route
              path={`${AppRoutePath.clientMyInstantPosts}/:id`}
              element={<AddNewPostPreview />}
            />
            <Route
              path={`${AppRoutePath.clientAddPage}/:type?`}
              element={<ClientAddDetailsPage />}
            />
            <Route
              path={`${AppRoutePath.clientEditPage}/:type?`}
              element={<ClientEditDetailsPage />}
            />
            <Route
              path={AppRoutePath.clientSystemMessages}
              element={<SystemMessageListPage />}
            />
            <Route
              path={`${AppRoutePath.clientSystemMessages}/:id`}
              element={<SystemMessages />}
            />
            {/* <Route
              path={`${AppRoutePath.clientMyInstantPosts}/scheduled`}
              element={<MyInstantPostLists />}
            /> */}
            <Route
              path={`${AppRoutePath.clientHistory}/:type/:id?`}
              element={<HistoryPage />}
            />
            <Route
              path={AppRoutePath.clientMemberProfile}
              element={<MemberProfilePage />}
            />
            <Route
              path={AppRoutePath.clientTchatList}
              element={<TChatList />}
            />
            <Route
              path={AppRoutePath.clientTchats}
              element={<ClientTchats />}
            />
            <Route
              path={AppRoutePath.clientTrainingPage}
              element={<TrainingPage />}
            />
            <Route
              path={AppRoutePath.clinetContactPage}
              element={<ContactPage />}
            />
            <Route
              path={AppRoutePath.clinetQRCodePage}
              element={<ClientQRCodePages />}
            />
            <Route
              path={AppRoutePath.clientSendOneCoin}
              element={<SendOneCoin />}
            />
          </Route>

          {/* Master Route */}

          <Route path="/" element={<MasterValidatorRoute />}>
            <Route
              path={`${AppRoutePath.masterIndividualAdDetails}`}
              element={<IndividualAddDetailPage />}
            />
            <Route
              path={`${AppRoutePath.masterEditIndividualAdDetails}`}
              element={<EditIndividualAddDetailPage />}
            />
            {/* <Route
              path={`${AppRoutePath.masterAddInstantPost}`}
              element={<AddNewPost />}
            /> */}

            <Route
              path={`${AppRoutePath.masterAddInstantPost}/:type?/:id?`}
              element={<AddNewPost />}
            />
            {/* <Route path="/master/add-instant-post" element={<AddNewPost />} /> */}
            <Route
              path={`${AppRoutePath.masterAddDetails}/:type?/:id?`}
              element={<AddDetails />}
            />
            <Route
              path={`${AppRoutePath.masterEditDetails}/:type?/:id?`}
              element={<EditProfile />}
            />
            {/* <Route
              path={`${AppRoutePath.masterAddInstantPost}`}
              element={<AddNewPost />}
            /> */}
            <Route
              path={`${AppRoutePath.masterAddInstantPost}/:id?`}
              element={<AddNewPost />}
            />
            <Route
              path={`${AppRoutePath.masterAddInstantPosts}/:userId?`}
              element={<AddNewPost />}
            />

            {/* <Route
              path={`${AppRoutePath.masterMyInstantPosts}/:userId?/scheduled`}
              element={<MyInstantPostLists />}
            /> */}
            <Route
              path={`${AppRoutePath.masterMyInstantPosts}/:userId?/scheduled`}
              element={<MyInstantPostListsScheduled />}
            />
            <Route
              path={`${AppRoutePath.masterMyInstantPostView}/:userId?/:id?`}
              element={<AddNewPostPreview />}
            />
            <Route
              path={AppRoutePath.masterAccount}
              element={<MasterAccount />}
            />
            {/* <Route
              path={`${AppRoutePath.masterAccountCreateShops}/edit`}
              element={<MasterAccountDetails />}
            /> */}
            <Route
              path={`${AppRoutePath.masterAccountDetails}/:type?`}
              element={<MasterAccountDetails />}
            />
            <Route
              path={`${AppRoutePath.masterAccountCreateShops}/create`}
              element={<MasterAccountCreateShop />}
            />
            {/* <Route
							path={`${AppRoutePath.masterAccount}/:walletType`}
							element={<ClientWalletPage />}
						/> */}
            <Route
              path={AppRoutePath.masterReloadCoin}
              element={<ReloadCoin />}
            />
            <Route
              path={AppRoutePath.masterReloadCoinImageSelecter}
              element={<ReloadCoinImageSelector />}
            />
            <Route
              path={AppRoutePath.masterReloadCoinGallery}
              element={<ReloadCoinGallery />}
            />
            <Route
              path={AppRoutePath.masterReloadCoinUpload}
              element={<ReloadCoinUploadProgress />}
            />
            <Route
              path={AppRoutePath.masterReloadCoinSuccess}
              element={<ReloadCoinSuccessPage />}
            />
            <Route
              path={`${AppRoutePath.masterWallet}/:walletType?/:walletTransactionType?`}
              element={<ClientWalletPage />}
            />
            <Route
              path={AppRoutePath.masterFollowerList}
              element={<FollowerListPage />}
            />
            <Route
              path={AppRoutePath.masterAdList}
              element={<MasterAdList />}
            />
            <Route
              path={AppRoutePath.masterChangeRegisterNumber}
              element={<ChangeRegisterNumber />}
            />
            <Route
              path={AppRoutePath.masterChangeRegisterEmail}
              element={<ChangeRegisterEmail />}
            />
            <Route
              path={`${AppRoutePath.masterChangePassword}`}
              element={<ChangePassword />}
            />
            <Route
              path={`${AppRoutePath.masterAdsChangePassword}/:id?`}
              element={<ChangeAdsPassword />}
            />
            <Route
              path={AppRoutePath.masterMyInstantPosts}
              element={<MyInstantPostLists />}
            />
            <Route
              path={`${AppRoutePath.masterMyInstantPosts}/:id?`}
              element={<MyInstantPostLists />}
            />
            <Route
              path={`${AppRoutePath.masterHistory}/:type/:id?`}
              element={<HistoryPage />}
            />
            <Route
              path={AppRoutePath.masterSystemMessages}
              element={<SystemMessageListPage />}
            />
            <Route
              path={`${AppRoutePath.masterSystemMessages}/:id`}
              element={<SystemMessages />}
            />
            <Route
              path={`${AppRoutePath.masterAddPage}/:type/:id?`}
              element={<ClientAddDetailsPage />}
            />
            <Route
              path={`${AppRoutePath.masterClientMainPage}/:success?`}
              element={<ClientMainPage />}
            />
            <Route
              path={`${AppRoutePath.masterMyInstantReview}/:id`}
              element={<MyInstantReviews />}
            />
            <Route
              path={`${AppRoutePath.masterMyInstantReview}/:id/:id`}
              element={<InstantReviewDetail />}
            />
            <Route
              path={AppRoutePath.masterMemberProfile}
              element={<MemberProfilePage />}
            />

            <Route
              path={AppRoutePath.masterTchatList}
              element={<TChatList />}
            />
            <Route
              path={AppRoutePath.masterTchats}
              element={<ClientTchats />}
            />
            <Route
              path={AppRoutePath.masterTrainingPage}
              element={<TrainingPage />}
            />
            <Route
              path={AppRoutePath.masterContactPage}
              element={<ContactPage />}
            />
            <Route
              path={AppRoutePath.masterSendOneCoin}
              element={<SendOneCoin />}
            />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
