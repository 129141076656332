import Snackbar from "awesome-snackbar";
import { ICreateUserParams } from "../domain/adapter/create-user-params-adapter";
import LocalStorageKey from "../constants/LocalStorageKey";
import AccountTypeConst from "../domain/enum/account-type";
import CreateUserParams from "../domain/model/create-user-params";
import axios from "./axios";
import { getClientProfile } from "./client/client-profile";
import { getProfileMaster } from "./master/master-account";

interface Params {
  verification_key: string;
  password: string;
  en_name: string;
  zh_name: string;
  userType: string;
  country_phone_code?: string;
  phone_number?: string;
  email?: string;
}
interface MasterParams {
  verification_key: string;
  password: string;
  en_name: string;
  zh_name: string;
  role: string;
  country_phone_code?: string;
  phone_number?: string;
  email?: string;
}


export const login = async (
  countryCode: string,
  user: string,
  password: string,
  role: number,
  email: string
) => {
  let params: any = {
    password: password,

  };


  if (user !== "") {
    params.country_code = countryCode;
    params.user = user;
    localStorage.setItem("email_login", "false");

  } else {
    params.email = email;
    localStorage.setItem("email_login", "true");
  }

  console.log("Login Params ", params);

  const { status, data } = await axios.post("/auth/login", params);
  const token = data["user_token"];
  const userId = data.user_id;

  const userRoleId = data.role_id;

  // console.log("userRoleId ", userRoleId);


  localStorage.setItem("token", token);
  localStorage.setItem("account_type", role.toString());
  localStorage.setItem("user_id", userId);



  const userParams = CreateUserParams.getInstance();

  userParams.loadData();
  userParams.data = {
    user_type: role,
  };

  console.log("Login data 1234 ", data);

  userParams.saveData();

  console.log("login userParams ", userParams);

  try {

    if (userRoleId !== 5) {


      switch (role) {
        case 1:
          const reqClient = await getClientProfile();
          if (!reqClient) {
            throw 1;
          } else {
            localStorage.setItem(
              LocalStorageKey.role,
              AccountTypeConst.Client.path
            );
          }
          break;
        case 2:
          const reqMaster = await getProfileMaster();
          if (!reqMaster) {
            throw 1;
          } else {
            localStorage.setItem(
              LocalStorageKey.role,
              AccountTypeConst.Master.path
            );
          }
          break;
      }
    } else {
      new Snackbar("This is not either a Client or Master Account!!!");

      return false;
    }
  } catch (e) {
    console.log(e);


    new Snackbar("Please select a correct role (client/master)");

    return false;
  }

  if (status === 200) {
    localStorage.setItem(LocalStorageKey.isLogin, String(true));
    return true;
  }
  return false;
};

export const createUser = async (params: ICreateUserParams) => {
  const response = await axios
    .post("/user", params.data, {
      headers: {
        "X-Verify-Key": localStorage.getItem("vkey"),
      },
    })
    .then((respons) => {
      console.log(respons);
      console.log(`token: ${localStorage.getItem("token")}`);
      return true;
    })
    .catch((error) => {
      try {
        console.log(JSON.stringify(error));
        new Snackbar(error.error.reason);
      } catch (error) {
        console.log(error);
        new Snackbar("Failed to update detail");
      }
      return false;
    });

  return response;
};

export const updateUser = async (params: ICreateUserParams) => {
  const response = await axios
    .put("/user", params.data)
    .then((respons) => {
      console.log(respons);
      console.log(`token: ${localStorage.getItem("token")}`);
      return true;
    })
    .catch((error) => {
      try {
        console.log(JSON.stringify(error));
        new Snackbar("ERROR: " + (error.error.reason ?? error.error.message));
      } catch (error) {
        console.log(error);
        new Snackbar("Failed to update profile");
      }
      return false;
    });

  return response;
};

export const logout = async () => {
  const {
    data: { message },
  } = await axios.post("/auth/logout");

  console.log("logout", message);
};

export const registerClient = async (
  verificationKey: string,
  password: string,
  countryPhoneCode: string,
  phoneNumber: string,
  en_name: string,
  zhName: string,
  userType: string,
  // telegram: string
  email: string
) => {
  let params: Params = {
    verification_key: verificationKey,
    password: password,
    country_phone_code: countryPhoneCode,
    phone_number: phoneNumber,
    en_name: en_name,
    zh_name: zhName,
    userType: userType,
    email: email,
  };

  if (phoneNumber !== "") {
    params.country_phone_code = countryPhoneCode;
    params.phone_number = phoneNumber;
    localStorage.setItem("email_login", "false");
  } else {
    params.email = email;
    localStorage.setItem("email_login", "true");
  }
  // const params = {
  //   verification_key: verificationKey,
  //   password: password,
  //   country_phone_code: countryPhoneCode,
  //   phone_number: phoneNumber,
  //   en_name: en_name,
  //   zh_name: zhName,
  //   userType: userType,
  //   email: email
  // };
  console.log("Client registration: " + JSON.stringify(params));

  const isSuccess = await axios
    .post("/client/register", params)
    .then(async (respons) => {
      console.log("Client respons : " + JSON.stringify(respons));
      localStorage.setItem("token", respons.data["user_token"]);
      localStorage.setItem("user_id", respons.data["user_id"]);
      localStorage.setItem(LocalStorageKey.isLogin, "true");
      localStorage.setItem(LocalStorageKey.isRegister, "true");
      localStorage.setItem(LocalStorageKey.role, AccountTypeConst.Client.path);
      console.log(`token: ${localStorage.getItem("token")}`);

      return true;
    })
    .catch((error) => {
      try {
        console.log(JSON.stringify(error));
        new Snackbar(
          "Mobile number already exists, please login to your account."
        );
      } catch (error) {
        console.log(error);
        new Snackbar("Failed to create client account");
      }
      return false;
    });

  // addAdditionalInfo(`${localStorage.getItem("token")}`);

  return isSuccess;
};

export const registerMaster = async (
  verificationKey: string,
  password: string,
  countryPhoneCode: string,
  phoneNumber: string,
  en_name: string,
  zhName: string,
  role: string,
  email: string,
) => {
  // console.log("userRole d", user_role);
  console.table({
    verificationKey: verificationKey,
    password: password,
    countryPhoneCode: countryPhoneCode,
    phoneNumber: phoneNumber,
    en_name: en_name,
    zhName: zhName,
    role: role,
    email: email,
  });



  let params: MasterParams = {
    verification_key: verificationKey,
    password: password,
    country_phone_code: countryPhoneCode,
    phone_number: phoneNumber,
    en_name: en_name,
    zh_name: zhName,
    role: role,
    email: email,
  };
  if (phoneNumber !== "") {
    params.country_phone_code = countryPhoneCode;
    params.phone_number = phoneNumber;
    localStorage.setItem("email_login", "false");
  } else {
    params.email = email;
    localStorage.setItem("email_login", "true");
  }

  console.log("Master registration 12345: " + JSON.stringify(params));
  const isSuccess = await axios
    .post("/user/master/register", params)
    .then((respons) => {
      console.log("Master account respons", respons);

      localStorage.setItem("token", respons.data["user_token"]);
      localStorage.setItem(LocalStorageKey.isLogin, "true");
      localStorage.setItem(LocalStorageKey.isRegister, "true");
      localStorage.setItem(LocalStorageKey.role, AccountTypeConst.Master.path);
      console.log(`token: ${localStorage.getItem("token")}`);
      // addAdditionalInfo(`${localStorage.getItem("token")}`);
      return true;
    })
    .catch((error) => {
      console.log(JSON.stringify(error));
      try {
        console.log(JSON.stringify(error));
        new Snackbar(
          "Mobile number already exists, please login to your account."
        );
      } catch (error) {
        console.log(error);
        new Snackbar("Failed to create master account");
      }
      return false;
    });

  return isSuccess;
};

// export const addAdditionalInfo = async (token: any) => {
//   const params = {
//     details: {
//       telegram: "mnbfasdnfsd",
//     }

//   };
//   const headers = {
//     'Authorization': `Bearer ${token}`
//   };

//   const isSuccess = await axios
//     .post("/client/int/detail/add", params, { headers })
//     .then((respons) => {
//       console.log("Additional Info Client respons", respons);
//       return true;
//     })
//     .catch((error) => {
//       console.log(JSON.stringify(error));

//       return false;
//     });

//   return isSuccess;
// };

export const forgetPassword = async (data: any) => {
  const res = await axios
    .post("/user/forgot/password", data)
    .then((respons) => {
      console.log(respons);
      return respons.data;
    })
    .catch((error) => {
      try {
        console.log(JSON.stringify(error));
        new Snackbar("" + (error.error.reason ?? error.error.message), {
          position: "bottom-center",
        });
      } catch (error) {
        console.log(error);
        new Snackbar("Failed to change password");
      }
      return false;
    });

  return res;
};

export const getOTP = async (data: any) => {
  // console.log("ggggh", data);

  // if (data.countryPhoneCode !== undefined && data.phoneNumber !== undefined) {
  //   params = {
  //     country_phone_code: data.countryPhoneCode,
  //     phone_number: data.phoneNumber,

  //   }

  // } else {
  //   params = {

  //     email: data.email
  //   }
  // }

  // console.log("gfh", params);

  const prefix = await axios
    .post("/sendVerificationCode", data)
    .then((respons) => {
      console.log(respons);
      return respons.data.prefix as string;
    })
    .catch((error) => {
      try {
        console.log(JSON.stringify(error));
        new Snackbar("ERROR: " + (error.error.reason ?? error.error.message));
      } catch (error) {
        console.log(error);
        new Snackbar("Failed to get verification code");
      }
      return null;
    });

  return prefix;
};
export const getOTPUserLogin = async (data: any) => {

  const prefix = await axios
    .post("/sendVerificationCodeLogin", data)
    .then((respons) => {
      console.log(respons);
      return respons.data.prefix as string;
    })
    .catch((error) => {
      try {
        console.log(JSON.stringify(error));
        new Snackbar("ERROR: " + (error.error.reason ?? error.error.message));
      } catch (error) {
        console.log(error);
        new Snackbar("Failed to get verification code");
      }
      return null;
    });

  return prefix;
};

export const getUserOTP = async (
  countryPhoneCode: string,
  phoneNumber: string
) => {
  const params = {
    country_phone_code: countryPhoneCode,
    phone_number: phoneNumber,
  };

  const prefix = await axios
    .post("/sendVerifiedUserVCode", params)
    .then((respons) => {
      console.log(respons);
      return respons.data.prefix as string;
    })
    .catch((error) => {
      try {
        console.log(JSON.stringify(error));
        new Snackbar("ERROR: " + (error.error.reason ?? error.error.message));
      } catch (error) {
        console.log(error);
        new Snackbar("Failed to get verification code");
      }
      return null;
    });

  return prefix;
};

export const verifyOTP = async (
  // countryPhoneCode: string,
  // phoneNumber: string,
  // verificationCode: string

  data: any
) => {
  // const params = {
  //   country_phone_code: countryPhoneCode,
  //   phone_number: phoneNumber,
  //   verification_code: verificationCode,
  // };

  const isSuccess = await axios
    .post("/verifyRegCode", data)
    .then((respons) => {
      console.log(respons);
      return respons.data.verification_key as string;
      // const createUserParams = CreateUserParams.getInstance();
      // createUserParams.data.verification_key = respons.data.verification_key;
      // createUserParams.saveData();``
      // return true;
    })
    .catch((error) => {
      try {
        console.log(JSON.stringify(error));
        new Snackbar("ERROR: " + (error.error.reason ?? error.error.message));
      } catch (error) {
        console.log(error);
        new Snackbar("Failed to verify code");
      }
      return null;
    });

  return isSuccess;
};

export const changePassword = async (oldPassword: string, password: string) => {
  const params = {
    old_password: oldPassword,
    new_password: password,
  };
  const { status, data } = await axios.post("/user/change/password", params);

  console.log("change password", data);

  if (status === 200) {
    return data;
  }
  return false;
};

export const changeMSISDN = async (mobile: string, vcode: string) => {
  const params = {
    mobile: mobile,
    vcode: vcode,
  };


  try {
    const data = await axios.post("/user/change/mobile", params);
    return data;
  } catch (error: any) {
    console.log(error.response || error || "An unknown error occurred.");
    return error
  }

};
export const changeRegisteredEmails = async (email: string, vcode: string) => {
  const params = {
    email: email,
    vcode: vcode,
    // new_pwd: new_pwd
  };


  try {
    const data = await axios.post("/user/changeEmail", params);

    return data;
  } catch (error: any) {
    console.log(error.response || error || "An unknown error occurred.");
    return error
  }
};
