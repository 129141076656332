// Loader.js

import React from "react";

const ActionLoader = ({ isHasTimer, second, ishasInfoText }: any) => {
  return (
    <>
      <div className={` ${isHasTimer ? "loader-backdrop-13":"loader-backdrop-12" } `}></div>;
      <div className="loader-container-2 px-0">
{ishasInfoText && (

  <p className="text-center text-16">
        Please wait and do not close this tab,  browser while we're validating the transaction...
        </p>
)}
      
       {isHasTimer && (<h4 className="primary-color">{second}</h4>)}
      
      </div>
    </>
  );
};

export default ActionLoader;
