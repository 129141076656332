import axios from "./axios";
import Snackbar from "awesome-snackbar";
import axiosGuest from "./axiosGuest";

const abortController = new AbortController();
const { signal } = abortController;



export const getGuestToken = async () => {
  const res = await axios.get("/auth/guest")
    .then((respons) => {
      // console.log("Type Respons", respons);

      return respons.data
    })
    .catch((error) => {
      new Snackbar(error.error.reason);
      return [];
    });

  return res;
}
export const getTypes = async (): Promise<string[]> => {
  const res = await axios.get("/modules/types")
    .then((respons) => {
      return respons.data as string[];
    })
    .catch((error) => {
      new Snackbar(error.error.reason);
      return [];
    });

  return res;
}
export const getTypesDefault = async () => {
  const res = await axiosGuest.get("/modules/types")
    .then((respons) => {
      return respons.data
    })
    .catch((error) => {
      new Snackbar(error.error.reason);
      return [];
    });

  return res;
}
export const getAllRolesDefault = async (type?: any) => {
  const res = await axios.get(`/modules/roleType?search=${type}`)
    .then((respons) => {
      return respons.data
    })
    .catch((error) => {
      new Snackbar(error.error.reason);
      return [];
    });

  return res;
}

export const getAllRoles = async () => {


  const res = await axiosGuest.get(`/modules/roles`)
    .then((respons) => {
      // console.log("Type Respons", respons);

      return respons.data
    })
    .catch((error) => {
      new Snackbar(error.error.reason);
      return [];
    });

  return res;
}

export const getServiceType = async (type: string, indexType?: boolean) => {


  const res = await axios.get(`/modules/service-type?search=${type}&type_index=${indexType}`)
    .then((respons) => {
      // const data = respons.data.filter((e: any) => Object.values(e)[0]);
      // console.log("ff respons.data ", respons.data);
      return respons.data
      // return data.map((e: any) => Object.keys(e)[0].toString());
    })
    .catch((error) => {
      new Snackbar(error.error.reason);
      return [];
    });

  return res;
}

export const getService = async (type: object) => {
  const res = await axios.get(`/modules/service?search=${type}`)
    .then((respons) => {
      // return respons.data as object[];
      return respons.data
    })
    .catch((error) => {
      new Snackbar(error.error.reason);
      return null;
    });
  return res;
}

export const getNationality = async (type: string) => {
  const res = await axios.get(`/modules/nationality?search=${type}`)
    .then((respons) => {
      return respons.data as string[];
    })
    .catch((error) => {
      new Snackbar(error.error.reason);
      return [];
    });
  return res;
}

export const getCountry = async (type: string) => {
  const res = await axios.get(`/modules/country?search=${type}`)
    .then((respons) => {
      // console.log("GeCountry ", respons.data);
      return respons.data;
    })
    .catch((error) => {
      new Snackbar(error.error.reason);
      return [];
    });
  return res;
}

export const getCurrencies = async (type: string) => {
  // const res = await axios.get(`/modules/currency?search=${type}`)
  const res = await axios.get(`/modules/currency`)
    .then((respons) => {
      // console.log("getCurrencies ", respons.data);

      return respons.data;
    })
    .catch((error) => {
      new Snackbar(error.error.reason);
      return [];
    });
  return res;
}

export const getLanguages = async () => {
  const res = await axios.get(`/modules/language`)
    .then((respons) => {
      // console.log(respons);
      return respons.data;
    })
    .catch((error) => {
      new Snackbar(error.error.reason);
      return [];
    });
  return res;
}

export const getRegion1s = async (country: string) => {
  const res = await axios.get(`/modules/region1?search=${country}`)
    .then((respons) => {
      // console.log("All region Data based on country ", respons.data);
      return respons.data;
    })
    .catch((error) => {
      new Snackbar(error.error.reason);
      return [];
    });
  return res;
}

export const getRegion2s = async (region: string) => {
  // console.log("ll region 2  Data based on Region 1 ", region);

  const res = await axios.get(`/modules/region2?search=${region}`)
    .then((respons) => {
      return respons.data;
    })
    .catch((error) => {
      new Snackbar(error.error.reason);
      return [];
    });
  return res;
}

export const getPromotion = async (promotionType?: string) => {
  const res = await axios
    .get(`/modules/promotion?search=${promotionType}`)
    .then((respons) => {
      // console.log(respons);
      return respons.data;
    })
    .catch((error) => {
      try {
        // console.log(JSON.stringify(error));
        new Snackbar(`ERROR: ${error.error.reason ?? error.error.message}`);
      } catch (error) {
        // console.log(error);
        new Snackbar("Failed to get Ad list");
      }
      return null;
    });

  return res;
}

export const getSpecialCatalogue = async (type?: string) => {

  // console.log("getSpecialCatalogue", type);

  const res = await axios
    .get(`/modules/special-cat?search=${type}`)
    .then((respons) => {
      // console.log("/modules/special-cat?search ", respons.data);
      // if (Array.isArray(respons.data)) {
      //   return respons.data.map((d) => Object.values(d)[0] as string);
      // } else if (typeof respons.data === 'string') {
      //   return [respons.data];
      // } else {
      //   return null;
      // }

      return respons.data
    })
    .catch((error) => {
      try {
        // console.log(JSON.stringify(error));
        new Snackbar(`ERROR: ${error.error.reason ?? error.error.message}`);
      } catch (error) {
        // console.log(error);
        new Snackbar("Failed to get special catalogue");
      }
      return null;
    });

  return res;
}

export const getClientPrice = async (type?: string) => {
  const res = await axios
    .get(`/modules/client-price?search=${type}`)
    .then((respons) => {
      // console.log(respons);
      return respons.data;
    })
    .catch((error) => {
      try {
        console.log(JSON.stringify(error));
        new Snackbar(`ERROR: ${error.error.reason ?? error.error.message}`);
      } catch (error) {
        console.log(error);
        new Snackbar("Failed to get Client Price ");
      }
      return null;
    });

  return res;
}

export const getRemarksDefault = async () => {
  const res = await axiosGuest.get("/modules/remarks")
    .then((respons) => {
      // console.log("Remarks", respons);

      return respons.data
    })
    .catch((error) => {
      new Snackbar(error.error.reason);
      return [];
    });

  return res;
}

export const getCheckVerificationMethod = async (data?: any) => {


  console.log("getCheckVerificationMethod ", data);

  const res = await axios.post(`/auth/check`, data)
    .then((respons) => {
      return respons.data
    })
    .catch((error) => {
      new Snackbar(error.error.reason);
      return [];
    });

  return res;
}


export const getAllProductsDefault = async (type?: any) => {
  const res = await axios.get(`/modules/products?search=${type}`)
    .then((respons) => {
      return respons.data
    })
    .catch((error) => {
      new Snackbar(error.error.reason);
      return [];
    });

  return res;
}
export const getSelectedProductsIds = async (type?: any) => {
  const res = await axios.get(`/modules/productId?search=${type}`)
    .then((respons) => {
      return respons.data
    })
    .catch((error) => {
      new Snackbar(error.error.reason);
      return [];
    });

  return res;
}



export const getTypeDisplayGroup = async (type: string,) => {


  const res = await axios.get(`/modules/Types_DisplayGroups?search=${type}`)
    .then((respons) => {
      // console.log("getTypeDisplayGroup ", respons);

      return respons.data

    })
    .catch((error) => {
      new Snackbar(error.error.reason);
      return [];
    });

  return res;
}
export const getTypeFeature = async (type: string) => {
  try {
    const res = await axios.get(`/modules/feature?search=${type}`);
    // console.log("getTypeFeature type ", res);

    const typeFeature = res.data;

    // Check if BLOG property exists in the typeFeature object
    if ('BLOG' in typeFeature) {
      return typeFeature;
    } else {
      console.error('BLOG property not found in typeFeature');
      return null;
    }
  } catch (error) {
    console.error('Error fetching type feature:', error);
    // new Snackbar(error.error.reason);
    return null;
  }
};
export const getAllTypeFeature = async () => {


  const res = await axios.get(`/modules/feature`)
    .then((respons) => {
      // console.log("getAllTypeFeature ", respons);

      return respons.data;
    })
    .catch((error) => {
      new Snackbar(error.error.reason);
      return [];
    });
  return res;
};

export const getFeature = async (type: string) => {
  try {
    const res = await axios.get(`/modules/feature?search=${type}`);
    // console.log("Feature type ", res);

    const typeFeature = res.data;

    // Check if BLOG property exists directly in the typeFeature object
    if ('BLOG' in typeFeature) {
      return typeFeature.BLOG;
    } else {
      // If 'BLOG' is nested, attempt to access it
      const nestedBlog = typeFeature[type]?.BLOG;

      if (nestedBlog !== undefined) {
        return nestedBlog;
      } else {
        console.error('BLOG property not found in typeFeature');
        return null;
      }
    }
  } catch (error) {
    console.error('Error fetching type feature:', error);
    // new Snackbar(error.error.reason);
    return null;
  }
};


export const getMasterTypeFeature = async (type: string,) => {


  const res = await axios.get(`/modules/master_types_feature?search=${type}`)
    .then((respons) => {
      // console.log("Master Feature type ", respons);

      return respons.data

    })
    .catch((error) => {
      new Snackbar(error.error.reason);
      return [];
    });

  return res;
}
export const getAllStickerIds = async () => {


  const res = await axios.get(`/modules/freetools/sticker_ids`)
    .then((respons) => {

      return respons.data

    })
    .catch((error) => {
      new Snackbar(error.error.reason);
      return [];
    });

  return res;
}


export const purchaseAllStickers = async (prod_id: number) => {

console.log("GGgh", prod_id);

  const res = await axios.get(`product/sticker/purchase`, { params: prod_id })
    .then((respons) => {

      return respons.data

    })
    .catch((error) => {
      new Snackbar(error.error.reason);
      return [];
    });

  return res;
}

