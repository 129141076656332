import Snackbar from "awesome-snackbar";
import axios from "../axios";
import { InvitaionParams, InvitationListModel } from "../../domain/model/invitation-model";




export const sendInvitaion = async (params: InvitaionParams) => {
	const res = await axios.post("invitation/send", params)
		.then((respons) => {
			console.log(respons);

			if (respons.data.result_code === 930) {
				new Snackbar(`${respons.data.message}`);
			}
			if (respons.data.result_code === 200) {
				new Snackbar(`Invitation successfully end to ${params.target} `);
			}
			return true;
		})
		.catch((error) => {
			try {
				console.log(JSON.stringify(error));
				new Snackbar(`ERROR: ${error.error.reason ?? error.error.message}`);
			} catch (error) {
				console.log(error);

			}
			return false;
		});

	return res;
};

export const getInvitationList = async (
	params?: any
) => {




	const res = await axios
		.post(`/invitation/list`, params)
		.then((respons) => {

			return respons.data as InvitationListModel;
		})
		.catch((error) => {
			try {
				console.log(JSON.stringify(error));
				// new Snackbar(`Error: ${error.error.reason ?? error.error.message}`);
			} catch (error) {
				console.log(error);
				new Snackbar("Failed to get follower");
			}
			return null;
		});

	return res;
};


