import { CreateUserParamsModel, OperatingHour } from "../domain/model/create-user-params-model";
import { AdsMasterModel, OHVal } from "../domain/model/ads-master-model";
import { ClientProfileModel } from "../domain/model/client-profile-model";

// Deprecated

export const convertMastersAddToClientProfile = (data: AdsMasterModel) => {
	const [countryCodeMaster, phoneNumberMaster] = splitCountryCodeAndMobileNumber(data.mobile?.split('EXT')[0] ?? "");
	const dataRes: ClientProfileModel = {
		ad: data.ad,
		blog: data.blog,
		created: '',
		details: {
			ad_images: data.details?.ad_images,
			address: {
				country: data.details?.Country,
				region1: data.details?.Region1,
				region2: data.details?.Region2,
				address_street: data.details?.AddressStress ?? '',
				block: data.details?.Block,
				building: data.details?.Building ?? '',
				description: data.details?.Description,
				floorC: data.details?.FloorC,
				floorE: data.details?.FloorE,
				room: data.details?.Room,
				street: data.details?.StreetName,
			},
			age: data.details?.Age,
			body_dimension: [],
			contact_info: {
				book_in_advance: data.details?.BookingInAdvance ?? false,
				call_to_open_door: data.details?.CallToOpenDoor ?? false,
				need_caller_display: data.details?.NeedCaller ?? false,
				phone_number: phoneNumberMaster,
				phone_number_country_code: countryCodeMaster,
			},
			currency: '',
			nationality: data.details?.Nationality,
			operating_hours: data.details?.OperatingHour ? convertOHValToOperatingHour(data.details?.OperatingHour!) : undefined,
			price: data.details?.price,
			real_images: data.details?.real_images,
			street_image: data.details?.street_image,
			self_description: data.details?.SelfDescription,
			services: data.details?.Service,
			type: data.details?.type,
			welcome_foreigner: data.details?.Foreigner,
			voice_memo: data.details?.voice_memo,
			welcome_pd: data.details?.PD,
		},
		language: data.language,
		message: '',
		mobile: data.mobile?.split('EXT')[0] ?? "",
		modified: '',
		nickname: data.nickname,
		photo: null,
		result_code: 0,
		rotate: false,
		status: 0,
		zh_name: data.zh_name,
	};
	return dataRes;
}

// CONVERT OPERATING HOUR OBJECT IN CLIENT TO SUPPORTED OPERATING HOUR AD'S MASTER OBJECT
export const convertCreateUserParamsToMastersAd = (data: CreateUserParamsModel, ext: string, phoneMaster: string) => {
	const [countryCodeMaster, phoneNumberMaster] = splitCountryCodeAndMobileNumber(phoneMaster);
	const dataRes: AdsMasterModel = {
		details: {
			Nationality: data.details?.nationality ?? "",
			Age: data.details?.age ?? 0,
			Service: data.details?.services ?? [],
			OperatingHour: convertOperatingHourToOHVal(data.details?.operating_hours ?? []),
			// Country: data.details?.address?.country ?? "",
			// Region1: data.details?.address?.region1 ?? "",
			// Region2: data.details?.address?.region2 ?? "",
			StreetName: data.details?.address?.street ?? "",
			AddressStress: data.details?.address?.address_street ?? "",
			Building: data.details?.address?.building ?? "",
			Room: data.details?.address?.room ?? "",
			FloorC: data.details?.address?.floorC ?? "",
			Block: data.details?.address?.block ?? "",
			FloorE: data.details?.address?.floorE ?? "",
			Description: data.details?.address?.description ?? "",
			// Phone: "",
			NeedCaller: data.details?.contact_info?.need_caller_display,
			BookingInAdvance: data.details?.contact_info?.book_in_advance,
			CallToOpenDoor: data.details?.contact_info?.call_to_open_door,
			SelfDescription: data.details?.self_description ?? "",
			Foreigner: data.details?.welcome_foreigner ?? false,
			PD: data.details?.welcome_pd ?? false,

			// NEW
			ad_images: data.details?.ad_images ?? { img_path: [], video: { fullpath: "", thn_path: "" } },
			type: data.details?.type ?? "",
			price: data.details?.price ?? 0,
			voice_memo: data.details?.voice_memo ?? [],
			real_images: data.details?.real_images ?? [],
			street_image: data.details?.street_image ?? [],

			// PHONE FOR CLIENT
			country_phone_code: data.country_phone_code,
			phone_number: data.phone_number,
		},
		language: data.language,
		nickname: data.nickname ?? "",
		photo: data.photo ?? "",
		zh_name: data.zh_name ?? "",

		// mobile: phoneMaster,

		// NEW
		password: data.password ?? "",
		country_phone_code: countryCodeMaster,
		phone_number: phoneNumberMaster,
		ext: ext
	}
	return dataRes;
}

// CONVERT OPERATING HOUR OBJECT IN AD'S MASTER TO SUPPORTED OPERATING HOUR CLIENT OBJECT
export const convertOperatingHourToOHVal = (opHour: OperatingHour[]) => {
	const timeVal = [
		"Monday",
		"Tuesday",
		"Wednesday",
		"Thursday",
		"Friday",
		"Saturday",
		"Sunday",
	].map((d) => {
		const ohTemp = opHour.find((oh) => oh.day === d);
		if (ohTemp?.rest ?? true) {
			return [];
		} else {
			return [ohTemp!.open_hours, ohTemp!.close_hours] as string[];
		}
	});

	const ohVal: OHVal = {
		Monday: timeVal[0],
		Tuesday: timeVal[1],
		Wednesday: timeVal[2],
		Thursday: timeVal[3],
		Friday: timeVal[4],
		Saturday: timeVal[5],
		Sunday: timeVal[6],
	};

	return ohVal;
};

export const convertOHValToOperatingHour = (ohval: OHVal) => {
	const ohValTemp: OHVal = {
		Monday: ohval.Monday ?? [],
		Tuesday: ohval.Tuesday ?? [],
		Wednesday: ohval.Wednesday ?? [],
		Thursday: ohval.Thursday ?? [],
		Friday: ohval.Friday ?? [],
		Saturday: ohval.Saturday ?? [],
		Sunday: ohval.Sunday ?? [],
	}
	return [
		{
			day: "Monday",
			open_hours: ohValTemp.Monday!.length !== 2 ? null : ohValTemp.Monday![0],
			close_hours: ohValTemp.Monday!.length !== 2 ? null : ohValTemp.Monday![1],
			rest: ohValTemp.Monday!.length !== 2,
		},
		{
			day: "Tuesday",
			open_hours: ohValTemp.Tuesday!.length !== 2 ? null : ohValTemp.Tuesday![0],
			close_hours: ohValTemp.Tuesday!.length !== 2 ? null : ohValTemp.Tuesday![1],
			rest: ohValTemp.Tuesday!.length !== 2,
		},
		{
			day: "Wednesday",
			open_hours: ohValTemp.Wednesday!.length !== 2 ? null : ohValTemp.Wednesday![0],
			close_hours: ohValTemp.Wednesday!.length !== 2 ? null : ohValTemp.Wednesday![1],
			rest: ohValTemp.Wednesday!.length !== 2,
		},
		{
			day: "Thursday",
			open_hours: ohValTemp.Thursday!.length !== 2 ? null : ohValTemp.Thursday![0],
			close_hours: ohValTemp.Thursday!.length !== 2 ? null : ohValTemp.Thursday![1],
			rest: ohValTemp.Thursday!.length !== 2,
		},
		{
			day: "Friday",
			open_hours: ohValTemp.Friday!.length !== 2 ? null : ohValTemp.Friday![0],
			close_hours: ohValTemp.Friday!.length !== 2 ? null : ohValTemp.Friday![1],
			rest: ohValTemp.Friday!.length !== 2,
		},
		{
			day: "Saturday",
			open_hours: ohValTemp.Saturday!.length !== 2 ? null : ohValTemp.Saturday![0],
			close_hours: ohValTemp.Saturday!.length !== 2 ? null : ohValTemp.Saturday![1],
			rest: ohValTemp.Saturday!.length !== 2,
		},
		// TODO: TEST
		{
			day: "Sunday",
			open_hours: ohValTemp.Sunday!.length !== 2 ? null : ohValTemp.Sunday![0],
			close_hours: ohValTemp.Sunday!.length !== 2 ? null : ohValTemp.Sunday![1],
			rest: ohValTemp.Sunday!.length !== 2,
		},
	] as OperatingHour[];
};

export const splitCountryCodeAndMobileNumber = (str: string) => {
	const listCountryCode = ["852", "853", "91", "60", "62"];
	var countryCodeMaster = '';
	for (let i = 0; i < listCountryCode.length; i++) {
		const cc = listCountryCode[i];
		if (cc === str.slice(0, cc.length)) {
			countryCodeMaster = cc;
			break;
		}
	}
	const phoneNumberMaster = str.slice(countryCodeMaster.length);
	return [countryCodeMaster, phoneNumberMaster]
}