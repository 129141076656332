import Snackbar from "awesome-snackbar";
import { FollowerListModel, IFollowUserParams, IStarUserParams } from "../../domain/model/follower-model";
import axios from "../axios";

// NOTE:
// isBlock values: 0 = unblock; 1 = block
export const blockUnblockFollower = async (fansId: number, isBlock: number) => {
	const res = await axios.post("client/follow/block", {
		fans: fansId,
		blockControl: isBlock,
	})
		.then((respons) => {
			console.log(respons);
			return true;
		})
		.catch((error) => {
			try {
				console.log(JSON.stringify(error));
				new Snackbar(`ERROR: ${error.error.reason ?? error.error.message}`);
			} catch (error) {
				console.log(error);
				new Snackbar(`Failed to ${isBlock ? 'block' : 'unblock'} follower`);
			}
			return false;
		});

	return res;
};


export const followUser = async (params: IFollowUserParams) => {

	console.log("Follower list : ", JSON.stringify(params));

	const res = await axios.post("/user/follow/control", params)
		.then((respons) => {
			console.log(respons);
			return true;
		})
		.catch((error) => {
			try {
				console.log(JSON.stringify(error));
				new Snackbar(`ERROR: ${error.error.reason ?? error.error.message}`);
			} catch (error) {
				console.log(error);
				new Snackbar(`Failed to ${params.followControl === 1 ? 'follow' : 'unfollow'}`);
			}
			return false;
		});

	return res;
};
export interface Follower {
	fans: number;
	block: number;

}

export const getFollowerList = async (
	userId?: string,
	isBlock: boolean = false
) => {

	const res = await axios
		// .get(`/client/follow/find?user=${userId ?? ''}&block=${isBlock ? 1: 0}`)
		.get(`/client/follow/find?user=${userId ?? ''}`)
		.then((respons) => {
			const friendData = respons.data["item(s)"];
			// console.log(friendData);

			const simplifiedData: Follower[] = friendData.map((follower: Follower) => ({
				fans: follower.fans,
				block: follower.block
			}));

			localStorage.setItem("frientLists", JSON.stringify(simplifiedData));
			return respons.data as FollowerListModel;

		})
		.catch((error) => {
			try {
				console.log(JSON.stringify(error));
				// new Snackbar(`Error: ${error.error.reason ?? error.error.message}`);
			} catch (error) {
				console.log(error);
				new Snackbar("Failed to get follower");
			}
			return null;
		});

	return res;
};


export const getFollowerStar = async (params?: IStarUserParams) => {

	console.log("followerStar params : ", JSON.stringify(params));

	const res = await axios.post("/user/follow/star-control", params)
		.then((respons) => {
			console.log(respons);
			return respons;
		})
		.catch((error) => {
			try {
				console.log(JSON.stringify(error));
				new Snackbar(`ERROR: ${error.error.reason ?? error.error.message}`);
			} catch (error) {
				console.log(error);
				// new Snackbar(`Failed to ${params.starControl === 1 ? 'follow' : 'unfollow'}`);
			}
			return false;
		});

	return res;
};