import React, { useRef } from "react";

function FilePicker(props: FilePickerProps): JSX.Element {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleFileSelection: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    if (props.onChange) {
      props.onChange!(event);
    }

    const files = event.target.files;
    // if (files && files.length > 9) {
    //   alert("You can only select a maximum of 9 files.");
    //   return;
    // }

    if (!(props.multiple ?? false) && props.onChanged != null) {
      if (files == null) return props.onChanged();

      const file = files[0];
      console.log(file);

      return props.onChanged!(file);
    } else if (props.multiple && props.onChangedList) {
      return props.onChangedList(files ?? undefined);
    }
  };

  return (
    <>
      <input
        type="file"
        multiple={props.multiple}
        accept={props.accept?.join(",")}
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={handleFileSelection}
      />
      <div
        style={{ display: "contents" }}
        onClick={() => fileInputRef.current!.click()}
      >
        {props.children}
      </div>
    </>
  );
}

export type FilePickerProps = {
  children: JSX.Element;
  onChanged?: (file?: File) => void;
  onChangedList?: (file?: FileList) => void;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  accept?: string[];
  multiple?: boolean;
};

export default FilePicker;
