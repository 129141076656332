import Snackbar from "awesome-snackbar";
import axios from "./axios";

// NOTE:
// adControlVal Values: 1 = ON; 0 = OFF; 2 = REST
export const controlAdClient = async (adControlVal: number, targetVal?: number) => {
	const params: any = { control: adControlVal, target: targetVal };
	const res = await axios.post("/client/control/ad", params)
		.then((respons) => {
			// console.log(respons.data.result_code);

			if (respons.data.result_code !== 200) {
				new Snackbar(`${respons.data.message}`);
				return false;
			} else {
				return true;
			}

		})
		.catch((error) => {
			try {
				console.log(JSON.stringify(error));
				new Snackbar(`ERROR: ${error.error.reason ?? error.error.message}`);
			} catch (error) {
				console.log(error);
				new Snackbar(`Failed to change advertisement`);
			}
			return false;
		});

	return res;
};

// NOTE:
// blogControl Values: 1 = ON; 0 = OFF
export const controlBlogClient = async (blogControlVal: number, targetVal?: number) => {
	const params: object = { control: blogControlVal, target: targetVal };
	const res = await axios.post("/client/control/blog", params)
		.then((respons) => {
			// console.log(respons);
			return respons;
		})
		.catch((error) => {
			try {
				console.log(JSON.stringify(error));
				new Snackbar(`ERROR: ${error.error.reason ?? error.error.message}`);
			} catch (error) {
				console.log(error);
				new Snackbar(`Failed to change blog`);
			}
			// return error;
		});

	return res;
};

export const controlSpecialCat = async (specialControl: boolean, target?: number) => {
	const params: object = { control: specialControl, target: target };
	const res = await axios.post("/client/control/specialControl", params)
		.then((respons) => {
			// console.log(respons);
			return respons;
		})
		.catch((error) => {
			try {
				console.log(JSON.stringify(error));
				new Snackbar(`ERROR: ${error.error.reason ?? error.error.message}`);
			} catch (error) {
				console.log(error);
				new Snackbar(`Failed to change blog`);
			}
			// return error;
		});

	return res;
};

export const publishPostClient = async (
	imgPath: string,
	videoVal: string,
	targetVal?: string,
	contentVal?: string,
	scheduledVal?: string
) => {
	const params: any = { img_path: imgPath, video: videoVal };

	if (targetVal !== null) {
		params["target"] = targetVal;
	}
	if (contentVal !== null) {
		params["content"] = contentVal;
	}
	if (scheduledVal !== null) {
		params["scheduled"] = scheduledVal;
	}
	const { data } = await axios.post("/client/post/publish", params);

	if (data["result_code"] === 200) {
		return true;
	}
	return false;
};

interface IControlSpecialCatalogueParams {
	control: string;
	target?: number;
}
export const controlSpecialCatalogue = async (params: IControlSpecialCatalogueParams) => {

	// console.log(params);

	var res = false;
	if (params.target !== undefined) {
		res = await axios
			.post(`/master/client/control/${params.target}/special-catalogue`, { control: params.control })
			.then((respons) => {
				console.log(respons);
				return true;
			})
			.catch((error) => {
				try {
					console.log(JSON.stringify(error));
					new Snackbar(`ERROR: ${error.error.reason ?? error.error.message}`);
				} catch (error) {
					console.log(error);
					new Snackbar("Failed to change special catalogue");
				}
				return false;
			});
	} else {
		res = await axios
			.post(`/client/control/special-catalogue`, { control: params.control })
			.then((respons) => {
				// console.log("respons Special Cat", res);
				return true;
			})
			.catch((error) => {
				try {
					console.log(JSON.stringify(error));
					new Snackbar(`ERROR: ${error.error.reason ?? error.error.message}`);
				} catch (error) {
					console.log(error);
					new Snackbar("Failed to change special catalogue");
				}
				return false;
			});
	}

	return res;
}
interface IControlBlogDetailsParams {
	basic_desc: string;
	target?: number;
}
export const controlBlogDetails = async (params: IControlBlogDetailsParams) => {

	console.log("params Blog Details ", params);

	var res = false;
	if (params.target) {
		res = await axios
			.post(`/master/client/control/${params.target}/blog-details`, { basic_desc: params.basic_desc })
			.then((respons) => {
				console.log(respons);
				new Snackbar("Blog details successfully  updated");
				return true;
			})
			.catch((error) => {
				try {
					console.log(JSON.stringify(error));
					new Snackbar(`ERROR: ${error.error.reason ?? error.error.message}`);
				} catch (error) {
					console.log(error);
					new Snackbar("Failed to change Blog details");
				}
				return false;
			});
	} else {
		res = await axios
			.post(`/client/control/blog-details`, { basic_desc: params.basic_desc })
			.then((respons) => {
				// console.log("respons Blog details", respons);
				new Snackbar("Blog details successfully  updated");
				return true;
			})
			.catch((error) => {
				try {
					console.log(JSON.stringify(error));
					new Snackbar(`ERROR: ${error.error.reason ?? error.error.message}`);
				} catch (error) {
					console.log(error);
					new Snackbar("Failed to change Blog details");
				}
				return false;
			});
	}

	return res;
}

interface IControlMemberPromotionParams {
	control: object;
	target?: number;
}
export const controlMemberPromotion = async (params: IControlMemberPromotionParams) => {
	var res = false;
	if (params.target) {
		res = await axios
			.post(`master/client/control/${params.target}/promotion-plan`, { control: params.control })
			.then((respons) => {
				console.log(respons);
				return true;
			})
			.catch((error) => {
				try {
					console.log(JSON.stringify(error));
					new Snackbar(`ERROR: ${error.error.reason ?? error.error.message}`);
				} catch (error) {
					console.log(error);
					new Snackbar("Failed to change special catalogue");
				}
				return false;
			});
	} else {
		res = await axios
			.post(`/client/control/promotion-plane`, { control: params.control })
			.then((respons) => {
				console.log(respons);
				return true;
			})
			.catch((error) => {
				try {
					console.log(JSON.stringify(error));
					new Snackbar(`ERROR: ${error.error.reason ?? error.error.message}`);
				} catch (error) {
					console.log(error);
					new Snackbar("Failed to change special catalogue");
				}
				return false;
			});
	}

	return res;
}