import * as React from "react";
// import { useNavigate } from "react-router-dom";
import AppRoutePath from "../../constants/AppRoutePath";
import LocalStorageKey from "../../constants/LocalStorageKey";
import AccountTypeConst from "../../domain/enum/account-type";
import CustomNavigation from "./Navigation";
import { getSystemMessage } from "../../services/system-notification";
import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { Link } from "react-router-dom";

import mainLogo from "../../assets/imgs/main-logo.png";
import { addCommas } from "../../helper/format-helper";
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: require("../../locales/en.json") },
      zh: { translation: require("../../locales/zh.json") },
    },
    fallbackLng: "en", // Default language fallback
    interpolation: {
      escapeValue: false, // Disable HTML escaping
    },
  });
export interface ICustomHeaderProps {
  title: string;
  accountType: AccountTypeConst;
  backRoute: string | -1 | null;
  sideBars?: boolean;
  publics?: boolean;
  isLogin?: boolean;
  isMasterAdCreate?: boolean;
}

export default function CustomHeader({
  title,
  sideBars,
  publics,
  isMasterAdCreate,
  accountType = localStorage.getItem(LocalStorageKey.role) === "master"
    ? AccountTypeConst.Master
    : AccountTypeConst.Client,
  backRoute = -1,
}: Partial<ICustomHeaderProps>) {
  // const navigate = useNavigate();
  const [isNavOpen, setIsNavOpen] = React.useState(true);
  const [notifCount, setNotifCount] = React.useState(0);

  React.useEffect(() => {
    if (!publics) {
      getSystemMessage({ page: 1, per_page: 10, status: 0 }).then((res) => {
        if (res) {
          // console.log("MM Not", res.total);
          setNotifCount(res.total_items || 0);
        }
      });
    }
  }, []);

  const [language, setLanguage] = React.useState("en"); // Default language is English
  const handleLanguageSwitch = () => {
    const newLanguage = language === "en" ? "zh" : "en";
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage); // Update the language setting
  };

  return (
    <>
      <CustomNavigation
        isOpen={isNavOpen}
        onCallback={(value: boolean) => {
          setIsNavOpen(value);
        }}
        accountType={accountType}
      />
      <header className=" ">
        {/* {backRoute && (
          <a
            className="lft-arw"
            onClick={() =>
              backRoute === -1 ? navigate(-1) : navigate(backRoute as string)
            }
          >
            <i className="fa-solid fa-chevron-left"></i>
          </a>
        )} */}

        {!publics || isMasterAdCreate ? (
          <>
            {accountType === AccountTypeConst.Client ? (
              <>
                <Link to={AppRoutePath.clientMainPage}>
                  <img src={mainLogo} alt="" style={{ maxWidth: 100 }} />
                </Link>
              </>
            ) : (
              <Link to={AppRoutePath.masterAccount}>
                <img src={mainLogo} alt="" style={{ maxWidth: 100 }} />
              </Link>
            )}
          </>
        ) : (
          <>
            <a>
              <img src={mainLogo} alt="" style={{ maxWidth: 100 }} />
            </a>
          </>
        )}
        {/* <a>
          <img src={mainLogo} alt="" style={{ maxWidth: 100 }} />
        </a> */}
        {publics ? (
          <>
            {/* <div className="title">{title}</div> */}
            <div className="d-flex justify-content-end pt-5">
              <p className="lang">
                <button
                  onClick={handleLanguageSwitch}
                  className="menu-language "
                  style={{ right: 10 }}
                >
                  {language === "en" ? "中文" : "ENG"}{" "}
                  {/* Display "ENG" or "中文" based on language */}
                </button>
              </p>
            </div>
          </>
        ) : (
          <>
            <div className="title">{title}</div>
            <div className="d-flex justify-content-end pt-5">
              <p className="">
                <button
                  onClick={handleLanguageSwitch}
                  className="menu-language"
                >
                  {language === "en" ? "中文" : "ENG"}{" "}
                  {/* Display "ENG" or "中文" based on language */}
                </button>
              </p>
            </div>

            {accountType === AccountTypeConst.Client ? (
              <>
                <a href="/client/system-messages" className="stn-btn">
                  <i className="fa-regular fa-comment"></i>
                  <i className="fa-solid fa-gear"></i>
                  {notifCount > 0 && <span>{notifCount}</span>}
                </a>
                <a className="clk-btn" onClick={() => setIsNavOpen(!isNavOpen)}>
                  <i className="fa-solid fa-bars"></i>
                </a>
              </>
            ) : (
              <>
                <a href="/master/system-messages" className="stn-btn">
                  <i className="fa-regular fa-comment"></i>
                  <i className="fa-solid fa-gear"></i>
                  {notifCount > 0 && (
                    <span>{addCommas(Number(notifCount))}</span>
                  )}
                </a>
                <a className="clk-btn" onClick={() => setIsNavOpen(!isNavOpen)}>
                  <i className="fa-solid fa-bars"></i>
                </a>
              </>
            )}
          </>
        )}
      </header>
    </>
  );
}
