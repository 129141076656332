import React, { useState } from "react";

type Message = {
  content: string;
  sender: string;
  photo?: string;
};

interface ChatMessage {
  content: string;
  sender: string;
  photo?: string;
}

interface ChatContainerProps {
  senderProfilePhoto: string;
  receiverProfilePhoto: string;
  messages: Message[];
}

const ChatContainer: React.FC<ChatContainerProps> = ({
  senderProfilePhoto,
  receiverProfilePhoto,
  messages,
}) => {
  const [newMessage, setNewMessage] = useState("");
  const [chatMessages, setChatMessages] = useState<ChatMessage[]>(messages);
  const [newPhoto, setNewPhoto] = useState<File | null>(null);

  const handleSendMessage = (e: React.FormEvent) => {
    e.preventDefault();

    if (newMessage.trim() === "") return;

    const newChatMessage: ChatMessage = {
      content: newMessage,
      sender: "Sender",
    };

    setChatMessages([...chatMessages, newChatMessage]);
    setNewMessage("");
  };

  return (
    <div className="container py-5">
      <div className="row">
        <div className="col-lg-12">
          <div className="card bg-transparent border-0">
            {/* <div className="card-header">Chat</div> */}
            <div className="card-body">
              {/* Chat messages */}
              <div className="chat-messages">
                {chatMessages.map((message, index) => (
                  <div
                    key={index}
                    className={`message ${
                      message.sender === "Sender" ? "sender" : "receiver"
                    }`}
                  >
                    <div>
                      {message.sender === "Sender" ? (
                        <img
                          src={senderProfilePhoto}
                          alt="Sender Profile"
                          className="message-profile-photo clt-img1"
                        />
                      ) : (
                        <img
                          src={receiverProfilePhoto}
                          alt="Receiver Profile"
                          className="message-profile-photo clt-img1"
                        />
                      )}
                    </div>

                    <div className="message-content">{message.content}</div>
                    <div
                      className={`arrow ${
                        message.sender === "Sender"
                          ? "arrow-sender"
                          : "arrow-receiver"
                      }`}
                    ></div>
                  </div>
                ))}
              </div>
            </div>
            <div className="card-footer">
              {/* Input form for sending messages */}
              <form onSubmit={handleSendMessage}>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Type your message..."
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                  />
                  <div className="input-group-append">
                    <button type="submit" className="btn btn-primary">
                      Send
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatContainer;
