import * as React from "react";
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: require('../../locales/en.json') },
      zh: { translation: require('../../locales/zh.json') }
    },
    fallbackLng: 'en', // Default language fallback
    interpolation: {
      escapeValue: false // Disable HTML escaping
    }
  });
export interface ICustomEmptyMessageProps {
	message?: string;
}

export default function CustomEmptyMessage({
	// message = "There is no data to show",
}: ICustomEmptyMessageProps) {
	const { t } = useTranslation();
	return (
		<div className='master-img'>
			<center>
				<div
					style={{
						height: 100,
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<div>
						{/* <img
                            src='/img/icon_photo.png'
                            width={50}
                            height={50}
                            alt=''
                        /> */}
						<p>{t('noDataInfo2')}</p>
					</div>
				</div>
			</center>
		</div>
	);
}
