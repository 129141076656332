import { Carousel, Modal, Spinner } from "react-bootstrap";
import LocalStorageKey from "../../constants/LocalStorageKey";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { InstantPostModel } from "../../domain/model/instant-post-model";
import { useNavigate, useParams } from "react-router-dom";
import {
  commentSelectedInstantPost,
  commentSelectedInstantReviewPost,
  deleteCommentInstantPosts,
  findInstantPost,
  findInstantPostComments,
  findInstantPostView,
} from "../../services/instant";
import CustomNavigation from "../reusable/Navigation";
import AppRoutePath from "../../constants/AppRoutePath";
import useIsMaster from "../../helper/custom_hooks/use-is-master";
import AccountTypeConst from "../../domain/enum/account-type";
import SizedBox from "../reusable/Sizedbox";
import InstantPostCommentsModel from "../../domain/model/instant-post-comment-model";
import FormErrorMessage from "../reusable/FormErrorMessage";
import { getSystemMessage } from "../../services/system-notification";
import { useTranslation, initReactI18next } from "react-i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { findFlowerBalance } from "../../services/products/flower";
import { getHeartBalance, sendHeart } from "../../services/products/heart";
import Snackbar from "awesome-snackbar";
import CustomHeader from "../reusable/Header";
import TopHearder from "../reusable/TopHearder";
import Loaders from "../reusable/Loaders";
export interface IHistoryPageProps {}

const langType = localStorage.getItem("i18nextLng");
interface IAddNewPostPreviewProps {
  photos: File[];
  video: File;
  title: string;
  content: string;
  id?: string;
  onCancel: () => void;
  onConfirm: () => void;
}

const AddNewPostPreview = (props: IAddNewPostPreviewProps) => {
  const { userId, id } = useParams();
  const navigate = useNavigate();
  const isMaster = useIsMaster();
  const [postData, setPostData] = React.useState<InstantPostModel>();
  const [isNavOpen, setIsNavOpen] = React.useState(true);
  const [isSendHeart, setIsSendHeart] = useState(false);
  const [heartBalance, setHeartBalance] = useState([]);
  const [productNames, setProductNames] = useState("");
  const [seletedProductId, setSeletedProductId] = useState("");
  const [sendHeartSuccess, setSendHeartSuccessl] = useState(false);
  const [postId, setPostId] = useState(0);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isSendHeartLoading, setIsSendHeartLoading] = useState(false);
  const [comments, setComments] = React.useState<InstantPostCommentsModel>();
  const [addComment, setAddComment] = useState("");
  const [totalComments, setTotalComments] = useState(0);
  const [contentValidated, setContentValidated] = useState<string>();
  const [notifCount, setNotifCount] = React.useState(0);
  const { t } = useTranslation();
  const [language, setLanguage] = React.useState("en"); // Default language is English

  const exts = localStorage.getItem("EXT");
  const userID = localStorage.getItem("user_id");

  // React.useEffect(() => {
  //   getSystemMessage({ status: 0 }).then((res) => {
  //     if (res) {
  //       setNotifCount(res.length);
  //     }
  //   });
  // }, []);

  const handleSendHearts = () => {
    if (seletedProductId != "") {
      setIsSendHeartLoading(true);
      sendHeart({ prod_id: Number(seletedProductId), post_id: postId }).then(
        (res) => {
          if (res?.result_code === 200) {
            setSendHeartSuccessl(true);
            setIsSendHeart(false);
            setSeletedProductId("");
            getHeartBalance().then((res: any) => {
              // console.log(res);
              setIsSendHeartLoading(false);
              setHeartBalance(res);
            });
          } else {
            // sendHeartSuccess(false);
            setIsSendHeartLoading(false);
            setSeletedProductId("");
          }
          setIsSendHeartLoading(false);
        }
      );
    } else {
      new Snackbar("Please Select Heart Package");
    }
  };

  const handleProductName: React.ChangeEventHandler<HTMLSelectElement> = (
    event
  ) => {
    const value = event.target.value;
    setSeletedProductId(value);
    // setProductNames(value);

    // console.log("gggh ", value);
  };

  const handleAddComment: React.ChangeEventHandler<HTMLTextAreaElement> = (
    event
  ) => {
    const val = event.target.value;

    if (val.length > 0) {
      setContentValidated("");
    } else {
      setContentValidated("Content cannot be empty!");
    }
    setAddComment(val);
  };

  const handleInput = (event: React.FormEvent<HTMLTextAreaElement>) => {
    const inputText = event.currentTarget.value;
    if (inputText.length <= 100) {
      setAddComment(inputText);
    }
  };

  useEffect(() => {
    if (!id) return;

    // console.log("gg ", id);

    if (isMaster) {
      setIsLoadingData(true);
      findInstantPostView({ post_id: id, target: userId }).then((data) => {
        // console.log("Post details ", data);
        setIsLoadingData(false);
        if (data) setPostData(data);
      });
    } else {
      setIsLoadingData(true);
      findInstantPost(id).then((data) => {
        // console.log("Post details ", data);
        setIsLoadingData(false);
        if (data) setPostData(data);
      });
    }

    findInstantPostComments(Number(id)).then((data) => {
      // console.log(data);

      if (data) setComments(data);
    });
  }, []);
  useEffect(() => {
    getHeartBalance().then((res: any) => {
      // console.log(res);

      setHeartBalance(res);
    });
  }, []);
  // console.log("fd ", JSON.stringify(heartBalance));

  const OnCommentPost = () => {
    const payload = {
      comment: addComment,
      author_photo: localStorage.getItem(LocalStorageKey.userProfilePicture),
      post_id: Number(id),
    };

    // console.log(addComment?.length);

    if (addComment?.length > 0) {
      commentSelectedInstantPost(payload).then((isSuccess) => {
        if (isSuccess) {
          findInstantPostComments(Number(id)).then((res) => {
            if (res != null) {
              setComments(res!);
              setTotalComments(res["item(s)"].length);
              setAddComment("");
            }
          });
        }
      });
    } else {
      if ((addComment?.length ?? 0) > 0) {
        setContentValidated("");
      } else {
        if ((addComment?.length ?? 0) <= 0) {
          setContentValidated("Content cannot be empty!");
        }
      }
    }

    // console.log("Comment Added ");
  };
  const handleDeleteCmment = (idd?: number) => {
    // console.log(addComment?.length);

    deleteCommentInstantPosts(Number(idd)).then((res) => {
      findInstantPostComments(id).then((res) => {
        if (res != null) {
          setComments(res!);
        }
      });
    });

    // console.log("Comment Deleted  ");
  };

  // if(exts !="true"){
  //   console.log("eddd true ",  exts);
  // }else {
  //   console.log("eddd ",  exts);
  // }

  if (id) {
    return (
      <>
        <CustomNavigation
          isOpen={isNavOpen}
          onCallback={(value: boolean) => {
            setIsNavOpen(value);
          }}
          accountType={
            isMaster ? AccountTypeConst.Master : AccountTypeConst.Client
          }
        />

        <CustomHeader />
        <TopHearder title={t("viewInstantPost")} />
        <div className="bg-dark">
          <div className="container">
            {isLoadingData ? (
              <div className="text-center my-5">
                <Loaders />
              </div>
            ) : !postData ? (
              // <Spinner />
              ""
            ) : (
              <div className="inner-box pt-4 pb-4">
                <div className="row align-items-center ip-wrap">
                  <div className="col-2">
                    <div className="clt-img">
                      <img
                        src={
                          postData.author_photo ??
                          "/img/blank-profile-picture.png"
                        }
                        alt=""
                      />
                    </div>
                  </div>
                  {/* <div className="col-4">
                    <strong></strong>
                  </div> */}
                  {/* <div className="col-6 text-end">
                    <button className="request-btn">+Follow</button>
                  </div> */}
                </div>

                <Carousel className="my-3 carousel-instant-post">
                  {postData.media.video && (
                    <Carousel.Item>
                      <video
                        className="w-100"
                        src={postData.media.video![0].cache_url}
                        controls
                      />
                    </Carousel.Item>
                  )}

                  {postData.media.image!?.map((e, i) => (
                    <Carousel.Item key={i}>
                      <img
                        src={e.cache_url}
                        className="image-grid"
                        height={250}
                      />
                    </Carousel.Item>
                  ))}
                </Carousel>

                {/* <ul className="instant-rate">
                  <li> <i className="fa-solid fa-heart"></i></li>
                  <li>
                    <i className="fa-regular fa-heart"></i>
                  </li>
                </ul> */}

                <div className="instant-title pt-3">
                  <h2>
                    {postData?.title ? postData?.title : " Instant Title"}{" "}
                    {!postData?.scheduled_time ? (
                      <i
                        className="fa-regular fa-heart primary-color"
                        onClick={() => {
                          if (exts != "true") {
                            setIsSendHeart(true);
                            setPostId(postData?.post_id);

                            // console.log("postData?.post_id ", postData?.post_id);
                          }
                        }}
                      ></i>
                    ) : (
                      <i className="fa-regular fa-heart primary-color"></i>
                    )}
                  </h2>
                  <p>{postData?.content}</p>
                  <div className="row">
                    {postData?.scheduled_time ? (
                      <>
                        <div className="col-12 d-flex justify-content-between ">
                          {/* <span> Comments ({totalComments})</span> */}
                          <div>
                            <p className="mb-0 primary-color">
                              {t("createDate")}
                            </p>
                            <span>{postData?.created} </span>
                          </div>
                          <div>
                            <p className="mb-0 primary-color">
                              {t("ScheduledDate")}
                            </p>
                            <span>{postData?.scheduled_time} </span>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-12 d-flex justify-content-between ">
                          <span> Comments ({totalComments})</span>

                          <span>{postData?.created} </span>
                        </div>
                      </>
                    )}
                  </div>
                </div>

                {!postData?.scheduled_time && (
                  <div className="row align-items-center">
                    <div className="col-9 ">
                      <div className="post-box ">
                        <textarea
                          rows={5}
                          className="form-control form-control-cus ACD"
                          value={addComment.slice(0, 99)}
                          onChange={handleAddComment}
                          onInput={handleInput}
                        ></textarea>
                        <span
                          className="pull-right label label-default"
                          id="count_message"
                        >
                          {addComment.length} / 100
                        </span>
                      </div>
                    </div>

                    <div className="col-3 text-end">
                      <button
                        className="btn primary-button rounded-button"
                        onClick={OnCommentPost}
                      >
                        Comment
                      </button>
                    </div>

                    <div className="col-12">
                      {contentValidated && (
                        <>
                          <FormErrorMessage message={contentValidated} />
                        </>
                      )}
                    </div>
                    <div className="row  pe-0 pt-4">
                      {comments &&
                        comments["item(s)"] &&
                        comments["item(s)"]
                          .sort(
                            (a, b) =>
                              moment(b.created).valueOf() -
                              moment(a.created).valueOf()
                          )
                          .map((data, i) => {
                            return (
                              <React.Fragment key={i + 1}>
                                <div className="col-2">
                                  <div className="clt-img1">
                                    <img
                                      src={
                                        data.author_photo ??
                                        "/img/blank-profile-picture.png"
                                      }
                                      alt=""
                                    />
                                  </div>
                                </div>

                                <div className="col-10  pb-3 border-0 comment-div">
                                  <div className="d-flex justify-content-between ">
                                    <h6 className="primary-color">
                                      {data?.author_name}{" "}
                                    </h6>
                                    <small className="text-muted">
                                      {data?.created}
                                    </small>
                                  </div>
                                  <p className="text-break">{data?.comment}</p>
                                  <div className="row">
                                    <div className="col-12">
                                      {data.author_id == Number(userID) && (
                                        <button
                                          className="btn primary-button rounded-button"
                                          onClick={() => {
                                            handleDeleteCmment(data.cmt_id);
                                          }}
                                        >
                                          Delete
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </React.Fragment>
                            );
                          })}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <Modal show={isSendHeart}>
          <Modal.Body>
            <div className="d-flex justify-content-center align-items-center">
              <Modal.Title>
                <h5 className="modal-title text-center">
                  {/* Reload <span className="color-pink-3" style={{fontWeight:"bold" , fontSize:17 }}>{quantity1}</span> {walletName} Successful */}
                </h5>
              </Modal.Title>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="row">
              <div className="col-12">
                <label htmlFor="" className="text-white pb-2">
                  {t("packageNames")}
                </label>
                <select
                  className="form-select input-md w-100 "
                  id="typeSelectOption"
                  onChange={handleProductName}
                >
                  <option value="" selected disabled>
                    {t("heartBalPack")}
                  </option>

                  {exts != "true" && (
                    <>
                      {heartBalance.map((item, index) => {
                        const { prod_name, quantity, prod_id } = item as {
                          prod_name: string;
                          quantity: number;
                          prod_id: number;
                        };

                        const [englishName, chineseName] = (
                          prod_name as string
                        ).split("|");
                        return (
                          <option key={index} value={prod_id}>
                            {langType === "zh" ? chineseName : englishName}
                          </option>
                        );
                      })}
                    </>
                  )}
                </select>
              </div>
              <div className="col-12 d-flex gap-2 mt-3">
                <button
                  className="button primary-button rounded-button w-100"
                  onClick={() => {
                    setIsSendHeart(false);
                    setSeletedProductId("");
                  }}
                  disabled={isSendHeartLoading}
                >
                  {t("cancel")}
                </button>
                <button
                  className="button primary-button rounded-button w-100"
                  onClick={() => {
                    handleSendHearts();
                  }}
                  disabled={heartBalance.length < 1 || seletedProductId == ""}
                >
                  {/* {t("sendHeart")} */}
                  {isSendHeartLoading ? (
                    <Spinner size="sm" />
                  ) : (
                    <span>{t("sendHeart")} </span>
                  )}
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal show={sendHeartSuccess}>
          <Modal.Header>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={(e) => setSendHeartSuccessl(false)}
            ></button>
          </Modal.Header>
          <Modal.Body>
            <div className="reload-flower-wrap">
              <div className="flower-top">
                <SizedBox width={50} />
              </div>

              <p className="text-center">{t("sendHertPostInfo")}</p>

              <div className="text-center">
                <button
                  id="blog"
                  className="btn primary-button rounded-button abs-btn coin-btn w-70"
                  onClick={() => setSendHeartSuccessl(false)}
                >
                  {t("close")}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }

  return (
    <>
      <div className="bg-dark">
        <div className="container">
          <div className="inner-box pt-4 pb-4">
            <div className="row align-items-center ip-wrap">
              <div className="col-2">
                <div className="clt-img">
                  <img
                    src={
                      localStorage.getItem(
                        LocalStorageKey.userProfilePicture
                      ) ?? "/img/blank-profile-picture.png"
                    }
                    alt=""
                  />
                </div>
              </div>
              <div className="col-4">
                <strong></strong>
              </div>
              {/* <div className="col-6 text-end">
              <button className="request-btn">+Follow</button>
            </div> */}
            </div>

            <Carousel className="my-3 carousel-instant-post">
              {props?.video && (
                <Carousel.Item>
                  <video
                    className="w-100"
                    src={URL.createObjectURL(props?.video)}
                    controls
                  />
                </Carousel.Item>
              )}

              {props?.photos?.map((e, i) => (
                <Carousel.Item key={i}>
                  <img
                    src={URL.createObjectURL(e)}
                    className="image-grid"
                    height={250}
                  />
                </Carousel.Item>
              ))}
            </Carousel>

            <ul className="instant-rate">
              <li></li>
              <li>
                <i className="fa-regular fa-heart"></i>
              </li>
            </ul>

            <div className="instant-title">
              <h2>{props.title}</h2>
              <p>{props.content}</p>
              <div className="row align-items-center">
                <div className="col-5">
                  <span>{moment().format("YYYY-MM-DD HH:mm:ss")}</span>
                </div>
                <div className="col-7 text-end">
                  <button className="btn primary-button rounded-button">
                    Comment
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6 text-center">
                <button
                  className="primary-button button mt-3 w-100"
                  onClick={props.onCancel}
                >
                  Cancel
                </button>
              </div>
              <div className="col-6 text-center">
                <button
                  className="primary-button button mt-3 w-100"
                  onClick={props.onConfirm}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNewPostPreview;
