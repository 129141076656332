import Snackbar from "awesome-snackbar";
import { ProfileMasterModel } from "../../domain/model/profile-master-model";
import axios from "../axios";
;


export const getProfileMaster = async () => {



	const res = await axios
		.get("/master/account")
		.then((respons) => {
			console.log("Master Profile Data ", respons);
			return respons.data as ProfileMasterModel;
		})
		.catch((error) => {
			return null;
		});

	return res;
};
export const getMasterDetials = async (user_id?: number) => {
	console.log("user_id Master ", user_id);
	const res = await axios
		.post("/master/basicInfo/describe", { user_id: user_id })
		.then((respons) => {
			console.log("Master Details Data ", respons);
			return respons.data
		})
		.catch((error) => {
			return null;
		});

	return res;
};

export const disableMasterAccount = async () => {
	const res = await axios
		.patch("/master/account/disable")
		.then((respons) => {
			console.log(respons);
			return true;
		})
		.catch((error) => {
			try {
				console.log(JSON.stringify(error));
				new Snackbar('ERROR: ' + (error.error.reason ?? error.error.message));
			} catch (error) {
				console.log(error);
				new Snackbar("Failed to get profile");
			}
			return false;
		});

	return res;
}

export const enableMasterAccount = async () => {
	const res = await axios
		.patch("/master/account/enable")
		.then((respons) => {
			console.log(respons);
			return true;
		})
		.catch((error) => {
			try {
				console.log(JSON.stringify(error));
				new Snackbar('ERROR: ' + (error.error.reason ?? error.error.message));
			} catch (error) {
				console.log(error);
				new Snackbar("Failed to get profile");
			}
			return false;
		});

	return res;
}
