import React, { useState } from "react";

// ===================
// NOTE:
// params.label: password field text label
// params.passwordValue: password value
// params.passwordOnChange: callback when password changed
// ===================
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
export interface IHistoryPageProps {}
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: require('../../locales/en.json') },
      zh: { translation: require('../../locales/zh.json') }
    },
    fallbackLng: 'en', // Default language fallback
    interpolation: {
      escapeValue: false // Disable HTML escaping
    }
  });
export default function PasswordField(params: PasswordFieldProps): JSX.Element {
	const [showPassword, setShowPassword] = useState(false);
	const handlePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	const { t } = useTranslation();
	const [switchLanguage, setSwitchLanguage] = useState("en"); // Default language is English
	const handleLanguageSwitch = () => {
	  const newLanguage = switchLanguage === "en" ? "zh" : "en";
	  setSwitchLanguage(newLanguage);
	  i18n.changeLanguage(newLanguage); // Update the language setting
	};

	return (
		<div className='row align-items-center mb-3 g-1'>
			<label className='label mb-1'> {params.label ?? t('createPwd')} </label>
			<div className='col-11'>
				<input
					className='form-control input'
					type={showPassword ? "text" : "password"}
					value={params.passwordValue}
					style={{ color: "white" }}
					onChange={params.passwordOnChange}
					id={`id_${(params.label ?? t('createPwd')).replace(" ", "_")}`}
				/>
			</div>
			<div className='col-1 text-end color-white3'>
				<i
					className={`fa fa-eye  ${showPassword ? "primary-color1 " : ""
				}`} style={{ cursor: "pointer" }}
					onClick={handlePasswordVisibility}
					id='togglePassword'
				></i>
			</div>
		</div>
	);
}

type PasswordFieldProps = {
	label?: string,
	passwordValue: string,
	passwordOnChange: React.ChangeEventHandler<HTMLInputElement>
}