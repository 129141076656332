import Snackbar from "awesome-snackbar";
import { Button, Spinner } from "react-bootstrap";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AppRoutePath from "../../constants/AppRoutePath";
import { changePassword } from "../../services/auth";
import FormErrorMessage from "../reusable/FormErrorMessage";
import SizedBox from "../reusable/Sizedbox";
import CustomHeader from "../reusable/Header";
import { useTranslation, initReactI18next } from "react-i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import TopHearder from "../reusable/TopHearder";
export interface IHistoryPageProps {}
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: require("../../locales/en.json") },
      zh: { translation: require("../../locales/zh.json") },
    },
    fallbackLng: "en", // Default language fallback
    interpolation: {
      escapeValue: false, // Disable HTML escaping
    },
  });
function ChangeRegisterNumber() {
  const navigate = useNavigate();
  const [oldpassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [retypePassword, setRetypePassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [passwordValidated, setPasswordValidated] = useState<string>();

  const handleOldPassword: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setOldPassword(event.target.value);
  };

  const handleChangePassword: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setPassword(event.target.value);
  };

  const handleRetypePasswordChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (event) => {
    setRetypePassword(event.target.value);
  };

  const handlePasswordVisibility: React.MouseEventHandler<HTMLElement> = () => {
    setShowPassword(!showPassword);
  };
  const handlePasswordVisibility1: React.MouseEventHandler<
    HTMLElement
  > = () => {
    setShowPassword1(!showPassword1);
  };
  const handlePasswordVisibility2: React.MouseEventHandler<
    HTMLElement
  > = () => {
    setShowPassword2(!showPassword2);
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (isLoading) return;
    setPasswordValidated("");

    let validatePassword = true;
    // Validations
    if (password.length < 1) {
      setPasswordValidated("Invalid Password");
      validatePassword = false;
    } else if (password === oldpassword) {
      setPasswordValidated("New Password can not be same with Old Password");
      validatePassword = false;
    } else if (password !== retypePassword) {
      setPasswordValidated("Password Mismatched");
      validatePassword = false;
    } else if (password.length < 6) {
      setPasswordValidated("Password must be at least 6 characters/numbers");
      validatePassword = false;
    }
    // End of validations

    setIsLoading(true);

    // if (passwordValidated!=="") return;

    if (validatePassword) {
      // if (password && oldpassword) {
      try {
        await changePassword(oldpassword, password).then((res: any) => {
          // console.log("gg ", res.result_code);

          if (res.result_code == 200) {
            new Snackbar("Password Successfully Changed", {
              position: "bottom-center",
            });
            navigate(-1);
          } else {
            new Snackbar(`${res.message}, Old Password is Wrong...`);
          }
        });
      } catch (e) {
        console.log(e);
        new Snackbar("Failed to change password", {
          position: "bottom-center",
        });
      }
      // }
    }

    setIsLoading(false);
  };

  const { t } = useTranslation();
  const [language, setLanguage] = React.useState("en"); // Default language is English

  return (
    <div>
      <CustomHeader />
      <TopHearder title={t("changePwd")} />
      <div className="bg-dark">
        <div className="container">
          <div className="inner-box">
            <form onSubmit={handleSubmit}>
              <SizedBox height={70} />
              <div className="row align-items-center mb-3 g-1">
                <label className="label mb-1"> {t("oldPwd")} </label>
                <div className="col-11">
                  <input
                    className="form-control input"
                    type={showPassword ? "text" : "password"}
                    value={oldpassword}
                    style={{ color: "white" }}
                    onChange={handleOldPassword}
                    id="id_password"
                  />
                </div>
                <div className="col-1 text-end">
                  <i
                    className={`fa fa-eye  ${
                      showPassword ? "primary-color1 " : "color-white3"
                    }`}
                    style={{ cursor: "pointer" }}
                    onClick={handlePasswordVisibility}
                    id="togglePassword"
                  ></i>
                </div>
              </div>
              <div className="row align-items-center mb-3 g-1">
                <label className="label mb-1"> {t("createNewPwd")} </label>
                <div className="col-11">
                  <input
                    className="form-control input"
                    type={showPassword1 ? "text" : "password"}
                    value={password}
                    style={{ color: "white" }}
                    onChange={handleChangePassword}
                    id="id_password"
                  />
                </div>
                <div className="col-1 text-end">
                  <i
                    className={`fa fa-eye  ${
                      showPassword1 ? "primary-color1 " : "color-white3"
                    }`}
                    style={{ cursor: "pointer" }}
                    onClick={handlePasswordVisibility1}
                    id="togglePassword"
                  ></i>
                </div>
              </div>
              <div className="row align-items-center mb-3 g-1">
                <label className="label mb-1"> {t("retypeNewPwd")} </label>
                <div className="col-11">
                  <input
                    className="form-control input"
                    type={showPassword2 ? "text" : "password"}
                    value={retypePassword}
                    style={{ color: "white" }}
                    onChange={handleRetypePasswordChange}
                  />
                </div>
                <div className="col-1 text-end">
                  <i
                    className={`fa fa-eye  ${
                      showPassword2 ? "primary-color1 " : "color-white3"
                    }`}
                    style={{ cursor: "pointer" }}
                    onClick={handlePasswordVisibility2}
                  ></i>
                </div>
              </div>
              <FormErrorMessage message={passwordValidated} />

              <div className="row">
                <div className="col-12 text-center">
                  {/* <Link to="/individual-ad-details"> */}
                  <button
                    className="primary-button button mt-3 w-30"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <Spinner size="sm" />
                    ) : (
                      <span>{t("submit")}</span>
                    )}
                  </button>
                  {/* </Link> */}
                </div>
              </div>
              <SizedBox height={20} />

              {/* <div className="row">
                <div className="col-12 text-center">
                  <Link to={AppRoutePath.clientMainPage}>
                    <button className="primary-button button mt-3 w-100">
                    {t('done')}
                    </button>
                  </Link>
                </div>
              </div> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ChangeRegisterNumber;
