import React from "react";
import { Button } from "react-bootstrap";

import { useTranslation, initReactI18next } from "react-i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: require("../../../locales/en.json") },
      zh: { translation: require("../../../locales/zh.json") },
    },
    fallbackLng: "en", // Default language fallback
    interpolation: {
      escapeValue: false, // Disable HTML escaping
    },
  });
function RegistrationSteps(props: RegistrationStepsProps): JSX.Element {
  const { t } = useTranslation();
  const [language, setLanguage] = React.useState("en"); // Default language is English
  const handleLanguageSwitch = () => {
    const newLanguage = language === "en" ? "zh" : "en";
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage); // Update the language setting
  };
  return (
    <div className="steps-container my-3">
      <div className="step-progress">
        <div
          className="step-progress-value"
          style={{ width: ["0", "30%", "65%", "100%"][props.step] }}
        />
      </div>
      <div className="steps">
        {[
          t("registration"),
          t("payment"),
          t("doneRegister"),
          t("addDetails"),
        ].map((e, i) => {
          const isCurrentStep = i === props.step;
          const isPreviousStep = i < props.step;
          const isNextStep = i === props.step + 1;

          return (
            <div className="step-item" key={i}>
              <div className="step-title">{e}</div>
              <Button
                className={`step-button text-center${
                  isCurrentStep ? " current-step" : ""
                } ${isPreviousStep ? " previous-step" : ""}${
                  isNextStep ? " next-step" : ""
                }`}
                type="button"
                aria-expanded="true"
              >
                {i + 1}
              </Button>
            </div>
          );
        })}
      </div>
    </div>
  );
}

type RegistrationStepsProps = {
  step: number;
};

export default RegistrationSteps;
