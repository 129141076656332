import React from "react";

function SizedBox(props: SizedBoxProps): JSX.Element {
  const style = {
    height: props.height ?? 0,
    width: props.width ?? 0,
  };

  return (
    <div style={style}></div>
  );
}

type SizedBoxProps = {
  height?: number,
  width?: number
}

export default SizedBox;
