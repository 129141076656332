import IPageType from "../adapter/page-type-adapter"

export default class AccountTypeConst implements IPageType {
    constructor(path: string) {
        this.path = path
    }

    path: string;

    static Master = new AccountTypeConst('master');
    static Client = new AccountTypeConst('client');

    static getPageTypeByPath(path: String) : AccountTypeConst | null {
        switch (path) {
			case AccountTypeConst.Master.path:
				return AccountTypeConst.Client;
			default:
				return null;
		}
    }
}