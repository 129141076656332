import Snackbar from "awesome-snackbar";
import axios from "../axios";
import { Image } from "../../domain/model/instant-review-model";
import { AdMedia } from "../../domain/model/ad-media";

interface IGetSpecialPhotoParams {
	target?: number;
	specialCat_id?: number;
}
export const getSpecialPhoto = async (params: IGetSpecialPhotoParams) => {
	const res = await axios
		.get(`/client/special/find`, { params })
		.then((respons) => {
			// console.log(respons);
			return respons.data["item(s)"] as Image[];
		})
		.catch((error) => {
			try {
				console.log(JSON.stringify(error));
				// new Snackbar(`ERROR: ${error.error.reason ?? error.error.message}`);
				if ((error.error.reason || error.error.message) === "Token Expired") {
					// new Snackbar("Please relogin again.");
				}
			} catch (error) {
				console.log(error);
				new Snackbar("Failed to get special photos");
			}
			return null;
		});

	return res;
};


export const getSpecialPhotoMedia = async (params: IGetSpecialPhotoParams): Promise<AdMedia[]> => {

	try {
		const res = await axios.get("/client/special/find", {
			params
		});

		return res.data["item(s)"];
	} catch (e) {
		return [];
	}



};

interface IUploadSpecialPhotoParams {
	img_path: string[];
	target?: number;
	specialCat_id?: number;
}
export const uploadSpecialPhoto = async (params: IUploadSpecialPhotoParams) => {

	// console.log("params Spec", params);

	const res = await axios
		.post(`/client/special/upload`, params)
		.then((respons) => {
			// console.log(respons);
			return true;
		})
		.catch((error) => {
			try {
				console.log(JSON.stringify(error));
				new Snackbar(`ERROR: ${error.error.reason ?? error.error.message}`);
			} catch (error) {
				console.log(error);
				new Snackbar("Failed to get special photos");
			}
			return false;
		});

	return res;
};

interface IDeleteSpecialPhotoParams {
	filename: string;
	target?: number;
}
export const deleteSpecialPhoto = async (params: IDeleteSpecialPhotoParams) => {
	console.log("params ", params);
	const res = await axios
		.delete(`/client/special/delete`, { data: params })
		.then((respons) => {
			// console.log(respons);
			return true;
		})
		.catch((error) => {
			try {
				console.log(JSON.stringify(error));
				new Snackbar(`ERROR: ${error.error.reason ?? error.error.message}`);
			} catch (error) {
				console.log(error);
				new Snackbar("Failed to get special photos");
			}
			return false;
		});

	return res;
};
interface ISetMainSpecialPhotoParams {
	filename: string;
	target?: number;
	media_cat: number;
}
export const setMainSpecialPhoto = async (params: ISetMainSpecialPhotoParams) => {

	const res = await axios
		.post(`/client/special/main`, params)
		.then((respons) => {
			console.log(respons);
			new Snackbar(" special Cat Mian photo successfully Updated");
			return true;
		})
		.catch((error) => {
			try {
				console.log(JSON.stringify(error));
				new Snackbar(`ERROR: ${error.error.reason ?? error.error.message}`);
			} catch (error) {
				console.log(error);
				new Snackbar("Failed to get special photos");
			}
			return false;
		});

	return res;
};
export interface IGetMainSpecialPhotoParams {
	target?: number;
	media_cat: number;
	specialCat_id?: number;

}
export const getMainSpecialPhoto = async (params: IGetMainSpecialPhotoParams) => {
	// console.log("params get ", params);
	const res = await axios
		.post(`/client/special/getMain`, params)
		.then((respons) => {
			console.log(respons);
			return respons;
		})
		.catch((error) => {
			try {
				console.log(JSON.stringify(error));
				new Snackbar(`ERROR: ${error.error.reason ?? error.error.message}`);
			} catch (error) {
				console.log(error);
				new Snackbar("Failed to get special photos");
			}
			return false;
		});

	return res;
};
