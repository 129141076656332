export default class AppRoutePath {
  static login = "/";
  static loginPage = "/login";
  static register = "/register";
  static accountType = "/account-type";
  static forgetPassword = "/forget-password";
  static reloadFlower = "/reload-flower";
  static loadCoin = "/load-coin";
  static loadCoinEmpty = "/load-coin/empty"; // /load-coin-empty
  static masterCreateShop = "/master/account/shop/create"; // /load-coin-empty
  static reloadCoin = "/reload-coin";
  static reloadCoinImageSelecter = "/reload-coin/image-selector" // "/reload-coin-image-selector"
  static reloadCoinGallery = "/reload-coin/gallery"; // "/reload-coin-gallery"
  static reloadCoinUpload = "/reload-coin/upload"; // "/reload-coin-upload"
  static reloadCoinSuccess = "/reload-coin/success"; // /reload-coin-success
  static yourAd = "/your-ad";

  static clientIndividualAdDetails = "/client/individual-ad-details"; // /individual-ad-details
  static clientEditIndividualAdDetails = "/client/edit-individual-ad-details";
  static clientAddDetails = "/client/add-details";
  static clientEditProfile = "/client/edit-profile";
  static clientAddPage = "/client/add-page";
  static clientEditPage = "/client/edit-page";
  static clientMainPage = "/client/main-page";
  static clientWallet = "/client/wallet" // "/client-wallet/:walletType"
  static clientReloadCoin = "/client/reload-coin";
  static clientReloadCoinImageSelecter = "/client/reload-coin/image-selector" // "/reload-coin-image-selector"
  static clientReloadCoinGallery = "/client/reload-coin/gallery"; // "/reload-coin-gallery"
  static clientReloadCoinUpload = "/client/reload-coin/upload"; // "/reload-coin-upload"
  static clientReloadCoinSuccess = "/client/reload-coin/success"; // /reload-coin-success
  static clientAddFlower = "/client/add-flower";
  static clientFollowerList = "/client/follower-list"; 
  static clientInvitationList = "/client/invitation-list"; 
  static clientChangeRegisterNumber = "/client/change-register-number";
  static clientChangeRegisterEmail = "/client/change-register-email";
  static clientChangePassword = "/client/change-password";
  static clientMyInstantReview = "/client/my-instant-reviews";
  static clientAddMyInstantReview = "/client/add-instant-review";
  static clientSystemMessages = '/client/system-messages';
  static clientMyInstantPosts = '/client/my-instant-posts';
  static clientMyIPScheduled = '/client/my-instant-posts/scheduled';
  static clientHistory = '/client/history';
  static clientMemberProfile = '/client/member-profile';
  static clientTchatList = '/client/t-chat-list'
  static clientTchats = '/client/t-chat'
  static clientTrainingPage = '/client/training';
  static clinetContactPage = '/client/contact'
  static clinetQRCodePage = '/client/qrcode'
  static clientSendOneCoin = '/client/send-onecoin'

  static masterIndividualAdDetails = "/master/individual-ad-details/:id";
  static masterEditIndividualAdDetails = "/master/edit-individual-ad-details/:id";

  static masterAddDetails = "/master/add-details";
  static masterEditDetails = "/master/edit-profile";
  static masterAddPage = "/master/add-page";
  static masterAccount = "/master/account";
  static masterAccountDetails = "/master/account/details";
  static masterAccountCreateShops = "/master/account/shop";
  static masterAccountEditShops = "/master/account/shop";
  static masterWallet = "/master/wallet"
  static masterReloadCoin = "/master/reload-coin";
  static masterReloadCoinImageSelecter = "/master/reload-coin/image-selector" // "/reload-coin-image-selector"
  static masterReloadCoinGallery = "/master/reload-coin/gallery"; // "/reload-coin-gallery"
  static masterReloadCoinUpload = "/master/reload-coin/upload"; // "/reload-coin-upload"
  static masterReloadCoinSuccess = "/master/reload-coin/success"; // /reload-coin-success
  static masterFollowerList = "/master/follower-list";
  static masterAdList = "/master/ad-list";
  static masterChangeRegisterNumber = "/master/change-register-number";
  static masterChangeRegisterEmail = "/master/change-register-email";
  static masterChangePassword = "/master/change-password";
  static masterAdsChangePassword = "/master/ads/change-password";
  static masterClientMainPage = "/master/client-main-page";
  static masterMyInstantReview = "/master/my-instant-reviews";
  static masterAddMyInstantReview = "/master/add-instant-review";
  static masterSystemMessages = '/master/system-messages';
  static masterMyInstantPosts = '/master/my-instant-posts';
  static masterMyIPScheduleds = '/master/my-instant-posts/scheduled';
  static masterHistory = '/master/history';
  static masterMemberProfile = '/master/member-profile';
  // static masterAddDetails = "/master/add-details"; // /master/add-details
  static masterTchatList = '/master/t-chat-list';
  static masterTchats = '/master/t-chats';
  static masterTrainingPage = '/master/training';
  static masterContactPage = '/master/contact';
  static masterAddInstantPost = '/master/add-instant-post';
  static masterAddInstantPosts = '/master/add-instant-posts';
  static masterMyInstantPostView = '/master/view-instant-posts';

  static masterClientQRCodePage = '/master/qrcode'
  static masterSendOneCoin = '/master/send-onecoin'
 
}
