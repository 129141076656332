import Snackbar from "awesome-snackbar";
import UploadModel from "../domain/model/upload-model";
import axios from "./axios";

export const uploadFileToS3 = async (file: File): Promise<UploadModel | null> => {
  const params = new FormData();

  params.append('file', file);
  const res = await axios.post('/service/upload', params)
    .then((respons) => {
      // console.log(`success: ${JSON.stringify(respons)}`);
      return respons.data as UploadModel;
    })
    .catch((error) => {
      try {
        console.log(JSON.stringify(error));
        new Snackbar('ERROR: ' + (error.error.reason ?? error.error.message));
      } catch (error) {
        console.log(error);
        new Snackbar(`Failed to upload ${file.name}`);
      }
      return null;
    });

  return res;
}
export const uploadFileToS3Voice = async (file: File): Promise<UploadModel | null> => {
  const params = new FormData();

  params.append('file', file);
  const res = await axios.post('/service/upload/voice', params)
    .then((respons) => {
      return respons.data as UploadModel;
    })
    .catch((error) => {
      try {
        console.log(JSON.stringify(error));
        new Snackbar('ERROR: ' + (error.error.reason ?? error.error.message));
      } catch (error) {
        console.log(error);
        new Snackbar(`Failed to upload ${file.name}`);
      }
      return null;
    });

  return res;
}

export const masterShopUpload = async (fullpath: string) => {
  try {
    const response = await axios.post('/master/shop/img/upload', {
      fullpath: fullpath,
    });

    if (response.status === 200) {
      return response.data;
    } else {
      console.error('API request failed with status:', response.status);
      return null;
    }
  } catch (error) {
    console.error('An error occurred during the API request:', error);
    return null;
  }
};

export const getMasterShopUpload = async () => {
  try {
    const response = await axios.get('/master/shop/img/describe');

    if (response.status === 200) {
      return response.data;
    } else {
      console.error('API request failed with status:', response.status);
      return null;
    }
  } catch (error) {
    console.error('An error occurred during the API request:', error);
    return null;
  }
};

export const deleteMasterShopBanner = async (filename: any) => {
  try {
    const response = await axios.delete('/master/shop/img/delete', {
      data: {
        filename: filename,
      }
    },);

    if (response.status === 200) {
      return response.data;
    } else {
      console.error('API request failed with status:', response.status);
      return null;
    }
  } catch (error) {
    console.error('An error occurred during the API request:', error);
    return null;
  }
};