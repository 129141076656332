import Snackbar from "awesome-snackbar";
import {
	GetInstantsReviewParams,
	InstantReviewListModel,
	InstantReviewModel,
} from "../domain/model/instant-review-model";
import axios from "./axios";
import InstantPostsModel, {
	InstantPostModel,
} from "../domain/model/instant-post-model";
import InstantPostCommentsModel from "../domain/model/instant-post-comment-model";
import InstantReviewPostCommentsModel from "../domain/model/instant-review-comment-model";


export const getInstantsReview = async (params?: GetInstantsReviewParams) => {
	// console.log(params)
	const res = await axios
		.get("/user/instants", { params })
		.then((respons) => {
			// console.log(respons);
			return respons.data as InstantReviewListModel;
		})
		.catch((error) => {
			try {
				console.log(JSON.stringify(error));
				new Snackbar("ERROR: " + (error.error.reason ?? error.error.message));
			} catch (error) {
				console.log(error);
				new Snackbar("Failed to get instant review");
			}
			return null;
		});

	return res;
};

//  get selected instant review details 
export const getInstantsReviewDetails = async (id: any) => {
	const res = await axios
		.get(`/user/instant/${id}`)
		.then((respons) => {
			return respons.data as InstantReviewModel;
		})
		.catch((error) => {
			try {
				new Snackbar("ERROR: " + (error.error.reason ?? error.error.message));
			} catch (error) {
				new Snackbar("Failed to get instant post");
			}
			return null;
		});

	return res;
};

//  delete selected instan review post 
export const deleteInstantReviewPost = async (id: string) => {
	const res = await axios
		.delete(`/user/instant/${id}`)
		.then((respons) => {
			new Snackbar("Instant post successfully deleted");
			return true;
		})
		.catch((error) => {
			try {
				new Snackbar(error.error.reason);
			} catch (error) {
				new Snackbar("Failed to delete instant post");
			}
			return null;
		});

	return res;
};

export const publishInstantReviewPost = async (payload: any) => {
	console.log("Instant Review Post Paylaod :" + JSON.stringify(payload));

	const res = await axios.post("/user/instant/add", payload)
		.then((respons) => {
			new Snackbar("Instant review post has been successfully published");
			return respons.data as InstantPostModel;
		})
		.catch((error) => {
			new Snackbar("ERROR: " + (error.error.reason ?? error.error.message) + "<br>" + "Failed to publish instant post");
			return null;
		});

	return res
};

//  add comment on  selected instan review post  
export const commentSelectedInstantReviewPost = async (payload: any) => {
	const res = await axios
		.post(`/user/comment`, payload)
		.then((respons) => {
			new Snackbar("successfully commented on instant review post");
			return true;
		})
		.catch((error) => {
			try {
				new Snackbar(error.error.reason);
			} catch (error) {
				new Snackbar("Failed to comment on  instant review post");
			}
			return null;
		});

	return res;
};


//  get instant review post comments 
export const getInstantsReviewcomments = async (id: any) => {
	const res = await axios
		.get(`/user/comments/${id}`)
		.then((respons) => {
			// console.log(JSON.stringify(respons))
			return respons.data as InstantReviewPostCommentsModel
		})
		.catch((error) => {
			try {
				new Snackbar("ERROR: " + (error.error.reason ?? error.error.message));
			} catch (error) {
				new Snackbar("Failed to get instant review post comments");
			}
			return null;
		});
	return res;
};



//  Delete selected comment from a instant review post  
export const deleteSelectedCommentInstantPost = async (id: any) => {
	const res = await axios
		.delete(`user/comment/${id}`)
		.then((respons) => {
			new Snackbar("comment successfully deleted on instant review post");
			return true;
		})
		.catch((error) => {
			try {
				new Snackbar(error.error.reason);
			} catch (error) {
				new Snackbar("Failed to delete comment on  instant review post");
			}
			return null;
		});

	return res;
};
//  edit / update  selected comment from a instant review post  
export const updateSelectedCommentInstantPost = async (payload: any, id: number) => {
	const res = await axios
		.put(`user/comment/${id}`, payload)
		.then((respons) => {
			new Snackbar("comment successfully updated on instant review post");
			return true;
		})
		.catch((error) => {
			try {
				new Snackbar(error.error.reason);
			} catch (error) {
				new Snackbar("Failed to updated comment on  instant review post");
			}
			return null;
		});

	return res;
};



//  Instant post 

// all post list either scheduled or normal post
export const getInstantsPosts = async (scheduled?: boolean, page?: number, per_page?: number, desc_sort?: boolean, id?: number) => {



	const res = await axios
		.get("/client/posts", {
			params: {
				scheduled_all: scheduled ? scheduled : undefined,
				page: page ? page : undefined,
				per_page: per_page ? per_page : undefined,
				desc_sort: desc_sort ? desc_sort : undefined,
				id: id ? id : undefined,
			},
		})
		.then((respons) => {
			// console.log(JSON.stringify(respons));
			return respons.data as InstantPostsModel;
			// return respons.data 
		})
		.catch((error) => {
			try {
				new Snackbar("ERROR: " + (error.error.reason ?? error.error.message));
			} catch (error) {
				new Snackbar("Failed to get instant post");
			}
			return null;
		});

	return res;
};

//  get selected post details 
export const findInstantPost = async (id: string) => {
	const res = await axios
		.get(`/client/post/${id}`)
		.then((respons) => {
			return respons.data as InstantPostModel;
		})
		.catch((error) => {
			try {
				new Snackbar("ERROR: " + (error.error.reason ?? error.error.message));
			} catch (error) {
				new Snackbar("Failed to get instant post");
			}
			return null;
		});

	return res;
};

//  get selected post details 
export const findInstantPostView = async (payload: any) => {

	console.log("post Id ", payload);

	const res = await axios
		.get(`/client/view/post`, {
			params: {
				post_id: payload.post_id,
				target: payload.target
			}
		})
		.then((respons) => {
			return respons.data as InstantPostModel;
		})
		.catch((error) => {
			try {
				new Snackbar("ERROR: " + (error.error.reason ?? error.error.message));
			} catch (error) {
				new Snackbar("Failed to get instant post");
			}
			return null;
		});

	return res;
};

// get selected post details 
export const getInstantPostById = async (id: string) => {
	const res = await axios
		.get(`/client/post/${id}`)
		.then((respons) => {
			return respons.data as InstantPostModel;
		})
		.catch((error) => {
			try {
				new Snackbar("ERROR: " + (error.error.reason ?? error.error.message));
			} catch (error) {
				new Snackbar("Failed to get instant post");
			}
			return null;
		});

	return res;
};

export const publishInstantPost = async (payload: any) => {
	console.log("Post Paylaod :" + JSON.stringify(payload));

	const res = await axios.post("/client/post", payload)
		.then((respons) => {
			if (payload?.scheduled) {
				new Snackbar(
					"Scheduled instant post has been successfully published"
				);
			} else {
				new Snackbar("Instant post has been successfully published");
			}
			return respons.data as InstantPostModel;
		})
		.catch((error) => {

			new Snackbar("ERROR: " + (error.error.reason ?? error.error.message) + "<br>" + "Failed to publish instant post");

			return null;
		});

	return res
};

export const editInstantPost = async (payload: any, id: string) => {
	const res = await axios.put(`/client/post/${id}`, payload)
		.then((respons) => {
			if (payload?.scheduled) {
				new Snackbar(
					"Scheduled instant post has been successfully updated"
				);
			} else {
				new Snackbar("Instant post has been successfully updated");
			}
			return respons.data as InstantPostModel;
		})
		.catch((error) => {
			new Snackbar("ERROR: " + (error.error.reason ?? error.error.message) + "<br>" + "Failed to update instant post");

			return null;
		});

	return res
};


//  delete selected instan post 
export const deleteInstantPost = async (id: string) => {
	const res = await axios
		.delete(`/client/post/${id}`)
		.then((respons) => {
			new Snackbar("Instant post successfully deleted");
			return true;
		})
		.catch((error) => {
			try {
				new Snackbar(error.error.reason);
			} catch (error) {
				new Snackbar("Failed to delete instant post");
			}
			return null;
		});

	return res;
};
//  add comment on  selected instan post 



//  add comment on  selected instan review post  
export const commentSelectedInstantPost = async (payload: any) => {

	console.log(payload);

	const res = await axios
		.post(`/user/instant-comment`, payload)
		.then((respons) => {
			new Snackbar("successfully commented on instant  post");
			return true;
		})
		.catch((error) => {
			try {
				new Snackbar(error.error.reason);
			} catch (error) {
				new Snackbar("Failed to comment on  instant  post");
			}
			return null;
		});

	return res;
};

// Instant POST


//  get selected comment of instant post  
export const findInstantPostComments = async (id: any) => {
	const res = await axios
		.get(`/user/instant-comments/${id}`,)
		.then((respons) => {

			console.log("res instan post comment ", respons);

			return respons.data as InstantPostCommentsModel;
		})
		.catch((error) => {
			try {
				new Snackbar("ERROR: " + (error.error.reason ?? error.error.message));
			} catch (error) {
				new Snackbar("Failed to get instant post comments");
			}
			return null;
		});

	return res;
};



//  Delete selected comment from a instant  post  
export const deleteCommentInstantPosts = async (id: any) => {
	const res = await axios
		.delete(`user/instant-comment/${id}`)
		.then((respons) => {
			new Snackbar("comment successfully deleted on instant  post");
			return true;
		})
		.catch((error) => {
			try {
				new Snackbar(error.error.reason);
			} catch (error) {
				new Snackbar("Failed to delete comment on  instant review post");
			}
			return null;
		});

	return res;
};
