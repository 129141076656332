import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AppRoutePath from "../../../constants/AppRoutePath";
import CreateUserParams from "../../../domain/model/create-user-params";
import RegistrationSteps from "../navigation/RegistrationSteps";
import LocalStorageKey from "../../../constants/LocalStorageKey";
import { useTranslation } from "react-i18next";

import CustomHeader from "../../reusable/Header";
import TopHearder from "../../reusable/TopHearder";
import { getUserWallet } from "../../../services/products/one-coin";
import { UserWalletModel } from "../../../domain/model/user-getwallet";
import Loaders from "../../reusable/Loaders";

function ReloadCoin(): JSX.Element {
  const [walletData, setWalletData] = React.useState<UserWalletModel>();
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const createUserParams = CreateUserParams.getInstance();
  const navigate = useNavigate();
  const isMaster = window.location.pathname.includes("master");
  const isLogin =
    (localStorage.getItem(LocalStorageKey.isLogin) ?? "false") === "true";
  const { t } = useTranslation();

  const handleBrowse = async () => {
    isLogin
      ? navigate(AppRoutePath.reloadCoinImageSelecter)
      : navigate(
          isMaster
            ? AppRoutePath.masterReloadCoinImageSelecter
            : AppRoutePath.clientReloadCoinImageSelecter
        );
  };

  useEffect(() => {
    setIsLoading(true);
    getUserWallet().then((res) => {
      if (res != null) {
        setWalletData(res);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  }, []);
  return (
    <>
      <CustomHeader publics={true} />
      <TopHearder title={t("OneCoinReloads1")} />
      <div className="bg-dark">
        <div className="container">
          <div className="inner-box">
            <div className="accordion pt-3" id="accordionExample">
              {isLogin ? <></> : <RegistrationSteps step={1} />}

              <div className="tab-content">
                <div id="headingTwo"></div>
                <div
                  id="collapseTwo"
                  className="collapse show text-center"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  {/* <img src="/img/qrcode.png" className="text-center" alt="" /> */}

                  <div className="text-center ">
                    {isLoading ? <Loaders /> : ""}
                  </div>
                  {walletData && (
                    <>
                      <img
                        src={walletData?.qrurl}
                        className="text-center rounded-1"
                        alt=""
                        width={220}
                      />
                      <p className="qr-link mt-2">{walletData?.w_id}</p>
                    </>
                  )}

                  <h4 className="mt-3 mb-1 qr-title">{t("transferUSD")} </h4>
                  <p className="qr-subtitle">{t("transferUSD1")} </p>
                  <label htmlFor="" className="text-dim mb-2">
                    {t("uploadFile")}
                  </label>
                  <button
                    className="btn primary-button w-100 text-end rounded-button px-3"
                    onClick={handleBrowse}
                  >
                    <i className="fa fa-angle-down"></i>
                    {t("Browse")}
                  </button>

                  <div
                    style={{ display: "none" }}
                    className="gallery-box text-center mt-4 p-3"
                  >
                    <div className="row mx-auto my-auto justify-content-center">
                      <h5 className="text-start gallery-title mb-2">Gallery</h5>
                      <div
                        id="recipeCarousel"
                        className="carousel slide"
                        data-bs-ride="carousel"
                      >
                        <div className="carousel-inner" role="listbox">
                          <div className="carousel-item active">
                            <div className="col-md-4">
                              <div className="card-img">
                                <img
                                  src="img/gallery/1.png"
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="carousel-item">
                            <div className="col-md-4">
                              <div className="card-img">
                                <img
                                  src="img/gallery/2.png"
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="carousel-item">
                            <div className="col-md-4">
                              <div className="card-img">
                                <img
                                  src="img/gallery/1.png"
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="carousel-item">
                            <div className="col-md-4">
                              <div className="card-img">
                                <img
                                  src="img/gallery/2.png"
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="carousel-item">
                            <div className="col-md-4">
                              <div className="card-img">
                                <img
                                  src="img/gallery/1.png"
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="carousel-item">
                            <div className="col-md-4">
                              <div className="card-img">
                                <img
                                  src="img/gallery/2.png"
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <a
                          className="carousel-control-prev bg-transparent w-aut"
                          href="#recipeCarousel"
                          role="button"
                          data-bs-slide="prev"
                        >
                          <span
                            className="carousel-control-prev-icon"
                            aria-hidden="true"
                          ></span>
                        </a>
                        <a
                          className="carousel-control-next bg-transparent w-aut"
                          href="#recipeCarousel"
                          role="button"
                          data-bs-slide="next"
                        >
                          <span
                            className="carousel-control-next-icon"
                            aria-hidden="true"
                          ></span>
                        </a>
                      </div>
                      <Link
                        to={
                          isMaster
                            ? AppRoutePath.masterReloadCoinImageSelecter
                            : AppRoutePath.clientReloadCoinImageSelecter
                        }
                      >
                        <button className="btn primary-button w-80 rounded-button mt-3 px-3">
                          Browse Your File
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReloadCoin;
