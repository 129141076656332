import Snackbar from "awesome-snackbar";
import axios from "../axios";
import {
  OneCoinRequestListModel,
  UserWalletModel,
  requestOneCoinParams,
} from "../../domain/model/user-getwallet";

export interface walletTranParams {
  page?: Number;
  per_page?: Number;
  to_user?: any[];
  to_post?: any[];
  from?: any[];
  to?: any[];
  tx_id?: string;
  start_at?: string;
  end_at?: string;
  desc_sort?: boolean;
  is_free?: boolean;
  order_cs_id?: Number;
}
export interface rechargeOneCoinParams {
  message?: string;
  reason?: string;
  result_code?: number

}

export const findCoinBalance = async (signal?: AbortSignal) => {
  try {
    const response = await axios.get("/product/coin/balance", { signal });

    return response.data.balance as number;
  } catch (error: any) {
    try {
      console.log(JSON.stringify(error));
      if ((error.error.reason || error.error.message) === "Token Expired") {
      }
    } catch (error) {
      console.log(error);
    }
    return 0;
  }
};
export const findCoinBalanceISEmpty = async () => {
  try {
    const response = await axios.get("/product/coin/balance");
    console.log("coin/balance ", response.data);

    return response.data as rechargeOneCoinParams;
    // return response.data.balance as number;
  } catch (error: any) {
    try {
      console.log(JSON.stringify(error));
      if ((error.error.reason || error.error.message) === "Token Expired") {
      }
    } catch (error) {
      console.log(error);
    }
    return 0;
  }
};

export const rechargeOneCoin = async () => {
  const token = `${localStorage.getItem("token")}`;
  const coins = 1000;
  const remark = "U";
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const isSuccess = await axios
    .post("/product/coin/recharge", { coins, remark }, { headers })
    .then((respons) => {
      return true;
    })
    .catch((error) => {
      try {
        console.log(JSON.stringify(error));
        new Snackbar(error.error.reason);
      } catch (error2) {
        console.log(error2);
        new Snackbar("Failed to reload coin");
      }
      return false;
    });

  return isSuccess;
};
export const rechargeOneCoinReal = async () => {
  // const token = `${localStorage.getItem("token")}`;
  // const coins = 1000;
  const remark = "U";
  // const headers = {
  //   Authorization: `Bearer ${token}`,
  // };

  const isSuccess = await axios
    .post("/product/oneCoin/topup", { remark })
    .then((respons) => {
      return respons.data;
    })
    .catch((error) => {
      try {
        console.log(JSON.stringify(error));
        new Snackbar(error.error.reason);
      } catch (error2) {
        console.log(error2);
        new Snackbar("Failed to reload coin");
      }
      return false;
    });

  return isSuccess;
};

export const findOneCoinTransaction = async (params: walletTranParams) => {
  const isSuccess = await axios
    .get(`/product/coin/transactions`, {
      params,
    })
    .then((respons) => {
      console.log("One Coin Tran ", respons.data);
      return respons.data;
      // return respons.data['item(s)'];
    })
    .catch((error) => {
      console.log(JSON.stringify(error));
      // new Snackbar(error.error.reason);
      return [];
    });

  return isSuccess;
};

export const getUserWallet = async () => {
  try {
    const data = await axios.get("/product/wallet");
    return data.data as UserWalletModel;
  } catch (e) {
    console.log(e);
  }
};
export const getAllOneCoinRequestList = async (params?: any) => {
  try {
    console.log("request Params ", params);

    const data = await axios.post("/oneCoin/transfer/request/list",
      params
    );

    // console.log("ggg gf ", data.data);

    return data.data;
  } catch (e) {
    console.log(e);
  }
};
export const sendOneCoinRequest = async (params?: any) => {
  try {
    console.log("request One CoinParams ", params.user_id);

    const data = await axios.post("/oneCoin/transfer/request/send", {
      user_id: params.user_id,
    });

    return data.data;
  } catch (e) {
    console.log(e);
  }
};
export const sendOneCoinRequestMobleOREmail = async (params?: any) => {
  try {
    console.log("request One CoinParams ", params);

    // let params = {}

    const data = await axios.post("/oneCoin/transfer/sendRequests",
      params
    );

    return data.data;
  } catch (e) {
    console.log(e);
  }
};

export const answerOneCoinRequest = async (params?: any) => {
  try {
    console.log("Answer request One CoinParams ", params);

    const data = await axios.post("/oneCoin/transfer/request/answer", {
      status: params.status,
      request_id: params.request_id,
    });

    return data.data;
  } catch (e) {
    console.log(e);
  }
};

export const transferOneCoinRequest = async (params?: any) => {
  try {
    console.log("Transfer request One CoinParams ", params);

    const data = await axios.post("/oneCoin/transfer", {
      receiver: params.receiver,
      coins: params.coins,
    });

    return data.data;
  } catch (e) {
    console.log(e);
  }
};
