export enum WalletType {
	OneCoin = "one-coin",
	Flower = "flower",
	Heart = "heart",
}

export const getWalletTypeByString = (str: string) => {
	switch (str) {
		case "one-coin":
			return WalletType.OneCoin;
		case "flower":
			return WalletType.Flower;
		case "heart":
			return WalletType.Heart;
		default:
			return WalletType.OneCoin;
	}
};

export function getIconWallet({ type }: { type: WalletType }): JSX.Element {
	switch (type) {
		case WalletType.OneCoin:
			return <img src='/img/onecoin_icon.png' alt='' />;
		case WalletType.Flower:
			return <img src='/img/flower_icon.png' alt='' />;
		case WalletType.Heart:
			return <i className='fa-solid fa-heart icon'></i>;
		default:
			return <img src='/img/onecoin_icon.png' alt='' />;
	}
}
