import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import AppRoutePath from "../../../../constants/AppRoutePath";
import WalletCardItemComp from "./WalletCardItemComp";

import { useTranslation } from "react-i18next";
import SizedBox from "../../../reusable/Sizedbox";
export interface IHeartCardCompProps {
  coin?: any;
}

export default function HeartCardComp({ coin = [] }: IHeartCardCompProps) {
  const isMaster = window.location.pathname.includes("master");
  const navigate = useNavigate();
  const heartIcon = <i className="fa-solid fa-heart icon"></i>;
  const langType = localStorage.getItem("i18nextLng");
  const MasterAdsCount = localStorage.getItem("adsTotal");
  const userRoles = localStorage.getItem("role");
  const EXT = localStorage.getItem("EXT");

  const { t } = useTranslation();
  return (
    <div className="card  d-flex flex-row bd-highlight text-center bg-white">
      {/* {JSON.stringify(coin)} */}
      <div className="card-body">
        <WalletCardItemComp
          title={t("heartbalance1")}
          // icon={heartIcon}
          // value={}
          langType={langType || "en"}
        />

        {coin &&
          coin?.map((data: any, index: any) => {
            const { prod_name, quantity, remark } = data;
            const [englishName, chineseName] = prod_name.split("|");
            return (
              <React.Fragment key={index}>
                <WalletCardItemComp
                  engTitle={englishName}
                  ChiTitle={chineseName}
                  icon={heartIcon}
                  value={quantity}
                  langType={langType || "en"}
                />
              </React.Fragment>
            );
          })}

        <div className="abs-btn2 position-absolute d-flex justify-content-center gap-2">
          {(MasterAdsCount !== "0" && userRoles === "master") ||
          (userRoles === "client" && (EXT === "false" || EXT === null)) ? (
            <>
              {/* <Link
                to={`${
                  isMaster
                    ? AppRoutePath.masterWallet
                    : AppRoutePath.clientWallet
                }/heart/reload`}
              >
                <button
                  className={`btn primary-button rounded-button coin-btn w-100 m-auto `}
                >
                
                  {t("ReloadHeart")}
                </button>
              </Link> */}
              <button
                className={`btn primary-button rounded-button coin-btn w-80 m-auto`}
                style={{ height: 50 }}
                onClick={() =>
                  navigate(
                    `${
                      isMaster
                        ? AppRoutePath.masterWallet
                        : AppRoutePath.clientWallet
                    }/heart/reload`
                  )
                }
              >
                <i className="fa fa-shopping-cart"></i> {t("ReloadHeart")}
              </button>
            </>
          ) : null}

          <SizedBox width={5} />
          {!isMaster && (
            <button
              className={`btn primary-button rounded-button coin-btn w-80 m-auto`}
              style={{ height: 50 }}
              onClick={() =>
                isMaster
                  ? navigate(AppRoutePath.masterMyInstantPosts)
                  : navigate(AppRoutePath.clientMyInstantPosts)
              }
            >
              {t("sendHeart")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
