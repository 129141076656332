import * as React from "react";
import {
  findFlowerBalance,
  findFlowerRanking,
  purchaseFlower,
} from "../../../../services/products/flower";
import { findCoinBalance } from "../../../../services/products/one-coin";
import WalletPaymentCardItem from "./WalletPaymentCardItem";

import { useTranslation } from "react-i18next";

export interface IReloadFlowerCardCompProps {
  updateTable?: () => void;
}

export default function ReloadFlowerCardComp(
  props: IReloadFlowerCardCompProps
) {
  const iconFlower = (
    <img
      src="/img/flower_icon.png"
      style={{ marginBottom: 3, height: "75%" }}
      alt=""
    />
  );
  const iconCoin = (
    <img
      src="/img/onecoin_icon.png"
      style={{ marginBottom: 3, height: 25, width: 25 }}
      alt=""
    />
  );
  const [flowerRanking, setFlowerRanking] = React.useState<Ranking>({
    rank: "N/A ",
  });
  const [clientPriceList, setClientPriceList] = React.useState([]);
  const [type, setType] = React.useState<String>();
  const isMaster = window.location.pathname.includes("master");
  const priceList: Record<string, any> = JSON.parse(
    localStorage.getItem("priceList") || "{}"
  );
  const userType = localStorage.getItem("userType") || "";
  const { t } = useTranslation();

  React.useEffect(() => {
    if (!isMaster) {
      findFlowerRanking().then((res) => {
        if (res.rank) {
          setFlowerRanking(res);
        }
      });
    }

    if (userType != "") {
      const flowerBackage: any = [];
      for (const key in priceList) {
        // console.log("Kkk ", key);

        if (key.includes("FLOWERx")) {
          flowerBackage.push([key, priceList[key]]);
        }
      }
      setClientPriceList(flowerBackage);
    }
  }, []);

  return (
    <div>
      <div className="flower-box">
        <h5 className="balance-title mb-0 d-flex align-items-center justify-content-center">
          {/* Current FLOWER Ranking */}

          {t("currentFlowerRanking")}
        </h5>
        <h5 className="balance-value d-flex align-items-center justify-content-center gap-1">
          {flowerRanking.rank}
        </h5>
        <hr className="mb-0" style={{ borderTop: "1.5px solid #ff3751" }} />
      </div>

      {clientPriceList.map((data: any, index: Number) => {
        // console.log("clientPriceList " ,data);

        const price = data[1];
        const productName = data[0];
        const [englishName, chineseName] = productName.split("|");

        // console.log(englishName);

        return (
          <>
            <WalletPaymentCardItem
              englishName={englishName}
              chineseName={chineseName}
              packagePrice={price}
              productName={productName}
              // productID={productID}

              walletName={t("flowerBal")}
              paymentName={t("Reloads")}
              initQuantity={1}
              multiple={1}
              coinPerBalanceCount={1}
              getBalance={findFlowerBalance}
              getCoinBalance={findCoinBalance}
              getNewRanking={async () => {
                return 9;
              }}
              getBalanceRanking={async () => {
                const res: Ranking = {
                  rank: "N/A",
                  units: "N/A",
                  user_id: "0",
                };
                return res;
              }}
              sendPayment={async (unit, target, prod_id) =>
                purchaseFlower(unit, prod_id)
              }
              icon={iconFlower}
              coinIcon={iconCoin}
              updateTable={props.updateTable}
            />
          </>
        );
      })}
    </div>
  );
}
