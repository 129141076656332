import { WalletTableRow } from "../interface/WalletTableRow";

import { sendInvitaion } from "../../../../services/client/client-invitation";

const langType = localStorage.getItem("i18nextLng");
const user_id = localStorage.getItem("user_id");

const flowerTableRow: WalletTableRow[] = [
  {
    label: "My FLOWERs Receiving History",
    div: true,
    langType: langType,
    colName: [
      "Time",
      "Sender Name",
      "Receiver Name",
      "Product Name",
      "Following Request",
    ],
    rowDataComp: (data: any, remarksData: any, friendList: any) => {
      return (
        <tr>
          <td className="text-center">
            <span className="d-block "> {data.sent_time?.split(" ")[0]}</span>
            <small className="d-block time-col">
              {" "}
              {data.sent_time?.split(" ")[1]}
            </small>
          </td>
          <td>
            <span>{data?.sender ? data?.sender : "N/A"}</span>
            {langType === "zh" ? data.sender_zh_name : data.sender_en_name}
          </td>
          <td>
            <span>{data?.receiver ? data?.receiver : "N/A"}</span>

            {langType === "zh" ? data.receiver_zh_name : data.receiver_en_name}
          </td>

          <td>
            {langType === "zh"
              ? data.product?.split("|")[1]
              : data.product?.split("|")[0]}
          </td>

          <td>
            {data?.sender === Number(user_id) ? (
              <>
                <button className="request-btn f-btn ">My Account</button>
              </>
            ) : (
              <div>
                {friendList.includes(data?.sender) ? (
                  <button className={"request-btn "}>Following</button>
                ) : (
                  <button
                    className={"request-btn"}
                    onClick={() =>
                      sendInvitaion({
                        target: data?.sender,
                      })
                    }
                  >
                    Send Invitation
                  </button>
                )}
              </div>
            )}
          </td>
        </tr>
      );
    },
  },
];

export default flowerTableRow;
