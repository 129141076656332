import React from "react";

import CustomHeader from "../reusable/Header";
import TopHearder from "../reusable/TopHearder";
import { useTranslation } from "react-i18next";

//  const  TrainingPage(): React.FC<AudioRecorderProps> = ({ onStop }){

const TrainingPage = () => {
  const { t } = useTranslation();

  return (
    <>
    
      <CustomHeader />
      <TopHearder title={t("training")} />
      <div className="bg-dark">
        <div className="container py-5">
          <h1 className="text-center"> Training Page</h1>
          <div></div>
        </div>
      </div>
    </>
  );
};

export default TrainingPage;
