import React, { useState, useEffect } from "react";
import CustomNavigation from "../reusable/Navigation";
import AccountTypeConst from "../../domain/enum/account-type";
import { Link, Navigate, useNavigate } from "react-router-dom";
import AppRoutePath from "../../constants/AppRoutePath";
import CustomEmptyMessage from "../reusable/EmptyMessage";
import moment from "moment";
import InstantPostsModel from "../../domain/model/instant-post-model";
import { Modal, Spinner } from "react-bootstrap";
import ChatCard from "../subcomponents/mixed/ChatCard";
import { getSystemMessage } from "../../services/system-notification";
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import TopHearder from "../reusable/TopHearder";
export interface IHistoryPageProps {}
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: require('../../locales/en.json') },
      zh: { translation: require('../../locales/zh.json') }
    },
    fallbackLng: 'en', // Default language fallback
    interpolation: {
      escapeValue: false // Disable HTML escaping
    }
  });
export default function TChatList(): JSX.Element {
  const isMaster = window.location.pathname.includes("master");
  const [isLoading, setIsLoading] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(true);
  const navigate = useNavigate();
  const [posts, setPosts] = useState<InstantPostsModel | null>();
  const [notifCount, setNotifCount] = useState(0);
  const { t } = useTranslation();
  const [language, setLanguage] = React.useState("en"); // Default language is English
  const handleLanguageSwitch = () => {
    const newLanguage = language === "en" ? "zh" : "en";
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage); // Update the language setting
  };
  // useEffect(() => {
  //   getSystemMessage({ status: 0 }).then((res) => {
  //     if (res) {
  //       setNotifCount(res.length);
  //     }
  //   });
  // }, []);

  return (
    <>
      <CustomNavigation
        isOpen={isNavOpen}
        onCallback={(value: boolean) => {
          setIsNavOpen(value);
        }}
        accountType={
          isMaster ? AccountTypeConst.Master : AccountTypeConst.Client
        }
      />
      <header>
        <a
          className="lft-arw"
          onClick={
            () => window.history.back()
            // Navigate(
            //   isMaster
            //     ? AppRoutePath.masterAccount
            //     : AppRoutePath.clientMainPage
            // )
          }
        >
          <i className="fa-solid fa-chevron-left"></i>
        </a>
        <div className="title">
          {" "}
          {/* <i className="fa-regular fa-comment"></i>  */}
          <img
            style={{ width: 22, marginRight: 7 }}
            src="/img/tchat_newlogo_white.png"
            className="default-logo"
          />
          {t('tchat')} {t('list')}
        </div>
        <div className="d-flex justify-content-end pt-5">
          <p className="lang">
            <button onClick={handleLanguageSwitch} className="menu-language">{language === "en" ? "中文" : "ENG"} {/* Display "ENG" or "中文" based on language */}</button>
          </p>
        </div>
        <a href="/client/system-messages" className="stn-btn">
          <i className="fa-regular fa-comment"></i>
          <i className="fa-solid fa-gear"></i>
          {notifCount > 0 && <span>{notifCount}</span>}
        </a>
        <a className="clk-btn" onClick={() => setIsNavOpen(!isNavOpen)}>
          <i className="fa-solid fa-bars"></i>
        </a>
      </header>
      <TopHearder title={t('tchat') }/>
      <div className="bg-dark">
        <div className="container py-5">
          {/* <div className="inner-box pt-5"> */}
          {tchatListData.map((data, i) => {
            return (
              <Link
                to={
                  isMaster
                    ? AppRoutePath.masterTchats
                    : AppRoutePath.clientTchats
                }
                key={i + 1}
              >
                <ChatCard
                  fans={data.fans}
                  photo={data.photo}
                  nickname={data.nickname}
                  content={data.content}
                  newMsgCount={data.newMsgCount}
                  date={data.date}
                  contentClass="text-truncate w-75"
                />
              </Link>
            );
          })}
          {/* </div> */}
        </div>
      </div>
    </>
  );
}

const tchatListData = [
  {
    fans: 1,
    nickname: "John",
    content:
      "This is testing message, This is testing message, This is testing message",
    date: "05-07-2023",
    photo: "",
    newMsgCount: 15,
  },
  {
    fans: 2,
    nickname: "Rostam",
    content: "This is testing message",
    date: "05-07-2023",
    photo:
      "	https://d2zzhkl4ews4a1.cloudfront.net/viewer/516/media/ad/ad_516_1686253795_1.PNG",
    // newMsgCount: ,
  },
  {
    fans: 3,
    nickname: "Rostam",
    content: "This is testing message",
    date: "05-07-2023",
    photo: "",
    newMsgCount: 78,
  },
  {
    fans: 4,
    nickname: "Rostam",
    content: "This is testing message",
    date: "05-07-2023",
    photo: "/img/luca-bravo.jpg",
    newMsgCount: 78,
  },
  {
    fans: 5,
    nickname: "Rostam",
    content: "This is testing message",
    date: "05-07-2023",
    photo: "",
    newMsgCount: 78,
  },
];
