import React, { useState } from "react";
import { Link } from "react-router-dom";
import AppRoutePath from "../../../constants/AppRoutePath";
import CustomBackButton from "../../reusable/BackButton";
import CustomHeader from "../../reusable/Header";
import TopHearder from "../../reusable/TopHearder";
import { useTranslation } from "react-i18next";

interface Images {
  [key: string]: boolean;
}

function ReloadCoinGallery() {
  const { t } = useTranslation();
  const [images, setImages] = useState<Images>({
    "/img/annie-spratt.jpg": false,
    "/img/luca-bravo.jpg": false,
    "/img/muneeb-syed.jpg": false,
    "/img/vladimir-kudinov.jpg": false,
  });

  return (
    <>
      {/* <header>
                <CustomBackButton />
                <div className='title'>Reload OneCoin</div>

            </header> */}

      <CustomHeader publics={true} />
      <TopHearder title={t("OneCoinReloads1")} isBackRoute={true} />
      <div className="bg-dark">
        <div className="container">
          <div className="inner-box pt-4">
            <div className="row g-1">
              {Object.keys(images).map((e, i) => {
                const value = images[e];
                const id = `image-${i}`;

                return (
                  <div className="col-md-4" key={e}>
                    <div className="custom-control custom-checkbox image-checkbox">
                      <input
                        id={id}
                        type="checkbox"
                        className="custom-control-input"
                        checked={value}
                        onChange={() => {
                          images[e] = !value;

                          setImages({
                            ...images,
                          });
                        }}
                      />
                      <label className="custom-control-label" htmlFor={id}>
                        <img src={e} alt="#" className="img-fluid" />
                      </label>
                    </div>
                  </div>
                );
              })}
            </div>

            <Link to={AppRoutePath.reloadCoinUpload}>
              <button className="primary-button button mt-3 w-100 l-1">
                Upload Photo
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReloadCoinGallery;
