/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { Modal, OverlayTrigger, Popover, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import AppRoutePath from "../../../../constants/AppRoutePath";
import SizedBox from "../../../reusable/Sizedbox";
import Snackbar from "awesome-snackbar";

import { useTranslation } from "react-i18next";

import { getSelectedProductsIds } from "../../../../services/modules";

export interface IWalletPaymentCardItemProps {
  chineseName?: string;
  englishName?: string;
  packageName?: string;
  productName: string;
  // productID: number;
  packagePrice: number;
  walletName?: string;
  paymentName: string;
  packageDay?: number;
  initQuantity: number;
  getBalance: () => Promise<number>;
  // getBalance: () => Promise<{ name: string; balance: number }[]>;
  getCoinBalance: () => Promise<number>;
  multiple: number;
  coinPerBalanceCount: number;
  getBalanceRanking: () => Promise<Ranking>;
  getNewRanking: () => Promise<number>;
  sendPayment: (
    units: number,
    targets: string,
    prod_id: number
    // prod_name: string
  ) => Promise<boolean>;
  icon: JSX.Element;
  coinIcon: JSX.Element;
  updateTable?: () => void;
}

export default function WalletPaymentCardItem(
  props: IWalletPaymentCardItemProps
) {
  const navigate = useNavigate();
  const isMaster = window.location.pathname.includes("master");
  const isEXT = localStorage.getItem("EXT") == "true";
  const isEmailLogin = localStorage.getItem("email_login") == "true";
  const [balance, setBalance] = React.useState(0);
  const [coinBalance, setCoinBalance] = React.useState(0);
  // const [newRanking, setNewRanking] = React.useState(0);
  const [quantity, setQuantity] = React.useState(props.initQuantity);
  const [productNames, setProductNames] = React.useState(props.productName);
  const [isBalanceLoading, setIsBalanceLoading] = React.useState(false);
  const [balanceRanking, setBalanceRanking] = React.useState<Ranking | null>(
    null
  );

  const [showPopover, setShowPopover] = React.useState(false);
  const [isDialogInsufficientCoin, setIsDialogInsufficientCoin] =
    React.useState(false);
  const [isDialogAddBalanceShow, setIsDialogAddBalanceShow] =
    React.useState(false);
  const [showSpinner, setShowSpinner] = React.useState(false);
  const [isReloadPopUpSuccessful, setIsReloadPopUpSuccessful] =
    React.useState(false);

  const getData = () => {
    setIsBalanceLoading(true);
    props.getBalance().then((res) => {
      console.log("Balance Data", JSON.stringify(res));
      setIsBalanceLoading(false);
      if (Array.isArray(res)) {
        const selectedPackageBalance = res.find(
          (item) => item.prod_name === productNames
        );

        if (selectedPackageBalance) {
          setBalance(selectedPackageBalance.quantity);
        } else {
          setBalance(0);
        }
      } else {
        console.error("Invalid data type for res:", typeof res);
      }
    });

    if (!isEXT) {
      props.getCoinBalance().then((res) => setCoinBalance(res));
      props.getBalanceRanking().then((res) => setBalanceRanking(res));
    }
  };

  const langType = localStorage.getItem("i18nextLng");
  const { t } = useTranslation();
  React.useEffect(getData, [props]);

  const handleMinusClick = () => {
    setQuantity((q) => Math.max(1, q - props.multiple));
  };

  const handlePlusClick = () => {
    setQuantity((q) => q + props.multiple);
  };
  // const handleInputChange = () => {
  //   setQuantity((q) => q + props.multiple);
  // };

  const handleInputChange: React.ChangeEventHandler<HTMLTextAreaElement> = (
    event
  ) => {
    const val = event.target.value;

    setQuantity(Number(val));
  };

  const handlePayment = (productName: string) => {
    console.log("handlePayment ", productName);

    setIsDialogAddBalanceShow(true);
    // if (coinBalance > quantity * props.coinPerBalanceCount) {
    // 	setIsDialogAddBalanceShow(true);
    // } else {
    // 	setIsDialogInsufficientCoin(true);
    // }
  };

  const handlePaymentSubmit = () => {
    setShowSpinner(true); // Show the spinner when the button is clicked
    const units = quantity;
    const targets = balanceRanking!.user_id!;
    const prod_name = productNames;

    // const prod_name = "FLOWERx10|FLOWERx十";

    getSelectedProductsIds(prod_name).then((res: any) => {
      if (res != null) {
        console.log("Product ID res ", res);

        if (coinBalance < quantity * props.packagePrice) {
          setShowPopover(true);
        } else {
          props
            .sendPayment(units, targets, Number(res))
            .then((isSuccess: any) => {
              if (isSuccess?.result_code === 200) {
                getData();
                if (props.updateTable) {
                  props.updateTable();
                }
                // new Snackbar(`${props.paymentName} ${props.walletName} success`);
                setIsDialogAddBalanceShow(false);
                setIsReloadPopUpSuccessful(true);
              }
              if (isSuccess?.result_code !== 200) {
                // console.log("ff", isSuccess);
                new Snackbar(
                  `${props.paymentName} ${props.walletName} Failed, ${isSuccess.reason}`
                );
              }
              setShowSpinner(false);
            });
        }
      }
    });

    getData();
  };

  return (
    <>
      <Modal show={isReloadPopUpSuccessful}>
        <Modal.Body>
          <div className="d-flex justify-content-center align-items-center">
            <Modal.Title>
              <h5 className="modal-title text-center">
                Reload{" "}
                <span
                  className="color-pink-3"
                  style={{ fontWeight: "bold", fontSize: 17 }}
                >
                  <span className="px-1"> {quantity}</span>{" "}
                  {langType === "zh" ? props.chineseName : props.englishName}
                </span>{" "}
                {/* {props.walletName}  */}
                Package Successful
              </h5>
            </Modal.Title>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="col">
              <button
                className="button primary-button rounded-button w-100"
                onClick={() => {
                  setIsReloadPopUpSuccessful(false);
                }}
              >
                OK
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      {/* {isBalanceLoading ? (
        <>
          <div className="text-center text-white">
            <Loaders />
          </div>
        </>
      ) : ( */}
      <>
        <div className="flower-box2 p-lg-3">
          <div className="flower-top d-block">
            <div className="row">
              <div className="col">
                <h3>
                  {props.paymentName}
                  {props.walletName}{" "}
                  <p className="fw-bold primary-color d-flex align-items-center pt-1">
                    {props.englishName}
                    {/* {langType === "zh"
                        ? props.chineseName
                        : props.englishName} */}
                    <span className="flower-top-img ms-1"> {props.icon} </span>
                  </p>
                  {/* {props.paymentName} {props.walletName.toUpperCase()}{" "} */}
                  {/* {(props.packageDay ?? 0) > 0
              ? `for ${props.packageDay} Days`
              : "Now"} */}
                </h3>
              </div>
              <div className="col text-end">
                {" "}
                <div className="">
                  <h3 style={{ width: "100%", fontSize: 13 }}>
                    <span>
                      {/* {(props.packageDay ?? 0) > 0 &&
                    `${props.packageDay} days Package `} */}
                      {/* Current BAL */}
                      {t("currentBAL")}
                    </span>
                  </h3>
                  <h4
                    className="flower-top-balacnce"
                    // style={{
                    //   width: "60%",
                    // }}
                  >
                    {/* {props.icon}  */}
                    {balance}
                  </h4>
                </div>
              </div>
            </div>

            {/*  */}
          </div>
          {/* <div className='flower-bottom'> */}

          <div className="row py-3">
            <div className="col">
              {" "}
              <h3 style={{ width: "100%", fontSize: 13 }}>
                <span className="t-color-2">
                  Package BAL
                  {/* {balance} */}
                </span>
              </h3>
              <h4>
                {props.coinIcon} {props.packagePrice}
              </h4>
            </div>
            <div className="col">
              {" "}
              <button
                className="btn primary-button rounded-button"
                data-bs-toggle="modal"
                data-bs-target="#myModal"
                onClick={() => handlePayment(props.paymentName)}
              >
                <i className="fa fa-shopping-cart"></i> {props.paymentName}
              </button>
            </div>
          </div>
          {/* <div
          className="flower-bottom"
          style={{ display: "flex", justifyContent: "space-between" }}
        > */}
          {/* <div className="f-qty f-qty-2">
            <button
              type="button"
              id="sub"
              className="minus"
              onClick={handleMinusClick}
            >
              <i className="fa-solid fa-minus"></i>
            </button>
            <div className="row-container d-flex align-items-center">
              <input
                type="text"
                readOnly={false}
                value={quantity}
                className="quantity"
                min="1"
                max="1000"
                style={{ width: quantity > 999 ? 40 : 30 }}
              />
              {props.icon}
            </div>
            <button
              type="button"
              id="add"
              className="plus"
              onClick={handlePlusClick}
            >
              <i className="fa-solid fa-plus"></i>
            </button>
          </div> */}
          {/* <button
            className="btn primary-button rounded-button"
            data-bs-toggle="modal"
            data-bs-target="#myModal"
            onClick={handlePayment}
          >
            <i className="fa fa-shopping-cart"></i> {props.paymentName}
          </button> */}
          {/* <h5>NEW {props.walletName.toUpperCase()} RANKING</h5>
					<h6>{`${newRanking < 10 ? "0" : ""}${newRanking}`}</h6> */}
        </div>
      </>
      {/* )} */}

      {/* <button
					className='btn primary-button rounded-button'
					data-bs-toggle='modal'
					data-bs-target='#myModal'
					onClick={handlePayment}
				>
					<i className='fa fa-shopping-cart'></i> {props.paymentName}
				</button> */}
      {/* </div> */}

      <Modal show={isDialogAddBalanceShow}>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={(e) => setIsDialogAddBalanceShow(false)}
        ></button>
        <Modal.Body>
          <div className="reload-flower-wrap">
            <div className="flower-top">
              <SizedBox width={50} />
              <h4>
                <strong>
                  Bal
                  {/* <SizedBox width={5}/>  */}
                  <i className="fa-solid fa-dollar-sign"></i>
                  {/* <SizedBox width={5}/>  */}
                  {coinBalance ?? "N/A"}
                </strong>
              </h4>
            </div>
            <div className="row-container d-flex align-items-center justify-content-center">
              <h5 className="title" style={{ fontSize: 16 }}>
                {langType === "zh" ? props.chineseName : props.englishName}
              </h5>
              <SizedBox width={20} />
            </div>
            <div className="row-container d-flex align-items-center justify-content-center">
              <h5>
                {/* {props.icon} */}
                {balance}
              </h5>
              <SizedBox width={20} />
              {/* <p style={{ fontSize: 13 }}>
                {(props.packageDay ?? 0) > 0 &&
                  `${props.walletName.toUpperCase()} PACKAGE (${
                    props.packageDay
                  } days)`}
              </p> */}
            </div>

            <div className="qty-box">
              <div>
                <button
                  type="button"
                  className="minus"
                  onClick={handleMinusClick}
                >
                  <i className="fa-solid fa-minus"></i>
                </button>
                <input
                  type="text"
                  // readOnly={false}
                  value={quantity}
                  className="quantity"
                  min="1"
                  max="1000"
                  onChange={(e: any) => handleInputChange(e)}
                  maxLength={6}
                />
                <button
                  type="button"
                  className="plus"
                  onClick={handlePlusClick}
                >
                  <i className="fa-solid fa-plus"></i>
                </button>
              </div>

              <div className="text-price" style={{ color: "white" }}>
                <i className="fa-solid fa-dollar-sign"></i>
                <input
                  readOnly={false}
                  type="text"
                  name=""
                  value={quantity * props.packagePrice}
                />
              </div>
            </div>

            <div className="text-center">
              <OverlayTrigger
                trigger="click"
                placement="right-start"
                overlay={
                  <Popover id="popover-positioned-left" title="Popover left">
                    {coinBalance < quantity * props.packagePrice && (
                      <div className="popover">
                        <div
                          className="popover-arrow"
                          style={{
                            position: "absolute",
                            transform: "translate3d(0px, 78px, 0px)",
                            top: "0px",
                          }}
                        ></div>
                        <div className="popover-body">
                          <div
                            data-name="blog-popover-content"
                            className="blog-popover text-center"
                          >
                            <p>Not enough OneCoin</p>
                            <Link
                              to={
                                (isMaster
                                  ? AppRoutePath.masterWallet
                                  : AppRoutePath.clientWallet) + "/one-coin"
                              }
                            >
                              <a
                                className="btn primary-button rounded-button w-100"
                                onClick={(e) => {
                                  setIsDialogAddBalanceShow(false);
                                  setShowPopover(false);
                                }}
                              >
                                Reload OneCoin
                              </a>
                            </Link>
                          </div>
                        </div>
                      </div>
                    )}
                  </Popover>
                }
              >
                <button
                  id="blog"
                  className="btn primary-button rounded-button abs-btn coin-btn w-70"
                  onClick={handlePaymentSubmit}
                  style={{ width: "100%" }}
                  disabled={showSpinner || Number(quantity) < 1}
                >
                  {/* <i className="fa fa-shopping-cart"></i>  */}
                  {showSpinner ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    <>
                      <i className="fa fa-shopping-cart"></i>
                      {props.paymentName} {props.walletName}{" "}
                    </>
                  )}
                  {/* {props.paymentName}{" "}
                  {props.walletName}{" "} */}
                  {(props.packageDay ?? 0) > 0 && " Packages"}
                </button>
              </OverlayTrigger>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={isDialogInsufficientCoin}>
        <Modal.Body>
          <Modal.Title>
            <center>
              <h5 className="modal-title" style={{ fontSize: 20 }}>
                Reload OneCoin
              </h5>
              <SizedBox height={25} />
              <h5 className="modal-title">
                {`Your OneCoin is not enough to ${props.paymentName} ${props.walletName}, please reload it first`}
              </h5>
              <SizedBox height={25} />
            </center>
          </Modal.Title>
        </Modal.Body>

        <Modal.Footer>
          <div className="d-flex justify-content-center align-items-center">
            <button
              type="button"
              className="primary-button button mt-3 w-100"
              data-bs-dismiss="modal"
              onClick={() =>
                navigate(
                  isMaster
                    ? AppRoutePath.masterReloadCoin
                    : AppRoutePath.clientReloadCoin
                )
              }
            >
              Yes
            </button>
            <div style={{ width: 50 }}></div>
            <button
              type="button"
              className="primary-button button mt-3 w-100"
              data-bs-dismiss="modal"
              onClick={() => setIsDialogInsufficientCoin(false)}
            >
              No
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
