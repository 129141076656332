import React, { useState, useEffect } from "react";
import CustomNavigation from "../reusable/Navigation";
import AccountTypeConst from "../../domain/enum/account-type";
import { useNavigate } from "react-router-dom";
import { getSystemMessage } from "../../services/system-notification";
import CustomHeader from "../reusable/Header";
import TopHearder from "../reusable/TopHearder";

export default function ContactPage(): JSX.Element {
  const isMaster = window.location.pathname.includes("master");
  const [isLoading, setIsLoading] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(true);
  const navigate = useNavigate();
  const [notifCount, setNotifCount] = useState(0);

  // useEffect(() => {
  //   getSystemMessage({ status: 0 }).then((res) => {
  //     if (res) {
  //       setNotifCount(res.length);
  //     }
  //   });
  // }, []);

  return (
    <>
      <CustomHeader />
      <TopHearder title={" Contact Page"} />

      <div className="bg-dark">
        <div className="container py-5">
          <h1 className="text-center"> Contact Page</h1>
        </div>
      </div>
    </>
  );
}
