import React, { useState } from "react";
import CustomNavigation from "../reusable/Navigation";
import AccountTypeConst from "../../domain/enum/account-type";
import { Link, Navigate, useNavigate } from "react-router-dom";

import ChatContainer from "../subcomponents/mixed/ChatContainer";

export default function TChats(): JSX.Element {
  const isMaster = window.location.pathname.includes("master");
  const [isLoading, setIsLoading] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(true);

  const [selectedPostTitle, setSelectedPostTitle] = useState("");
  const navigate = useNavigate();

  const chatData = {
    messages: [
      {
        content: "Hello",
        sender: "Sender",
      },
      {
        content: "Hi there!",
        sender: "Receiver",
      },
      {
        content: "How are you?",
        sender: "Sender",
      },
      {
        content: "I'm doing great, thanks!",
        sender: "Receiver",
      },
      {
        content: "I'm doing great, thanks!,jkghjakdshgjkdhgkjha kjrghjkerhkjh ",
        sender: "Receiver",
      },
      {
        content:
          " thanks!,jkghjakdshgjkdhgkjha kjrghjkerhkjh erkjhter tkjrhjkfgjhfsd jkshsdkjfgh ghsdfj gnfdjkgh idngkjdhfg dfhjkg hkjfdhgjkdf hg ksjfgjskdhg sdhgjkdfhgjk hgjkhfdghf djkg jkghjfd hg jkfdhgjkfhgj jkfdhgjkdfhgj hgjdfhgkj dhkjshg",
        sender: "Sender",
      },
    ],
  };

  return (
    <>
      <CustomNavigation
        isOpen={isNavOpen}
        onCallback={(value: boolean) => {
          setIsNavOpen(value);
        }}
        accountType={
          isMaster ? AccountTypeConst.Master : AccountTypeConst.Client
        }
      />
      <header>
        <a className="lft-arw">
          <i
            className="fa-solid fa-chevron-left"
            onClick={() => {
              window.history.back();
            }}
          ></i>
        </a>
        <div className="title">John</div>
        <a className="clk-btn" onClick={() => setIsNavOpen(!isNavOpen)}>
          <i className="fa-solid fa-bars"></i>
        </a>
      </header>
      <div className="bg-dark">
        <ChatContainer
          senderProfilePhoto={"/img/blank-profile-picture.png"}
          receiverProfilePhoto={
            "https://d2zzhkl4ews4a1.cloudfront.net/viewer/516/media/ad/ad_516_1686253795_1.PNG"
          }
          messages={chatData.messages}
        />
      </div>
    </>
  );
}
