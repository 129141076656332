import * as React from "react";

import CustomEmptyMessage from "../reusable/EmptyMessage";
import { useParams } from "react-router-dom";

// import moment from "moment";
import { useTranslation } from "react-i18next";
import CustomHeader from "../reusable/Header";
import TopHearder from "../reusable/TopHearder";
import Loaders from "../reusable/Loaders";
import { Pagination, Table } from "react-bootstrap";
import { getInvitationList } from "../../services/client/client-invitation";
import { InvitationModel } from "../../domain/model/invitation-model";
import moment from "moment";

export default function InvitationListPage() {
  const { t } = useTranslation();
  const langType = localStorage.getItem("i18nextLng");
  const { id } = useParams();
  const isMaster = localStorage.getItem("role") == "master";

  const [listInvitaion, setListInvitaion] = React.useState<InvitationModel[]>(
    []
  );

  const [perPage, setPerPage] = React.useState(10);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);

  const getData = () => {
    setIsLoading(true);
    getInvitationList({ page: currentPage, per_page: perPage }).then((res) => {
      if (res) {
        setListInvitaion(res["item(s)"]);
        const totalPages = res.total_pages || 1;
        setTotalPages(totalPages);

        setIsLoading(false);
      }
      setIsLoading(false);
    });
  };

  React.useEffect(getData, [currentPage, totalPages]);

  const goToPage = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const goToNextPage = () => {
    goToPage(currentPage + 1);
  };

  const goToPrevPage = () => {
    goToPage(currentPage - 1);
  };

  // React.useEffect(() => {
  //   setIsLoading(true);
  //   getInvitationList({page:page, per_page:perPage}).then((res) => {
  //     if (res != null) {
  //       setListInvitaion(res["item(s)"]);
  //       setIsLoading(false);
  //     } else {
  //       setIsLoading(false);
  //     }
  //   });
  // }, []);

  return (
    <div>
      <CustomHeader />
      <TopHearder title={t("invitationList")} />

      <div className="bg-dark">
        <div className="container py-5">
          {isLoading ? (
            <>
              <div className="col-12 text-center">
                <Loaders />
              </div>
            </>
          ) : (
            <Table className="cutome-tables" responsive>
              {listInvitaion.length < 1 ? (
                <CustomEmptyMessage />
              ) : (
                <>
                  <thead>
                    <tr className="primary-color">
                      <th className="nowrap">{t("memberProfile")}</th>
                      <th className="nowrap">{t("invited_times")}</th>
                      <th className="nowrap">{t("last_invited")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listInvitaion
                      .sort(
                        (a, b) =>
                          moment(b.last_invited).valueOf() -
                          moment(a.last_invited).valueOf()
                      )
                      .map((invitations, i) => (
                        <tr
                          className="align-items-center mb-3 border-0"
                          key={i}
                        >
                          <td className="  ">
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0">
                                <img
                                  className="clt-img1 "
                                  src={
                                    invitations.photo
                                      ? invitations.photo
                                      : "/img/blank-profile-picture.png"
                                  }
                                  alt="User profile"
                                />
                              </div>
                              <div className="flex-grow-1 ms-lg-2 ms-1 ">
                                <p className="primary-color mb-0 ">
                                  {invitations.en_name}
                                </p>
                              </div>
                            </div>
                          </td>

                          <td className="primary-color ps-5 ">
                            {invitations.invited_times}
                          </td>
                          <td className="primary-color profile-title">
                            {invitations.last_invited}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </>
              )}
            </Table>
          )}
        </div>

        <div className="d-flex justify-content-center my-3">
          <Pagination>
            <Pagination.Prev
              onClick={goToPrevPage}
              disabled={currentPage === 1}
            />

            {/* Show the first page */}
            <Pagination.Item onClick={() => goToPage(1)}>First</Pagination.Item>

            {/* Show ellipsis if there are more than 3 pages before the current page */}
            {currentPage > 3 && <Pagination.Ellipsis disabled />}

            {/* Show two pages before the current page */}
            {currentPage > 2 && (
              <Pagination.Item onClick={() => goToPage(currentPage - 1)}>
                {currentPage - 1}
              </Pagination.Item>
            )}

            {/* Show the current page */}
            <Pagination.Item active>{currentPage}</Pagination.Item>

            {/* Show two pages after the current page */}
            {currentPage < totalPages - 1 && (
              <Pagination.Item onClick={() => goToPage(currentPage + 1)}>
                {currentPage + 1}
              </Pagination.Item>
            )}

            {/* Show ellipsis if there are more than 3 pages after the current page */}
            {currentPage < totalPages - 2 && <Pagination.Ellipsis disabled />}

            {/* Show the last page */}
            <Pagination.Item onClick={() => goToPage(totalPages)}>
              Last
            </Pagination.Item>

            <Pagination.Next
              onClick={goToNextPage}
              disabled={currentPage === totalPages}
            />
          </Pagination>
        </div>
      </div>
    </div>
  );
}
