export default class LocalStorageKey {
    static isLogin = 'is_login';
    static isRegister = 'is_register';
    static role = 'role'
    static token = 'token'
    static tokenGuest = 'tokenGuest'
    static userProfilePicture = 'user_profile_picture';
    static master_id = 'master_id';
}

