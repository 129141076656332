import React, { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";
import AppRoutePath from "../../../constants/AppRoutePath";

import RegistrationSteps from "../navigation/RegistrationSteps";
import {
  ReloadCoinUploadInputState,
  ImageUploaded,
} from "./ReloadCoinUploadProgress";
import LocalStorageKey from "../../../constants/LocalStorageKey";
import { useTranslation } from "react-i18next";
import CustomHeader from "../../reusable/Header";
import TopHearder from "../../reusable/TopHearder";

function ReloadCoinImageSelector(): JSX.Element {
  const imgRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const isLogin =
    (localStorage.getItem(LocalStorageKey.isLogin) ?? "false") === "true";
  const isMaster = window.location.pathname.includes("master");
  const { t } = useTranslation();

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const target = event.target as HTMLInputElement;
    console.log(target.files as FileList);
    const listFileSelected = target.files as FileList;

    const listImageUploaded: ImageUploaded[] = [];
    for (let i = 0; i < listFileSelected.length; i++) {
      const imageTemp: ImageUploaded = {
        image: listFileSelected[i],
        path: "",
        uploadProgress0: 0,
        uploadProgress: 0,
      };
      listImageUploaded.push(imageTemp);
    }
    const reloadCoinUploadInput: ReloadCoinUploadInputState = {
      listImage: listImageUploaded,
    };
    isLogin
      ? navigate(AppRoutePath.reloadCoinUpload, {
          state: reloadCoinUploadInput,
        })
      : navigate(
          isMaster
            ? AppRoutePath.masterReloadCoinUpload
            : AppRoutePath.clientReloadCoinUpload,
          {
            state: reloadCoinUploadInput,
          }
        );
  };

  const showOpenFileDlg = () => {
    imgRef.current?.click();
  };

  return (
    <>
      <CustomHeader publics={true} />
      <TopHearder title={t("OneCoinReloads1")} />
      <div className="bg-dark">
        <div className="container">
          <div className="inner-box">
            <div className="accordion pt-3" id="accordionExample">
              {isLogin ? <></> : <RegistrationSteps step={1} />}

              <div className="tab-content">
                <div id="headingTwo"></div>
                <div
                  id="collapseTwo"
                  className="collapse show text-center"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <h4 className="mt-3 mb-1 qr-title">{t("transferUSD")} </h4>
                  <p className="qr-subtitle">{t("transferUSD1")} </p>
                  <label htmlFor="" className="text-dim mb-2">
                    {t("uploadFile")}
                  </label>

                  <div className="text-center mt-4 p-3">
                    <input
                      ref={imgRef}
                      type="file"
                      onChange={handleFileChange}
                      hidden={true}
                      multiple={true}
                    />
                    {/* <Link to="/reload-coin-gallery"> */}
                    <button
                      className="btn primary-button w-80 rounded-button mt-3 px-3"
                      onClick={showOpenFileDlg}
                    >
                      {/* {seconds > 0 ? (
                        <>
                          {" "}
                          {t("transferingUSD")} {seconds}
                        </>
                      ) : (
                        t("BrowseFile")
                      )} */}
                      {t("BrowseFile")}
                    </button>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReloadCoinImageSelector;
