import React, { useState } from "react";
import moment from 'moment';
import { Link } from "react-router-dom";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Timepicker } from "react-timepicker";
import 'react-timepicker/timepicker.css'
import { Button, CloseButton, Modal } from "react-bootstrap";
import CustomBackButton from "../reusable/BackButton";

function MyInstantScheduledPostLists() {
    const posts = [
        {
            title: "Instant post title",
            author: "Client Name",
            date: "2023-03-13 03:00:00",
            likes: 15020,
            saves: 98
        },
        {
            title: "Instant post title",
            author: "Client Name",
            date: "2023-03-13 03:00:00",
            likes: 18694,
            saves: 984
        },
        {
            title: "Instant post title",
            author: "Client Name",
            date: "2023-03-13 03:00:00",
            likes: 5404,
            saves: 9245
        },
        {
            title: "Instant post title",
            author: "Client Name",
            date: "2023-03-13 03:00:00",
            likes: 389,
            saves: 43
        }
    ];
    const [datePickerVisibility, setDatePickerVisibility] = useState(); // The index of item

    return (
        <>
            <header>
                <CustomBackButton />
                <div className='title'>Instant Post List</div>
                <a href="#" className="clk-btn"><i className="fa-solid fa-bars"></i></a>

            </header>
            <div className="bg-dark">
                <div className="container">
                    <div className="inner-box pt-5">
                        <div className="d-flex align-items-end justify-content-between mb-4">
                            <a href="/client/add-instant-post"><button className="button primary-button rounded-button create-post-btn"><i className="fa fa-plus me-2"></i>
                                Create New</button></a>
                            <a href="post-list2.html" className="schedule-list primary-color">Schedule Post List <i className="fa fa-angle-right"></i></a>
                        </div>
                        <h3 className="postlist-main-title pb-1">Posted Instant Post List</h3>
                        <ul className="p-0 m-0">
                            {
                                posts.map((e, i) => {
                                    const date = moment(e.date);

                                    return (
                                        <li className="postlist-box position-relative">
                                            <h4 className="postlist-client">{e.title}</h4>
                                            <div className="d-flex gap-2 align-items-center justify-content-between">
                                                <div className="postlist-datetime">
                                                    <h6>{date.format("YYYY-MM-DD")}</h6>
                                                    <h6>{date.format("HH:mm:ss")}</h6>
                                                </div>
                                                <h5 className="postlist-title mb-0"><a href="my-instant-review1.html">Instant Post Title</a></h5>
                                                <div className="d-flex gap-2">
                                                    <button className="button primary-button rounded-button create-post-btn">
                                                        Delete</button>
                                                    <button className="button primary-button rounded-button create-post-btn" onClick={() => setDatePickerVisibility(datePickerVisibility == i ? null : i)}>
                                                        Date</button>
                                                </div>
                                                <Link to="/client/add-instant-post" className="primary-color no-decoration post-edit">Edit</Link>
                                            </div>
                                            <Modal show={i == datePickerVisibility}>
                                                <Modal.Header>
                                                    <CloseButton onClick={() => setDatePickerVisibility(null)} />
                                                    <Modal.Title>
                                                        <span className="text-white">
                                                            Please choose date and time
                                                        </span>
                                                    </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <DatePicker inline onChange={(date) => {
                                                        // TODO date logic here
                                                    }} />
                                                    <div style={{ height: 20 }} />
                                                    <Timepicker onChange={(time) => {
                                                        // TODO time logic here
                                                    }} />
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <button onClick={() => {
                                                        // TODO date and time logic here
                                                        setDatePickerVisibility(null);
                                                    }} className="button primary-button rounded-button create-post-btn" variant="primary">Confirm</button>
                                                </Modal.Footer>
                                            </Modal>
                                        </li>
                                    );
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

function parseThousands(number) {
    const thousandth = number / 1000;

    return number >= 1000 ? `${Math.round((thousandth + Number.EPSILON) * 10) / 10}k` : number.toString();
}

export default MyInstantScheduledPostLists;