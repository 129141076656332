import * as React from "react";
import CustomHeader from "../reusable/Header";

export interface IMemberProfilePageProps {}

export default function MemberProfilePage(props: IMemberProfilePageProps) {
	return (
		<>
			<CustomHeader title='Member Profile' />
			<div className='bg-dark'>
				<div className='container'>
					<div className='inner-box pt-4 pb-4'>
						<div className='row g-0 mb-3 pb-3 rgd-mob'>
							<div className='col-6'>
								<span>Registered Mobile</span>
							</div>
							<div className='col-6 text-end'>
								<span>+85 212 3456 7788</span>
							</div>
						</div>
						<div className='row'>
							<div className='col-4'>
								<div className='clt-img'>
									<img src='img/gallery/1.png' alt='' />
								</div>
							</div>
							<div className='col-8'>
								<div className='pro-edt mf'>
									<div className='row'>
										<div className='col-8'>
											<h4 className='mb-0 qr-title'>Member Name</h4>
											<h5>
												<a href='#'>membername@mail.com</a>
											</h5>
											<ul>
												<li>
													<i className='fa-solid fa-award' />
												</li>
												<li>
													<i className='fa-solid fa-award' />
												</li>
												<li>
													<i className='fa-solid fa-award' />
												</li>
												<li>
													<i className='fa-solid fa-award' />
												</li>
												<li>
													<i className='fa-solid fa-award' />
												</li>
											</ul>
										</div>
										<div className='col-4'>
											<a className='t-chat'>
												<i className='fa-regular fa-comment' /> T Chat{" "}
												<span>15</span>
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='bg-dark2'>
					<div className='container'>
						<div className='plan-wrap pt-4 pb-4'>
							<div className='row g-0 mb-3 pb-3 rgd-mob'>
								<div className='col-6'>
									<span>Membership Level</span>
								</div>
								<div className='col-6 text-end'>
									<a href='#'>Normal Member</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
