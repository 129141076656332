import { Convert, CreateUserParamsModel } from "./create-user-params-model";
import { ICreateUserParams } from "../adapter/create-user-params-adapter";

export default class CreateUserParams implements ICreateUserParams {
    private static instance: CreateUserParams
    private CREATE_USER_PARAMS_KEY: string = 'CREATE_USER_PARAMS_KEY';

    public data: CreateUserParamsModel;

    private constructor() {
        this.data = {};
        console.log(`testing create user:  ${JSON.stringify( this.data)}`)
    }

    public static getInstance(): CreateUserParams {
        if (!CreateUserParams.instance) {
            CreateUserParams.instance = new CreateUserParams();
            // CreateUserParams.instance.saveData();
        }

        CreateUserParams.instance.loadData();
        return CreateUserParams.instance;
    }

    saveData(): boolean {
        try {
            const existingData = localStorage.getItem(this.CREATE_USER_PARAMS_KEY);
            var dataTemp = {}
            if (existingData != null) {
                dataTemp = {...JSON.parse(existingData)}
            }
            dataTemp = {...this.data}
            localStorage.setItem(this.CREATE_USER_PARAMS_KEY, Convert.createUserParamsModelToJson(dataTemp));
            return true;
        } catch (error) {
            console.log(error)
        }
        return false;
    }

    loadData(): boolean {
        try {
            this.data = Convert.toCreateUserParamsModel(localStorage.getItem(this.CREATE_USER_PARAMS_KEY) ?? '{}')
            return true;
        } catch (error) {
            console.log(error)
        };
        return false;
    }

    deleteData(): boolean {
        try {
            localStorage.removeItem(this.CREATE_USER_PARAMS_KEY);
            return true;
        } catch (error) {
            console.log(error)
        }
        return false;
    }

    getData(): object {
        return this.data;
    }
}