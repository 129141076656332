/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";

import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/@fortawesome/fontawesome-free/css/all.min.css";
import "../../css/bootstrap-image-checkbox.css";
import AppRoutePath from "../../constants/AppRoutePath";
import {
  getIconWallet,
  getWalletTypeByString,
  WalletType,
} from "../../constants/WalletType";
import IClientWalletPageTemplate, {
  getWalletTempateByType,
} from "../subcomponents/ClientWalletPage/interface/ClientWalletPageTemplate";
import CustomHeader from "../reusable/Header";
import { getWalletTransactionTypeByString } from "../../constants/WalletTransactionType";
import { useTranslation } from "react-i18next";
import TopHearder from "../reusable/TopHearder";
import OneCoinTableRowV2 from "../subcomponents/ClientWalletPage/table/OneCoinTableRowV2";
import FlowerTableRowV2 from "../subcomponents/ClientWalletPage/table/FlowerTableRowV2";
import HeartTableRowV2 from "../subcomponents/ClientWalletPage/table/HeartTableRowV2";

export default function ClientWalletPage() {
  const search = useLocation().search;
  const params = useParams();
  const [walletTemplate, setWalletTemplate] =
    useState<IClientWalletPageTemplate>();
  const isMaster = window.location.pathname.includes("master");
  const navigate = useNavigate();

  const [cardWallet, setCardWallet] = useState(<p></p>);
  const [tableWallet, setTableWallet] = useState(<p></p>);
  const langType = localStorage.getItem("i18nextLng");
  const [isCardWalletShow, setIsCardWalletShow] = useState(true);
  const [isTableWalletShow, setIsTableWalletShow] = useState(true);
  const { t } = useTranslation();

  // UPDATE WALLET COMPONENT FUNCTION
  const updateCard = () => {
    setIsCardWalletShow(false);
    setTimeout(() => {
      setIsCardWalletShow(true);
    }, 250);
  };
  const updateTable = () => {
    setIsTableWalletShow(false);
    setTimeout(() => {
      setIsTableWalletShow(true);
    }, 250);
  };

  // UPDATE WALLET TEMPLATE
  useEffect(() => {
    const wtTemp = getWalletTempateByType(
      getWalletTypeByString(params.walletType ?? ""),
      getWalletTransactionTypeByString(params.walletTransactionType ?? "")
    );
    wtTemp.updateCard = updateCard;
    wtTemp.updateTable = updateTable;
    if (isMaster) {
      const searchParams = new URLSearchParams(search);
      wtTemp.id = Number(searchParams.get("ad_id")) ?? undefined;
    }
    setWalletTemplate(wtTemp);
  }, [params.walletType, params.walletTransactionType, isMaster, search]);

  // GET COMPONENT WALLET TEMPLATE
  useEffect(() => {
    walletTemplate?.cardComp().then((res) => {
      setCardWallet(res);
    });
    walletTemplate?.tableComp(1, 10).then((res) => {
      setTableWallet(res);
    });
  }, [isMaster, walletTemplate]);

  const handleTab = (typeSelected: WalletType) => {
    const wt = getWalletTempateByType(typeSelected);
    wt.updateCard = updateCard;
    wt.updateTable = updateTable;
    if (isMaster) {
      const searchParams = new URLSearchParams(search);
      wt.id = Number(searchParams.get("ad_id")) ?? undefined;
      const route =
        AppRoutePath.masterWallet +
        (wt.type ? `/${wt.type}` : "") +
        (wt.transactionType ? `/${wt.transactionType}` : "") +
        (wt.id ? `?ad_id=${wt.id}` : "");
      // setWalletTemplate(wt);
      navigate(route);
    } else {
      navigate(
        AppRoutePath.clientWallet +
        (wt.type ? `/${wt.type}` : "") +
        (wt.transactionType ? `/${wt.transactionType}` : "")
      );
    }
  };

  return (
    <>
      <CustomHeader
        // title={walletTemplate?.title}
        backRoute={
          isMaster ? AppRoutePath.masterAccount : AppRoutePath.clientMainPage
        }
      />
      <TopHearder title={walletTemplate?.title} />
      <div className="bg-dark">
        <div className="container py-5">
          {/* <div className='inner-box pt-4 pb-4'> */}
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <ul className="nav-page">
                <li
                  className={
                    walletTemplate?.type === WalletType.OneCoin ? "active" : ""
                  }
                  onClick={() => handleTab(WalletType.OneCoin)}
                >
                  <a>
                    {getIconWallet({ type: WalletType.OneCoin })}{" "}
                    {t("oneCoinBal")}
                  </a>
                </li>
                <li
                  className={
                    walletTemplate?.type === WalletType.Flower ? "active" : ""
                  }
                  onClick={() => handleTab(WalletType.Flower)}
                >
                  <a>
                    {getIconWallet({ type: WalletType.Flower })}{" "}
                    {t("flowerBal")}
                  </a>
                </li>
                <li
                  className={
                    walletTemplate?.type === WalletType.Heart ? "active" : ""
                  }
                  onClick={() => handleTab(WalletType.Heart)}
                >
                  <a>
                    {getIconWallet({ type: WalletType.Heart })} {t("heartBal")}
                  </a>
                </li>
              </ul>
              <div className="account-box mt-5 mb-5 align-items-center">
                <div className="account active-account w-100">
                  {isCardWalletShow && cardWallet}
                </div>
              </div>
              {/* {isTableWalletShow && tableWallet} */}
              {walletTemplate?.type === WalletType.OneCoin ? (
                <>
                  <OneCoinTableRowV2 />
                </>
              ) : walletTemplate?.type === WalletType.Flower ? (
                <>
                  <FlowerTableRowV2 />
                </>
              ) : (
                <HeartTableRowV2 />
              )}
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

// 