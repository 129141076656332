import { WalletTransactionType } from "../../../../constants/WalletTransactionType";
import ReloadFlowerCardComp from "../card/ReloadFlowerCardComp";
import FlowerWalletTemplate from "./FlowerWalletPageTemplate";

export default class ReloadFlowerWalletPageTemplate extends FlowerWalletTemplate {
  constructor(id?: number, updateTable?: () => void) {
    super(id);
    this.updateTable = updateTable;
  }

  title = "Reload FLOWER";
  transactionType = WalletTransactionType.Reload;
  updateTable;

  async cardComp() {
    return <ReloadFlowerCardComp updateTable={this.updateTable} />;
  }
}
