import Snackbar from "awesome-snackbar";
import axios from "../axios";
import {
  FlowerRanking,
  IFindFLowerRankingListParams,
} from "../../domain/model/flower-ranking-model";
import { sumBalances } from "../../helper/format-helper";
import { walletTranParams } from "./one-coin";

export const findFlowerBalance = async (signal?: AbortSignal) => {
  const res = await axios
    .get("/product/flower/balance",{signal})
    .then((respons) => {


      return respons.data.balance;
    })
    .catch((error) => {
      try {
        console.log(JSON.stringify(error));
        // new Snackbar(`ERROR: ${error.error.reason ?? error.error.message}`);
      } catch (error) {
        console.log(error);
        new Snackbar("Failed to get flower balance");
      }
      return 0;
    });

  return res;
};
export const findFlowerBalanceNumber = async () => {
  const res = await axios
    .get("/product/flower/balance")
    .then((respons) => {
      const totalBalance = Number(sumBalances(respons.data.balance));
      return totalBalance;
    })
    .catch((error) => {
      try {
        console.log(JSON.stringify(error));
        // new Snackbar(`ERROR: ${error.error.reason ?? error.error.message}`);
      } catch (error) {
        console.log(error);
        new Snackbar("Failed to get flower balance");
      }
      return 0;
    });

  return res;
};

export const findFlowerHistory = async () => {
  const params = `limit&offset`;
  const isSuccess = await axios
    .get(`/product/flower/histories?${params}`)
    .then((respons) => {
      // console.log("/flower/histories : " + JSON.stringify(respons));
      return respons.data["item(s)"];
      // return true;
    })
    .catch((error) => {
      try {
        console.log(JSON.stringify(error));
        // new Snackbar(`ERROR: ${error.error.reason ?? error.error.message}`);
      } catch (error) {
        console.log(error);
        new Snackbar("Failed to get flower history");
      }
      return false;
    });

  return isSuccess;
};

export const findFlowerTransaction = async (
  params: walletTranParams
) => {
  // const params = `page=${page}&per_page=${perPage}&desc_sort=${descSort}&to=${to}&tx_id&start_at&end_at`;

  console.log("params Folowers :" + JSON.stringify(params));
  try {
    const response = await axios.get(`/product/flower/transactions`, { params });

    const transactions = response.data;
    console.log("transactions Flower ", transactions);

    return transactions;
  } catch (error: any) {
    console.log(error.response || error || "An unknown error occurred.");
    // new Snackbar('An error occurred.');
    return [];
  }
};

export const findFlowerRanking = async (): Promise<Ranking> => {
  try {
    const response = await axios.get("/product/flower/rank");
    // console.log("Folowre rankkk ", response);

    return response.data;
  } catch (error: any) {
    console.log(error.response || error || "An unknown error occurred.");

    return error;
  }
};

export const findFlowerRangkingList = async (
  params?: IFindFLowerRankingListParams
) => {

  const res = await axios
    .get(`/product/flower/ranks`, { params })
    .then((respons) => {
      // console.log("findFlowerRangkingList : " + JSON.stringify(respons));
      return respons.data["item(s)"] as FlowerRanking[];
    })
    .catch((error) => {
      try {
        console.log(JSON.stringify(error));
        new Snackbar(`ERROR: ${error.error.reason ?? error.error.message}`);
      } catch (error) {
        console.log(error);
        new Snackbar("Failed to get flower ranking");
      }
      return null;
    });

  return res;
};

export const purchaseFlower = async (units: number, prod_id: number) => {
  try {
    const response = await axios.post("/product/flower/purchase", {
      units: units,
      prod_id: prod_id,
    });


    if (response.data.result_code !== 200) {
      return response.data
    } else {
      // return true
      return response.data
    }

    // return true;
  } catch (error: any) {
    try {
      console.log(JSON.stringify(error));
      new Snackbar(`ERROR: ${error.error.reason ?? error.error.message}`);
    } catch (error) {
      console.log(error);
      new Snackbar("Failed to purchase flower");
    }
    return false;
  }
};

export const sendFlower = async (data: any) => {
  try {
    const response = await axios.post("/product/flower/send", {
      prod_id: data.prod_id,
      target: data.target,
    });
    // console.log(response);

    return response.data;
  } catch (error: any) {
    try {
      console.log(JSON.stringify(error));
      new Snackbar(`ERROR: ${error.error.reason ?? error.error.message}`);
    } catch (error2) {
      console.log(error2);
      new Snackbar("Failed to send flower");
    }
    return false;
  }
};

export const addFlower = async (units: number, target: number) => {
  try {
    const response = await axios.post("/product/flower/add", {
      units: units,
      target: target,
    });
    console.log(response);
    return true;
  } catch (error: any) {
    try {
      console.log(JSON.stringify(error));
      new Snackbar(`ERROR: ${error.error.reason ?? error.error.message}`);
    } catch (error2) {
      console.log(error2);
      new Snackbar("Failed to add flower");
    }
    return false;
  }
};
