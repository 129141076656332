import { FirstDayOfWeek, useMonth } from "@datepicker-react/hooks";
import Day from "./Day";

export interface IMonthProps {
	year: number;
	month: number;
	firstDayOfWeek: FirstDayOfWeek;
}

function Month({ year, month, firstDayOfWeek }: IMonthProps) {
	const { days, weekdayLabels, monthLabel } = useMonth({
		year,
		month,
		firstDayOfWeek,
	});

	return (
		<div>
			<div
				style={{ textAlign: "center", margin: "0 0 16px", color: "#FFFFFF" }}
			>
				<strong>{monthLabel.toUpperCase()}</strong>
			</div>
			<div
				style={{
					display: "grid",
					gridTemplateColumns: "repeat(7, 1fr)",
					justifyContent: "center",
					marginBottom: "10px",
					fontSize: "10px",
				}}
			>
				{weekdayLabels.map((dayLabel) => (
					<div style={{ textAlign: "center", color: "#FFFFFF" }} key={dayLabel}>
						{dayLabel}
					</div>
				))}
			</div>
			<div
				style={{
					display: "grid",
					gridTemplateColumns: "repeat(7, 1fr)",
					justifyContent: "center",
				}}
			>
				{days.map((day, index) => {
					if (typeof day === "object") {
						return (
							<Day
								date={day.date}
								key={day.date.toString()}
								dayLabel={
									day.dayLabel.slice(0, 1) === "0"
										? day.dayLabel.slice(1)
										: day.dayLabel
								}
							/>
						);
					}

					return <div key={index} />;
				})}
			</div>
		</div>
	);
}

export default Month;
