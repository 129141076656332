import axios from "axios";

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY_TRA; // Assuming it's stored securely


export const translateToChinese = async (text: any) => {
  try {
    if (!text || typeof text !== "string") {
      throw new Error("Invalid text input for translation.");
    }

    //   const apiKey = process.env.REACT_APP_GOOGLE_TRANSLATE_API_KEY;
    const url = `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}`;

    const response = await axios.post(
      url,
      {
        q: text,
        source: "en",
        target: "zh-CN",
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    console.log("trd", response.data.data.translations[0].translatedText);

    if (
      response.data &&
      response.data.data.translations &&
      response.data.data.translations.length > 0
    ) {
      return response.data.data.translations[0].translatedText
    } else {
      throw new Error("Unexpected API response: translations missing or empty");
    }
  } catch (error) {
    console.error("Error translating text:", error);
    // Handle the error (e.g., return default text, retry translation)
    return ""; // Example of a default value
  }
};
