import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import AppRoutePath from "../../../../constants/AppRoutePath";
import { addCommas } from "../../../../helper/format-helper";
import { useTranslation, initReactI18next } from "react-i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: require("../../../../locales/en.json") },
      zh: { translation: require("../../../../locales/zh.json") },
    },
    fallbackLng: "en", // Default language fallback
    interpolation: {
      escapeValue: false, // Disable HTML escaping
    },
  });
export interface IOneCoinCardCompProps {
  coin?: number;
  page?: number;
  per_page?: number;
}

export default function OneCoinCardComp({ coin = 0 }: IOneCoinCardCompProps) {
  const isMaster = window.location.pathname.includes("master");
  const isEXT = localStorage.getItem("EXT") == "true";
  const isEmailLogin = localStorage.getItem("email_login") == "true";
  const { t } = useTranslation();

  return (
    <div className="card  d-flex flex-row bd-highlight text-center bg-white">
      <div className="card-body">
        <h5 className="balance-title mb-0"> {t("OneCoinWalletBalance")}</h5>
        <h5 className="balance-value d-flex align-items-center justify-content-center gap-1">
          <img src="/img/onecoin_icon.png" alt="" />
          <p> {addCommas(coin)}</p>
        </h5>
        {!isEXT && 
          (!isEmailLogin && (
            <>
              <Link
                to={
                  isMaster
                    ? AppRoutePath.masterReloadCoin
                    : AppRoutePath.clientReloadCoin
                }
              >
                <button
                  className={`btn primary-button rounded-button abs-btn coin-btn w-80 m-auto`}
                >
                  <i className="fa fa-shopping-cart"></i> {t("reloadOneCoin")}
                </button>
              </Link>
            </>
          ))}
      </div>
    </div>
  );
}
