
import Snackbar from "awesome-snackbar";
import axios from "./axios";
import {

	IDeleteSystemMessageParams,
	IGetSystemMessageDetailParams,
	IGetSystemMessageParams,
	SystemMessageModel,
} from "../domain/model/system-message";

export const addSystemMessage = async (params?: SystemMessageModel) => {
	const res = await axios
		.get("/sup/notification/add", { params })
		.then((respons) => {
			// console.log(respons);
			return respons.data["item(s)"] as SystemMessageModel[];
		})
		.catch((error) => {
			try {
				console.log(JSON.stringify(error));
				new Snackbar(`Error: ${error.error.reason ?? error.error.message}`);
			} catch (error) {
				console.log(error);
				new Snackbar("Failed to get system message");
			}
			return null;
		});

	return res;
};
export const getSystemMessage = async (params?: IGetSystemMessageParams) => {
	// console.log("parms ", JSON.stringify(params));


	const res = await axios
		.get("/system/notification/list", { params })
		.then((respons) => {
			// console.log("SystemMessageModel respons " ,respons.data);
			// const systemMessages = respons.data["item(s)"] as SystemMessageModel[];
			const systemMessages = respons.data as SystemMessageModel;

			return systemMessages
		})
		.catch((error) => {
			try {
				console.log(JSON.stringify(error));
				// console.log("SystemMessageModel Error :", error);
				// new Snackbar(`Error: ${error.error.reason ?? error.error.message}`);
				if ((error.error.reason || error.error.message) === "Token Expired") {
					new Snackbar("Please relogin again.");
					setTimeout(() => {
						localStorage.clear();
						document.location.href = "/";
					}, 1000);
				}
			} catch (error) {
				console.log(error);
				new Snackbar("Failed to get system message");
			}
			return null;
		});

	return res;
};

export const getSystemMessageDetail = async (
	params?: IGetSystemMessageDetailParams
) => {
	const res = await axios
		.get(`/system/notification/${params?.notif_id}`)
		.then((respons) => {
			// console.log(respons);
			return respons.data as SystemMessageModel;
		})
		.catch((error) => {
			try {
				console.log(JSON.stringify(error));
				new Snackbar(`Error: ${error.error.reason ?? error.error.message}`);
			} catch (error) {
				console.log(error);
				new Snackbar("Failed to get system message");
			}
			return null;
		});

	return res;
};

export const deleteSystemMessage = async (
	params: IDeleteSystemMessageParams
) => {
	const res = await axios
		.delete(`/system/notification/${params.notif_id}`)
		.then((respons) => {
			// console.log(respons);
			new Snackbar("System Message successfully deleted");
			return true;
		})
		.catch((error) => {
			try {
				console.log(JSON.stringify(error));
				new Snackbar(`Error: ${error.error.reason ?? error.error.message}`);
			} catch (error) {
				console.log(error);
				new Snackbar("Failed to get system message");
			}
			return false;
		});

	return res;
};
export const deleteAllSystemMessages = async (

) => {

	// console.log("fgfg ", params);

	const res = await axios
		.delete(`/system/notification/delete/all`, { data: { delete_all: true } })
		.then((respons) => {
			// console.log(respons);
			new Snackbar("All read System Message successfully deleted");
			return true;
		})
		.catch((error) => {
			try {
				console.log(JSON.stringify(error));
				new Snackbar(`Error: ${error.error.reason ?? error.error.message}`);
			} catch (error) {
				console.log(error);
				new Snackbar("Failed to get system message");
			}
			return false;
		});

	return res;
};

